import { Component, OnInit, AfterContentInit, ViewChild } from '@angular/core';
import { ProposalService } from '../../_services/proposal.service';
import { BrandService } from '../../_services/brand.service';
import { VelvetysoftService } from '../../_services/velvetysoft.service';
import { Settings, SettingsSubject, UserProfileModel } from "../../_models/subjects";
import { ActivatedRoute } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ProposalModel } from "../../_models/subjects";
import { AlertService } from '../../_services/alert.service';
import {  map, first } from 'rxjs/operators';
import { SendEmailComponent } from '../dialogs/send-email/send-email.component';
import { UserService } from 'src/app/_services/user.service';
import { Location } from '@angular/common';
import { ConfirmComponent } from '../dialogs/confirm/confirm.component';

@Component({
  selector: "app-proposals",
  templateUrl: "./proposals.component.html",
  styleUrls: ["./proposals.component.scss"],
})
export class ProposalsComponent implements AfterContentInit {
  $proposals: Observable<ProposalModel[]>;
  $userProfile: Observable<UserProfileModel>;
  displayedColumns: string[] = [
    "select",
    "user",    
    "title",
    // "proposal_number",
    "type",
    "total",
    //"due",
    "status",
    "actions",
  ];
  _settings: Settings;
  hasNotified = [];
  types: any;
  view: string = "table";
  //dataSource = new MatTableDataSource<ProposalModel[]>();
  sortedData: ProposalModel[];
  filteredSubjects: string[] = ["draft", "sent", "approved"];
  subject: string = "proposal";
  subjectObj: SettingsSubject;
  search: string = '';
  prefix = '';
  @ViewChild("vaultTable") vaultTable;
  constructor(
    private proposalService: ProposalService,
    public brandService: BrandService,
    public vs: VelvetysoftService,
    private router: Router,
    public dialog: MatDialog,
    private userService: UserService,
    private location: Location
  ) {}

  selection = new SelectionModel<ProposalModel>(true, []);

  ngAfterContentInit() {
    this.$proposals = this.proposalService.proposals;
    this.$userProfile = this.userService.userProfile();
    this.types = this.vs.settings[this.subject];
    this.brandService.$activeBrand.subscribe(brand => this.prefix = brand.prefix);
    this.subjectObj = this.vs.findSubject(this.subject); 
    const state:any = this.location.getState(); 
    if(state['action']){
      if(state.action == 'addproposal') this.edit(null,null,state['pidCtx']);
      if(state.action == 'editproposal') this.edit(state.proposal);
    }     
  }

  approvalLink(proposal: ProposalModel) {
    let host = window.location.origin;
    let msg = (proposal.approval_link) 
      ? `<p>View Approval page. Note: Annyone with this link is able to approve the Proposal.</p><a class="btn btn-primary" target="_blank" href="${host}/url/p/${proposal.approval_link}">View Approval Link</a>` 
      : 'No Approval Link created yet. Try re-saving the proposal to auto-generate a new link.';

    this.dialog.open(ConfirmComponent, {      
      data: {
        title: "Approval Link",
        content: msg,
        action: "Copy",
      },
    }).afterClosed().subscribe(result => {
      //console.log(`http://localhost:4200/url/p/${proposal.approval_link}`);
      console.log(result);
    });    
  }

  changeView(view) {
    this.view = view;
  }

  send(proposal: ProposalModel) {
    this.proposalService.sendEmail(proposal);
  }

  multiBrand(proposal){
    return this.proposalService.isMultiBrand(proposal);
  }

  log(event) {
    console.log(event);
  }

  createProjects(proposal: ProposalModel){
    this.proposalService.createProjects(proposal);
  }

  convetToInvoice(proposal: ProposalModel){
    console.log(proposal);
    let data = { 
      action: 'editinvoice',
      invoice: this.proposalService.convertToInvoice(proposal)
    }    
    this.router.navigate(['/dashboard/invoices'],{ state: data })
  }

  wizard(proposal: ProposalModel) {
    
    let data = { 
      action: 'wizard',
      invoice: this.proposalService.convertToInvoice(proposal)
    }    
    this.router.navigate(['/dashboard/invoices'],{ state: data })
  }

  download(proposal: ProposalModel) {
    this.proposalService.download(proposal);
  }

  //CAN DELET FROM SINGLE ITEM OR LIST OF SELECTED
  trash(id?) {
    let ids = id ? [id] : this.vaultTable.selection.selected.map((p) => p.id);
    this.proposalService.deleteDialog(ids);
  }

  edit(proposal?: ProposalModel,view?,pid=null) {    
    this.proposalService.edit(proposal,view,pid);
  }
}
