<app-dashboard-snapshot *ngIf="vs.dashboardSnapshot"></app-dashboard-snapshot>

<ng-container *ngIf="( $userProfile | async );let profile"  >
<nav  *ngIf="profile.company; let company;" class="navbar navbar-dark fixed-top flex-md-nowrap p-0 navbar-top shadow" [style.backgroundColor]="company.color">
  <div class="d-flex">
    <div class="mobile-nav d-flex"> 
      <button mat-button (click)="mobileNav()" > <mat-icon class="p-3 text-white" >menu</mat-icon></button>
    </div>
    <a class="navbar-brand mr-0" routerLink="/dashboard" >
      <img *ngIf="company.logo; else title" [src]="cdn+company.logo" class="company-logo" />
      <ng-template #title>
        {{ company.name }}
      </ng-template>
      
    </a> 
    
  </div>
  <ul class="navbar-nav px-3">
    <li class="nav-item text-nowrap">
      <app-usermenu></app-usermenu>      
    </li>
  </ul>
</nav>

<div class="container-fluid" [ngClass]="{'full-layout': this.vs.layoutFull,'mobile-open': vs.mobileNav,  'no-brand': hasBrandBar  }">
  <div class="row">
    <nav class=" sidebar"  >
      <div class="sidebar-sticky">
        <app-sidebar></app-sidebar>
        <div class="no-brandbar"><button (click)="back($event)" mat-button mat-icon-button ><mat-icon mat-button color="white">arrow_back</mat-icon></button></div>
        <ng-container *ngIf="io.isConnected">
          <ng-container *ngIf="profile | userCan: 'manage company'">                    
            <div *ngIf="($activeSummary | async);let summary" class="section" (click)="activeUsers(summary)" >
              <h4 ><span class="active dot"></span> {{ summary.connections }} Connections</h4>   
            </div>
          </ng-container>
        </ng-container>
      </div>
    </nav>

    <main role="main" id="main" class="ml-sm-auto px-4">     


      <app-team-bar></app-team-bar>
      
      <!-- <nav mat-tab-nav-bar>        
        <span *ngIf="($brands | async); let brands" class="align-self-center">
          <button  mat-button [matMenuTriggerFor]="menu" *ngIf="($activeBrand | async); let brand" class="brand-selector">
            <app-brand-logo format="medium" [brand]="brand"></app-brand-logo>
            {{ brand.name }} <mat-icon>arrow_drop_down</mat-icon>
          </button>
          <mat-menu #menu="matMenu" class="brand-nav">            
            <button mat-menu-item *ngFor="let brand of brands | active"  (click)="selectBrand(brand.id)">
              <app-brand-logo class="mr-2" [brand]="brand"></app-brand-logo> <span class="brand-name">{{ brand.name }}</span>
            </button>
          </mat-menu>
        </span>
        <a 
          mat-tab-link
          class="navigation-link align-self-center"
          *ngFor="let link of navigation"
          [routerLinkActiveOptions]="{ exact: true }"
     	    routerLinkActive #rla="routerLinkActive"
     	    [active]="rla.isActive"
          [routerLink]="link.path"
          >
          <mat-icon *ngIf="link.icon" matListIcon>{{ link.icon }}</mat-icon>&nbsp;{{ link.label }}
        </a>
      </nav> -->

      <div class="dash-content mt-4">
        <router-outlet #route="outlet"></router-outlet>
      </div>
    </main>
  </div>
</div>

</ng-container>

