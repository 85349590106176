<div class="vault-modal">
  <form>
    <h1 mat-dialog-title>Manage Users for {{ data.item.name | capitalize }}</h1>
    <div mat-dialog-content>
      <h3 class="subhead form-group">Add Users to {{ data.item.name }}</h3>
      <div class="row">
        <div class="col-sm-12">       
          <users-select 
            title="Client Team"
            [users]="data.item.users" 
            [context]="data.context" 
            [userList]="data.item" 
            fillrole="client"
            autofill="true"
            [fillbrand]="data.item.id"
            role="client"></users-select>
        </div>
        <div class="col-sm-12">          
          <users-select 
            title="Internal Team"
            [users]="data.item.users" 
            [context]="data.context" 
            [userList]="data.item" 
            autofill="true"
            [fillbrand]="data.item.id"
            fillrole="employee"
            role="employee"></users-select>
        </div>
      </div>
    </div>

    <mat-dialog-actions>
      <button mat-button (click)="close()">Cancel</button>
      <!-- Can optionally provide a result for the closing dialog. -->
      <button color="primary" mat-raised-button (click)="save()">Save</button>
    </mat-dialog-actions>
  </form>
</div>