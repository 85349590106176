<div class="vault-modal" [ngClass]="{'modal-library': isLibraryOpen}">
  <button mat-button mat-button-base class="float-right" (click)="close()">
    <mat-icon class="mat-sm">close</mat-icon>   
  </button>

  <h1 mat-dialog-title>
    <ng-container *ngIf="data?.options?.title; else normalTitle">
      {{ data?.options?.title }}
    </ng-container>
    <ng-template #normalTitle>
      <span *ngIf="!hasNew; else addTitle">Edit</span>
      <ng-template #addTitle>
        Create
      </ng-template>
      {{ subject | capitalize }} {{ data.title }}
    </ng-template>
  </h1>

  <div mat-dialog-content *ngIf="($userProfile | async);let profile">
  <!-- Invoice Tabs -->
    <div *ngIf="data?.options?.view">
      <mat-tab-group>
        <mat-tab label="Invoice" *ngIf="data?.options?.view == 'invoice'">
          <div class="mt-3" *ngIf="data[subject]; let invoice">
            <div class="form-group">
              <span class="label mr-2">Status:</span> <meta-display subject="invoice" [item]="invoice" type="statuses" [id]="invoice.status"></meta-display>
            </div>        
            <div class="form-group">
              <div class="my-4">
                <ng-container *ngIf="profile | userCan: 'view invoice & proposal'">
                  <button class="my-2" *ngIf="invoice.status == 'sent'" mat-raised-button class="mr-2" (click)="paid(invoice)" color="accent"><mat-icon>monetization_on</mat-icon> Mark Paid</button>
                  <button class="my-2" mat-raised-button class="mr-2" (click)="send(invoice)" color="primary"><mat-icon>email</mat-icon> &nbsp;<ng-container *ngIf="invoice.sent_at"> Re-</ng-container>Send</button>          
                </ng-container >
                <button class="my-2" mat-button class="mr-2" (click)="view(invoice)" color="primary">View PDF</button>
                <button class="my-2" mat-button class="mr-2" (click)="download(invoice)" color="primary">Download PDF</button>
              </div>
            </div>

            <div class="form-group" *ngIf="invoice?.phases?.length > 0 || invoice?.projects?.length > 0 ">
              <div class="block-title-label cap-init">Projects associated with Invoice </div>
              <ng-container *ngIf="invoice?.projects?.length > 0" [ngTemplateOutlet]="prjs" [ngTemplateOutletContext]="{projects: invoice.projects}"></ng-container>
              <ng-container *ngIf="invoice?.phases?.length > 0 && invoice?.projects?.length < 1" [ngTemplateOutlet]="prjs" [ngTemplateOutletContext]="{projects: invoice.phases}"></ng-container>
              <ng-template #prjs let-projects='projects'>
                <div class="row p-0  align-items-center m-0 table-text cell" *ngFor="let project of projects">

                  <ng-container *ngIf="invoice.phases.length > 0 && project.project_id">
                    <div class="col-sm-8 cursor" (click)="navTo('/dashboard/project/'+project.project_id)"> 
                      <span *ngIf="project.name">{{ project.name }}</span>
                      <span *ngIf="project.title; else projectTitle">{{ project.title }}</span>
                      <ng-template #projectTitle>{{ invoice.title }}</ng-template>
                    </div>
                    <div class="col-sm-4" >
                      <button mat-button (click)="navTo('/dashboard/project/'+project.project_id)" color="primary">View</button>
                    </div>
                  </ng-container>

                  <!-- THIS IS OUTDATED AND SHOULD BE REMOVED -->
                  <ng-container *ngIf="invoice?.projects?.length > 0 && project.id">
                    <div class="col-sm-8 cursor" (click)="navTo('/dashboard/project/'+project.id)"> 
                      <span *ngIf="project.name">{{ project.name }}</span>
                      <span *ngIf="project.title">{{ project.title }}</span>
                    </div>
                    <div class="col-sm-4" *ngIf="project.id">
                      <button mat-button (click)="navTo('/dashboard/project/'+project.id)" color="primary">View</button>
                    </div>
                  </ng-container>
                  
                </div>
              </ng-template>
            </div>
            
            
            <div class="form-group" *ngIf="profile | userCan: 'view invoice & proposal'">
              <div class="block-title-label cap-init">History for this Invoice</div>
              <div>
                <app-activity-log
                  [ids]="invoiceIDS"
                  type="projectInvoices"
                  exclude=""
                ></app-activity-log>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="View Invoices" *ngIf="data?.options?.view == 'project'">
          <div class="mt-3">
            <div class="form-group">
              <div class="block-title-label">Invoices</div>
              <div *ngFor="let invoice of data[subject]" class="row align-items-center m-0 table-text cell">
                <div class="col-sm-12 cursor click title font-weight-bold" (click)="navTo('/dashboard/invoices')">{{ invoice.title }}</div>                
                <div class="col-sm-2">#
                  <!-- <ng-container *ngIf="!isMultiBrand(invoice)">
                    <ng-container *ngIf="($brandList | async); let brands">
                      <ng-container *ngIf="findBrand(brands,invoice.brand_id); let brand"><span class="uc">{{ brand.prefix }}</span></ng-container>
                    </ng-container>
                  </ng-container> -->
                  {{ invoice.invoice_number }}
                </div>
                <div class="col-sm-5" *ngIf="phaseTotal(invoice,activePID); let phasetotalsval">
                  <span *ngIf="(phasetotalsval.sub); else ptotal">
                    {{ phaseTotal(invoice,activePID)?.type }} {{ phaseTotal(invoice,activePID)?.sub | currency }} of 
                    {{ phaseTotal(invoice,activePID)?.total | currency}}
                  </span>
                  <ng-template #ptotal>{{ phaseTotal(invoice,activePID)?.total | currency }}</ng-template>
                </div>
                <div class="col-sm-2"><span class="dot-label" [ngClass]=" invoice.status">{{ invoice.status }}</span></div>
                <!-- <div class="col-sm-2">{{ phaseTotal(invoice,activePID) }}</div> -->
                <div class="col-sm-3 p-0">                                    
                  <button mat-button (click)="view(invoice)" color="primary">View PDF</button>
                  <button mat-button *ngIf="profile | userCan: 'view invoice & proposal'" (click)="editInvoice(invoice)" color="primary">Edit</button>
                </div>
              </div>
            </div>
            <div class="form-group" *ngIf="profile | userCan: 'view invoice & proposal'">
              <div class="block-title-label cap-init">All Invoice history for this project</div>
              <div>
                <app-activity-log
                  [ids]="invoiceIDS"
                  type="projectInvoices"
                ></app-activity-log>
              </div>
            </div>
          </div>
        </mat-tab>
        <ng-container *ngIf="data?.options?.view == 'invoice'">
        <mat-tab label="Edit Invoices" *ngIf="profile | userCan: 'view invoice & proposal'">
          <div class="mt-3">
            <ng-container 
              *ngIf="form"
              [ngTemplateOutlet]="invoices" 
              [ngTemplateOutletContext]="{formctx: 'form', panelsctx: panels, formref: form}"></ng-container>
          </div>
        </mat-tab>              
        </ng-container>
        <ng-container *ngIf="($proposals | async); let proposals">
        <mat-tab label="Add Invoice" *ngIf="data?.options?.view == 'project' && profile | userCan: 'view invoice & proposal'">
          <div class="mt-3">
            <ng-container 
              [ngTemplateOutlet]="wizardRef" 
              [ngTemplateOutletContext]="{wizardState: wizardState, proposals: proposals, wizardShell: wizardShell, formctx: 'formWizard', panelsctx: panelsWizard, formref: formWizard}"></ng-container>
              <!-- [ngTemplateOutletContext]="{formctx: 'formWizard', panelsctx: panelsWizard, formref: formWizard}"></ng-container> -->
          </div>
        </mat-tab>   
        </ng-container> 
        <mat-tab label="Invoice" *ngIf="data?.options?.view == 'wizard'">
          <div class="mt-3">
            <ng-container 
              [ngTemplateOutlet]="wizardRef" 
              [ngTemplateOutletContext]="{wizardState: wizardState, proposals: [], wizardShell: wizardShell, formctx: 'formWizard', panelsctx: panelsWizard, formref: formWizard}"></ng-container>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>

    <div *ngIf="!data?.options?.view">
      <div class="mt-3">
        <ng-container 
          *ngIf="form"
          [ngTemplateOutlet]="invoices" 
          [ngTemplateOutletContext]="{formctx: 'form', panelsctx: panels, formref: form}"></ng-container>
      </div>
    </div>  

  </div>


<ng-template #wizardRef 
  let-wizardState="wizardState" 
  let-proposals="proposals" 
  let-wizardShell="wizardShell" 
  let-formctx="formctx" 
  let-panelsctx="panelsctx">
  <div >
    <div *ngIf="wizardState == 1">
      <div class="mt-2">
        <button mat-raised-button color="primary" (click)="addInvoice()">Add New Invoice</button>
      </div>

      <div class="py-4">- or -</div>
    </div>

    <div class="d-flex justify-content-between mb-3">
      <label for="" class="subhead">Generate Invoices from Proposals</label>
      <label for="" class="subhead">Step {{ wizardState }} of {{ wizardTotal }}</label>
      
    </div>

    <div  *ngIf="wizardShell.error">{{ wizardShell.error }}</div>
    <!-- <div>
      <div *ngFor="let step of wizardTotal" class="bar" [ngClass="{'active': wizardState > }"]></div>
    </div> -->
    <div *ngIf="wizardState == 1">
      <div class="block-title-label mb-2">Select a Proposal</div>
      <div *ngIf="!proposals.length">No Proposals found on this project to create a invoice from</div>
      <div *ngFor="let proposal of proposals" class="row align-items-center m-0 table-text cell">
        <div class="col-sm-4">{{ proposal.title }}</div>
        <div class="col-sm-2"><span class="dot-label" [ngClass]=" proposal.status">{{ proposal.status }}</span></div>
        <div class="col-sm-2">#{{ proposal.proposal_number }}</div>
        <div class="col-sm-2">{{ proposal.total | currency}}</div>
        <div class="col-sm-2"><button mat-button (click)="wizard(proposal)" color="primary">Start</button></div>
      </div>
    </div>
    <div *ngIf="wizardState == 2 && !wizardShell.error" >
      <div class="block-title-label mb-2">{{ wizardShell.proposal.title }} Proposal > Invoices</div>

      
      <!-- <label for="">Divide Proposal Between:</label> -->
      <div class="mt-3 align-items-center">
        <div>
          <p class="label">Drag the marker below to create multiple invoices:</p>
          <!-- <label  for="numof">Invoices {{ wizardShell.invoicesToCreate }}:</label> -->
        </div>
        <div class="text-center px-2" style="max-width: 400px;">
          <mat-slider
            class="w-100" 
            min="1" 
            max="12" 
            thumbLabel
            step="1" 
            (change)="generateInvoiceList($event)" 
            [(ngModel)]="wizardShell.invoicesToCreate" color="primary">
            <input matSliderThumb name="numof">
          </mat-slider>
        </div>
      </div>

      <div *ngIf="wizardShell.invoicesToCreate > 0" class="mb-4"> Generate {{ wizardShell.invoicesToCreate }} Invoices for: 
        <span  *ngIf="wizardShell.invoices && wizardShell.invoices.length > 0">{{ calcTotal() | currency }} of {{ wizardShell.proposal.total | currency }} 
        <span class="subhead">{{ calcPercent() }}</span></span>
      </div>

      <div class="form-group">
        <mat-form-field class="w-100">
          <input type="text" (change)="generateInvoiceList($event)" placeholder="Inovice Title" name="Inovice Title"  matInput [(ngModel)]="wizardShell.title">
        </mat-form-field>
      </div>

      <div class="my-3">
        <div class="row align-items-center m-0 table-text cell" 
          *ngFor="let invoice of wizardShell.invoices;let idx=index;">
          <div class="col-sm-4">
            <mat-form-field class="w-100">
              <input type="text" required placeholder="Inovice Title" name="Inovice Title" matInput [(ngModel)]="wizardShell.invoices[idx].title">
            </mat-form-field>
          </div>
          <div class="col-sm-3">
            <mat-form-field class="w-100">
              <span matPrefix>$ &nbsp;</span>                    
              <input type="text" placeholder="Total" name="Total" matInput [(ngModel)]="wizardShell.invoices[idx].total" mask="separator.2" thousandSeparator="," required>
            </mat-form-field>
          </div>
          <div class="col-sm-5">
            <mat-form-field class="w-100">
              <input matInput [matDatepicker]="picker" placeholder="Due Date" [(ngModel)]="wizardShell.invoices[idx].due_date">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </div>

      <button 
        mat-raised-button 
        color="primary" 
        *ngIf="wizardShell.gend" 
        [disabled]="+wizardShell.invoicesToCreate < 1" 
        (click)="wizard()">Review Invoices</button>
    </div>

    <div *ngIf="wizardState == 3 && !wizardShell.error">
      <ng-container 
        [ngTemplateOutlet]="invoices" 
        [ngTemplateOutletContext]="{formctx: 'formWizard', panelsctx: panelsWizard, formref: formWizard}"></ng-container>
    </div>

  </div>
</ng-template>

<ng-template #invoices let-formctx="formctx" let-panelsctx="panelsctx">
  <div class="hide-overflow">
    <div class="row">
      <div class="col-sm-12">
        <div class="mat-form-field-wrapper">
          <mat-slide-toggle class="example-margin" [(ngModel)]="isLibraryOpen" color="primary"
            [checked]="isLibraryOpen">
            <span class="subhead"> {{ subject | capitalize }} Library </span>
          </mat-slide-toggle>
        </div>
      </div>
    </div>
    <div class="row m-0">
      <div class="col-sm-4 p-0" *ngIf="isLibraryOpen">
        <app-modal-library-list [subject]="subject" (loadItem)="loadLibrary($event)" [collection]="$invoices">
        </app-modal-library-list>
      </div>

      <div class="col-sm-8" [ngClass]="{'col-sm-12': !isLibraryOpen}">
        <mat-tab-group animationDuration="0ms" [selectedIndex]="modalLibraryService.panelSelected.value"
          (selectedIndexChange)="modalLibraryService.panelSelected.setValue($event)">
          
          <mat-tab *ngFor="let panel of panelsctx;let index = index" [label]="panel">
            <ng-container 
              [ngTemplateOutlet]="subjectRef" 
              [ngTemplateOutletContext]="{parent: this,formIndex:index, formctx: formctx}">
            </ng-container>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>      
</ng-template>

<ng-template #subjectRef let-formIndex='formIndex' let-parent="parent" let-formctx="formctx" let-formref="formref">
  <form  [formGroup]="getForm(formctx)" class="py-2">

    <div *ngIf="!editMultiple">
      <div class="library-controls p-1 pb-2" *ngIf="formIndex > 0">
        <button mat-button mat-button-base color="primary" (click)="cloneFrame(formIndex)">
          <mat-icon class="mat-sm">file_copy</mat-icon> Clone {{ subject }}
        </button>
        <button mat-button mat-button-base class="float-right" (click)="closeFrame(formIndex)">
          <mat-icon class="mat-sm">close</mat-icon>Close
        </button>
      </div>
    </div>

    <div formArrayName="items" class="w-100">
      <div [formGroupName]="formIndex" class="w-100">
        
          <app-alert></app-alert>
          <div class="p-1">

            <!-- form -->            
              <div class="row" *ngIf="($brandList | async); let brands">
                <div class="col-sm-12" *ngIf="data.invoice.user">
                  <div class="form-group">
                    <label class="label">Created By</label>
                    <div>
                      <user-avatar [user]="data.invoice.user" [showName]="true"></user-avatar>
                    </div>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div *ngIf="settings.statuses; let statuses">
                    <mat-form-field class="w-100">
                      <mat-select placeholder="Status" formControlName="status">
                        <mat-option 
                          [disabled]="thisform(formIndex,formctx).controls.id.value == null ? true : false" 
                          *ngFor="let status of statuses"
                          [value]="status.value">
                          {{status.title}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-sm-3" *ngIf="thisform(formIndex,formctx).controls.status.value == 'paid'">
                  <mat-form-field class="w-100">
                    <input matInput [matDatepicker]="picker" placeholder="Paid At" formControlName="paid_at">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error *ngIf="thisform(formIndex,formctx).controls.paid_at.invalid">Enter a valid date</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-sm-3"  *ngIf="thisform(formIndex,formctx).controls.status.value == 'sent'">
                  <mat-form-field class="w-100">
                    <input matInput [matDatepicker]="picker" placeholder="Sent At" formControlName="sent_at">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error *ngIf="thisform(formIndex,formctx).controls.sent_at.invalid">Enter a valid date</mat-error>
                  </mat-form-field>
                </div>
                <!-- <div class="col-sm-12 d-flex align-items-center">
                  <div class="w-100 mat-form-field mb-3" *ngIf="!thisform(formIndex,formctx).controls.project_id.value">
                    <div>
                      <mat-checkbox value="create" color="primary" [(ngModel)]="multipleProjects" [ngModelOptions]="{standalone: true}">
                        <span >Multiple Phases / Project(s)</span>
                      </mat-checkbox>
                    </div>
                    <label class="label">This will allow you to create or associate multiple projects with this single invoice.</label>
                  </div>
                </div> -->
                <!-- <div *ngIf="multipleProjects" class="col-sm-12 d-flex align-items-center">
                  <div class="w-100 mat-form-field  mb-3" *ngIf="!thisform(formIndex,formctx).controls.project_id.value">
                    <div>
                      <mat-checkbox value="create" color="primary" [(ngModel)]="multipleBrands" [ngModelOptions]="{standalone: true}">
                        <span >Multiple Brands</span>
                      </mat-checkbox>
                    </div>
                    <label class="label">This will let you assign different phases / projects to different brands.</label>
                  </div>
                </div> -->
                <div class="col-sm-12">
                  <mat-form-field class="w-100">
                    <input matInput name="title" placeholder="Title" formControlName="title" required>
                  </mat-form-field>
                </div>                                
                <div class="col-sm-6">
                  <mat-form-field class="w-100">                    
                    <input matInput [readonly]="invoiceNumLocked" placeholder="Invoice #" mask="00000"
                      formControlName="invoice_number" required>
                    <button matSuffix mat-icon-button class="mat-sm" *ngIf='invoiceNumLocked' (click)="toggleInvoiceNumber()">
                      <mat-icon style="margin-top: -8px;">lock_open</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
                
              </div>
            
              <div class="row">
                <div class="col-sm-4">
                  <div *ngIf="settings.types; let types">
                    <mat-form-field class="w-100">
                      <mat-select placeholder="Project Type" formControlName="type" required>
                        <mat-option *ngFor="let type of types" [value]="type.value">
                          {{type.title}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-sm-3">
                  <mat-form-field class="w-100">
                    <input matInput [matDatepicker]="picker" placeholder="Due Date" formControlName="due_date">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error *ngIf="thisform(formIndex,formctx).controls.due_date.invalid">Enter a valid date</mat-error>
                  </mat-form-field>
                </div>
            
              </div>
            
              <div class="row">
                <div class="col-sm-3">
                  <mat-form-field class="w-100">
                    <span matPrefix>$ &nbsp;</span>
                    <input matInput placeholder="Total" autocomplete="off" formControlName="total" mask="separator.2"
                      thousandSeparator="," required>
                  </mat-form-field>
                </div>
                <div class="col-sm-9 p-3"
                  *ngIf="parent.data.invoiceService.isOverDue(thisform(formIndex,formctx).controls.due_date.value, thisform(formIndex,formctx).controls.status.value)">
                  <div class="form-group">
                    <mat-slide-toggle formControlName="penalty">
                      <span class="label">Add Penalty
                        <span>+ ( {{ parent.data.invoiceService.penaltyAmount }} % )</span>
                      </span>
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
            
              <!-- <div class="row">
                <div class="col-sm-10 p-3 invoice-total-block form-group">
                  <label class="label">Calculated Total:</label>
                  <span *ngIf="!thisform(formIndex,formctx).controls.penalty.value; else hasPenalty" class="total">
                    {{ thisform(formIndex,formctx).controls.total.value | currency:'USD':true:'1.2-2' }}
                  </span>
                  
                </div>
              </div> -->



              <!-- Phases -->
              <!-- <div *ngIf="formPhases(formIndex,formctx).length < 1">Add or select an existing project</div> -->

              <div class="row m-0">
                <div class="col-sm-12 mx-0 px-0">
                  <mat-accordion class="px-0" #phasesAccordian="matAccordion">
                    <div formArrayName="phases" class="project-item" *ngFor="let phase of formPhases(formIndex,formctx); let i = index">
                      <div [formGroupName]="i" class=" w-100">
                        <!-- HACK -->                
                        <mat-expansion-panel class="project-item-panel" [expanded]="true" (opened)="true">
                          <mat-expansion-panel-header >
                            <mat-panel-title >
                              <span *ngIf="formPhase(formIndex,i,formctx).controls.title.value; let ptitle; else default"> {{ ptitle }}</span>
                              <ng-template #default ><span *ngIf="formPhases(formIndex,formctx).length > 1">Phase: {{ i+1 }}</span></ng-template>
                            </mat-panel-title>
                            <mat-panel-description>
                              <span *ngIf="formPhase(formIndex,i,formctx).controls.title.value && formPhase(formIndex,i,formctx).controls.total.value">{{ formPhase(formIndex,i,formctx).controls.total.value | currency:'USD':true:'1.2-2' }}</span>
                            </mat-panel-description>
                          </mat-expansion-panel-header>                        
                          <div class="form-row" >
                            
                            <div class="row w-100" *ngIf="($brandList | async); let brands">
                              <div class="col-sm-12 text-right">
                                <mat-checkbox value="create" color="primary" formControlName="showOptions" >
                                  <span class="label">Show More Options</span>
                                </mat-checkbox>
                              </div>           
                              <div class="col-sm-12" *ngIf="formPhase(formIndex,i,formctx).controls.showOptions.value">
                                <mat-form-field class="w-100"  >
                                  <mat-select placeholder="Brand" formControlName="brand_id">
                                    <mat-option value="null">- None -</mat-option>
                                    <mat-option *ngFor="let brand of brands" [value]="brand.id">
                                      <app-brand-logo [brand]="brand"></app-brand-logo> &nbsp; <span>{{brand.name}}</span>
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>
                              <div class="col-sm-8" *ngIf="formPhase(formIndex,i,formctx).controls.showOptions.value || formPhase(formIndex,i,formctx).controls.id.value == null">
                                <label class="label" for="">Attach a existing project to invoice for tracking project payments</label>
                                <div *ngIf="formPhase(formIndex,i,formctx).controls.brand_id.value;let bid">
                                  <div *ngIf="findBrand(brands,bid); let brand">                                    
                                    <mat-form-field class="w-100" *ngIf="brand.projects.length">
                                      <mat-select (selectionChange)="addedExistingProject($event.value, formIndex,i,formctx, brand )" placeholder="Project" formControlName="project_id">
                                        <mat-option *ngFor="let project of brand.projects" [value]="project.id">
                                          {{project.name}}
                                        </mat-option>
                                      </mat-select>                                      
                                    </mat-form-field>                                    
                                  </div>
                                </div>
                              </div>       
                                        
                              <div class="col-sm-12" *ngIf="formPhase(formIndex,i,formctx).controls.showOptions.value || !formPhase(formIndex,i,formctx).controls.project_id.value">
                                <mat-form-field class="w-100">
                                  <input matInput name="title"    placeholder="Phase Title ( optinal )" formControlName="title" >
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                          <div class="form-row mt-2" >
                            <div class="col-sm-4" >
                              <div *ngIf="settings.types; let types">
                                <mat-form-field class="w-100">
                                  <mat-select placeholder="Project Type" formControlName="type" >
                                    <mat-option *ngFor="let type of types" [value]="type.value">
                                      {{type.title}}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>
                            </div>
                            <div class="col-sm-1"></div>
                            <div class="col-sm-2">
                              <mat-form-field class="w-100">
                                <span matPrefix>$ &nbsp;</span>
                                <input matInput (input)="getTotalPhases(formIndex,i,formctx, this)" placeholder="Total" autocomplete="off" formControlName="total" mask="separator.2"
                                  thousandSeparator="," >
                              </mat-form-field>
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="col-sm-10" >                
                              <div class="form-group">
                                <div class="d-flex justify-content-between align-items-bottom">                                  
                                  <label class="label mb-0">Overview</label> 
                                  <button  mat-button mat-icon-button matTooltip="Robot Writter"  (click)="prompt(formPhase(formIndex,i,formctx),'content')"><mat-icon color="primary">smart_toy</mat-icon></button>
                                </div>
                                
                                <ckeditor [editor]="Editor" formControlName="content" ></ckeditor>
                              </div>
                            </div>
                            <div class="col-sm-10">
                              <div class="form-group">
                                <div class="d-flex justify-content-between align-items-bottom">                                                                    
                                  <label class="label mb-0">Deliverables</label> 
                                  <button  mat-button mat-icon-button matTooltip="Robot Writter"  (click)="prompt(formPhase(formIndex,i,formctx),'deliverables')"><mat-icon color="primary">smart_toy</mat-icon></button>
                                </div>
                                
                                <ckeditor [editor]="Editor" formControlName="deliverables" ></ckeditor>
                              </div>                
                            </div>
                          </div>
                          <!-- SAVED PHASE -->
                          <div *ngIf="formPhase(formIndex,i,formctx).controls.id.value; let phaseid">
                            <button color="danger" mat-raised-button (click)="deletePhase(phaseid,formIndex,i,formctx)">Delete Phase</button>
                          </div>
                          <div *ngIf="formPhases(formIndex,formctx).length > 1 && !formPhase(formIndex,i,formctx).controls.id.value">
                            <button color="danger" mat-raised-button (click)="removePhase(formIndex,i,formctx)">Remove Phase</button>
                          </div>                      
                        </mat-expansion-panel>                      
    
                      </div>
                      <span *ngIf="getTotalPhases | Fn : formIndex : i : formctx : this"></span>
                    </div> 
                  </mat-accordion>
    
                  
    
                  <div class="my-3" >
                    <p> Add a new or existing Project / Phase</p>
                    <button class="mr-1" mat-stroked-button color="primary" (click)="addPhase(formIndex,formctx)">
                      <mat-icon>add</mat-icon> Add 
                    </button>    
                       
                  </div>
    
                  <div class="py-3 mb-2">
                    <div class="hr"></div>
                  </div>
    
                  <div class="row m-0">
                    <label class="col-sm-2 label" matTooltip="This is the total that the client will see">Total:</label>
                    <strong><span class="col-sm-2 total"> {{ thisform(formIndex, formctx).controls.total.value | currency:'USD':true:'1.2-2' }}</span></strong>
                    <span *ngIf="previewTotal && previewTotal > 0" class="ml-1"> of ({{ previewTotal | currency:'USD':true:'1.2-2' }})</span>
                    <ng-template #hasPenalty>
                      <span
                        class="total">&nbsp;{{ (thisform(formIndex,formctx).controls.total.value * parent.data.invoiceService.penaltyAmount) | currency:'USD':true:'1.2-2' }}</span>
                    </ng-template>
                  </div>

                  <div class="mt-3" *ngIf="wizardState == 3">
                    <button mat-button (click)="close()" >Cancel</button>
                    <button 
                      color="primary" 
                      *ngIf="!thisform(formIndex,formctx).disabled" 
                      [disabled]="thisform(formIndex,formctx).invalid"
                      mat-raised-button 
                      (click)="saveAll(formctx)">Generate All</button>
                  </div>
    
                </div>
              </div>

              
            
              <!-- <div class="row">
                <div class="col-sm-10">
                  <div class="form-group">
                    <label class="label">Description</label>
                    <ckeditor [editor]="Editor" formControlName="description"></ckeditor>
                  </div>
                </div>
              </div>
            
              <div class="row">
                <div class="col-sm-10">
                  <div class="form-group">
                    <label class="label">Overview</label>
                    <ckeditor [editor]="Editor" formControlName="content"></ckeditor>
                  </div>
                </div>
              </div>
            
              <div class="row">
                <div class="col-sm-10">
                  <div class="form-group">
                    <label class="label">Deliverables</label>
                    <ckeditor [editor]="Editor" formControlName="deliverables"></ckeditor>
                  </div>
                </div>
              </div> -->
            
            <!-- end form -->

          </div>
        
        <mat-dialog-actions class="mb-0" *ngIf="editMultiple || formIndex == 0">
          <button mat-button (click)="close()" *ngIf="wizardState !== 3">Cancel</button>
          <button 
            color="primary" 
            *ngIf="!thisform(formIndex,formctx).disabled && wizardState < 2" 
            [disabled]="thisform(formIndex,formctx).invalid"
            mat-raised-button 
            (click)="save(formIndex)"><span *ngIf="!hasNew; else addTitle">Save</span>
            <ng-template #addTitle>
              Create
            </ng-template></button>
          
        </mat-dialog-actions>
      </div>
    </div>
  </form>
</ng-template>
            






<!-- <div class="vault-modal">
  <form [formGroup]="form">
    <h1 mat-dialog-title>
      <span *ngIf="data.invoice.id; else addTitle">Edit</span>      
      <ng-template #addTitle>
        Create
      </ng-template>
      Invoice {{ data.title }}
    </h1>

    <div mat-dialog-content>

      <app-alert></app-alert>

      <div class="row">
        <div class="col-sm-12" *ngIf="data.invoice.user">
          <div class="form-group">
            <label class="label">Created By</label>
            <div><user-avatar [user]="data.invoice.user" [showName]="true"></user-avatar></div>
          </div>
        </div>
        <div class="col-sm-3">
          <mat-form-field class="w-100">
            <input matInput [readonly]="invoiceNumLocked" placeholder="Invoice #" mask="0000" formControlName="invoice_number" required>            
            <button matSuffix mat-icon-button class="mat-sm" *ngIf='invoiceNumLocked' (click)="toggleInvoiceNumber()">
              <mat-icon style="margin-top: -8px;">lock_open</mat-icon>
            </button>
          </mat-form-field>          
        </div>
        <div class="col-sm-3">
          <div *ngIf="settings.statuses; let statuses">            
            <mat-form-field class="w-100">
              <mat-select placeholder="Status" formControlName="status">
                <mat-option [disabled]="data.invoice.id == null ? true : false" *ngFor="let status of statuses" [value]="status.value">
                  {{status.title}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="($brandList | async); let brands">
        <div class="col-sm-4">
          <mat-form-field class="w-100">
            <mat-select placeholder="Brand" formControlName="brand_id">
              <mat-option value="null">- None -</mat-option>
              <mat-option *ngFor="let brand of brands" [value]="brand.id" >
                <app-brand-logo [brand]="brand"></app-brand-logo> &nbsp; <span>{{brand.name}}</span>
              </mat-option>
            </mat-select>            
          </mat-form-field>
        </div>
        <div class="col-sm-6">          
          <div *ngIf="findBrand(brands,form.controls.brand_id.value); let brand">
            <mat-form-field class="w-100" *ngIf="brand.projects.length">
              <mat-select placeholder="Project" formControlName="project_id">
                <mat-option *ngFor="let project of brand.projects" [value]="project.id">
                  {{project.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>      

      <div class="row">
        <div class="col-sm-6">
          <mat-form-field class="w-100">
            <input matInput name="title" placeholder="Title" formControlName="title" required>
          </mat-form-field>
        </div>        
      </div>

      <div class="row">        
        <div class="col-sm-4">
          <div *ngIf="settings.types; let types">
            <mat-form-field class="w-100">
              <mat-select placeholder="Project Type" formControlName="type" required>
                <mat-option *ngFor="let type of types" [value]="type.value">
                  {{type.title}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm-3">
          <mat-form-field class="w-100">
            <input matInput [matDatepicker]="picker" placeholder="Due Date" formControlName="due_date">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="form.controls.due_date.invalid">Enter a valid date</mat-error>
          </mat-form-field>          
        </div>
        
      </div>
      
      <div class="row">
        <div class="col-sm-3">
          <mat-form-field class="w-100">
            <span matPrefix>$ &nbsp;</span>
            <input matInput placeholder="Total" autocomplete="off" formControlName="total" mask="separator.2" thousandSeparator="," required>
          </mat-form-field>
        </div>
        <div class="col-sm-9 p-3" *ngIf="data.invoiceService.isOverDue(form.controls.due_date.value, form.controls.status.value)">
          <div class="form-group">
            <mat-slide-toggle formControlName="penalty">
              <span class="label">Add Penalty 
                <span>+ ( {{ data.invoiceService.penaltyAmount }} % )</span>
              </span>
            </mat-slide-toggle>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-10 p-3 invoice-total-block form-group">
          <label class="label">Calculated Total:</label>
          <span *ngIf="!form.controls.penalty.value; else hasPenalty" class="total">
            {{ form.controls.total.value | currency:'USD':true:'1.2-2' }}
          </span>
          <ng-template #hasPenalty >
            <span class="total">&nbsp;{{ (form.controls.total.value * data.invoiceService.penaltyAmount) | currency:'USD':true:'1.2-2' }}</span>
          </ng-template>          
        </div>
      </div>
      
      <div class="row">
        <div class="col-sm-10">
          <div class="form-group">
            <label class="label">Description</label>
            <ckeditor [editor]="Editor" formControlName="description"></ckeditor>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-10">
          <div class="form-group">
            <label class="label">Content</label>
            <ckeditor [editor]="Editor" formControlName="content"></ckeditor>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-10">
          <div class="form-group">
            <label class="label">Deliverables</label>
            <ckeditor [editor]="Editor" formControlName="deliverables"></ckeditor>
          </div>
        </div>
      </div>

    </div>

    <mat-dialog-actions>
      <button mat-button (click)="close()">Cancel</button>      
      <button color="primary" [disabled]="form.invalid" mat-raised-button (click)="save()">Save</button>
    </mat-dialog-actions>
  </form>
</div> -->