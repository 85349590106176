<ng-container *ngIf="!findUser">  
<span 
  class="av-wrap" 
  *ngIf="(user && user.photo); else defaultAvatar" 
    matTooltip="{{ toolTipDisplay }}"
    matTooltipPosition="right"
    [ngClass]="{'mr-3':showName}">
  <img    
    class="avatar {{ class }}" 
    [src]="cdn+user.photo" 
    [ngClass]="{'has-name': showName}"
    matTooltip="{{ (showName) ? null : user.name + '\n' + user.email }}"
    matTooltipPosition="right" 
    />
    <div class="pl-2" *ngIf="showName || showFullName || position || phone">
      <div *ngIf="showName" class="avatar-name">{{ user.name }}</div>
      <div *ngIf="showFullName" class="avatar-name">{{ user.name }} {{ user.last_name }}</div>    
      <div *ngIf="position && user.position" class="label">{{ user.position }}</div>
      <div *ngIf="phone && user.phone" class="label"><a target="_blank" href="tel:{{user.phone}}">{{ user.phone | mask: '(000) 000-0000' }}</a></div>
    </div>
</span>

<ng-template #defaultAvatar>
<span 
    class="av-wrap" 
    matTooltip="{{ toolTipDisplay }}"
    matTooltipPosition="right"
    *ngIf="user">
  <span 
    [ngStyle]="{'background-color':color(user,+145),'color':color(user)}" 
    class="avatar none {{ class }}"
    [ngClass]="{'has-name': showName}"
    matTooltip="{{ (showName) ? null : user.name + '\n' + user.email }}"
    matTooltipPosition="right">
      {{ initials(user) }}
    </span> 
    <div class="pl-2" *ngIf="showName || showFullName || position || phone">
      <div *ngIf="showName" class="avatar-name">{{ user.name }}</div>
      <div *ngIf="showFullName" class="avatar-name">{{ user.name }} {{ user.last_name }}</div>    
      <div *ngIf="position && user.position" class="label">{{ user.position }}</div>
      <div *ngIf="phone && user.phone" class="label"><a target="_blank" href="tel:{{user.phone}}">{{ user.phone | mask: '(000) 000-0000' }}</a></div>
    </div>
    
</span>
</ng-template>
</ng-container>
