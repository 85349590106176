<div class="vault-modal">
  
  <h1 mat-dialog-title>Edit {{subject | capitalize }} </h1>

  <div mat-dialog-content class="form-group">
    <img [src]="thumb(data.asset)" />
    <p>File downloaded {{ data.asset.entropy }} times</p>
    <div [formGroup]="form">
      <div class="row">
        <div class="col-sm-10">
          <mat-form-field class="w-100">
            <input matInput name="title" placeholder="Asset Title" formControlName="title" required >
          </mat-form-field>
        </div>
        <div class="col-sm-10">
          <div class="py-3">
            <app-tags [subject]="data.asset" [tags]="data.asset.tags"></app-tags>
          </div>
        </div>
      </div>
    </div>        
  </div>

  <mat-dialog-actions>
    <button mat-button (click)="close()">Cancel</button>
    <button color="primary" [disabled]="form.invalid" mat-raised-button (click)="save()">Save</button>
    <button *ngIf="data[subject].id" mat-button mat color="warn" (click)="delete()">Delete</button>
  </mat-dialog-actions>
</div>