import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from '../../_services/user.service';
import { UserProfileModel } from "../../_models/subjects";
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { GoogleApiService } from "../../_services/google-api.service";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  form: UntypedFormGroup;
  error: any = false;
  $userProfile: Observable<UserProfileModel>;
  gApiLoggedIn = false;
  
  constructor(
    private userService: UserService,
    fb: UntypedFormBuilder,
    public gapi: GoogleApiService,
  ) {
    this.form = fb.group({
      name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required]],
      photo: [''],
      headshot: [''],
      contractor_business_name: [''],
      contractor_logo: [''],
      phone: [''],
      dob: [''],
      position: [''],
      addresses: fb.array([]),
    });
    //this.$userProfile = this.userService.profile;    
  }

  submit(){    
    if (this.form.valid) {      
      this.form.value.addresses.forEach((address) => {        
        address.is_primary = true;
        address.is_billing = true;
      });
      this.userService.update(this.form.value,'user').pipe(first()).subscribe(data=>{console.log(data)});
      //this.userService.loadUsersAssignedTo();
    }
  }

  profileImage(imagePath,field){
    let data = {};
    data[field] = imagePath;
    this.form.patchValue(data);
  }

  ngOnInit() {    
    this.$userProfile = this.userService.userProfile();
    this.$userProfile.subscribe( profile => {
        this.form.patchValue(profile.user)
    });
    //this.gapi.getContacts();
  }

  changeName(user){
    return user.name + "hello" + user.last_namae;
  }

}
