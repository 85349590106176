import { Component, OnInit, Input, ViewChild, KeyValueDiffers, ElementRef } from '@angular/core';
import { ChartDataSets } from "chart.js";
import { BaseChartDirective, Label } from "ng2-charts";
import moment from 'moment';
import { CurrencyPipe } from '@angular/common';
import { ProjectModel, RoundModel, Settings } from 'src/app/_models/subjects';
import { VelvetysoftService } from 'src/app/_services/velvetysoft.service';

@Component({
  selector: "app-charts",
  templateUrl: "./charts.component.html",
  styleUrls: ["./charts.component.scss"],
  providers: [CurrencyPipe]
})
export class ChartsComponent implements OnInit {
  @Input() data: {project: ProjectModel, rounds: Array<RoundModel> } | any;
  @Input() type: string;
  @Input() options;
  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;
  @Input() format: string = 'currency';
  @ViewChild('myChart') myChart!: ElementRef<HTMLCanvasElement>;
  gradientChart: CanvasRenderingContext2D | null = null;
  plugins = '';
  _data = null;  
  propertySelected: string = 'type';
  chartData: ChartDataSets[] = [];
  labels: Label[] = [];
  _options = {
    responsive: true,
    legend: {
      position: "right",
    },
  };
  error = null;
  //colors = ["#fd397a", "#1dc9b7", "#673ab7", "#39c7fd", "#ffb822", "#6610f2"];
  colors = ["#1276a3", "#ed4f7b", "#f26522", "#593494", "#61c5b4", "#aad268"];
  legend = true;
  lineChartPlugins = [];
  chartType = "doughnut";
  last: any = null;
  differ: any;
  workTypes: Settings;
  
  //private cp: CurrencyPipe
  constructor(
    private currencyPipe:CurrencyPipe, 
    private differs: KeyValueDiffers,
    private vs: VelvetysoftService
  ) {    
    this.differ = this.differs.find({}).create();
    let workTypes = this.vs.getSettings('jobs');
    let internalTypes = this.vs.getSettings('internaljobs');
    this.workTypes = {
      types: [...workTypes.types, ...internalTypes.types]
    }
  }

  run(){
    

    this.setTypes();
    var self = this;
    var _data = null;  

    //SETUP REQUIRED FIELDS FOR EACH

    if (this.propertySelected == 'total'){      
        _data = this.countByMonth(this.data);      
        this.chartData[0] = _data;
    }else if (this.type == 'projectGoalZone'){
      
      //SPECIAL PROJECT CHART
      _data = this.projectGoalZone(this.data);      
      this.chartData = _data;      
    }else if (this.type == 'projectProjectionsTotal'){
      _data = this.projectProjectionsTotal(this.data)
      this.chartData = _data; 
    }else if (this.type == 'weekly'){
      _data = this.projectHours(this.data)
      this.chartData = _data; 
    }else if (this.type == 'revenue'){
      _data = this.revenue(this.data);
      this.chartData = _data;
    }else if (this.type == 'revenue_brand'){
      _data = this.revenue_brand(this.data);
      this.chartData = _data;
    }else if (this.type == 'brands'){
      _data = this.brandHours(this.data)
      this.chartData[0] = _data; 
    }else if(this.type == 'keyvalue'){
      this.chartData[0] = this.keyval(this.data);      
    }else{      
        _data = this.count(this.data, function (item) {
          return item[self.propertySelected];
        });     
        this.chartData[0] = _data;
    }
  }

  ngDoCheck(){
    var changes = this.differ.diff(this.data);
    if(!changes) return true;
    this.run()
  }

  
  ngOnInit() {       
    this._options = { ...this._options, ...this.options };      
    
  }

  ngAfterViewInit(): void {
    this.gradientChart = this.myChart.nativeElement.getContext('2d');

    
    this.run();
  }

  setTypes(){
    
    if (this.type == "bar") this.chartType = "bar";
    if (this.type == "pie") this.chartType = "pie";
    if (this.type == "piehole" || this.type == 'projectProjections') this.chartType = "doughnut";
    if (this.type == 'projectProjectionsTotal') this.chartType = "horizontalBar";
    if (this.type == 'weekly') this.chartType = "bar";
    if (this.type == 'brands') this.chartType = "bar"; 
    if (this.type == 'revenue') this.chartType = "line"; 
    if (this.type == 'revenue_brand') this.chartType = "bar"; 
    if (this.type == "bar-stats") {
      this.chartType = "bar";
      this.propertySelected = "total";      
    }
    if (this.type == "projectGoalZone"){
      this.chartType = "line";      
    }
    if (this.type == "projectNew"){
      this.chartType = "line";      
    }
  }

  refresh(){
    this.chart.update();
  }

  revenue_brand(data){
    
    this.labels = [];
    let revenue = []
    let colors = [];
    let brdcolors = [];
    //for labels get the brand name
    //get the year in javascript
    let year = new Date().getFullYear();
    for(let key in data){
      let brand = data[key];
      let _rev = (brand.revenue[year]?.total) ? brand.revenue[year]?.total : 0;
      if(_rev == 0) continue;
      //check if the hex color is to light
      //let color = (brand.color == '#ffffff') ? '#000000' : brand.color;    
      colors.push('#522d6d');      

      this.labels.push(brand.name);      
      revenue.push(_rev);      
      
      //brdcolors.push('#000');
    }

    //calculate the total revenue
    //let total = revenue.reduce((a,b) => a+b,0);
    //console.log(total)

    let chartData = {
      data: revenue,
      label: `Revenue ${year}`,
      backgroundColor: colors,
      //borderColor: 'rgba(0, 0, 0, 0.5)',
      //borderWidth: 1,
      //categoryPercentage: 1.1,
      //backgroundColor: this.colors,
    }   
    //console.log(this.options)
    return [chartData];
    
  }

  revenue(data){
    this.labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    let res = [];
    this.options = this._options;
    
    for(const key in data){
      let range = data[key];

      if(this.gradientChart){        
          // Create gradient
          var gradient = this.gradientChart.createLinearGradient(0, 0, 0, 400);
          let color = this.vs.hexToRgb(range.bgcolor);
          //console.log(color,range.bgcolor)
          gradient.addColorStop(0, 'rgba('+color+', 0.4)');   // Red at the top
          gradient.addColorStop(0.5, 'rgba('+color+', 0)');   // Blue at the bottom        
      }

      let bgColor = gradient || range.bgcolor;
      let chartDataCurrent = {
        data: range.data.map( item => {
          return item.total;
        }),
        label: range.label,          
        pointBackgroundColor: range.brdcolor,
        borderWidth: 2,
        pointBorderColor: 'rgba(255, 0, 0, 0)',
        backgroundColor: bgColor,
        borderColor: range.brdcolor,
        pointRadius: 3
      };

      res.push(chartDataCurrent);
    }
    
    this.options.scales.yAxes = this.options.scales.yAxes || [];
    this.options.scales.xAxes = this.options.scales.xAxes || [];
    
    
    this.options.scales.yAxes[0] = {
      ticks: {
          beginAtZero: true,
          //change the font to be a smaller size
          fontSize: 10,          
      },
      gridLines: {
        color: "rgba(0, 0, 0, 0)",
        drawOnChartArea: false
      },
      //money format      
      
    }

    this.options.tooltips = {
      callbacks: {
        title: function(t, d) {
            return t[0].xLabel;
        },
        label: function(t,d){          
          return '$'+parseInt(t.yLabel).toLocaleString();
          
        }

      }
    }
    
    this.options.scales.xAxes[0].gridLines = {
      drawOnChartArea: false,
      color: "rgba(0, 0, 0, 0)",
    };
    
    this.options.legend.position = 'bottom';
    return res;
  }


  keyval(obj){
    
    this.labels = Object.keys(obj);
    
    //@ts-ignore
    let data = Object.values(obj); 
    //this.labels = [];    
    let chartData = {
      data: data,
      label: "chart",
      backgroundColor: this.colors,
    };
    this.options = this._options;
    
    this.labels.map( (label,key) =>{
      const color = this.vs.findRGB(label)
      if(color) chartData.backgroundColor[key] = color;
    })
    this.labels = this.labels.map( label => {
      let res = this.workTypes.types.find( type => type.value == label)?.title 
      return (res) ? res : `"${label}"`;
    });
    //console.log(this.format)
    if(this.format == 'currency'){
      this.options.tooltips = {
        callbacks: {
          title: function(t, d) {
              return d.labels[t[0].index];
          },
          label: function(t,d){
            return '$'+parseInt(d.datasets[0].data[t.index]).toLocaleString();
          }

        }
      }
    }
    
    
    return chartData;
  }

  count = function (ary, classifier) {
    classifier = classifier || String;

    let collection = ary.reduce(function (counter, item) {
      var p = classifier(item);
      counter[p] = counter.hasOwnProperty(p) ? counter[p] + 1 : 1;
      return counter;
    }, {});

    this.labels = [];
    let chartData = {
      data: [],
      label: "chart",
      backgroundColor: this.colors,
    };

    Object.keys(collection).forEach((label,key) => {
      const color = this.vs.findRGB(label)
      if(color) chartData.backgroundColor[key] = color;
      chartData.data.push(collection[label]);
      this.labels.push(label.charAt(0).toUpperCase() + label.slice(1));
    });
    return chartData;
  };

  
  brandHours(_data){
    //@TODO: FIX THIS company color is hard coded
    let data = _data.chartData;
    this.labels = Object.keys(data)
    this.colors = Object.keys(data).map( key => {
      if(key == '(Us)'){
        return '#522d6e';
      }else{
        let color = _data.brands.find( brand => brand.prefix == key)?.color;
        //console.log(color)

        return (color == '#ffffff') ? '#f2f2f2' : color;
      }      
    });
    //console.log(data)
    let chartData = {
      data: Object.values(data),
      label: 'hours',
      categoryPercentage: 1.1,
      backgroundColor: this.colors,
    }   
    this.options = this._options;
    //this.options.categoryPercentage = 1.0, // here 
    //this.options.barPercentage = 0.68,  // here
    this.options.legend.display = false;
    this.options.scales = {
        xAxes: [{
            categoryPercentage: 0.9,
            barPercentage: 0.8,
            gridLines: {
                display:false
            },
            // ticks: {              
            //   beginAtZero: true,
            //   callback: function (value, index, values) {
            //     return '$'+value.toLocaleString()
            //   }
            // }
        }],
        yAxes: [{
            gridLines: {
                display:false
            }   
        }],        
    }
    //console.log(this.options)
    // this.options.scales.yAxes = this.options.scales.yAxes || [];
    // this.options.scales.yAxes[0] = {
    //   gridLines: {
    //     color: "rgba(0, 0, 0, 0)",
    //     drawOnChartArea: false
    //   },
      
    // }
    
    // this.options.scales.xAxes[0].gridLines = {
    //   drawOnChartArea: false,
    //   color: "rgba(0, 0, 0, 0)",
    // }

    return chartData;
    //return null;
  }

  //BURN RATE
  projectHours(data){
    //this.colors = ['#522d6e','#f5a623'];
    let daysRange = moment(data.project.kickoff).clone().startOf('month').add(-1,'month')
    let lastRange = data.data.dates[data.data.dates.length-1];
    
    let range = [];
    let i = 0;
    
    let datasets = []
    var datasetIndex = 0;
    let totals = {};

    for(const uid in data.data.userMap){
      data.data.hours.forEach( (ary,i) => {
        let _hours = ary[uid];        
        if(_hours){
          if(!totals[uid]) totals[uid] = 0;
          totals[uid] += _hours;
        }
      });
    }

    for(const uid in data.data.userMap){

      //HOURS FOR EACH USER
      const user = data.data.userMap[uid];
      let hours = {
        data: [],
        label: `${user} Hours (${totals[uid]})`,
        categoryPercentage: 1,
        backgroundColor: this.colors[datasetIndex],
      }   

      // total the data.data.hours by key
      
      

      //GET HOURS FOR EACH MONTH for this user
      data.data.dates.forEach(function(_date,j){
        let _hours = data.data.hours[j][uid];
        if(_hours)  hours.data.push({ 
          x: moment(_date).format('MMM YYYY'),
          y: _hours//data.data.hours[i]
        })
        
      });
      

      datasets.push(hours)
      datasetIndex++;
    }

    //console.log(datasets,'the hours');

    this.options.scales.yAxes = this.options.scales.yAxes || [];
    this.options.scales.yAxes[0] = {
      ticks: {
          beginAtZero: true
      },
      gridLines: {
        color: "rgba(0, 0, 0, 0)",
        drawOnChartArea: false
      },
      
    }
    
    this.options.scales.xAxes[0].gridLines = {
      drawOnChartArea: false,
      color: "rgba(0, 0, 0, 0)",
    }
    this.options.scales.xAxes[0].offset = true;
    this.options.scales.xAxes[0].time.unit = "month";
    
    
    //console.log(datasets)
    return datasets;
  }

  projectProjectionsTotal(data){
    if(!data) return this.error = "No Proposal Attached";
    //if(data.forecast == "0" || data.forecast == 0) return this.error = "Add a proposal to track the project budget.";
    
    let $total = this.currencyPipe.transform(data.forecast, '$');
    let $spent = this.currencyPipe.transform(data.spent, '$');
    this.labels = ['Budget','Agency Fees', 'Expenses', 'Invoiced'];// null;//['Proposal1','Spent2'];

    //FN - Active:  total sperated 12,320.64 - add to FN Expense ( make percent bigger as header )
    //console.log(data)
    //EXCEPTIOn
    let chartData = {
      data: [data.forecast,data.spent, data.expenses, data.invoiced], //data.invoiced
      backgroundColor: [this.vs.findRGB('draft'),this.vs.findRGB('sent'),'#fd397a',this.vs.findRGB('complete')],
    };

    this.options = this._options;
    this.options.legend.display = false;
    this.options.scales = {
        xAxes: [{
            gridLines: {
                display:false
            },
            ticks: {              
              beginAtZero: true,
              callback: function (value, index, values) {
                return '$'+value.toLocaleString()
              }
            }
        }],
        yAxes: [{
            gridLines: {
                display:false
            }   
        }],        
    }
    this.options.tooltips = {
      callbacks: {
         title: function(t, d) {
            return d.labels[t[0].index];
         },
         label: function(a,b){
          return '$'+parseInt(a.value).toLocaleString()
         }

      }
   }

  
    // let chartData = {
    //   data: data.total,data.spent],
    //   backgroundColor: ['#1dc9b7','#fd397a'],
    //   label: 'Proposal'
    // };
    // this.options = { legend: { display: false, position: "right", labels: { fontColor: "#fff" } }, 
    //   scales:{
    //     xAxes: [{
    //       gridLines: {
    //         drawOnChartArea: false
    //       },
    //       ticks: {
    //         fontColor: 'rgba(255,255,255,0.7)',
    //       }
    //     }],
    //     yAxes: [{
    //       gridLines: {
    //         drawOnChartArea: false
    //       },
    //       ticks: {
    //         fontColor: 'rgba(255,255,255,0.7)',
    //         beginAtZero: true,
    //         callback: function (value, index, values) {
    //           return '$ ' + value;
    //         }
    //       }
    //     }]
    //   } 
    // };      
    return [chartData];
  }

  projectGoalZone(obj) {
    //CHART SETTINGs
    this.labels = [];
    let chartDataGoal = {
      data: [],
      label: "Goal",
      lineTension: 0,
      pointRadius: 0,
      backgroundColor: ['rgba(29, 201, 183, 0.05)'],
      borderColor: 'rgba(29, 201, 183,0)'
    }
    let chartDataGoalExt = null;
    let chartDataRounds = {
      data: [],
      label: "Rounds",
      pointBorderColor: '#673AB7', 
      pointBackgroundColor: ['#673AB7'],
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: ['#673AB7']
    };

    //DRAW GOAL
    const start = obj.project.kickoff;

    chartDataGoal.data.push({
      x: start,
      y: obj.project.est_round
    })
    chartDataGoal.data.push({
      x: obj.project.due,
      y: obj.project.est_round
    })
    chartDataGoal.data.push({
      x: obj.project.due,
      y: 0
    })

    //DRAW EXT GOAL if date is pushed
    if(obj.project.past_due_dates && obj.project.past_due_dates.length > 0){
      chartDataGoalExt = {
        data: [],
        label: "Goal Ext",
        borderDash: [10,5],
        pointRadius: 0,
        lineTension: 0,
        backgroundColor: ['rgba(248, 231, 232,0)'],
        borderColor: ['rgba(29, 201, 183,1)']//['#1dc9b7'], //#1dc9b7
      }
      
      let lastExt = obj.project.past_due_dates[obj.project.past_due_dates.length - 1];      
      chartDataGoalExt.data.push({
        x: lastExt,
        y: 0
      })
      chartDataGoalExt.data.push({
        x: lastExt,
        y: obj.project.est_round
      })
      chartDataGoalExt.data.push({
        x: obj.project.due,
        y: obj.project.est_round
      })
      chartDataGoalExt.data.push({
        x: obj.project.due,
        y: 0
      })
      
    }
    


    //DRAW ROunds  
    let r = 1;
    chartDataRounds.data.push({
      x: start,
      y: 0
    });
    obj.rounds.map( round =>{
      if(!round.due_date) return;
      let clr = (round.due_date < obj.project.due) ? '#673AB7' : '#ff6384'
      chartDataRounds.pointBackgroundColor.push(clr);
      chartDataRounds.data.push({
        x: round.due_date,
        y: r
      });
      r++;   
    });

    //MORE SETTINGS
    this.options.scales.yAxes = this.options.scales.yAxes || [];
    this.options.scales.yAxes[0] = {
      gridLines: {
        color: "rgba(0, 0, 0, 0)",
        drawOnChartArea: false
      },
      ticks: {
        suggestedMax: obj.project.est_round+2
      }
    }
    this.options.scales.xAxes[0].time = { 
      suggestedMax: moment(obj.project.due).add('days', 2).toString(),      
    }
    this.options.scales.xAxes[0].gridLines = {
      drawOnChartArea: false,
      color: "rgba(0, 0, 0, 0)",
    }
    this.options.scales.xAxes[0].ticks = {
      maxTicksLimit: 10
    }
    this.options.layout = {
      padding:{
        right: 10
      }
    };
    
    let res =  [chartDataRounds, chartDataGoal];
    if(chartDataGoalExt) res.push(chartDataGoalExt);    
    return res;
  }

  countByMonth(ary) {
    
    let collection = ary.reduce(function (counter, item) {
      counter[item.month] = item.total
      return counter;
    }, {});

    this.labels = [];
    let chartData = {
      data: [],
      label: "Revenue",
      backgroundColor: this.colors,
    };

    ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"].forEach(i => {
      this.labels.push(i);
      if (collection[i]) {
        chartData.data.push(collection[i].toUpperCase());
      } else {
        chartData.data.push(0)
      }
    })
    return chartData;
  };


  // countByMonth(ary, classifier){
  //   classifier = classifier || String;
    
  //   let collection = ary.reduce(function (counter, item) {
  //     var p = classifier(item);
  //     counter[item.month] = p;
  //     return counter;
  //   }, {});

  //   this.labels = [];
  //   let chartData = {
  //     data: [],
  //     label: "Revenue",
  //     backgroundColor: this.colors,
  //   };

  //   [0,1,2,3,4,5,6,7,8,9,10,11].forEach( i =>{
  //     this.labels.push().month(i).format("MMM"));
  //     if (collection[i]) {
  //       chartData.data.push(collection[i]);
  //     }else{
  //       chartData.data.push(0)
  //     }
  //   })
    
  //   return chartData;
  // };
}
