import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { TimesheetModel } from "../_models/subjects";
import { ReplaySubject, Subject } from 'rxjs';
import * as moment from "moment";



@Injectable({
  providedIn: 'root'
})
export class TimesheetService {
  //private readonly refreshToken$ = new BehaviorSubject(undefined);
  private $timesheets: ReplaySubject<{sheets:TimesheetModel[], type: [], brand: [], projects: [], projectTotal: number}> = new ReplaySubject(1);
  public cached: string;
  private _timesheetData: TimesheetModel[];
  constructor(
    private apiService: ApiService
  ) { }

  
  //USER SPECIFIC
  timesheets(week=null,impersonating=null) {
    //this.getProjectsCache()
    this.getTimesheetsCache(week,impersonating);
    return this.$timesheets.asObservable();
  }

  overview(impersonating=null){
    return this.apiService.post('timesheets-overview',{imp:impersonating});
  }

  setRange(week,impersonating=null,view='week',force=false){   
    if (force) this.cached = null; 
    this.getTimesheetsCache(week,view,impersonating);
  }

  loadTimesheets(startOfWeek=null, view="week", impersonating=null,skipToast?:boolean){
    let ref = new Subject();;
    this.apiService.post<TimesheetModel[]>('timesheets',{range: startOfWeek, imp: impersonating, view}, skipToast)
      .subscribe(
        (res) => {
          this._timesheetData = res;
          this.$timesheets.next(res);
          ref.next(res);
        });

    return ref;
  }

  returnTimesheets(startOfWeek=null){
    return this.apiService.post<TimesheetModel[]>('timesheets',{range: startOfWeek})
  }

  getTimesheetsCache(week,view='week',impersonating=null){
    if (!week.isSame(this.cached) ){
        this.loadTimesheets(week.toDate(),view,impersonating);
        this.cached = week.toDate();
      }
  }

  addTimesheet(timesheet, skipToast?:boolean) {
    return this.apiService.post<TimesheetModel>('timesheet', timesheet, skipToast);
  }


  //ADMIN
  loadAllTimesheets(startOfWeek = null) {    
    return this.apiService.post<TimesheetModel[]>('admin-timesheets', { range: startOfWeek });      
  }

  unlock(ids){
    return this.apiService.post<TimesheetModel[]>('admin-timesheets-unlock', { ids: ids });      
  }
  
  
}
