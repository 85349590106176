import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { first } from 'rxjs/operators';
import { FormErrorsService } from 'src/app/_services/form-errors.service';

@Component({
  selector: 'app-aiprompt',
  templateUrl: './aiprompt.component.html',
  styleUrls: ['./aiprompt.component.scss']
})
export class AipromptComponent implements OnInit {
  form: UntypedFormGroup;
  loading: boolean = false;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AipromptComponent>,
    private fb: UntypedFormBuilder,
    private formErrors: FormErrorsService,
  ) { }

  ngOnInit() {
    this.data.title = "Show Pony - Robot Writter"
    this.form = this.fb.group({
      directions: [""],
      results: [""],
    });

    if(this.data['preloaded']){
      this.form.patchValue({directions: this.data['preloaded']})
    }
  }

  prompt() {
    this.loading = true;
    this.form.patchValue({results: null})
    const data = this.form.value;
    this.data['openaiService'].prompt(data)
      .pipe(first())
      .subscribe( 
        result => {
          console.log(result)
          const response = result?.choices[0]?.message?.content;
          this.form.patchValue({results: response}) 
          this.loading = false;
        },
        error => { 
          this.loading = false; 
          this.formErrors.add(`Error: ${error}`)
        }
      );
  }

  save() {
    this.dialogRef.close({ status: "approve", data: this.form.value });
  }

  cancel(){
    this.loading = false;
  }

  close() {
    this.dialogRef.close();
  }

}
