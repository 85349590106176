import { Component, OnInit, Inject, Injectable  } from '@angular/core';
import { UserService,  } from '../../_services/user.service';
import { UserProfileModel, BrandModel, NervIoSummary } from "../../_models/subjects";
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { BrandService } from '../../_services/brand.service';
import { environment } from '../../../environments/environment';
import { VelvetysoftService } from '../../_services/velvetysoft.service';
import { tap, first } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { Socket } from 'ngx-socket-io';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmComponent } from '../dialogs/confirm/confirm.component';
import { IoService } from '../../_services/io.service';
import { filter, map } from "rxjs/operators";
import { Location } from '@angular/common';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  model: any = {};
  dataFromServer: any = [];
  routerSub: Subscription;
  $userProfile: Observable<UserProfileModel>;
  $brands: Observable<BrandModel[]>;
  $activeBrand: Observable<BrandModel>;
  $activeSummary: Observable<NervIoSummary>;
  cdn = environment.cdn;
  navigation: Array<any> = [];
  hasBrandBar: boolean;
  


  // navigation = [
  //   { path: '/dashboard/overview', label: "Dashboard", icon: 'visibility' },
  //   { path: '/dashboard/projects', label: "Projects", icon: 'folder' },
  //   { path: '/dashboard/invoices', label: "Invoices", icon: 'monetization_on' },
  //   { path: '/dashboard/proposals', label: "Proposals", icon: 'menu_book' },
  //   //{ path: '/logs', label: "Logs", icon: 'assignment' },
  //   //{ path: '/assets', label: "Assets", icon: 'attachment' }
  //   //{ path: 'overview', label: "Overview", icon: 'visibility' },    
  // ]
  root = this.document.documentElement;
  constructor(
    public dialog: MatDialog,
    //private authService: AuthService,
    private location: Location,
    public userService: UserService,
    private brandService: BrandService,
    private router: Router,
    private route: ActivatedRoute,
    public vs: VelvetysoftService,
    public io: IoService,
    private snack: MatSnackBar,
    @Inject(DOCUMENT) private document: Document
  ) {
    
    this.routerSub = router.events.pipe(
      filter((val) => val instanceof NavigationEnd))
      .subscribe((val: NavigationEnd) => {
       let path = vs.noBrandBar.find((item) => val.url.includes(item.path));
       this.hasBrandBar = ( path ) ? true : false;
       //console.log(this.hasBrandBar)
      });

    this.userService.userProfile()
      .pipe(first())
      .subscribe( user => (user.company.color) && this.root.style.setProperty('--primary',user.company.color))

    for (let key in this.vs.colors) {
      const color = this.vs.colors[key]      
      this.root.style.setProperty(key, color);
    }

  }

  ngOnInit() {
    this.navigation = this.vs.dashNav;
    this.$activeSummary = this.io.connections();
    this.$userProfile = this.userService.userProfile().pipe(
      tap( user => (user.company.color) && this.root.style.setProperty('--primary',user.company.color)) 
    );
    this.$brands = this.brandService.brands;
    if(this.cdn) this.root.style.setProperty('--cdn',this.cdn)

    //CHECK VERSION
    let refresh = this.vs.checkVersion();
    if(refresh){
      let ref = this.snack.open('You may need to refresh to a new version', 'Refresh Now', {duration: 3000, });
      ref.onAction().subscribe(()=>{
        window.location.reload();
      });
    }
  }

  back(e) {
    e.preventDefault();    
    this.router.navigate([`dashboard/overview`]);
    //this.location.back();
  }

  mobileNav(){
    this.vs.mobileNav = !this.vs.mobileNav;
  }

  selectBrand(id){
    this.brandService.selectBrand(id);
  }

  activeUsers(summary: NervIoSummary){
    const dialogRef = this.dialog.open(ConfirmComponent, {
      data: {
        summary: summary,
        type: "active-users",
        title: `Active Connections`,
        content: "",
      },
    });
    dialogRef.afterClosed().pipe(first()).subscribe();
  }

}
