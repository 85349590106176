import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../_services/api.service'
import { ActivityModel } from '../../_models/activity';
import { Observable, Subscription } from 'rxjs';
import { VelvetysoftService } from '../../_services/velvetysoft.service';
import { map, switchMap } from 'rxjs/operators';
import { UserService } from 'src/app/_services/user.service';
import { UserModel, BrandList } from 'src/app/_models/subjects';
import { ProjectService } from 'src/app/_services/project.service';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit {
  $activity: any; //Observable<ActivityModel[]>
  subjects: Array<any> = [];
  $users: Subscription;
  users: UserModel[];
  //$brandList: Subscription;
  brandList:BrandList[];

  constructor(
    private apiService: ApiService,
    private vs: VelvetysoftService,
    private userService: UserService,
    private projectService: ProjectService
  ) { }

  ngOnInit() {
    return true;
    this.subjects = this.vs.subjects;
    // this.$brandList = this.projectService.projectTree.subscribe( brands =>{
    //   this.brandList = brands;
    // });
    this.$users = this.userService.loadUsersAssignedTo('brand', null).subscribe( users =>{
      this.users = users;
    })
    // this.$activity = this.apiService
    //   .get('activity-all')
    //   .pipe(
    //     map( (action) =>{
    //       return _.map(action, (obj) => {
    //         // add the properties from second array matching the userID
    //         // to the object from first array and return the updated object
    //         return _.assign(obj, _.find(this.subjects, { 'subject_type': obj.subject_type }));
    //       });
    //     })
    //   );
  }

  ngOnDestroy() {
    //this.$users.unsubscribe();
    //this.$brandList.unsubscribe();
  }

  // projectById(id){
  //   var _project = null;
  //   this.brandList.map(brand => {
  //     if(brand.projects) brand.projects.find(project => {
  //       if (project.id == id) _project = project
  //     })
  //   })
  //   return _project;    
  // }

  userById(id){
    return this.users.find(user => user.id == id);
  }

}
