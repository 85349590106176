import { AfterContentInit, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UserService } from '../../_services/user.service'
import { Pager, PagerObject, UserModel, UserProfileModel } from "../../_models/subjects";
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { AddUserComponent } from '../dialogs/add-user/add-user.component';
import { first, map, tap } from 'rxjs/operators';
import { VelvetysoftService } from 'src/app/_services/velvetysoft.service';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: "app-manage-users",
  templateUrl: "./manage-users.component.html",
  styleUrls: ["./manage-users.component.scss"],
})
export class ManageUsersComponent implements AfterContentInit {
  //new view
  viewOptions = {};

  //
  userPager: PagerObject;
  $users: Observable<Pager<UserModel>>;
  $userProfile: Observable<UserProfileModel>;
  cdn = environment.cdn;
  search: string = "";
  view: string = "tile";
  subject: string = "users";
  settings: any;
  @Input() public tableRef: TemplateRef<any>;

  //TABLE VIEW
  displayedColumns: string[] = [
    "select",
    "name",
    "Brands",
    "Projects",
    "Role",
    "actions",
  ];
  asyncFilters = {
    filters: [],
    deleted: false
  };
  // options = {
  //   count: true,
  //   filter: true
  // };

  types: any;
  sortedData: UserModel[];
  filteredSubjects: string[];
  @ViewChild("vaultTable") vaultTable;
  constructor(
    private userService: UserService,
    //private dialogRef: MatDialogRef<AddUserComponent>,
    public dialog: MatDialog, //private snack: MatSnackBar,
    public vs: VelvetysoftService,
    private snack: MatSnackBar,

  ) {}
  selection = new SelectionModel<UserModel>(true, []);

  ngAfterContentInit() {
    this.types = this.vs.collectionToFilters("user", "roles");
    //console.log(this.types)
    // this.filteredSubjects = this.types.map( item => item.value);

    this.asyncFilters.filters.push({
      label: 'Role',
      field: 'roles',
      data: this.types.map( role => {        
          return {label: role.title, value: role.title}
      }),
    })

    this.userPager = this.userService.pager<UserModel>();
    this.$users = this.userPager.$source;
    //this.$users = this.userService.loadUsersAssignedTo("all", "all");
    this.$userProfile = this.userService.userProfile();
    //WIRD ERROR HERE, PIP NOT BEING CALLED ON EMIT, HAVING TO SUBSCRIBBE AS WELL ( MIGHT BE BECUASE SUBSCRIBE ON SECOND VIEW )    
    //USEED FOR TILE VIEW
    //this.$users = this.userService.$activeUsers;    
    this.settings = this.vs.getSettings("user");
    //this.filteredSubjects = this.settings.roles.map((role) => role.name);    
    this.types = this.vs.collectionToFilters("user", "roles");
    this.filteredSubjects = this.types.map( item => item.value);

    this.viewOptions = {
      views: ["tile", "table"],
      table: {
        filterOn: "roles:slug",
        types: this.types,
        filteredSubjects: this.filteredSubjects, //change name
        columns: this.displayedColumns,
        settings: {
          filter: true,
          count: true,
        },
      },
    };
  }


  changeView(view) {
    this.view = view;
  }

  trash(id?){    
    let ids = id ? [id] : this.vaultTable.selection.selected.map((p) => p.id);
    if(ids.length <= 0) return this.snack.open(`No Users Selected`, "Dismiss", {
      duration: 2000,
    });
    this.userService.deleteDialog(ids);
  }

  add(user?: UserModel) {
    this.userService
      .addDialog(user)
      .pipe(first())
      .subscribe((user) => {
        //console.log(user);
        this.userPager.refresh();
        //this.$users = this.userService.loadUsersAssignedTo("all", "all");
      });
  }
}
