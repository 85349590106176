<div class="container" >
  
    <div class="block">
      <div class="block-title">
        <div class="block-title-label">Team Out of Office Calendar</div>
          <div class="block-title-toolbar view-tools">          
        </div> 
      </div>  
    </div>
  
    <div class="block" *ngIf="($staff | async) let staff">

      <div class="block-content">
        <button mat-stroked-button (click)="prevWeek()">
            <mat-icon>chevron_left</mat-icon>
          </button>
          &nbsp;
          {{ staff?.range?.start | date: 'MMMM Y' }}
          <!-- <mat-form-field>
            <mat-label>Choose a date</mat-label>
            <input matInput (dateChange)="selectWeek('change', $event)" [value]="currentWeek" [matDatepicker]="picker">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker startView="multi-year"#picker></mat-datepicker>
          </mat-form-field>             -->
          &nbsp;
          <button mat-stroked-button (click)="nextWeek()"><mat-icon>chevron_right</mat-icon></button>      
        
        <div>
          <div class="row" *ngFor="let week of days" >
            <div class="col aday" [ngClass]="{ 'bad-month': day.out}" *ngFor="let day of week">
              <div class="text-center uc subhead" >{{ day.date | amDateFormat: 'ddd' }}</div>
              <div class="text-center label-sm">{{ day.date | date: 'dd' }}</div>
              <div *ngFor="let uid of day.users">
                <user-avatar [user]="uid" class="mr-1" [showName]="true"></user-avatar>
              </div>
            </div>            
          </div>  
        </div>

        <!-- <div >            
          <div *ngFor="let employee of staff.ts" class="mb-1">
            <div class="row table-text cell cursor" >
              <div class="col-sm-12 d-flex align-items-center" >
                <user-avatar [user]="employee.user" class="mr-1" [showName]="true"></user-avatar>
                <span class="" *ngFor="let date of employee.dates">{{ date | date }}</span>
              </div>
            </div>
          </div>              
        </div> -->
      </div>
    </div>
</div>