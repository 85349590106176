<div class="vault-modal">
  <div [formGroup]="form">
    <div class="row">
      <div class="col-sm-12">
        <h1 mat-dialog-title>
          <span *ngIf="data.brand.id; else addTitle">Edit </span>
          <ng-template #addTitle>
            Add {{ subject }}
          </ng-template>
          <span class="cap">{{ data.brand.name }}</span>
        </h1>
      </div>
    </div>
    
    <div mat-dialog-content>
      <app-alert></app-alert>
      <div class="row ">
        <div class="col-sm-6 text-center"  >        
          <app-brand-logo  format="large" [brand]="preview"></app-brand-logo>
          <div class="mb-4">
            <app-file-upload 
              (uploadComplete)="brandLogo($event)" 
              type="company" 
              hideFile="true"
              removeBtn="true"
              [_file]="form.controls.logo.value">
            </app-file-upload>
          </div>        
        </div>
        <div class="col-sm-6">
          <mat-form-field class="w-100">
            <input matInput name="name" placeholder="Brand Title" formControlName="name">
          </mat-form-field>   
          
          <div *ngIf="data.drives; let drives" class="col-sm-12">
            <div class="form-group">                  
              <mat-form-field class="w-100">
                <mat-select formControlName="gfolder" placeholder="Google DriveFolder">
                  <mat-option>None</mat-option>
                  <!-- {{drives | json}} -->
                  <mat-option *ngFor="let drive of drives" [value]="drive.id">{{drive.name}}</mat-option>
                  <!-- <mat-option *ngFor="let drive of data.drives | keyvalue" [value]="drive.id">{{drive.name}}</mat-option> -->
                </mat-select>
              </mat-form-field>                 
            </div>
          </div>

          <div class="form-group">                  
            <div class="">
              <div style="margin-bottom: 5px;;" class="d-flex align-items-center">
                <span (click)="swatchOpen = !swatchOpen" class="color-block mr-2"
                  [style.background-color]="form.controls.color.value"></span> <label class="label mb-0">Brand Color</label>
              </div>
              <span class="error-label" *ngIf="form.controls.color?.errors?.required" >Brand color is required</span>
              <div *ngIf="swatchOpen" class="swatch-wrap">
                <color-chrome
                  [color]="(form.controls.color.value||'#333333')" (onChangeComplete)="colorChange($event)">
                </color-chrome>
                <div class="p-1 text-center">
                  <button (click)="swatchOpen = false" mat-button>Close</button>
                </div>     
              </div>
            </div>
          </div>

          <div class="form-group text-left">
            <mat-slide-toggle (change)="statusChange($event)" color="primary" formControlName="active"> 
              <label class="label">Active</label>
            </mat-slide-toggle>
          </div>

        </div>

        <div class="col-sm-12">
          <div class="row">
            <div class="col-sm-12">
              <users-select 
                title="Client Team" 
                [users]="form.controls.users.value" 
                context="brand" 
                [userList]="form.controls.users.value"
                fillrole="client"
                [fillbrand]="form.controls.id.value"
                autofill="true"
                role="client"></users-select>
            </div>
            <div class="col-sm-12">
              <users-select 
                title="Internal Team" 
                [users]="form.controls.users.value" 
                context="brand" 
                [userList]="form.controls.users.value"
                fillrole="employee"
                [fillbrand]="form.controls.id.value"
                autofill="true"
                role="employee"></users-select>
            </div>
          </div>
        </div>

        <div class="col-sm-12">                    
          <mat-vertical-stepper  #stepper>
            <mat-step >
              <ng-template matStepLabel>Invoices </ng-template>
              <div class="row">                  
                <div class="col-sm-10">
                  <mat-form-field class="w-100">
                    <input matInput name="prefix" [maxLength]="3" mask="AAA" placeholder="Invoice Prefix" formControlName="prefix" >
                  </mat-form-field>                            
                  <span class="error-label" *ngIf="form.controls.prefix?.errors?.required" >A three leter prefix is required</span>
                </div>

                <div class="col-sm-10 mt-2">   
                  <div class="row col-sm-10">
                    <div class="w-100 mat-form-field mb-1">
                      <mat-checkbox color="primary" (change)="removeHC()" [ngModelOptions]="{standalone: true}" [(ngModel)]="useHoldingCompany">Use Holding Company</mat-checkbox>
                    </div>
                  </div>
                  <div class="row" *ngIf="useHoldingCompany">
                    <div class="col-sm-6">
                      <mat-form-field class="w-100" *ngIf="($holdingCompanies | async); let hcs;">
                        <mat-select  formControlName="holding_company_id" placeholder="Holding Company">
                          <mat-option>None</mat-option>
                          <mat-option *ngFor="let hc of hcs" [value]="hc.id">{{hc.name}} <button mat-button (click)="addHcs(hc)"><mat-icon>edit</mat-icon></button></mat-option>
                        </mat-select>
                      </mat-form-field>             
                    </div>
                    <div class="col-sm-6">
                      <button mat-raised-button (click)="addHcs()" color="primary">Add Holding Company</button>
                    </div>
                  </div>                 
                                           
                </div>

                <div class="col-sm-10" *ngIf="!useHoldingCompany">      
                  <mat-form-field class="w-100">
                    <input matInput name="client" placeholder="Client Legal Name" formControlName="client"  >                       
                  </mat-form-field>                                  
                </div>

                <div class="col-sm-10" *ngIf="!useHoldingCompany">
                  <label class="mat-dialog-title mr-3 pl-0 mb-1 mt-3" for="">Addresses</label>
                  <app-addresses 
                    [currentAddresses]="data?.brand?.addresses" 
                    limit="2"
                    [form]="form" 
                    ></app-addresses>                  
                </div>

              </div>   
            </mat-step>
            <mat-step>
              <ng-template matStepLabel>Financials</ng-template>
              <div class="row">
                <div class="col-sm-10">
                  <mat-form-field class="w-100">
                    <span matPrefix>$ &nbsp;</span>
                    <input matInput name="yearly_forcast" placeholder="Yearly Projection" formControlName="yearly_forcast"  mask="separator.2"
                    thousandSeparator=",">
                  </mat-form-field>                            
                </div>

                <div class="col-sm-10">
                  <mat-form-field class="w-100">                    
                    
                    <input matInput name="invoice_overdue_day" placeholder="Brand Overide Invoice Overdue Days" formControlName="invoice_overdue_day" type="number">
                    <span matSuffix>Days</span>
                  </mat-form-field>
                  <label class="label" *ngIf="($userProfile | async); let profile" for="">Currently set at: {{  profile.company.invoice_overdue_day }} days</label>
                </div>
                
              </div>
            </mat-step>
          </mat-vertical-stepper>
        </div>
            
        
        
      </div>    
    </div>

    <mat-dialog-actions>
      <button mat-button (click)="close()">Cancel</button>
      <!-- Can optionally provide a result for the closing dialog. -->
      <button color="primary" [disabled]="form.invalid" mat-raised-button (click)="save()">Save</button>
      <button *ngIf="data.brand.id" mat-button mat color="warn" (click)="delete()">Delete</button>
    </mat-dialog-actions>
  </div>
</div>