<div class="flag">
  <img src="https://vh-vault.s3.us-east-1.amazonaws.com/static/vh-icon.png" alt="Velvet Hammer Branding">
</div>

<div class="d-flex h-100 align-items-center">
  <div class="container">
    <div class="text-center" *ngIf="!loading; else loader">
      <img src="https://vh-vault.s3.us-east-1.amazonaws.com/static/showpony-logo.png" alt="Show Pony" style="max-width: 280px;" />
      
      <div class="row mt-4">
        <div class="col-sm-6 offset-sm-3">
          <app-alert></app-alert>
        </div>
        <div class="col-sm-4 offset-sm-4">                    
          <form (ngSubmit)="login()" #form="ngForm">
            <div class="form-group">
              <mat-form-field class="w-100">
                <input matInput placeholder="Email" type="email"  name="email" 
                  [(ngModel)]="model.email" required />
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field class="w-100">
                <input matInput placeholder="Password" type="password"  name="password"
                  placeholder="Password" [(ngModel)]="model.password" required />
              </mat-form-field>
            </div>
            <button mat-flat-button color="primary" type="submit" [disabled]="submitted || !form.valid">Sign In</button>
            <a mat-button color="" (click)="forgot()" >Reset Password</a>
          </form>   

          <div class="mt-5 mb-4">
            <div class="d-flex align-items-center ">
              <div class="flex-fill"><hr></div>
              <div class="px-3">or</div>
              <div class="flex-fill"><hr></div>
            </div> 
          </div>

          <div>
            <form (ngSubmit)="quickSignin()" #formquick="ngForm">
              <mat-form-field class="w-100">
                <input matInput placeholder="Email" type="email"  name="email" 
                  [(ngModel)]="quickemail" required />
              </mat-form-field>
              <button mat-flat-button color="primary" color="primary" [disabled]="submitted || !formquick.valid" type="submit">Quick sign in with Email</button>
            </form>
          </div>

               
        </div>
      </div>
    </div>

    <ng-template #loader>
      <mat-spinner color="primary" class="mx-auto loader"></mat-spinner>
    </ng-template>
  </div>
</div>