import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { UserModel } from "../../_models/subjects";
import { environment } from '../../../environments/environment';
import { UserService } from '../../_services/user.service';
import { filter, map, tap, first } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: "user-avatar",
  templateUrl: "./user-avatar.component.html",
  styleUrls: ["./user-avatar.component.scss"]
})
export class UserAvatarComponent implements OnInit {
  cdn = environment.cdn;
  toolTipDisplay: string = null;
  users: UserModel[];
  $userSub: Subscription;
  findUser = false;  
  @Input() user: UserModel | any;
  @Input() class: string;
  @Input() showName: boolean;
  @Input() showFullName: boolean;
  @Input() toolTip: boolean = false;
  @Input() position: boolean = false;  
  @Input() phone: boolean = false;  
  @HostBinding('class') @Input() parentCss:string = '';
  constructor(private userService: UserService) {}

  ngOnInit() {
    //if (!_.isObject(this.user)){
    // console.log(this.user);
    // this.userService.loadUsersAssignedTo('brand', null).pipe(
    //   map( users =>{
    //     console.log(users);
    //   })
    // );
    //}    
    //if (!_.isObject(this.user)){
    
    if(!(this.user instanceof Object)){
      this.findUser = true;
      this.$userSub = this.userService.$activeUsers.pipe(first()).subscribe( users => {
        //tap( users => { 
          this.users = users; 
          this.user = this.users.find(user => user.id == this.user);
          this.findUser = false;
        });
        //);
    }

    //if (_.isObject(this.user) && this.user.id && this.toolTip)
    if (!(this.user instanceof Object) && this.user?.id && this.toolTip)    
      this.toolTipDisplay =
        "Name: " + this.user.name + "\n Email: " + this.user.email;

    

  }

  //toolTipDisplay

  initials(user) {
    let display = "";
    if (user.name) display += user.name[0];
    if (user.last_name) display += user.last_name[0];
    return display;
  }

  color(user, alpha = 0) {
    return this.intToRGB(this.hashCode(user.name), alpha);
  }

  private hashCode(str) {
    if(!str) return 0;
    // java String#hashCode
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  private hexToRgb(hex, alpha = 1) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return `rgba(${parseInt(result[1], 16)}, ${parseInt(
      result[2],
      16
    )}, ${parseInt(result[3], 16)}, ${alpha})`;
  }

  private adjust(color, amount) {
    return (
      "#" +
      color
        .replace(/^#/, "")
        .replace(/../g, (color) =>
          (
            "0" +
            Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(
              16
            )
          ).substr(-2)
        )
    );
  }

  private intToRGB(i, alpha) {
    var c = (i & 0x00ffffff).toString(16).toUpperCase();
    var fc = "00000".substring(0, 6 - c.length) + c;
    var color = this.adjust("#" + fc, alpha);
    //var color = this.hexToRgb('#'+fc,alpha);
    //adjust('#ffffff', alpha)
    return color;
  }

  

  ngOnDestroy(){
    if(this.$userSub) this.$userSub.unsubscribe();
  }
}
