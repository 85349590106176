<ng-container *ngIf="ids.length > 0">
    <div *ngIf="($activity | async); let activity" class="">
        <div *ngFor="let item of activity" class="row align-items-center m-0 table-text cell">
            <div class="col-auto p-1" *ngIf="item.causer_id">
                <user-avatar class="shrink" tooltip="true" [user]="item.causer_id"></user-avatar>
            </div>
            <div class="col-sm-3">
                <div>{{ item.description }}</div>
                <div><span class="date">{{ item.created_at | date }}</span></div>
            </div>
            <div class="col-sm-6">
                <div>{{ item.properties?.attributes?.title }}</div>
                <div>
                    <span class="cap-init" *ngIf="item.properties?.attributes?.email">{{ item.properties?.attributes?.email }}</span>
                    <span class="cap-init" *ngIf="item.properties?.attributes?.payment_ref">Ref: {{ item.properties?.attributes?.payment_ref }}</span>
                </div>
            </div>
            
        </div>
    </div>
</ng-container>
