<div [ngClass]="{isOver: isOver}" class="drag-overlay form-group">
  <div class="inner-drag d-flex align-items-center justify-content-center">
    <div class="text-center">
      <div>
        <label class="label">Drag & Drop or</label>
      </div>
      <label class="mat-button mat-button-base mat-primary">
        <span class="mat-button-wrapper">
          <mat-icon>note_add</mat-icon> Add Files
        </span>
        <div class="label">Max filesize: {{ maxFileSize }}</div>
        <div class="mat-button-focus-overlay"></div>
        <input type="file" name="file" hidden multiple (change)="upload($event)">
      </label>
    </div>
  </div>
</div>