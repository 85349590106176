<ng-container [formGroup]="form">
    <mat-form-field class="w-100">
        <mat-select  placeholder="Brand" [formControlName]="formkey">                            
            <mat-option *ngIf="!excludeCompany" [value]="-1" >
                <div class="d-flex align-items-center">
                    <app-brand-logo [brand]="profile.company"></app-brand-logo> &nbsp; {{profile.company.name}}
                </div>
            </mat-option>
            <mat-option  *ngFor="let brand of sortBy('name',brandList) | keyvalue" [value]="brand.value.id">
                <div class="d-flex align-items-center">
                    <app-brand-logo [brand]="brand.value"></app-brand-logo> &nbsp; {{brand.value.name}}
                </div>
            </mat-option>
        </mat-select>
        
    </mat-form-field>

</ng-container>