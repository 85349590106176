import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AssetModel, UserProfileModel, assetChangeResults } from 'src/app/_models/subjects';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../_services/api.service'
import { first, switchMap } from 'rxjs/operators';
import { AssetService } from '../../_services/asset.service';
// import { RoundService } from 'src/app/_services/round.service';
import { RoundService } from 'src/app/_services/round.service';
import { forkJoin, zip, concat, Observable } from 'rxjs';


@Component({
  selector: 'app-asset',
  templateUrl: './asset.component.html',
  styleUrls: ['./asset.component.scss']
})
export class AssetComponent implements OnInit {
  @Input() asset: AssetModel;
  @Input() onlyImage: Boolean = false;
  @Input() currentUser: UserProfileModel = null;
  @Output() assetChanged = new EventEmitter<assetChangeResults>();
  cdn = environment.cdn;
  constructor(
    private apiService: ApiService,
    private assetService: AssetService,
    private roundService: RoundService
  ) { }

  ngOnInit() {
  }

  assetThumb(asset,self?){
    let _self = self || this;
    let url = _self.assetService.thumb(asset);
    return  (url) ? 'url(' + url + ')' : '';
  }

  gallery(asset){
    this.assetService.gallery(asset);
  }

  downloadAsset(asset: AssetModel){
   this.assetService.downloadAsset(asset);
  }

  size(bytes,self?){    
    let _self = self || this;
    return _self.assetService.size(bytes);
  }

  trash(id?) {    
    this.assetService.deleteDialog([id], { skipLoad: true })
      .pipe(first())
      .subscribe(res => {
        this.roundService.loadSubject() 
        this.assetChanged.emit({
          action: 'removed',
          id: id
        });
      });
  }



  edit(asset){

    this.assetService.edit(asset)
      .pipe(first())
      .subscribe( asset =>{
        this.roundService.loadSubject();
      })

  }

}
