import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AlertService, Alert, AlertType } from '../../_services/alert.service'
import { Subscription } from 'rxjs';




@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, OnDestroy {

  //alerts: Array<NotificationModel> = [];  
  @Input() id: string;
  alerts: Alert[] = [];
  subscription: Subscription;
  
  constructor(
    private alertService: AlertService
  ) {
    this.subscription = this.alertService.onAlert(this.id)
      .subscribe(alert => {
        if (!alert.message) {
          // clear alerts when an empty alert is received
          this.alerts = [];
          return;
        }

        // add alert to array
        this.alerts.push(alert);
      });
  }

  ngOnInit() {
    
  }

  removeAlert(alert: Alert) {
    // remove specified alert from array
    this.alerts = this.alerts.filter(x => x !== alert);
  }

  cssClass(alert: Alert) {
    if (!alert) {
      return;
    }
    // return css class based on alert type
    switch (alert.type) {
      case AlertType.Success:
        return 'alert alert-block-success';
      case AlertType.Error:
        return 'alert alert-block-danger';
      case AlertType.Info:
        return 'alert alert-block-info';
      case AlertType.Warning:
        return 'alert alert-block-warning';
    }
  }

  ngOnDestroy() {
    // unsubscribe to avoid memory leaks
    this.subscription.unsubscribe();
  }

  

}
