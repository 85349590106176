<mat-form-field class="w-100" *ngIf="($avalibleUsers); let allUsers; else loading">    
  <mat-chip-list #chipList aria-label="Users selection">
    <mat-chip 
      *ngFor="let user of users | userIs: role" 
      class="avatar-chip"
      [selectable]="selectable" 
      (removed)="remove(user)"
      [removable]="removable">
        <user-avatar [user]="user" [toolTip]="toolTip" [showName]="true"></user-avatar>
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input 
      [placeholder]="_title | capitalize" 
      [formControl]="userCtrl" 
      #userInput  
      [matAutocomplete]="auto"                
      [matChipInputFor]="chipList" 
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur" 
      (matChipInputTokenEnd)="add($event)">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let user of filteredUsers | async | userIs: role | isNot: users; trackBy: _trackItem" [value]="user">
      <user-avatar [user]="user" [showFullName]="true"></user-avatar>
    </mat-option>
  </mat-autocomplete> 
  <button 
    mat-icon-button 
    color="primary" 
    matTooltip="Add user"
    matTooltipPosition="right" 
    (click)="createUser()" 
    class="quick-add">
      <mat-icon>add_box</mat-icon>
    </button>
</mat-form-field>

<ng-template #loading>
  <div class="bar-loading"></div>
</ng-template>