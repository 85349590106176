import { I } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, FormArray, UntypedFormArray, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { BrandModel, ExpenseModel, Setting } from 'src/app/_models/subjects';
import { ExpenseService } from 'src/app/_services/expense.service';
import { VelvetysoftService } from 'src/app/_services/velvetysoft.service';

@Component({
  selector: 'app-line-expense',
  templateUrl: './line-expense.component.html',
  styleUrls: ['./line-expense.component.scss']
})
export class LineExpenseComponent {
  @Output() isExpenseValid: EventEmitter<any>;
  $brandList: Observable<BrandModel[]>;
  @Input() start: string;
  @Input() expenses: ExpenseModel[];
  @Input() imp: Number|String|null = null;
  SAVED: boolean = true;
  types: Setting[];
  form = new FormGroup({
    expenses: new FormArray([])
  });   
  constructor(
    private vs: VelvetysoftService,
    private expenseService: ExpenseService,
  ) {
    this.isExpenseValid = new EventEmitter();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('changes',changes);
    if ('expenses' in changes) {        
      this.expenses = [];
      
      this.expenses = changes.expenses.currentValue;      
      this.form = new FormGroup({expenses: new FormArray([]) });   
      this.loadDate();
    }
  }

  ngOnInit(): void {

    
    this.types = this.vs.getSettings('expense')?.types;   
    
    //this.loadDate();
    

    this.form.valueChanges
      .pipe(debounceTime(300))
      .subscribe((_form) => {
        //this.form.valid
        //this.isExpenseValid.emit(this.SAVED);   
      });
    
  }

  get formExpenses(){
    return (this.form.get("expenses") as UntypedFormArray).controls;    
  }

  loadDate(){
    
    if(this.expenses && this.expenses.length){
      this.expenses.forEach((expense) => {
        let expenses = this.form.get('expenses') as FormArray;
        expenses.push(this.expenseForm());
      });
      this.form.patchValue({expenses: this.expenses});
    }
  }

  addExpense() {
    let expenses = this.form.get('expenses') as FormArray;
    expenses.push(this.expenseForm());
    this.SAVED = false;
    this.isExpenseValid.emit(this.SAVED);    
  }

  editExpense(){
    this.SAVED = false;
    this.isExpenseValid.emit(this.SAVED);    
  }

  uploadReceipt(event,idx){
    let expenses = this.form.get('expenses') as FormArray;
    expenses.controls[idx].patchValue({receipt: event});
  }

  findType(type){
    return this.types.find((t) => t.value == type)?.title;
  }

  dismiss(){
    this.SAVED = true;
    this.isExpenseValid.emit(this.SAVED);
  }

  removeline(idx){
    let expenses = this.form.get('expenses') as FormArray;
    if(expenses.value[idx].id){
      this.expenseService.deleteDialog([expenses.value[idx].id]).subscribe((res) => {
        expenses.removeAt(idx);
        this.SAVED = false;
        this.isExpenseValid.emit(this.SAVED);
      });
    }else{
      expenses.removeAt(idx);
      this.SAVED = false;
      this.isExpenseValid.emit(this.SAVED);
    }
    
  }

  saveExpenses(){      
    let expense = this.form.value;
    this.expenseService.addExpenseLine(expense,this.start,this.imp).subscribe((res) => {            
        this.SAVED = true;
        this.isExpenseValid.emit(this.SAVED);      
        this.form.patchValue({expenses: res.data});
    });
    
  }

  expenseForm() {
    return new FormGroup({
      brand_id: new FormControl('', [Validators.required]),
      project_id: new FormControl(''),
      type: new FormControl('', [Validators.required]),
      total: new FormControl('', [Validators.required]),
      receipt: new FormControl(''),
      notes: new FormControl(''),
      id: new FormControl(''),
      date: new FormControl(''),
    });
  }


}
