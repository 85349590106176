<ng-container *ngIf="type !== 'statuses';else status">
  {{ display }}
</ng-container>
<ng-template #status>

  <!-- PROJECTS -->
  <span *ngIf="format == 'dot';else labeldispaly" class="dot" [ngClass]="id" [matTooltip]="display"></span>

  <!-- STANDARD -->
  <ng-template #labeldispaly>
    <span class="dot-label" [ngClass]="id">
      {{ display }} 

      <!-- MODEL -->
      <ng-container *ngIf="item">    

        <!-- PROPOSALS SENT -->
        <span 
          *ngIf="item.status == 'sent'"
          matTooltip="{{ item.sent_at | amFromUtc | amLocal | amCalendar }}">:
          {{ item.sent_at | amFromUtc | amTimeAgo }}</span>

        <!-- PROPOSALS APPROVED -->
        <span 
          *ngIf="item.status == 'approved' && subject == 'proposal'"
          matTooltip="{{ item.approved_at | amFromUtc | amLocal | amCalendar }}">:
          {{ item.approved_at | amFromUtc | amTimeAgo }}</span>

          <!-- Invoice PAID -->
        <span 
          *ngIf="item.status == 'paid'"
          matTooltip="{{ item.paid_at | amFromUtc | amLocal | amCalendar }}">:
          {{ item.paid_at | amFromUtc | amTimeAgo }}</span>

        <span 
          *ngIf="item.status == 'submitted' && subject == 'expense'">:
          Needs Payment</span>

        <!-- <ng-container *ngIf="isOverDue(item)">
          : OVERDUE : {{ item.due_date | amFromUtc | amTimeAgo }}        
        </ng-container> -->

      </ng-container>

      
    </span>

  </ng-template>
</ng-template>