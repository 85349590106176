import { Component, OnInit, Inject } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { BrandService } from '../../../_services/brand.service';
import { ProjectService } from '../../../_services/project.service';
import { ProjectModel, BrandModel, Settings, InvoiceModel, ProposalModel, UserModel, UserProfileModel} from "../../../_models/subjects";
import { VelvetysoftService } from '../../../_services/velvetysoft.service';
import { environment } from '../../../../environments/environment';
import { Observable, ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { AlertService } from '../../../_services/alert.service';
import * as moment from "moment";
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { ModalLibraryService } from '../../../_services/modal-library.service';
import { ProposalService } from 'src/app/_services/proposal.service';
import { Router } from '@angular/router';
import { MultiPhaseTypeRequired } from 'src/app/_validators/validators';
import { OpenaiService } from 'src/app/_services/openai.service';
import { UserService } from 'src/app/_services/user.service';


@Component({
  selector: "app-add-invoice",
  templateUrl: "./add-invoice.component.html",
  styleUrls: ["./add-invoice.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class AddInvoiceComponent implements OnInit {
  public Editor = ClassicEditor;
  public $selectedBrandID: ReplaySubject<number> = new ReplaySubject();
  $brandList: Observable<BrandModel[]>;
  $projects: Observable<ProjectModel[]>;
  $invoices: Observable<InvoiceModel[]>;
  $proposals: Observable<ProposalModel[]>
  $userProfile: Observable<UserProfileModel>;
  

  previewTotal: number = 0;
  form: UntypedFormGroup;
  items: UntypedFormArray;
  error: any = false;
  cdn = environment.cdn;
  settings: Settings;
  invoiceNumLocked = true;
  invoiceIDS = [];

  isLibraryOpen: boolean = false;
  editMultiple: boolean = false;
  hasNew: boolean = false;  
  activeBID = null;  
  activePID = null;
  
  panels = ['New Invoice'];
  panelSelected = new UntypedFormControl(0);
  subject: string = 'invoice';

  lockProject: boolean|null = null;
  //multipleProjects = false;
  //multipleBrands = false;
  phases: UntypedFormArray;
  
  //invoiceNumberEditable = false;

  formWizard: UntypedFormGroup;
  itemsWizard: UntypedFormArray;
  panelsWizard = [];
  panelSelectedWizard = new UntypedFormControl(0);
  genIdFix = null;
  wizardState: number = 1;
  wizardTotal: number = 3;
  wizardShell:any = {
    proposal: null,
    project: null,
    invoices: [],
    title: null,
    invoicesToCreate: 1,
    gend: false,
    error: false,
  }


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddInvoiceComponent>,
    //public invoiceService: InvoiceService,
    private brandService: BrandService,
    private projectService: ProjectService,
    private velvetysoft: VelvetysoftService,
    private alertService: AlertService,
    private fb: UntypedFormBuilder,
    public modalLibraryService: ModalLibraryService,
    private proposalService: ProposalService,
    private router: Router,
    private openaiService: OpenaiService,
    private userService: UserService
  ) {
    this.form = fb.group({
      items: fb.array([])
    })  
    this.formWizard = fb.group({
      items: fb.array([])
    })    
  }

  ngOnInit() {    
    this.$brandList = this.projectService.projectTree;
    this.activeBID = this.data.invoiceService?.ctx_dependency?.id;
    this.$userProfile = this.userService.userProfile();

    //STRAIGHT WIZARD
    if(this.data?.options?.view == 'wizard'){
      this.wizard(this.data[this.subject]);
      console.log(this.wizardShell)
    }
    //END STRAIG WIZARD
    
    //CONTEXT OF OPENING - Project VIew and the id
    if(this.data?.options?.pid) this.activePID = [+this.data?.options?.pid];

    if (Array.isArray(this.data[this.subject])) {
      this.editMultiple = true;
      this.panels = [];
      this.invoiceIDS = [];
      //console.log(this.data.options.pid)

      //

      this.data[this.subject].map(item => {
        this.addForm(this.form, this.items, item);
        this.panels.push(item.title);       
        this.invoiceIDS.push(item.id) 
      })
      //this.$activity = this.apiService.post('activity-project-invoice',{ids: invoiceIDS})
    } else {
      //RETRO FIX FOR SINGLE INVOICE to ALL BEING MULTI      
      this.fixSingleToMulti(this.form, this.items, this.data[this.subject]);
      
      this.addForm(this.form, this.items, this.data[this.subject]);      

      if(this.data[this.subject].id) this.invoiceIDS.push(this.data[this.subject].id);
      if (!this.data[this.subject].id){
        this.hasNew = true;
      }else{
        this.panels[0] = "Edit";
      }
      //console.log(this.invoiceIDS)
    }

    let pids = []; 
    pids.push(this.data?.options?.pid) 
    if(Array.isArray(this.data[this.subject]) ){      
      this.data[this.subject].map( item => { if(item.project_id) pids.push(...item.project_id) })
    }
    pids = [...new Set(pids)];
    //convert items in pids to numbers, then remove duplpicates
    pids = pids.map(item => +item);
    //remove duplicates
    pids = [...new Set(pids)];
    
    console.log(pids)
    this.$proposals = this.proposalService._activesByProject<ProposalModel>(pids);
    this.$invoices = this.data[`${this.subject}Service`].all;
    //this.isLibraryOpen = (this.data.isLibraryOpen) ? this.data.isLibraryOpen : false;
  }

  alog(item){
    console.log(item)
  }

  fixSingleToMulti(form, items, invoice){
    
    //If invoice has phases and phases has not brand_id but invoice has brand_id add invoice.brand_id to phases
    if(invoice.phases && invoice.phases.length > 0 && !invoice.phases[0].brand_id && invoice.brand_id){
      invoice.phases.map(phase => {
        phase.brand_id = invoice.brand_id;
        
        //if the array invoice.project_id has a value add it to the phase
        if(invoice?.project_id?.length > 0 && !phase.project_id) phase.project_id = invoice.project_id[0];

        //Add title
        console.log(phase.title)
        //if(!phase.title) phase.title = invoice.project.name;

        //if(invoice.project_id ) phase.project_id = invoice.project_id;
        if(!phase.type) phase.type = invoice.type;
      });
    }



    // if(form.get("brand_id").value){      
    //   console.log('Move brand from invoice to project',form.get("brand_id").value)
    // }
  }

  // formItems(formName) {
  //   return (this[formName].get("items") as FormArray).controls;
  // }

  thisform(index,formName) {
    //@TODO RUNNING TO MANY TIMES
    //console.log(index,formName) 
    return (this[formName].get("items") as UntypedFormArray).controls[index] as UntypedFormGroup;
  }
  

  // PHASES
  formPhases(index,formName){
    return (this.thisform(index,formName).get("phases") as UntypedFormArray).controls;    
  }

  formPhase(indexf,indexp,formName){
    return (this.thisform(indexf,formName).get("phases") as UntypedFormArray).controls[indexp] as UntypedFormGroup;    
    //return (this.form.get("items") as FormArray).controls[index] as FormGroup;
  }

  // showOptionsToggle(indexf,indexp,formName){
  //   //flip the boolean value of showOptions
  //   let showOptions = !this.formPhase(indexf,indexp,formName).get("showOptions").value;
  //   this.formPhase(indexf,indexp,formName).controls.showOptions.patchValue(showOptions);
  // }

  newPhase(){
    let frm = this.fb.group({      
      id: [null],
      type: [""],
      title: [""],      
      content: [""],
      deliverables: [""],
      total: [""],
      project_id: [""],
      brand_id: [""],
      showOptions: [""]
    });

    // Prepopulate brand
    frm.controls.showOptions.patchValue(false);
    if(this.activeBID) frm.controls.brand_id.patchValue(this.activeBID);
    return frm;

    // ,{
    //   validators: MultiPhaseTypeRequired('type',this.multipleProjects) // <-----
    // });
  }

  phaseTotal(subject, pid){
    return this.data.invoiceService.phaseTotal(subject, pid);    
  }

  addInvoice(){
    this.dialogRef.close();
    this.router.navigate(['/dashboard/invoices'],{ state: { action: 'addinvoice' } })
  }
  editInvoice(invoice){
    this.dialogRef.close();
    this.router.navigate(['/dashboard/invoices'],{ state: { action: 'editinvoice', invoice: invoice } })
  }

  createPhases(count=1) {
    return Array.from({ length: count }, (_, i) => this.newPhase());
  }
  deletePhase(id,formIndex,idx,formName){
    this.data.invoiceService.deletePhase(id)
    .pipe(first())
    .subscribe(res=> {
      if(res){
        this.removePhase(formIndex,idx,formName);        
      }
    }); 
  }

  removePhase(formIndex,idx,formName){
    (this.thisform(formIndex,formName).get("phases") as UntypedFormArray).removeAt(idx);
  }

  addPhase(idx, formName) {
    this.phases = this.thisform(idx,formName).get("phases") as UntypedFormArray; 
    this.phases.push(this.newPhase());
  }

  prompt(form,field){
    let prompt = form.value[field];
    this.openaiService.openPrompt(prompt)
    .subscribe(result => {
        let data = []
        data[field] = result;
        form.patchValue(data);
    });
  }
  //END PHASES

  

  cloneFrame(index) {
    let _copyForm = this.thisform(index,'form').value;
    delete (_copyForm.invoice_number)
    delete (_copyForm.id)
    delete (_copyForm.sent_at);
    delete (_copyForm.paid_at);
    _copyForm.status = "draft";


    //@ts-ignore
    this.thisform(0, 'form').get("phases").controls = this.createPhases(_copyForm.phases.length);
    

    //BRAND ID CHECKSSSSZZZZZ
    _copyForm.phases.map(phase => {
      if(!phase.brand_id) phase.brand_id = this.activeBID
    });

    this.thisform(0,'form').patchValue(_copyForm);
    this.panelSelected.setValue(0);    
    this.closeFrame(index);
    //console.log(this.thisform(index));
  }

  // MOVE ALL THIS TO A MODULES FOR ACTIVITY

  navTo(url){  
    this.router.navigate([url]);
    this.close();
  }

  wizard(proposal?){
    this.panelsWizard = [];
    this.wizardState++;
    //STEP 1
    if(proposal)this.wizardShell = {proposal: proposal}
      
    //STEP 2
    
    if(this.wizardState == 2){
      //this is compicated
      //project is first project name in the context of generating from a project...
      //if no project ( direct from proposal ) then add propsal as the starting point, need to rename these variables ( project ) represents any source to generate title and
      this.wizardShell.project = (this.wizardShell.proposal.projects[0]) 
        ? this.wizardShell.proposal.projects[0]
        : this.data.options.project

        

      this.wizardShell.title = (this.wizardShell.project)
        ? this.wizardShell.project.name
        : 'Untitled';

        if(!this.wizardShell.project){
          //this.wizardShell.error = "Current this Proposal is to complicated... check back later. :(";
          this.wizardShell.title = this.wizardShell.proposal.title;
        }
        
    }

    //step 3
    if(this.wizardState == 3){
      this.wizardShell.invoices.map(item => {
        this.addForm(this.formWizard, this.itemsWizard, item);
        this.panelsWizard.push(item.title);
      })
      this.panelSelectedWizard.setValue(0);
      
    }
  }

  calcTotal(){
    return this.wizardShell.invoices.reduce((total, item) => total + parseInt(item.total),0);
  }

  calcPercent(){
    return Math.floor((this.calcTotal() / parseInt(this.wizardShell.proposal.total)) * 100 ) + '%';
  }

  generateInvoiceList(event){
    this.wizardShell.gend = true;
    let divided = this.wizardShell.proposal.total / (this.wizardShell.invoicesToCreate);
    this.wizardShell.invoices = [];
    if(this.wizardShell.invoicesToCreate) Array.from({ length: this.wizardShell.invoicesToCreate }, (v,i) => {                   
      let invoice = {...this.data.invoiceService._invoice}      
      invoice.total = divided;
      invoice.title = `${this.wizardShell.title} ${i+1}`;
      invoice.due_date = (this.wizardShell.project) 
        ? moment(this.wizardShell.project.due).add(30,'days')
        : moment(this.wizardShell.proposal.due_date).add(30,'days');
      invoice.brand_id = this.wizardShell.proposal.brand_id;
      invoice.type = this.wizardShell.proposal.type;
      invoice.project_id = (this.wizardShell.project)
        ? [this.wizardShell.project.id]
        : null;
      invoice.phases = [];
      this.wizardShell.proposal.phases.map(phase => {
        let _phase = {...phase};
        _phase.brand_id = phase.brand_id || this.wizardShell.proposal.brand_id;
        _phase.project_id = phase.project_id || this.wizardShell.project.id;
        if(_phase.total) _phase.total = Math.floor(_phase.total / this.wizardShell.invoicesToCreate);
        console.log(_phase);
        invoice.phases.push(_phase) 
      });   
      this.wizardShell.invoices.push(invoice);
    })
  }

  closeFrame(index) {
    this.items = this.form.get("items") as UntypedFormArray;
    this.items.removeAt(index);
    this.panels = this.panels.filter((item, i) => i !== index);
  }


  view(invoice){
    this.data[`${this.subject}Service`].download(invoice,true) 
  }

  download(invoice: InvoiceModel) {
    this.data[`${this.subject}Service`].download(invoice)    
  }

  getForm(formName){
    return this[formName];
  }
  
  addForm(form, items, invoice, disable: boolean = false){
    let _phases = (invoice && invoice?.phases && invoice?.phases?.length > 0)
      ? invoice.phases.length
      : 0;
    let _form = this.fb.group({
      id: [null],
      title: ["", Validators.required],
      invoice_number: { value: "" },
      due_date: [""],
      type: ["", Validators.required],
      description: [""],
      deliverables: [""],
      content: [""],
      penalty: [""],
      status: ["", Validators.required],
      total: ["", Validators.required],
      project_id: [""],
      brand_id: [""],
      paid_at: [""],
      sent_at: [""],
      phases: this.fb.array(this.createPhases(_phases))
    });

    _form.patchValue(invoice);
    if (disable) _form.disable();
    items = form.get("items") as UntypedFormArray;
    items.push(_form);

    //IF NEW ( based on title chekc ) will defaults
    if (!invoice.id) {
      this.data.invoiceService.getNewNumber().subscribe(num => {
        if(this.genIdFix){
          this.genIdFix++;
          num = this.genIdFix;
        }
        _form.controls.invoice_number.patchValue(num);
        if(!this.genIdFix) this.genIdFix = num;        
      });
      _form.controls.status.patchValue("draft");
      //_form.controls.total.patchValue("00.00");
      
    }else{
      //#EDIT
      // if( !invoice.brand_id ) this.multipleBrands = true;
      // if( invoice?.phases?.length > 1 ) this.multipleProjects = true;
      // if(this.data.invoiceService.isMultiBrand(invoice)) this.multipleBrands = true;      
    }

    //@TODO: LOCK BRAND ON THESE INVOICES
    //SETUP OTHERSTUFF

    this.settings = this.velvetysoft.settings[this.subject];
  }

  // lockProjects(idx,formName){
  //   if(this.lockProject !== null) return this.lockProject
  //   //let form = this.thisform(idx).value
  //   let form = this.formPhases(idx,formName).value

  //   this.lockProject = (form.phases.filter(phase => phase.project_id !== "" && phase.project_id !== null  ).length > 0)
  //   return this.lockProject;
  // }
  

  // isMultiBrand(invoice){
  //   return this.data.invoiceService.isMultiBrand(invoice);
  // }
 
  findBrand(brands, id, pid = null) {
    return this.projectService.selectTree(brands, id, pid);
  }

  loadLibrary(invoice) {
    //console.log(invoice);
    this.addForm(this.form, this.items, invoice, true);
    this.panels.push(invoice.title)
    this.panelSelected.setValue(this.panels.length - 1);
  }

  addedExistingProject(event, formIndex,i,formctx, brand) {
    let project = brand.projects.find((item) => item.id == event);
    if(project){
      this.formPhase(formIndex,i,formctx).controls.title.patchValue(project.name);
      this.formPhase(formIndex,i,formctx).controls.type.patchValue(project.type);
    }
    console.log(event, formIndex,i,formctx, project)
  }

  getTotalPhases(idx,i,formctx, _self){
    console.log(idx,i,formctx)
    _self.previewTotal = 0;
    _self.formPhases(idx,formctx).forEach((item) => {      
     if(item.value.total) _self.previewTotal = _self.previewTotal + parseFloat(item.value.total);
    });        
  }

  
  toggleInvoiceNumber() {
    this.invoiceNumLocked = false;
  }

  save(formIndex) {
    let data = { status: 'load', action: 'load' };
    data[this.subject] = this.thisform(formIndex,'form').value;

    //ERRORS WHERE COMING LATER, QUKC FIX, NEED TO ADDRESS THIS ON ALL MODALS
    this.data.invoiceService.add(data[this.subject] as InvoiceModel)
      .pipe(first())
      .subscribe( res =>{
        //a save results true with the data
       console.log(res)
        //@TODO: Normalize this
        
        if(res == true) this.dialogRef.close(data);
      });
    
    
  }

  saveAll(formctx){
    let data = { status: true };
    const allInvoices = this[formctx].value;
    this.data[`${this.subject}Service`].addMany(allInvoices)
      .pipe(first())
      .subscribe(res => this.dialogRef.close())
  }
  

  delete() {
    this.dialogRef.close({ status: "delete" });
  }

  close() {
    this.dialogRef.close();
  }

  send(invoice: InvoiceModel){
    this.data[`${this.subject}Service`].sendEmail(invoice);
  }

  paid(invoice: InvoiceModel){
    this.dialogRef.close();
    this.data[`${this.subject}Service`].paid(invoice).pipe(first()).subscribe();
    console.log('do paid')
  }
}
