import { Component, Input } from '@angular/core';
import moment from 'moment';
import { first, switchMap } from 'rxjs/operators';
import { InvoiceModel } from 'src/app/_models/subjects';
import { BrandService } from 'src/app/_services/brand.service';
import { InvoiceService } from 'src/app/_services/invoice.service';

@Component({
  selector: 'app-invoice-summary',
  templateUrl: './invoice-summary.component.html',
  styleUrls: ['./invoice-summary.component.scss']
})
export class InvoiceSummaryComponent {
  @Input() pager: any;
  @Input() brandScope: boolean = false;
  $summary: any;
  invoiceSummaryDetails: Boolean = false;

  constructor(
    private invoiceService: InvoiceService,
    private brandService: BrandService,
  ) { }

  ngOnInit(): void {
    if(this.brandScope){
      //GET ACTIVE BRAND FROM BRAND SERVICE and use a switch map to get summary while passing bid to api
      this.$summary = this.brandService.activeBrand.pipe(
        first(),
        switchMap( brand => {
          return this.invoiceService.summary({bid: brand.id});
        })
      );      
    }else{
      this.$summary = this.invoiceService.summary();
    }
      
  }

  sendEmail(invoice: InvoiceModel){
    this.invoiceService.sendEmailOverDue(invoice);
  }

  overDueBy(invoice: InvoiceModel){
    return moment(invoice.due_date).diff(moment(), 'days') + ' days';
  }

  edit(invoice: InvoiceModel){
    this.invoiceService.edit(invoice,{view:'invoice',title: invoice.title})
    .pipe(first())
    .subscribe(() => {
      if(this.pager) this.pager.refresh();
    });
  }
}
