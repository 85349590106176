import { Injectable } from '@angular/core';
import { BrandService } from './brand.service';
import { ApiService } from './api.service';
import { BrandModel, ProjectModel, RoundModel, BrandList, SubjectAction } from "../_models/subjects";
import { ReplaySubject, Subject, Observable } from 'rxjs';
import { map, first, tap } from 'rxjs/operators';
import { MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import * as moment from "moment";
import { AddProjectComponent } from '../_component/projects/add-project/add-project.component';
import { ConfirmComponent } from '../_component/dialogs/confirm/confirm.component';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { SubjectBaseService } from './subject-base.service';
import { AddRoundComponent } from '../_component/project/add-round/add-round.component';
import { UntypedFormBuilder } from '@angular/forms';
import { switchMap, flatMap } from 'rxjs/operators';
import { of } from 'rxjs';
//import { RoundService } from '../_services/round.service';



@Injectable({
  providedIn: 'root'
})

export class ProjectService extends SubjectBaseService {
  
  _project: ProjectModel = {
    id: null,
    ongoing: false,
    name: "",
    type: '',
    due: moment().add(1, 'M').toDate(),
    kickoff: null, 
    est_round: 3,
    est_round_dates: [],//this.generateEstimatedDates(3,moment().add(1, 'M').toDate())],
    brand_id: null,
    status: 'active',
    users: []
  }
  
  //FIX FOR ACTIVE PROJECT, MIGHT NEED TO CHANGE TO SUBJECT  
  instance: any = Math.random();
  $projectTreeCache: ReplaySubject<BrandList[]> = new ReplaySubject<BrandList[]>(1);
  constructor(
    public brandService: BrandService,
    public apiService: ApiService,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder
    //private roundService: RoundService
  ) {
    super("project", apiService, dialog, brandService);
    this.setModel<ProjectModel>(this._project);
    this.setBroadcaster();
    this._project.est_round_dates = this.getEst(3);
    //console.log();
    //this['$activeProject'].subscribe( p => console.log(this.instance,'------------------ACTIVE PROJECT:'+this.subject+' -------------------------',p,this['$activeProject']));
    //this.brandService['$activeBrand'].subscribe(p => console.log(this.instance, '------------------ACTIVE Brand:' + this.subject + ' -------------------------', p, this['$activeProject']));
  }

  get all(): Observable<ProjectModel[]> {
    return this.subjectsAll;
  }

  get projects(): Observable<ProjectModel[]> {
    return this._actives;
  }

  isAvalible(pid){
    console.log(pid);
    //return this;
  }

  getEst(rounds):Array<Date>{
    let due = moment().add(1, 'M').toDate();
    let today = moment();
    let diff = moment(due).diff(today, 'days', false);
    let distro = diff / (rounds+1);
    let estRndDates = [];

    Array.from({ length: rounds }, () => {      
      let date = today.add(distro,'days').toDate();
      estRndDates.push(date);
    })
    return estRndDates;
  }

  //!!!!!!!!!!!!!!!!!!!!!!!!
  // DO NOT USE THIS - clean up this code, for context of selected project only
  //!!!!!!!!!!!!!!!!!!!!!
  project<T>(id): Observable<ProjectModel> {
    //this.activeProjectID = id;        
    return this._active(id);
  }

  hunt(pid){
    return this.projects
      .pipe(
        switchMap((projects) => {
          let t = projects.find(p => p.id == parseInt(pid));
          return (t)
            ? this.project<ProjectModel>(pid)
            : this.lookupProject(pid).pipe(flatMap(res => { 
              this.brandService.selectBrand(res.brand,true);
              return this.project<ProjectModel>(pid);
            }))
        }));
  }

  get projectTreeCache(): Observable<BrandList[]> {
    //MAY WANT TO ADD ABILITY TO FORCE REFRESH
    this.$projectTreeCache.pipe(first()).subscribe(res => {
      if(res.length == 0) this.projectTree.pipe(first()).subscribe();
    });
    return  this.$projectTreeCache.asObservable();    
  }

  get projectTree(): Observable<BrandList[]> {
    return this.apiService.post(`${this.subject}s-tree`, {})
      .pipe(tap(res => this.$projectTreeCache.next(res)  )); 
  }

  exportHours(pid){
    return this.apiService.download(`${this.subject}-export-hours/${pid}`);
  }

  projectsMe(data): Observable<ProjectModel[]>{
    return this.apiService.post(`${this.subject}s-me`,data);
  }



  // RETURNS A LIST OF ALL PROJECTS FOR A BRAND OR BRANDS
  selectTree(brands, bid=null, pid = null) {

    //LOOKING FOR A PROJECT
    if (pid && brands) return brands.find(brand => brand.projects.find(project => project.id == pid));
    //console.log(brands, bid, pid)
    //LOOING FOR BRAND LIST
    return brands.find(brand => brand.id == bid)   
    // let brand = brands.find(brand => brand.id == bid)    
    // return (pid)
    //   ? brand.projects.find(project => project.id == pid)
    //   : brand;
  }


  edit(project?: ProjectModel, options?) {    
    let resultRef = new Subject<SubjectAction<ProjectModel>>();
    if(!project) this._project.users = [];
    
    const result = this.editDialog<ProjectModel>(
      project,
      AddProjectComponent,
      options
    );

    result.pipe(first()).subscribe((res: SubjectAction<ProjectModel>) => {
      const project = res.subject;
      this.prepDate(project);
      (res?.action == 'update') && this.add<ProjectModel>(project)
        .pipe(first())
        .subscribe(res => resultRef.next(res));

        (res?.action == 'delete') && resultRef.next(res)
    });

    return resultRef;
  }

   addRound(round: RoundModel, pid) {
  //   const result = this.roundService.edit(round, pid);
  //   result.pipe(first()).subscribe(round => {
  //     this.project<ProjectModel>(round.project_id)
  //       .pipe(first())
  //       .subscribe(project => {
  //         console.log(project);
  //         //eventually this need to order it'sself by updated_at
  //         // project.rounds.push()
  //         //project.sortBy('updated_at')
  //         project.rounds.unshift(round);
  //       })
  //   })
   }

  getemptyProject(halfOfProject):ProjectModel{
    let _project = this._project;
    halfOfProject.est_round_dates = this.generateEstimatedDates(halfOfProject.est_round,halfOfProject.due,halfOfProject.kickoff);
    let project = Object.assign({}, _project,  halfOfProject)
    return project;
  }

  generateEstimatedDates(rounds,due,kickoff){
    
    let estRndDates = [];
        
    let today = (kickoff) ? kickoff : moment();
    let diff = moment(due).diff(today, 'days', false);
    let distro = diff / (rounds+1);

    Array.from({ length: rounds }, () => {      
      let date = today.add(distro,'days').toDate();
      estRndDates.push(date);
    });

    return estRndDates;
   
  }

  projections(pid:string): Observable<any>{
    return this.apiService.post(`${this.subject}-projection/${pid}`,null);
  }

  hours(pid:string): Observable<any>{
    return this.apiService.post(`${this.subject}-hours/${pid}`,null);
  }
  

  lookupProject(pid){
    return this.apiService.get(`${this.subject}-lookup/${pid}`);
  }

  removeEstDate(pid,index){
    const data = { index: index };
    return this.apiService.post(`${this.subject}-remove-date-ext/${pid}`, data);    
  }

  markComplete(pid){    
    return this.apiService.get(`${this.subject}-complete/${pid}`);   
  }
  

}