<div class="mt-4" *ngIf="!error;else erromsg">
  <!-- {{ data.project.due }} -->
  <div class="trak-wrap" [ngClass]="'status-'+cssStatus">
    <div *ngFor="let milestone of milestones; let i=index; trackBy: trackOn" 
         class="milestone" 
        [ngClass]="milestone.css"  
        [style.left]="milestone.left+'%'" 
        [matTooltip]="milestone.date | date">
        <span class="date milestones-text" [innerHTML]="milestone.label"></span>
        <div class="track-date date" *ngIf="milestone.css == 'kickoff'">Start: {{ milestone.date | date: 'shortDate' }}</div>
        
      </div>
    <div class="track" *ngIf="active">      
      <div class="progress" [style.width]="progress+'%'"></div>
    </div>
  </div>
</div>

<!-- 'active': (currentRound-1) >= i, 
'past': data.project.est_currentRound <= i, 
'complete': data.project.status == 'complete' && (currentRound-1) == i ,
'last': i == (totalMarkers-1)}"  -->

<ng-template #erromsg >
  <div class="px-3">
    <span class="dot-label overdue">{{ error }}</span>
  </div>
</ng-template>
