<div *ngIf="($summary | async); let summary">
    <div class="block-content mb-3">
      <div class="block-title-label cap-init">Invoices Summary - {{ summary.summary.range }} Days Past Due</div>
      <div class="row py-3">
          <div class="col-sm-4">
            <div class="tile sent">
              <div class="d-flex align-items-center">
                <div class="pr-3">
                  <mat-icon >money_off</mat-icon>
                </div>
                <div>
                  <div class="label">Amount</div>
                  <div class="content">{{ -summary.summary.amount | currency }}</div>
                </div>
              </div>
              
            </div>
          </div>  
          <div class="col-sm-4" *ngIf="summary.summary.count > 1">
            <div class="tile sent">
              <div class="d-flex align-items-center">
                <div class="pr-3">
                  <mat-icon >lists</mat-icon>
                </div>
                <div>
                  <div class="label">Invoices</div>
                  <div class="content">{{ summary.summary.count}}</div>
                </div>
              </div>
              
            </div>
          </div>      
          <div class="col-sm-4 d-flex align-items-center">
            <button mat-button color="primary" (click)="invoiceSummaryDetails = !invoiceSummaryDetails"><mat-icon>expand_circle_down</mat-icon> View Details</button>
          </div>      
      </div>
      <div class="row">
        <mat-expansion-panel class="w-100 panel" hideToggle [expanded]="invoiceSummaryDetails">              
          <div class="row p-3">
            <div class="col-sm-1 mat-header-cell"></div>
            <div class="col-sm-5 mat-header-cell">Brand</div>
            <div class="col-sm-1 mat-header-cell">Count</div>
            <div class="col-sm-4 mat-header-cell">Amount</div>
            <div class="col-sm-1"></div>
          </div>
          <div *ngFor="let overdue of summary.overdue ">            
            <div *ngIf="summary.overdue.length > 1" class="row table-text cell cursor align-items-center" (click)="overdue.isOpen = !overdue.isOpen">
              <div class="col-sm-1 ">
                <div *ngIf="overdue?.brand;">
                  <app-brand-logo [tooltip]="true" [bid]="overdue?.brand.id"></app-brand-logo> 
                </div>                    
              </div>
              <div class="col-sm-5">
                <div *ngIf="overdue?.brand; else noBrand">{{ overdue.brand.name }}</div>
                <ng-template #noBrand>
                  No Brand
                </ng-template>
              </div>
              <div class="col-sm-1">
                {{ overdue.count}}
              </div>
              <div class="col-sm-4">
                -{{ overdue.total | currency}}
              </div>
              <div class="col-sm-1">
                 <button mat-icon-button ><mat-icon>expand_circle_down</mat-icon> </button>
              </div>
            </div>
            <mat-expansion-panel class="w-100 panel inset " [expanded]="overdue.isOpen || summary.overdue.length == 1">
              <div class="">
                <div class="row py-3">
                  <div class="col-sm-3 mat-header-cell">Title</div>
                  <div class="col-sm-1 mat-header-cell">#</div>
                  <div class="col-sm-1 mat-header-cell">Amount</div>
                  <div class="col-sm-2 mat-header-cell">Due Date</div>
                  <div class="col-sm-2 mat-header-cell">Overdue Notice</div>
                  <div class="col-sm-2 mat-header-cell">Past Days</div>
                  <div class="col-sm-1"></div>
                </div>
                <div class="" *ngFor="let invoice of overdue.invoices">
                  <div class="row align-items-center vault-table">
                    <div class="col-sm-3 td">
                      <div class="label-sm">{{ invoice.title  }}</div>
                    </div>
                    <div class="col-sm-1 td">
                      {{ invoice.invoice_number  }}
                    </div>
                    <div class="col-sm-1 td">
                      -{{ invoice.total | currency}}
                    </div>
                    <div class="col-sm-2 td">
                      {{ invoice.due_date | date}}
                    </div>
                    <div class="col-sm-2 td">
                      <span *ngIf="invoice.overdue_notice_sent_at == null; else date">
                        <button (click)="sendEmail(invoice)" mat-button color="primary">Send</button>
                      </span>
                      <ng-template #date>
                        Sent: {{ invoice.overdue_notice_sent_at | date}}
                      </ng-template>
                    </div>
                    <div class="col-sm-2 td">
                      {{ overDueBy | Fn : invoice }}
                    </div>
                    <div class="col-sm-1 td">                          
                      <button matTooltip="Edit" (click)="edit(invoice)" class="mat-sm" mat-icon-button mat-button><mat-icon>edit</mat-icon></button>  
                    </div>
                  </div>   
                </div>
              </div>
            </mat-expansion-panel>
            
          </div>
        </mat-expansion-panel>
      </div>
    </div>
  </div>