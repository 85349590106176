<div class="container" *ngIf="($assets | async); let assets; else noProjects">
    <div class="block" *ngIf="($userProfile | async); let profile">
      <div class="block-title" *ngIf="vs.subject('asset'); let subject">
        <div class="block-title-label"><mat-icon *ngIf="subject.icon" class="mr-2">{{ subject.icon }}</mat-icon>{{ subject.label }}</div>      
        <div class="block-title-toolbar">
          <button mat-button color="primary" (click)="add()"><mat-icon>add</mat-icon>New {{subject.label}}</button>
          &nbsp;
          <button mat-button [matMenuTriggerFor]="menu" color="primary"><mat-icon>sort</mat-icon>Actions</button>
          <mat-menu #menu="matMenu" class="menu-sm">
            <button mat-menu-item (click)="trash()" >Delete</button>
          </mat-menu>
        </div>
      </div>
      <div class="block-content">
        <!--          
                       -->
        <div *ngIf="assets && assets.data">
          <app-table
            mat-table
            #vaultTable
            matSort #sort="matSort"  
            [dataSource]="$assets"   
            [pager]="assetspager"
            [columns]="displayedColumns"
            > 

            <ng-container matColumnDef="preview">
              <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
              <td mat-cell *matCellDef="let element"><app-asset [currentUser]="profile" class="w-100" [onlyImage]="true"  [asset]="element"></app-asset>  </td>
            </ng-container>
  
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Name </th>
              <td mat-cell *matCellDef="let element" class="text-capitalize" > {{element.title}} 
                <div *ngIf="element.project_id" class="mt-3"> <button mat-raised-button class="btn-sm" (click)="navTo(element,'project')" color="primary">View Project</button></div>
                <div *ngIf="element.round_id" class="mt-3"> <button mat-raised-button class="btn-sm" (click)="navTo(element,'round')" color="primary">View Round</button></div>
                <app-tags [subject]="element" [showlabel]="false" [tags]="element.tags"></app-tags>
              </td>
            </ng-container>

            <ng-container matColumnDef="created">
              <th mat-header-cell *matHeaderCellDef> Created </th>
              <td mat-cell *matCellDef="let element" class="text-capitalize" > 
                {{element.created_at | date}} <div *ngIf="element.deleted_at" class="mt-3"> <span class="dot-label hold">Deleted {{ element.deleted_at | date }}</span></div></td>
            </ng-container>
            
            <ng-container matColumnDef="size">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Size </th>
              <td mat-cell *matCellDef="let element"> {{ size(element.size) }} </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell class="text-right" *matHeaderCellDef> Actions </th>
              <td mat-cell class="text-right" *matCellDef="let element">    
                <button (click)="edit(element)" class="mat-sm" mat-icon-button mat-button>
                  <mat-icon>edit</mat-icon>
                </button>
              </td>
            </ng-container>
  
            <tr  mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns; "></tr>
  
          </app-table>
            
          <ng-template #noneYet>
            <mat-spinner color="primary" class="mx-auto loader"></mat-spinner>
            <p>No {{ subject }} found</p>
          </ng-template> 
  
        </div>    
      </div>
    </div>
  </div>
  <ng-template #noneYet>
    <p>No {{ subject }} found</p>
  </ng-template>
  <ng-template #noProjects>
    <mat-spinner color="primary" class="mx-auto loader"></mat-spinner>
  </ng-template>
                