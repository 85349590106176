import { Component, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/_services/alert.service';
import { AuthService } from 'src/app/_services/auth.service';
import { FormErrorsService } from 'src/app/_services/form-errors.service';

@Component({
  selector: 'app-quick-signin',
  templateUrl: './quick-signin.component.html',
  styleUrls: ['./quick-signin.component.scss']
})
export class QuickSigninComponent {
  loading: boolean = false;
  email: string = '';
  code: string[] = ['', '', '', '', '', ''];
  @ViewChildren('num0, num1, num2, num3, num4, num5') inputs!: QueryList<ElementRef>;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private formErrors: FormErrorsService,
    private alertService:AlertService
  ) { }

  ngOnInit() {
    
    //get query params email
    this.route.queryParamMap.subscribe(params => {
      this.email = params.get("email");
      //console.log(params,params.get("email"));
      // this.model.email = params.get("email");
      // this.forceReset = (params.get("action") == 'reset') ? true : false;
    });

    if( this.authService.isLoggedIn()){
      this.router.navigate(['/dashboard']);
    }else{
      this.loading = false;
    }

      
  }

  goToNext(event: KeyboardEvent, index: number){
    
    const inputElement = event.target as HTMLInputElement;
    if ( inputElement.value.length === 1 && index < 5) {
      // Move focus to the next input if it's within the bounds
      this.inputs.toArray()[index + 1].nativeElement.focus();
    } else if (event.key === 'Backspace' && index > 0 && inputElement.value.length === 0) {
      // Move focus to the previous input on backspace
      this.inputs.toArray()[index - 1].nativeElement.focus();
    }
  }

  submit(event: KeyboardEvent,index: number){

    const inputElement = event.target as HTMLInputElement;
    if (event.key === 'Backspace') {
      // Move focus to the previous input on backspace
      return this.inputs.toArray()[index - 1].nativeElement.focus();
    }

    let _code = this.concatenatedValue;
    
    if (_code.length !== 6) {
      this.alertService.error('Please fill all fields');
      return;
    }

    if(this.loading) return;    

    this.loading = true;
    this.authService
        .quickSigninConfirm({code: _code })
        .subscribe( res => {
          this.loading = false;
          console.log(res,'res') 
          if(res && res.token){
            this.authService.setUser(res);
          }
        }, err => {
          this.loading = false;
          console.log(err,'err')
        });
  }

  get concatenatedValue(): string {
    return this.code.join('');
  }

  handlePaste(event: ClipboardEvent) {
    event.preventDefault(); // Prevent the default paste action

    const clipboardData = event.clipboardData || window['clipboardData'];
    const pastedText = clipboardData.getData('text');

    // Ensure the pasted text has exactly 6 characters
    if (pastedText.length === 6) {
      const inputsArray = this.inputs.toArray();

      // Distribute the characters into the inputs
      inputsArray.forEach((input, index) => {
        input.nativeElement.value = pastedText[index] || '';
      });

      // Move focus to the last input (assuming all inputs are filled)
      inputsArray[5].nativeElement.focus();
    }

    //trigger cahnge event
    this.code = Array.from(pastedText);
  }

}
