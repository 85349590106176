import { Injectable, Component, ComponentRef } from '@angular/core';
import { ApiService } from "./api.service";
import { MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmComponent } from "../_component/dialogs/confirm/confirm.component";
import { Subject, ReplaySubject, Observable } from 'rxjs';
import { first, tap, flatMap, filter, map } from "rxjs/operators";
import { ProposalModel, BrandModel, ApiOptions, Tag, InvoiceModel } from "../_models/subjects";
import { BrandService } from './brand.service';
import * as moment from "moment";

// @Injectable({
//   providedIn: "root",
// })
export class SubjectBaseService {
  /* NAME OF THE SUBJECT TYPE */
  subject: string = null;
  /* API */
  api: ApiService;
  /* BRAND SERVICE */
  brandService: BrandService;
  /* DIALOG */
  dialog: MatDialog;
  /* ACTIVE SUBJECT OBSERVABLE BASED ON SELECTED BRAND */
  subjectModel: Object;
  /* Only call this is you need data in context of the brand being viewed ( not all data ) */
  $ctx_subject_data: ReplaySubject<any> = new ReplaySubject(1);
  /* active brand */
  ctx_dependency: BrandModel;
  /* CACHE VARIABLE */
  cached: number = null;
  /* DEPENDENCY FOR THIS SUBJECT */
  dependency: string = 'brand';

  // SPECIAL TYPE
  // - THIS WILL CREATE A REPLAYSUBJECT TO BROADCAST TO CHILD AN ACTIVE STATE LIKE A DEPENDENCY ( Ex brand, project )
  isBroadcaster:boolean = false;

  //TODO FIX THIS
  //public $activeBrand: ReplaySubject<any>|null = null;
  //public $activeBrand: ReplaySubject<any> = new ReplaySubject(1);
  //public $activeProject: ReplaySubject<any> = new ReplaySubject(1);
  /* Array of cahces */
  /* each query will be registered here */
  //cacheStore = {}

  constructor(subject, apiService, dialog, brandService?) {
    this.subject = subject;
    this.api = apiService;
    this.dialog = dialog;
    this.brandService = brandService;
  }

  setModel<T>(subject) {
    this.subjectModel = subject;
  }

  setDependecy(dep){
    this.dependency = dep;
  }

  setBroadcaster(){
    this.isBroadcaster = true;
    let activeDep = this.subject.charAt(0).toUpperCase() + this.subject.slice(1);
    this[`$active${activeDep}`] = new ReplaySubject(1);
  }

  // setBrand(brand) {
  //   this.ctx_dependency = brand;
  //   //CAHCE = BROKEN
  // }



  // NOTES TO BYPASS CACHE, CALL LOADSUBJECT ( brand context matters )


  //////////////////////////////////////////////////////////
  // CACHE WALL
  //////////////////////////////////////////////////////////

  /* Check if the subject data is cached */
  cacheCheck(subject) { 
    //console.log(this.subject,'step:3:CheckCache');
    //@TODO: THIS IS PREVENTING AN ERROR, ON TEH ROUND SERVICE, THIS COULD COMPOND
    if (!subject){
      console.log('ERROR EMPTY RESPONSE ->',this.subject);
      return;
    }
    ////console.log(subject.id, this.subject, this.ctx_dependency, subject);
    if (!this.ctx_dependency){
      this.ctx_dependency = subject;
      this.loadSubject();  
    }else if( this.ctx_dependency.id !== subject.id ){
      this.ctx_dependency = subject;
      this.loadSubject();  
    }    
  }

  //////////////////////////////////////////////////////////
  // CONTEXT BASED API CALLS
  // * All these calls are based on teh selected dependency.
  //////////////////////////////////////////////////////////

  resolveDependency<T>(cb):Observable<any> {
    //console.log(this.subject,'step:2:ResolveDependencys');
    //IF BRAND ISN"T RESOLVE, RESOLVE IT
    ////console.log(this.subject,'--------------',cb);    
    let activeDep = this.dependency.charAt(0).toUpperCase() + this.dependency.slice(1);
    return this[`${this.dependency}Service`][`$active${activeDep}`].pipe(
      flatMap((subject) => {
        ////console.log(`this dependency ${activeDep} for ${this.subject}`,subject);
        ////console.log('flat amp', subject, this[`${this.dependency}Service`][`$active${activeDep}`]);
        this.cacheCheck(subject);
        return cb;
      })
    );    
  }

  /* ALL SUBJECT BY COPMANY  */
  /* ADMIN PROBABLY */
  get subjectsAll(){
    return this.api.get(`${this.subject}s-all`);
  }

  /* ALL SUBJECT WITHIN BRAND  */
  get _actives() {
    //console.log(this.subject,'step:1:GET:subjectBasedOnBrand');
    ////this.$ctx_subject_data.subscribe(a => console.log('brand dependency',a));
    ////console.log('brand dependency')
    return this.resolveDependency(this.$ctx_subject_data.asObservable());
  }

  //pager
  pager<T>(data?){
    return this.api.pager<T>(`${this.subject}s-pager`,data);
  }
  

  /*
  * Returns an item from a collection in the scope of it's dependency
  * It subject is a dependency it will broadcast its active state
  */
  _active(id:number) {    
    let activeBroadcasterName = this.subject.charAt(0).toUpperCase() + this.subject.slice(1);
    //console.log('before error',id);
    const stream = (this.isBroadcaster)
      ? this._actives.pipe(
        map(subjects => subjects.find(subject => subject.id == id)),
        tap(subject => this[`$active${activeBroadcasterName}`].next(subject)))
      : this._actives.pipe(map(subjects => subjects.find(subject => subject.id == id)));
    return stream;
      //return this.resolveDependency(this.$ctx_subject_data.asObservable().pipe(
     
  }

  /* ALL SUBJECT WITHIN BRAND BY PROJECT */
  _activesByProject<T>(pid) {
    return this.resolveDependency<T>(this.$ctx_subject_data.pipe(
      map(subjects => subjects.filter(subject => {
        return ( 
          subject.project_id && subject.project_id.toString().includes(parseInt(pid))   
          || subject?.phases.find( phase => phase.project_id == pid)
          || subject?.associated_projects.includes(+pid)
        )
      })),
    ));
  }

  /* Load Subject based on active Brand */
  loadSubject<T>() {
    //console.log(this.subject,'step:4:CALLAPI');
    //this.setCache();
    let ctxDepId = this.ctx_dependency?.id || null;
    this.api
      .post<[]>(`${this.subject}s?${Math.random()}`, { id: ctxDepId })
      .pipe(first())
      .subscribe((res) => {
        this.$ctx_subject_data.next(res);
      });
  }

  /*  UPDATE OR ADD IF ID IS GIVEN */
  add<T>(subject, options:ApiOptions = {}) {
    options.skipToast = options.skipToast || false;
    options.skipLoad = options.skipLoad || false;

    let data = {};
    data[this.subject] = subject;


    return subject?.id
      ? this.api
          .put<T>(this.subject, subject.id, data, options.skipToast)
          .pipe(tap(() => {            
            if(!options.skipLoad){
              (options && options.pager) 
                ? options.pager.refresh()
                : this.loadSubject();
            } 
          }))
      : this.api
          .post<T>(this.subject, data, options.skipToast)
          .pipe(tap(() => {            
            if(!options.skipLoad){
              (options && options.pager) 
                ? options.pager.refresh()
                : this.loadSubject();
            } 
          }));
  }

  addTag<T>(tag:Tag, options:ApiOptions = {}){
    let data = {};
    data = tag;
    return this.api
      .post<T>(`${this.subject}-addtag/${tag.id}`, data, options.skipToast);
  }

  //////////////////////////////////////////////////////////
  // GENERAL API CALLS
  //////////////////////////////////////////////////////////

  /* Mulitple Delete - General API */
  delete(ids: Array<Number>, options = { skipLoad: false }, data?) {
    return this.api
      .post<number>(`${this.subject}s-delete`, {
        ids: ids,
        data: data
      })
      .pipe(tap(() => !options.skipLoad && this.loadSubject()));
  }

  


  /* PDF DOWNLOAD OF ITEM */
  download(subject, view?) {
    return this.api.download<string>(
      `${this.subject}-download/${subject.id}`, null, null, {view: view}
    );
  }


  //INVOICE AND PROPOSALS
  isMultiBrand(subject: ProposalModel|InvoiceModel){
    let bids = null;
    let res = false;
    subject.phases && subject.phases.map( phase =>{      
      if(bids && (bids !==  phase.brand_id) ) res = true;
      bids = phase.brand_id;
    })
    return res;
  }

  phaseTotal(subject: ProposalModel|InvoiceModel, pid): number|{sub:number, total:number, type:string}{    
    if(!subject ) return null;
    if(!pid || !subject.phases || !subject.phases.length || subject.phases?.length < 1 || (subject['project_id'] && subject['project_id']['length'] > 0)) 
      return {total: subject.total, sub: null, type: null};
      
    //add total for phases
    if(subject.phases.length > 1){
      let totals = subject.phases.map(p => p.total);
      let subTotals = totals.reduce((a, b) => a + b, 0);
      if(subTotals == 0) return {
        sub: subject.total / subject.phases.length,
        total: subject.total,
        type: 'Est'
      }
    }
    

    return {
      sub: subject.phases.find(p => p.project_id == pid)?.total,
      total: subject.total,
      type: null
    }
  }

  /* GET UNIQUE INVOICE NUMBER */
  getNewNumber() {
    return this.api.get<number>(`${this.subject}-new-number`);
  }

  /* SEND EMAIL*/
  send(email,endpoint?): Observable<string> {
    let data = {};
    data['email'] = email;
    let _endpoint = endpoint || this.subject;
    return this.api.post<string>(`${_endpoint}-send`, data);
  }

  /* PREVIEW EMAIL */
  preview(email,endpoint?): Observable<string> {
    let data = {};
    data['email'] = email;
    let _endpoint = endpoint || this.subject;
    return this.api.post<string>(`${_endpoint}-preview`, data);
  }

  //////////////////////////////////////////////////////////
  // DIALOGS
  //////////////////////////////////////////////////////////

  /* DELETE DIALOG */
  deleteDialog(ids: Array<Number>, options = { skipLoad: false }, service?) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: ids,
      type: "delete",
      title: `Delete ${ids.length} ${this.subject} `,
      content: "This can not be undone.",
      subject: this.subject,
      _service: service,
    };

    let resultRef = new Subject();
    const dialogRef = this.dialog.open(ConfirmComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.status === "delete") {
        let additionlData = (result.data.tsprojectid && result.data.tsbrandid)
          ? {'tsprojectid': result.data.tsprojectid, 'tsbrandid': result.data.tsbrandid }
          : null;
        this.delete(ids,options, additionlData).pipe(first()).subscribe();
          //.subscribe((res) => {
          //this.loadSubject();
          //this.loadProposalBrand(this.brand, true);
          //});
      }
      resultRef.next(result);
    });
    return resultRef;
  }
  

  /* EDIT/NEW  DIALOG */
  editDialog<T>(subject, AddComponent: any, options?: any) {
    let resultRef = new Subject();
    let _subject = subject ? subject : {...this.subjectModel};
    let preSelectedSubjects = [];

    //IF MULTIPLE SUBJECT ARE PROVIDED, GET FIRST AND PASS IDS
    if (subject && Array.isArray(subject)){
      
    }else{
      if (_subject.hasOwnProperty("brand_id") && _subject.brand_id == null && !_subject.id )
        _subject.brand_id = this.ctx_dependency?.id;      
    }

    //SET BRAND ID IF NEEDED
    

    ////console.log('USING LIBRARY', dialogConfig.panelClass, subject)

    //DIALOG CONFIG
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;        
    dialogConfig.data = {};
    dialogConfig.data.isLibraryOpen = !subject ? true :  false;
    dialogConfig.data[this.subject] = _subject;
    dialogConfig.data[`${this.subject}Service`] = this;
    dialogConfig.data['preSelectedIDs']= preSelectedSubjects;
    dialogConfig.data['options'] = options;
    const dialogRef = this.dialog.open(AddComponent, dialogConfig);
    dialogRef.componentInstance['config'] = dialogConfig;
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe((result) => {
        console.log('result123',result);


        if (result && result.status === true) {
          resultRef.next({
            action: "update",
            subject: <T>result[this.subject]
          });
          console.log( '-----update')
        } else if (result && result.status === "delete") {
          this.deleteDialog([result[this.subject].id],options, dialogConfig.data[`${this.subject}Service`])
            .pipe(first())
            .subscribe((res) => {
              result[this.subject]._ACTION = 'deleted';
              resultRef.next({
                action: "delete",
                subject: result[this.subject]
              });  
            });
          //resultRef.next();
          console.log( '-----delete')
        } else if(result && result.status === "pdelete") {
          resultRef.next({action: "pdelete",subject: result});
          console.log( '-----pdelete')
        } else if(result && result.status === "group-delete") {
          resultRef.next({action: "group-delete",subject: result});
          console.log( '-----delete-group')
        } else if(result && result.status === "load") {
          resultRef.next({action: "load", subject: result});
          console.log( '-----load')
        } else if(result && result.status === "paid") {
          console.log('paid inside')
          resultRef.next({action: "paid",subject: result});
          console.log( '-----paid')
        } else if(result && result.status === "pass") {
          resultRef.next({action: "pass",subject: result});
          console.log( '-----pass')
        }
      });
    return resultRef;
  }

  /* Send Dialog*/
  sendDialog<T>(subject, SendComponent, options?) {
    let resultRef = new Subject();
    let _subject = subject ? subject : this.subjectModel;
    const dialogConfig = new MatDialogConfig();
    
    dialogConfig.data = {};
    dialogConfig.data.subject = this.subject;    
    dialogConfig.data[this.subject] = _subject;
    dialogConfig.data['options'] = options;
    console.log('send dialog',dialogConfig.data['options']);

    //this is a switch for non standard endpoints
    dialogConfig.data.endpoint = options?.endpoint || null;    
    dialogConfig.data['emit'] = (result, options?) => {

      if(options?.autoPreview) return this.preview(result.email,dialogConfig.data.endpoint).pipe(first());

      this
        .preview(result.email,dialogConfig.data.endpoint)
        .pipe(first())
        .subscribe((preview) => {
          var emailPreviewWindow = window.open();
          emailPreviewWindow.document.write(preview);
          emailPreviewWindow.focus();
        });
    };    

    const dialogRef = this.dialog.open(SendComponent, dialogConfig);
    dialogRef
      .afterClosed()
      .subscribe((result) => {
        if (result && result.status === true) {
          this
            .send(result.email,dialogConfig.data.endpoint)
            .pipe(first())
            .subscribe((result) => { 
              resultRef.next(result);
            });
        }
    });
    return resultRef;
  }

  //////////////////////////////////////////////////////////
  // HELPERS
  //////////////////////////////////////////////////////////
  prepDate(subject){
    // if (subject && subject.due_date && subject){
    //   return subject.due_date = moment(subject.due_date).startOf('day').format("YYYY-MM-DD HH:mm:ss");
    // }
  }
  /* Fix Date */

  //////////////////////////////////////////////////////////
  // UI REFERENCE
  //////////////////////////////////////////////////////////

  /* TRASH */
  /* Trash function is for the frontend */
  // trash(id?){
  //   let ids = id ? [id] : this.vaultTable.selection.selected.map((p) => p.id);
  //   this.deleteDialog(ids);
  // }

  private formatCollection(collection){
    return collection;
  }
}
