<div class="vault-modal" [class.with-preview]="hasPreview">
  <form [formGroup]="form">
    <h1 mat-dialog-title>Send {{ data.subject | capitalize }}</h1>

    <div mat-dialog-content >      

      <div class="row">
        <div [ngClass]=" hasPreview ? 'col-sm-5' : 'col-sm-12'">

          <div class="row">
            <div class="col-sm-12">
              <users-select 
                [toolTip]="true"
                [prepopulate]="false"
                [users]="form.controls.to.value" 
                [fillbrand]="bid"
                autofill="true"
                context="brands"
                [contextid]="bid"            
                allowAnnonymous="true"
                title="To"></users-select>    
                <mat-error class="form-group" *ngIf="form.controls.to.invalid">{{ form.controls.to.errors }}</mat-error>
            </div>        
          </div>

          <div class="row">        
            <div class="col-sm-12">
              <users-select 
                [users]="form.controls.replyTo.value" 
                [prepopulate]="false"
                [fillbrand]="bid"
                autofill="true"
                context="brands"
                [contextid]="bid"    
                allowAnnonymous="true"
                title="ReplyTo"
                ></users-select>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">          
                <mat-checkbox 
                  color="primary" 
                  [(ngModel)]="extra" [ngModelOptions]="{standalone: true}">
                  <span class="label">
                      CC & BCC 
                      <span *ngIf="form.controls.cc?.value?.length > 0 || form.controls.ccc?.value?.length > 0">(
                        <span *ngIf="form.controls.cc?.value?.length > 0"> 1 CC </span>
                        <span *ngIf="form.controls.bcc?.value?.length > 0"> 1 BCC </span>
                        )
                      </span>
                  </span>
                </mat-checkbox>                
                <mat-checkbox 
                  *ngIf="data[this.data.subject]?.next_steps"
                  class="ml-2"
                  color="primary" 
                  formControlName="show_next_steps">
                  <span class="label">
                      Add Next Steps                       
                  </span>
                </mat-checkbox>
            </div>
            <div *ngIf="extra" class="col-sm-12">
              <users-select 
                [users]="form.controls.cc.value" 
                [prepopulate]="false"
                [fillbrand]="bid"
                autofill="true"
                context="brands"
                [contextid]="bid"    
                allowAnnonymous="true"
                title="CC"
                ></users-select>
            </div>
            <div *ngIf="extra" class="col-sm-12">
              <users-select 
                [users]="form.controls.bcc.value" 
                [prepopulate]="false"
                [fillbrand]="bid"
                autofill="true"
                context="brands"
                [contextid]="bid"    
                allowAnnonymous="true"
                title="BCC"
                ></users-select>
            </div>
          </div>

          <div class="row mt-2">
            <!-- <div class="col-sm-10">
              <mat-form-field class="w-100">
                <input matInput placeholder="Subject" formControlName="subject" required>
                <mat-hint>Default: {{ data.invoic | json }} - Invoice # {{ data.invoice.invoice_number }}</mat-hint>
              </mat-form-field>
            </div> -->
            <div class="col-sm-12">
              <label class="label">Message</label>
              <button  mat-button mat-icon-button matTooltip="Robot Writter"  (click)="prompt(form,'message')"><mat-icon color="primary">smart_toy</mat-icon></button>
              <ckeditor [editor]="Editor" [config]="vs.editor.config" formControlName="message"></ckeditor>
            </div>
          </div>
        </div>
        <div *ngIf="hasPreview" class="col-sm-7" style="overflow: hidden;"> 
          <div class="preview-container " style="height: 97%;">
            <div class="h-100">
              <div class="preview-header text-center cap label">Email Preview</div>   
              <div class="d-flex h-100">            
                <iframe width="100%" border="0" class="preview-window" #iframe></iframe>                   
              </div>      
            </div>
          </div>
        </div>
      </div>

      <!-- <app-users [users]="brandUsers" role="client"></app-users> -->
      <!-- {{ form | json  }} -->
    </div>

    <mat-dialog-actions>
      <button mat-button (click)="close()">Cancel</button>
      <button  mat-button color="primary" (click)="preview()">Preview</button>
      <button color="primary" [disabled]="form.invalid" mat-raised-button (click)="send()">Send</button>      
    </mat-dialog-actions>
  </form>
</div>