import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { InvoiceModel, CompanyModel, SubjectAction } from '../_models/subjects';
import { UserService } from './user.service';
import { BrandService } from './brand.service';
import { ReplaySubject, Observable, Subscribable, Subject } from 'rxjs';
import { take, first, map, flatMap, tap, switchMap } from 'rxjs/operators';
import * as moment from "moment";
import { AlertService } from '../_services/alert.service';
import { MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AddInvoiceComponent } from '../_component/invoices/add-invoice/add-invoice.component';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { SubjectBaseService } from './subject-base.service';
import { SendEmailComponent } from '../_component/dialogs/send-email/send-email.component';


export interface InvoiceSummary {
  title: string;
  id: string;
  brand_id: string;
  project_id: string;
  nvoice_number: string;
}

export interface HoldingCompanyList {
  name: string;
  brands: string[];
}
export interface InvoiceList {
  invoices: InvoiceSummary[];
  holding_company: HoldingCompanyList[];
}


@Injectable({
  providedIn: "root",
})
export class InvoiceService extends SubjectBaseService {
  // private $invoices: ReplaySubject<InvoiceModel[]> = new ReplaySubject();
  //public cachedBid: number = null;
  //private _invoiceData: InvoiceModel[];
  public companyOverDueDays = null;
  public invoicePenaltyPercent = null;
  private hasNotified = []; //@TODO: might need to fix this
  
  private company: CompanyModel;
  _invoice: InvoiceModel = {
    id: null,
    title: null,
    invoice_number: null,
    type: null,
    printed: false,
    due_date: null,
    total: null,
    deliverables: null,
    content: null,
    project_id: null,
    description: null,
    penalty: false,
    brand_id: null,
    user_id: null,
    status: "active",
    paid_at: null,
    sent_at: null,
  };
  //private subject: string = "invoice";
  //overDueRange = 30;
  //public cached: boolean = false;

  constructor(
    private apiService: ApiService,
    public brandService: BrandService,
    private alertService: AlertService,
    private userService: UserService,
    public dialog: MatDialog,
    private snack: MatSnackBar
  ) {
    super("invoice", apiService, brandService, dialog);
    this.setModel<InvoiceModel>(this._invoice);
  }

  get all(): Observable<InvoiceModel[]> {
    return this.subjectsAll;
  }

  get invoices(): Observable<InvoiceModel[]> {
    return this.userService.userProfile().pipe(
      switchMap(profile => {
        this.companyOverDueDays = profile.company.invoice_overdue_day
        this.invoicePenaltyPercent = profile.company.invoice_penalty_percent
        return this._actives;
      })
    );
  }
  

  // invoicesAll(){
  //   return this.apiService.pager<InvoiceModel>(`invoices-pager`,{});
  // }

  sendEmail(invoice:InvoiceModel){
    const result = this.sendDialog(invoice, SendEmailComponent);
    result
      .pipe(first())
      .subscribe(() => this.loadSubject() );
  }

  sendEmailOverDue(invoice:InvoiceModel){
    
    //invoice-overdue
    const result = this.sendDialog(invoice, SendEmailComponent, {endpoint: 'invoice-overdue'});
    result
      .pipe(first())
      .subscribe(() => this.loadSubject() );
  }
  
  alert(type, _invoice){
    if (this.hasNotified[_invoice.id]) return;
    if (type == "penalty") {
      this.hasNotified[_invoice.id] = true;
      this.alertService.warn(this.penaltyMsg(_invoice));
    }
  }
  
  penaltyMsg(_invoice) {
    return `Invoice <strong>#${_invoice.invoice_number}</strong> is overdue and needs a penalty fee added to it. Edit it Here:`;
  }

  get penaltyAmount() {
    return this.invoicePenaltyPercent
      ? this.invoicePenaltyPercent
      : 1;
  }

  isOverDue(dueDate, status) {    
    var _futureDate = moment().startOf("day");
    var days = moment(dueDate).diff(_futureDate, "days");
    return days < -this.companyOverDueDays && status == "sent";
  }

  markPrinted(_invoice: InvoiceModel) {
    console.log(_invoice.printed);
    return this.api.post<InvoiceModel>(
      `${this.subject}-mark-printed`,
      {invoice: _invoice},
    );
  }

  edit(invoice?: InvoiceModel, options?) {
    let resultRef = new Subject();


    //@TODO FIX DATE
    //also look at the date below and see if I can fix it earlier
    //if (invoice) this.fixDate(invoice);          
    const result = this.editDialog<InvoiceModel>(
      invoice,
      AddInvoiceComponent,
      options
    );
    result.pipe(first()).subscribe((res: SubjectAction<InvoiceModel>) => {    
      const invoice = res.subject;
      console.log(res);
      (res.action == 'update') && this.add<InvoiceModel>(invoice,options)
      .pipe(first())
      .subscribe(res => resultRef.next(invoice));

      if(res.action == 'load') resultRef.next(invoice);
    });

    return resultRef;
  }

  deletePhase(id: Number) {
    return this.api.put<number>(`invoicephase-delete`,id,{})      
  }

  paid(invoice: InvoiceModel){
    this.api
      .get(`${this.subject}-paid/${invoice.id}`)
      .pipe(
        first(),
        tap(()=> this.loadSubject())
      ).subscribe();
  }

  addMany(invoices: InvoiceModel[]){
    return this.api
      .post(`${this.subject}-many`, invoices, false)
      .pipe(tap(() => this.loadSubject()));
  }

  get stats(): Observable<any>{
    return this.api.get(`${this.subject}s-stats`);
  }

  invoiceList(): Observable<InvoiceList>{
    return this.api.get<InvoiceModel[]>(`${this.subject}s-list`);
  }

  previewNotifications(){
    return this.api.get(`${this.subject}-preview-overdue`);
  }

  summary(data?){
    return this.api.post(`${this.subject}-summary`,data);
  }

  avalibleInvoices(includeHC: boolean, HClist, invoiceList, bid: string){
    if(!includeHC) return invoiceList.filter(i => {
      //if brand_id is an array search arra
      if(Array.isArray(i.brand_id)){
        return i.brand_id.includes(bid);
      }else{
        return i.brand_id == bid;
      }
    });
    
    let _hc = HClist.find(hc => hc.brands.includes(bid))?.brands;

    if(!_hc) return invoiceList.filter(i => i.brand_id == bid);

    return invoiceList.filter(i => _hc.includes(i.brand_id));
  }


}
