

<div class="vault-modal">
    <form [formGroup]="form">
        <h1 mat-dialog-title>            
            <ng-container *ngIf="data?.options?.title; else normalTitle">
                {{ data.options.title }}
            </ng-container>
        
            <ng-template #normalTitle><span class="cap">{{ subject }}</span>
        </ng-template>
        </h1>

        <div mat-dialog-content >

            <div class="d-flex">
                <div>
                    <div class="row">
                        <div class="col-sm-6">
                            <mat-form-field class="w-100">
                                <input matInput placeholder="Vendor Name" formControlName="name" required>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <mat-form-field class="w-100">
                                <input matInput placeholder="Phone" type="text" mask='(000) 000-0000' formControlName="phone" >
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6">
                            <mat-form-field class="w-100">
                                <input matInput placeholder="Email" type="email" formControlName="email">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row" style="overflow: hidden;">
                        <div class="col-sm-12">
                        <label class="mat-dialog-title mr-3 pl-0 mb-1 mt-3" for="">Address</label>
                        <app-addresses 
                                [currentAddresses]="data.vendor.addresses" 
                                [form]="form"
                                [hideOptions]="true">
                            </app-addresses> 
                        </div>
                    </div>
                </div>
            </div>

        </div> 
        
        <mat-dialog-actions>
            <button mat-button (click)="close()">Cancel</button>
            <button  color="primary" [disabled]="form.invalid" mat-raised-button (click)="save()">Save</button>            
            <button *ngIf="data?.vendor?.id" mat-button mat color="warn" (click)="delete()">Delete</button>
        </mat-dialog-actions>
    </form>
</div>

