import { Component, OnInit, Inject } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';


@Component({
  selector: "app-media-dialog",
  templateUrl: "./media-dialog.component.html",
  styleUrls: ["./media-dialog.component.scss"],
})
export class MediaDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MediaDialogComponent>
  ) {}

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }
}
