import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray, FormControl } from '@angular/forms';
import { VelvetysoftService } from 'src/app/_services/velvetysoft.service';

@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.scss']
})
export class AddressesComponent {
  addresses: UntypedFormArray;
  @Input() form: UntypedFormGroup;
  @Input() key: string = "addresses";
  @Input() currentAddresses: any[];
  @Input() hideOptions: boolean = false;
  @Input() limit: number = 1;
  states: object[];
  constructor(
    private fb: UntypedFormBuilder,
    private vs: VelvetysoftService
  ){
    this.states = vs.states();
  }

  ngOnInit() {
    this.addresses = this.form.get("addresses") as UntypedFormArray;
    this.currentAddresses && this.currentAddresses.forEach((address, i) => {      
      if(this.currentAddresses.length > this.addresses.length ) this.addresses.push(this.createAddress());
    });
    this.addresses.patchValue(this.currentAddresses);
  }

  createAddress() {
    return this.fb.group({
      id: [""],
      street: [""],
      city: [""],
      state: [""],
      post_code: [""],
      is_primary: [""],
      //is_shipping: [""],
      is_billing: [""],
    });
  }  

  get formAddresses() {
    return (this.form.get(this.key) as UntypedFormArray).controls;
  }

  addAddress(e) {
    e.preventDefault();
    this.addresses = this.form.get(this.key) as UntypedFormArray;
    this.addresses.push(this.createAddress());
  }

  delete(e, i) {
    e.preventDefault();
    this.addresses = this.form.get(this.key) as UntypedFormArray;
    this.addresses.removeAt(i);
  }

  checkedUnique(e, i, inputType) {
    this.addresses = this.form.get(this.key) as UntypedFormArray;
    this.addresses.controls.forEach((item: UntypedFormGroup, j) => {
      if (i != j) {
        item.controls[inputType].patchValue(false);
      }
    });
  }
  
}


