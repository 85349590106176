<div class="asset d-flex align-items-center mx-2 mb-3 p-2 cursor" (click)="gallery(asset)">
  <div class="asset-preview mr-2" 
    [ngClass]="{noThumb: !asset.hasThumb, 'gthumb': asset.g_id, 'unknown': !asset.hasThumb && !asset.g_id}" 
    [style.backgroundImage]="assetThumb | Fn : asset : this"></div>
  <div *ngIf="!onlyImage" class="asset-info" >
    <div class="asset-name" [matTooltip]="asset.title">{{ asset.title }}</div>
    <div class="asset-details">{{ size | Fn : asset.size : this }}</div>    
  </div>
  <div *ngIf="!onlyImage" class="asset-actions">
    <button *ngIf="(currentUser | userCan: 'manage asset')" (click)="$event.stopPropagation()" [matMenuTriggerFor]="moreMenu" class="mat-sm mat-24" mat-icon-button mat-button>
      <mat-icon>more_vert</mat-icon>      
    </button>
    <mat-menu class="drop-menu" #moreMenu="matMenu">
      <button (click)="edit(asset)" mat-menu-item ><mat-icon>edit</mat-icon> Edit</button>
      <button (click)="downloadAsset(asset)" mat-menu-item ><mat-icon>cloud_download</mat-icon> Download</button>
      <button (click)="trash(asset.id)" mat-menu-item ><mat-icon>delete</mat-icon> Trash</button>
    </mat-menu>     
  </div>
</div>