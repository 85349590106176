<div class="container mt-5">        
    <div *ngIf="$brandList">      
      <div class="d-flex">
        <div class="col-sm-3">
          <span *ngIf="brandSelected">
            <app-brand-logo *ngIf="findBrand(brandSelected);let brand" [brand]="brand" format="medium"></app-brand-logo> &nbsp;
          </span>
          <mat-select  placeholder="Brand" [(ngModel)]="brandSelected" (selectionChange)="changeBrand()" class="brand-wrapper">
            <mat-option>- All -</mat-option>
            <mat-option *ngFor="let brand of $brandList" [value]="brand.id">
              <app-brand-logo [brand]="brand"></app-brand-logo> &nbsp; <span>{{brand.name}}</span>
            </mat-option>
          </mat-select>
        </div>
        <div class="col-sm-6">
          <h1 class="h1 text-center">{{ year }} At A Glance</h1>
        </div>
        <div class="col-sm-3 text-right">
          <div class="text-center"> <button mat-button (click)="toggleDashSnap()">Close</button></div>
        </div>
      </div>         

     <div class="d-flex justify-content-center">
        <div class="p-5">
          <div class="d-flex justify-content-between">
            <div class="subhead-bold text-white mb-2">Projects:</div>
            <div class="pl-3"><span class="dot-label light">{{ allProjects.length }}</span></div>
          </div>
          <div class="d-flex justify-content-between" *ngIf="invoices">
            <div class="subhead-bold text-white mb-2">Invoices Issued:</div>
            <div class="pl-3"><span class="dot-label light">{{ invoiceCount() }}</span></div>
          </div>
        </div>
        <div class="p-5">
          <div class="d-flex justify-content-between" *ngIf="invoices">
            <div class="subhead-bold text-white mb-2">Revenue:</div>
            <div class="pl-3"><span class="dot-label light">{{ invoiceRevenue() | currency:'USD':true:'1.2-2' }}</span></div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="subhead-bold text-white mb-2">Expenses:</div>
            <div class="pl-3"><span class="dot-label light">{{ 0 | currency:'USD':true:'1.2-2' }}</span></div>
          </div>
        </div>
      </div>

      <div class="p-4">
        <hr>
      </div>

      <div>
        <div class="h2 text-center">Projects</div>
        <div class="d-flex align-items-center">
          <div class="col-sm-6" *ngIf="allProjects">
            <app-charts [options]="options" [data]="allProjects" type="pie"></app-charts> 
          </div>
          <div class="p-5">
            <div class="d-flex justify-content-between">
              <div class="subhead-bold text-white mb-2">Projects:</div>
              <div class="pl-3"><span class="dot-label active">{{ projectsByStatus('active').length }}</span></div>
            </div>
            <div class="d-flex justify-content-between">
              <div class="subhead-bold text-white mb-2">Completed Projects:</div>
              <div class="pl-3"><span class="dot-label complete">{{ projectsByStatus('complete').length }}</span></div>
            </div>            
          </div>
        </div>        
      </div>

      <div class="p-4">
        <hr>
      </div>

      <div>
        <div class="h2 text-center">Revenue</div>
        <div class="d-flex align-items-center">
          <div class="col-sm-6" *ngIf="invoices">
            <div *ngIf="invoiceTotal();let total">
              <div>Revenu By Month</div>
              <div *ngIf="total.length" >
                <app-charts  [options]="optionsRev" [data]="total" type="bar-stats"></app-charts>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div>Revenu By Project</div>
            <app-charts [options]="options" [data]="allProjects" ></app-charts>
          </div>
        </div>
      </div>

    </div>
</div>
