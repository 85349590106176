import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DATE_FORMATS, DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { TimesheetModel } from 'src/app/_models/subjects';
import { MatCalendarCellCssClasses, MatCalendar } from '@angular/material/datepicker';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { delay, first, map, switchMap, tap } from 'rxjs/operators';
import { ConfirmComponent } from '../../dialogs/confirm/confirm.component';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-add-timsheet-invoice',
  templateUrl: './add-timsheet-invoice.component.html',
  styleUrls: ['./add-timsheet-invoice.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})
export class AddTimsheetInvoiceComponent implements OnInit{
  form: UntypedFormGroup;  
  subject: string = "timesheet-invoice";
  sheetsSun:  Subscription;
  $timesheetInvoice:  any;//Observable<any>;
  sheets: TimesheetModel[] = [];
  expensesValid: boolean = true;
  month: any;
  $month: Subject<any> = new Subject();
  loading: boolean = true;
  taxable: boolean = false;
  $prettyMonth: Observable<any>;
  timeSheetInvoice = {
    totalHours: 0,
    total: 0,
    rate: 120
  };
  submitted: boolean = false;
  @ViewChild(MatCalendar) _cal: MatCalendar<any>;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddTimsheetInvoiceComponent>,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private snack: MatSnackBar,
  ) {
    this.form = fb.group({
      id: [""],
      user_id: [""],      
    });
    this.$prettyMonth = this.$month.pipe(delay(200));
  }  
  

  ngOnInit() {    
    this.$prettyMonth.subscribe((month) => {
      this.loading = true;
      this.month = month;
      this.data['timesheet-invoiceService'].preview({
        range: month, 
        overview: 'week', 
        imp: this.data.options?.preview?.imp,
        taxable: this.taxable
      }).pipe(first()).subscribe((res:any) => {
        this.loading = false;
        this.submitted = (res?.hasInvoice) ? true : false;
        this.$timesheetInvoice = res;
        this.taxable = res.taxable;
      });
    });    
 
    
    

    
    this.sheets = this.data.options.sheets;
    this.$month.next(this.firstBillingDay(this.data.options.preview.range));
    

    //total worked hours on this.sheets
    // let totalHours = 0;
    // this.sheets.forEach((sheet) => {
    //   totalHours += sheet.time_worked;
    // });    
    //this.timeSheetInvoice.totalHours = totalHours;
    //this.timeSheetInvoice.total = totalHours * this.timeSheetInvoice.rate;
    //this.$timesheetInvoice = this.data['timesheet-invoiceService'].preview(this.data.options.preview)
    
    //this.$timesheetInvoice = this.data['timesheet-invoiceService'].preview({range: this.month, overview: 'week'})    
   
    
  }

  toggleTax(){
    //this.taxable = !this.taxable;
    console.log('CHANGE');
    this.refresh();
  }

  isExpenseValid(formStatus){
    this.expensesValid = formStatus;    
    if(this.expensesValid) this.refresh();
  }

  unsavedCheck(){
    if(!this.expensesValid){
      this.snack.open('Please save or cancel your expenses before leaving.','',{duration: 3000});
      return false;
    }else{
      return true;
    }

  }

  //WE BILL BY START OF WEEK IN A MONTH
  firstBillingDay(date){
    return date.clone()
      .startOf('month')
      .add(6, 'day')
      .startOf('isoWeek');
  }

  addTax(){

  }

  refresh(){
    this.loading = true;
    let month = this.firstBillingDay(this.month);
    this.$month.next(month);
  }

  prevMonth() {
    if(!this.unsavedCheck()) return;
    this.loading = true;
    let month = this.firstBillingDay(this.month.clone().subtract(1, 'month'));
    this.$month.next(month);
  }

  nextMonth() {
    if(!this.unsavedCheck()) return;
    this.loading = true;
    let month = this.firstBillingDay(this.month.clone().add(1, 'month'));
    this.$month.next(month);
  }

  submit() {
    if(!this.unsavedCheck()) return;
    var _self = this;    
    this.dialog.open(ConfirmComponent, {
      data: {        
        type: "expense-paid",
        title: `Invoice Reference`,
        content: `If you would like to reference your internal invoice number, please enter it below. Otherwise, leave blank.`,
        label: `Invoice # Reference`,
        ids: [],
        payment_ref: '',
        button: `Submit`,
      },
    }).afterClosed().subscribe((result) => {  
      let invoice_ref = (result?.data?.payment_ref)
        ? result?.data?.payment_ref
        : null;
      if(result?.status == "paid") _self.data['timesheet-invoiceService']
      .submit({
        range: _self.month, 
        overview: 'week', 
        imp: _self.data.options?.preview?.imp,
        user_invoice_ref: invoice_ref,
        taxable: this.taxable
      })
      .subscribe((res) => {        
        //console.log(res);
        _self.dialogRef.close();        
      }, (err) => {
        if(err.error?.data?.action == 'refresh') _self.refresh();        
      });
    });    
    //this.dialogRef.close({ status: true });
  }
  viewInvoice(tsi) {    
    this.data['timesheet-invoiceService'].view(tsi);
  }
  preview() {
    if(!this.unsavedCheck()) return;
    this.data['timesheet-invoiceService'].makePreview({
      range: this.month, 
      overview: 'week', 
      imp: this.data.options?.preview?.imp,
      taxable: this.taxable
    })
  }
  close() {
    if(!this.unsavedCheck()) return;
    this.dialogRef.close();
  }

}
