
<div class="vault-modal">
  <button mat-button mat-button-base class="float-right" (click)="close()">
    <mat-icon class="mat-sm">close</mat-icon>   
  </button>
    <form [formGroup]="form">
      <h1 mat-dialog-title>
        <ng-container *ngIf="data?.options?.title; else normalTitle">
          {{ data.options.title }}
        </ng-container>

        <ng-template #normalTitle>
          <span *ngIf="data.expense.title; else addTitle">Edit</span>
          <ng-template #addTitle>
            Add
          </ng-template>
          Expense {{ data.title }}
        </ng-template>
      </h1>

      <div mat-dialog-content *ngIf="( $userProfile | async ); let profile">
       
        <div *ngIf="( $brandList | async ); let brands">
          <mat-tab-group>
            <mat-tab label="Expense" *ngIf="data?.options?.view == 'manage expense' && data?.expense?.id">
              <div class="mt-4 px-4" *ngIf="data.expense.expenses[0];let expense">
                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <div class="mb-3"><meta-display subject="expense" [item]="expense" type="statuses" [id]="expense.status"></meta-display></div>
                        <div><span class="label mr-2 min-w-100">Title:</span> {{ expense.title }}</div>
                        <div *ngIf="expense.sub_category !== 'vendor'"><span class="label mr-2 min-w-100">Vendor:</span> {{ expense.from }}</div> 
                        <div><span class="label mr-2 min-w-100">Amount:</span> {{ data.expense.total | currency }}</div>
                        <div><span class="label mr-2 min-w-100">Date:</span> {{ expense.date | date }}</div>
                        <div><span class="label mr-2 min-w-100">Type:</span> <meta-display subject="expense" [item]="expense" type="types" [id]="expense.type"></meta-display></div>
                        <div><span class="label mr-2 min-w-100">Payment Ref:</span> {{ expense.payment_ref }}</div>
                        

                      </div>        
                    </div>
                    <div class="col-sm-12">                      
                      <!-- <button class="mr-1" *ngIf="!data.expenseService.io.isConnected" (click)="print([data.expense.id])" mat-raised-button color="primary">Print</button>
                      <button *ngIf="data.expenseService.io.isConnected" class="mr-1" (click)="printAll(data.expense)" mat-raised-button color="primary">Print</button> -->

                      <!-- <button class="my-2" mat-button class="mr-2" (click)="view(invoice)" color="primary">View PDF</button>
                      <button class="my-2" mat-button class="mr-2" (click)="view(invoice)" color="primary">View PDF</button> -->
                      <button mat-raised-button *ngIf="!data.expenseService.io.isConnected" class="mr-1" (click)="print([data.expense.id], true)" color="primary">View PDF</button>
                      <button mat-raised-button *ngIf="data.expenseService.io.isConnected" class="mr-1" (click)="printAll(data.expense, true)" color="primary">View PDF</button>


                      <button mat-raised-button *ngIf="!data.expenseService.io.isConnected" class="mr-1" (click)="print([data.expense.id])" color="primary">Download PDF</button>
                      <button mat-raised-button *ngIf="data.expenseService.io.isConnected" class="mr-1" (click)="printAll(data.expense)" color="primary">Download PDF</button>
                      <!-- FIX FOR UNUSUAL CASES -->
                      <button mat-raised-button *ngIf="checkStatusesAlignment | Fn : data.expense" class="mr-1" (click)="alignStatus(data.expense)" color="warn">Correct Status</button>
                      <button *ngIf="expense.status == 'submitted'" class="mr-1" (click)="paid()" mat-raised-button color="accent"><mat-icon>check</mat-icon> Mark Paid</button>
                      <button *ngIf="expense.id && data.expenseService.io.isConnected" class="mr-1" mat-button mat color="primary" (click)="downloadReceipt(data.expense)">Download Receipt</button>
                      <button *ngIf="data.expense.deleted_at" class="mr-1" (click)="restore()" color="primary" mat-button  > Restore</button>
                      <button *ngIf="!data.expense.deleted_at && data.expense.id" class="mr-1" mat-button mat color="warn" (click)="delete()">Delete</button>
                      <button *ngIf="data.expense.deleted_at" class="mr-1" (click)="permDelete()" mat-button color="warn" > Permanently Delete</button>                      
                    </div>
                  </div>
                  <div class="hr mt-3"></div>
                </div>

                <div class="form-group" *ngIf="expense.project_id">
                  <div class="block-title-label cap-init">Project for this Expense </div>
                  <div class="row p-0  align-items-center m-0 table-text cell" *ngIf="findBrandProjects(brands,expense.brand_id,expense.project_id); let project" >
                      <div class="col-sm-8 cursor" (click)="navTo('/dashboard/project/'+project.id)"> 
                        <span >{{ project.name }}</span>
                      </div>
                      <div class="col-sm-4" >
                        <button mat-button (click)="navTo('/dashboard/project/'+project.id)" color="primary">View Project</button>
                      </div>   
                  </div>               
                </div>

                

                <div class="block-title-label cap-init">History for this Expense</div>
                <app-activity-log
                  [ids]="[expense.id]"
                  subject="expense"
                  exclude=""
                  ></app-activity-log>
              </div>
              
            </mat-tab>
            <mat-tab  label="{{ (data?.expense?.id) ? 'Edit Expense' : 'Add Expense' }}">
              <div class="mt-4 mx-0 px-4">
                <div  [formGroup]="form">
                  <input type="hidden"  matinput formControlName="category">
                  <div class="row form-group">                                
                      <div class="col-sm-6">
                          <div class="form-group">
                              <mat-form-field class="w-100">
                                  <input matInput name="title" [placeholder]="lang.expense.new.title.field.label" formControlName="title">
                              </mat-form-field>
                          </div>
                      </div>        
                      <div class="col-sm-6">
                          <div class="form-group">
                              <mat-form-field class="w-100">
                                  <input matInput [matDatepicker]="picker" [placeholder]="lang.expense.new.date.field.label" formControlName="date">
                                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                  <mat-datepicker #picker></mat-datepicker>                                        
                              </mat-form-field>
                              <div class="label label-snug-mat">{{ lang.expense.new.date.field.hint }}</div>
                          </div> 
                      </div>                                
                      <div class="col-sm-12">
                          <div class="row">
                              <div class="col-sm-4">
                                  <div class="form-group">
                                      <mat-form-field class="w-100">
                                          <mat-select [placeholder]="lang.expense.new.organize.brand.field.label" formControlName="brand_id">
                                              <mat-option [value]="-1" >
                                                  <app-brand-logo [brand]="profile.company"></app-brand-logo> &nbsp; {{profile.company.name}}
                                              </mat-option>
                                              <mat-option *ngFor="let brand of brands | keyvalue" [value]="brand.value.id">
                                                  <app-brand-logo [brand]="brand.value"></app-brand-logo> &nbsp; {{brand.value.name}}
                                              </mat-option>
                                          </mat-select>
                                      </mat-form-field>
                                      <div class="label label-snug-mat">{{ lang.expense.new.organize.brand.field.hint }}</div>
                                  </div>                                  
                              </div>
                              <div class="col-sm-4">
                                  <div class="form-group">
                                      <div *ngIf="form.value.brand_id; let bid">
                                          <div *ngIf="bid != -1">
                                              <mat-form-field class="w-100" *ngIf="findBrandProjects(brands,bid); let projects">
                                                  <mat-select [placeholder]="lang.expense.new.organize.project.field.label" formControlName="project_id">
                                                      <mat-option value="">- None -</mat-option>
                                                      <mat-option *ngFor="let project of projects | keyvalue" [value]="project.value.id">
                                                          {{project.value.name}}
                                                      </mat-option>
                                                  </mat-select>
                                              </mat-form-field>
                                              <div class="label label-snug-mat">{{ lang.expense.new.organize.project.field.hint }}</div>                                 
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-sm-4" *ngIf="form.value.category !== 'purchase'">
                                  <div class="form-group">
                                      <div *ngIf="form.value.brand_id; let bid">
                                          <ng-container *ngIf="avalibleInvoices(bid); let invoices">
                                              <ng-container *ngIf="bid !== -1">
                                                  <mat-form-field class="w-100" >
                                                      
                                                      <mat-select [placeholder]="lang.expense.new.organize.invoice.field.label" formControlName="invoice_id">
                                                          <mat-option>- None Available -</mat-option>
                                                          <mat-option matTooltipPosition="left" [matTooltip]="invoice.title" *ngFor="let invoice of invoices" [value]="invoice.id">
                                                            #{{invoice.invoice_number}} - {{invoice.title}}
                                                          </mat-option>
                                                      </mat-select>
                                                  </mat-form-field>
                                                  <div class="label label-snug-mat">{{ lang.expense.new.organize.invoice.field.hint }}</div>
                                                  <div>
                                                    <mat-checkbox class="label" [ngModelOptions]="{standalone: true}" [(ngModel)]="includeHC">Include Holding Company</mat-checkbox>
                                                  </div>
                                              </ng-container>
                                          </ng-container>
                                      </div>
                                  </div>                                  
                              </div>
                          </div>  
                      </div>                      
                      <div class="col-sm-6" *ngIf="form.value.category !== 'purchase'">
                        <div class="form-group">
                              <mat-checkbox formControlName="expensed_seperatly">Expensed Seperate from project</mat-checkbox>
                              <div class="label ">This will remove the expensefrom being tracked on the project budget</div>
                          </div>
                      </div>
                      <div class="col-sm-4" *ngIf="form.value.category !== 'purchase'">
                        <div class="form-group">
                            <mat-form-field class="w-100">
                                <input matInput name="payment_ref" placeholder="Payment Ref" formControlName="payment_ref">                                
                            </mat-form-field>
                            <div class="label label-snug-mat">Check # for payment issued or other notes</div>
                        </div>                        
                      </div>
                      <div class="col-sm-6" *ngIf="form.value.category !== 'purchase'">
                        <div class="form-group">
                          <mat-form-field class="w-100" >
                            <mat-select  placeholder="Expense Type" formControlName="sub_category" required>
                              <mat-option *ngFor="let type of subcategoryTypes" [value]="type.value">
                                      {{type.title}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>                
                            
                  </div>
              
                  <div formArrayName="expense_group"    
                      class="mb-3 exp-group py-3 rel"                              
                      *ngFor="let expense of formExpenses; let i = index"
                      >                      

                      <div class="" [formGroup]="expense">                                                                        
                              <div class="col-sm-12 block-content">                                            
                                  <div class="form-group">
                                      <div class="row">
                                          <div class="col-sm-4">
                                            <ng-container *ngIf="form.value.sub_category == 'vendor'">

                                              <div class="form-group" *ngIf="($vendors | async); let vendors">
                                                <mat-form-field class="w-100" style="padding-bottom:5px!important">
                                                  <mat-select  placeholder="Vendor" formControlName="vendor_id" >
                                                    <mat-option [value]="" >- None - </mat-option>
                                                    <mat-option *ngFor="let vendor of vendors" [value]="vendor.id">
                                                        {{vendor.name}}
                                                    </mat-option>
                                                    
                                                  </mat-select>    
                                                  <div matSuffix>
                                                    <button class="p-0" mat-button color="primary" (click)="addVendor($event)"><mat-icon>add</mat-icon></button>
                                                  </div>                                              
                                                </mat-form-field>                                                
                                              </div>                                                                                            
                                            </ng-container>

                                            <div class="form-group" *ngIf="form.value.sub_category !== 'vendor'">
                                              <mat-form-field class="w-100">
                                                  <input matInput name="from" [placeholder]="lang.expense.new.from.field.label" formControlName="from">
                                              </mat-form-field>
                                              <div class="label label-snug-mat">{{ lang.expense.new.from.field.hint }}</div>
                                          </div>

                                          </div>
                                          <div class="col-sm-4">
                                              <div class="form-group">
                                                  <mat-form-field class="w-100">
                                                      <mat-select  [placeholder]="lang.expense.new.category.field.label" formControlName="type">
                                                          <mat-option *ngFor="let type of types" [value]="type.value">
                                                                  {{type.title}}
                                                          </mat-option>
                                                      </mat-select>
                                                  </mat-form-field>
                                                  <div class="label label-snug-mat">{{ lang.expense.new.category.field.hint }}</div>
                                              </div>
                                          </div>
                                          <div class="col-sm-4">
                                              <div class="form-group">
                                                  <mat-form-field class="w-100">
                                                      <span matPrefix>$ &nbsp;</span>
                                                      <input 
                                                          matInput 
                                                          [placeholder]="lang.expense.new.total.field.label" 
                                                          autocomplete="off" 
                                                          formControlName="total" 
                                                          mask="separator.2"
                                                          thousandSeparator="," 
                                                          required>
                                                  </mat-form-field>
                                                  <div class="label label-snug-mat">{{ lang.expense.new.total.field.hint }}</div>
                                              </div>
                                          </div>

                                          <div class="col-sm-8">
                                              <mat-form-field class="w-100">
                                                  <textarea matInput [rows]="3" [placeholder]="lang.expense.new.notes.field.label" formControlName="notes"></textarea>
                                              </mat-form-field>
                                              <div class="label">{{ lang.expense.new.notes.field.hint }}</div>
                                          </div>

                                          <div class="col-sm-4">
                                              <div class="label">{{ lang.expense.new.receipt.field.label }}</div>
                                              <app-file-upload 
                                                  type="receipt"
                                                  class="might-be-trans"
                                                  [text]="lang.expense.new.receipt.field.action"
                                                  (uploadComplete)="uploadReceipt($event,i)" 
                                                  [_file]="form.value.expense_group[i].receipt">
                                              </app-file-upload>
                                              <div class="label">{{ lang.expense.new.receipt.field.hint }}</div>
                                          </div>                                                    
                                      </div>                                                                                    
                                  </div>
                                  
                                  
                              </div>  
                              <div class="col-sm-12 text-right">
                                <button *ngIf="expense.value.id" mat-button color="warn" (click)="deleteItem(expense.value,i)">Delete Item</button>
                                <button *ngIf="!expense.value.id" mat-button color="warn" (click)="remove(i)">Remove Item</button>
                              </div>                                  
                      </div>
                  </div>
                </div>

                <div class="form-group ">
                    <button mat-button color="primary" (click)="add()"><mat-icon>add</mat-icon> Add another Expense</button>
                </div>

                <div class="my-4">                       
                  <button [disabled]="form.invalid" mat-raised-button color="primary" (click)="save()">
                      <ng-container *ngIf="!form.value.id">Create</ng-container> <ng-container *ngIf="form.value.id">Save</ng-container> Expense Report
                  </button>
              </div>

              </div>              
            </mat-tab>
          </mat-tab-group>
        </div>

        

        
      </div>
  
      
    </form>
  </div>
  