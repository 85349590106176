<div class="d-flex h-100 align-items-center">
  <div class="container">
    <div class="text-center" *ngIf="!loading; else loader">
      <h5>Reset your password</h5>
      <div class="row">
        <div class="col-sm-6 offset-sm-3">
          <app-alert></app-alert>
        </div>
        <div class="col-sm-4 offset-sm-4">          
          <form (ngSubmit)="reset()" #form="ngForm">
            <div class="form-group">
              <mat-form-field class="w-100">
                <input matInput placeholder="Email" type="email" name="email" [(ngModel)]="model.email" required />
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field class="w-100">
                <input matInput placeholder="New password" type="password" name="password" [(ngModel)]="model.password" required />
              </mat-form-field>
            </div>            
            <div class="form-group">
              <mat-form-field class="w-100">
                <input matInput placeholder="Confirm password" type="password" name="password_confirmation" [(ngModel)]="model.password_confirmation" required />
              </mat-form-field>
            </div>
            <input type="hidden" [(ngModel)]="model.token" name="token" > 
            <button mat-flat-button color="primary" type="submit" [disabled]="submitted || !form.valid">Submit</button> <a mat-button color="primary" (click)="login()" >Back</a>       
          </form>
        </div>
      </div>
    </div>

    <ng-template #loader>
      <mat-spinner color="primary" class="mx-auto loader"></mat-spinner>
    </ng-template>
  </div>
</div>