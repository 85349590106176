<ng-container *ngIf="brand?.logo;else noLogo">
  <div 
    class="brand-logo-wrap" 
    [ngClass]="'blw-'+format"  
    [ngStyle]="{'background-color': brand.color}"
    matTooltip="{{ (tooltip) ? brand.name : null }}"
    matTooltipPosition="right">
    <div *ngIf="format == 'large'" [style.background-color]="brand.color" class="brand-tile-bg" [style.background-image]="'url(' + (cdn+brand.logo ) + ')'"></div>
    <img       
      class="brand-logo"
      [ngClass]="getCss()"  
      [src]="cdn+brand.logo"/>
    </div>
</ng-container>
<ng-template #noLogo>    
  <span 
    *ngIf="brand?.name"
    class="no-brand-logo" 
    [attr.data-displayname]="displayName()"
    [ngClass]="getCss()"
    [ngStyle]="{'background-color': brand.color}"></span>
</ng-template>