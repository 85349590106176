<div class="container">
    <div class="block">

        <div class="row" *ngIf="( $userProfile | async ); let profile;">
            <div class="col-sm-12">
                <div class="block block-cool">
                    <div class="block-title noborder mb-4">
                        <div class="block-title-label" >
                            <div class="d-flex align-items-center">
                                <div class="d-flex align-items-center col-sm-6">
                                    <user-avatar class="exlrg mr-3" [showName]="false" [user]="profile.user"></user-avatar> 
                                    <div>
                                        {{ profile.user.name }}
                                        <div class="subhead" *ngIf="profile.user.position" style="text-transform: initial;letter-spacing: 0;">{{ profile.user.position }}</div>
                                        <div class="label" style="text-transform: initial;letter-spacing: 0;">{{ profile.user.email }}</div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-5">
                            <!-- STAFF -->
                            <div class="row table-text" *ngIf="($staff | async)?.ts let staff">
                                <div *ngFor="let employee of staff" class="mb-2">
                                    <div class="col-sm-12 d-flex align-items-center" >
                                    <user-avatar class="mr-1" [user]="employee.user" [showName]="true"></user-avatar>
                                    OOO : <span class="dot-label hold mx-1" *ngFor="let date of employee.dates">{{ date | amDateFormat: 'ddd' }}</span>
                                    </div>
                                </div>              
                            </div>
                        </div>
                    </div>

                    <div *ngIf="profile | theUserIs: 'admin';else defaultpage">
                        <app-widgets></app-widgets>
                    </div>

                    <ng-template #defaultpage>
                        <mat-tab-group *ngIf="($myProjects | async); let projects">
                            <mat-tab label="Active Projects">
                                <ng-template *ngTemplateOutlet="projectTemplate; context: { $implicit: {projects:projects, pstatus: 'active'} }"></ng-template>
                            </mat-tab>
                            <!-- <mat-tab label="Pending Projects">
                                <ng-template *ngTemplateOutlet="projectTemplate; context: { $implicit: {projects:projects, pstatus: 'hold'} }"></ng-template>
                            </mat-tab>
                            <mat-tab label="Complete Projects">
                                <ng-template *ngTemplateOutlet="projectTemplate; context: { $implicit: {projects:projects, pstatus: 'complete'} }"></ng-template>
                            </mat-tab> -->
                        </mat-tab-group>

                        <ng-template #projectTemplate let-data>
                            <div class="mt-3">
                                <div class="mb-3" style="padding-left: 25px;padding-right: 25px;" >
                                    <div class="row">
                                        <div class="label col-sm-4">Project</div> 
                                        <div class="label col-sm-1">Type</div> 
                                        <div class="label col-sm-1 p-0">Team</div> 
                                        <!-- <div class="label col-sm-2">Checkin</div>  -->
                                        <div class="label col-sm-3 p-0">Due</div> 
                                        <div class="label col-sm-3">Progress</div> 
                                    </div>
                                </div>
                                <div class="block-content mb-2 p-3" *ngFor="let project of data.projects | vhFilter: {status:data.pstatus}">                                
                                    <div class="row align-items-center">                                           
                                        <div class="col-sm-4 title-section click d-flex align-items-center" (click)="navTo(project.id)">                                        
                                            <app-brand-logo class="pr-2" [bid]="project.brand_id"  format="small"></app-brand-logo> 
                                            {{ project.name }}
                                        </div>
                                        <div class="col-sm-1 subhead cap">
                                            {{ project.type }} 
                                        </div>
                                        <div class="col-sm-1 p-0">
                                            <app-users  class="inline" [users]="project.users"></app-users>
                                        </div>
                                        <!-- <div class="col-sm-2">
                                            <div *ngIf="(nextRound | Fn: project.rounds.length : project.est_round_dates); let checkin">                                            
                                                <div 
                                                    style="margin-left:0;"
                                                    class="date"                                                
                                                    matTooltip="{{ checkin | date }}"
                                                    matTooltipPosition="right"
                                                    >{{  checkin | amTimeAgo }}</div>                                                                                            
                                            </div>
                                        </div> -->
                                        <div class="col-sm-3 p-0">                                        
                                            <span 
                                                class="date"
                                                matTooltip="{{ project.due | date }}"
                                                matTooltipPosition="right"
                                                >{{ project.due | amTimeAgo }}</span>
                                        </div>
                                        <div class="col-sm-3">
                                            <div *ngIf="data.pstatus == 'active'; else label">
                                                <div class="row m-0 align-items-center" *ngIf="!project.ongoing; else ongoing">
                                                    <ng-container *ngIf="isOverDue | Fn : project; else overdue">
                                                        <div class="col-sm-9 p-0">
                                                            <mat-progress-bar 
                                                                mode="determinate" 
                                                                class="vh-progress"
                                                                value="{{ progress | Fn: project }}"></mat-progress-bar>
                                                        </div>
                                                        <div class="col-sm-3 p-0 text-center">
                                                            <span class="date mx-auto">{{ progress | Fn: project }}%</span>
                                                        </div>
                                                    </ng-container>
                                                    <ng-template #overdue>
                                                        <span class="dot-label overdue">Overdue</span>
                                                    </ng-template>
                                                </div>
                                                <ng-template #ongoing>
                                                    <label class="subhead m-0"><span class="dot-label active">Ongoing</span></label>
                                                </ng-template>
                                            </div>
                                            <ng-template #label>
                                                <meta-display subject="project" type="statuses" [id]="project.status"></meta-display>
                                            </ng-template>
                                        </div>                                
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-template>
                    
                </div>
            </div>
        </div>

    </div>
</div>
