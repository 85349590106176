import { Component } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from '../../../_services/user.service';
import { VelvetysoftService } from 'src/app/_services/velvetysoft.service';
import { Setting, Settings } from 'src/app/_models/subjects';
import { WidgetService } from 'src/app/_services/widget.service';
import { first, tap } from 'rxjs/operators';

@Component({
  selector: 'app-widgets',
  templateUrl: './widgets.component.html',
  styleUrls: ['./widgets.component.scss']
})
export class WidgetsComponent {
  $widgets: BehaviorSubject<any> = new BehaviorSubject({});
  widgetsAvalible: any;//Setting[];
  widgetsSelected: any[] = [];
  colors = [];
  options = {
    responsive: true,
    lineTension: 0,
    tooltips: {
      callbacks: {
        title: function(t, d) {
            return d.labels[t[0].index];
        },
        label: function(t,d){
          return '$'+parseInt(d.datasets[0].data[t.index]).toLocaleString();
        }

      }
    },
    legend: {
      position: "right",
    },
    scales: {
      yAxes: [{
        //currency
        ticks: {
          callback: function (value, index, values) {
            return '$' + value;
          },
        },
      }],
      xAxes: [
        {
          // type: "time",
          // distribution: "linear",
          // time: {
          //   unit: "month",
          // },
        },
      ],
      //infowindow on hover currency

    },
  };
  constructor(
    private userService: UserService,
    private vs: VelvetysoftService,
    private widgetService: WidgetService
  ) { }

  ngOnInit() {
    this.userService.userProfile()
      .pipe(first())
      .subscribe( user => {        
        this.colors.push( user.company.color)          
        this.colors.push( this.vs.increaseBrightness(user.company.color,100))
      });

    this.widgetsAvalible = this.groupWidgets(this.vs.getSettings('widgets')?.types);       
    this.widgetService.widgets({})
      .pipe(tap((data) => {        
        this.$widgets.next(data);
        this.widgetsSelected = Object.keys(data);
    })).pipe(first()).subscribe();
  }

  updateWdigets(){
    this.widgetService
      .add({widgets: this.widgetsSelected})
      .pipe(first())
      .subscribe((data) => {
        this.widgetsSelected = Object.keys(data);        
        this.$widgets.next(data);
      });
  }

  groupWidgets(_widgets) {
    const groups = {};
    for (const key in _widgets) {
      const widget = _widgets[key];
      if (!groups[widget.group]) {
        groups[widget.group] = [];
      }
      groups[widget.group].push({ key, ...widget });
    }

    return Object.keys(groups).map(group => ({
      group,
      widgets: groups[group]
    }));
  }
}
