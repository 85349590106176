import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BrandService } from 'src/app/_services/brand.service';
import { VelvetysoftService } from 'src/app/_services/velvetysoft.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray  } from '@angular/forms';
import { ConfirmComponent } from '../dialogs/confirm/confirm.component';

@Component({
  selector: 'app-add-holding-company',
  templateUrl: './add-holding-company.component.html',
  styleUrls: ['./add-holding-company.component.scss']
})
export class AddHoldingCompanyComponent implements OnInit {
  form: UntypedFormGroup;
  error: any = false;
  states: object[];
  subject: string = "holding-company";
  constructor(
    private dialogRef: MatDialogRef<AddHoldingCompanyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    public vs: VelvetysoftService,
    private dialog: MatDialog,
  ) { 
    this.form = fb.group({
      id: [""],
      name: ["", Validators.required],
      //brand_id: [""],
      addresses: this.fb.array([]),
    });    
  }

  

  ngOnInit(): void {
    console.log(this.data);
    this.form.patchValue(this.data[this.subject]);
  }

  save(){
    if (this.form.valid) {
      this.form.value.addresses.forEach((address) => {        
        address.is_primary = address.is_primary ? true : false;
        address.is_billing = address.is_billing ? true : false;
      });
      console.log(this.form.value,this.form)
      this.dialogRef.close({status: true, 'holding-company': this.form.value});
    }    
    console.log(this.form.value)
    
  }

  close(){
    this.dialogRef.close({status: false});
  }

  delete(){
    this.dialogRef.close({status: "delete", 'holding-company': this.form.value});
  }

  
}
