import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { NervIoSummary, NervResponse } from '../_models/subjects';
import { tap } from 'rxjs/operators';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Subject } from 'rxjs';
import { VSR } from './api.service';
import { FileuploadprogressService } from './fileuploadprogress.service';
import { FileuploadprogressComponent } from '../_directive/fileuploadprogress/fileuploadprogress.component';
import { VelvetysoftService } from './velvetysoft.service';

@Injectable({
  providedIn: 'root'
})
export class IoService {
  isConnected: boolean = false;
  openRequests: string = null;
  statusSnack = null;
  constructor(
    private socket: Socket,
    private snack: MatSnackBar,
    private fileuploadprogressservice: FileuploadprogressService,
    private vs: VelvetysoftService
  ) {

    this.socket.on('connect', () => {
      this.isConnected = true;
      console.log('IO - Online')
    });
     

    this.socket.on('disconnect', () => {
      this.isConnected = false;
      if(this.openRequests){
        console.log('IO - Offline - Open Request - possible FATAL ERROR', this.openRequests)
        this.snack.open(`You're recent request for  "${this.openRequests}" may have failed. We are restrating a server real fast... `, 'Close', {
          duration: 5000,
        });
      }
      console.log('IO - Offline')
    });

    this.socket.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`);
    });

    this.socket.fromEvent<NervResponse>('error').subscribe( data => {
      this.openRequests = null;
      console.log('IO - Error', data);
      this.snack.open(`${data.message}`, 'Close', {
        duration: 2000,
      });
    });

    this.socket.fromEvent<NervResponse>('message').subscribe( data => {
      this.openRequests = null;
      console.log('IO - Message', data);
      this.snack.open(`${data.message}`, 'Close', {
        duration: 2000,
      });
    });

    this.socket.fromEvent<NervResponse>('status').subscribe( data => {
      this.showStatus(data.message);
      
      if(data.action == 'complete'){
        this.statusSnack.dismiss();
        this.statusSnack = null;
      }
    });


    // CURRENTLY ONLY FOR EXPENSES
    this.socket.fromEvent<NervResponse>('file').subscribe( data => {
      this.openRequests = null;
      console.log('IO - Message', data);
      try{
        console.log(data,"<----")
        this.download(data);
        this.snack.open(`${data.message}`, 'Close', {
          duration: 2000,
        });
      }catch(e){
        this.snack.open(`Error generating download: ${e}`, 'Close', {
          duration: 2000,
        });
      }
    });

  }

  

  connections(){
    return this.socket.fromEvent<NervIoSummary>('connections');      
  }


  ping(ioFn,params){
    this.openRequests = ioFn;
    const ref = new Subject<VSR>();
    this.socket.emit(ioFn, params, (data) => ref.next(data));
    
    

      // //DO FINISHED
      // if (r.type === HttpEventType.Response) {   
      //   console.log(r.body)
      //   $result.next(this.result(r.body));          
      //   $result.complete();
      //   quickSnack.dismiss();
      // }
      
      // //DO PROGRESS
      // if(r.type == HttpEventType.UploadProgress){
      //   const percentDone = Math.round(100 * r.loaded / r.total);
      //   let msg = (percentDone >= 100)
      //       ? 'Processing file(s)...'
      //       : `Uploading: ${percentDone}%`;
        
      //   this.fileuploadprogressservice.uploadProgress.next(msg);
      // }

    return ref.asObservable();
  }


  //@TODO: THIS FUNCTIONALITY IS DUPLICATED ON API SERVICE, REDUCE
  download(data){
    console.log('file to download',data);
    var base64str = data.file;
    // decode base64 string, remove space for IE compatibility
    var binary = atob(base64str.replace(/\s/g, ''));
    //console.log(binary)
    var len = binary.length;
    var buffer = new ArrayBuffer(len);
    var view = new Uint8Array(buffer);
    for (var i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
    }

    const blob = new Blob([view], { type: data.mime });
    const dataURL = window.URL.createObjectURL(blob);

     const link = document.createElement("a");

    if(data?.view){
       link.target = "_blank";
    }else{
       link.download = this.vs.escapeFilename(data.name);
    }

    link.href = dataURL;    
    link.click();
  }


  showStatus(msg){
    if(!this.statusSnack) this.statusSnack= this.snack.openFromComponent(FileuploadprogressComponent,{
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    });
    this.fileuploadprogressservice.uploadProgress.next(msg);
  }
  
}
