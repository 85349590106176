import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
//import { ClipboardModule } from '@angular/cdk/clipboard'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
//import { JwtModule } from '@auth0/angular-jwt';
import { LoginComponent } from './_component/login/login.component';
import { DashboardComponent } from './_component/dashboard/dashboard.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SidebarComponent } from './_component/sidebar/sidebar.component';
import { UsermenuComponent } from './_component/usermenu/usermenu.component';
import { MomentModule } from 'ngx-moment';
import { DragDropModule } from '@angular/cdk/drag-drop';
//import { MomentModule } from 'angular2-moment';
// import { ColorTwitterModule } from 'ngx-color/twitter';
import { ColorChromeModule } from 'ngx-color/chrome';
//import { ColorPickerModule } from 'ngx-color-picker';
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { TrendModule } from 'ngx-trend';

import { ChartsModule } from 'ng2-charts';
import { NgxChartsModule } from "@swimlane/ngx-charts";

import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip'
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatButtonToggleModule} from '@angular/material/button-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyProgressBarModule as MatProgressBarModule } from "@angular/material/legacy-progress-bar";

import { ProfileComponent } from './_component/profile/profile.component';
import { OverviewComponent } from './_component/overview/overview.component';
import { CompanyComponent } from './_component/company/company.component';
import { FileUploadComponent } from './_component/file-upload/file-upload.component';
import { FileService } from './_services/file.service';
import { ApiService } from './_services/api.service';
import { UserService } from './_services/user.service';
import { BrandService } from './_services/brand.service';
import { ProjectService } from './_services/project.service';
import { VelvetysoftService } from './_services/velvetysoft.service';
import { TimesheetService } from './_services/timesheet.service';
import { InvoiceService } from './_services/invoice.service';
import { AlertService } from './_services/alert.service';
import { FormErrorsService } from './_services/form-errors.service';
import { BrandsComponent } from './_component/brands/brands.component';

//import copy from '../assets/copy.json';

import {
  VhFilterPipe,
  WrapFnPipe,
  ReversePipe,
  CapitalizePipe,
  filterUsersByPipe,
  filterTheUsersByPipe,
  filterUserCanByPipe,
  filterActivePipe,
  VhFilterByPipe,
  filterProjectThisWeek,
  filterProjectEstThisWeek,
  filterProjectDueThisWeek,
  MapKeyValuePipe,  
  isNOtPipe,
  SecurePipe,
  labelsWork,
  UrlifyPipe
} from './pipes.pipes';
import { ProjectComponent } from './_component/project/project.component';
import { ProjectsComponent } from './_component/projects/projects.component';
import { AddRoundComponent } from './_component/project/add-round/add-round.component';
import { InvoicesComponent } from './_component/invoices/invoices.component';
import { ProposalsComponent } from './_component/proposals/proposals.component';
import { UsersComponent } from './_directive/users/users.component';
import { UserAvatarComponent } from './_directive/user-avatar/user-avatar.component';
import { ManageUsersDialogComponent } from './_directive/manage-users/manage-users.component';
import { ManageUsersComponent } from './_component/manage-users/manage-users.component';
import { UsersSelectComponent } from './_directive/users-select/users-select.component';
import { TimesheetComponent } from './_component/timesheet/timesheet.component';
//import { Timesheet2Component } from './_component/timesheet2/timesheet2.component';
//import { TimeSheetViewComponent } from './_component/time-sheet-view/time-sheet-view.component';
import { MatNativeDateModule } from '@angular/material/core';
import { AddInvoiceComponent } from './_component/invoices/add-invoice/add-invoice.component';
import { ConfirmComponent } from './_component/dialogs/confirm/confirm.component';
import { SendEmailComponent } from './_component/dialogs/send-email/send-email.component';
import { InvoiceLogComponent } from './_directive/invoice-log/invoice-log.component';
import { ActivityComponent } from './_component/activity/activity.component';
import { AlertComponent } from './_directive/alert/alert.component';
import { AddUserComponent } from './_component/dialogs/add-user/add-user.component';
import { ManageTimesheetsComponent } from './_component/manage-timesheets/manage-timesheets.component';
import { AddProjectComponent } from './_component/projects/add-project/add-project.component';
import { AddBrandComponent } from './_component/brands/add-brand/add-brand.component';
import { AddProposalComponent } from './_component/proposals/add-proposal/add-proposal.component';
import { ResetPassComponent } from './_component/reset-pass/reset-pass.component';
import { TableComponent } from './_directive/table/table.component';
import { ModalLibraryListComponent } from './_directive/modal-library-list/modal-library-list.component';
import { BrandLogoComponent } from './_directive/brand-logo/brand-logo.component';
import { MetaDisplayComponent } from './_directive/meta-display/meta-display.component';
import { ChartsComponent } from './_directive/charts/charts.component';
import { DashboardSnapshotComponent } from './_component/dashboard-snapshot/dashboard-snapshot.component';
import { AssetComponent } from './_directive/asset/asset.component';
import { EditAssetComponent } from './_component/dialogs/edit-asset/edit-asset.component';
import { AssetCabinetComponent } from './_directive/asset-cabinet/asset-cabinet.component';
import { MeetingNotesComponent } from './_directive/meeting-notes/meeting-notes.component';
import { LoaderComponent } from './_directive/loader/loader.component';
import { DateComponent } from './_directive/date/date.component';
import { TeamBarComponent } from './_component/dashboard/team-bar/team-bar.component';
import { ProjectProgressComponent } from './_directive/project-progress/project-progress.component';
import { MediaManagerComponent } from './_component/media-manager/media-manager.component';
import { MediaDialogComponent } from './_component/dialogs/media-dialog/media-dialog.component';
import { AddAssetComponent } from './_directive/add-asset/add-asset.component';
import { BrandLogosComponent } from './_directive/brand-logos/brand-logos.component';
import { ViewsComponent } from './_directive/views/views.component';
import { ManageComponent } from './_component/manage/manage.component';
import { ManageAssetsComponent } from './_component/manage-assets/manage-assets.component';
import { TagsComponent } from './_directive/tags/tags.component';
import { ManagerPermissonsComponent } from './_component/manager-permissons/manager-permissons.component';
import { ManagerActivityComponent } from './_component/manager-activity/manager-activity.component';
import { UserDashComponent } from './_component/user-dash/user-dash.component';
import { AipromptComponent } from './_component/dialogs/aiprompt/aiprompt.component';
import { ActivityLogComponent } from './_directive/activity-log/activity-log.component';
import { ManageInvoicesComponent } from './_component/manage-invoices/manage-invoices.component';
import { NoconnectionComponent } from './_component/noconnection/noconnection.component';
import { FileuploadprogressComponent } from './_directive/fileuploadprogress/fileuploadprogress.component';
import { FeComponent } from './_component-fe/fe/fe.component';
import { AddHoldingCompanyComponent } from './_component/add-holding-company/add-holding-company.component';
import { AddBriefComponent } from './_component/add-brief/add-brief.component';
import { AssetViewComponent } from './_component/asset-view/asset-view.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ManageExpensesComponent } from './_component/manage-expenses/manage-expenses.component';
import { AddExpenseComponent } from './_component/manage-expenses/add-expense/add-expense.component';
import { NewExpenseComponent } from './_component/expenses/new-expense.component';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { environment } from 'src/environments/environment';
import { AddressesComponent } from './_component/addresses/addresses.component';
import { ExpensesComponent } from './_component/expenses/expenses.component';
import { TeamCalComponent } from './_component/team-cal/team-cal.component';
import { AddTimsheetInvoiceComponent } from './_component/timesheet-invoice/add-timesheet-invoice/add-timesheet-invoice.component';
import { UserRatesComponent } from './_component/user-rates/user-rates.component';
import { ManageTsComponent } from './_component/timesheet-invoice/manage-ts/manage-ts.component';
import { LineExpenseComponent } from './_component/expenses/line-expense/line-expense.component';
import { ProjectSelectorComponent } from './_directive/project-selector/project-selector.component';
import { ClientApprovalComponent } from './_component/proposals/client-approval/client-approval.component';
import { BrandSelectorComponent } from './_directive/brand-selector/brand-selector.component';
import { ManageVendorsComponent } from './_component/manage-vendors/manage-vendors.component';
import { AddVendorComponent } from './_component/vendors/add-vendor/add-vendor.component';
import { ManagePurchasesComponent } from './_component/manage-purchases/manage-purchases.component';
import { ManageProposalsComponent } from './_component/manage-proposals/manage-proposals.component';
import { RegisterComponent } from './_component/register/register.component';
import { SetupRegistrationComponent } from './_component/setup/setup-registration/setup-registration.component';
import { InvoiceSummaryComponent } from './_component/invoices/invoice-summary/invoice-summary.component';
import { WidgetsComponent } from './_component/widgets/widgets/widgets.component';
import { WidgetComponent } from './_component/widgets/widget/widget.component';
import { QuickSigninComponent } from './_component/quick-signin/quick-signin.component';


export function jwtTokenGetter() {  
  return localStorage.getItem('token');
}
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};
const config: SocketIoConfig = { url: environment.io, options: {
  transportOptions: {
    polling: {
      extraHeaders: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    },
  },
}};

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        DashboardComponent,
        SidebarComponent,
        UsermenuComponent,
        ProfileComponent,
        OverviewComponent,
        CompanyComponent,
        FileUploadComponent,
        BrandsComponent,
        VhFilterPipe,
        isNOtPipe,
        VhFilterByPipe,
        filterProjectThisWeek,
        filterProjectDueThisWeek,
        filterProjectEstThisWeek,
        ReversePipe,
        SecurePipe,
        isNOtPipe,
        WrapFnPipe,
        labelsWork,
        UrlifyPipe,
        MapKeyValuePipe,
        filterUsersByPipe,
        filterTheUsersByPipe,
        filterUserCanByPipe,
        filterUserCanByPipe,
        CapitalizePipe,
        filterActivePipe,
        ProjectComponent,
        ProjectsComponent,
        AddRoundComponent,
        InvoicesComponent,
        ProposalsComponent,
        UsersComponent,
        UserAvatarComponent,
        ManageUsersComponent,
        ManageUsersDialogComponent,
        UsersSelectComponent,
        TimesheetComponent,
        //Timesheet2Component,
        //TimeSheetViewComponent,
        AddInvoiceComponent,
        ConfirmComponent,
        SendEmailComponent,
        InvoiceLogComponent,
        ActivityComponent,
        AlertComponent,
        AddUserComponent,
        ManageTimesheetsComponent,
        AddProjectComponent,
        AddBrandComponent,
        AddProposalComponent,
        ResetPassComponent,
        TableComponent,
        ModalLibraryListComponent,
        BrandLogoComponent,
        MetaDisplayComponent,
        ChartsComponent,
        DashboardSnapshotComponent,
        AssetComponent,
        EditAssetComponent,
        AssetCabinetComponent,
        MeetingNotesComponent,
        LoaderComponent,
        DateComponent,
        TeamBarComponent,
        ProjectProgressComponent,
        MediaManagerComponent,
        MediaDialogComponent,
        AddAssetComponent,
        BrandLogosComponent,
        ViewsComponent,
        ManageComponent,
        ManageAssetsComponent,
        TagsComponent,
        ManagerPermissonsComponent,
        ManagerActivityComponent,
        UserDashComponent,
        AipromptComponent,
        ActivityLogComponent,
        ManageInvoicesComponent,
        NoconnectionComponent,
        FileuploadprogressComponent,
        FeComponent,
        AddHoldingCompanyComponent,
        AddBriefComponent,
        AssetViewComponent,
        ManageExpensesComponent,
        AddExpenseComponent,
        NewExpenseComponent,
        AddressesComponent,
        ExpensesComponent,
        TeamCalComponent,
        AddTimsheetInvoiceComponent,
        UserRatesComponent,
        ManageTsComponent,
        LineExpenseComponent,
        ProjectSelectorComponent,
        ClientApprovalComponent,
        BrandSelectorComponent,
        ManageVendorsComponent,
        AddVendorComponent,
        ManagePurchasesComponent,
        ManageProposalsComponent,
        RegisterComponent,
        SetupRegistrationComponent,
        InvoiceSummaryComponent,
        WidgetsComponent,
        WidgetComponent,
        QuickSigninComponent,
    ],
    imports: [
        BrowserModule,
        MomentModule,
        HttpClientModule,
        NgxChartsModule,
        ChartsModule,
        FormsModule,
        ReactiveFormsModule,
        TrendModule,
        //ColorTwitterModule,
        ColorChromeModule,
        DragDropModule,
        CKEditorModule,
        Ng2SearchPipeModule,
        //ClipboardModule,
        NgxGoogleAnalyticsModule.forRoot('G-EK72P3DVWN'),
        SocketIoModule.forRoot(config),
        NgxMaskModule.forRoot(options),
        NgxGoogleAnalyticsRouterModule,
        AppRoutingModule,
        // JwtModule.forRoot({
        //   config: {
        //     tokenGetter: jwtTokenGetter,
        //     whitelistedDomains: [
        //       "localhost",
        //       "vault-back.local",
        //       "brand-vault.com",
        //     ],
        //     blacklistedRoutes: ["localhost/auth/login"],
        //   },
        // }),
        BrowserAnimationsModule,
        //MomentModule,
        MatButtonModule,
        MatInputModule,
        MatExpansionModule,
        MatNativeDateModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatBadgeModule,
        MatProgressBarModule,
        MatStepperModule,
        MatButtonToggleModule,
        MatSnackBarModule,
        MatDatepickerModule,
        MatTableModule,
        MatMenuModule,
        MatSortModule,
        MatAutocompleteModule,
        MatTooltipModule,
        MatIconModule,
        MatChipsModule,
        MatIconModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        MatSliderModule,
        MatListModule,
    ],
    providers: [
        FileService,
        UserService,
        TimesheetService,
        InvoiceService,
        BrandService,
        MatDatepickerModule,
        ProjectService,
        AlertService,
        FormErrorsService,
        VelvetysoftService,
        ApiService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}


