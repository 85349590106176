import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';
import { VelvetysoftService } from 'src/app/_services/velvetysoft.service';
import * as moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { BrandModel, ExpenseModel, Setting, Settings, UserProfileModel, VendorModel } from 'src/app/_models/subjects';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { ProjectService } from 'src/app/_services/project.service';
import { UserService } from 'src/app/_services/user.service';
import { ConfirmComponent } from '../../dialogs/confirm/confirm.component';
import { Router } from '@angular/router';
import { HoldingCompanyList, InvoiceList, InvoiceService } from 'src/app/_services/invoice.service';
import lang from '../../../../assets/copy.json';
import { VendorsService } from 'src/app/_services/vendors.service';


@Component({
  selector: 'app-add-expense',
  templateUrl: './add-expense.component.html',
  styleUrls: ['./add-expense.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ]
})
export class AddExpenseComponent implements OnInit {
  form: UntypedFormGroup;
  error: any = false;
  subject: string = "expense";
  expenses: UntypedFormArray;
  expense: ExpenseModel;
  settings: Settings;
  subcategoryTypes: Settings[];
  $brandList: Observable<BrandModel[]>;
  $userProfile: Observable<UserProfileModel>;
  $vendors: Observable<VendorModel[]>;
  types: Setting[];
  categoryTypes: Setting[];
  invoiceList = [];
  lang = lang.lang;
  includeHC = false;
  hcList: HoldingCompanyList[] = [];

  constructor(
    private dialogRef: MatDialogRef<AddExpenseComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: UntypedFormBuilder,
    public vs: VelvetysoftService,
    private projectService: ProjectService,
    private userService: UserService,
    private router: Router,
    private invoiceService: InvoiceService,
    private vendorService: VendorsService
  ) { }

  ngOnInit(): void {
    this.$brandList = this.projectService.projectTree;
    this.$userProfile = this.userService.userProfile();
    this.$vendors = this.vendorService.vendors();
    this.form = this.fb.group({
      id: [""],
      sub_category: ["", Validators.required],
      expensed_seperatly: [""],
      title: ["", Validators.required],
      project_id: [""],
      brand_id: ["", Validators.required],      
      category: [""], //switch
      payment_ref: [""],
      invoice_id: [""],
      date: [moment(), Validators.required],
      expense_group: this.fb.array([this.makeExpenseForm()]),
    });
    
    

    this.invoiceService.invoiceList().pipe(first()).subscribe(data => {
      this.invoiceList = data.invoices;
      this.hcList = data.holding_company;
    });
    this.types = this.vs.getSettings('expense')?.types;
    this.subcategoryTypes = this.vs.getSettings('expense')?.subcategories;
    this.categoryTypes = this.vs.getSettings('expense')?.categories;
     
    this.settings = this.vs.settings[this.subject];

    if( !this.data[this.subject]?.id ){
      let data = {...this.data[this.subject], date: moment().format('YYYY-MM-DD') };
      this.form.patchValue(data);
      this.form.patchValue({ expensed_seperatly: true });
      //if not set can't submit
      this.form.patchValue({ 'category': 'payment' }); 
      
    }else{
      this.form.patchValue(this.data[this.subject]);
      let create = this.data[this.subject].expenses.length - 1;
      for(let i = 0; i < create; i++){
        this.add();
      }
      this.form.patchValue({ 'category': this.data[this.subject].expenses[0].category });
      this.form.patchValue({ 'brand_id': this.data[this.subject].expenses[0].brand_id });
      this.form.patchValue({ 'project_id': this.data[this.subject].expenses[0].project_id });
      this.form.patchValue({ 'invoice_id': this.data[this.subject].expenses[0].invoice_id });
      this.form.patchValue({ 'expensed_seperatly': this.data[this.subject].expenses[0].expensed_seperatly });
      this.form.patchValue({ 'sub_category': this.data[this.subject].expenses[0].sub_category });
      this.form.patchValue({ 'payment_ref': this.data[this.subject].expenses[0].payment_ref });
      
      this.expenses = this.form.get('expense_group') as UntypedFormArray;
      this.expenses.patchValue(this.data[this.subject].expenses);
    }
    
  }

  add(){
    this.expenses = this.form.get('expense_group') as UntypedFormArray;
    this.expenses.push(this.makeExpenseForm());
  }

  remove(idx){
    this.expenses = this.form.get('expense_group') as UntypedFormArray;
    this.expenses.removeAt(idx);

  }

  alignStatus(expense: ExpenseModel){
    this.data[`${this.subject}Service`]
      .alignExpense(expense)
      .pipe(first())
      .subscribe(res => {
        this.dialogRef.close({ status: 'load', expense: this.form.value });
      });
  }

  addVendor(e){
    e.stopPropagation();
    this.vendorService.edit().subscribe( res => {
      console.log(res);
      //selectvendor(res.id)
    });
  }

  checkStatusesAlignment(expense){
    const isAligned = expense.expenses.every(item => item.status === expense.expenses[0].status);
    return !isAligned;    
  }

  deleteItem(expense,idx){
    const dialogRef = this.dialog.open(ConfirmComponent, {
      data: {        
        type: "delete",
        title: `Delete Expense Item for ${expense.total}`,
        content: `Are you sure you want to permanently delete it`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if(result && result.status == 'delete') this.data[`${this.subject}Service`]?.delete([expense.id], {skipLoading: true})
        .pipe(first())
        .subscribe( res => {
          this.remove(idx);
        });
    });
  }

  get formExpenses(){
    return (this.form.get("expense_group") as UntypedFormArray).controls;    
  }

  makeExpenseForm(){
    return this.fb.group({
      //date: [moment().format("Y-M-D")],
      id: [""],      
      project_id: [""],
      brand_id: [""],
      title: [""],
      date: [moment(), Validators.required],
      paid_at: [""],
      payment_ref: [""],
      //reimbursement: [""],
      total: ["", Validators.required],
      receipt: [""],
      type: ["", Validators.required],
      category: [""], //switch
      invoice_id: [""],
      vendor_id: [""],
      status: [""],
      _open: [""],
      from: [""],
      notes: [""],
    });
  }

  avalibleInvoices(bid){
    return this.invoiceService.avalibleInvoices(this.includeHC, this.hcList, this.invoiceList, bid);    
  }

  navTo(url){  
    this.router.navigate([url]);
    this.close();
  }

  uploadReceipt(event,idx){
    this.expenses = this.form.get('expense_group') as UntypedFormArray;

    this.expenses.controls[idx].patchValue({receipt: event});
    this.data.expense[idx].receipt = event;
    // this.expenses.controls[idx].patchValue({receipt: event});
    // console.log(this.form)
    // this.data.expense.expense_group[idx].receipt = event;
  }

  findBrandProjects(brands,id,pid=null){
    let brand =  brands.find( brand => brand.id == id)
    if(!brand) return ;
    if(pid) return brand.projects.find( project => project.id == pid);
    return brand.projects;
  }

  save() {
    if( !this.form.value.expensed_seperatly ) this.form.patchValue({ expensed_seperatly: false });
    this.dialogRef.close({ status: true, expense: this.form.value });
  }

  delete() {
    this.dialogRef.close({  status: "group-delete", expense: this.form.value });
  }


  print(ids: Array<number>, view=false){
    this.data['expenseService'].print(ids, view);
  }

  permDelete(){
    const dialogRef = this.dialog.open(ConfirmComponent, {
      data: {        
        type: "deletepermanently",
        title: `Delete : ${this.data.expense.title}`,
        content: `Are you sure you want to permanently delete ${this.data.expense.title}`,
        permDelete: false,
      },
    });
    //expenses-delete-permanently
    dialogRef.afterClosed().subscribe((result) => {
      
      this.data['expenseService'].permDelete(this.data.expense);
      
      this.dialogRef.close({ status: 'pdelete', expense: this.data.expense });
    });
  }

  downloadReceipt(expense: ExpenseModel){
    //this.data['expenseService'].view(expense);
    //pdfWrap
    this.data[`${this.subject}Service`].pdfWrap(expense).subscribe(res => {
    });
  }

  printAll(expense: ExpenseModel, view=false){
    //this.data['expenseService'].view(expense);
    //pdfWrap
    this.data[`${this.subject}Service`].pdfWrap(expense, true, view).subscribe(res => {});
  }

  paid(){
    const dialogRef = this.dialog.open(ConfirmComponent, {
      data: {        
        type: "expense-paid",
        title: `Mark Paid: ${this?.data?.expense?.title}`,
        ids: [this?.data?.expense?.id],
        payment_ref: '',
        label: `Payment or check reference`,
        content: `Optionally add payment details for ${this?.data?.ids?.length} expense.`,
        button: `Paid`,
      },
    }).afterClosed().subscribe((result) => {     
      if(result.status == "paid") this.data['expenseService'].paid([this?.data?.expense?.id], result?.data?.payment_ref)
        .pipe(first())
        .subscribe();
        this.dialogRef.close({ status: 'paid', expense: this?.data?.expense });
      //{ status: 'pdelete', expense: this.data.expense }
    });
  }

  restore(){
    this.data['expenseService'].restore(this.data.expense);
    this.dialogRef.close({ status: 'pass', expense: this.data.expense });
  }

  close() {
    this.dialogRef.close();
  }
  

}
