import { Injectable } from '@angular/core';
import { SubjectBaseService } from './subject-base.service';
import { ApiService } from './api.service';
import { SubjectAction, VendorModel } from '../_models/subjects';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { first, tap } from 'rxjs/operators';
import { AddVendorComponent } from '../_component/vendors/add-vendor/add-vendor.component';

@Injectable({
  providedIn: 'root'
})
export class VendorsService extends SubjectBaseService{
  $vendors: ReplaySubject<VendorModel[]> = new ReplaySubject(1);
  _vendor: VendorModel = {
    id: null,  
    name: "",
    email: "",
    addresses: null
  }
  init:boolean = false;

  
  constructor( 
      public apiService: ApiService,
      public dialog: MatDialog
    ) {
    super("vendor", apiService, dialog);
    this.setModel<VendorModel>(this._vendor);
  }

  vendors(force:boolean=false):Observable<VendorModel[]> {
    if(!this.init || force) this.loadSubject();    
    return this.$vendors.asObservable();
  }

  loadSubject(){
    this.init = true;
    this.apiService.get(`${this.subject}s`).pipe(tap(res => {
      this.$vendors.next(res) 
    }),first()).subscribe();      
  }

  edit(vendor?: VendorModel,options?):Observable<VendorModel> {    
    let resultRef = new Subject<VendorModel>();

    //options
    options = options || {};
    
    
    const result = this.editDialog<VendorModel>(
      vendor,
      AddVendorComponent,
      options
    );

    result.pipe(first()).subscribe((res: SubjectAction<VendorModel>) => {
      const v = res.subject;
      
      options.skipLoad = true;
      if(res.action == 'load') this.vendors(true).pipe(first()).subscribe();

      (res.action == 'update') && this.add<VendorModel>(v, options)
        .pipe(first())
        .subscribe((v:VendorModel) => {
          console.log(v);
          this.vendors(true).pipe(first()).subscribe();
          resultRef.next(v);
        });

      //resultRef.next(v);
      
    });

    return resultRef;   
  }
}
