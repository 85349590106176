import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { SettingTypes } from 'src/app/_models/subjects';
import { UserService } from 'src/app/_services/user.service';
import { VelvetysoftService } from 'src/app/_services/velvetysoft.service';
import { WidgetService } from 'src/app/_services/widget.service';

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss']
})
export class WidgetComponent {
  @Input() label: string;
  @Input() value: string | any ;
  @Input() range: boolean = false;
  @Input() queryid: string;
  @Input() trend: any;
  @Input() trendColors: any;
  @Input() format: string;
  @Input() chart: any;
  rangeValue: string = 'year';  
  color: string = '#000';

  constructor(
    private widgetService: WidgetService,
  ) {}

  ngOnInit() {   
    
    
  }

  rangeChange(){
    let data = { [this.queryid]: {
      range: this.rangeValue
    }};

    this.widgetService
      .widgets(data)
      .subscribe((data) => this.value = data[this.queryid] );
  }
}
