import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { BrandModel } from 'src/app/_models/subjects';

export function MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}

@Component({
  selector: "app-confirm",
  templateUrl: "./confirm.component.html",
  styleUrls: ["./confirm.component.scss"],
})
export class ConfirmComponent implements OnInit {
  form: UntypedFormGroup;
  canApprove: boolean = false;
  $hours: Observable<any>;
  tsprojectid: string = null;
  tsbrandid: string = null;
  $brandList: Observable<BrandModel[]>;
  updateTimsheets: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ConfirmComponent>,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    if (this.data.type == "password-reset") {
      this.form = this.fb.group(
        {
          password: ["", [Validators.required, Validators.minLength(8)]],
          password_confirm: ["", Validators.required],
          email: []
        },{validator: MustMatch("password", "password_confirm")}
      );      
      this.form.patchValue({ email: this.data.user.email});      
    }
    if (this.data.type == "add-tag") {
      this.form = this.fb.group(
        {
          tag: ["", [Validators.required]]
        }
      )
      this.form.patchValue({ tag: this.data.tag});      
    }

    //IF PROJECT FIND HOURS
    if(this.data.type === 'delete' && this.data.subject == 'project'){
      console.log(this)
      this.$hours = this.data['_service'].hours(this.data.id[0]);      
      this.$brandList = this.data._service.projectTree;
      this.tsbrandid = this.data._service.brandService._activeBrandID;
    }
    
  }

  getHours(uid, hours){    

    let total = 0;
    hours.forEach( h => {
      console.log(h)
        if(h[uid])total += h[uid];
    });
    return total;
    
  }

  findBrandProjects(brands,id){
    let brand =  brands.find( brand => brand.id == id)
    if(!brand) return ;
    
    return brand.projects.filter( project => project.status == "active");
  }

  get f() {
    return this.form.controls;
  }

  save() {
    this.dialogRef.close({ status: true });
  }

  saveWithData() {
    this.dialogRef.close({ status: true, data: this.data });
  }

  add(){    
    this.dialogRef.close({ status: true, data: this.form.value });
  }

  approve() {
    this.dialogRef.close({ status: "approve" });
  }

  delete() {
    if(this.tsprojectid && this.tsbrandid){
      this.data['tsprojectid'] = this.tsprojectid;
      this.data['tsbrandid'] = this.tsbrandid;
    }
    this.dialogRef.close({ status: "delete", data: this.data });
  }

  pdelete() {
    this.dialogRef.close({ status: "pdelete", data: this.data });
  }

  paid(){
    this.dialogRef.close({ status: "paid", data: this.data });
  }

  change() {
    console.log("attempt change here",this.form.value );
    this.data['userService']
      .resetPassManually(this.form.value )
      .pipe(first())
      .subscribe( x =>{
        this.dialogRef.close({ status: "change"});
      })
    //
  }

  close() {
    this.dialogRef.close();
  }

  attachFile(file,variable){
    this.data.approve_doc = file;
    if(file) this.canApprove = true;
  }

  preview(notification){
    let prv = window.open();
    prv.document.write(notification.preview);
  }

  approvedDoc(){
    console.log(this.data)
    this.dialogRef.close({ status: "approved", data: this.data.approve_doc });
  }

  // checkPasswords(group: FormGroup) {
  //   // here we have the 'passwords' group
  //   let pass = group.get("password").value;
  //   let confirmPass = group.get("password_confirm").value;

  //   return pass === confirmPass ? null : { notSame: true };
  // }
  // public static matchValues(
  //   matchTo: string // name of the control to match to
  // ): (AbstractControl) => ValidationErrors | null {
  //   return (control: AbstractControl): ValidationErrors | null => {
  //     return !!control.parent &&
  //       !!control.parent.value &&
  //       control.value === control.parent.controls[matchTo].value
  //       ? null
  //       : { isMatching: false };
  //   };
  // }
}
