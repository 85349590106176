import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from '../../_services/user.service';
import { VelvetysoftService } from '../../_services/velvetysoft.service';

@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.scss']
})
export class ManageComponent implements OnInit {
  $userProfile: Observable<any>;  
  managerNav: Array<any> = [];
  constructor(
    public userService: UserService,  
    private vs: VelvetysoftService
  ) { }

  displayType(subject_typle){
    return subject_typle.replace('App\\','');
  }

  ngOnInit() {
    this.$userProfile = this.userService.userProfile();   
    this.managerNav =  this.vs.managerNav;
  }

}
