import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AipromptComponent } from '../_component/dialogs/aiprompt/aiprompt.component';
import { ApiService } from './api.service';
import { MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { apiResponse } from '../_models/subjects';


@Injectable({
  providedIn: 'root'
})
export class OpenaiService {
  constructor(
    private apiService: ApiService,
    public dialog: MatDialog,
  ) {
    
  }

  prompt(data):Observable<any>{
    return this.apiService.post('prompt',data);
  }

  clean(string:string){
    if(!string) return null;
    return string.replace(/(<([^>]+)>)/gi, "");
  }

  openPrompt(text,directions?):Subject<any>{
    if(text) text = this.clean(text);
    if(directions) text = directions;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;        
    dialogConfig.data = {
      preloaded: text
    };
        
    dialogConfig.data[`openaiService`] = this; 
    //ADD FORM FIELD HERE
    let resultRef = new Subject();
    const dialogRef = this.dialog.open(   
      AipromptComponent,
      dialogConfig
    );

    dialogRef
      .afterClosed()
      .subscribe((result) => {
        if(result && result.status == "approve"){
          resultRef.next(result.data.results);
        }
    });    
    //return dialogRef;
    return resultRef;
  }
}
