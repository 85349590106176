import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { VelvetysoftService } from '../../_services/velvetysoft.service';
import { AssetModel } from 'src/app/_models/subjects';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-asset-view',
  templateUrl: './asset-view.component.html',
  styleUrls: ['./asset-view.component.scss']
})
export class AssetViewComponent implements OnInit {
  asset: AssetModel;
  subject = 'asset'
  _assetService: any;
  url: string;
  isVideo: boolean = false;
  $videoPreview: Observable<any>;
  cdn = environment.cdn;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AssetViewComponent>,
    public dialog: MatDialog,    
    private vs: VelvetysoftService
  ) { }

  ngOnInit(): void {
    this.asset = this.data[this.subject]; 
    this.url = this.asset.path;   
    this._assetService = this.data[`${this.subject}Service`];

    if(this.asset.ext == 'mp4'){
      this.isVideo = true;
      this.$videoPreview = this._assetService.viewVideo(this.asset);
    }
    
    console.log(this.asset);
    console.log(this._assetService,this)
  }

  assetThumb(asset){
    return this.data[`${this.subject}Service`].thumb(asset);
    //return this.data[`${this.subject}Service`].nervThumb(asset);    
  }

  gallery(path){
    this.url = path;
  }

  edit(asset: AssetModel){
    this.data[`${this.subject}Service`].edit(asset);    
  }

  size(bytes){    
    return this.data[`${this.subject}Service`].size(bytes);
  }

  download(asset: AssetModel){
    this.data[`${this.subject}Service`].downloadAsset(asset);
  }

  

}
