import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ActivityModel } from '../_models/activity';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  constructor(
    private apiService: ApiService
  ) { }

  projectInvoices(ids,exclude?){
    return this.apiService.post('activity-project-invoice?1',{ids: ids, exclude: exclude});
  }

  projectProposals(ids,exclude?){
    return this.apiService.post('activity-project-proposal?1',{ids: ids, exclude: exclude});
  }

  subject(ids,subject,exclude?){
    return this.apiService.post(`activity-${subject}?1`,{ids: ids, exclude: exclude});
  }

  pager(data?){
    return this.apiService.pager('activity-pager',data);
  }
}
