import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-asset',
  templateUrl: './edit-asset.component.html',
  styleUrls: ['./edit-asset.component.scss']
})
export class EditAssetComponent implements OnInit {
  form: UntypedFormGroup;
  subject: string = 'asset'
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<EditAssetComponent>, 
    fb: UntypedFormBuilder,
    ) {
    this.form = fb.group({
      id: [null],
      title: ['', Validators.required],     
      entropy: ['0']
    });
    }

  ngOnInit() {
    this.form.patchValue(this.data[this.subject]);    
  }

  thumb(asset){
   return this.data.assetService.thumb(asset)
  }

  save() {
    let data = { status: true }
    data[this.subject] = this.form.value;
    this.dialogRef.close(data);
  }

  delete() {
    this.dialogRef.close({ status: 'delete', asset: this.form.value });
  }

  close() {
    this.dialogRef.close();
  }

}
