<div class="vault-modal">
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <app-alert></app-alert>
    <div mat-dialog-content class="form-group">
        <form  [formGroup]="form">
            <div class="form-group">
              <mat-form-field class="w-100">
                <textarea 
                  placeholder="Directions for Content"
                  matTextareaAutosize
                  [disabled]="!loading"
                  matAutosizeMinRows=2              
                  matInput type="text" name="tag" formControlName="directions"
                  required></textarea>
              </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field *ngIf="form.controls.results.value" class="w-100">
                  <textarea matInput matTextareaAutosize matAutosizeMinRows=5 placeholder="Results" type="text"  formControlName="results"
                    required ></textarea>
                </mat-form-field>
              </div>
          </form>
    </div>
    
    <mat-dialog-actions *ngIf="!loading; else loader">
        <button mat-button (click)="close()">Close</button>
        <button color="primary" mat-raised-button (click)="prompt()"><span *ngIf="form.controls.results.value">Re</span><span>Generate Prompt</span></button>
        <button color="primary" *ngIf="form.controls.results.value" mat-raised-button (click)="save()">Use Text</button>
        <!-- <button *ngIf="data.type == 'add-tag'" color="primary" mat-raised-button (click)="add()">Add</button> -->
    </mat-dialog-actions>
    <ng-template #loader>
      <div class="label text-center">Loading</div> <mat-spinner color="primary" class="mx-auto loader"></mat-spinner>
      
    </ng-template>
</div>