<div class="vault-modal" >
    <button mat-button mat-button-base class="float-right" (click)="close()">
      <mat-icon class="mat-sm">close</mat-icon>   
    </button>
  
    <h1 mat-dialog-title>
        <span *ngIf="data.title; else addTitle">Edit</span>
        <ng-template #addTitle>
          Create
        </ng-template>
        {{ subject | capitalize }} Holding Company
    </h1>
  
    <div mat-dialog-content >
        <form [formGroup]="form">
            <div class="row">
                <div class="col-sm-6">
                    <mat-form-field class="w-100">
                        <input matInput name="title" name="name" placeholder="Legal Name" formControlName="name" required required>
                    </mat-form-field>
                </div>
                <div class="col-sm-12">
                    <label class="mat-dialog-title mr-3 pl-0 mb-1 mt-3" for="">Addresses</label>
                    <app-addresses 
                      [currentAddresses]="data?.['holding-company']?.addresses" 
                      limit="2"
                      [form]="form" ></app-addresses> 
                </div>
            </div>  
        </form>        
    </div>

    <mat-dialog-actions>
        <button mat-button (click)="close()">Cancel</button>
        <!-- Can optionally provide a result for the closing dialog. -->
        <button *ngIf="data[subject].id" mat-button mat color="warn" (click)="delete()">Delete</button>
        <button color="primary" [disabled]="form.invalid" mat-raised-button (click)="save()">Save</button>
        <button mat-button mat color="warn" *ngIf="data.id;" (click)="delete()">Delete</button>
    </mat-dialog-actions>
</div>
  

