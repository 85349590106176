<link href="https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap" rel="stylesheet">

    <div class="container">
        <div class="px-4">

            <div class="">
                <a href="https://show-pony.us" class="d-flex align-items-center legal uc">
                    <img src="https://vh-vault.s3.us-east-1.amazonaws.com/static/showpony-logo.png" alt="Show Pony" class="mr-3" style="max-width: 28px;">Showpony Powered
                </a>
            </div>

            <div class="mb-4 mt-2">
                
                    <div *ngIf="($proposalData | async); let data; else loader;">                      

                        <div *ngIf="!loading; else loader">

                            <div class="text-center" *ngIf="!data.found">
                                <h3>{{ data.message }}</h3>
                            </div>

                            <div *ngIf="data.found" class="row mt-3">

                                <div *ngIf="approved">
                                    <h3 class="mb-3 mt-3">Proposal Approved!</h3>
                                    
                                    <div class="d-flex align-items-center mb-3">
                                        <app-brand-logo  format="small" [brand]="data.brand"></app-brand-logo> &nbsp; 
                                        <h3 class="subhead mb-0 ">                                                 
                                            {{ data.brand.name }}
                                        </h3>
                                    </div>
                                    <div><span class="label mr-3">Title:</span>{{ data.proposal.title }}</div>
                                    <div><span class="label mr-3">Amount:</span>{{ data.proposal.total | currency }}</div>
                                    <button mat-raised-button color="primary" (click)="downloadAfter(data.proposal)">Download Approved PDF</button>
                                </div>

                                <div *ngIf="!approved" class="row justify-content-center mt-3">
                                    <div class="col-sm-6" >
                                        <div class="row  align-items-center">
                                            <div class="col-sm-6 mb-3" >
                                                <div class="p-4 proposal-copmany-logo" [style.background-color]="data.company.color">
                                                    <div *ngIf="data.company.invoiceLogo">
                                                        <img [src]="cdn+data.company.invoiceLogo" [alt]="data.company.name" >
                                                    </div>
                                                    <div *ngIf="!data.company.invoiceLogo">
                                                        <img [src]="cdn+data.company.logo" [alt]="data.company.name" >
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12">
                                                <div><div class="label uc mb-2">Proposal For:</div></div>
                                                <div class="d-flex align-items-center">
                                                    <app-brand-logo  format="small" [brand]="data.brand"></app-brand-logo> &nbsp; 
                                                    <h3 class="subhead-large mb-0 ">                                                 
                                                        {{ data.brand.name }}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="my-4">
                                                    <p>Please review the below Proposal then type your full name to approve.</p>

                                                    <div>
                                                        <div class="row justify-content-center align-items-center">
                                                            <div class="col-sm-6">                                    
                                                                <mat-form-field class="w-100">
                                                                    <input matInput type="text" required placeholder="Full name of Approver" autocomplete="off" name="name" [(ngModel)]="name" (ngModelChange)="makepng($event)" />                                        
                                                                    <mat-error *ngIf="!name">Name is required</mat-error>
                                                                </mat-form-field>                                                          
                                                            </div>
                                                            <div class="col-sm-6 d-flex">    
                                                                <div class="mr-2 mb-2">
                                                                    <button mat-raised-button color="accent" [disabled]="!name" (click)="approveProposal(data.proposal)">Approve</button>
                                                                </div>                                                
                                                            </div>                         
                                                            <div class="col-sm-12">
                                                                <p style="font-size: 12px;">The signing of this document provides authorization to produce deliverables outlined above. If change in scope causes fees to increase beyond this estimated amount, an additional estimate will be presented prior to the commencement of the requested deliverables. This supplemental estimate will need approval prior to the initiation of any additional work.</p>                          
                                                            </div>           
                                                        </div>
                                                        <div>
                                                            <hr>
                                                        </div>
                                                        <div>
                                                            <div><span class="label mr-3">Title:</span>{{ data.proposal.title }}</div>
                                                            <div><span class="label mr-3">Amount:</span>{{ data.proposal.total | currency }}</div>
                                                            <br>
                                                            <button mat-raised-button color="primary" (click)="download(data.proposal)">Download Full PDF</button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6" >
                                            
                                        <div [class.hide]="!makingPNG" style="transition:0.5s;">                    
                                            <h4>WORK AUTHORIZATION</h4>                     
                                            <div  style="border: 1px solid #000;">                                        
                                                <canvas style="max-width: 100%;" #workauth></canvas>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-sm-12 mb-5">
                                        <iframe width="100%" style="height: 80vh; min-height: 500px;" *ngIf="pdf" [src]="pdf"></iframe>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                
                <ng-template #loader>
                    <mat-spinner color="primary" class="mx-auto loader"></mat-spinner>
                </ng-template>
            </div>
        </div>
    </div>
