import { Injectable } from '@angular/core';
import { BriefModel, ExpenseModel, SubjectAction } from '../_models/subjects';
import { take, first, map, flatMap, tap, switchMap } from 'rxjs/operators';
import { ReplaySubject, Observable, Subscribable, Subject } from 'rxjs';
import { ApiService } from './api.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BrandService } from './brand.service';
import { SubjectBaseService } from './subject-base.service';
import { AddExpenseComponent } from '../_component/manage-expenses/add-expense/add-expense.component';
import { IoService } from './io.service';

@Injectable({
  providedIn: 'root'
})
export class ExpenseService extends SubjectBaseService{
  _expense: ExpenseModel = {
    id: null,
    //reimbursement: false,
    title: "",
    date: null,
    paid_at: null, 
    invoice_id: null,
    type: "",
    receipt: "",
    user_id: null,    
    company_id: null,
    brand_id: null,
    payment_ref: null,    
    project_id: null,
    status: "submitted",
    total: null,
    deleted_at: null,
    notes: "",
    from: "",
  }
  subject = 'expense';

  constructor(
    private apiService: ApiService,
    public dialog: MatDialog,
    public brandService: BrandService,
    public io: IoService
  ) {
    super("brief", apiService, dialog);
    this.setModel<ExpenseModel>(this._expense);
  }

  // expenses(data){
  //   return this.apiService.pager<ExpenseModel>(`${this.subject}-pager`,data);
  // }

  myExpenses(data?){
    return this.apiService.post<ExpenseModel>(`${this.subject}s`,data);
  }

  print(ids: Array<number>, view=false){
    let _view = (view) ? {view: true} : null;
    return this.apiService.download(`${this.subject}-print`,null,null,_view,{ids: ids});     
  }
  
  pdfWrap(expenseGroup, withExpense?, view=false){
    //@TODO NEESD SECURITY,what is they DOWNLOAD ALL
    
    //IF ARRAY OF EXEPNSE GROUPS
    let files = [];
    if(expenseGroup.length){
      
      //MANY EXPENSE GROUPS
      expenseGroup.forEach(eg => {
        eg.expenses.forEach(expense => {
          if(expense.receipt) files.push(expense.receipt);
        });     
      });
      console.log(files,expenseGroup);
    }else{

      //ONE EXPENSEGROUP
      expenseGroup.expenses.forEach(expense => {
        if(expense.receipt) files.push(expense.receipt);
      });   

    }

    return this.io.ping('pdf-wrap',{
      files: files, 
      view: view,
      expenseGroup: expenseGroup, 
      withExpense: (withExpense) ? withExpense : false,
      name: expenseGroup.title});
    
  }

  // deleteEG(){
  //   return this.apiService.post(`${this.subject}-group-delete`,{ids: this.selectedIds})
  //     .pipe(first()).subscribe(res => {
  //       this.selectedIds = [];
  //   })
  // }

  edit(expense?: ExpenseModel, options?) {
    let resultRef = new Subject();     
    const result = this.editDialog<ExpenseModel>(
      expense,
      AddExpenseComponent,
      options
    );
    result.pipe(first()).subscribe((res: SubjectAction<ExpenseModel|any>) => {    
      const expense = res.subject;
     
      if(res.action == 'update'){
        this.add<ExpenseModel>(expense,options)
        .pipe(first())
        .subscribe(res => resultRef.next(expense));
      }else if(res.action == 'group-delete'){
        this.deleteGroup(expense.expense)
        .pipe(first())
        .subscribe(res => resultRef.next(expense));
      }else{
        resultRef.next(expense);
      }                  
    });

    return resultRef;
  }

  restore(expense: ExpenseModel){
    return this.apiService.post(`${this.subject}-restore`,{ids: expense.id})
      .pipe(first()).subscribe(res => {
    })
  }

  paid(ids, pref): Observable<any>{
    return this.apiService.post(`${this.subject}-paid`,{ids: ids, payment_ref: pref});      
  }

  addExpenseLine(expense, start, imp?){
    const data = {expense: expense, start: start, imp: imp};
    return this.apiService.post(`${this.subject}-line`,data)
  }

  alignExpense(expense: ExpenseModel){
    return this.apiService.post(`${this.subject}-align`,{id: expense.id})
  }
  
  deleteGroup(expense: ExpenseModel){
    console.log(expense,'hi')
    return this.apiService.post(`${this.subject}-groups-delete`,{ids: [expense.id]})      
  }

  permDelete(expense: ExpenseModel){
    return this.apiService.post(`${this.subject}-delete-permanently`,{ids: expense.id})
      .pipe(first()).subscribe(res => {
    })
  }

  view(expense){
    this.apiService.get(`${this.subject}-download/${expense.id}`)
    .pipe(first())
    .subscribe(
      (response) => {
        window.open(response);
      });
  }
 
}
