import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class WidgetService {

  constructor(
    private apiService: ApiService
  ) { }


  widgets(options: Object){
    return this.apiService.post<any>("user-widgets", options);
  }

  add(data: Object){
    return this.apiService.post<any>("user-widget", data);
  }

  delete(data: string){
    return this.apiService.post<any>(`user-widget-delete`, data);
  }


}
