import { Component } from '@angular/core';
import { BrandModel, ExpenseModel, UserProfileModel } from 'src/app/_models/subjects';
import { Observable, ReplaySubject, Subscriber, Subscription } from 'rxjs';
import { UserService } from 'src/app/_services/user.service';
import { ProjectService } from 'src/app/_services/project.service';
import lang from '../../../assets/copy.json';
import { ActivatedRoute, Router } from '@angular/router';
import { ExpenseService } from 'src/app/_services/expense.service';
import { first } from 'rxjs/operators';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Location } from '@angular/common';

@Component({
  selector: 'app-expenses',
  templateUrl: './expenses.component.html',
  styleUrls: ['./expenses.component.scss']
})
export class ExpensesComponent {
  $userProfile: Observable<UserProfileModel>;
  $expenses: ReplaySubject<ExpenseModel[]>;
  $brandList: Observable<BrandModel[]>;
  subject: any;
  type: any;
  state: any;
  sub: Subscription;
  lang = lang.lang;
  displayedColumns: string[] = ['date','title','category','type','total','status', 'actions'];
  sortedData: ExpenseModel[];
  
  constructor(
    private userService: UserService,
    private projectService: ProjectService,
    private expenseService: ExpenseService,
    private route: ActivatedRoute,
    private router: Router,
    private snack: MatSnackBar,
    private location: Location
  ){
    this.$userProfile = this.userService.userProfile();
    this.$brandList = this.projectService.projectTree;
    this.$expenses = new ReplaySubject(1);
  }

  ngOnInit(): void {
    this.state = this.location.getState();    
    if(!this.state.type) this.state = {type: 'payment', label: 'Expense' };   
    this.subject = this.state.label;         
    this.expenseService.myExpenses({'type': this.state.type })
      .pipe(first())
      .subscribe( expenses => {        
        this.$expenses.next(expenses);       
    });
  }

  edit(expense){
    this.router.navigate([`dashboard/expense/${expense.id}`],{ state: {  expense: expense, type: this.state.type, label: this.state.label }  });
  }

  add(profile: UserProfileModel){
    if(profile.user?.addresses.length){
      this.router.navigate([`dashboard/new-expenses`],{ state: { type: this.state.type, label: this.state.label }  });
    }else{
      let sref =this.snack.open('Please add your address before creating an expense', 'Add Address', {duration: 3000, });
      sref.onAction().subscribe(()=>{
        this.router.navigate(['/dashboard/edit/profile']);
      });
    }
    
  }

  calcTotal(expenses: ExpenseModel[]){
    return expenses.reduce((a, b) => +a + +b.total, 0);
  }
}
