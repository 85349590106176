import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { Observable } from 'rxjs';
import { ProjectModel, UserProfileModel } from 'src/app/_models/subjects';
import { ProjectService } from 'src/app/_services/project.service';
import { UserService,  } from '../../_services/user.service';
import { Router } from '@angular/router';
import { BrandService } from 'src/app/_services/brand.service';
import { ConfirmComponent } from '../dialogs/confirm/confirm.component';


@Component({
  selector: 'app-user-dash',
  templateUrl: './user-dash.component.html',
  styleUrls: ['./user-dash.component.scss']
})
export class UserDashComponent implements OnInit {
  $userProfile: Observable<UserProfileModel>;
  $myProjects: Observable<ProjectModel[]>
  $staff: Observable<any[]>  
  constructor(
    private userService: UserService,
    private projectService: ProjectService,
    private router: Router,
    private brandService: BrandService
  ) {
    this.$userProfile = this.userService.userProfile();
    this.$staff = this.userService.staff();
  }

  ngOnInit() {                            
    this.$myProjects = this.projectService.projectsMe({ 
      users: 'true',
      rounds: 'true'
    });
  }

  navTo(id){
    this.router.navigate([`/dashboard/project/${id}`]);    
  }

  getBrand(bid){
    return this.brandService.brand(bid);
  }

  isOverDue(project){
    return ( moment(project.due) > moment() )
      ? true
      : false
  }

  progress(project){
    const range = moment(project.due).diff(project.created_at,'days', false);
    const lastround = project.rounds[project.rounds.length-1];
    if(lastround){
      const current = moment(lastround.due_date).diff(project.created_at,'days', false);
      let percent = Math.floor(current/range*100);
      return (percent > 100)
        ? 100 
        : ( percent < 0 ) 
          ? 0
          : percent ;
      
    }
    return 0; 
  }

  nextRound(rCount:number,dates:Array<Date>){
    if(!rCount || !dates) return null;
    return ( dates[rCount] ) ? dates[rCount] : null ;
  }

  nextSteps(project){
    if(!project.rounds) return false;
    const steps = project.rounds[project.rounds.length-1]
    return (steps && steps.next_steps) ? true : false;
  }

}
