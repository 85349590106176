import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BrandService } from './brand.service';
import { take, first, map, flatMap, tap, switchMap } from 'rxjs/operators';
import { ReplaySubject, Observable, Subscribable, Subject } from 'rxjs';
import { IoService } from './io.service';
import { TimesheetInvoiceModel, SubjectAction } from '../_models/subjects';
import { SubjectBaseService } from './subject-base.service';
import { AddTimsheetInvoiceComponent } from '../_component/timesheet-invoice/add-timesheet-invoice/add-timesheet-invoice.component';
import { TimesheetService } from './timesheet.service';
import { ManageTsComponent } from '../_component/timesheet-invoice/manage-ts/manage-ts.component';

@Injectable({
  providedIn: 'root'
})
export class TimesheetInvoiceService extends SubjectBaseService{

  _timesheetInvoice: TimesheetInvoiceModel = {
    id: null,
    user_id: null,
    amount: null,
    company_id: null,
    conditions: null,
    created_at: null,
    deleted_at: null,
    expense_total: null,
    payment_ref: null,
    user_invoice_ref: null,
    expenses: null,
    min: null,
    paid_at: null,
    rate: null,
    start: null,
    status: null,
    stop: null,
    timesheet_total: null,
    timesheets: null,
    updated_at: null,
    variable_rate: null,
    weeks: null
  }
  subject = 'timesheet-invoice';

  constructor(
    private apiService: ApiService,
    public dialog: MatDialog,
    public timesheetService: TimesheetService,
    public brandService: BrandService,
    public io: IoService
  ) {
    super("brief", apiService, dialog);
    this.setModel<TimesheetInvoiceModel>(this._timesheetInvoice);
  }

  preview(data) {
    return this.apiService.post(`${this.subject}-preview`, data);
  }

  makePreview(data) {
    return this.apiService.download(`${this.subject}-preview-make`,null,null,{view: true}, data);
  }

  view(data: TimesheetInvoiceModel) {
    return this.apiService.download(`${this.subject}`,null,null,{view: true}, {id: data.id});
  }

  download(data: TimesheetInvoiceModel) {
    return this.apiService.download(`${this.subject}`,null,null,null, {id: data.id});
  }

  paid(ids, pref): Observable<any>{
    return this.apiService.post(`${this.subject}-paid`,{ids: ids, payment_ref: pref});      
  }

  submit(data) {    
    return this.apiService.post(`${this.subject}-submit`, data);
  }

  manage(tsi) {
    
    let options ={};
    const result = this.editDialog<TimesheetInvoiceModel>(
      tsi,
      ManageTsComponent,
      options
    );
    return result;
  }

  edit(timesheetInvoice?: TimesheetInvoiceModel, options?) {
    let resultRef = new Subject();
    options.timesheetService = this.timesheetService;     
    const result = this.editDialog<TimesheetInvoiceModel>(
      timesheetInvoice,
      AddTimsheetInvoiceComponent,
      options
    );
    result.pipe(first()).subscribe((res: SubjectAction<TimesheetInvoiceModel|any>) => {    
      const timesheet = res.subject;
     console.log(res,options);
      if(res.action == 'update'){
        this.add<TimesheetInvoiceModel>(timesheet,options)
        .pipe(first())
        .subscribe(res => resultRef.next(timesheet));      
      }else{
        resultRef.next(timesheet);
      }                  
    });

    return resultRef;
  }

}
