import { Component, OnInit } from '@angular/core';
import { BrandService} from '../../_services/brand.service';
import { BrandModel } from "../../_models/subjects";
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { GoogleApiService } from 'src/app/_services/google-api.service';
import { debounceTime, first } from 'rxjs/operators';

//import { VelvetysoftService } from 'src/app/_services/velvetysoft.service';


@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss']
})
export class BrandsComponent implements OnInit {

  $brands: Observable<BrandModel[]>;
  cdn = environment.cdn;
  drives = null;
  displayedColumns: string[] = ['logo','title', 'users', 'action'];
  sortedData: BrandModel[];
  ioConnected: any;
  brandspager;
  subject: string = 'brands';
  tileSize: number = 400;
  asyncFilters = {
    deleted: false,
    size: this.tileSize
  };
  searchtiles = null;
  applyTilesSearch = new Subject<any>();
  
  view: string = "tile";
  
  constructor(
    private brandService: BrandService,
    private gapi: GoogleApiService
    //private vs: VelvetysoftService
  ) { }

  ngOnInit() {
    this.brandspager = this.brandService.pager<BrandModel>({size: this.asyncFilters.size});    
    this.$brands = this.brandspager.$source;
    //this.$brands = this.brandService.brands;
    this.gapi.listDrives().subscribe( (drives) =>{
      this.drives = drives;
    });    
    //this.vs.isAdminPage = true;

    //DEBOUNCE THE ITLES SEARCH
    //@TODO: THIS NEED A WHOLE NEW MODUEL
    this.applyTilesSearch.pipe(
      debounceTime(400))
      .subscribe((event) => this.brandspager.loadSearch({search: this.searchtiles, size: this.tileSize}));    
  }

  add(brand?: BrandModel) {    
    this.brandService.editDialog(brand,this.drives)
    .pipe(first())
    .subscribe((res:any) => {
      if(res.status) this.brandspager.refresh();
    });    
  }

  changeView(view) {
    this.view = view;
    let _size = (view == 'tile') ? this.tileSize : 25;
    this.searchtiles = null;
    this.brandspager.loadSearch({size: _size});    
    //this.brandspager.refresh();
  }

  updateTiles(){
    this.applyTilesSearch.next();
  }

}
