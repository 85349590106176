<div *ngIf="isVisible"> 
  <div class="team-bar" *ngIf="($activeBrand | async); let brand">
    <div class="container">
      <div *ngIf="!isProject; else project" class="row align-items-center">
        <div class="col col-auto d-flex align-content-center">
          <mat-icon 
            class="agrey"
            matTooltip="Team Roster"
            matTooltipPosition="right">groups</mat-icon>
          <!-- <div class="subhead">Team Roster</div> -->
        </div>
        <div class="pr-3 align-v-center">
          <span class="subhead">Clients</span> &nbsp;
          <app-users class="inline" [users]="brand.users" role="client"></app-users>
        </div>
        <div class="align-v-center">
          <span class="subhead">Agency</span> &nbsp;
          <app-users class="inline" [users]="brand.users" role="employee"></app-users>
        </div>
      </div>

      <ng-template #project >
        <div class="row align-items-center" *ngIf="( $activeProject | async ); let project">
          <div class="col col-auto d-flex align-content-center">
            <mat-icon 
            class="agrey"
            matTooltip="Project Roster" 
            matTooltipPosition="right">groups</mat-icon>
            <!-- <div class="subhead">Project Roster</div> -->
          </div>        
          <div class="pr-3 align-v-center">
            <span class="subhead">Clients</span> &nbsp;
            <app-users class="inline" [users]="project.users" role="client"></app-users>
          </div>      
          <div class="align-v-center">
            <span class="subhead">Agency</span> &nbsp;
            <app-users class="inline" [users]="project.users" role="employee"></app-users>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>


