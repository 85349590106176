
<div *ngIf="($userProfile | async); let profile" class="d-flex align-items-center">
  <!-- <button mat-button (click)="toggleDashSnap()" class="mobile-hide">Dashboard</button> -->
  
  
  <button mat-button [routerLink]="['/dashboard/welcome']" class="mobile-hide"><mat-icon>dashboard</mat-icon></button>

  <button *ngIf="profile | userCan: 'manage settings'" mat-button [routerLink]="['/dashboard/team-calendar']" class="mobile-hide"><mat-icon>calendar_today</mat-icon></button>
  <!-- <button mat-icon-button class="mobile-hide"><mat-icon>search</mat-icon></button>   -->
  <button *ngIf="profile | userCan: 'manage settings'" mat-icon-button [routerLink]="['/dashboard/manage']"><mat-icon>settings</mat-icon></button>
  <!-- <button mat-icon-button [matMenuTriggerFor]="settingsMenu"><mat-icon>settings</mat-icon></button> -->
  <button mat-button  [matMenuTriggerFor]="menu" color="white"><user-avatar [showName]="false" [user]="profile.user"></user-avatar> <mat-icon >arrow_drop_down</mat-icon></button>

  <mat-menu #activityMenu="matMenu" class="menu-full-right">
    <div class="row m-0">
      <div class="col-sm-12">
        <app-activity ></app-activity>
      </div>       
    </div>
  </mat-menu>

  <mat-menu #settingsMenu="matMenu" class="menu-full-right">
    <div class="row m-0 px-3">
      <div class="col-sm-12 mb-3 subhead-bold">Manage</div>
      <div class="col-sm-6 py-3" *ngFor="let link of managerNav">
        <a class="menu-tile" [routerLink]="link.path" mat-menu-item color="primary">
          <mat-icon>{{ link.icon }}</mat-icon>  {{ link.label }}</a>
      </div>      
    </div>
  </mat-menu>

  <mat-menu  #menu="matMenu" class="user-nav">
    <div mat-menu-item class="title-item">
      <div>{{ profile.user.email }}</div>
    </div>
    <a *ngIf="profile | userCan: 'manage company'" [routerLink]="['/dashboard/edit/company']"
      mat-menu-item>My Company</a>
    <a mat-menu-item [routerLink]="['/dashboard/edit/profile']">My Profile</a>
    <a *ngIf="profile | userCan: 'create timesheets'" mat-menu-item [routerLink]="['/dashboard/timesheets']">My Timesheets</a>
    <a *ngIf="profile | userCan: 'create timesheets'" mat-menu-item [routerLink]="['/dashboard/expenses']" [state]="{type: 'payment', label: 'Expense' }">My Expenses</a>
    <a *ngIf="profile | userCan: 'create purchase'" mat-menu-item [routerLink]="['/dashboard/purchases']" [state]="{type: 'purchase', label: 'Purchase' }">My Purchases</a>    
    <!-- <a mat-menu-item [routerLink]="['/dashboard/timesheets']">My Timesheets</a> -->
    <!-- <button mat-icon-button [matMenuTriggerFor]="activityMenu">
      <mat-icon>notifications</mat-icon>
    </button> -->
    <button mat-menu-item (click)="logout()">Sign Out</button>
  </mat-menu>
</div>
