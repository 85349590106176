import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { ProjectService } from '../../_services/project.service';
import { Observable } from 'rxjs';
import { tap,first } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MatAccordion } from '@angular/material/expansion';
import { AddRoundComponent } from './add-round/add-round.component';
import { VelvetysoftService } from '../../_services/velvetysoft.service';
import { InvoiceService } from '../../_services/invoice.service';
import { ProposalService } from '../../_services/proposal.service';
import { AssetService } from '../../_services/asset.service';
import { ProjectModel, RoundModel, ProposalModel, InvoiceModel, SettingsSubject, AssetModel, BrandModel, ProjectionModel, assetChangeResults, DialogOptions, BriefModel } from "../../_models/subjects";
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { RoundService } from 'src/app/_services/round.service';
import { UserService } from 'src/app/_services/user.service';
import { UserProfileModel } from '../../_models/subjects';
import { BrandService } from 'src/app/_services/brand.service';
import { BriefService } from 'src/app/_services/brief.service';
import { IoService } from 'src/app/_services/io.service';
import { ConfirmComponent } from '../dialogs/confirm/confirm.component';

//import * as shape from 'd3-shape';

@Component({
  selector: "app-project",
  templateUrl: "./project.component.html",
  styleUrls: ["./project.component.scss"],
})
export class ProjectComponent implements OnInit {
  @ViewChildren("roundsAccordian") roundsAccordian: QueryList<MatAccordion>;
  $project: Observable<ProjectModel>;
  $invoices: Observable<InvoiceModel[]>;
  $proposals: Observable<ProposalModel[]>;
  $rounds: Observable<RoundModel[]>;
  $userProfile: Observable<UserProfileModel>;
  $assetCabinet: Observable<AssetModel[]>;
  $projections: Observable<ProjectionModel>;//CREATE TPYE
  $brief: Observable<BriefModel>;
  brief:BriefModel;
  // THIS IS A HACK
  proposalIDS: number[];
  $hours: Observable<any>
  pid: string;
  currentRounds: number = 0;
  openRounds: boolean = false;
  roundsCtrl: any;
  project_type: any;
  cabineOpen: boolean = false;
  //notesOpen: boolean = false;
  selectedNotes: RoundModel = null;
  roundLabel:string = 'Round';
  proposalTotal:number = 0;
  deepSelectedRound: number = null;
  //curve = shape.curveCardinal;
  subject: string = "project";
  // @TODO clean this mess up
  subjectObj: SettingsSubject = {
    subject_type: "",
    label: "",
    id: "",
    icon: "",
    path: "",
    adminPath: "",
  };
  // options
  view: any[] = [100, 50];
  options = {
    responsive: true,
    lineTension: 0,
    legend: {
      position: "right",
    },
    scales: {
      // yAxes: [{
      // }],
      xAxes: [
        {
          type: "time",
          distribution: "linear",
          time: {
            unit: "week",
          },
        },
      ],
    },
  };

  constructor(
    private projectService: ProjectService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public vs: VelvetysoftService,
    private snack: MatSnackBar,
    private invoiceService: InvoiceService,
    private proposalService: ProposalService,
    private assetService: AssetService,
    private roundService: RoundService,
    private userService: UserService,
    private brandService: BrandService,
    private briefService: BriefService,
    private router: Router,
    public io: IoService,
  ) {
    
  }

  ngOnInit() {
    this.route.paramMap.subscribe((queryParams) => {

      //Project ID
      this.pid = queryParams["params"]["pid"];      

      //RESET DEEPROUND 
      this.deepSelectedRound = null;      

      //CHECK IF PROJECT EXISTS
      this.$project = this.projectService.hunt(this.pid).pipe(
        tap(p => {
          this.vs.title(['Project', p.name.toUpperCase()])
          this.roundLabel = (p.ongoing) ? 'Update' : 'Round';
          this.brief = p.brief;
        }));
       

      this.$invoices = this.invoiceService._activesByProject<InvoiceModel[]>(this.pid);
      this.$proposals = this.proposalService._activesByProject<ProposalModel[]>(this.pid).pipe(
        tap((proposals: ProposalModel[])=> {
          //console.log(proposals)
          this.proposalIDS = proposals.map( p => p.id);
          this.proposalTotal = proposals.reduce((a, b) => a + b.total, 0);
          return true;
      }));
      this.$rounds = this.roundService.rounds.pipe(
        tap((rounds: RoundModel[]) => (this.currentRounds = rounds.length))
      );      
      this.$assetCabinet = this.assetService.assetsByProject(this.pid);
      this.$projections = this.projectService.projections(this.pid);
      this.$hours = this.projectService.hours(this.pid);
      //this.briefService.brief(this.pid).subscribe(brief => this.brief = brief);

      let deepRound = new URL(location.href).searchParams.get('round');
      if(deepRound){
        //this.roundsCtrl.closeAll();
        this.deepSelectedRound = parseInt(deepRound);          
      }else if(!this.deepSelectedRound){
        this.$rounds.pipe(tap()).subscribe(rounds => {
        
        if(!rounds.length) return this.deepSelectedRound = null;

        let targetRound = rounds[rounds.length-1].id;          
        return this.deepSelectedRound = (targetRound && !Array.isArray(targetRound)) 
          ?  targetRound
          : null;
        });          
      }

      
    });
    
    this.project_type = this.vs.settings.project;
    this.subjectObj = this.vs.findSubject(this.subject);
    this.$userProfile = this.userService.userProfile();    
    
  }

  percent(spent,forecast){

    let expense = spent;
    return Math.floor((expense / forecast) * 100);
  }


  
  editBrief(brief?: BriefModel) {
    this.briefService.edit(this.pid,brief)
      .pipe(first())
      .subscribe(briefState => {
        this.projectService.loadSubject();
        // if(briefState.action == 'edit/add'){
        //   this
        //   return console.log('add/edit', briefState.subject);
        // }else if(briefState.action == 'delete'){
        //   return console.log('delete', briefState.subject);
        // }
      });
  }

  ngAfterViewInit() {
    //this.$brief.pipe(first()).subscribe(brief => this.brief = brief);
    this.roundsAccordian.changes.subscribe(() => {

      this.roundsAccordian.toArray().forEach((el) => {
        this.roundsCtrl = el;
        
        // let deepRound = new URL(location.href).searchParams.get('round');
        // if(deepRound){
        //   this.roundsCtrl.closeAll();
        //   this.deepSelectedRound = parseInt(deepRound);          
        // }else{
        //   this.$rounds.pipe(first()).subscribe(rounds => this.deepSelectedRound = rounds.length-1);          
        // }
      });

      
    });

    //OPEN SPECIFIC ROUND
    
  }

  toggleAll() {
    !this.openRounds ? this.roundsCtrl.openAll() : this.roundsCtrl.closeAll();
    this.openRounds = !this.openRounds;
  }

  exportHours(project:ProjectModel){
    this.projectService.exportHours(project.id);
  }

  edit(project?: ProjectModel, options?) {
    let _options = options || {  };
    this.projectService.edit(project, _options)
    .pipe(first())
    .subscribe(projectState => {
      if(projectState?.action == 'delete'){
        this.router.navigate(['/dashboard/projects']);
      }
        
    });
  }

  webshots(round) {  
    const dialogRef = this.dialog.open(ConfirmComponent, {
      data: {
        
        type: "webshots",
        title: `Create Webpage Screenhots`,
        content: "Enter the URL on a newline for each page you would like to screenshot.",
        pages: "",
        options: {
          fullscreen: true,
          mobile: false,
        },
        action: "save"
      },
    });
    dialogRef.afterClosed().pipe(first()).subscribe( res =>{
      if(res.status){
        //create an array from newlines
        let pages = res.data.pages.split(/\r?\n/);
        console.log(pages);
        this.assetService.webshots({...round, urls:pages, subject:'round', options: res.data.options}).subscribe(res =>{
          if(res.status){
            console.log(round,res);
            res.data.forEach(asset => {
              round.model.assets.push(asset);
            });
          }
        });
      }
    });

    // let pages = ['https://www.google.com']
    
  }

  addRound(pid, round?, options?:DialogOptions) {
    let res = this.roundService.edit(pid, round, options);
    res.subscribe(roundState => {
      if(roundState.id) setTimeout( ()=> {this.deepSelectedRound = roundState.id }, 450);  
    });
    
    //this.projectService.addRound(null,pid)
  }

  trashRound(id?) {
    let ids = [id];
    this.roundService.deleteDialog(ids);
  }

  markComplete(project:ProjectModel){
    this.projectService.markComplete(project.id)
      .pipe(first())
      .subscribe(res => project.status = "complete")
    
  }
  notes(pid,round:RoundModel){
    //console.log()
  }

  emailRound(bid, round) {
    round.brand_id = bid;
    this.roundService.sendEmail(round);
  }

  openInvoices(invoices?,options?) {
    if (invoices.length < 1) invoices = null;
    this.invoiceService.edit(invoices, options);
  }
  openProposals(proposals?,options?) {
    if (proposals.length < 1) proposals = null;
    this.proposalService.edit(proposals,options);
  }

  openCabinet() {
    this.cabineOpen = !this.cabineOpen;
  }

  // openNotes(round?) {
  //   this.selectedNotes = (round) 
  //     ? round
  //     : null;
  //   this.notesOpen = !this.notesOpen;
  // }

  

  assetEdit(result:assetChangeResults){
    //this.$rounds
    console.log(result.action, result.id)
  }

  uploadRoundAsset(result) {
    result.model.assets = result.model.assets || [];
    result.model.assets = [...result.model.assets, ...result.assets];
  }
  
}
