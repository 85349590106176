import { Time } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable, Subject } from 'rxjs';
import { TimesheetInvoiceModel, UserProfileModel } from 'src/app/_models/subjects';
import { UserService } from 'src/app/_services/user.service';
import { ConfirmComponent } from '../../dialogs/confirm/confirm.component';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-manage-ts',
  templateUrl: './manage-ts.component.html',
  styleUrls: ['./manage-ts.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})
export class ManageTsComponent implements OnInit{
  subject: string = "timesheet-invoice";
  _service: string;
  tsi: TimesheetInvoiceModel;
  $userProfile: Observable<UserProfileModel>;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ManageTsComponent>,
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private dialog: MatDialog,
  ) { 
    this._service = `${this.subject}Service`;
  }

  ngOnInit() {    
    this.tsi = this.data['timesheet-invoice'];
    this.$userProfile = this.userService.userProfile();
    console.log(this.data);
  }

  close() {
    this.dialogRef.close();
  }

  view(tsi: TimesheetInvoiceModel){
    console.log(tsi,this);
    this.data[this._service].view(tsi);

  }

  paid(tsi: TimesheetInvoiceModel){
    let ids = [tsi.id ] ;
    // console.log('paid',ids);    
    this.dialog.open(ConfirmComponent, {
      data: {        
        type: "expense-paid",
        title: `Mark Paid: ${ids?.length} Timesheet Invoice`,
        ids: ids,
        payment_ref: '',
        label: `Payment or check reference`,
        content: `Optionally add payment details for Invoice`,
        button: `Paid`,
      },
    }).afterClosed().subscribe((result) => {  
      console.log('refresh b');   
      if(result.status == "paid") this.data[this._service].paid(ids, result.data.payment_ref)
        .pipe(first())
        .subscribe(res => {
          console.log(res);
          this.dialogRef.close({ status: 'pass', tsi: res });
        });     
      
    });
  }

  delete(tsi: TimesheetInvoiceModel){
    this.data[this._service].deleteDialog([tsi.id],{skipLoad:true}).pipe(first()).subscribe( () =>{
      this.dialogRef.close({ status: 'pass' });
    });  
  }
  

  download(tsi: TimesheetInvoiceModel){
    console.log(tsi,this);
    this.data[this._service].download(tsi);

  }
}
