import { Component, Input, TemplateRef, ViewChild } from '@angular/core';

import { Observable } from 'rxjs';
import { Pager, PagerObject, UserProfileModel, VendorModel } from 'src/app/_models/subjects';
import { UserService } from 'src/app/_services/user.service';
import { MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { VelvetysoftService } from 'src/app/_services/velvetysoft.service';
import { environment } from 'src/environments/environment';
import { SelectionModel } from '@angular/cdk/collections';
import { VendorsService } from 'src/app/_services/vendors.service';

@Component({
  selector: 'app-manage-vendors',
  templateUrl: './manage-vendors.component.html',
  styleUrls: ['./manage-vendors.component.scss']
})
export class ManageVendorsComponent {
  viewOptions = {};
  vendorPager: PagerObject;
  $vendors: Observable<Pager<VendorModel>>;
  $userProfile: Observable<UserProfileModel>;
  cdn = environment.cdn;
  search: string = "";
  view: string = "tile";
  subject: string = "vendors";
  settings: any;
  @Input() public tableRef: TemplateRef<any>;
  sortedData: VendorModel[];
  filteredSubjects: string[];
  @ViewChild("vaultTable") vaultTable;

  //TABLE VIEW
  displayedColumns: string[] = [
    "select",
    "name",
    "phone",
    "address",
    "actions",
  ];
  asyncFilters = {
    filters: [],
    deleted: false
  };

  constructor(
    private userService: UserService,
    public dialog: MatDialog, //private snack: MatSnackBar,
    public vs: VelvetysoftService,
    private vendorService: VendorsService
  ) {
    
  }

  selection = new SelectionModel<VendorModel>(true, []);

  add(vendor?: VendorModel) {
    this.vendorService.edit(vendor,{
      pager: this.vendorPager,
      skipLoad: true
    });

  }

  ngAfterContentInit() {
    this.vendorPager = this.vendorService.pager<VendorModel>();
    this.$vendors = this.vendorPager.$source;
    
  }
}
