import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { LoginResponse, RegisterResponse } from '../_models/login';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { UserService } from './user.service';
import { BrandService } from './brand.service';
import * as moment from "moment";
import { ApiService, jwtTokenGetter } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  api = environment.backend;

  constructor(
    private router: Router,
    private userService: UserService,
    private brandService: BrandService,
    private apiService: ApiService,
  ) {}

  // Verify user credentials on server to get token
  loginForm(data): Observable <LoginResponse> {    
    return this.apiService.post<LoginResponse>('login', data, true);
    //.pipe(retry(2));    
  }

  registerForm(data): Observable <RegisterResponse> {    
    return this.apiService.post<RegisterResponse>('register-beta', data, true);
    //.pipe(retry(2));    
  }

  // After login save token and other values(if any) in localStorage
  setUser(resp: LoginResponse) {
    localStorage.setItem('token', resp.token);    
    var exp = moment().add(resp.expires_in, 'seconds').toDate().toString();
    localStorage.setItem('expiration', exp);  
    
    console.log('did it');
    this.userService.userProfileBuster = true;
    this.brandService.BrandsBuster = true;   
    //FIX THIS
    location.reload(); 
    //if(jwtTokenGetter()) this.router.navigate(['/dashboard']);    
  }

  // Checking if token is set
  isLoggedIn() {    
    var exp = localStorage.getItem('expiration');
    if (moment().isAfter(exp)) return false;
    return localStorage.getItem('token') != null;
  }

  // After clearing localStorage redirect to login screen
  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  forgotPassword(data): Observable<any> {
    return this.apiService.post('forgot', data);    
  }  

  confirmToken(token): Observable<any> {
    return this.apiService.post('forgot-confirm', {token: token});
  }

  resetPassword(data): Observable<any> {
    return this.apiService.post('reset', data);
  }

  quickSignin(data): Observable<any> {
    return this.apiService.post('quick-signin', data);
  }

  quickSigninConfirm(data): Observable<any> {
    return this.apiService.post('quick-signin-confirm', data);
  }
  

}
