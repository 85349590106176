<div class="container" *ngIf="( $userProfile | async ); let profile;">
    <div class="block">

        <div class="row">
            <div class="col-sm-12">
                <div class="block block-cool">
                    <div class="block-title">
                        <div class="block-title-label" >
                            <mat-icon class="mr-2">settings</mat-icon> Manage {{ profile.company.name }} Settings
                        </div>
                    </div>
                    
                    <div class="row">
                        <ng-container *ngFor="let link of managerNav">
                        <div class="col-6 col-lg-4 py-3"  *ngIf="profile | userCan: link.perms">
                            <div [routerLink]="link.path" class="block-content cursor">
                                <!-- class="menu-tile" -->
                                <div class="row align-items-center">
                                    <div class="pr-0 pl-2 text-right">
                                        <div [style.background]="link.color" class="color-tile d-flex align-items-center justify-content-center">
                                            <mat-icon class="color-white">{{ link.icon }}</mat-icon>
                                        </div>
                                    </div>
                                    <div class="col-sm-9">
                                        <div class="label-block">{{ link.label }}</div>
                                        <p class="body-small mb-0">{{ link.description }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
