import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { map} from 'rxjs/operators';
import { ApiService } from './api.service';
import { environment } from '../../environments/environment';
import { AbstractControl } from '@angular/forms';
import { BrandModel, CompanySetting, Settings, SettingsSubject, SettingTypes} from "../_models/subjects";
import { Observable } from 'rxjs';
import {Title} from "@angular/platform-browser";


@Injectable({
  providedIn: "root",
})
export class VelvetysoftService implements Resolve<any> {
  settings: SettingTypes = {};
  dashboardSnapshot: boolean = false;
  mobileNav: boolean = false;
  layoutFull: boolean = false;
  cdn = environment.cdn;
  nerv = environment.nerv;
  isAdminPage = false;
  allowedSpace = "1000000000";
  maxFileSize = "104857600";
  app = "Show Pony";
  divider = "|"
  subdivider = " - ";
  //THIS IS THE CURRENT VERSION OF THE FRONTEND
  //IF THIS DOESN"T MATCH THE BACKEND VERSION, THE USER WILL BE PROMPTED TO REFRESH
  FEVersion = "1.0.0";
  public colors: any =  null;
  public editor = {
    config: {
      toolbar: [
        ["Bold"],
        ["Italic"],
        ["BulletedList"],
        ["NumberedList"],
        ["clipboard"],
      ],
    },
  };

  navIds: string[];
  sidebarNav: any[];

  pagerOptions = [5, 10, 25, 100];  

  noSideBar = [
    { path: "/dashboard/overview" },
  ];

  //@todo get this variable from route
  noTeamBar = [
    { path: "/dashboard/overview" },
    { path: "/dashboard/welcome" },
    { path: "/dashboard/manage/brands" },
    { path: "/dashboard/manage/users" },
    { path: "/dashboard/manage/invoices" },
    { path: "/dashboard/manage/proposals" },
    { path: "/dashboard/manage/timesheets" },
    { path: "/dashboard/timesheets"},
    { path: "/dashboard/edit/company" },
    { path: "/dashboard/edit/profile" },
    { path: "/dashboard/manage" },
    { path: "/dashboard/team-calendar"},
    { path: "/dashboard/manage/assets" },
    { path: "/dashboard/manage/purchases"},
    { path: "/dashboard/manage/payments" },
    { path: "/dashboard/manage/proposals" },    
    { path: "/dashboard/expenses" },
    { path: "/dashboard/purchases" },
    { path: "/dashboard/new-expenses" },
  ];

  noBrandBar = [
    { path: "/dashboard/edit/company" },
    { path: "/dashboard/edit/profile" },
    { path: "/dashboard/timesheets" },
    { path: "/dashboard/expenses" },
    { path: "/dashboard/manage/brands" },
    { path: "/dashboard/manage/users" },
    { path: "/dashboard/manage/invoices" },
    { path: "/dashboard/manage/timesheets" },
    { path: "/dashboard/manage/assets" },
    { path: "/dashboard/manage/purchases"},
    { path: "/dashboard/manage/permissons" },
    { path: "/dashboard/manage/activity" },
    { path: "/dashboard/manage/payments" },
    { path: "/dashboard/manage/proposals" },    
    { path: "/dashboard/team-calendar"},
    { path: "/dashboard/manage" },
    { path: "/dashboard/expense/" },
    { path: "/dashboard/purchases" },
    { path: "/dashboard/new-expenses" },
  ];

  contentTypes = [
    { path: "/dashboard/overview", label: "Overview", icon: "visibility" },
    {
      path: "/dashboard/projects",
      label: "Projects",
      icon: "folder",
      subject_type: "App\\Project",
    },
    {
      path: "/dashboard/invoices",
      label: "Invoices",
      icon: "monetization_on",
      subject_type: "App\\Invoice",
    },
    {
      path: "/dashboard/proposals",
      label: "Proposals",
      icon: "menu_book",
      subject_type: "App\\Proposal",
    },
  ];
  menuManage = [
    {
      path: "/dashboard/manage/brands",
      color: "#19c37d",
      description: "Manage your Brands",
      label: "Brands",
      icon: "star",
      perms: "manage brand"
    },
    { 
      path: "/dashboard/manage/users", 
      color: "#dd5ce5",
      description: "Manage all Users",
      label: "Users", 
      icon: "person",
      perms: "manage brand"
    },
    { 
      path: "/dashboard/manage/vendors", 
      color: "#7e5ce5",
      description: "Manage all Vendors",
      label: "Vendors", 
      icon: "person",
      perms: "manage vendors"
    },
    {
      path: "/dashboard/manage/invoices",
      color: "#5436da",
      description: "Manage all Invoices",
      label: "Invoices",
      icon: "monetization_on",
      perms: "manage invoice"
    },
    {
      path: "/dashboard/manage/proposals",
      color: "#ef4146",
      description: "Manage all Proposals",
      label: "Proposals",
      icon: "menu_book",
      perms: "manage proposal"
    },
    {
      path: "/dashboard/manage/timesheets",
      color: "#f4ac36",
      description: "Manage User Timesheets",
      label: "Timesheets",
      icon: "timer",
      perms: "manage timesheets"
    },
    {
      path: "/dashboard/manage/assets",
      color: "#36c9f4",
      description: "Manage All Asset",
      label: "Assets",
      icon: "note_add",      
      perms: "manage asset"
    },
    {
      path: "/dashboard/manage/permissons",
      color: "#b7d81a",
      description: "Manage User Permissons",
      label: "Permissons",
      icon: "lock",      
      perms: "manage company"
    },
    {
      path: "/dashboard/manage/activity",
      color: "#d81a8c",
      description: "View Account Activity",
      label: "Activity",
      icon: "help",      
      perms: "manage company"
    },
    {
      path: "/dashboard/manage/payments",
      color: "#2d8945",
      description: "Manage all company payments: employee, vendor, consultant and contractor payments",
      label: "Payments",
      icon: "payments",      
      perms: "manage expenses"      
    },
    {
      path: "/dashboard/manage/purchases",
      color: "#892d2d",
      description: "Manage all company card purchases",
      label: "Company Card Purchases",
      icon: "payments",      
      perms: "manage expenses"      
    }
  ];
  _subjects: SettingsSubject[] = [
    {
      subject_type: null,
      label: "Expenses",
      id: "expenses",
      icon: "payments",
      path: null,
      adminPath: '/dashboard/manage/expenses',
    },
    {
      subject_type: null,
      label: "Overview",
      id: "overview",
      icon: "visibility",
      path: "/dashboard/overview",
      adminPath: null,
    },
    {
      subject_type: "App\\Project",
      label: "Projects",
      id: "project",
      icon: "folder",
      path: "/dashboard/projects",
      adminPath: null,
    },
    {
      subject_type: "App\\Invoice",
      label: "Invoices",
      id: "invoice",
      icon: "monetization_on",
      path: "/dashboard/invoices",
      perms: 'view invoice & proposal pdf',
      adminPath: "/dashboard/manage/invoices",
    },
    {
      subject_type: "App\\Proposal",
      label: "Proposals",
      id: "proposal",
      icon: "menu_book",
      path: "/dashboard/proposals",
      perms: 'view invoice & proposal pdf',
      adminPath: null,
    },
    {
      subject_type: "App\\Brand",
      label: "Brands",
      id: "brand",
      icon: "star",
      path: null,
      adminPath: "/dashboard/manage/brands",
    },
    {
      subject_type: "App\\User",
      label: "Users",
      id: "user",
      icon: "person",
      path: null,
      adminPath: "/dashboard/manage/users",
    },
    { 
      subject_type: "App\\Vendor",
      label: "Vendors",
      id: "vendor",
      icon: "person",
      path: null,
      adminPath: "/dashboard/manage/vendors",
    },
    {
      subject_type: "App\\Asset",
      label: "Assets",
      id: "asset",
      icon: "note_add",
      path: null,
      adminPath: null,
    },
    {
      subject_type: "App\\Round",
      label: "Rounds",
      id: "round",
      icon: "linear_scale",
      path: null,
      adminPath: null,
    },
    {
      subject_type: "App\\Timesheet",
      label: "Timesheets",
      id: "timesheet",
      icon: "timer",
      path: null,
      adminPath: "/dashboard/manage/timesheets",
    },
    {
      subject_type: "App\\Permissons",
      label: "Permissons",
      id: "permissons",
      icon: "lock",
      path: null,
      adminPath: "/dashboard/manage/permissons",      
    },
    {
      subject_type: "App\\Activity",
      label: "Activity",
      id: "activity",
      icon: "help",
      path: null,
      adminPath: "/dashboard/manage/activity",      
    },
    {
      subject_type: "App\\Expense",
      path: null,
      label: "Expenses",
      icon: "payments",      
      id: "expenses",
      adminPath: "/dashboard/manage/expenses",   
    }
  ];
  //@TODO: MOVE THIS TO BACKEND, VH SETTINGS, EVENTUALL WILL NEED TO BE A MODEL FOR OTHER COMPANIES
  // _workType: any = [
  //   { label: "3D Animation", value: "3d" },
  //   { label: "Account", value: "account" },
  //   { label: "Art Direction", value: "artdirection" },
  //   { label: "Bullshitting", value: "bs" },
  //   { label: "Copywriting", value: "copy" },
  //   { label: "Design", value: "design" },
  //   { label: "Downtime", value: "downtime" },
  //   { label: "Illustration", value: "illustration" },
  //   { label: "New Biz", value: "newbiz" },
  //   { label: "Photography", value: "photography" },
  //   { label: "Project Meeting", value: "projectmeeting" },
  //   { label: "Social Media Mgmt", value: "socialmediamgmt" },
  //   { label: "Strategy", value: "strategy" },
  //   { label: "Travel", value: "travel" },
  //   { label: "Web Development", value: "web" },
  // ];

  constructor(
    private apiService: ApiService,    
    private titleService:Title
    ) {
    //MOVE TO DB
    this.navIds = ["overview", "project", "proposal", "invoice"];
    //SETUP NAV
    this.sidebarNav = this.navIds.map((id) => this.findSubject(id));
  }

  title(title:Array<string>, options?){
    this.titleService.setTitle(`${this.app} ${this.divider} ${title.join(this.subdivider)}`)
  }

  resolve() {
    return this.loadSettings();
  }

  settitle(){
    this.app
    this.divider
  }

  /*
  * Finds variables for the given subject Ex; Icon, Label, Path
  */
  findSubject(id) {
    if (!id)
      return {
        subject_type: "",
        label: "",
        id: "",
        icon: "",
        path: "",
        adminPath: "",
      };
    return this._subjects.find((subject) => subject.id == id && subject);
  }


  //CONVERT COMPANY SETTINGS - JOBS TO COMPOANY SETTINGS MODEL- MUTATE DATA FOR DB
  mutateJobs(jobs:Settings):Array<CompanySetting>{
    let collection = [];
    for(let job in jobs.types){
      let _job = jobs.types[job];
      collection.push({name: _job.value, label: _job.title, type: "rate",value: null})
    }
    return collection;
  }

  getSettings(type:string): Settings {
    return this.settings[type];
  }

  collectionToFilters(_setting,_property){
    //console.log(_setting,_property, this.settings)
    return this.settings[_setting][_property].map((x) => {
      return { title: x.name, value: x.slug };
    });
  }

  getYearProjection(brand:BrandModel): null|number{
    const yr = new Date().getFullYear();
    return (brand.yearly_forcast && brand.yearly_forcast[yr])
      ? brand.yearly_forcast[yr]
      : null;      
  }

  loadSettings() {
    let _settings =  this.apiService.get("settings").pipe(
      map(
        (res: any) => {
          for (const settingType in res.settings) {
            
            let _settings = res.settings[settingType];
            
            this.settings[settingType] = {};
            for (const id in _settings) {
              
              let data = _settings[id];
              
              if (data.type == "collection") {
                this.settings[settingType][id] =  [];
                for (const [val, label] of Object.entries(data.data)) {
                  this.settings[settingType][id].push({title: label, value: val})
                }                
              } else {
                this.settings[settingType][id] = data.data;
              }
            }
          }
          this.colors = res.colors;
          return this.settings;
        },
        (err) => {
          console.log(err);
        }
      )
    );
    console.log(`%c Velvetysoft - Showpony @ ${this.FEVersion}`, 'background: #522d6d; color: #ffffff')
    return _settings;
  }

  checkVersion(){
    //@ts-ignore
    return ( this.settings.cache.types?.FEVersion !== this.FEVersion);
  }

  get dashNav() {
    return this.sidebarNav;
  }

  escapeFilename(filename) {
    // Replace any invalid characters with underscores
    const safeFilename = filename.replace(/[\\/:*?"<>|\.]/g, ' ');
    // Encode the filename
    return safeFilename;
  }

  emailMessage(subject) {
    if (subject == "rarr") {
      return `A new ${subject} is ready. Please contact us if you have any questions. `;
    } else if (subject == "round") {
      return `A new round is ready. `;
    } else {
      return '';
    }
  }

  get managerNav() {
    return this.menuManage;
  }

  get subjects(): any {
    return this._subjects;
  }
  subject(id): any {
    return this._subjects.find((s) => s.id == id);
  }

  findRGB(label){
    let res = Object.values(Object.fromEntries(Object.entries(this.colors).filter(([key]) => key.includes(label))))[0];
    return (res)
      ? `rgb(${res})`
      : null ;
  }

  getContrast(hexcolor) {
    // Convert hex color to RGB
      const r = parseInt(hexcolor.substr(1, 2), 16);
      const g = parseInt(hexcolor.substr(3, 2), 16);
      const b = parseInt(hexcolor.substr(5, 2), 16);

      // Calculate luminance
      const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

      // Return 'black' or 'white' based on luminance
      return luminance > 0.5 ? 'black' : 'white';
  }

  hexToRgb(hex) {
    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, '');
  
    // Parse the r, g, b values
    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;
  
    return [r, g, b];
  }
  
  rgbToHex(r, g, b) {
    return '#' + [r, g, b].map(x => {
      const hex = x.toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    }).join('');
  }
  
  increaseBrightness(hex, percent) {
    // Convert hex to RGB
    let [r, g, b] = this.hexToRgb(hex);
  
    // Increase each component by the given percent
    r = Math.min(255, Math.floor(r * (1 + percent / 100)));
    g = Math.min(255, Math.floor(g * (1 + percent / 100)));
    b = Math.min(255, Math.floor(b * (1 + percent / 100)));
  
    // Convert back to hex
    return this.rgbToHex(r, g, b);
  }

  graphData(items) {
    return items
      .map((p) => p.type)
      .reduce(
        (b, c) => (
          (
            b[b.findIndex((d) => d.name === c)] ||
            b[b.push({ name: c, value: 0 }) - 1]
          ).value++,
          b
        ),
        []
      );
  }

  size(bytes, separator = " ", postFix = "") {
    if (bytes == 0) return "0 KB";
    if (bytes) {
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      const i = Math.min(
        parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10),
        sizes.length - 1
      );
      return `${(bytes / 1024 ** i).toFixed(i ? 1 : 0)}${separator}${
        sizes[i]
      }${postFix}`;
    }
    return "n/a";
  }

  validatorArrayMinLength(min: number) {
    return (c: AbstractControl): { [key: string]: any } => {
      if (c.value.length >= min) return null;
      return { minLengthArray: { valid: false } };
    };
  }
  

  //GIVE A LIST OF STATES FOR FORMS
  states(): object[] {
    return [
      { title: "Alabama", value: "AL" },
      { title: "Alaska", value: "AK" },
      { title: "Arizona", value: "AZ" },
      { title: "Arkansas", value: "AR" },
      { title: "California", value: "CA" },
      { title: "Colorado", value: "CO" },
      { title: "Connecticut", value: "CT" },
      { title: "Delaware", value: "DE" },
      { title: "Florida", value: "FL" },
      { title: "Georgia", value: "GA" },
      { title: "Hawaii", value: "HI" },
      { title: "Idaho", value: "ID" },
      { title: "Illinois", value: "IL" },
      { title: "Indiana", value: "IN" },
      { title: "Iowa", value: "IA" },
      { title: "Kansas", value: "KS" },
      { title: "Kentucky", value: "KY" },
      { title: "Louisiana", value: "LA" },
      { title: "Maine", value: "ME" },
      { title: "Maryland", value: "MD" },
      { title: "Massachusetts", value: "MA" },
      { title: "Michigan", value: "MI" },
      { title: "Minnesota", value: "MN" },
      { title: "Mississippi", value: "MS" },
      { title: "Missouri", value: "MO" },
      { title: "Montana", value: "MT" },
      { title: "Nebraska", value: "NE" },
      { title: "Nevada", value: "NV" },
      { title: "New Hampshire", value: "NH" },
      { title: "New Jersey", value: "NJ" },
      { title: "New Mexico", value: "NM" },
      { title: "New York", value: "NY" },
      { title: "North Carolina", value: "NC" },
      { title: "North Dakota", value: "ND" },
      { title: "Ohio", value: "OH" },
      { title: "Oklahoma", value: "OK" },
      { title: "Oregon", value: "OR" },
      { title: "Pennsylvania", value: "PA" },
      { title: "Rhode Island", value: "RI" },
      { title: "South Carolina", value: "SC" },
      { title: "South Dakota", value: "SD" },
      { title: "Tennessee", value: "TN" },
      { title: "Texas", value: "TX" },
      { title: "Utah", value: "UT" },
      { title: "Vermont", value: "VT" },
      { title: "Virginia", value: "VA" },
      { title: "Washington", value: "WA" },
      { title: "West Virginia", value: "WV" },
      { title: "Wisconsin", value: "WI" },
      { title: "Wyoming", value: "WY" },
    ];
  }
  
}




