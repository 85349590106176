import { Component, OnInit, Input } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})
export class DateComponent implements OnInit {
  @Input() date:Date = null;
  isToday:boolean = false;
  constructor() { }

  ngOnInit() {    
    this.isToday = (moment().isSame(this.date, 'day'));
  }

}
