<div class="container" *ngIf="($proposals | async); let proposals; else noProjects">
    <div class="block" *ngIf="($userProfile | async); let profile">
      <div class="block-title" *ngIf="vs.subject('invoice'); let subject">
        <div class="block-title-label"><mat-icon *ngIf="subject.icon" class="mr-2">{{ subject.icon }}</mat-icon>All {{ subject.label }}</div>      
        <div class="block-title-toolbar">                    
          &nbsp;
          <button mat-button [matMenuTriggerFor]="menu" color="primary"><mat-icon>sort</mat-icon>Actions</button>
          <mat-menu #menu="matMenu" class="menu-sm">
            <!-- <button mat-menu-item (click)="delete()" >Delete</button> -->
          </mat-menu>
        </div>
      </div>
      <div class="block-content">
        <!--          
                       -->
        <div *ngIf="proposals && proposals.data">
          <app-table
            mat-table
            #vaultTable
            matSort #sort="matSort"  
            [asyncFilters]="asyncFilters"
            [dataSource]="$proposals"   
            [pager]="proposalspager"
            [columns]="displayedColumns"> 

            <ng-container matColumnDef="author">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Author</th>
              <td mat-cell *matCellDef="let element"><user-avatar  [user]="element.user_id" ></user-avatar></td>
            </ng-container>
            

            <ng-container matColumnDef="brand">
              <th mat-header-cell *matHeaderCellDef> Brand </th>
              <td mat-cell class="cap pr-3" *matCellDef="let element">
                <div *ngIf="element.brand_id; else complexInvoice">
                  <app-brand-logo [tooltip]="true" [bid]="element.brand_id"></app-brand-logo>
                </div>
                <ng-template #complexInvoice>
                  <app-brand-logo [tooltip]="true" [bid]="element.phases[0].brand_id"></app-brand-logo>
                </ng-template>
              </td>
            </ng-container>

            <ng-container matColumnDef="due">
                <th mat-header-cell *matHeaderCellDef> Due </th>
                <td mat-cell *matCellDef="let element" class="text-capitalize" > 
                  {{element.due_date | date}}</td>
              </ng-container>

              
  
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Title </th>
              <td mat-cell *matCellDef="let element" (click)="edit(element)" class="text-capitalize cursor" > {{element.title}} 
              </td>
            </ng-container>

            

            <ng-container matColumnDef="total">
                <th mat-header-cell *matHeaderCellDef> Amount </th>
                <td mat-cell *matCellDef="let element"  > {{element.total | currency}} 
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element"> 
                  <span class="dot-label" [ngClass]="element.status">{{ element.status }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="created">
              <th mat-header-cell *matHeaderCellDef> Created </th>
              <td mat-cell *matCellDef="let element" class="text-capitalize" > 
                {{element.created_at | date}}</td>
            </ng-container>
            


            <ng-container matColumnDef="actions">
              <th mat-header-cell class="text-right" *matHeaderCellDef> Actions </th>
              <td mat-cell class="text-right" *matCellDef="let element">    
                <button (click)="edit(element)" class="mat-sm" mat-icon-button mat-button>
                  <mat-icon>edit</mat-icon>
                </button>
              </td>
            </ng-container>
  
            <tr  mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns; "></tr>
  
          </app-table>
            
          <ng-template #noneYet>
            <mat-spinner color="primary" class="mx-auto loader"></mat-spinner>
            <p>No {{ subject }} found</p>
          </ng-template> 
  
        </div>    
      </div>
    </div>
  </div>
  <ng-template #noneYet>
    <p>No {{ subject }} found</p>
  </ng-template>
  <ng-template #noProjects>
    <mat-spinner color="primary" class="mx-auto loader"></mat-spinner>
  </ng-template>
                