<div class="container" *ngIf="( $project | async ); let project; else loading">  

  <div *ngIf="($userProfile | async); let profile">
    <div class="row" >
      <div class="col-lg-12">

          <div class="block block-cool">
            <div class="block-title">
              <div class="block-title-label">
                <mat-icon *ngIf="subjectObj.icon">{{ subjectObj.icon }}</mat-icon> &nbsp; 
                <span class="subhead-large mb-0 ml-2">{{ project.name }}</span>
                &nbsp; <button *ngIf="(profile | userCan: 'manage project')" class="pull-right" mat-button color="primary" (click)="edit(project,{proposal: proposalTotal})">Manage</button>              
              </div>
              <div>
                &nbsp; <button *ngIf="(profile | userCan: 'manage project')" class="pull-right " mat-button color="do-complete" (click)="markComplete(project)"><mat-icon>verified</mat-icon> Mark Complete</button>
                <!-- <span class="subhead">Created: {{ project.created_at | date }}</span> -->
              </div>
            </div>
            
            <div class="block-content pb-1">
          
              <div class="row tile-inner mb-1">
                <div class="col-auto mb-3 h-100 text-center">
                  <span class="subhead">Status &nbsp;</span>
                  <meta-display subject="project" type="statuses" [id]="project.status"></meta-display>
                </div>
                <div class="col-auto mb-3 h-100 text-center">
                  <span class="subhead">Type: &nbsp;</span>
                  <span class="dot-label p-1">{{project.type}}</span>
                </div>
                <div class="col-auto mb-3 h-100 text-center">
                  <span class="subhead">Kickoff: &nbsp;</span>
                  <span class="dot-label p-1">{{project.kickoff | date }}</span>
                </div>
                <div class="col-auto mb-3 h-100 text-center">
                  <span class="subhead">Due: &nbsp;</span>
                  <span class="dot-label p-1" ><span *ngIf="!project.ongoing">{{project.due | date}}</span> <span *ngIf="project.ongoing"> ongoing</span></span>
                </div>
              </div>
          
              <div class="hr my-3"></div>
          
              <div class="tile-inner" >
                <div class="row">
                  <!-- <div class="col-sm-12"><span class="subhead">Documents:</span></div> -->
                </div>
                <div class="row text-center">
                  <div class="col-6 col-sm-3 p-0  mb-3 h-100">
                    <span >
                      <button mat-button (click)="editBrief(brief)">
                        <mat-icon>work</mat-icon>
                        <span matBadge="{{  (brief) ? 1 : null }}" matBadgeOverlap="false"
                          matBadgeSize="small" matBadgePosition="after">&nbsp; Briefs</span>
                      </button>
                    </span>
                  </div>
                  <div class="col-6 col-sm-3 p-0 mb-3 h-100" *ngIf="profile | userCan: 'view invoice & proposal pdf'">
                    <span *ngIf="($proposals | async); let proposals">
                      <button mat-button (click)="openProposals(proposals,{view:'project',title: 'Proposal Manager',pid: pid})">
                        <mat-icon >menu_book</mat-icon>
                        <span matBadge="{{  (proposals.length > 0) ? proposals.length : null }}" matBadgeOverlap="false"
                          matBadgeSize="small" matBadgePosition="after">&nbsp; Proposals</span>
                      </button>
                    </span>
                  </div>
                  <div class="col-6 col-sm-3 p-0 mb-3 h-100" *ngIf="profile | userCan: 'view invoice & proposal pdf'">
                    <span *ngIf="($invoices | async); let invoices">
                      <button mat-button (click)="openInvoices(invoices,{view:'project',title: 'Invoice Manager',pid: pid, project: project})">
                        <mat-icon *ngIf="vs.findSubject('invoice').icon">{{ vs.findSubject('invoice').icon }}</mat-icon>
                        <span *ngIf="vs.findSubject('invoice').label" matBadge="{{ ( invoices.length > 0 ) ? invoices.length : null }}" matBadgeOverlap="false"
                          matBadgeSize="small" matBadgePosition="after">&nbsp; {{ vs.findSubject('invoice').label }}</span>
                      </button>
                    </span>
                  </div>
                  <div class="col-6 col-sm-3 p-0 mb-3 h-100">
                    <span *ngIf="($assetCabinet | async); let assetCabinet">
                      <button mat-button (click)="openCabinet()">
                        <mat-icon>
                          <mat-icon>perm_media</mat-icon>
                        </mat-icon>
                        <span matBadge="{{ ( assetCabinet.length > 0 ) ? assetCabinet.length : null }}"
                          matBadgeOverlap="false" matBadgeSize="small" matBadgePosition="after">&nbsp; File Cabinet</span>
                      </button>
                    </span>
                  </div>
                </div>
              </div>
          
            </div>
          </div>

      </div>
    </div>
    
    
    <div *ngIf="project.est_round_dates.length > 1 || project.ongoing;else waitingProject">

      <div class="row" *ngIf="($userProfile | async); let profile">
        <div class="col-lg-12">
      
          <div class="block block-cool">
            <div class="block-title">
              <div class="block-title-label">
                <mat-icon *ngIf="subjectObj.icon">timeline</mat-icon> &nbsp; Progress Tracking
              </div>
            </div>

                  
            <div class="block-content py-2" >
                <mat-tab-group>
                  <mat-tab label="Timeline" *ngIf="profile | userCan: 'view project progress'">                
                    <div class="py-3">
                      <div *ngIf="($rounds | async); let rounds;"  class="d-flex tile-inner ">
                        <div class="py-3 h-100 w-100 pp-wrap">
                          <app-project-progress [data]="{rounds: rounds, project: project}" ></app-project-progress>
                        </div>
                      </div>
                    </div>
                  </mat-tab>              
                  <mat-tab label="On-Target Map" *ngIf="(profile | userCan: 'view project management') && !project.ongoing">
                    <app-charts 
                      *ngIf="($rounds | async); let rounds;" 
                      [options]="options"
                      [data]="{rounds: rounds, project: project}" 
                      type="projectGoalZone"></app-charts>
                  </mat-tab>
                  <mat-tab label="Burn Rate" *ngIf="profile | userCan: 'view project projections'">
                    <div                   
                      class="py-3" 
                      *ngIf="($projections | async); let projections">
                        <div class="row m-0">                        
                          <div class="col-sm-6" *ngIf="projections.type">
                            <app-charts type="keyvalue" [data]="projections.type" ></app-charts>
                          </div>
                          <div class="col-sm-6" *ngIf="projections.spent || projections.forecast">
                            <div *ngIf="projections.forecast && projections.spent" class="date text-center">
                              <div class="mx-auto" style="width: 200px">
                                <div class="mb-2">{{ percent | Fn :projections.cost : projections.forecast }} % of budget spent</div> 
                                <mat-progress-bar 
                                  color="primary"
                                  mode="determinate" 
                                  class="vh-progress"
                                  value="{{ percent(projections.cost,projections.forecast) }}"></mat-progress-bar>
                              </div>
                            </div>
                            <div class="rel">
                              <app-charts type="projectProjectionsTotal" [data]="projections" ></app-charts>
                            </div>
                          </div>
                          <div *ngIf="!(projections.type)" class="col-sm-12">
                            <div class="p-4">
                              <label class="title-section cap-init">No time attributed to this project yet.</label>
                              <div class="d-flex align-items-center">
                                <span class="mr-3">Team:</span> 
                                <app-users 
                                  class="inline" 
                                  [users]="project.users" 
                                  showName="true" 
                                  role="employee">
                                </app-users>
                              </div>
                            </div>
                          </div>
                        </div>               
                      
                    </div>
                  </mat-tab>
                  <mat-tab label="Hours" *ngIf="(profile | userCan: 'view project projections')">                  
                    <div                   
                      class="py-3" 
                      *ngIf="($hours | async); let hoursData">
                      <div class="row m-0">                        
                        <div class="col-sm-8 rel">
                          <app-charts 
                            type="weekly" 
                            [options]="options"
                            [data]="{data: hoursData, project: project}" ></app-charts>
                        </div>
                        <div class="col-sm-4">
                          <p>Export hours by employee</p>
                          <button mat-raised-button (click)="exportHours(project)" disabled="true" color="primary">
                            Export To CSV
                          </button>
                        </div>
                      </div>
                    </div>
                  </mat-tab>
                </mat-tab-group>                        
            </div>

          </div>
        
        </div>
      </div>

    


      <div class="row">
        <div class="col-lg-12">
          <div class="block" style="margin-bottom:0!important;">
            <div class="block-title" style="justify-content: initial!important;">
              <div class="block-title-label">
                <mat-icon>linear_scale</mat-icon> &nbsp; {{roundLabel}}s
              </div>
              <div class="block-title-toolbar">
                <button *ngIf="(profile | userCan: 'manage round')" mat-button (click)="addRound(project.id)" color="primary">
                  <mat-icon>add</mat-icon>New {{roundLabel}}
                </button>
                <button mat-button (click)="toggleAll()" color="primary">
                  <span *ngIf="!openRounds; else toggle">Open All</span>
                  <ng-template #toggle><span>Close All</span></ng-template>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>  

      <div class="mb-4" *ngIf="($rounds | async); let rounds; else loading">
        <mat-accordion 
          *ngIf="rounds.length > 0"
          displayMode="flat" 
          [multi]="true" 
          #roundsAccordian="matAccordion">
          <mat-expansion-panel 
            *ngFor="let round of rounds; let i=index;let last = last" 
            class="round" [ngClass]="{'round-last': last}" 
            #rarr
            [expanded]="deepSelectedRound == round.id">
            <mat-expansion-panel-header>
              <div class="round-indicator" ><span *ngIf="!project.ongoing">R{{ i+1 }}</span></div>
              <mat-panel-title>
                <div>
                  <h6 class="round-label">{{ round.name }}</h6> <div class="date" *ngIf="round.due_date; else elseDate">{{ round.due_date | date }}</div>
                  <ng-template #elseDate  ><div class="date">{{ round.created_at | date }}</div></ng-template>
                </div>
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>          
            <div>
              <div class="round-header">
                <div class="round-content" [innerHTML]="round.description | urlify"></div>
                <div class="toolbar" *ngIf="(profile | userCan: 'manage round')">
                  <span class="subhead" *ngIf="round.sent_at"><small>Sent: {{ round.sent_at | date }}</small></span>
                  <button matTooltip="Email Round" (click)="emailRound(project.brand_id,round)" mat-icon-button mat-button><mat-icon>email</mat-icon></button>            
                  <!-- <button [ngClass]="{'active': round.meeting_notes}" matTooltip="Internal Notes" (click)="openNotes(round)" mat-icon-button mat-button>
                    <mat-icon                  
                    >attachment</mat-icon>
                  </button> -->
                  <button matTooltip="Edit" (click)="addRound(project.id,round)" mat-icon-button mat-button><mat-icon>edit</mat-icon></button>                
                  <button mat-icon-button mat-button (click)="trashRound(round.id)"><mat-icon>delete</mat-icon></button>            
                </div>
              </div>
              
              <div class="my-3" style="border-bottom: 1px solid rgba(0, 0, 0, 0.06);"></div>

              <div class="row" *ngIf="round.assets && round.assets.length">
                  <app-asset *ngFor="let asset of round.assets" (assetChanged)="assetEdit($event)" [currentUser]="profile" [asset]="asset"></app-asset>
              </div>
              <div *ngIf="(profile | userCan: 'manage round')">
                <app-media-manager
                  subject="round"
                  [model]="round"
                  mediaLibrary="true"
                  (assetsAdded)="uploadRoundAsset($event)"
                ></app-media-manager>
                <button mat-button (click)="addRound(project.id,round,{view: 'nextsteps',title: 'Round Next Steps'})" color="primary">
                  <mat-icon>arrow_forward_ios</mat-icon>
                  <span *ngIf="round.next_steps; else ns">Edit Next Steps</span>
                  <ng-template #ns><span>Add Next Steps</span></ng-template>              
                </button>
                
                <ng-container *ngIf="(profile | userCan: 'manage company')">
                  <button *ngIf="io.isConnected" mat-button (click)="webshots({model: round})" color="primary"><mat-icon>public</mat-icon> Add Website Pages</button>
                </ng-container>
                <!-- <span *ngIf="(profile | userCan: 'view notes')">
                  <button mat-button 
                    (click)="openNotes(round)" 
                    color="primary" 
                    >
                    <mat-icon>attachment</mat-icon> <span *ngIf="round.meeting_notes; else addnotes">View Internal Notes</span>
                    <ng-template #addnotes><span>Add Internal Notes</span></ng-template> 
                  </button>
                </span> -->
              </div>
              
              <!-- <label class="mat-button mat-button-base mat-primary">
                <span class="mat-button-wrapper"><mat-icon>note_add</mat-icon> Add Files</span>
                <div class="mat-button-focus-overlay"></div>
                <input type="file" name="file" hidden multiple (change)="uploadRoundAsset($event,round)">
              </label> -->
              <div class="next-steps" *ngIf="round.next_steps">
                <div class="row">
                  <div class="col-auto d-flex align-items-center" >
                    <div class="ns-label">
                      <div><span class="block-title-label" style="font-weight: 400;color: #f2f2f2;">Next Steps: </span></div>
                    </div>
                  </div>
                  <div class="col-sm-9 html-reset" [innerHTML]="round.next_steps"></div>
                </div>
              </div>
            </div>        
          </mat-expansion-panel>        
        </mat-accordion>
      </div>

    </div>

    <ng-template #waitingProject>
      <div class="block text-center mx-auto">
        <div class="block-content">
          <div class="title-section my-3">Setup your project</div> 
          <button mat-raised-button color="primary" (click)="edit(project,{proposal: proposalTotal})">Get Started</button>
        </div>
      </div>
    </ng-template>
    
    <app-asset-cabinet 
      class="scrollbar"
      [ngClass]="{'open': cabineOpen}"
      [project]="project"
      (toggleDisplay)="openCabinet()"
      ></app-asset-cabinet>

    <!-- <app-meeting-notes 
    class="scrollbar"
    [ngClass]="{'open': notesOpen}"
    [round]="selectedNotes"
    (toggleDisplay)="openNotes()"
    ></app-meeting-notes> -->
  </div>
</div>



<ng-template #loading><app-loader></app-loader></ng-template>



