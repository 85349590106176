import { Component, OnInit, ViewChild } from '@angular/core';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators, UntypedFormArray } from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { MatCalendarCellCssClasses, MatCalendar, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { tap } from 'rxjs/operators';
import { filterUserCanByPipe } from 'src/app/pipes.pipes';
import * as moment from "moment";
import { Observable } from 'rxjs';
import { UserProfileModel } from 'src/app/_models/subjects';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-team-cal',
  templateUrl: './team-cal.component.html',
  styleUrls: ['./team-cal.component.scss'],
  providers: [
    filterUserCanByPipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ]
  //encapsulation: ViewEncapsulation.None
})
export class TeamCalComponent {
  days: any[] = [];
  weekRange: any = { start: null, end: null };  
  $userProfile: Observable<UserProfileModel>;
  $staff: Observable<any>;
  currentWeek = null;
  month = null;
  @ViewChild(MatCalendar) _cal: MatCalendar<any>;
  constructor(
    private userService: UserService
  ){

  }


  ngOnInit() {
    this.currentWeek = moment();    
    this.updateDisplay();
  }

  prevWeek() {
    this.currentWeek = this.currentWeek.clone().add(-1, "months");
    this.updateDisplay();
  }

  nextWeek() {
    this.currentWeek = this.currentWeek.clone().add(1, "months");
    this.updateDisplay();
  }

  selectWeek(type: string, event: MatDatepickerInputEvent<Date>) {
    this.currentWeek = event.value;
    this.updateDisplay();
  }


  updateDisplay(){
    
    this.$staff = this.userService.
      staff(this.currentWeek.clone().startOf('month').format('YYYY-MM-DD') )
      .pipe(tap(res => {
        this.days = [];  
        let week = [];      
        // get the first day of the month
        let start = this.currentWeek.clone().startOf('month').startOf('week');
        // get the last day of the month
        let end = this.currentWeek.clone().endOf('month').endOf('week');

        //SETUP A MAP FOR USERS WHO ARE OUT FOR THE DAY
        let _map = [];
        res.ts.map((ts)=>{
          let uid = ts.user.id;         
          //iterator over array of ts.days
          ts.dates.map((day)=>{
            let dayArr = _map[day] || [];
            dayArr.push(uid);
            _map[day] = dayArr;
          })
        });


        // loop through the days of the week
        
        for (let date = start; date.isSameOrBefore(end); date.add(1, 'day')) {
          // add the date to the days array
          
          //USERS WHO ARE OUT FOR THE DAY
          let users = [];
          for (var mapDate in _map) {
            if(date.isSame(mapDate,'day')) users.push(_map[mapDate]);              
          }

          //CREATE DAY
          week.push({
            out: date.month() !== this.currentWeek.month(),
            date: date.clone(),
            users: users.flat()
          });

          // if the date is a sunday, create a new week
          if (date.day() === 6) {        
            this.days.push(week);
            week = [];
          }      
        }    
      
        //ADD END OF THE WEEK
        if(week.length > 0) this.days.push(week);
        //end sub
        
      }));
   
  }

  // selectWeek(type: string, event: moment.Moment) {        
  //   console.log('selectWeek')
  //   //console.log(event, this.currentWeek.value)
  //   //if (event.diff(this.currentWeek.value)){
  //   if (event.diff(this.currentWeek.value, 'days') < 7 && event.diff(this.currentWeek.value, 'days') > 0){
  //     return console.log('skip');
  //   }
  //   //}
  //   this.currentWeek.patchValue(event);
  //   //this.generateTimesheets();
  //   //FORCE UPDATE
  //   this._cal.updateTodaysDate();
  // }

  markCalendarDates() {
    return (date: moment.Moment): MatCalendarCellCssClasses => {
      let _date = date.format("Y-M-D");
      let output = "";

      // this.approveds.map((appDate)=>{
      //   if( moment(appDate).isSame(date,'week') ){
      //     output += "approved ";
      //   }
      // })

      if (date.isBetween(this.weekRange.start, this.weekRange.end)) {
        output += "selected-week ";
      }
      if (
        date.format("Y-M-D") == "2019-11-27" ||
        date.format("Y-M-D") == "2019-11-19"
      ) {
        output += "has-timesheet";
      }

      return output;
      
    };
  }
  
}
