import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BrandList, BrandModel, PagerObject, Settings, UserModel, UserProfileModel } from 'src/app/_models/subjects';
import { ApiService } from 'src/app/_services/api.service';
import { UserService } from 'src/app/_services/user.service';
import { VelvetysoftService } from 'src/app/_services/velvetysoft.service';
import { ActivityService } from 'src/app/_services/activity.service';
import { ActivityModel } from 'src/app/_models/activity';
import { ProjectService } from 'src/app/_services/project.service';

@Component({
  selector: 'app-manager-activity',
  templateUrl: './manager-activity.component.html',
  styleUrls: ['./manager-activity.component.scss']
})
export class ManagerActivityComponent implements OnInit {
  $activity: any; //Observable<ActivityModel[]>
  subjects: Array<any> = [];
  $users: Observable<UserModel[]>;
  workTypes: Settings;
  users: UserModel[];
  brandList:BrandList[];
  activitypager: PagerObject;
  displayedColumns: string[] = ['select', 'name','created','subject', 'event','info',];
  @ViewChild('vaultTable') vaultTable;
  subject: any;
  $userProfile: Observable<UserProfileModel>;
  $brandList: Observable<BrandModel[]>;
  asyncFilters = {
    user: 'all',    
    filters:[]
  };

  constructor(
    private vs: VelvetysoftService,
    private apiService: ApiService,
    private userService: UserService,
    private activityService: ActivityService,
    private projectService: ProjectService
  ) { }

  ngOnInit() {
    this.$brandList = this.projectService.projectTree;
    this.$userProfile = this.userService.userProfile();
    this.subject = this.vs.managerNav.find( item => item.label == "Activity" );
    this.$users = this.userService.loadUsersAssignedTo('brand', null);
    
    this.subjects = this.vs.subjects;
    console.log(this.subjects)

    let _subjectsData = this.subjects;
    this.asyncFilters.filters.push({
      label: 'Type',
      field: 'subject_type',
      data: _subjectsData.map( subject => {
        if(subject?.subject_type)
          return {label: subject.label, value: subject?.subject_type?.replace('App\\','App\\Models\\')}
        return false;
      }),
    })

    console.log(this.asyncFilters)

    this.activitypager = this.activityService.pager();
    this.$activity = this.activitypager.$source
      .pipe( map( pager => {
        pager.data.map( activity => {
          let sid = activity.subject_type.replace('Models\\','');
          activity.subject = this.subjects.find( s => s.subject_type == sid);          
          return activity;   
        })
        return pager;
      }));
    
    this.workTypes = this.vs.getSettings('jobs');
    let internalTypes = this.vs.getSettings('internaljobs');
    this.workTypes.types = [...this.workTypes.types, ...internalTypes.types];
  }

  userById(id, users){
    return users.find(user => user.id == id);
  }

  findBrandProjects(brands,id,pid?){
    let brand =  brands.find( brand => brand.id == id)
    if(pid) return brand.projects.find( project => project.id == pid);
    return brand;
  }

  findType(type){
    return this.workTypes.types.find( _type => _type.value == type ).title;
  }

  ngOnDestroy() {
    //this.$users.unsubscribe();
    //this.$brandList.unsubscribe();
  }

}
