<h5>Histroy</h5>
<div>Sent At: {{ invoice.sent_at }}</div>
<br>

<div *ngIf="($activity | async); let activity">
  <div *ngFor="let action of activity">
    {{ action.description }} : {{ action.created_at }} By {{ action.causer_id }}<!-- <user-avatar [user]="action.causer_id"></user-avatar> -->
  </div>
</div>

<div *ngIf="invoice.analytics.length > 0">
  <div  *ngFor="let analytic of invoice.analytics">
    <span *ngIf="analytic.user; else useremail">
      <user-avatar style="display: inline-block;" [showName]="true" [user]="analytic.user"></user-avatar>
    </span>
    <ng-template #useremail>{{ analytic.user_id }}</ng-template>
    Viewed email at: {{ analytic.created_at | amFromUtc | amLocal | amCalendar }}
  </div>
</div>