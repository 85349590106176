<form [formGroup]="form">
<div class="fieldset mb-4" formArrayName="addresses" *ngFor="let addresses of formAddresses; let i = index">
    <div class="row ml-2" [formGroupName]="i">                  
      <div class="col-sm-6">
        <mat-form-field class="w-100">
          <input matInput placeholder="Address" type="text" formControlName="street">
        </mat-form-field>
      </div>                        
      <div class="col-sm-4">          
        <mat-form-field class="w-100">
          <input matInput placeholder="City" type="text" formControlName="city">
        </mat-form-field>
      </div>
      <div class="col-sm-4">
        <mat-form-field class="w-100">
          <mat-select formControlName="state" placeholder="State">
            <mat-option>None</mat-option>
            <mat-option *ngFor="let state of states" [value]="state.title">{{state.title}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-3">
        <mat-form-field class="w-100">
          <input matInput placeholder="Zip" mask="00000" type="text" formControlName="post_code">
        </mat-form-field>
      </div>        
      <div class="col-sm-12" >
        <div class="form-group">
          <mat-checkbox *ngIf="!hideOptions" #checkUniq (change)="checkedUnique($event,i,'is_primary')" formControlName="is_primary">Primary Address</mat-checkbox> &nbsp;
          <mat-checkbox *ngIf="!hideOptions" #checkUniq (change)="checkedUnique($event,i,'is_billing')" formControlName="is_billing">Billing Address</mat-checkbox> &nbsp;
          <button mat-button color="danger" (click)="delete($event,i)">Delete</button>
        </div>
      </div>
    </div>
  </div>
  <button *ngIf="addresses.length < limit" mat-stroked-button (click)="addAddress($event)"><mat-icon>add</mat-icon> Add Address</button> 
</form> 