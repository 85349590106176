<!-- @TODO: change status to be editable for draft when id is indefined -->
<div class="vault-modal" [ngClass]="{'modal-library': isLibraryOpen}">
    <button mat-button mat-button-base class="float-right" (click)="close()">
      <mat-icon class="mat-sm">close</mat-icon>   
    </button>

    <h1 mat-dialog-title>
      <ng-container *ngIf="data?.options?.title; else normalTitle">
        {{ data?.options?.title }}
      </ng-container>
      <ng-template #normalTitle>
        <span *ngIf="!hasNew; else addTitle">Edit</span>
        <ng-template #addTitle>
          Create
        </ng-template>
        {{ subject | capitalize }} {{ data.title }} 
      </ng-template>
    </h1>
    
    <div mat-dialog-content *ngIf="($userProfile | async);let profile">
      
      <div *ngIf="data?.options?.view">
        <mat-tab-group>
          <mat-tab label="Proposal" *ngIf="data?.options?.view == 'proposal'">
            <div class="mt-3" *ngIf="data[subject]; let proposal">
              <app-alert></app-alert>  
              <div class="form-group">
                <span class="label mr-2">Status:</span> <meta-display subject="proposal" [item]="proposal" type="statuses" [id]="proposal.status"></meta-display>
              </div>            
              <div class="form-group">
                <div class="my-4">
                  <span *ngIf="profile | userCan: 'view invoice & proposal'">
                    <button class="my-2" *ngIf="proposal.status == 'sent'" mat-raised-button class="mr-2" (click)="approved(proposal)" color="accent"><mat-icon>check</mat-icon> Mark Approved</button>
                    <button class="my-2" mat-raised-button class="mr-2" (click)="send(proposal)" color="primary"><mat-icon>email</mat-icon> &nbsp;<ng-container *ngIf="proposal.sent_at"> Re-</ng-container>Send</button>          
                    <button class="my-2" *ngIf="proposal.approved_doc" mat-button class="mr-2" (click)="downloadApproved(proposal.approved_doc)" color="primary" >View Approval</button>                  
                  </span>
                  <button class="my-2" mat-button class="mr-2" (click)="view(proposal)" color="primary">View PDF</button>
                  <button class="my-2" mat-button class="mr-2" (click)="download(proposal)" color="primary">Download PDF</button>
                </div>
              </div>
              
              <div class="form-group" *ngIf="proposal?.phases?.length > 0 || proposal.projects.length > 0 ">
                <div class="block-title-label cap-init">Projects associated with Proposal </div>
                <ng-container *ngIf="proposal?.projects?.length > 0" [ngTemplateOutlet]="prjs" [ngTemplateOutletContext]="{projects: proposal.projects}"></ng-container>
                <ng-container *ngIf="proposal?.phases?.length > 0 && proposal?.projects?.length < 1" [ngTemplateOutlet]="prjs" [ngTemplateOutletContext]="{projects: proposal.phases}"></ng-container>
                <ng-template #prjs let-projects='projects'>
                  <div class="row p-0  align-items-center m-0 table-text " *ngFor="let project of projects">
                    
                  <ng-container *ngIf="proposal?.projects?.length < 1 && project.project_id" class="cell">
                    <div class="col-sm-8 cursor" (click)="navTo('/dashboard/project/'+project.project_id)"> 
                      <span *ngIf="project.name">{{ project.name }}</span>
                      <span *ngIf="project.title; else projectTitle">{{ project.title }}</span>
                      <ng-template #projectTitle>{{ proposal.title }}</ng-template>
                    </div>
                    <div class="col-sm-4" >
                      <button mat-button *ngIf="project.project_id && project.project_id !== null" (click)="navTo('/dashboard/project/'+project.project_id)" color="primary">View</button>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="proposal?.projects?.length > 0 && project.id">
                    <div class="col-sm-8 cursor" (click)="navTo('/dashboard/project/'+project.id)"> 
                      <span *ngIf="project.name">{{ project.name }}</span>
                      <span *ngIf="project.title">{{ project.title }}</span>
                    </div>
                    <div class="col-sm-4" *ngIf="project.id">
                      <button mat-button *ngIf="project.id" (click)="navTo('/dashboard/project/'+project.id)" color="primary">View</button>
                    </div>
                  </ng-container>
                  </div>
                </ng-template>
              </div>

              <div class="form-group" *ngIf="profile | userCan: 'view invoice & proposal'">
                <div class="block-title-label cap-init">History for this Proposal</div>
                <div>
                  <app-activity-log
                    [ids]="proposalIDS"
                    type="projectProposals"
                    exclude=""
                  ></app-activity-log>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="View Proposal" *ngIf="data?.options?.view == 'project'">
            <div class="mt-3">
            
              <div class="my-3" *ngIf="profile | userCan: 'view invoice & proposal'">
              <button mat-raised-button color="primary" (click)="addProposal(activePID)">Add New Proposal</button>
              </div>

              <div class="form-group">
                <div class="block-title-label">Proposal</div>
                <div *ngFor="let proposal of data[subject]" class="row align-items-center m-0 table-text cell">
                  <div class="col-sm-4 cursor click title" (click)="navTo('/dashboard/proposals')">{{ proposal.title }}</div>
                  <div class="col-sm-2"><span class="dot-label" [ngClass]=" proposal.status">{{ proposal.status }}</span></div>
                  <!-- <div class="col-sm-2">#{{ proposal.proposal_number }}</div> -->
                  <div class="col-sm-3" *ngIf="phaseTotal(proposal,activePID); let phasetotalsval">
                    <span *ngIf="(phasetotalsval.sub); else ptotal">
                      {{ phaseTotal(proposal,activePID)?.sub | currency }} of 
                      {{ phaseTotal(proposal,activePID)?.total | currency}}
                    </span>
                    <ng-template #ptotal>{{ phaseTotal(proposal,activePID)?.total | currency }}</ng-template>
                  </div>
                  <div class="col-sm-3 p-0">
                    <button mat-button *ngIf="profile | userCan: 'view invoice & proposal'" (click)="editProposal(proposal)" color="primary">Edit</button>
                    <button mat-button (click)="view(proposal)" color="primary">View PDF</button>
                  </div>
                </div>
              </div>
              <div class="form-group" *ngIf="profile | userCan: 'view invoice & proposal'">
                <div class="block-title-label cap-init">All Proposal history for this project</div>
                <div>
                  <app-activity-log
                    [ids]="proposalIDS"
                    type="projectProposals"
                  ></app-activity-log>
                </div>
              </div>
            </div>
          </mat-tab>
          <ng-container *ngIf="data?.options?.view == 'proposal'">
          <mat-tab label="Edit Proposal" *ngIf="profile | userCan: 'view invoice & proposal'">
            <div class="mt-3">
              
              <div class="hide-overflow">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="mat-form-field-wrapper">
                      <mat-slide-toggle class="example-margin" [(ngModel)]="isLibraryOpen" color="primary" [checked]="isLibraryOpen">
                        <span class="subhead"> {{ subject | capitalize }} Library </span>
                      </mat-slide-toggle>
                    </div>
                  </div>
                </div>
                <div class="row m-0">        
                  <div class="col-sm-4 p-0" *ngIf="isLibraryOpen">
                    <app-modal-library-list
                      [subject]="subject"
                      (loadItem)="loadLibrary($event)"
                      [collection]="$proposals">
                    </app-modal-library-list>        
                  </div>
                  
                  <div class="col-sm-8" [ngClass]="{'col-sm-12': !isLibraryOpen}">
                    <mat-tab-group
                    animationDuration="0ms"
                    [selectedIndex]="modalLibraryService.panelSelected.value"
                    (selectedIndexChange)="modalLibraryService.panelSelected.setValue($event)">
                      <mat-tab *ngFor="let panel of panels;let index = index" [label]="panel"> 
                        <ng-container [ngTemplateOutlet]="subjectRef" [ngTemplateOutletContext]="{parent: this,formIndex:index}"></ng-container>
                      </mat-tab>
                    </mat-tab-group>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
          </ng-container>
        </mat-tab-group>
      </div>

      <!-- CREATE NEW -->
      <div *ngIf="!data?.options?.view">
        <div class="mt-3 row">
          <div class="col-sm-12">
            <div class="mat-form-field-wrapper">
              <mat-slide-toggle class="example-margin" [(ngModel)]="isLibraryOpen" color="primary" [checked]="isLibraryOpen">
                <span class="subhead"> {{ subject | capitalize }} Library </span>
              </mat-slide-toggle>
            </div>
          </div>
          <div class="col-sm-4 p-0" *ngIf="isLibraryOpen">
            <app-modal-library-list
              [subject]="subject"
              (loadItem)="loadLibrary($event)"
              [collection]="$proposals">
            </app-modal-library-list>        
          </div>
          
          <div class="col-sm-8" [ngClass]="{'col-sm-12': !isLibraryOpen}">
            <mat-tab-group
              animationDuration="0ms"
              [selectedIndex]="modalLibraryService.panelSelected.value"
              (selectedIndexChange)="modalLibraryService.panelSelected.setValue($event)">
                <mat-tab *ngFor="let panel of panels;let index = index" [label]="panel"> 
                  <ng-container [ngTemplateOutlet]="subjectRef" [ngTemplateOutletContext]="{parent: this,formIndex:index}"></ng-container>
                </mat-tab>
          </mat-tab-group>
            <!-- <ng-container 
              *ngIf="form"
              [ngTemplateOutlet]="subjectRef" 
              [ngTemplateOutletContext]="{parent: this,formIndex:0}"></ng-container> -->
              
          </div>
        </div>
      </div>

      <ng-template #subjectRef let-formIndex='formIndex'  let-parent="parent">  
        <form [formGroup]="form" class="py-2"> 

          <div *ngIf="!editMultiple">
            <div class="library-controls p-1 pb-2" *ngIf="formIndex > 0"> 
              <button mat-button mat-button-base color="primary" (click)="cloneFrame(formIndex)"><mat-icon class="mat-sm">file_copy</mat-icon>  Clone {{ subject }}</button>
              <button mat-button mat-button-base  class="float-right" (click)="closeFrame(formIndex)"><mat-icon class="mat-sm">close</mat-icon>Close</button>
            </div>
          </div>

          <div formArrayName="items" class="w-100" >
            <div [formGroupName]="formIndex" class="w-100">      
                <app-alert></app-alert>
                <div class="p-1">
                  <div class="row">
                    <div class="col-sm-12" *ngIf="parent.data.proposal.user">
                      <div class="form-group">
                        <div><label class="label">Created By</label></div>
                        <user-avatar [user]="parent.data.proposal.user" [showName]="true"></user-avatar>
                      </div>
                    </div>              
                    <div class="col-sm-3">
                      <div *ngIf="parent.settings.statuses; let statuses">
                        <mat-form-field class="w-100">
                          <mat-select placeholder="Status" formControlName="status">
                            <mat-option 
                              [disabled]="parent.data.proposal.id == null ? true : false" 
                              *ngFor="let status of statuses"
                              [value]="status.value">
                              {{status.title}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="col-sm-3" *ngIf="thisform(formIndex).controls.status.value == 'approved'">
                      <mat-form-field class="w-100">
                        <input matInput [matDatepicker]="picker" placeholder="Approved At" formControlName="approved_at">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error *ngIf="thisform(formIndex).controls.approved_at.invalid">Enter a valid date</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-sm-3" *ngIf="thisform(formIndex).controls.status.value == 'sent' || thisform(formIndex).controls.status.value == 'approved'">
                      <mat-form-field class="w-100">
                        <input matInput [matDatepicker]="picker" placeholder="Sent At" formControlName="sent_at">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error *ngIf="thisform(formIndex).controls.sent_at.invalid">Enter a valid date</mat-error>
                      </mat-form-field>
                    </div>
                    
                    <div class="col-sm-5 mb-3" *ngIf="['sent','approved'].includes(thisform(formIndex).controls.status.value)">
                      <div><label for="" class="label mr-3">Approved Document</label></div>
                      <app-file-upload 
                        type="proposal_approval"
                        class="might-be-trans inline"
                        (uploadComplete)="attachApproval($event,formIndex)" 
                        [_file]="thisform(formIndex).controls.approved_doc.value">
                      </app-file-upload>
                      <div *ngIf="thisform(formIndex).controls.approved_doc.value; let appvDoc">
                        <button  mat-button color="primary" (click)="downloadApproved(appvDoc)">View Approved Doc</button>                        
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-6">
                      <mat-form-field class="w-100">
                        <input matInput name="title" placeholder="Title" formControlName="title" required>
                      </mat-form-field>
                    </div>
                  </div>
              
                  <!-- WEIRD IF -->
                  
                  


                  <div class="row">
                    <!-- <div *ngIf="($brandList | async); let brands" class="col-sm-3" >
                      <div >                  
                        <mat-form-field class="w-100">
                          <span *ngIf="findBrand(brands,thisform(formIndex).controls.brand_id.value); let brand" matPrefix>{{ brand.prefix }} &nbsp;</span>
                          <input matInput [readonly]="proposalNumLocked" placeholder="Proposal #" mask="0000"
                            formControlName="proposal_number" required>
                          <button matSuffix mat-icon-button class="mat-sm" *ngIf='proposalNumLocked' (click)="toggleProposalNumber()">
                            <mat-icon style="margin-top: -8px;">lock_open</mat-icon>
                          </button>
                        </mat-form-field>
                      </div> 
                    </div>-->
                    <div class="col-sm-4">
                      <div *ngIf="settings.types; let types">
                        <mat-form-field class="w-100">
                          <mat-select placeholder="Project Type" formControlName="type" required>
                            <mat-option *ngFor="let type of types" [value]="type.value">
                              {{type.title}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="col-sm-5">
                      <mat-form-field class="w-100">                
                        <input matInput [matDatepicker]="picker" placeholder="Project Estimated Due Date" required formControlName="due_date">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error *ngIf="thisform(formIndex).controls.due_date.invalid">Enter a valid date</mat-error>
                      </mat-form-field>
                    </div>
              
                  </div>
              
                  <div class="row">
                    <div class="col-sm-3">
                      <mat-form-field class="w-100">
                        <span matPrefix>$ &nbsp;</span>
                        <input matInput placeholder="Total" autocomplete="off" formControlName="total" mask="separator.2"
                          thousandSeparator="," required>
                      </mat-form-field>
                    </div>
                  </div>

                  
              
                  <!-- <div class="row">
                    <div class="col-sm-10 p-3 invoice-total-block form-group">
                      <div class="row" *ngIf="getProjectedCost(formIndex); let projectedCost">
                        <label class="col-sm-2 label">Projected Total: </label>
                        <span class="col-sm-2 total">{{ projectedCost | currency:'USD':true:'1.2-2' }}</span>
                      </div>
                      <div class="row">
                        <label class="col-sm-2 label" matTooltip="This is the total that the client will see">Total:</label>
                        <span class="col-sm-2 total"> {{ thisform(formIndex).controls.total.value | currency:'USD':true:'1.2-2' }}</span>
                      </div>              
                    </div>
                  </div> -->
              
                  <!-- <div class="row">
                    <div class="col-sm-10">
                      <div class="form-group">
                        <label class="label">Description</label>
                        <ckeditor [editor]="Editor" formControlName="description"></ckeditor>
                      </div>
                    </div>
                  </div> -->
                  

                  <div class="row m-0">
                    <div class="col-sm-12 mx-0 px-0">
                      <mat-accordion cdkDropList (cdkDropListDropped)="drop($event,formIndex)" class="px-0" #phasesAccordian="matAccordion">
                        <div formArrayName="phases" class="project-item" cdkDrag cdkDragLockAxis="y"  *ngFor="let phase of formPhases(formIndex); let i = index">
                          <div [formGroupName]="i" class=" w-100">
                            <!-- HACK2 -->                
                            <mat-expansion-panel class="project-item-panel" [expanded]="true" (opened)="true">
                              <mat-expansion-panel-header cdkDragHandle >
                                <mat-panel-title>
                                  <span *ngIf="formPhase(formIndex,i).controls.title.value; let ptitle; else default"> {{ ptitle }}</span>
                                  <ng-template #default ><span *ngIf="formPhases(formIndex).length > 1">Project: {{ i+1 }}</span></ng-template>
                                </mat-panel-title>
                                <mat-panel-description>
                                  <span *ngIf="formPhase(formIndex,i).controls.title.value && formPhase(formIndex,i).controls.total.value">{{ formPhase(formIndex,i).controls.total.value | currency:'USD':true:'1.2-2' }}</span>
                                </mat-panel-description>
                              </mat-expansion-panel-header>      
                              <div class="form-row"  >
                                <div class="col-sm-12 text-right">
                                  <mat-checkbox value="create" color="primary" formControlName="showOptions" >
                                    <span class="label">Show More Options</span>
                                  </mat-checkbox>
                                </div>       
                                <div class="row w-100" *ngIf="($brandList | async); let brands">
                                  <div class="col-sm-12" *ngIf="formPhase(formIndex,i).controls.showOptions.value">
                                    <mat-form-field class="w-100"  >
                                      <mat-select placeholder="Brand" formControlName="brand_id">
                                        <mat-option value="">- None -</mat-option>
                                        <mat-option *ngFor="let brand of brands" [value]="brand.id">
                                          <app-brand-logo [brand]="brand"></app-brand-logo> &nbsp; <span>{{brand.name}}</span>
                                        </mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                  </div>                                  
                                  <div class="col-sm-8" *ngIf="formPhase(formIndex,i).controls.showOptions.value">
                                    <label class="label" for="">Attach a existing project to invoice for tracking project payments</label>
                                    <div *ngIf="formPhase(formIndex,i).controls.brand_id.value;let bid">
                                      <div *ngIf="findBrand(brands,bid); let brand">
                                        <mat-form-field class="w-100" *ngIf="brand.projects.length">
                                          <mat-select (selectionChange)="addedExistingProject($event.value, formIndex,i, brand )"  placeholder="Project" formControlName="project_id">
                                            <mat-option *ngFor="let project of brand.projects" [value]="project.id">
                                              {{project.name}}
                                            </mat-option>
                                          </mat-select>
                                        </mat-form-field>
                                      </div>
                                    </div>
                                  </div>
                                  
                                  <div class="col-sm-8"  *ngIf="formPhase(formIndex,i).controls.showOptions.value || !formPhase(formIndex,i).controls.project_id.value">
                                    <mat-form-field class="w-100">
                                      <input matInput name="title"   placeholder="Phase / Project Title ( optional )" formControlName="title" >
                                    </mat-form-field>
                                  </div>
                                </div>
                              </div>
                              <div class="form-row mt-2" >
                                <div class="col-sm-6" >
                                  <div *ngIf="settings.types; let types">
                                    <mat-form-field class="w-100">
                                      <mat-select placeholder="Project Type" formControlName="type" >
                                        <mat-option *ngFor="let type of types" [value]="type.value">
                                          {{type.title}}
                                        </mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                  </div>
                                </div>
                                <div class="col-sm-1"></div>
                                <div class="col-sm-5">
                                  <mat-form-field class="w-100">
                                    <span matPrefix>$ &nbsp;</span>
                                    <input matInput placeholder="Total" (input)="getTotalPhases(formIndex, this)" autocomplete="off" formControlName="total" mask="separator.2"
                                      thousandSeparator="," >
                                  </mat-form-field>
                                </div>
                              </div>
                              <div class="form-row">
                                <div class="col-sm-12" >                
                                  <div class="form-group">
                                    <div class="d-flex justify-content-between align-items-bottom">                                                                                                          
                                      <label class="label mb-0">Overview</label> 
                                      <button  mat-button mat-icon-button matTooltip="Robot Writter" (click)="prompt(formPhase(formIndex,i),'content')"><mat-icon color="primary">smart_toy</mat-icon></button>
                                    </div>
                                    
                                    <ckeditor [editor]="Editor" formControlName="content" ></ckeditor>
                                  </div>
                                </div>
                                <div class="col-sm-12">
                                  <div class="form-group">
                                    <div class="d-flex justify-content-between align-items-bottom">                                                                                                                                                
                                      <label class="label mb-0">Deliverables</label> 
                                      <button  mat-button mat-icon-button matTooltip="Robot Writter" (click)="prompt(formPhase(formIndex,i),'deliverables')"><mat-icon color="primary">smart_toy</mat-icon></button>
                                    </div>                                    
                                    <ckeditor [editor]="Editor" formControlName="deliverables" ></ckeditor>
                                  </div>                
                                </div>
                              </div>
                              <!-- SAVED PHASE -->
                              <div *ngIf="formPhase(formIndex,i).controls.id.value; let phaseid">
                                <button color="danger" mat-raised-button (click)="deletePhase(phaseid,formIndex,i)">Delete Phase</button>
                              </div>
                              <div *ngIf="formPhases(formIndex).length > 1 && !formPhase(formIndex,i).controls.id.value">
                                <button color="danger" mat-raised-button (click)="removePhase(formIndex,i)">Remove Phase</button>
                              </div>                      
                            </mat-expansion-panel>                      

                          </div>
                          <span *ngIf="getTotalPhases | Fn : formIndex : this"></span>
                        </div> 
                      </mat-accordion>

                      

                      <div class="my-3" >
                        <button  mat-stroked-button color="primary" (click)="addPhase(formIndex)">
                          <mat-icon>add</mat-icon>Project / Phase
                        </button>               
                      </div>

                      <div class="py-3 mb-2">
                        <div class="hr"></div>
                      </div>

                      <div class="row m-0" *ngIf="!thisform(formIndex).controls.id.value">
                        <div class="col-sm-4 d-flex align-items-center" *ngIf="data?.options?.view !== 'project'">
                          <div class="w-100 mat-form-field" *ngIf="!thisform(formIndex).controls.project_id.value">
                            <div>
                              <mat-checkbox value="create" color="primary" formControlName="_createProject">
                                <span>Create Project(s) from this proposal.</span>
                              </mat-checkbox>
                            </div>
                            <label class="label" for="">This will generate a project for each phase and attach them individually</label>
                          </div>
                        </div>
                      </div>
                      
                      

                      <div class="row m-0">
                        <label class="col-sm-2 label" matTooltip="This is the total that the client will see">Total:</label>
                        <strong><span class="col-sm-2 total"> {{ thisform(formIndex).controls.total.value | currency:'USD':true:'1.2-2' }}</span></strong>
                        <span *ngIf="previewTotal && previewTotal > 0" class="ml-1"> of ({{ previewTotal | currency:'USD':true:'1.2-2' }})</span>
                      </div>

                    </div>
                  </div>
              
                  <!-- <div class="row">
                    <div class="col-sm-10">
                      <div class="form-group">
                        <label class="label">Content</label>
                        <ckeditor [editor]="Editor" formControlName="content"></ckeditor>
                      </div>
                    </div>
                  </div> -->
              
                  <!-- <div class="row">
                    <div class="col-sm-10">
                      <div class="form-group">
                        <label class="label">Deliverables</label>
                        <ckeditor [editor]="Editor" formControlName="deliverables"></ckeditor>
                      </div>
                    </div>
                  </div> -->
                </div>
              <mat-dialog-actions class="mb-0" *ngIf="editMultiple || formIndex == 0">
                <button mat-button (click)="close()">Cancel</button>
                <button color="primary" *ngIf="!thisform(formIndex).disabled" [disabled]="thisform(formIndex).invalid" mat-raised-button (click)="save(formIndex)">Save</button>
              </mat-dialog-actions>
            </div>
          </div>  
        </form>
      </ng-template>

    </div>
</div>