<div class="container" *ngIf="( $users | async ); let users">
  
  <div class="block">
    <div class="block-title">
      <div class="block-title-label">Manage Users</div>
        <div class="block-title-toolbar view-tools">
        <button mat-button (click)="add()" color="primary">
          <mat-icon>add</mat-icon>New User
        </button>
        <!-- <button [ngClass]="{ 'active': view == 'table'}" mat-icon-button (click)="changeView('table')">
          <mat-icon class="">view_list</mat-icon>
        </button>
        <button [ngClass]="{ 'active': view == 'tile'}" mat-icon-button (click)="changeView('tile')">
          <mat-icon class="">view_module</mat-icon>
        </button>         -->
      </div> 
    </div>  
  </div>

  <div class="block" *ngIf="($userProfile | async); let profile">
    <div class="block-title" *ngIf="vs.subject('users'); let subject">
      <div class="block-title-label"><mat-icon *ngIf="subject.icon" class="mr-2">{{ subject.icon }}</mat-icon>{{ subject.label }}</div>      
      <div class="block-title-toolbar">
        <button mat-button color="primary" (click)="add()"><mat-icon>add</mat-icon>New {{subject.label}}</button>
        &nbsp;
        <button mat-button [matMenuTriggerFor]="menu" color="primary"><mat-icon>sort</mat-icon>Actions</button>
        <mat-menu #menu="matMenu" class="menu-sm">
          <!-- <button mat-menu-item (click)="trash()" >Delete</button> -->
          <!-- <button mat-menu-item (click)="paid()" >Mark Paid</button>   -->
          <!-- <button mat-menu-item (click)="print()">Print</button>           -->
        </mat-menu>
      </div>
    </div>
    <div class="block-content">
      <!--          
                     -->
      <div *ngIf="users && users.data">

    <!-- <ng-template #tileRef let-user="item">
      <div class="block">
        <div class="block-content">
          <div class="row m-0 align-items-center form-group">
            <div class="mr-3">
              <user-avatar [class]="'mdm'" [user]="user"></user-avatar>
            </div>
            <div class="flex-fill">
              <h3 class="card-title">{{ user.name }} {{ user.last_name }}</h3>
              <div class="subhead">{{ user.email }}</div>
            </div>
            <div class="flex-shrink ">
              <button mat-button (click)="add(user)" color="primary">Edit</button>
            </div>
          </div>
        </div>
        <div class="block-hr"></div>
        <div class="block-content">
          <div class="">
            <div class="row">
              <div class="col-auto d-flex align-items-center">
                <span class="subhead">Brands: &nbsp;</span>
                <app-brand-logos [brands]="user.brands"></app-brand-logos>
              </div>
              <div class="col-auto d-flex align-items-center">
                <span class="subhead">Projects: &nbsp;</span>
                <span class="dot-label active">{{ user.projects.length }}</span>
              </div>
              <div class="col-auto d-flex align-items-center">
                <span class="subhead">Role: &nbsp;</span>
                <span class="label-sm" *ngFor="let role of user.roles">
                  {{ role.name }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #customActionsTemplate>
      <button mat-menu-item (click)="trash()">Delete</button>
    </ng-template> -->

    <!-- <app-views
      matSort  
      [options]="viewOptions"
      [collection]="$users"
      [viewSelection]="view"
      [tileRef]="tileRef"
      [tableRef]="tableRef"
      [actionsTemplate]="customActionsTemplate"> -->


      <app-table
        mat-table 
        #vaultTable
        matSort #sort="matSort" 
        [asyncFilters]="asyncFilters"
        [dataSource]="$users"   
        [pager]="userPager"        
        [columns]="displayedColumns"
        >
 
      
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> User </th>
          <td mat-cell class="cap cursor" *matCellDef="let element" (click)="add(element)">
            <div class="row  m-0 align-items-center">
              <div class="mr-3">
                <user-avatar [class]="'mdm'" [user]="element"></user-avatar>
              </div>
              <div class="flex-fill">
                <div>{{element.name}} {{element.last_name}} <span *ngIf="element.last_login" style="font-size: 10px;color: #9b9494;">Last Login: {{ element.last_login | date }}</span></div>
                <div class="subhead">{{element.email}}</div>
              </div>
            </div>
          </td>
        </ng-container>
                
        <ng-container matColumnDef="Brands">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Brands </th>
          <td mat-cell class="cap" *matCellDef="let element">
            <app-brand-logos [brands]="element.brands"></app-brand-logos>
          </td>
        </ng-container>
        
        <ng-container matColumnDef="Projects">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Projects </th>
          <td mat-cell class="cap" *matCellDef="let element">
            <span class="dot-label active">{{ element.projects.length }}</span>
          </td>
        </ng-container>
        
        <ng-container matColumnDef="Role">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Role </th>
          <td mat-cell class="cap" *matCellDef="let element">
            <span class="label-sm" *ngFor="let role of element.roles">
              {{ role.name }}
            </span>
          </td>
        </ng-container>
        
        <ng-container matColumnDef="actions">
          <th class="text-right" mat-header-cell *matHeaderCellDef> Actions </th>
          <td class="text-right" mat-cell *matCellDef="let element">
            <button (click)="add(element)" class="mat-sm" mat-icon-button mat-button>
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></tr>
      
    </app-table>
    
  </div>
</div>

<ng-template #nousers>
  <mat-spinner color="primary" class="mx-auto loader"></mat-spinner> No Users
</ng-template>

<ng-template #noUsers>
  <mat-spinner color="primary" class="mx-auto loader"></mat-spinner>
</ng-template>