import { Component, OnInit, HostBinding, ViewChild, AfterContentInit } from '@angular/core';
import { VelvetysoftService } from "../../_services/velvetysoft.service";
import { ProjectService } from '../../_services/project.service';
import { InvoiceService } from "../../_services/invoice.service";
import { Observable } from 'rxjs';
import { BrandModel, InvoiceModel } from 'src/app/_models/subjects';
import moment from 'moment';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: "app-dashboard-snapshot",
  templateUrl: "./dashboard-snapshot.component.html",
  styleUrls: ["./dashboard-snapshot.component.scss"],
})
export class DashboardSnapshotComponent implements AfterContentInit {
  @HostBinding("class") get class() {
    return this.vs.dashboardSnapshot ? "open" : "";
  }
  //$activeBrand = new Subject();

  $brandList: BrandModel[];
  allProjects;

  brandSelected: BrandModel | null;
  $invoices: any; //[<InvoiceModel>];
  $invoicesStats: any;
  invoiceStats: [any]
  invoices: any; //[<InvoiceModel>];
  projectsList: [any];
  $projects: any;
  year: any;
  options = { legend: { position: "right", labels: { fontColor: "#fff" } } };
  optionsRev = { legend: { display: false, position: "right", labels: { fontColor: "#fff" } }, 
    scales:{
      xAxes: [{
        gridLines: {
          drawOnChartArea: false
        },
        ticks: {
          fontColor: 'rgba(255,255,255,0.7)',
        }
      }],
      yAxes: [{
        gridLines: {
          drawOnChartArea: false
        },
        ticks: {
          fontColor: 'rgba(255,255,255,0.7)',
          beginAtZero: true,
          callback: function (value, index, values) {
            return '$ ' + value;
          }
        }
      }]
    } 
  };  
  constructor(
    private vs: VelvetysoftService,
    private projectService: ProjectService,
    private invoiceService: InvoiceService
  ) {}

  ngOnInit() {
    this.$projects = this.projectService.projectTree.subscribe((brands) => {
      this.$brandList = brands;
      this.changeBrand();
    });
    this.$invoices = this.invoiceService.subjectsAll.subscribe((invoices) => {
      this.invoices = invoices;
    });
    // this.$invoicesStats = this.invoiceService.stats.subscribe((stats) => {
    //   this.invoiceStats = stats;
    //   console.log(stats)
    // });
    this.year = moment().format("YYYY");
  }

  ngAfterContentInit() {
    console.log("running");
  }

  ngOnDestroy() {
    this.$projects.unsubscribe();
    this.$invoices.unsubscribe();
    //this.$invoicesStats.unsubscribe();
  }

  changeBrand() {
    this.allProjects = [];
    this.allProjects = this.projects(this.$brandList);
  }

  findBrand(id, pid = null) {
    return this.projectService.selectTree(this.$brandList, id, pid);
  }

  toggleDashSnap() {
    this.vs.dashboardSnapshot = !this.vs.dashboardSnapshot;
  }

  // INVOICES

  invoiceTotal(){
    //let groupedResults = _.groupBy(this.selectedInvoices(), (result) => moment(result['created_at']).startOf('month'));
    // var data = [];
    // var year = moment().format('Y');
    // Object.keys(groupedResults).forEach( month =>{
    //   let groupTotal = groupedResults[month].reduce((total,item)=>{
    //     if (item.status == "sent" ) return total;
    //     if (moment(item.created_at).format('Y') == year ){
    //       console.log(item)
    //       total += parseFloat(item.total);
    //       return total;
    //     }
    //   },0)
    //   data.push({ month: moment(month).format("MMM"), total: groupTotal })
    // })
    // return data;    
  }

  invoiceCount() {
    return this.selectedInvoices().length;
  }

  invoiceRevenue() {
    return this.selectedInvoices()
      .filter((invoices) => invoices.status == "paid")
      .reduce((total, item) => {
        return total + parseFloat(item.total);
      }, 0);
  }

  selectedInvoices() {
    return this.brandSelected
      ? this.invoices.filter(
          (invoice) => invoice.brand_id == this.brandSelected
        )
      : this.invoices;
  }
  // INVOICES END

  projectsByStatus(status) {
    return this.allProjects.filter((p) => p.status == status);
  }

  projects(brands) {
    return this.brandSelected
      ? brands.find((b) => b.id == this.brandSelected).projects
      : brands.reduce(this.reduceToProjects, []).flat();
  }

  private reduceToProjects = (ag, brands) => {
    ag.push(brands.projects);
    return ag;
  };

  
}
