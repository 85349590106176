<div class="row">
  <div class="col-sm-12" style="font-size: 12px">    
    <mat-form-field class="" *ngIf="$brands | async; let brands">
      <mat-select placeholder="Filter by Brand" [(ngModel)]="selectedBrand">
        <mat-option >- None -</mat-option>
        <mat-option *ngFor="let brand of brands" [value]="brand.id">
          <app-brand-logo [brand]="brand" format="small"></app-brand-logo> {{brand.name}}
        </mat-option>
      </mat-select>
    </mat-form-field> &nbsp;
    <mat-form-field class="" *ngIf="types">
      <mat-select placeholder="Filter by Type" [(ngModel)]="selectedType">
        <mat-option>- None -</mat-option>
        <mat-option *ngFor="let type of types" [value]="type.value">
          {{type.title}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div class="items-container h-100">
  <div class="items-wrap" mat-dialog-content *ngIf="collection | async; let items">
    <div *ngFor="let item of items | filterSingle: 'brand_id' : selectedBrand | filterSingle: 'type' : selectedType" class="d-flex item click justify-content-between" (click)="load(item)">
      <span class="item-title">{{ item.title }}</span> <span><span class="badge badge-light">{{ item.type | capitalize }}</span></span>
    </div>
  </div>
</div>