import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { empty, Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { AssetService } from 'src/app/_services/asset.service';
import { ConfirmComponent } from '../../_component/dialogs/confirm/confirm.component';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {
  @Input() tags;
  @Input() showlabel:boolean = true;
  @Input() subject: any;
  constructor(
    private assetService: AssetService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  add(tag,id){    
    this.assetService.addTag({name: tag,id: id});
  }

  createTag(_subject){
    const dialogRef = this.dialog.open(ConfirmComponent, {
      data: {
        id: _subject.id,
        type: "add-tag",
        title: `Add Tag`,
        content: "",
      },
    });
    dialogRef.afterClosed()
    .pipe(switchMap( result =>{
      return (result && result.status === true)  
        ? this.assetService.addTag({name: result.data.tag, id: _subject.id})
        : empty();      
    })).pipe(first())
    .subscribe((result) => {                    
       if(result) this.tags.push({vhtag: result }) 
    })
  }

}
