import { Injectable, NgZone } from "@angular/core";
import { ReplaySubject, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from "../../environments/environment";
import { AssetModel, BrandModel, GoogleAsset } from '../_models/subjects';
import { AssetService } from './asset.service';
import { BrandService } from './brand.service';

function _window(): any {  
  return window;
}

export interface AssetContext {
    subject:string
    model: object
    ref?: any
    assetService?: AssetService
  }

@Injectable({
  providedIn: "root",
})
export class GoogleApiService {
  public $activeted: ReplaySubject<any> = new ReplaySubject(1);
  public gapi: any;
  public loggedIn = false;
  private _self = this;
  private pickerApiLoaded = false;
  public guser: any;
  token: any;

  constructor(
    private _ngZone: NgZone,
    private brandService: BrandService
  ) {

    this.gapi = this.nativeWindow.gapi;
    this.gapi.load("client:auth2", () => {
      this.initglib();
    });
    
    // this.gapi.load("client:auth2", () => {
    //   this.gapi.auth2
    //     .init({ client_id: environment.gApiClientId })
    //     .then((x) => {
    //       this.token = this.gapi.auth.getToken();
    //       this.$activeted.next(this.gapi.auth.getToken());
    //       if (this.isLoggedIn) this.gloadClient();
    //     });
    // });
  }

  initglib() {
    this.gapi.client
      .init({
        apiKey: environment.gApiKey,
        clientId: environment.gApiClientId,
        discoveryDocs: [
          "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
        ],
        scope: "https://www.googleapis.com/auth/drive.metadata.readonly",
      })
      .then(
        () => {
          // Listen for sign-in state changes.
          this.gapi.auth2
            .getAuthInstance()
            .isSignedIn.listen(this.updateSigninStatus.bind(this));

          // Handle the initial sign-in state.
          this.updateSigninStatus(
            this.gapi.auth2.getAuthInstance().isSignedIn.get()
          );
        },
        (error) => {
          console.log(JSON.stringify(error, null, 2));
        }
      );
  }

  updateSigninStatus(isSignedIn) {
    if (isSignedIn) {
      this._self.token = this._self.gapi.auth.getToken();
      let _user = this._self.gapi.auth2
        .getAuthInstance()
        .currentUser.get()
        .getBasicProfile();

      let user = {
        email: _user.getEmail(),
        name: _user.getName(),
        id: _user.getId()
      }
        
      this._self.guser = user;
      this._ngZone.run(() => {
        this._self.$activeted.next(true);
      });      
    } else {
      this._ngZone.run(() => {
        this._self.$activeted.next(false);
      });
    }
  }

  get nativeWindow(): any {
    return _window();
  }

  get isLoggedIn(): any {
    return this.gapi.auth2.getAuthInstance().isSignedIn.get();
  }

  thumb(asset: AssetModel){
    this.gapi.client.drive.files.get({
      //auth: jwtClient,
      fileId: asset.g_id,
      fields : "thumbnailLink"
    }).then((res)=>{
      console.log(res)
    });
    return false;
    // if(this.token.access_token){      
    //   let url = `https://www.googleapis.com/drive/v2/files/${asset.g_id}?alt=media&access_token=${this.token.access_token}`
    //   console.log(url)
    //   return url;
    // }else{
    //   console.log('no token')
    //   return false;
    // }
    
  }

  signIn(e) {
    e.stopPropagation();
    this.gapi.auth2.getAuthInstance().signIn();
    return false;
  }

  /**
   *  Sign out the user upon button click.
   */
  signOut(e) {
    e.stopPropagation();
    this.gapi.auth2.getAuthInstance().signOut();
    return false;
  }

  listDrives(){
    const drives = new Subject();
    this.$activeted
      .pipe(first())
      .subscribe(()=>{
        this.gapi.client.drive.drives
          .list({
            pageSize: 100
          })
          .then((res) => {
            drives.next(res.result.drives);
          });
      });
  
    return drives;
  }

  getContacts(){
    
    //use GoogleApiService to get contacts
    const contacts = new Subject();
    this.$activeted
      .pipe(first())
      .subscribe(()=>{
        this.gapi.client.people.people.connections.list({
          'resourceName': 'people/me',
          'pageSize': 10,
          'personFields': 'names,emailAddresses',
          })
          .then((res) => {
            console.log(res)
            contacts.next(res.result.contacts);
          });
      });
  }

  //PICKER

  openPicker(e, context: AssetContext): Subject<any> {
    e.stopPropagation();

    //PASS ALONG META DATA FOR ASSET RELATION AND A RESULT SUBJECT
    let handler = context;
    handler.ref = new Subject();

    this.gapi.load("picker", () => {
      this.onPickerApiLoad(handler);
    });
    return handler.ref;
  }
  onPickerApiLoad(handler) {
    this.pickerApiLoaded = true;
    this.createPicker(handler);
  }

  createPicker(handler) {
    if (this.pickerApiLoaded && this.token.access_token) {
      let activeBrandFolder = null;
      this.brandService.activeBrand
        .pipe(first())
        .subscribe((brand) => {
          if (brand.gfolder) activeBrandFolder = brand.gfolder;
      });

      // Let users to select files from any Team Drive
      var multiTeamDrive = new this.nativeWindow.google.picker.DocsView();
      multiTeamDrive
        .setIncludeFolders(true)
        .setEnableTeamDrives(true);
      
      if(activeBrandFolder) multiTeamDrive.setParent(activeBrandFolder);

      var personalView = this.nativeWindow.google.picker.ViewId.DOCS;
      var picker = new this.nativeWindow.google.picker.PickerBuilder()
        .addView(multiTeamDrive)
        .addView(personalView)
        .setOAuthToken(this.token.access_token)
        .setDeveloperKey(environment.gApiKey)
        .setCallback((arg) => {
          this.pickerCallback(arg, handler);
        })
        .build();
      picker.setVisible(true);
    }
  }

  pickerCallback(data, handler) {
    // Get this working, and new users add user to list and add to selection
    //ad check user email
    //https://developers.google.com/picker/docs
    // if (
    //   data[this.nativeWindow.google.picker.Response.ACTION] ==
    //   this.nativeWindow.google.picker.Action.PICKED
    // ) {
    //   var doc = data[this.nativeWindow.google.picker.Response.DOCUMENTS][0];
    //   url = doc[this.nativeWindow.google.picker.Document.URL];
    // }
    if (data && data.docs) {
      console.log(data.docs);
      let gAssets = data.docs.map((doc) => {
        return {
          title: doc.name,
          type: "gasset",
          icon: doc.iconUrl,
          id: doc.id,
          size: doc.sizeBytes,
          url: doc.url,
        };
      });

      handler.assetService
        .upload(null, handler.subject, handler.model, gAssets)
        .subscribe((results) => {
          handler.ref.next(results);
        });
    }

    //document.getElementById("result").innerHTML = message;
  }

  //INITILIZE GOOGLE API

  // gAuth() {
  //   if (this.gIsAuth()) {
  //     let auth = this.gapi.auth.getToken();
  //     return auth;
  //   } else {
  //     return "Not Connected";
  //   }
  // }

  // gIsAuth() {
  //   let auth = this.gapi.auth.getToken();
  //   console.log(auth);
  //   return auth ? true : false;
  // }

  // initClient() {
  //   return new Promise((resolve,reject)=>{
  //       this.gapi.load('client:auth2', () => {
  //           return this.gapi.client.init({
  //               apiKey: API_KEY,
  //               clientId: CLIENT_ID,
  //               discoveryDocs: DISCOVERY_DOCS,
  //               scope: SCOPES,
  //           }).then(() => {
  //               this.googleAuth = gapi.auth2.getAuthInstance();
  //               resolve();
  //           });
  //       });
  //   });

  // //GOOGLE API
  // authenticate(e) {
  //   e.stopPropagation();
  //   this.gapi.auth2
  //     .getAuthInstance()
  //     .signIn({
  //       scope:
  //         "https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/drive.appdata https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.metadata https://www.googleapis.com/auth/drive.metadata.readonly https://www.googleapis.com/auth/drive.photos.readonly https://www.googleapis.com/auth/drive.readonly",
  //     })
  //     .then(
  //       function () {
  //         console.log("Sign-in successful");
  //       },
  //       function (err) {
  //         console.error("Error signing in", err);
  //       }
  //     );
  //   return false;
  // }
  // gloadClient() {
  //   this.gapi.client.setApiKey("AIzaSyDrE29Sg8x9u65Qzws4MmrJ-agZfUEqqVM");
  //   return this.gapi.client
  //     .load("https://content.googleapis.com/discovery/v1/apis/drive/v3/rest")
  //     .then(
  //       function () {
  //         console.log("GAPI client loaded for API");
  //       },
  //       function (err) {
  //         console.error("Error loading GAPI client for API", err);
  //       }
  //     );
  // }
  // // // Make sure the client is loaded and sign-in is complete before calling this method.
  // gexecute() {
  //   return this.gapi.client.drive.files.list({}).then(
  //     function (response) {
  //       // Handle the results here (response.result has the parsed body).
  //       console.log("Response", response);
  //     },
  //     function (err) {
  //       console.error("Execute error", err);
  //     }
  //   );
  // }
}
