<div class="vault-modal">
  <div>

    <div class="row">
      <div class="col-sm-12">
        <!-- <h1 mat-dialog-title>          
          {{ data.title }}
        </h1> -->
      </div>
      <div class="col-sm-12">
        <div mat-dialog-content>

            <app-add-asset
              [callback]="data.assetsAdded"
              [subject]="data.subject"
              [model]="data.model"
              [dialogref]="dialogRef"
            ></app-add-asset>                        

            <span *ngIf="(data.gapi.$activeted | async); else connected">
              <div class="text-center">
                <button mat-flat-button color="primary" (click)="data.uploadGAsset($event,dialogRef)">Attach Files via Google Drive </button>
              </div>
            </span>
            
            <ng-template #connected >
              <div class="text-center">
                <button mat-button (click)="data.gapi.signIn($event)">Connect GDrive</button>
              </div>
            </ng-template>
        </div>
      </div>
    </div>

    <mat-dialog-actions>
      <button mat-button (click)="close()">Close</button>      
    </mat-dialog-actions>

    
  </div>
</div>