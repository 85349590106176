import { Component, OnInit, AfterContentInit, ViewChild } from '@angular/core';
import { InvoiceModel, Settings, SettingsSubject, UserProfileModel } from "../../_models/subjects";
import { InvoiceService } from '../../_services/invoice.service';
import { BrandService } from '../../_services/brand.service';
import { VelvetysoftService } from '../../_services/velvetysoft.service';
import { ActivatedRoute } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Observable } from 'rxjs';
import { ConfirmComponent } from '../dialogs/confirm/confirm.component';
import { SendEmailComponent } from '../dialogs/send-email/send-email.component';
import * as moment from "moment";
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AlertService } from '../../_services/alert.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { UserService } from 'src/app/_services/user.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';



@Component({
  selector: "app-invoices",
  templateUrl: "./invoices.component.html",
  styleUrls: ["./invoices.component.scss"],
})
export class InvoicesComponent implements AfterContentInit {
  $invoices: Observable<InvoiceModel[]>;
  $userProfile: Observable<UserProfileModel>;
  displayedColumns: string[] = [
    "select",
    "user",    
    "title",
    "invoice_number",
    "type",
    "total",
    "due",
    "status",
    "actions",
  ];  
  types: any;
  sortedData: InvoiceModel[];
  filteredSubjects: string[] = ["draft", "sent", "paid"];
  subject: string = 'invoice';
  view: string = "table";
  subjectObj: SettingsSubject;
  search: string = '';
  prefix = '';
  @ViewChild("vaultTable") vaultTable;
  constructor(
    private invoiceService: InvoiceService,
    private brandService: BrandService,
    public vs: VelvetysoftService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private userService: UserService,
    private snack: MatSnackBar,
    private alertService: AlertService,
    private location: Location
  ) {}

  selection = new SelectionModel<InvoiceModel>(true, []);

  ngAfterContentInit() {
    this.$invoices = this.invoiceService.invoices.pipe(
      tap((invoices) => 
        invoices.map( i => this.isOverDue(i))
      )
    );
    this.$userProfile = this.userService.userProfile();
    this.types = this.vs.settings.invoice;
    this.subjectObj = this.vs.findSubject(this.subject);   
    this.brandService.$activeBrand.subscribe(brand => this.prefix = brand.prefix);

    //MOVE THIS TO A SERVICE
    const state:any = this.location.getState();

    if(state['action']){
      if(state.action == 'addinvoice') this.edit();
      if(state.action == 'editinvoice') this.edit(state.invoice);
      if(state.action == 'wizard') this.edit(state.invoice, {view: 'wizard'});
    }    
  }

  edit(proposal?: InvoiceModel,options?) {
    this.invoiceService.edit(proposal, options);
  }

  changeView(view) {
    this.view = view;
  }

  ngOnDestroy() {    
  }

  trash(id?) {
    let ids = id ? [id] : this.vaultTable.selection.selected.map((p) => p.id);
    this.invoiceService.deleteDialog(ids);
  }

  isOverDue(_invoice) {
    let overDue = this.invoiceService.isOverDue(
      _invoice.due_date,
      _invoice.status      
    );
    if (overDue && !_invoice.penalty) this.alert("penalty", _invoice);
    //return overDue;
  }

  multiBrand(invoice){
    return this.invoiceService.isMultiBrand(invoice);
  }

  alert(type, _invoice: InvoiceModel) {
    this.invoiceService.alert(type, _invoice);    
  }

  send(invoice: InvoiceModel){
    this.invoiceService.sendEmail(invoice);
  }

  navToProject(id) {
    this.router.navigate([`/dashboard/project/${id}`]);
  }

  log(invoice) {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      data: {
        id: invoice.id,
        type: "view-invoice-log",
        title: `Invoice : ${invoice.title}`,
        content: "",
        invoice: invoice,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      // if (result && result.status === 'delete') {
      //   this.invoiceService.deleteInvoice(invoice.id).subscribe(() => {
      //     this.invoiceService.loadInvoicesBrand(this._bid, true);
      //   })
      // }
    });
  }

  viewpdf(invoice){
    this.invoiceService.download(invoice,true) 
  }

  download(invoice: InvoiceModel) {
    this.invoiceService.download(invoice)    
  }

  // send(invoice: InvoiceModel) {
  //   this.invoiceService.sendDialog(invoice);
  // }

  //add(invoice?: InvoiceModel) {
    // const ref = this.invoiceService.editDialog(invoice);
    // ref.subscribe((x) => console.log(x));
  //}
}
