<div class="vault-modal">
    <form [formGroup]="form">
        <h1 mat-dialog-title>{{ data.title }}</h1>
        <app-alert></app-alert>
        <div class="block-title-label mb-3 pl-4 ">Create invoice for: &nbsp; 
            <button mat-stroked-button (click)="prevMonth()">
                <mat-icon>chevron_left</mat-icon>
            </button> &nbsp;
            {{ month | date: "MMMM" }} &nbsp;
            <button mat-stroked-button (click)="nextMonth()">
                <mat-icon>chevron_right</mat-icon>
            </button>
        </div>        

        <div mat-dialog-content class="form-group mt-3">

            <div style="min-height: 400px;">
                <ng-container *ngIf="!loading; else loader" style="min-height: 400px;">
                <div *ngIf="$timesheetInvoice; let tsi;" >
                    
                    <ng-container *ngIf="tsi.hasInvoice;else preview">
                        <div class="block-title-label">Invoices</div>
                        <div class="row table-text align-items-center m-0">
                            <div class="col-sm-2">{{ tsi.tsi.start | date: "MMM" }} Invoice</div>
                            <div class="col-sm-2">{{ tsi.tsi.created_at | date }}</div>
                            <div class="col-sm-4"> <meta-display subject="expense" [item]="tsi.tsi" type="statuses" [id]="tsi.tsi.status"></meta-display></div>
                            <div class="col-sm-2">{{ tsi.tsi.amount | currency }}</div>                            
                            <div class="col-sm-1">
                                <button mat-icon-button color="primary" (click)="viewInvoice(tsi.tsi)">
                                    <mat-icon>visibility</mat-icon> View
                                </button>
                            </div>
                        </div>
                    </ng-container>

                    <ng-template #preview >
                        
                        <div class="alert alert-danger mb-4">Only Approved timesheets can be invoiced</div>
                        <div *ngIf="$timesheetInvoice; let tsi;">

                            <div [class.limbo]="!expensesValid">
                                <div class="mb-2 d-flex align-items-center">
                                    <user-avatar [user]="tsi.user"></user-avatar> &nbsp; {{tsi.user.name}} {{tsi.user.last_name}}
                                </div>                                        
                                <div class="mb-3 ">The Invoice period for {{ month | date: "MMMM" }} covers <strong>{{ tsi.range.start | date: "MMM dd, yyyy":"UTC"  }}</strong> - <strong>{{ tsi.range.stop | date: "MMM dd, yyyy":"UTC" }}</strong></div>
                                
                                <!-- <div *ngFor="let sheet of tsi.timesheets " class="row">
                                    <div>{{ sheet.time_worked }}</div>
                                </div> -->

                                <div class="block-title-label">Timesheets</div>
                                <div *ngFor="let week of tsi.weeks" class="row table-text align-items-center m-0">
                                    <div class="col-sm-5 cell">Week of {{ week.startOfWeek | date: "MMM dd":"UTC" }} 
                                        <div *ngIf="week.conditional_rate" class="label uc">* {{week.conditional_rate}} </div>
                                        <div *ngIf="week.min" class="label uc">* {{ week.min }}</div>
                                    </div>
                                    <div class="col-sm-2 cell">{{ week.hours | number : '1.2-2' }} Hrs</div>
                                    <div class="col-sm-2 cell">{{ week.rate | currency }} </div>
                                    <div class="col-sm-3 cell">{{ week.amount | currency }}</div>
                                </div>

                                <div class="mb-3">
                                    <div class="row align-items-center m-0">
                                        <div class="col-sm-9 cell">Sub Total: </div>                    
                                        <div class="col-sm-3 cell">
                                            <div class="row table-text align-items-center m-0">                    
                                                <div class="col-sm-6 cell" class="strong">
                                                    {{ tsi.timesheets_sub_total | currency:'USD':true:'1.2-2'}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                </div>
                            </div>

                            <div class="mb-3">
                                <div class="block-title-label">Expenses</div>                                
                                <app-line-expense 
                                    [start]="month" 
                                    [imp]="data.options?.preview?.imp"
                                    (isExpenseValid)="isExpenseValid($event)"
                                    [expenses]="tsi.expenses">
                                </app-line-expense>    

                                <div class="mt-3">
                                    <div class="row align-items-center m-0">
                                        <div class="col-sm-9 cell">Sub Total: </div>                    
                                        <div class="col-sm-3 cell">
                                            <div class="row table-text align-items-center m-0">                    
                                                <div class="col-sm-6 cell" class="strong">
                                                    {{ tsi.expenses_sub_total | currency:'USD':true:'1.2-2'}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                </div>
                            </div>


                            <div class="my-3" [class.limbo]="!expensesValid">

                                <div class="row align-items-center m-0 mb-3">
                                    <div class="col-sm-9 cell align-items-center">
                                        <mat-checkbox (change)="toggleTax()" [(ngModel)]="taxable"  [ngModelOptions]="{standalone: true}" color="primary">Include Sales Tax</mat-checkbox>
                                        <div class="table-text" *ngIf="tsi.tax">{{ tsi.tax.state }} {{ tsi.tax.rate }}%</div>
                                    </div>                    
                                    <div class="col-sm-3 cell">
                                        <div *ngIf="tsi.tax" class="row table-text align-items-center m-0">                    
                                            <div class="col-sm-6 cell" class="strong">
                                                {{ tsi.tax.tax | currency:'USD':true:'1.2-2'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row align-items-center">                                   
                                    <div class="col-sm-4 "><div class="block-title-label">Total:</div></div>                    
                                    <div class="col-sm-8">
                                        <div class="row table-text align-items-center m-0">                    
                                            <div class="col-sm-4 cell" class="strong">{{ tsi.amount | currency:'USD':true:'1.2-2'}}  </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </ng-template>

                    
                </div>
                </ng-container>
                <ng-template #loader>                
                    <mat-spinner color="primary" class="mx-auto loader"></mat-spinner>                
                </ng-template>
            </div>
        </div>
        
        <mat-dialog-actions>
            <button mat-button (click)="close()">Close</button>            
            <button mat-button [disabled]="submitted || !expensesValid" (click)="preview()">Preview</button>
            <button mat-button [disabled]="submitted || !expensesValid" (click)="submit()">Submit</button>
        </mat-dialog-actions>
    </form>
</div>


