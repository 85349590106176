import { Component, OnInit } from '@angular/core';
import { ProjectModel, BrandModel, UserProfileModel } from "../../_models/subjects";
import { Observable } from 'rxjs';
import { ViewEncapsulation } from '@angular/core';
import { VelvetysoftService } from '../../_services/velvetysoft.service';
import { ProjectService } from '../../_services/project.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BrandService } from '../../_services/brand.service';
import { tap } from 'rxjs/operators';
import { GoogleApiService } from 'src/app/_services/google-api.service';
import { UserService } from 'src/app/_services/user.service';
  

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnInit {
  projectState: string = "active";
  projectStatuses: any;
  $projects: Observable<ProjectModel[]>;
  $brands: Observable<BrandModel[]>;
  $activeBrand: Observable<BrandModel>;
  navigation: Array<any> = [];
  test: any;
  brandColor: string = null;
  brandGoogleDrive: string | null = null;
  $userProfile: Observable<UserProfileModel>;

  constructor(
    private projectService: ProjectService,
    private router: Router,
    private route: ActivatedRoute,
    private brandService: BrandService,
    public vs: VelvetysoftService,
    public gapi: GoogleApiService,
    private userService: UserService
  ) {}

  ngOnInit() {
    
    this.$userProfile = this.userService.userProfile();
    this.$projects = this.projectService.projects;
    this.projectStatuses = this.vs.settings.project.statuses;
    this.$brands = this.brandService.brands;
    this.$activeBrand = this.brandService.activeBrand.pipe(
      tap( (brand) =>{
        this.brandColor = brand.color
        this.brandGoogleDrive = brand.gfolder;
      })
    );
    this.navigation = this.vs.dashNav;
    // this.gapi.$activeted.subscribe( ()=>{
    //   console.log(this.gapi);
    // })
  }


  navTo(path) {
    this.router.navigate([`/dashboard/${path}`]);
    this.vs.mobileNav = false;
  }

  addProject(e) {
    e.stopPropagation();
    this.projectService.edit();
  }

  mobileClose() {
    this.vs.mobileNav = false;
  }

  selectBrand(id) {
    this.brandService.selectBrand(id);
  }

  openGoogle(){
    window.open(`https://drive.google.com/drive/u/2/folders/${this.brandGoogleDrive}`)    
  }
}
