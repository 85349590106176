import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { BrandService } from 'src/app/_services/brand.service';
import { environment } from '../../../environments/environment';
import { BrandModel } from "../../_models/subjects";
import { first, map,  tap } from 'rxjs/operators';


@Component({
  selector: 'app-brand-logo',
  templateUrl: './brand-logo.component.html',
  styleUrls: ['./brand-logo.component.scss']
})
export class BrandLogoComponent implements OnInit {
  cdn = environment.cdn;
  @Input() format: string = 'small';
  @Input() brand:BrandModel;
  @Input() bid:string;
  @Input() classes;
  @Input() tooltip: boolean = false;
  $brands: Observable<BrandModel[]>
  //displayName: string = null;
  constructor(
    private brandService: BrandService
  ) {}

  ngOnInit() {        
    if(this.bid ){
      //@TODO - this is a hack to get the brand logo to show up on the brand page
      if(+this.bid == -1) return this.brand = {
        id: -1, 
        name: 'Velvet Hammer Branding', 
        logo: 'company/thumb/v-1-1677684144.png', 
        color: '#522d6d', 
        users: null, 
        company_id: null, 
        addressess: null,
        active: null
      };
      this.brandService.brand(this.bid)
        .pipe(first())
        .subscribe(brand =>{
          this.brand = brand
      });
    }
  }

  getCss(){
    if (this.format == 'medium') return 'brand-logo-primary';
    if (this.format == 'large') return 'brand-logo-primary-hero';
  }

  displayName(){
    if (this.format == 'small') return this.brand.name[0];
    if (this.format == 'medium') return this.brand.name[0];
    return this.brand.name;
  }

}
