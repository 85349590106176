<div 
  class="avatars d-flex"
  [ngClass]="{'flex-column': vertical}"
  matBadge="{{ ( extra ) ? ('+'+extra) : null }}"
  matBadgeOverlap="false"
  matBadgeSize="small" 
  matBadgePosition="after"
  (click)="showUsers()">
  <user-avatar 
    *ngFor="let user of users | userIs: role; let i = index" 
    [parentCss]="parentCss"
    [class]="css"
    [showFullName]="showFullName"    
    [user]="user" 
    [showName]="showName"
    [ngClass]="{'hide': i>= max  }"
    [position]="position"
    [phone]="phone"></user-avatar>
</div>
