import { Component, Input, OnInit, Output, NgZone } from '@angular/core';
import { EventEmitter } from "@angular/core";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ReplaySubject, Subject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AssetModel, BrandModel, UploadResults } from 'src/app/_models/subjects';
import { AssetService } from 'src/app/_services/asset.service';
import { BrandService } from 'src/app/_services/brand.service';
import { GoogleApiService } from 'src/app/_services/google-api.service';
import { MediaDialogComponent } from '../dialogs/media-dialog/media-dialog.component'
import { VelvetysoftService } from 'src/app/_services/velvetysoft.service';




@Component({
  selector: "app-media-manager",
  templateUrl: "./media-manager.component.html",
  styleUrls: ["./media-manager.component.scss"],
})
export class MediaManagerComponent implements OnInit {
  @Output() assetsAdded = new EventEmitter<UploadResults>();
  @Input() plural: boolean = true; //@TODO MAKE A MODEL FOR FILE
  @Input() subject: string;
  @Input() model: object;
  @Input() mediaLibrary: boolean = false;
  @Input() color: string = "primary";
  _self = null;
  maxFileSize: string;
  dialogRef = null;

  constructor(
    private assetService: AssetService,
    public gapi: GoogleApiService,
    public dialog: MatDialog,
    private _ngZone: NgZone,
    private brandService: BrandService,
    private vs: VelvetysoftService
  ) {
    this._self = this;
    this.maxFileSize = this.vs.size(this.vs.maxFileSize);
  }

  uploadGAsset(event, dialogRef?) {
    dialogRef.close();

    const googleRef = this.gapi.openPicker(event, {
      subject: this.subject,
      model: this.model,
      assetService: this.assetService,
    });

    return googleRef.pipe(first()).subscribe((assets) => {
      let results: UploadResults = {
        assets: assets,
        model: this.model,
      };
      console.log(results);
      return this.assetsAdded.emit(results);
    });
  }

  //@TODO find a way to remove this
  upload(_event, dialogRef?) {
    const ref = this.assetService.upload(_event, this.subject, this.model);
    if (dialogRef) dialogRef.close();
    return ref.pipe(first()).subscribe((assets) => {
      let results: UploadResults = {
        assets: assets,
        model: this.model,
      };
      console.log(results);
      return this.assetsAdded.emit(results);
    });
  }

  openLibrary() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: "",
      subject: this.subject,
      title: `Add Asset`,
      model: this.model,
      assetService: this.assetService,
      //upload: this.upload,
      assetsAdded: this.assetsAdded,
      uploadGAsset: this.uploadGAsset,
      gapi: this.gapi,
    };

    this.dialogRef = this.dialog.open(MediaDialogComponent, dialogConfig);
  }

  ngOnInit() {}
}
