<!-- <div class="container" *ngIf="( $timesheets | async ); let timesheets; else noTimesheets"> -->
<div class="container-fluid" *ngIf="($userProfile | async); let profile">    
  <ng-container *ngIf="( $brandList | async ); let brands; else noTimesheets">
    <!-- <div class="row" *ngIf="canInvoice(); let invoiceable">
        <div class="col-sm-4">
            <div class="block">
                <div class="block-title">
                    <div class="block-title-label" for="">Week of: {{ currentWeek.value | date }}</div>
                </div>
                <div class="block-content">
                    Create an Invoice for {{ invoiceable.prev | date }}
                </div>
            </div>
        </div>
    </div> -->

    <div class="row" >
        <div class="col-sm-4">
            <div class="block">
                <div class="block-title">
                    <div class="block-title-label" for="">Week of: {{ currentWeek.value | date }}</div>
                </div>
                <div class="block-content">
                    <div class="text-center subhead">
                        
                    </div>
                    <div class="form-group">
                        <!-- <div class="d-flex justify-content-center form-group">
                            <mat-icon (click)="prevWeek()">arrow_left</mat-icon>
                            <mat-icon>calendar_today</mat-icon>
                            <mat-icon (click)="nextWeek()">arrow_right</mat-icon>
                        </div> -->
                        <!-- <mat-form-field> -->
                        <!-- <input matInput [matDatepicker]="picker" [formControl]="currentWeek" placeholder="Choose a date" > -->
                        <!--  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker ></mat-datepicker> -->
                
                        <!-- </mat-form-field> -->
                        <div style="max-width: 300px;margin: 0 auto;" class="form-group">
                            <mat-calendar 
                                [dateClass]="markCalendarDates()" 
                                (selectedChange)="selectWeek('change', $event)"
                                [selected]="currentWeek.value"
                                ></mat-calendar>

                            <div *ngIf="profile | userCan: 'create invoice timesheet'">                                
                                <button color="primary" mat-raised-button   (click)="invoice(profile)">Create {{ genStartOfWeek | date :"MMMM" }} Invoice </button> <span *ngIf="impersonating"> as {{ impersonating.name }}</span>
                                <div class="label mt-2 ">Select a day in a month to generate an invoice for that month. Only approved timesheets will be used.</div>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-8" *ngIf="hourstype || brandType">
            <div class="block">
                <div class="block-title">
                    <div class="block-title-label" for="">Hours Break Down</div>
                </div>
                <div class="block-content" >
                    <div class="row px-3">
                        <div class="col-sm-12 mb-3">
                            <mat-button-toggle-group class="group-sm mr-4" #overviewcom="matButtonToggleGroup" 
                                [value]="overview" 
                                (change)="onValChange(overviewcom.value, 'range')"
                                aria-label="View">
                                <mat-button-toggle value="week">Week</mat-button-toggle>
                                <mat-button-toggle value="month">Month</mat-button-toggle>
                                <mat-button-toggle value="year">Year</mat-button-toggle>
                            </mat-button-toggle-group>
                            <mat-button-toggle-group class="group-sm" #overviewcom2="matButtonToggleGroup" 
                                [value]="overviewtype" 
                                (change)="onValChange(overviewcom2.value, 'type')"
                                aria-label="View">
                                <mat-button-toggle value="type">Work Type</mat-button-toggle>
                                <mat-button-toggle value="project">Project</mat-button-toggle>
                                <mat-button-toggle value="brand">Brand</mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>

                        <ng-container *ngIf="overviewtype == 'type'">
                        <div class="col-sm-6" *ngIf="hourstype">      
                            <div class="subhead mb-2">Hours by Type</div>                  
                            <app-charts format="hours" type="keyvalue" [data]="hourstype" ></app-charts>
                        </div>
                        <div class="col-sm-6 d-flex align-items-center">
                            <div>
                                <div class="subhead" *ngFor="let prj of hourstype | keyvalue">
                                    <span class="">{{ prj.key | labelsWork }}</span>: {{ prj.value }}
                                </div>
                            </div>
                            <hr >
                            
                        </div>
                        </ng-container>

                        <ng-container *ngIf="overviewtype == 'project'">
                        <div class="col-sm-6" *ngIf="projectType">      
                            <div class="subhead mb-2">Hours by Project</div>                  
                            <app-charts format="hours" type="keyvalue" [data]="projectType"></app-charts>                            
                        </div>
                        <div class="col-sm-6 d-flex align-items-center">
                            <div>
                                <div class="subhead" *ngFor="let prj of projectType | keyvalue">
                                    {{ prj.key }}: {{ prj.value }}
                                </div>
                                <hr >
                                <div>Total: {{ projectTotal }}</div>
                                <div class="label">*Monthly hours may differ from Invoice dates</div>
                            </div>
                        </div>
                        </ng-container>

                        <ng-container *ngIf="overviewtype == 'brand'">
                        <div class="col-sm-6" *ngIf="brandType">
                            <div class="subhead mb-2">Hours by Brand</div>
                            <app-charts format="hours" [options]="optionsBrand" type="brands" [data]="{chartData:brandType, brands: brands}" ></app-charts>
                        </div>
                        <div class="col-sm-6 d-flex align-items-center">
                            <div>
                                <div class="subhead" *ngFor="let prj of brandType | keyvalue">
                                    <span class="uc">{{ prj.key }}</span>: {{ prj.value }}
                                </div>
                                <hr >
                                <!-- a -->
                            </div>
                        </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <!--    <div class="block">
                <div class="block-title">
                    <div class="block-title-label" for="">Active Projects you're working on.</div>
                </div>
                <div class="block-content">
                    <div class="text-center subhead">
                        
                    </div>
                    <div class="form-group">
                        <table class="vault-table mat-table big">
                            <thead>
                                <tr class="mat-header-row">
                                    <th class="mat-header-cell">Brand</th>
                                    <th class="mat-header-cell">Project</th>
                                    <th class="mat-header-cell">Due</th>
                                    <th class="mat-header-cell">View</th>
                                </tr>
                            </thead>
                            <tr class="mat-row" *ngFor="let myproject of myProjects">
                                <td >{{  (brandService.brand(myproject.brand_id) | async)?.name }}</td>
                                <td class="mat-cell">{{ myproject.name }}</td>
                                <td class="mat-cell">{{ myproject.due | amTimeAgo}}</td>
                                <td>
                                    <button (click)="navTo(myproject.id)" class="mat-sm" mat-icon-button mat-button>
                                        <mat-icon>visibility</mat-icon>
                                    </button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
    <div>
        <div class="col-sm-12" >
            <div class="block">
                <div class="block-title" [class.loadingdis]="loadingTS" >
                    <div class="block-title-label" for="">My Timesheets Hours for ( {{ currentWeek.value | date }} &nbsp; <span *ngIf="approved">Approved</span> )</div>                    
                </div>

                <div class="mb-3 d-flex" [class.loadingdis]="loadingTS" >
                    <!-- <mat-slide-toggle [(ngModel)]="autosave">Autosave</mat-slide-toggle>                     
                    <span *ngIf="autosavestatus" class="pl-2 d-inline-flex" >
                        <app-loader class="small-laoder pr-2"></app-loader> {{ autosavestatus }}
                    </span> -->
                    <!-- <div *ngIf="autosave && form.invalid"></div> -->
                    <div *ngIf="form.invalid" >
                        <span class="label"> - Unale to save untill error is fixed: {{ deepErrors }}</span>
                    </div>
                </div>

                <span *ngIf="impersonating" [class.loadingdis]="loadingTS" >
                    <div class="alert alert-block-danger align-items-center d-flex " >
                        <mat-icon>error_outline</mat-icon> WARNING - you are editing: &nbsp; 
                        <strong>{{ impersonating.name }}'s</strong> &nbsp; timesheet
                    </div>
                </span>
                <div class="block-content" >
                    <app-alert></app-alert>

                    <div class="row" [class.loadingdis]="loadingTS" >
                        <div class="col-sm-2">
                            <button [disabled]="approved" (click)="clonePrevWeek()" mat-button color="primary" ><mat-icon>file_copy</mat-icon> Copy Last Week</button> &nbsp;
                        </div>
                        <div class="col-sm-2 d-flex">
                            <span prefix><user-avatar [user]="impersonating"></user-avatar></span>
                            <div class="w-100" *ngIf="profile | userCan: 'manage timesheets'">                                        
                                <mat-select class="" placeholder="Manage another user's timesheets" (selectionChange)="trigger()" [(ngModel)]="impersonating">                                
                                    <mat-option                     
                                    *ngFor="let user of users | userIs: 'employee'"
                                    [value]="user">
                                        <div class="my-1 d-flex align-items-center">
                                            <user-avatar [user]="user"></user-avatar> &nbsp; {{user.name}} {{user.last_name}}
                                        </div>
                                    </mat-option>
                                </mat-select>                                
                            </div>
                        </div>
                    </div>                     
                    <div class="form-group" [formGroup]="form" [ngClass]="{'disabled-form': approved}">
                        <div formArrayName="timesheets" class="form-row" [class.loadingdis]="loadingTS"  *ngFor="let timesheet of formTimesheets; let i = index">
                            <div class="row w-100" [formGroupName]="i">
                                <div class="col-sm-6">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div style="height: 50px" *ngIf="i == 0" class="height: 50px;"></div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="row">
                                                <div class="col-sm-1">
                                                    <button 
                                                        [disabled]="approved"
                                                        mat-icon-button 
                                                        color="primary" 
                                                        matTooltip="Remove"
                                                        matTooltipPosition="right" 
                                                        (click)="removeline(i)" 
                                                        class="quick-remove">
                                                            <mat-icon>remove</mat-icon>
                                                    </button>
                                                </div>
                                                <div class="col-sm-8">
                                                    <app-project-selector [form]="timesheet"></app-project-selector>
                                                </div>                                                
                                                <!-- <div class="col-sm-3">
                                                    <mat-form-field class="w-100">
                                                        <mat-select [disabled]="approved" placeholder="Brand" formControlName="brand_id">
                                                            <mat-option [value]="-1" >
                                                                <app-brand-logo [brand]="profile.company"></app-brand-logo> &nbsp; {{profile.company.name}}
                                                            </mat-option>
                                                            <mat-option *ngFor="let brand of sortBy('name',brands) | keyvalue" [value]="brand.value.id">
                                                                <app-brand-logo [brand]="brand.value"></app-brand-logo> &nbsp; {{brand.value.name}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col-sm-5">
                                                    <div *ngIf="form.get('timesheets').value[i].brand_id; let bid">
                                                        <div *ngIf="bid != -1">
                                                            <mat-form-field class="w-100" *ngIf="findBrandProjects(brands,bid); let projects">
                                                                <mat-select [disabled]="approved" placeholder="Project" formControlName="project_id">
                                                                    <mat-option value="">- None -</mat-option>
                                                                    <mat-option *ngFor="let project of projects | keyvalue" [value]="project.value.id">
                                                                        {{project.value.name}}
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>                                                            
                                                        </div>
                                                    </div>
                                                </div> -->
                                                <div class="col-sm-3" *ngIf="form.get('timesheets').value[i].brand_id != -1; else internal">                                                    
                                                    <mat-form-field class="w-100">
                                                        <mat-select [disabled]="approved" placeholder="Type" formControlName="work_type">
                                                            <mat-option *ngFor="let type of workTypes.types" [value]="type.value">
                                                                {{type.title}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>    
                                                <ng-template #internal>
                                                    <div class="col-sm-3">
                                                        <mat-form-field class="w-100">
                                                            <mat-select [disabled]="approved" placeholder="Type" formControlName="work_type">
                                                                <mat-option *ngFor="let type of workTypesInternal.types" [value]="type.value">
                                                                    {{type.title}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                </ng-template>                                            
                                            </div>
                                            <!-- <div class="row" *ngIf="1 == 2">
                                                <div class="col-sm-12">                                                    
                                                    <small >
                                                        <mat-form-field class="w-100">
                                                            <input matInput placeholder="Description" formControlName="description">
                                                        </mat-form-field>
                                                    </small>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>                                
                                <div class="col-sm-6">
                                    <div class="row">
                                        <div class="col" formArrayName="days"
                                            *ngFor="let day of timesheet.controls.days.controls; let j = index">
                                            <div style="height: 50px" *ngIf="i == 0" class="height: 50px;">
                                                <div class="day-of-week" *ngIf="day.get('day').value; let date">
                                                    <div >{{ date | amDateFormat: 'ddd' }} <span *ngIf="j == 5">/Sun</span></div>
                                                    <div class="subhead">{{ date | amDateFormat: 'MMM D' }} </div>
                                                </div>
                                            </div>
                                            <div [formGroupName]="j">
                                                <mat-form-field>
                                                    <input 
                                                        matInput  
                                                        min="0"
                                                        type="number" 
                                                        step="0.25" 
                                                        formControlName="time_worked">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4" [class.loadingdis]="loadingTS" >
                            <div class="col-sm-12">
                                <button [disabled]="approved" mat-stroked-button color="primary" (click)="addTimesheet()">
                                    <mat-icon>add</mat-icon>Project
                                </button>
                            </div>
                        </div>
                        <br><br>
                        <div *ngIf="!loadingTS; else loader">
                            <button color="accent" mat-raised-button  [disabled]="approved || form.invalid" (click)="approve()">Approve</button>
                            &nbsp;
                            <button mat-raised-button color="primary" [disabled]="approved || form.invalid" type="submit" (click)="onSubmit()">Save</button> &nbsp;&nbsp;&nbsp; Weekly Hours: {{ totalhours | number }}
                            &nbsp;
                                                                                                        
                            <span *ngIf="approved"> Approved on: <span *ngIf="approvedDate">{{ approvedDate | amFromUtc | date }}</span> </span>
                            <br><br>
                        </div>
                        <ng-template #loader>
                            <mat-spinner color="primary" class="mx-auto loader"></mat-spinner>  
                        </ng-template>
                        <!-- {{ form.get('timesheets').value | json }} -->
                    </div>
                </div>
            </div>
        </div>
    </div>    
  </ng-container>
</div>

    <!-- <form class="form-group" [formGroup]="form">

    <mat-form-field class="example-full-width">
        <input matInput placeholder="Project ID" input="number" name="project_id" formControlName="project_id">
    </mat-form-field>

    <br>
    <mat-form-field class="example-full-width">
        <textarea matInput placeholder="Description" input="text" name="description" type="text"
            formControlName="description"></textarea>
    </mat-form-field>

    <br>
    <mat-form-field class=" example-full-width">
        <input matInput placeholder="Brand ID" input="number" name="brand_id" formControlName="brand_id">
    </mat-form-field>

    <br>
    <mat-form-field>
        <mat-label>Type of Work</mat-label>
        <select matNativeControl required name="work_type" formControlName="work_type">
            <option value="accounting">Accounting</option>
            <option value="design">Design</option>
            <option value="engineering">Engineering</option>
            <option value="management">Management</option>
        </select>
    </mat-form-field>

    <br>
    <mat-form-field class="example-full-width">
        <input matInput placeholder="Hours Worked" input="number" name="time_worked" type="number" class="form-control"
            formControlName="time_worked">
    </mat-form-field>
    <br>
    <button class="btn btn-primary" type="submit" (click)="onSubmit()">Submit Timesheet</button>
    <br><br>
    <div>Value: {{form.value | json}}</div>
</form> -->

<ng-template #noTimesheets>

        <mat-spinner color="primary" class="mx-auto loader"></mat-spinner> 
</ng-template>