import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { ExpenseModel, Pager, Setting, UserProfileModel } from 'src/app/_models/subjects';
import { ExpenseService } from 'src/app/_services/expense.service';
import { UserService } from 'src/app/_services/user.service';
import { VelvetysoftService } from 'src/app/_services/velvetysoft.service';
import { ConfirmComponent } from '../dialogs/confirm/confirm.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { InvoiceService } from 'src/app/_services/invoice.service';
import { TimesheetInvoiceService } from 'src/app/_services/timesheet-invoice.service';

@Component({
  selector: 'app-manage-expenses',
  templateUrl: './manage-expenses.component.html',
  styleUrls: ['./manage-expenses.component.scss']
})
export class ManageExpensesComponent implements OnInit {
  $userProfile: Observable<UserProfileModel>;
  $expenses:Observable<Pager<ExpenseModel>>;
  displayedColumns: string[] = ['select', 'brand', 'author',  'title', 'created', 'total','invoice','status','actions'];
  sortedData: ExpenseModel[];
  ioConnected: any;
  selected: string = 'expense';
  expensespager;
  invoiceList = [];
  types: Setting[];
  asyncFilters = {
    user: {role: 'employee'},
    projects: 'all',
    brand: 'all',
    tiles: [{
      selected: 'expense reimbursement',
      field: 'sub_category',
      data: null
    }],
    status: {
      selected: 'submitted',//complete
      data: null
    },
    deleted: false
  };
  
  subject:any;
  @ViewChild('vaultTable') vaultTable;
  
  constructor(
    public vs: VelvetysoftService,
    public userService: UserService,
    private expenseService: ExpenseService,
    private dialog: MatDialog,
    private InvoiceService: InvoiceService,
    private timesheetInvoiceService: TimesheetInvoiceService
  ) { }

  ngOnInit(): void {
    this.ioConnected = this.expenseService.io.isConnected
    this.asyncFilters.status.data = this.vs.getSettings('expense').statuses;        
    this.asyncFilters.tiles[0].data = this.vs.getSettings('expense').subcategories;
    this.asyncFilters.tiles[0].data.map( (item) => {
      if(item.value == 'expense reimbursement') item.description = 'Remittance for out-of-pocket expenses  / Out-Of-Pocket Expenses';
      if(item.value == 'contractor') item.description = 'Compensation for hourly work / Hourly Compensation';  
      if(item.value == 'consultant') item.description = 'Compensation for project fees / Project Fee Compensation';
      if(item.value == 'vendor') item.description = 'Purchase of product-related services & supplies / Project-Related Purchases';
    });

    //'expense reimbursement'   => 'Employee Reimbursements',
    //'contractor'  => 'Contractor Fees',
    //'consultant'  => 'Consultant Payments',
    //'vendor'  => 'Vendor Payments',

    //console.log(this.asyncFilters.tiles[0].data, this.vs.getSettings('expense').subcategories    );
    this.subject = this.vs.managerNav.find( item => item.label == "Expense" );
    this.expensespager = this.expenseService.pager<ExpenseModel>({
      status: this.asyncFilters.status.selected, 
      sub_category: this.asyncFilters.tiles[0].selected
    });    
    this.$expenses = this.expensespager.$source;
    this.$userProfile = this.userService.userProfile();
    this.InvoiceService.invoiceList().pipe(first()).subscribe(data => {
      this.invoiceList = data.invoices;
    });
    this.types = this.vs.getSettings('expense')?.types;
  }

  invoiceName(iid){
    return this.invoiceList.find(i => i.id == iid);     
  }

  add(){

  }

  view(expense: ExpenseModel){
    this.expenseService.view(expense);
  }

  edit(expense?,view?,isInvoice?){
    let options = {pager: this.expensespager };//skipLoad: true};
    if(view) options['view'] = view;
    
    if(isInvoice){
      this.timesheetInvoiceService.manage(expense)
        .pipe(first())
          .subscribe( tsi =>{
            this.expensespager.refresh();
            this.vaultTable.selection.clear();
          });
    }else{
      this.expenseService.edit(expense,options)
        .pipe(first())
        .subscribe( expense =>{
          this.expensespager.refresh();
          this.vaultTable.selection.clear();
        });
    }
    
  }

  category(cat){
    this.expensespager.loadSearch({category: cat});
    this.selected = cat;
  }
  

  trash(id?){
    let ids = id ? [id] : this.vaultTable.selection.selected.map((p) => p.id);
    this.expenseService.deleteDialog([ids],{skipLoad:true})
    .pipe(first())
    .subscribe( expense =>{
      this.expensespager.refresh();
      this.vaultTable.selection.clear();
    })
  }

  // print(id?: Array<number>){
  //   let ids = id ? [id] : this.vaultTable.selection.selected.map((p) => p.id);
  //   this.expenseService.print(ids);
  // }

  printAll(){
    //this.data['expenseService'].view(expense);
    //pdfWrap
    let expenses:ExpenseModel[] = this.vaultTable.selection.selected;
    console.log(expenses);
    this.expenseService.pdfWrap(expenses, true).subscribe(res => {
      console.log('CTREATE DOWNLOAD',res)
    });
  }

  paid(id?){
    let ids = id ? [id] : this.vaultTable.selection.selected.map((p) => p.id);
    this.dialog.open(ConfirmComponent, {
      data: {        
        type: "expense-paid",
        title: `Mark Paid: ${ids?.length} Expenses`,
        ids: ids,
        payment_ref: '',
        label: `Payment or check reference`,
        content: `Optionally add payment details for ${ids?.length} expense.`,
        button: `Paid`,
      },
    }).afterClosed().subscribe((result) => {  
      console.log('refresh b');   
      if(result.status == "paid") this.expenseService.paid(ids, result.data.payment_ref)
        .pipe(first())
        .subscribe(res => {
          console.log('refresh a');
          this.expensespager.refresh() 
          this.vaultTable.selection.clear();
        });     
      
    });
  }
  

}
