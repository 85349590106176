<form [formGroup]="form">
    <div  formArrayName="rates" *ngFor="let j of getformRate; let i = index">    
        <div class="row" [formGroupName]="i">    
            <ng-container *ngIf="i == 0">
                <div class="row align-items-center w-100"> 
                    <div class="col-sm-1"> <button mat-button (click)="delete($event,i, j)">-</button></div>
                    <div class="col-sm-3"><span class="label">Base Rate</span></div>
                    <div class="col-sm-3">
                        <mat-form-field class="w-100 type-field">
                            <span matPrefix>$ &nbsp;</span>
                            <input matInput required placeholder="Rate" autocomplete="off" formControlName="rate" mask="separator.2" thousandSeparator="," >
                            <span matSuffix>per Hour</span>
                        </mat-form-field>                          
                    </div>                         
                </div>
            </ng-container> 

            <ng-container *ngIf="i > 0">
                <div class="row align-items-center w-100">    
                    <div class="col-sm-1"> <button mat-button (click)="delete($event,i, j)">-</button></div>
                    <div class="col-sm-3">
                        <span *ngIf="j.controls.type.value == 'conditional'" class="label">Conditional Rate</span>
                        <span *ngIf="j.controls.type.value == 'contract'" class="label" style="line-height: 1;display: inline-block;">Contract Minimun Hours per Month</span>
                    </div>
                    <div class="col-sm-3">
                        <mat-form-field class="w-100 type-field" *ngIf="j.controls.type.value !== 'contract'">
                            <span matPrefix>$ &nbsp;</span>
                            <input matInput placeholder="Rate" required autocomplete="off" formControlName="rate" mask="separator.2" thousandSeparator="," >
                            <span matSuffix>per Hour</span>
                        </mat-form-field>
                        <ng-container *ngIf="j.controls.type.value == 'contract'" >
                            <mat-form-field class="w-100 type-field">
                                <input matInput placeholder="Hours Per Month" autocomplete="off" required formControlName="value" type="number" >                            
                            </mat-form-field>
                        </ng-container>
                    </div>
                    <div class="col-sm-5" >
                        <div class="col-sm-12" *ngIf="j.controls.type.value !== 'contract'">
                            <mat-select placeholder="Condition" formControlName="condition" >
                                <mat-option                               
                                    required
                                *ngFor="let type of types.types" [value]="type.value">
                                {{type.title}}
                                </mat-option>
                            </mat-select>
                        </div>                    

                        <ng-container *ngIf="j.controls.condition.value == 'type'" >
                            <div class="col-sm-12" >
                                <mat-select placeholder="Work Type" formControlName="value">
                                    <mat-option
                                    required
                                    *ngFor="let type of workTypes.types" [value]="type.value">
                                    {{type.title}}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </ng-container>
                        
                        <ng-container *ngIf="j.controls.condition.value == 'hours'">
                            <div class="col-sm-12" *ngIf="j.controls.condition.value == 'hours'">
                                <mat-form-field class="w-100 type-field">
                                    <input matInput placeholder="Hours Per Month" autocomplete="off" required formControlName="value" type="number" >                            
                                </mat-form-field>
                            </div>
                        </ng-container>
                        
                        
                    </div>
                </div>
            </ng-container>
        </div> 
    </div>
    <div>
        <button color="primary" mat-raised-button *ngIf="rates.controls.length < 1" (click)="addRate('base')">Add Base Rate</button> &nbsp;
        <button color="primary" mat-raised-button *ngIf="rates.controls.length > 0" (click)="addRate('contract')">Add Minimun Contract</button> &nbsp;
        <button color="primary" mat-raised-button *ngIf="rates.controls.length > 0" (click)="addRate('conditional')">Add Conditional Rate</button> 
        
    </div>
    
</form>