import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams, HttpEventType } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, debounceTime, distinctUntilChanged, map, tap, first } from 'rxjs/operators';
import { ReplaySubject, Subject, throwError } from 'rxjs';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { FormErrorsService } from './form-errors.service';
import { AlertService } from './alert.service';
import { NervResponseImg, Pager } from '../_models/subjects';
import { Router } from '@angular/router';
import { FileuploadprogressComponent } from '../_directive/fileuploadprogress/fileuploadprogress.component';
import { FileuploadprogressService } from './fileuploadprogress.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpResponse } from '@angular/common/http';

export interface VSR {
  status: Boolean,
  data: any,
  errors: any,
  message?: string,
  performance?: string
}

export function jwtTokenGetter() {    
  return localStorage.getItem('token');
}


@Injectable({
  providedIn: "root",
})
export class ApiService {
  api = environment.backend;  
  nerv = environment.nerv;
  
  constructor(
    private http: HttpClient,
    private snack: MatSnackBar,
    private formErrors: FormErrorsService,
    private alertService: AlertService,
    private router: Router,
    private fileuploadprogressservice: FileuploadprogressService,
    private sanitizer: DomSanitizer
  ) //@Optional() @Inject(Window) window: any
  {
  
  }

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      "Authorization": [`Bearer ${jwtTokenGetter()}`]
    }),
  };

  result(res,hideMessage:boolean=false) {
    if (res?.message && !hideMessage)
      this.snack.open(`${res.message}`, "Dismiss", {
        duration: 2000,
      });
    return res?.status ? res.data : res;
  }

  handleErrors(res, skipToast) {
    console.log(res);
    this.alertService.clear();

    //NETWORK ERROR
    // if(res.status == 0 && res.name == "HttpErrorResponse"){
    //   res.status = "Network Error";
    //   res.statusText = "Can't connect to server... Do you have internet?";
    //   this.router.navigate(['/noconnection'])
    // }
    // console.log(res)

    //FORM ERRORS
    if (res.error && res.error.errors) {
      this.formErrors.add(res.error.errors);
    }

    //BACKEND ERRORS
    if (res.error && res.error.message) {
      console.log("ERROR:", res.error.message);
      

      let msg = "Dismiss";
      if (res.error.message == "Unauthorized") {
        msg = "Login";
      } else {
        msg = "Dismiss";
      }

      if (!skipToast)
        this.snack.open(`${res.error.message}`, msg, {
          duration: 2000,
        });
    } else {
      if (!skipToast)
        this.snack.open(`${res.status}: ${res.statusText}`, "Dismiss", {
          duration: 2000,
        });
    }
  }

  get<T>(path, skipToast?: boolean, skipGlobalErrorHandler?: boolean) {    
    return this.http.get<VSR>(`${this.api}${path}`, this.httpOptions).pipe(
      catchError((res) => {
        
        if (!skipGlobalErrorHandler) this.handleErrors(res, skipToast);
        return throwError(res);
      }),
      map((r) => this.result(r))
    );
  }

  download<T>(path, skipToast?: boolean, skipGlobalErrorHandler?: boolean, options?,data=null) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "application/json",
      "Authorization": [`Bearer ${jwtTokenGetter()}`]
    });

    

    // headers_download.responseType = "blob";
    // headers_download.observe = 'response';

    const req = (data)
      ? this.http
        .post<Blob>(`${this.api}${path}`,data, {
          headers: headers,
          responseType: "json",
        })
      : this.http
        .get<Blob>(`${this.api}${path}`, {
          headers: headers,
          responseType: "json",
        });   
    
    return req
      .pipe(
        catchError((res) => {
          if (!skipGlobalErrorHandler) this.handleErrors(res, skipToast);
          return throwError(res);
        })
      )
      .subscribe((res:any) => {

        if(!res?.status && res?.message){
          this.snack.open(`${res.message}`, "Dismiss", {
            duration: 2000,
          });
          return;
        }

        //decode base64        
        var base64str = res.data.blob;
        // decode base64 string, remove space for IE compatibility
        var binary = atob(base64str.replace(/\s/g, ''));
        var len = binary.length;
        var buffer = new ArrayBuffer(len);
        var view = new Uint8Array(buffer);
        for (var i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
        }

        const blob = new Blob([view], { type: "application/pdf" });
        const dataURL = window.URL.createObjectURL(blob);

        

        // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //   window.navigator.msSaveOrOpenBlob(blob);
        //   return;
        // }

        const link = document.createElement("a");

        if(options?.view){
          link.target = "_blank";
        }else{
          link.download = res.data.title;
        }
        link.href = dataURL;
        
        link.click();
        // setTimeout(() => {
        //     // For Firefox it is necessary to delay revoking the ObjectURL
        //     window.URL.revokeObjectURL(dataURL);
        //   }, 100);
      });
  }

  

  pager<T>(path:string, data, skipToast?: boolean, skipGlobalErrorHandler?: boolean) {    
    const _self = this;
    const $source:ReplaySubject<T> = new ReplaySubject(1);      
    let _originalPath = path;
    let _filters = null;
    let _lastPath = null;    
    // Load data
   
    const load = function(url:string){
      //console.log('LOAD',_filters)
        let path = _lastPath = (url) ? url.replace(_self.api,"") : url;
        _self.post(path,{}).pipe(first()).subscribe(pager => $source.next(pager));
    }
    //Search data
    const loadSearch = function(filters, url?:string){

      //check if the url contains '?'
      let operator = (url && url.includes('?'))
        ? '&'
        : '?';

      _filters = (filters) 
        ? `${operator}${_self.paramatersToString(filters)}`
        : null;
     // console.log('LOAD SEARCH',_filters)
      let path = (url) 
        ? `${url}${_filters}`
        : `${_originalPath}${_filters}`;

      filters && load(path);                  
    }

    const params = function(){
      return _filters;
    }

    const refresh = function(){
      let path = (_lastPath)
        ? _lastPath
        : _originalPath;
      load(path);
    };

    //short hand if else statment with no return

    (data)
      ? loadSearch(data)
      : load(path);

    return {
      load: load,
      refresh: refresh,
      loadSearch: loadSearch,
      getParams: params,
      $source: $source
    }
  }

  post<T>(path, data, skipToast?: boolean, skipGlobalErrorHandler?: boolean) {
    return this.http
      .post<VSR>(`${this.api}${path}`, data, this.httpOptions)
      .pipe(
        catchError((res) => {
          if (!skipGlobalErrorHandler) this.handleErrors(res, skipToast);
          return throwError(res);
        }),
        map((r) => this.result(r,skipToast))
      );
  }

  put<T>(
    path,
    id,
    data,
    skipToast?: boolean,
    skipGlobalErrorHandler?: boolean,
  ) {
    return this.http
      .put<T>(`${this.api}${path}/${id}`, data, this.httpOptions)
      .pipe(
        catchError((res) => {
          if (!skipGlobalErrorHandler) this.handleErrors(res, skipToast);
          return throwError(res);
        }),
        map((r) => this.result(r,skipToast))
      );
  }

  delete<T>(path, id, skipToast?: boolean, skipGlobalErrorHandler?: boolean) {
    return this.http
      .delete<T>(`${this.api}${path}/${id}`, this.httpOptions)
      .pipe(
        catchError((res) => {
          if (!skipGlobalErrorHandler) this.handleErrors(res, skipToast);
          return throwError(res);
        }),
        map((r) => this.result(r))
      );
  }

  file<T>(path, data, skipToast?: boolean, skipGlobalErrorHandler?: boolean) {
    let $result = new ReplaySubject(1);
    let quickSnack = null;
    //@TODO: DO I NEED TO UNSUBSCRIBE?
    this.http.post<VSR>(`${this.api}${path}`, data, {
        headers: new HttpHeaders({"Authorization": [`Bearer ${jwtTokenGetter()}`]}),
        reportProgress: true,
        observe: 'events'
      }).pipe(
      catchError((res) => {
        if (!skipGlobalErrorHandler) this.handleErrors(res, skipToast);
        return throwError(res);
      })).subscribe((r) => {
        //NOTIFICATION
        this.fileuploadprogressservice.uploadProgress.next('Uploading: 0%');
        if(!quickSnack) quickSnack= this.snack.openFromComponent(FileuploadprogressComponent,{
          horizontalPosition: 'right',
          verticalPosition: 'bottom',
        });

        //DO FINISHED
        if (r.type === HttpEventType.Response) {   
          console.log(r.body)
          $result.next(this.result(r.body));          
          $result.complete();
          quickSnack.dismiss();
        }
        
        //DO PROGRESS
        if(r.type == HttpEventType.UploadProgress){
          const percentDone = Math.round(100 * r.loaded / r.total);
          let msg = (percentDone >= 100)
             ? 'Processing file(s)...'
             : `Uploading: ${percentDone}%`;
          
          this.fileuploadprogressservice.uploadProgress.next(msg);
        }
      });

    return $result;
  }


  paramatersToString(vars:object):string{
    let params = new HttpParams();
    for (const f in vars) {
      if(vars[f]) params = params.set(f,vars[f]);
    };
    return params.toString();
  }

  secureImg(path:string){    
    let $result = new ReplaySubject(1);
    $result.next({image: 'https://vh-vault.s3.us-east-1.amazonaws.com/static/loader.svg'});
    this.http.get(`${this.nerv}image/${path}`, { 
          // responseType: 'blob', 
          // observe: 'response', 
          headers: new HttpHeaders({
            "Authorization": [`Bearer ${jwtTokenGetter()}`],
          })
        }).pipe(
          //@ts-ignore
          catchError((res) => {
            $result.next({image: 'https://vh-vault.s3.us-east-1.amazonaws.com/static/404.png?1'});            
        })).subscribe((res: NervResponseImg) => { 
          console.log(res)         
          let img = this.sanitizer.bypassSecurityTrustUrl(`data:image/png;base64,${res.image}`)
          //res.image
          $result.next({
            image: img,
            ...res.info
          }); 
        });
        
    return $result;
      //`${this.vs.nerv}image/${asset.path}?max=1920`
  }


  nervCall():any{
    return this.http.get<VSR>(`${this.nerv}test`, this.httpOptions);
  }
}
