import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { BrandService } from '../../_services/brand.service';
import { BrandModel, SettingTypes } from 'src/app/_models/subjects';
import { VelvetysoftService } from '../../_services/velvetysoft.service';

@Component({
  selector: 'app-modal-library-list',
  templateUrl: './modal-library-list.component.html',
  styleUrls: ['./modal-library-list.component.scss']
})
export class ModalLibraryListComponent implements OnInit {
  @Input() collection: Observable<any>;
  @Input() subject: string = null;
  @Output() loadItem = new EventEmitter();
  selectedBrand: number;
  selectedType: string;
  $brands: Observable<BrandModel[]>;
  types: SettingTypes;
  constructor(
    private brandService: BrandService,
    private vs: VelvetysoftService
  ) { }

  ngOnInit() {
    this.$brands = this.brandService.brands;
    if (this.subject) this.types = this.vs.settings[this.subject].types;
    
  }

  load(item){  
    console.log(item);
    this.loadItem.emit(item);
  }

}
