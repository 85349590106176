import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivityModel } from 'src/app/_models/activity';
import { UserModel } from 'src/app/_models/subjects';
import { ActivityService } from 'src/app/_services/activity.service';
import { UserService } from 'src/app/_services/user.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.scss']
})
export class ActivityLogComponent implements OnInit {
  @Input() ids: number[];
  @Input() type: string;
  @Input() subject: string;
  @Input() exclude = 'updated';
  $activity: Observable<ActivityModel[]>
  users: UserModel[];
  
  constructor(
    private activityService: ActivityService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    //'activity-project-invoice'
    //console.log(this.activityService,this.type)
    if(this.ids && this.ids.length > 0){
      (this.type)
        ? this.$activity = this.activityService[this.type](this.ids,this.exclude)
        : this.$activity = this.activityService.subject(this.ids,this.subject,this.exclude);
      // this.userService.loadUsersAssignedTo('brand', null)
      //   .pipe(first())
      //   .subscribe( users =>{
      //     this.users = users;
      //   })
    }
  }

  // userById(id){
  //   return this.users.find(user => user.id == id);
  // }



}
