import { Component, OnInit } from '@angular/core';
import { PermsService } from 'src/app/_services/perms.service';
import { VelvetysoftService } from 'src/app/_services/velvetysoft.service';
import { first } from 'rxjs/operators';
import { PermissionModel, RoleModel } from 'src/app/_models/subjects';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/_services/api.service';
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-manager-permissons',
  templateUrl: './manager-permissons.component.html',
  styleUrls: ['./manager-permissons.component.scss']
})
export class ManagerPermissonsComponent implements OnInit {
  roles: any;//RoleModel[];
  $permissions: Observable<PermissionModel[]>; 
  name: string = '';
  nameP: string = '';
  displayedColumns: string[] = ['label'];
  socketMsg:any = '';
  testInput: string = '';
  constructor(
    public vs: VelvetysoftService,
    private permsService: PermsService,
    private apiService: ApiService,
    private socket: Socket
  ) { }

  //MIGHT NEED TO MOVE THIS STUFF TO SECONDARY ULR CALL
  ngOnInit() {
    this.roles = this.vs.settings.user.roles; 
    this.roles.forEach( r => this.displayedColumns.push(r.name))
    this.$permissions =  this.permsService.perms();
    this.sendMessage('Yo');
    // this.apiService.nervCall().subscribe( res =>{
    //   console.log(res)
    // });
    this.socketMsg = this.socket.fromEvent('message');
    this.socket.fromEvent('who').subscribe( res =>{
      console.log(res)
    });
  }

  test(){
    this.socket.emit('who', 'who');
  }

  testuser(){
    this.apiService.post('test-user-io',{message: this.testInput, action: 'user'}).subscribe();
  }
  testCompany(){
    this.apiService.post('test-user-io',{message: this.testInput, action: 'company'}).subscribe();
  }

  sendMessage(msg: string) {
    this.socket.emit('message', msg);
  }
  

  //will have to fix this later
  load(){
    this.vs.loadSettings().subscribe( res =>{
      console.log('res')
      this.roles = this.vs.settings.user.roles; 
      this.roles.forEach( r =>{        
      })
      
      //this.permissions =  this.vs.settings.user.permissions; 
    })    
  }

  isActive(el,col){
    const active = el.roles.find(r => r.name == col);
    return (active)
      ? true
      : false   
  }

  assignPerm(event, perm:PermissionModel,role:string){
    console.log(event.checked)
    this.permsService.assignPerm(perm.id, role, event.checked )
      .pipe(first())
      .subscribe( res => {
        this.load() 
      })
  }

  isNotCustom(perms, role){
    console.log(perms.name, role)
    let disabled = true;
    this.roles.find( r => {
      if(r.name == role && r.company_id){
        disabled = false;
      } 
    });      

    return disabled;    
  }

  create(name){
    this.permsService.createRole(name)
      .pipe(first())
      .subscribe( res => {
        this.load() 
        this.name = "";
      })
  }

  createPerm(name){
    this.permsService.createPerm(name)
      .pipe(first())
      .subscribe( res => {
        this.load() 
        this.nameP = "";
      })
  }

}
