<ng-container *ngIf="($userProfile | async); let profile">
  <div class="container" *ngIf="($projects | async);let projects; else noProjects">
    <div class="block">
      <div class="block-title">
        <div class="block-title-label" for="">
          <mat-icon class="mr-1">{{subjectObj.icon}}</mat-icon>{{subjectObj.label}}
        </div>
        <div class="block-title-toolbar">
          <button *ngIf="profile | userCan: 'create project'" mat-button color="primary" (click)="add()"><mat-icon>add</mat-icon>New {{subjectObj.label}}</button>
          &nbsp;
          <button *ngIf="profile | userCan: 'manage project'" mat-button [matMenuTriggerFor]="menu" color="primary"><mat-icon>sort</mat-icon>Actions</button>
          <mat-menu #menu="matMenu" class="menu-sm">
            <button mat-menu-item (click)="delete()" >Delete</button>
          </mat-menu>
        </div>
      </div>
      <div class="block-content">
        
        <div *ngIf="projects.length; else noneYet">
          <app-table
            mat-table 
            #vaultTable
            matSort #sort="matSort"
            filterOn="status"
            [filters]="project_type.statuses"
            [filtered]="filteredProjects"          
            [dataSource]="$projects"          
            [columns]="displayedColumns"> 

            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Name </th>
              <td mat-cell *matCellDef="let element" class="text-capitalize click" (click)="navTo(element.id)"> {{element.name}} </td>
            </ng-container>
            
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
              <td mat-cell *matCellDef="let element"> <meta-display subject="project" type="types" [id]="element.type"></meta-display> </td>
            </ng-container>
            
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
              <td mat-cell *matCellDef="let element">
                <meta-display format="dot" subject="project" type="statuses" [id]="element.status"></meta-display>              
              </td>
            </ng-container>
            
            <ng-container matColumnDef="actions">
              <th mat-header-cell class="text-right" *matHeaderCellDef> Actions </th>
              <td mat-cell class="text-right" *matCellDef="let element"> 
                <button *ngIf="profile | userCan: 'manage project'" (click)="add(element,{proposal: -1})" class="mat-sm" mat-icon-button mat-button>
                  <mat-icon>edit</mat-icon>
                </button>
              </td>
            </ng-container>
                      
            <ng-container matColumnDef="due">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Due Date </th>
              <td mat-cell *matCellDef="let element" [matTooltip]="element.due"> {{element.due  | amTimeAgo}} </td>
            </ng-container>

            <ng-container matColumnDef="user">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Team </th>
              <td mat-cell *matCellDef="let element"> <app-users class="inline" [users]="element.users"></app-users></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns; "></tr>

          </app-table>
            
          <ng-template #noProjectsYet>
            <p>No {{ subject }} found</p>
          </ng-template> 

        </div>    
      </div>
    </div>
  </div>
  <ng-template #noneYet>
    <p>No {{ subject }} found</p>
  </ng-template>
  <ng-template #noProjects>
    <mat-spinner color="primary" class="mx-auto loader"></mat-spinner>
  </ng-template>
</ng-container>