import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DATE_FORMATS, DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { VelvetysoftService } from 'src/app/_services/velvetysoft.service';
import { OpenaiService } from 'src/app/_services/openai.service';

@Component({
  selector: "app-add-round",
  templateUrl: "./add-round.component.html",
  styleUrls: ["./add-round.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class AddRoundComponent implements OnInit {
  public Editor = ClassicEditor;
  form: UntypedFormGroup;
  error: any = false;
  subject: string = "round";
  constructor(
    private dialogRef: MatDialogRef<AddRoundComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    fb: UntypedFormBuilder,
    public vs: VelvetysoftService,
    private openaiService: OpenaiService
  ) {
    this.form = fb.group({
      id: [""],
      name: ["", Validators.required],
      project_id: [""],
      due_date: [""],
      //created_at: ['', [Validators.required, Validators.email]],
      description: [""],
      next_steps: [""],
      //meeting_notes: [""]
    });
  }

  ngOnInit() {
    console.log(this.data);
    this.form.patchValue(this.data[this.subject]);
  }

  save() {
    this.dialogRef.close({ status: true, round: this.form.value });
  }

  delete() {
    this.dialogRef.close({ status: "delete", round: this.form.value });
  }

  close() {
    this.dialogRef.close();
  }

  prompt(form,field){    
    let prompt = form.value[field];
    this.openaiService.openPrompt(prompt)
    .subscribe(result => {
        let data = [];
        data[field] = result;
        form.patchValue(data);
    });
  }
}
