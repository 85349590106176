import { Injectable } from '@angular/core';
import { ReplaySubject, Observable, Subscribable, Subject } from 'rxjs';
import { Router, Resolve } from '@angular/router';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { first, map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { BrandModel } from "../_models/subjects";
import { AddBrandComponent } from '../_component/brands/add-brand/add-brand.component';
import { MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import * as moment from "moment";
import { AddressModel } from '../_models/address';


@Injectable({
  providedIn: "root",
})
export class BrandService implements Resolve<any> {
  _brand ={
    id: null,
    name: "",
    color: "",
    active: 1,
    company_id: null,
    users: [],
    addressess: null,
    logo: "",
    yearly_forcast: null,
    invoice_overdue_days: null,
  };
  public BrandsBuster: boolean = true;
  public $activeBrand: ReplaySubject<BrandModel> = new ReplaySubject(1);
  private $brands: ReplaySubject<BrandModel[]> = new ReplaySubject(1);
  private _activeBrandID: number = null;
  private _brandsData: BrandModel[];
  private subject: string = "brand";

  constructor(
    private router: Router,
    private snack: MatSnackBar,
    private apiService: ApiService,
    public dialog: MatDialog,
  ) {}

  resolve() {    
    return this.loadBrands();
  }

  get activeBrandID() {
    if (!this._activeBrandID) {
      return false;
    } else {
      return this._activeBrandID;
    }
  }
  get activeBrand() {
    this.getBrandsCache();
    return this.$activeBrand.asObservable();
  }

  get brands() {
    this.getBrandsCache();
    return this.$brands.asObservable();
  }
  

  brand(bid: string): Observable<BrandModel> {
    return this.$brands.pipe(
      map((brands) => {
        //return _.find(brands, { id: parseInt(bid) });
        return brands.find(x => x.id == +bid);
      })
    );
  }

  addBrand(brand) {
    return this.apiService.post<BrandModel[]>("brand", { brand: brand });
  }

  loadBrands(forceReplay?) {
    this.apiService.get<BrandModel[]>("brands")
      .pipe(first())
      .subscribe(
      (res) => {

        //RUN SETUP 
        if(!res.status && res?.data?.action == 'setup-company') this.router.navigate(['/setup']);

        this._brandsData = res;
        this.$brands.next(res);
        if (!this._activeBrandID || forceReplay) {
          
          let lastBrand = null;
          let lastBrandID = localStorage.getItem("active_brand");
          if (lastBrandID) {
            //lastBrand = _.find(this._brandsData, ["id", parseInt(lastBrandID)]);
            //lastBrand = _.find(this._brandsData, ["id", parseInt(lastBrandID)]);
            lastBrand = this._brandsData.find(x => x.id == +lastBrandID);
          }
          let selectedBrand = lastBrand ? lastBrand : this._brandsData[0];
          this._activeBrandID = selectedBrand.id;
          this.$activeBrand.next(selectedBrand);
        }
      },
      (error) => {
        if(error && error.status == 401){
          console.log(error, 'HUGE ERROR')
          localStorage.removeItem("token");
          window.location.reload();
        }
      }
    );
  }

  selectBrand(id,skipReload:boolean=false) {
    //this.$activeBrand.next('LOADING');
    //let selectedBrand: BrandModel = _.find(this._brandsData, ["id", id]);
    let selectedBrand: BrandModel = this._brandsData.find((x) => x.id == id);
    this._activeBrandID = selectedBrand.id;
    localStorage.setItem("active_brand", JSON.stringify(this._activeBrandID));
    if(!skipReload) this.router.navigate(["/dashboard/overview"]);
    return this.$activeBrand.next(selectedBrand);
  }

  // Returns a list of all proposals waiting for invoices on brand.
  openProposals(bid?){
    let id = (bid) ? bid : this.activeBrandID;
    return this.apiService.post(`${this.subject}-open-proposals`,{bid: id});
  }

  projections(bid?, date?){
    //console.log(this.activeBrandID)
    let id = (bid) ? bid : this.activeBrandID;
    return this.apiService.post(`${this.subject}-projection`,{bid: id, date: date});
  }

  addresses(bid):Observable<AddressModel[]>{    
    let data = {bid: bid}
    return this.apiService.post(`${this.subject}-addresses`,data);
  }

  updateUsers(brand: BrandModel) {
    //let data = { bid: brand.id, users: _.map(brand.users, "id") };
    let data = { bid: brand.id, users: brand.users.map(x => x.id) };
    this.apiService.post<BrandModel[]>("brand/users", data).subscribe(
      (res: object) => {
        var message = "";
        Object.keys(res).forEach((label) => {
          //let records = _.isObject(res[label])!(this.user instanceof Object)
          let records = (res[label] instanceof Object)
            ? Object.keys(res[label]).length
            : res[label].length;
          message +=
            records > 0
              ? `${
                  label.charAt(0).toUpperCase() + label.slice(1)
                } ${records} User(s).`
              : "";
        });

        if(message) this.loadBrands(true);
        message = message !== "" ? message : "No changes made.";        

        this.snack.open(message, null, {
          duration: 2000,
        });
      },
      (error) => console.log(error)
    );
  }

  editDialog(model,drives=null) {
    let _model: BrandModel = model
      ? model
      : this._brand;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { 
      brand: _model,
      drives: drives,
      brandService: this
    };

    let resultRef = new Subject();
    const dialogRef = this.dialog.open(AddBrandComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return true;
      if (result.status === true) {
        result.due = moment(result.due).toDate();
        this.addBrand(result.brand).subscribe((res) => {
          //this.BrandsBuster = true;
          //@TODO refresh sidebar brand if active during this

          //UPDATE ACTIVE BRAND IF IS ACTIVE
          this.activeBrand
            .pipe(first())
            .subscribe( activeBrand =>{
              if( result.brand.id == activeBrand.id) this.$activeBrand.next(result.brand);
          })
          this.loadBrands();
        });
      } else if (result.status === "delete") {              
          const data = {ids: [_model.id]}           
          this.apiService.post(`brand-delete`, data)
            .pipe(first())
            .subscribe( res => this.loadBrands() )
        
      }
      resultRef.next(result);
    });
    return resultRef;
  }
  
  

  private getBrandsCache() {
    if (this.BrandsBuster) {
      this.loadBrands();
      this.BrandsBuster = false;
    }
  }

  pager<T>(data?){
    return this.apiService.pager<T>(`${this.subject}s-pager`,data);
  }
  
}