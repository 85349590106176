
<div *ngIf="!error;else erromsg">
  <canvas
    baseChart 
    [datasets]="chartData"
    [labels]="labels"
    [options]="_options"
    [legend]="legend"
    [chartType]="chartType"
    [plugins]="plugins"
    >    
  </canvas>  
</div>

<div style="display: none;">
  <canvas #myChart width="400" height="400"></canvas>
</div>

<ng-template #erromsg >
  <div class="px-3 mt-3">
    <label class="title-section cap-init">{{ error }}</label>
  </div>
</ng-template>
