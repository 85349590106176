<!-- *ngIf="( projects$ | async ); let projects" -->
<div *ngIf="($brands | async); let brands">
  <div 
    class="align-self-center logo-holder"  
    *ngIf="($activeBrand | async); let brand" 
    [style.background-color]="brandColor">
    <button mat-button [matMenuTriggerFor]="menu"  class="brand-selector">
      <app-brand-logo format="large" [brand]="brand"  class="app-brand-logo"></app-brand-logo>
      <!--  {{ brand.name }} -->
      <mat-icon style="text-shadow: 0px 0px 7px black;">arrow_drop_down</mat-icon>
    </button>
    <mat-menu #menu="matMenu" class="brand-nav" xPosition="after">
      <button mat-menu-item *ngFor="let brand of brands | active" class="align-items-center d-flex" (click)="selectBrand(brand.id)">
        <app-brand-logo class="mr-2" [brand]="brand" ></app-brand-logo> <span class="brand-name">{{ brand.name }}</span>
      </button>
    </mat-menu>
  </div>
</div>

<section *ngFor="let link of navigation" class="section" [ngClass]="{'project-sb' : link.label == 'Projects'}">
  <mat-expansion-panel [expanded]="true" *ngIf="link.label == 'Projects';else normal" class="reset projects-expand">
    <mat-expansion-panel-header class="reset" [expandedHeight]="'44px'" [collapsedHeight]="'44px'">
      <h4 class="cursor d-flex"  routerLinkActive="active" [routerLink]="link.path">
        <mat-icon>{{ link.icon }}</mat-icon> 
        <span>&nbsp; {{ link.label }}</span> 
        <ng-container *ngIf="($userProfile | async); let profile">
          <button class="" *ngIf="profile | userCan: 'create project'"  mat-icon-button (click)="addProject($event)"><mat-icon>add</mat-icon></button>
        </ng-container>   
      </h4>
    </mat-expansion-panel-header>  
    <div *ngIf="link.label == 'Projects'">
      <mat-accordion *ngIf="( $projects | async ); let projects">
        <mat-expansion-panel *ngFor="let status of projectStatuses" class="expanion-projects"
          [expanded]="projectState == status.value">
          <mat-expansion-panel-header [expandedHeight]="'44px'" [collapsedHeight]="'44px'">
            <mat-panel-title color="white">
              <div class="align-center flex-grow-1">
                <span class="dot {{ status.value }}"></span>
                {{ status.title }}
              </div>
              <span class="align-self-center bug">{{(projects | vhFilter: {status:status.value}).length}}</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul class="list-group list-group-flush"
            *ngIf="( projects | vhFilter: {status:status.value}).length; else noProjects">
            <li class="list-group-item" *ngFor="let project of projects | vhFilter: {status:status.value};let i=index;">
              <a class="w-100" (click)="mobileClose()" routerLinkActive="active" routerLink="./project/{{ project.id}}"><span
                  class="dash"></span>{{ !!project.name ? project.name : '( Untitled )' }}</a>
            </li>
          </ul>
          <ng-template #noProjects>
            <div class="none">- -</div>
          </ng-template>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </mat-expansion-panel>
  <ng-template #normal>
    <div *ngIf="($userProfile | async); let profile">
      <h4 class="cursor" 
        *ngIf="profile | userCan: link.perms" 
        (click)="mobileClose()" 
        routerLinkActive="active" 
        [routerLink]="link.path">
      <mat-icon>{{ link.icon }}</mat-icon> <span>&nbsp; {{ link.label }}</span>
    </h4>
    </div>
  </ng-template>  
</section>

<section *ngIf="(gapi.$activeted | async ) && brandGoogleDrive" (click)="openGoogle()" class="section">
  <h4 class="cursor">
    <mat-icon>cloud</mat-icon><span>&nbsp; Google Files</span>
  </h4>
</section>

