import { Component, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { BrandModel, Pager, ProposalModel, UserProfileModel } from 'src/app/_models/subjects';
import { BrandService } from 'src/app/_services/brand.service';
import { UserService } from 'src/app/_services/user.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ProposalService } from 'src/app/_services/proposal.service';
import { VelvetysoftService } from 'src/app/_services/velvetysoft.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-manage-proposals',
  templateUrl: './manage-proposals.component.html',
  styleUrls: ['./manage-proposals.component.scss']
})
export class ManageProposalsComponent {
  subject:any;
  $userProfile: Observable<UserProfileModel>;
  $proposals: Observable<Pager<ProposalModel>>;
  displayedColumns: string[] = ['select', 'brand', 'author', 'name','created','due','total','status', 'actions',];
  sortedData: ProposalModel[];
  asyncFilters = {
    user: {role: 'employee'},
    brand: 'all',    
    status: {
      selected: 'sent,draft,approved',//complete
      data: null
    },
    filters: [],
    deleted: false,    
  };
  //subject: string = 'Project';
  filteredProposals: string[] = [];  
  proposalspager;
  brands: BrandModel[];
  @ViewChild('vaultTable') vaultTable;

  constructor(
    public vs: VelvetysoftService,
    private userService: UserService,
    private brandService: BrandService,
    public dialog: MatDialog,
    private proposalService: ProposalService,
  ) { }

  ngOnInit(): void {
    this.subject = this.vs.managerNav.find( item => item.label == "Proposal" );
    this.asyncFilters.status.data = this.vs.getSettings('proposal').statuses; 
    this.asyncFilters.filters.push({
      label: 'Filter Printed',
      field: 'printed',
      data: [
        {label: 'Printed', value: 1},
        {label: 'Not Printed', value: 0}
      ]
    });
    this.proposalspager = this.proposalService.pager<ProposalModel>();    
    this.$proposals = this.proposalspager.$source;
    this.$userProfile = this.userService.userProfile();

  }

  edit(proposal: ProposalModel){

    let options = {
      pager: this.proposalspager,
      view:'proposal',
      title: proposal.title,
      skipLoad: true
    };

    
    this.proposalService.edit(proposal,options)
    .pipe(first())
    .subscribe(() => {      
      this.proposalspager.refresh();
      this.vaultTable.selection.clear();
    });
      
  }

  delete(){

  }
}
