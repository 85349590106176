<div class="vault-modal">
  <ng-container >
    <!-- *ngIf="(profile | async); let profile" -->
  <div [formGroup]="form">
    <h1 mat-dialog-title>
      <span *ngIf="data.project.name; else addTitle">Edit</span>
      <ng-template #addTitle>
        Add
      </ng-template>
      Project{{ data.title }} 
    </h1>
    <div mat-dialog-content>
      <app-alert></app-alert>
      <div style="display:block;color: #f44336;">{{ form.controls.est_round.errors?.compareRounds }}</div>
      <div class="row">
        <div class="col-sm-10">
          <mat-form-field class="w-100">
            <input matInput name="title" name="name" placeholder="Project Title" formControlName="name" required required>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <div *ngIf="settings.statuses; let statuses">
            <mat-form-field class="w-100">
              <mat-select placeholder="Status" formControlName="status">
                <mat-option *ngFor="let status of statuses" [value]="status.value">
                  {{status.title}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm-4">
          <div *ngIf="settings.types; let types">
            <mat-form-field class="w-100">
              <mat-select placeholder="Project Type" formControlName="type">
                <mat-option *ngFor="let type of types" [value]="type.value">
                  {{type.title}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-sm-4">
          <mat-form-field class="w-100">            
            <input matInput name="kickoff" formControlName="kickoff" [matDatepicker]="pickerk" placeholder="Kickoff Date">
            <mat-datepicker-toggle matSuffix [for]="pickerk"></mat-datepicker-toggle>
            <mat-datepicker #pickerk></mat-datepicker>            
            <!-- <div class="errors" *ngIf="est_round.errors?.['compareRounds']">
              {{ est_round?.errors?.['compareRounds'] }}
            </div> -->
          </mat-form-field>                    
        </div>
        <div class="col-sm-4">
          <mat-form-field class="w-100">            
            <input (dateInput)="generateEstimatedDates()" matInput name="due" formControlName="due" [matDatepicker]="picker" placeholder="Estimate Due Date" required>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>            
            <!-- <div class="errors" *ngIf="est_round.errors?.['compareRounds']">
              {{ est_round?.errors?.['compareRounds'] }}
            </div> -->
          </mat-form-field>                    
        </div>
        <div class="col-sm-6" >          
          <mat-form-field class="w-100" *ngIf="data?.options?.proposal != -1">
            <span matPrefix>$ &nbsp;</span>                    
            <input type="text" [readonly]="data?.options?.proposal > 0" placeholder="Budget" name="Total" matInput formControlName="budget" mask="separator.2" thousandSeparator=",">
            <span *ngIf="data?.options?.proposal > 0" matSuffix>(Proposal)</span>
          </mat-form-field>
          
        </div>
        <div class="col-sm-12 mt-2">
          <div class="form-group text-left">
            <mat-slide-toggle color="primary" formControlName="ongoing"> 
              <label class="label">Ongoing Project</label>
            </mat-slide-toggle>
            <div class="label">If this is an onging project the estimated round will be removed.</div>
          </div>
                          
        </div>
      </div>


      <div class="row mb-3" *ngIf="form.controls.ongoing.value == false">
        <div class="col-sm-3">
          <mat-form-field class="w-100">
            <input (change)="generateEstimatedDates()" matInput type="number" name="title" name="est_round" placeholder="Estimated Rounds" formControlName="est_round" required >
          </mat-form-field>
        </div>
        <div class="col-sm-8">
          <button 
              mat-button 
              color="primary" 
              matTooltipPosition="right" 
              (click)="generateEstimatedDates(true)" 
              class="quick-add">
                <mat-icon>public</mat-icon> Distribute Evenly
            </button>
            <div class="label ml-3">Distribute estimated dates between Start and End dates</div>
        </div>
      </div>      
      
      <!-- <div class="" *ngIf="form.controls.est_round.value && form.controls.due.value">
        <div *ngFor="let estDate of estDates();let idx=index;" (click)="addEditDate(idx)" class="" >
          Round {{ idx+1 }}: {{ estDate | date: 'MMM d, y' : 'UTC' }} <span class="mat-sm"><mat-icon>edit</mat-icon></span> 
        </div>
        
      </div>

      <input (ngModel)="proxyDate"  matInput [matDatepicker]="picker2"  [ngModelOptions]="{standalone: true}">
      <mat-datepicker #picker2 ></mat-datepicker> -->
      <div *ngIf="form.controls.ongoing.value == false">
        <label class="mt-3">Estimated Project Rounds</label> 
        <div class="fieldset" *ngIf="form.controls.est_round.value && form.controls.due.value">        
          <div class="ml-4">          
            <div formArrayName="est_round_dates">
              <div class="" *ngFor="let estRndDatesForm of estRndDates.controls; let i = index">
                <div [formGroupName]="i" class=" w-100" >
                  <div class="form-row">
                    <div class="col-sm-6">
                      <mat-form-field class="w-100">
                        <input (dateInput)="modDate()" matInput name="date" [matDatepicker]="pickerl" placeholder="Round {{i+1}}" formControlName="date" >
                        <button 
                          mat-icon-button 
                          color="primary" 
                          matTooltip="Remove Estimate"
                          matTooltipPosition="right" 
                          (click)="removeEstRndDates(i)" 
                          class="quick-remove">
                            <mat-icon>remove</mat-icon>
                          </button>
                        <mat-datepicker-toggle matSuffix [for]="pickerl"></mat-datepicker-toggle>
                        <mat-datepicker #pickerl></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>              
                </div>
              </div>
              <div class="mb-3">
                <div class="mb-3">
                  <button 
                    mat-button 
                    color="primary" 
                    matTooltipPosition="right" 
                    (click)="addEstRndDates()" 
                    class="quick-add">
                      <mat-icon>add_box</mat-icon> Add Estimated Rounds
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      

      
      <div class="row mb-4" *ngIf="data[subject].past_due_dates; let past_dates">
        <div class="col-sm-12">
          <div class="mat-form-field">
            <label class="label">Past Due Dates:</label>
            <mat-chip-list #chipList aria-label="Past User Dates">
              <mat-chip 
                *ngFor="let pdate of past_dates;let idx=index;" >
                {{ pdate | date: 'MMM d, y' : 'UTC' }}
                <mat-icon matChipRemove (click)="removeExtDate(idx)" *ngIf="1==1">cancel</mat-icon>
              </mat-chip>
            </mat-chip-list>  
          </div>
        </div>
      </div>

      <div class="row mt-3" *ngIf="($brand | async); let brand">
        <div class="col-sm-12">
          <div class="form-group">
            <label class="mt-3">Project Team</label> 
            <!-- *ngIf="profile | userCan: 'manage brand'" -->
            <div>
              <button 
                
                class="mat-sm" 
                mat-icon-button 
                color="primary"
                (click)="manageUsers(brand)">
                  <mat-icon>group</mat-icon> Brand Team Manager
              </button>
            </div>
            <label class="label">Type an existing users name to add them to this project. If the user isn't associated with {{ brand.title }} add them with the Brand Manager above.  You can also add a new user to the platofmr with the "+" arrow below.</label>
          </div>
        </div>
        <div class="col-sm-12" >
          <users-select
            [users]="form.controls.users.value"
            title="Client Team"
            context="brands"
            autofill="true"
            [contextid]="brand.id"
            [userList]="form.controls.users.value"
            [fillproject]="form.controls.id.value"
            fillrole="client"
            [fillbrand]="brand.id"
            role="client"></users-select>
          </div>
        <div class="col-sm-12">
          <users-select
            [users]="form.controls.users.value"
            title="Internal Team"
            context="brands"
            autofill="true"
            [contextid]="brand.id"
            [userList]="form.controls.users.value"
            [fillproject]="form.controls.id.value"
            fillrole="employee"
            [fillbrand]="brand.id"
            role="employee"></users-select>
        </div>
      </div>
    </div>

    

    <mat-dialog-actions>
      <button mat-button (click)="close()">Cancel</button>
      <!-- Can optionally provide a result for the closing dialog. -->
      <button color="primary" [disabled]="form.invalid" mat-raised-button (click)="save()">Save</button>
      <button *ngIf="data.project.id" mat-button mat color="warn" (click)="delete()">Delete</button>
    </mat-dialog-actions>
  </div>
  </ng-container>
</div>