<div class="container" *ngIf="($expenses | async); let expenses; else noProjects">   

    <div class="block" *ngIf="($userProfile | async); let profile">
      <div class="block-title" *ngIf="vs.subject('expenses'); let subject">
        <div class="block-title-label"><mat-icon *ngIf="subject.icon" class="mr-2">{{ subject.icon }}</mat-icon>Payments</div>      
        <div class="block-title-toolbar">
          <button mat-button color="primary" (click)="edit()"><mat-icon>add</mat-icon>New Payment</button>
          &nbsp;
          <button mat-button [matMenuTriggerFor]="menu" color="primary"><mat-icon>sort</mat-icon>Actions</button>
          <mat-menu #menu="matMenu" class="menu-sm">
            <button mat-menu-item *ngIf="!expenses.INVOICES" (click)="trash()" >Delete</button>
            <button mat-menu-item *ngIf="!expenses.INVOICES" (click)="paid()" >Mark Paid</button>  
            <button mat-menu-item *ngIf="ioConnected && !expenses.INVOICES" (click)="printAll()">Print</button>          
          </mat-menu>
        </div>
      </div>

      

      <div class="block-content">

        <!-- TWO VIEW EXIST HERE BASED ON THIS SWITCH expenses.INVOICE -->
        

        <div *ngIf="expenses && expenses.data">

          <app-table
            mat-table
            #vaultTable
            matSort #sort="matSort"  
            [asyncFilters]="asyncFilters"
            [dataSource]="$expenses"   
            [pager]="expensespager"
            [columns]="displayedColumns">          

    
            <ng-container matColumnDef="title" >
              <th mat-header-cell *matHeaderCellDef> Title</th>
              <td mat-cell *matCellDef="let element" (click)="edit(element,'manage expense',expenses.INVOICES)" class="text-capitalize cursor" > 
                <ng-container *ngIf="element.title; else monthTitle"> {{element.title}} </ng-container>
                <ng-template #monthTitle> {{element.start | date: 'MMM'}} {{element.start | date: 'Y'}} Invoice</ng-template>
              </td>
            </ng-container>

            <ng-container matColumnDef="created">
              <th mat-header-cell *matHeaderCellDef> Date </th>
              <td mat-cell *matCellDef="let element" (click)="edit(element,'manage expense',expenses.INVOICES)" class="text-capitalize" > 

                <ng-container *ngIf="element?.total; else createdAt">
                  {{element.expenses[0].date | date}}
                </ng-container>

                <ng-template #createdAt>
                  {{element.created_at | date}}
                </ng-template>

              </td>
            </ng-container>

            <ng-container matColumnDef="brand" >
              <th mat-header-cell *matHeaderCellDef> Brand </th>
              <td mat-cell class="cap pr-3" (click)="edit(element,'manage expense',expenses.INVOICES)" *matCellDef="let element">
                <app-brand-logo *ngIf="element?.expenses[0]?.brand_id" [tooltip]="true" [bid]="element.expenses[0].brand_id"></app-brand-logo>
              </td>
            </ng-container>
            
            <ng-container matColumnDef="total">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Total </th>
              <td mat-cell *matCellDef="let element"> 
                <ng-container *ngIf="element?.total; else totalTS">
                  {{ element.total | currency }}
                </ng-container>
                <ng-template #totalTS>
                  {{ element.amount | currency }}
                </ng-template>
              </td>
            </ng-container>

            <!-- <ng-container matColumnDef="reimbursement">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Reimbursement </th>
                <td mat-cell *matCellDef="let element"> {{ element.reimbursement ? 'YES' : 'NO' }} </td>
            </ng-container> -->

            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
              <td mat-cell *matCellDef="let element">
                {{ element.expenses[0].type }}
              </td>
            </ng-container>       

            <ng-container matColumnDef="receipt">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Receipt </th>
              <td mat-cell *matCellDef="let element">
                <button mat-button (click)="view(element)" *ngIf="element.expenses[0].receipt; else noRe">Download</button>
                <ng-template #noRe>( No Receipt )</ng-template>
              </td>
            </ng-container>       
            
            <ng-container matColumnDef="invoice">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Invoice # </th>
              <td mat-cell *matCellDef="let element">
                  <span *ngIf="element?.invoice_number">#{{ element.invoice_number }}</span>
                  <span *ngIf="element?.total && invoiceName(element?.expenses[0]?.invoice_id); let invoice; else noNum">#{{ invoice.invoice_number }}</span>
                  <ng-template #noNum >
                    <span *ngIf="element.expenses[0]?.expensed_seperatly" matTooltip="This expense will be invoiced seperately from any projects, it will need an invoice added when ready">( Needs Invoice )</span>
                    <span *ngIf="element.expenses[0]?.brand_id == -1 " matTooltip="This doesn't require an invoice becuase it's a internal expense">--</span>
                    <span *ngIf="!element.expenses[0]?.expensed_seperatly && element.expenses[0]?.brand_id !== -1 && !element?.invoice_number" matTooltip="This expense will be invoiced via the project it's associated with">--</span>
                  </ng-template>
              </td>
            </ng-container>       

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
              <td mat-cell *matCellDef="let element"> 

                <ng-container *ngIf="element?.total; else statusTS">
                  <meta-display subject="expense" [item]="element" type="statuses" [id]="element.expenses[0].status"></meta-display>   
                </ng-container>

                <ng-template #statusTS>
                  <meta-display subject="expense" [item]="element" type="statuses" [id]="element.status"></meta-display>   
                </ng-template>

                <span *ngIf="element.deleted_at" class="dot-label sent mx-1">Deleted {{ element.deleted_at | date }}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="author">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Author </th>
              <td mat-cell class="cursor" *matCellDef="let element" (click)="edit(element,'manage expense',expenses.INVOICES)"> <user-avatar showFullName="true" [user]="element.user_id" ></user-avatar> </td>
            </ng-container>
          

            <ng-container matColumnDef="actions">
              <th mat-header-cell class="text-right" *matHeaderCellDef> Actions </th>
              <td mat-cell class="text-right" *matCellDef="let element">    
                <button (click)="edit(element,'manage expense',expenses.INVOICES)" class="mat-sm" mat-icon-button mat-button>
                  <mat-icon>edit</mat-icon>
                </button>
              </td>
            </ng-container>
  
            <tr  mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns; "></tr>
  
          </app-table>
            
          <ng-template #noneYet>
            <mat-spinner color="primary" class="mx-auto loader"></mat-spinner>
            <p>No {{ subject }} found</p>
          </ng-template> 
  
        </div>    
      </div>
    </div>
  </div>
  <ng-template #noneYet>
    <p>No {{ subject }} found</p>
  </ng-template>
  <ng-template #noProjects>
    <mat-spinner color="primary" class="mx-auto loader"></mat-spinner>
  </ng-template>
                