<div class="container-fluid" *ngIf="($userProfile | async); let profile">    
    <div>
        <div class="col-sm-12" *ngIf="($brandList | async); let brands">
            <div class="block" *ngIf="lang">
                
                <div class="container">
                    <button routerLink="/dashboard/expenses" [state]="state" mat-button>
                        <mat-icon>arrow_back</mat-icon> Back
                    </button>
                    <div class="block-title">                        
                        <div class="subhead-large" for="">
                        <span *ngIf="!form.value.id">Add</span>
                        <span *ngIf="form.value.id">Edit</span>
                        <span class="cap"> {{ state.label }}</span> Report
                        </div>                     
                    </div>

                    <app-alert></app-alert>

                    <!-- Iteration 3 -->
                    <div  [formGroup]="form">
                            <input type="hidden"  matinput formControlName="category">
                            <div class="row form-group">                                
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <mat-form-field class="w-100">
                                            <input matInput name="title" [placeholder]="lang.expense.new.title.field.label" formControlName="title">
                                        </mat-form-field>
                                    </div>
                                </div>                                                              
                                <div class="col-sm-12">
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <div class="form-group">
                                                <mat-form-field class="w-100">
                                                    <mat-select (selectionChange)="modifyExpensedSeperate($event)" [placeholder]="lang.expense.new.organize.brand.field.label" formControlName="brand_id">
                                                        <mat-option [value]="-1" >
                                                            <app-brand-logo [brand]="profile.company"></app-brand-logo> &nbsp; {{profile.company.name}}
                                                        </mat-option>
                                                        <mat-option *ngFor="let brand of brands | keyvalue" [value]="brand.value.id">
                                                            <app-brand-logo [brand]="brand.value"></app-brand-logo> &nbsp; {{brand.value.name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <div class="label label-snug-mat">{{ lang.expense.new.organize.brand.field.hint }}</div>
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div class="form-group">
                                                <div *ngIf="form.value.brand_id; let bid">
                                                    <div *ngIf="bid != -1">
                                                        <mat-form-field class="w-100" *ngIf="findBrandProjects(brands,bid); let projects">
                                                            <mat-select [placeholder]="lang.expense.new.organize.project.field.label" formControlName="project_id">
                                                                <mat-option value="">- None -</mat-option>
                                                                <mat-option *ngFor="let project of projects | keyvalue" [value]="project.value.id">
                                                                    {{project.value.name}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                        <div class="label label-snug-mat">{{ lang.expense.new.organize.project.field.hint }}</div>                                 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-2">
                                            <div class="form-group">
                                                <div *ngIf="form.value.brand_id; let bid">
                                                    <ng-container *ngIf="avalibleInvoices(bid); let invoices">
                                                        <ng-container *ngIf="bid !== -1">
                                                            <mat-form-field class="w-100" >
                                                                
                                                                <mat-select [placeholder]="lang.expense.new.organize.invoice.field.label" formControlName="invoice_id">
                                                                    <mat-option>- None Available -</mat-option>
                                                                    <mat-option *ngFor="let invoice of invoices" [value]="invoice.id">
                                                                        #{{invoice.invoice_number}} - {{invoice.title}}
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                            <div class="label label-snug-mat">{{ lang.expense.new.organize.invoice.field.hint }}</div>
                                                            <div>
                                                                <mat-checkbox class="label" [ngModelOptions]="{standalone: true}" [(ngModel)]="includeHC">Include Holding Company</mat-checkbox>
                                                              </div>
                                                        </ng-container>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>  
                                </div>
                                <div class="col-sm-12" *ngIf="state.type !== 'purchase'">
                                    <div class="">
                                        <mat-checkbox formControlName="expensed_seperatly">Expensed Seperate from project</mat-checkbox>
                                        <div class="label ">This will remove the expense from being tracked on the project budget</div>
                                    </div>
                                </div>
                            </div>
                        
                            <div formArrayName="expense_group"    
                                class="mb-3"                              
                                *ngFor="let expense of formExpenses; let i = index"
                                (click)="toggle(i)">
                                <div class="" [formGroup]="expense">                                                                        
                                        <div class="col-sm-8 block-content">                                            
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-sm-6">
                                                        <div class="form-group">
                                                            <mat-form-field class="w-100">
                                                                <input matInput name="from" [placeholder]="lang.expense.new.from.field.label" formControlName="from">
                                                            </mat-form-field>
                                                            <div class="label label-snug-mat">{{ lang.expense.new.from.field.hint }}</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <div class="form-group">
                                                            <mat-form-field class="w-100">
                                                                <mat-select  [placeholder]="lang.expense.new.category.field.label" formControlName="type">
                                                                    <mat-option *ngFor="let type of types" [value]="type.value">
                                                                            {{type.title}}
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                            <div class="label label-snug-mat">{{ lang.expense.new.category.field.hint }}</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <div class="form-group">
                                                            <mat-form-field class="w-100">
                                                                <input matInput [matDatepicker]="picker" [placeholder]="lang.expense.new.date.field.label" formControlName="date">
                                                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                                <mat-datepicker #picker></mat-datepicker>                                        
                                                            </mat-form-field>
                                                            <div class="label label-snug-mat">{{ lang.expense.new.date.field.hint }}</div>
                                                        </div> 
                                                    </div>          
                                                    <div class="col-sm-6">
                                                        <div class="form-group">
                                                            <mat-form-field class="w-100">
                                                                <span matPrefix>$ &nbsp;</span>
                                                                <input 
                                                                    matInput 
                                                                    [placeholder]="lang.expense.new.total.field.label" 
                                                                    autocomplete="off" 
                                                                    formControlName="total" 
                                                                    mask="separator.2"
                                                                    thousandSeparator="," 
                                                                    required>
                                                            </mat-form-field>
                                                            <div class="label label-snug-mat">{{ lang.expense.new.total.field.hint }}</div>
                                                        </div>
                                                    </div>

                                                    

                                                    <div class="col-sm-8">
                                                        <mat-form-field class="w-100">
                                                            <textarea matInput [placeholder]="lang.expense.new.notes.field.label" formControlName="notes"></textarea>
                                                        </mat-form-field>
                                                        <div class="label">{{ lang.expense.new.notes.field.hint }}</div>
                                                    </div>

                                                    <div class="col-sm-4">
                                                        <div class="label">{{ lang.expense.new.receipt.field.label }}</div>
                                                        <app-file-upload 
                                                            type="receipt"
                                                            class="might-be-trans"
                                                            [text]="lang.expense.new.receipt.field.action"
                                                            (uploadComplete)="uploadReceipt($event,i)" 
                                                            [_file]="form.value.expense_group[i].receipt">
                                                        </app-file-upload>
                                                        <div class="label">{{ lang.expense.new.receipt.field.hint }}</div>
                                                    </div>                                                    
                                                </div>                                                                                    
                                            </div>
                                            
                                            
                                        </div>                                    
                                </div>
                            </div>
                    </div>

                    <div class="form-group ">
                        <button mat-button color="primary" (click)="add()"><mat-icon>add</mat-icon> Add another Item</button>
                    </div>

                    <!-- Iteration 2 -->
                    

                   


                    <div class="my-4">                       
                        <button [disabled]="form.invalid" mat-raised-button color="primary" (click)="save()">
                            <ng-container *ngIf="!form.value.id">Create</ng-container> <ng-container *ngIf="form.value.id">Save</ng-container> {{ state.label }} Report
                        </button>
                    </div>

                    <!-- <div class="mb-3">
                        <button  mat-stroked-button color="primary" (click)="addExpense(null,'prepend')">
                            <mat-icon>add</mat-icon>Expense
                        </button>
                    </div> -->

                    
                    <!-- Iteration 1 -->
                    <!-- <div class="form-group" [formGroup]="form">
                        <div formArrayName="expenses" 
                            class="form-row expense" 
                            *ngFor="let expense of formExpenses; let i = index"
                            (click)="toggle(i)"
                            [ngClass]="{'closed': form.get('expenses').value[i].id !== '' && getCtxFormVal(i,'_open') == false }">
                            <div class="preview row m-0 vault-table">
                                <div class="col-sm-3 td">
                                    {{ getCtxFormVal(i,'title')  }}
                                </div>
                                <div class="col-sm-1 td" >
                                    {{ getCtxFormVal(i,'date') | date }}
                                </div>
                                <div class="col-sm-2 td" >
                                    <span class="label mr-2"></span> <meta-display subject="expense" [item]="getCtxFormVal(i)" type="statuses" [id]="getCtxFormVal(i,'status')"></meta-display>
                                </div>    
                                <div class="col-sm-1 td">
                                    <span *ngIf="invoiceName(getCtxFormVal(i,'invoice_id')); let invoice; else noinvoice">
                                        #{{ invoice.invoice_number }}
                                    </span>
                                    <ng-template #noinvoice>
                                        -
                                    </ng-template>
                                </div>          
                                <div class="col-sm-1 td">
                                    {{ getCtxFormVal(i,'total') | currency }}
                                </div>         
                                <div class="col-sm-2 td" *ngIf="getCtxFormVal(i,'payment_ref');let pref">
                                    Payment Ref: {{ pref  }}
                                </div>         
                            </div>
                            <div  class="exist-hide row pl-3 m-0 w-100 rel" [formGroupName]="i">                               
                                <div class="remove-ico " *ngIf="!form.get('expenses').value[i].id">
                                    <button                                                         
                                        mat-icon-button 
                                        color="primary" 
                                        matTooltip="Remove"
                                        matTooltipPosition="right" 
                                        (click)="removeline(i)" 
                                        class="quick-remove">
                                            <mat-icon>remove</mat-icon>
                                    </button>
                                </div>
                                <div class="col-sm-9">
                                    <div class="row">
                                        <div class="col-sm-5">              
                                            <mat-form-field class="w-100">
                                                <input matInput name="title" placeholder="Title" formControlName="title" required>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-sm-3  d-flex align-items-center">
                                            <mat-checkbox formControlName="reimbursement">Reimbursement Needed</mat-checkbox>
                                        </div>
                                        <div class="col-sm-2">
                                            <mat-form-field class="w-100">
                                                <mat-select  placeholder="Type" formControlName="type">
                                                    <mat-option *ngFor="let type of types" [value]="type.value">
                                                        {{type.title}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-sm-2 ">
                                            <mat-form-field class="w-100">
                                                <input matInput [matDatepicker]="picker" placeholder="Date of Expense" formControlName="date">
                                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                                <mat-error *ngIf="form.get('expenses').value[i].date.invalid">Enter a valid date</mat-error>
                                                
                                                </mat-form-field>
                                        </div>
                                        <div class="col-sm-6">
                                            <mat-form-field class="w-100">
                                                <input matInput name="from" placeholder="Purchase From" formControlName="from">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-sm-4 ">
                                            <mat-form-field class="w-100">
                                                <span matPrefix>$ &nbsp;</span>
                                                <input matInput placeholder="Total" autocomplete="off" formControlName="total" mask="separator.2"
                                                thousandSeparator="," required>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-sm-3" style="overflow: hidden;">
                                            <div class="d-flex">
                                                
                                                <mat-form-field class="">
                                                    <mat-select  placeholder="Brand" formControlName="brand_id">
                                                        <mat-option [value]="-1" >
                                                            <app-brand-logo [brand]="profile.company"></app-brand-logo> &nbsp; {{profile.company.name}}
                                                        </mat-option>
                                                        <mat-option *ngFor="let brand of brands | keyvalue" [value]="brand.value.id">
                                                            <app-brand-logo [brand]="brand.value"></app-brand-logo> &nbsp; {{brand.value.name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div *ngIf="form.get('expenses').value[i].brand_id; let bid">
                                                <div *ngIf="bid != -1">
                                                    <mat-form-field class="w-100" *ngIf="findBrandProjects(brands,bid); let projects">
                                                        <mat-select  placeholder="Project" formControlName="project_id">
                                                            <mat-option value="">- None -</mat-option>
                                                            <mat-option *ngFor="let project of projects | keyvalue" [value]="project.value.id">
                                                                {{project.value.name}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>                                      
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-2" >
                                            <div *ngIf="form.get('expenses').value[i].brand_id; let bid">
                                                <ng-container *ngIf="avalibleInvoices(bid); let invoices">
                                                    <mat-form-field class="w-100" *ngIf="bid !== -1">
                                                        
                                                        <mat-select  placeholder="Invoice#" formControlName="invoice_id">
                                                            <mat-option>- None Available -</mat-option>
                                                            <mat-option *ngFor="let invoice of invoices" [value]="invoice.id">
                                                                {{invoice.title}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </ng-container>
                                            </div>
                                        </div>                                        
                                        
                                        <div class="col-sm-8">
                                            <mat-form-field class="w-100">
                                                <textarea matInput placeholder="Notes" formControlName="notes" required></textarea>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-3 ">
                                    <div class="label">Receipt</div>
                                    <app-file-upload 
                                        type="receipt"
                                        class="might-be-trans"
                                        text="Upload Receipt"
                                        (uploadComplete)="uploadReceipt($event,i)" 
                                        [_file]="form.get('expenses').value[i].receipt">
                                    </app-file-upload>
                                    <div class="py-3">
                                        <button class="mr-1" (click)="save($event,expense,i)" color="accent" mat-raised-button>
                                            <span *ngIf="form.get('expenses').value[i].id">Save</span>
                                            <span *ngIf="!form.get('expenses').value[i].id">Create</span>
                                        </button>
                                        <button class="mr-1" *ngIf="form.get('expenses').value[i].id" (click)="collapse($event,i)" mat-button>Cancel</button>                                        
                                        <button *ngIf="form.get('expenses').value[i].id" class="mr-1" (click)="delete($event,form.get('expenses').value[i])" color="warn" mat-button>Delete</button>
                                    </div>
                                </div>
                                
                                
                                
                                
                                                                                                                                                                                         
                            </div>
                        </div>
                        
                
                    </div> -->
                </div>
            </div>
        </div>
    </div>    
</div>