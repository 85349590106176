import { Component, OnInit, Output, Input } from '@angular/core';
import { UserModel } from "../../_models/subjects";
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmComponent } from 'src/app/_component/dialogs/confirm/confirm.component';
import { first } from 'rxjs/operators';
import { filterUsersByPipe } from '../../pipes.pipes';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  providers: [filterUsersByPipe]
})
export class UsersComponent implements OnInit {


  @Input() users: UserModel[];
  @Input() role: string;
  @Input() showName: boolean;
  @Input() showFullName: boolean;
  @Input() position: boolean = false;  
  @Input() phone: boolean = false;
  @Input() css = null;
  @Input() wrapitup: boolean = true;
  @Input() vertical: boolean = false;
  @Input() parentCss:string = '';
  extra: number;
  max: number = 3;
  constructor(
    public dialog: MatDialog,
    private userIs: filterUsersByPipe
  ) {

  }

  ngOnInit() {  
    this.users = this.userIs.transform(this.users, this.role);
    if(!this.wrapitup) return this.max = 99999;
    if((this.users.length)>this.max){
      this.extra = (this.users.length)-this.max;
    }else{
      this.extra = null;
    }
  }

  showUsers(){
    const dialogRef = this.dialog.open(ConfirmComponent, {
      data: {
        users: this.users,
        type: "view-users",
        title: `Users`,
        content: "",
      },
    });
    dialogRef.afterClosed().pipe(first()).subscribe();
  }

}
