import { Component, Input, OnInit } from '@angular/core';
import {  
  BrandModel,
} from "../../_models/subjects";

@Component({
  selector: 'app-brand-logos',
  templateUrl: './brand-logos.component.html',
  styleUrls: ['./brand-logos.component.scss']
})
export class BrandLogosComponent implements OnInit {
  @Input() brands:BrandModel[];
  blengths:number = 0;
  constructor() { }

  ngOnInit() {
    this.blengths = this.brands.length;
  }

}
