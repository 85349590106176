<div>
  <div *ngIf="($userProfile | async); let profile">

    <div class="float-right cursor" (click)="close()"><mat-icon>close</mat-icon></div>

    <app-media-manager
      subject="project"
      [model]="project"
      mediaLibrary="true"
      color="white"
      (assetsAdded)="uploadProjectAsset($event)"
    ></app-media-manager>

    <div class="hr white my-3"></div>

    <div *ngIf="( $assets | async); let assets">
      <app-asset [currentUser]="profile" class="w-100" *ngFor="let asset of assets" [asset]="asset"></app-asset>  
    </div>
    
  </div>
</div>