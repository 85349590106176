import { Component, OnInit } from '@angular/core';
import { MatLegacySnackBarRef as MatSnackBarRef } from '@angular/material/legacy-snack-bar';
import { ApiService } from 'src/app/_services/api.service';
import { FileuploadprogressService } from 'src/app/_services/fileuploadprogress.service';

@Component({
  selector: 'app-fileuploadprogress',
  templateUrl: './fileuploadprogress.component.html',
  styleUrls: ['./fileuploadprogress.component.scss']
})
export class FileuploadprogressComponent implements OnInit {
  $progress: any;
  constructor(
    private fileuploadprogressservice: FileuploadprogressService,
    private snackRef: MatSnackBarRef<FileuploadprogressComponent>
  ) { }

  ngOnInit(): void {
    this.$progress = this.fileuploadprogressservice.uploadProgress
    console.log(this.$progress)
  }

  exit() {
    this.snackRef.dismiss();
  }


}
