import { Component, OnInit, Inject, ViewChildren, QueryList, Output } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { BrandService } from '../../../_services/brand.service';
import { ProjectService } from '../../../_services/project.service';
import { VelvetysoftService } from '../../../_services/velvetysoft.service';
import { Settings, ProjectModel, BrandModel, UserProfileModel } from "../../../_models/subjects";
import { environment } from '../../../../environments/environment';
import { Observable, ReplaySubject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ProposalModel } from "../../../_models/subjects";
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { ModalLibraryService } from '../../../_services/modal-library.service';
import {MatAccordion} from '@angular/material/expansion';
import { parse } from 'querystring';
import { MultiPhaseTypeRequired } from 'src/app/_validators/validators';
import { OpenaiService } from '../../../_services/openai.service'
import { Router } from '@angular/router';
import { ConfirmComponent } from '../../dialogs/confirm/confirm.component';
import { UserService } from 'src/app/_services/user.service';
import { filterUserCanByPipe } from 'src/app/pipes.pipes';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';


@Component({
  selector: 'app-add-proposal',
  templateUrl: './add-proposal.component.html',
  styleUrls: ['./add-proposal.component.scss'],
  providers: [
    filterUserCanByPipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ]
})
export class AddProposalComponent implements OnInit {  
  @ViewChildren("phasesAccordian") phasesAccordian: QueryList<MatAccordion>;
  public Editor = ClassicEditor;
  public $selectedBrandID: ReplaySubject<number> = new ReplaySubject(); 
  isLibraryOpen: boolean = false;
  editMultiple: boolean = false;
  $brandList: Observable<BrandModel[]>;
  $proposals: Observable<ProposalModel[]>;
  $userProfile: Observable<UserProfileModel>;
  hasNew: boolean = false;  
  previewTotal: number = 0;

  form: UntypedFormGroup;
  items: UntypedFormArray;
  error: any = false;
  settings: Settings;
  proposalNumLocked = true;
  proposalIDS = [];
  
  panels = ['New Proposal'];
  lockProject: boolean|null = null;
  panelSelected = new UntypedFormControl(0);
  subject:string = 'proposal';
  activeBID = null;  
  activePID = null
    
  phases: UntypedFormArray;
  //
  bidPhase = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddProposalComponent>,
    public dialog: MatDialog,    
    private brandService: BrandService,
    private projectService: ProjectService,
    private velvetysoft: VelvetysoftService,
    private fb: UntypedFormBuilder,
    public modalLibraryService: ModalLibraryService,
    private openaiService: OpenaiService,
    private router: Router,
    private userService: UserService,
    private filterUserCanByPipe: filterUserCanByPipe
  ) {
    this.form = fb.group({
      items: fb.array([])
    })
    //console.log(this.data.proposalService.ctx_dependency.id,'active')
  }

  ngOnInit() {    
    this.$brandList = this.projectService.projectTree;
    this.activeBID = this.data.proposalService?.ctx_dependency?.id;
    this.$userProfile = this.userService.userProfile();

    //CONTEXT OF OPENING - Project VIew and the id
    if(this.data?.options?.pid) this.activePID = [+this.data?.options?.pid];
    
    //SET DEFAULT OR EXISTING VALUES    
    if (Array.isArray(this.data[this.subject])){
      this.editMultiple = true;
      this.panels = [];
      this.proposalIDS = [];
      this.data[this.subject].map(item =>{
        this.addForm(item);
        this.panels.push(item.title);
        this.proposalIDS.push(item.id);
      })
    }else{
      this.fixSingleToMulti(this.form, this.items, this.data[this.subject]);
      this.addForm(this.data[this.subject]);
      this.proposalIDS.push(this.data[this.subject].id);
      if (!this.data[this.subject].id){
        this.hasNew = true;
      }else{
        this.panels[0] = "Edit";
      }
    }
    
    //console.log(this.thisform(0).value); 
    this.$proposals = this.data[`${this.subject}Service`].all;
    this.isLibraryOpen = false;//(this.data.isLibraryOpen) ? this.data.isLibraryOpen : false;    
      
  }

  addProposal(pidCtx=null){
    this.dialogRef.close();
    let data = { action: 'addproposal' }
    if (pidCtx) data['pidCtx'] = pidCtx;
    this.router.navigate(['/dashboard/proposals'],{ state: data })
  }

  editProposal(proposal){
    this.dialogRef.close();
    this.router.navigate(['/dashboard/proposals'],{ state: { action: 'editproposal', proposal: proposal } })
  }
    
  phaseTotal(subject, pid){
    return this.data.proposalService.phaseTotal(subject, pid);    
  }

  get formItems() {
    return (this.form.get("items") as UntypedFormArray).controls;
  }

  formPhases(index){
    return (this.thisform(index).get("phases") as UntypedFormArray).controls;    
  }

  formPhase(indexf,indexp){
    return (this.thisform(indexf).get("phases") as UntypedFormArray).controls[indexp] as UntypedFormGroup;    
    //return (this.form.get("items") as FormArray).controls[index] as FormGroup;
  }

  getProjectedCost(idx){
    let phases = this.formPhases(idx);
    let projectedCost:number = 0; 
    phases.forEach( (phase, i) =>{
      let _cost = parseInt(phase.value.total);
      if(_cost) projectedCost = (parseInt(phase.value.total) + projectedCost);       
    });    
    return projectedCost;
  }

  addedExistingProject(event, formIndex,i, brand) {
    let project = brand.projects.find((item) => item.id == event);
    if(project){
      this.formPhase(formIndex,i).controls.title.patchValue(project.name);
      this.formPhase(formIndex,i).controls.type.patchValue(project.type);
    }
    //console.log(event, formIndex,i, project)
  }

  getTotalPhases(idx, _self){
    _self.previewTotal = 0;
    _self.formPhases(idx).forEach((item) => {      
     if(item.value.total) _self.previewTotal = _self.previewTotal + parseFloat(item.value.total);
    });    
    //console.log(_self.previewTotal)
  }

  newPhase(){
    let _form = this.fb.group({      
      id: [null],
      type: [""],
      title: [""],      
      content: [""],
      deliverables: [""],      
      total: [""],
      project_id: [""],
      brand_id: [""],
      weight: [""],
      showOptions: [""]
    });

    _form.controls.brand_id.patchValue(this.activeBID);
    return _form;
    // ,{
    //   validators: MultiPhaseTypeRequired('type',this.multipleProjects) // <-----
    // });
  }

  createPhases(count=1) {
    return Array.from({ length: count }, (_, i) => this.newPhase());
  }

  attachApproval(imagePath,idx) {
    this.thisform(idx).patchValue({ approved_doc: imagePath });
  }

  deletePhase(id,formIndex,idx){
    this.data.proposalService.deletePhase(id)
    .pipe(first())
    .subscribe(res=> {
      if(res){
        this.removePhase(formIndex,idx);        
      }
    }); 
  }

  removePhase(formIndex,idx){
    (this.thisform(formIndex).get("phases") as UntypedFormArray).removeAt(idx);
  }


  addPhase(idx) {
    this.phases = this.thisform(idx).get("phases") as UntypedFormArray; 
    this.phases.push(this.newPhase());
  }

  // generatePhases() {
  //   //FORCE CHANGE DETECTION
  //   //CREATE NEW FORM ENTIRELY
  //   this.form = this.fb.group({
  //     phases: this.fb.array([this.newPhase()])
  //   });        
  // }

  thisform(index) {
    return (this.form.get("items") as UntypedFormArray).controls[index] as UntypedFormGroup;
  }

  cloneFrame(index){
    let _copyForm = this.thisform(index).value;
    delete (_copyForm.proposal_number);
    delete (_copyForm.id);
    delete (_copyForm.sent_at);
    delete (_copyForm.approved_at);
    delete (_copyForm.approved_doc );
    _copyForm.status = "draft";
    
    //(this.thisform(0).get("phases") as FormArray).controls.push()
     //this.createPhases(_copyForm.phases.length-1)
     //@ts-ignore
    this.thisform(0).get("phases").controls = this.createPhases(_copyForm.phases.length);

    //BRAND ID CHECKSSSSZZZZZ
    _copyForm.phases.map(phase => {
      if(!phase.brand_id) phase.brand_id = this.activeBID
    });
    
    this.thisform(0).patchValue(_copyForm)    
    this.panelSelected.setValue(0);    
    this.closeFrame(index);
  }

  closeFrame(index){
    this.items = this.form.get("items") as UntypedFormArray;
    this.items.removeAt(index);    
    this.panels = this.panels.filter( (item,i) => i!==index);
  }

  alog(t){
    console.log(t)
  }
  

  

  prompt(form,field){
    let prompt = form.value[field];
    this.openaiService.openPrompt(prompt)
    .subscribe(result => {
        let data = []
        data[field] = result;
        form.patchValue(data);
    });
  }


  addForm(proposal,disable:boolean = false){     
    

    let _phases = (proposal && proposal.phases && proposal.phases.length > 0)
      ? proposal.phases.length
      : 0;


    let _form = this.fb.group({
      id: [null],
      title: ["", Validators.required],
      proposal_number: { value: "" },
      due_date: ["", Validators.required],
      type: ["", Validators.required],
      description: [""],
      deliverables: [""],
      content: [""],
      status: ["", Validators.required],
      total: ["", Validators.required],
      project_id: [""],
      brand_id: [""],
      approved_doc: [""],
      approved_at: [""],
      sent_at: [""],
      _createProject: [""],
      phases: this.fb.array(this.createPhases(_phases))
    });
    

    _form.patchValue(proposal);            
    if (disable) _form.disable();
    this.items = this.form.get("items") as UntypedFormArray;
    this.items.push(_form);
   
    //IF NEW ( based on title chekc ) will defaults
    if (!proposal.id) {
      this.$userProfile.pipe(first()).subscribe(user => {
        let allowed = this.filterUserCanByPipe.transform(user, 'view invoice & proposal');
        if(allowed) this.data.proposalService.getNewNumber().subscribe(num => {
          _form.controls.proposal_number.patchValue(num);
        });
      })
      
      _form.controls.brand_id.patchValue(this.activeBID);
      _form.controls.project_id.patchValue(this.activePID);
      //console.log(this.activePID)
      _form.controls.status.patchValue("draft");
      _form.controls.total.patchValue("00.00");
    }else{
      //if( !proposal.brand_id ) this.multipleBrands = true;
      //if( proposal.phases.length > 1 ) this.multipleProjects = true;
      //if(this.data.proposalService.isMultiBrand(proposal)) this.multipleBrands = true;      
    }

    //SETUP OTHERSTUFF
        
    this.settings = this.velvetysoft.settings[this.subject];
  }

  fixSingleToMulti(form, items, proposal){
    //console.log('added project',form, items, proposal.associated_projects);
    //If proposal has phases and phases has not brand_id but proposal has brand_id add proposal.brand_id to phases
    if(proposal.phases && proposal.phases.length > 0 && !proposal.phases[0].brand_id && proposal.brand_id){
      proposal.phases.map(phase => {
        phase.brand_id = proposal.brand_id;
        console.log('FIX ENACTED BRAND:', proposal);
        
        //if the array proposal.project_id has a value add it to the phase
        if(proposal?.associated_projects?.length > 0 && !phase.project_id){
          console.log('FIX ENACTED PROJECT:', proposal);
          phase.project_id = proposal.associated_projects[0];        
        }

        //if(proposal.project_id ) phase.project_id = proposal.project_id;
        if(!phase.type) phase.type = proposal.type;
      });
    }
  }



  //   // if(form.get("brand_id").value){      
  //   //   console.log('Move brand from invoice to project',form.get("brand_id").value)
  //   // }
  // }

  //CHECK IF THIS IS MULTIPLE BRAND
  // isMultiBrand(idx){
  //     let exists = this.thisform(idx).controls.id.value;
  //     let hasbrand = this.thisform(idx).controls.brand_id.value;
  //     console.log(exists,hasbrand)
  //     return (exists && !hasbrand ) ? true : false      
  // }



  lockProjects(idx){
    if(this.lockProject !== null) return this.lockProject
    let form = this.thisform(idx).value

    this.lockProject = (form.phases.filter(phase => phase.project_id !== "" && phase.project_id !== null  ).length > 0)
    return this.lockProject;
  }

  // dropped(event: CdkDragDrop<string[]>,idx) {
  //   let phases = this.formPhases(idx);
  //   moveItemInArray(
  //      phases,
  //      event.previousIndex,
  //      event.currentIndex
  //     );
  //   }

    drop(event: CdkDragDrop<string[]>,idx) {
      //console.log(event)
      let phases = this.formPhases(idx);      
      moveItemInArray(phases, event.previousIndex, event.currentIndex);
      phases.forEach((phase,i) => {
        phase.patchValue({weight:i});
      });
      //console.log(this.form.value)
    }



  

  findBrand(brands, id, pid = null) {
    return this.projectService.selectTree(brands, id, pid);
  }
  // getCtxFormVal(index: number, field: string) {
  //   return this.form.get('timesheets').value[index][field];
  // }


  loadLibrary(proposal){
    //console.log(proposal);
    this.editMultiple = false
    this.addForm(proposal,true);
    this.panels.push(proposal.title)
    this.panelSelected.setValue(this.panels.length - 1);
  }

  toggleProposalNumber() {
    //console.log("switch");
    this.proposalNumLocked = false;
  }


  navTo(url){  
    this.router.navigate([`${url}`]);
    this.close();
  }

  view(proposal){
    this.data[`${this.subject}Service`].download(proposal,true) 
  }

  download(proposal: ProposalModel) {
    this.data[`${this.subject}Service`].download(proposal)    
  }

  save(formIndex) {
    let data = { status: true};
    data[this.subject] = this.thisform(formIndex).value;
    //console.log(data[this.subject]);
    this.dialogRef.close(data);
  }

  send(proposal: ProposalModel){
    this.data[`${this.subject}Service`].sendEmail(proposal);
  }

  approved(proposal: ProposalModel){
    const dialogRef = this.dialog.open(ConfirmComponent, {
      data: {        
        type: "proposalapproval",
        title: `Add Approval Document`,
        content: `Upload client approval for ${proposal.title}`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      
      if(result && result.status == 'approved'){
        proposal.approved_doc = result.data;       
        this.data[`${this.subject}Service`].approved(proposal)
        .pipe(first())
        .subscribe(()=>this.dialogRef.close());
      }
    });
  }

  downloadApproved(name){
    this.data[`${this.subject}Service`].downloadApproved(name);
  }

  delete() {
    this.dialogRef.close({ status: "delete" });
  }

  close() {
    this.dialogRef.close();
  }

  // get form(){
  //   return this.form
  // }

}
