<div class="vault-modal">
  <div *ngIf="( data.options?.$profile | async ); let profile">
    <h1 mat-dialog-title>
      <ng-container *ngIf="formUser.controls.id.value; else newUser">
        Edit User
      </ng-container>
      <ng-template #newUser>
        Add User
      </ng-template> 
    </h1>
    <app-alert></app-alert>
    <div mat-dialog-content *ngIf="( $brandList | async ); let brands">

      <ng-container *ngIf="formUser.controls.id.value">
        <button mat-stroked-button (click)="resetPassEmail()" color="primary">Reset Password via Email</button>
        &nbsp;
        <button mat-stroked-button (click)="resetPass()" color="primary">Reset Password Manually</button>
      </ng-container>

      <mat-vertical-stepper  #stepper>
        <mat-step [stepControl]="formUser">
          <form [formGroup]="formUser">
            <ng-template matStepLabel>User Details </ng-template>
            <div class="row">
              <div class="col-sm-4">
                <div class="form-group">
                  <app-file-upload (uploadComplete)="userPhoto($event)" type="profile" [_file]="formUser.controls.photo.value">
                  </app-file-upload>
                </div>                
              </div>
              <div class="col-sm-6">
                <mat-form-field class="w-100">
                  <input matInput placeholder="First Name" formControlName="name" required>
                </mat-form-field>
                <mat-form-field class="w-100">
                  <input matInput placeholder="Last Name" formControlName="last_name" required>
                </mat-form-field>
              </div>
              <div class="col-sm-6">
                <mat-form-field class="w-100">
                  <input matInput placeholder="Email" formControlName="email" required>
                </mat-form-field>
              </div>
              <div class="col-sm-3">
                <div *ngIf="settings.roles; let roles">
                  <mat-form-field class="w-100">
                    <mat-select placeholder="Role" formControlName="roles">
                      <mat-option class="cap" 
                        [disabled]="(role.name == 'admin' ? true : false)" 
                        *ngFor="let role of roles" [value]="role.id">
                        {{role.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>          
            <div class="row">
              <div class="col-sm-3">
                <mat-form-field class="w-100">            
                  <input matInput name="dob" formControlName="dob" [matDatepicker]="picker" placeholder="DOB">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>            
                </mat-form-field>      
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <mat-form-field class="w-100">
                    <input matInput placeholder="Phone" type="text" name="phone" formControlName="phone" />
                  </mat-form-field>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <mat-form-field class="w-100">
                    <input matInput placeholder="Position" type="text" name="position" formControlName="position" />
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <mat-form-field class="w-100" >
                  <mat-select placeholder="Brand" formControlName="brands" multiple>
                    <mat-option *ngFor="let brand of brands" [value]="brand.id">
                      <app-brand-logo [brand]="brand"></app-brand-logo> &nbsp; {{brand.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>                            
              <div class="col-sm-6" *ngIf="!formUser.controls.id.value">
                <mat-checkbox color="primary" formControlName="send_notification">Send Email Notification
                </mat-checkbox> &nbsp;                
              </div>
            </div>

            <div class="mb-3">
              <label class="mat-dialog-title mr-3 pl-0 mb-1 mt-3" for="">Address</label>
              <app-addresses 
                [currentAddresses]="data?.user?.addresses" 
                [form]="formUser"
                [hideOptions]="true"></app-addresses> 
            </div>

            <div>
              <button matStepperNext color="primary" [disabled]="formUser.invalid" mat-raised-button >Next</button>
            </div>
          </form>
        </mat-step>
        <!-- STEP 2 -->
        <mat-step [stepControl]="formProjects">
          <form [formGroup]="formProjects">
            <ng-template matStepLabel>Projects </ng-template>
            <div class="row">
              <div class="col-sm-6">
                <div *ngIf="formUser.controls.brands.value.length > 0" >
                  <div *ngFor="let bid of formUser.controls.brands.value;let pidx=index;">
                    <div *ngIf="findBrand(brands,bid); let brand">
                      <mat-form-field class="w-100" *ngIf="brand.projects.length">
                        <mat-select                           
                          placeholder="Projects for {{ brand.name }}" 
                          [(ngModel)]="projects[bid]" 
                          [ngModelOptions]="{standalone: true}"
                          multiple>
                          <mat-option *ngFor="let project of brand.projects" [value]="project.id">
                            <span>{{project.name}}</span>
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>             
            </div>
            <div>
              <button mat-button matStepperPrevious>Back</button>
              <button mat-button matStepperNext>Next</button>
            </div>
          </form>
        </mat-step>

        <!-- STEP 3 -->
        <ng-container *ngIf="profile | userCan: 'manage company rates'">
          <mat-step [stepControl]="formUserRates" *ngIf="(userIs | Fn: data.user : 'contractor' )">
          <ng-template matStepLabel>Timesheet Invoice Rates ( Contractors )</ng-template>
            <form [formGroup]="formUserRates">
              <app-user-rates [form]="formUserRates" [data]="userRates"></app-user-rates>
            </form>
          </mat-step>
        </ng-container>

        <!-- <mat-step [stepControl]="formRates" *ngIf="profile | userCan: 'manage company rates'">
          <ng-template matStepLabel>Rates</ng-template>
          <app-user-rates [form]="userRates" [data]="userRatesData"></app-user-rates>
          <form [formGroup]="formRates">
            
              <div  formArrayName="rates" *ngFor="let rate of getformRate; let i = index">
                <div class="row ml-2" [formGroupName]="i">     
                    <div class="row ml-2 w-100">
                      
                        <div class="col-sm-3 d-flex align-items-center">
                          <div >
                            {{ rate.controls.label.value }}
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <mat-form-field class="w-100 type-field">
                            <span matPrefix>$ &nbsp;</span>
                            <input matInput placeholder="Rate" autocomplete="off" formControlName="value" mask="separator.2" thousandSeparator="," >
                            <span matSuffix>per Hour</span>
                          </mat-form-field>                          
                        </div>         
                        <div class="col-sm-3 d-flex align-items-center">
                          <div *ngIf="rate.controls.overide.value" class="date">${{ rate.controls.overide.value }}</div>
                        </div>
                    </div>
                </div>
              </div>
          </form>
        </mat-step> -->
        <!-- <mat-step>
          <ng-template matStepLabel>Finish</ng-template>
          You are now done.
          <mat-dialog-actions>
            <button mat-button matStepperPrevious>Back</button>
            <button color="primary" [disabled]="formProjects.invalid" mat-raised-button (click)="save()">Create User</button>
          </mat-dialog-actions>
        </mat-step> -->
      </mat-vertical-stepper>
    </div>
    
  </div>
  <mat-dialog-actions>
    <button mat-button (click)="close()">Close</button>    
    <button color="primary" [disabled]="formProjects.invalid || formUser.invalid" mat-raised-button (click)="save()">
      <span *ngIf="formUser.controls.id.value; else create">Update</span> 
      <ng-template #create><span>Create</span></ng-template>
      <span>&nbsp; User</span>
    </button>    
    <button color="warn" *ngIf="data.user.id" mat-button (click)="delete()">Delete</button>
  </mat-dialog-actions>
</div>