import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { BrandModel, UserProfileModel } from 'src/app/_models/subjects';
import { BrandService } from 'src/app/_services/brand.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-brand-selector',
  templateUrl: './brand-selector.component.html',
  styleUrls: ['./brand-selector.component.scss']
})
export class BrandSelectorComponent {
  @Input() form: FormGroup;
  @Input() profile: UserProfileModel;
  @Input() brands: BrandModel[];
  @Input() formkey: string = "brand_id"
  @Input() excludeCompany: boolean = false;
  brandList: BrandModel[];
  userProfile: UserProfileModel;
  
  constructor(
    private brandService: BrandService,
    private userService: UserService
  ){
    
   }

  ngOnInit(): void {    
    if (!this.brands) {
      this.brandService.brands
        .pipe(first())
        .subscribe(brands => {
          this.brandList = brands
        });
    }else{
      this.brandList = this.brands;
    }

    if (!this.profile) {
      this.userService.userProfile()
        .pipe(first())
        .subscribe(profile => this.profile = profile);
    }
  }

  sortBy(prop: string,arr: any[]) {
    return arr.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
  }
}
