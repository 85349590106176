import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray, FormControl } from '@angular/forms';
import { UserService } from '../../_services/user.service';
import { UserProfileModel, CompanyModel, Settings, CompanySetting, apiResponse } from '../../_models/subjects';
import { VelvetysoftService } from '../../_services/velvetysoft.service';
import { Observable } from 'rxjs';
import { first, flatMap } from 'rxjs/operators';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Location } from '@angular/common';
// import { MAT_CHECKBOX_CLICK_ACTION, MatCheckbox } from '@angular/material/checkbox';
import { environment } from "../../../environments/environment";
import { FormErrorsService } from 'src/app/_services/form-errors.service';
import { MatLegacyCheckbox as MatCheckbox } from '@angular/material/legacy-checkbox';

//provide: MAT_CHECKBOX_CLICK_ACTION, useValue: "check"
@Component({
  selector: "app-company",
  templateUrl: "./company.component.html",
  styleUrls: ["./company.component.scss"],
})
export class CompanyComponent implements OnInit {
  form: UntypedFormGroup;
  formRates: UntypedFormGroup;  
  formExpenses: UntypedFormGroup;
  formInvoices: UntypedFormGroup;
  states: object[];
  error: any = false;
  $userProfile: Observable<UserProfileModel>;
  $companySettings: Observable<CompanySetting[]>
  // addresses: UntypedFormArray;
  rates: UntypedFormArray;
  ratesInternal: UntypedFormArray;
  ratesContractor: UntypedFormArray;
  expenseContacts: UntypedFormArray;
  invoiceSettings: UntypedFormArray;
  swatchOpen = false;
  
  cdn = environment.cdn;
  workTypes: Array<CompanySetting>;
  workTypesInternal: Array<CompanySetting>;
  
  spaceUsed = {
    percent: 0,
    value: "0",
    allowed: "0",
  };

  openAiUsed = {
    percent: 0,
    value: 0,
    allowed: "1000",
  }

  @ViewChildren("checkUniq") checkUniq: QueryList<MatCheckbox>;
  constructor(
    private userService: UserService,
    private fb: UntypedFormBuilder,
    private vs: VelvetysoftService,
    private snack: MatSnackBar,
    private formErrors: FormErrorsService,
    private location: Location
  ) {
    this.form = fb.group({
      name: ["", Validators.required],
      logo: [null],
      color: [""],
      prefix: [""],
      website: [""],
      icon: [""],
      invoice_penalty_percent: [""],
      invoice_overdue_day: [""],
      addresses: this.fb.array([]),
      mybrand: [""],
    });
    this.formRates = fb.group({
      rates: this.fb.array([]),
      ratesInternal: this.fb.array([]),
      ratesContractor: this.fb.array([]),
    })
    this.formExpenses = fb.group({
      expenseContacts: this.fb.array([]),
    });
    this.formInvoices = fb.group({
      settings: this.fb.array([])
    });
    // this.states = vs.states();
    this.workTypes = this.vs.mutateJobs(this.vs.getSettings('jobs'));//.filter( rate => rate.name !== 'timeoff');
    this.workTypesInternal = this.vs.mutateJobs(this.vs.getSettings('internaljobs'));
    this.$companySettings = this.userService.companySettings();
  }


  ngOnInit() {
    this.$userProfile = this.userService.userProfile();
    this.$userProfile.pipe(first()).subscribe((profile) => {
      // if (profile.company.addresses) {
      //   profile.company.addresses.forEach((address) => {
      //     this.currentAddress = address;
      //     this.addresses = this.form.get("addresses") as UntypedFormArray;
      //     if(profile.company.addresses.length > this.addresses.length ) this.addresses.push(this.createAddress());
      //   });
      // }

      let percent = (+profile.company.space_used / +this.vs.allowedSpace) * 100;
      this.spaceUsed = {
        percent: percent,
        value: this.vs.size(profile.company.space_used),
        allowed: this.vs.size(this.vs.allowedSpace),
      };

      let aipercent = (+profile.company.openaicount / +this.openAiUsed.allowed) * 100;
      this.openAiUsed.percent = aipercent,
      this.openAiUsed.value = profile.company.openaicount,
          
      this.form.patchValue(profile.company);
    });
    
    this.rates = this.formRates.get("rates") as UntypedFormArray;
    this.ratesInternal = this.formRates.get("ratesInternal") as UntypedFormArray;
    this.ratesContractor = this.formRates.get("ratesContractor") as UntypedFormArray;
    this.expenseContacts = this.formExpenses.get("expenseContacts") as UntypedFormArray;
    this.invoiceSettings = this.formInvoices.get("settings") as UntypedFormArray;


    //SETUP AMOUNT OF OPTIONS
    //IN THE FUTRE THIS WILL NED TO CHANGE FOR VARIABLE AMOUNTS
    Array.from({ length: this.workTypes.length }, () => {    
      this.rates.push(this.createSetting())  
    });   
    Array.from({ length: this.workTypesInternal.length }, () => {    
      this.ratesInternal.push(this.createSetting())  
    });
    
    this.ratesContractor.push(this.createSetting());
    
    this.$companySettings.subscribe(_settings => {
    
      //COMPANY COLLECTION AND FILL MODEL BY NAME MATCH
      const settings = this.workTypes.map( setting =>({
        ...setting,
        ..._settings.find( type => type.name == setting.name)
      })) ;

      const settingsInternal = this.workTypesInternal.map( setting =>({
        ...setting,
        ..._settings.find( type => type.name == setting.name)
      })) ;

      // const settingsContractor = this.workTypesInternal.filter( setting =>({
      //   return settings.name == 'contractor';
      // })) ;


      let _contractor = _settings.find( type => type.name == 'contractor');
      const settingsContractor = (_contractor)
        ? [_contractor]
        : [{id: null, type: 'rate', name: 'contractor', label: 'Contractor', value: null}]; 
      

      const filterC = setting => setting.type == 'expense-contact';
      const filterBCC = setting => setting.type == 'expense-contact-bcc';
      const expenseContacts = _settings.filter( setting => filterC(setting) || filterBCC(setting) );
      Array.from({ length: expenseContacts.length }, () => {   
        this.expenseContacts.push(this.createSetting())
      });

      const invoiceSettings = _settings.filter( setting => setting.type == 'invoice-setting');
      let logo = invoiceSettings.find( setting => setting.name == 'logo');
      if(!logo) invoiceSettings.push({
        id: null,
        type: 'invoice-setting',
        name: 'logo',
        label: 'Logo',
        value: null,
      });

      Array.from({ length: invoiceSettings.length }, () => {   
        this.invoiceSettings.push(this.createSetting())
        //this.invoiceSettings.push(_settingInovice);
      });

      //Find if this.invoiceSettings has logo
      
    

      this.invoiceSettings.patchValue(invoiceSettings);
      this.expenseContacts.patchValue(expenseContacts);      
      this.ratesContractor.patchValue(settingsContractor);
      this.rates.patchValue(settings) 
      this.ratesInternal.patchValue(settingsInternal);
    });

    this.formErrors.errors.subscribe(errors =>{
      if(!errors) return
      this.formErrors.apply(this.form,errors);
    })
  }


  colorChange(e) {
    let color = e && e.color && e.color.hex ? e.color.hex : null;
    this.form.patchValue({ color: color });    
  }

  companyLogo(imagePath) {
    this.form.patchValue({ logo: imagePath });
  }
  companyIcon(imagePath) {
    this.form.patchValue({ icon: imagePath });
  }
  invoiceLogo(imagePath,i) {
    this.invoiceSettings.controls[i].patchValue({ value: imagePath });
    //this.form.patchValue({ logo: imagePath });
  }

  createSetting(){
    return this.fb.group({
      id: [""],
      name: [""],
      label: [""],
      value: [""],
      type: [""]
    });
  }

  removeline(idx,arr){
    let expensesContact = this[arr];
    if(expensesContact.value[idx].id){
      this.userService.removeCompanySetting(expensesContact.value[idx].id).subscribe((res) => {
        if(res) expensesContact.removeAt(idx);        
      });
    }else{
      expensesContact.removeAt(idx);      
    }
  }

  addExpenseAddress(e, _type?: string) {
    e.preventDefault();

    let type = 'expense-contact';
    let label = 'Email To';

    if(_type && _type == 'bcc'){
      type = 'expense-contact-bcc';
      label = 'Email BCC';
    }

    this.expenseContacts = this.formExpenses.get("expenseContacts") as UntypedFormArray;
    let _setting = this.createSetting();
    _setting.patchValue({
        type: type, 
        name: 'email',
        label: label,
      });
    this.expenseContacts.push(_setting);
  }


  get getformRate() {
    return (this.formRates.get("rates") as UntypedFormArray).controls;
  }
  get getformRateInternal() {
    return (this.formRates.get("ratesInternal") as UntypedFormArray).controls;
  }
  get getformRateContractor() {
    return (this.formRates.get("ratesContractor") as UntypedFormArray)?.controls;
  }
  get getformExpense() {
    return (this.formExpenses.get("expenseContacts") as UntypedFormArray).controls;
  }
  get getformInovice() {
    return (this.formInvoices.get("settings") as UntypedFormArray).controls;
  }
 


  // get formAddresses() {
  //   return (this.form.get("addresses") as UntypedFormArray).controls;
  // }

  submit() {
    console.log(this.form.value);
    if (this.form.valid) {
      this.form.value.addresses.forEach((address) => {
        address.is_primary = address.is_primary ? true : false;
        address.is_billing = address.is_billing ? true : false;
      });

      this.userService
        .update<CompanyModel>(this.form.value, "company")
        .pipe(first())
        .subscribe();

      //EROOR      
    }
  }

  submitExpenses() {
    let data = this.formExpenses.value
    this.userService
        .update<CompanySetting>(data, "company-settings")
        .pipe(first())
        .subscribe();
    console.log(data);
    //formExpenses
    //expenseContacts
    //getformExpense
  }

  submitRates() {
    if (this.formRates.valid) {
      let data = this.formRates.value
      data.rates = [...data.rates, ...data.ratesInternal, ...data.ratesContractor]
      //console.log(this.formRates,data)
      delete data.ratesInternal;

      this.userService
        .update<CompanySetting>(data, "company-settings")
        .pipe(first())
        .subscribe();
    }
  }

  submitInvoices() {
    if (this.formInvoices.valid) {
      let data = this.formInvoices.value      
      this.userService
        .update<CompanySetting>(data, "company-settings")
        .pipe(first())
        .subscribe();
    }
  }

  

  createAddress() {
    return this.fb.group({
      id: [""],
      street: [""],
      city: [""],
      state: [""],
      post_code: [""],
      is_primary: [""],
      //is_shipping: [""],
      is_billing: [""],
    });
  }  

  // addAddress(e) {
  //   e.preventDefault();
  //   this.addresses = this.form.get("addresses") as UntypedFormArray;
  //   this.addresses.push(this.createAddress());
  // }
  

  // delete(e, i) {
  //   e.preventDefault();
  //   this.addresses = this.form.get("addresses") as UntypedFormArray;
  //   this.addresses.removeAt(i);
  // }

  // checkedUnique(e, i, inputType) {
  //   this.addresses = this.form.get("addresses") as UntypedFormArray;
  //   this.addresses.controls.forEach((item: UntypedFormGroup, j) => {
  //     if (i != j) {
  //       item.controls[inputType].patchValue(false);
  //     }
  //   });

    // this.addresses.forEach( chk =>{
    //   console.log(chk)
    // } )
    // this.primary.forEach(check =>{
    //    console.log(check)
    // });
    //console.log('checked', e, this.primary);
  //}

  back(e) {
    e.preventDefault();
    this.location.back();
  }


}
