import { Injectable } from '@angular/core';
import { SubjectBaseService } from './subject-base.service';
import { ApiService } from './api.service';
import { HoldingCompanyModel, SubjectAction } from "../_models/subjects";

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder } from '@angular/forms';
import { ReplaySubject, Observable, Subscribable, Subject } from 'rxjs';
import { AddHoldingCompanyComponent } from '../_component/add-holding-company/add-holding-company.component';
import { first, tap } from 'rxjs/operators'; 

@Injectable({
  providedIn: 'root'
})
export class HoldingCompanyService extends SubjectBaseService {
  _holdingCompany: HoldingCompanyModel = {
    id: null,  
    company_id: null, 
    name: "",
    addressess: null
  }
  init:boolean = false;
  $companies: ReplaySubject<HoldingCompanyModel[]> = new ReplaySubject(1);
  

  constructor(
    public apiService: ApiService,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder
  ) {
    super("holding-company", apiService, dialog);
    this.setModel<HoldingCompanyModel>(this._holdingCompany);
  }

  companies(force:boolean=false):Observable<HoldingCompanyModel[]> {
    if(!this.init || force) this.loadSubject();    
    return this.$companies.asObservable();
  }

  loadSubject(){
    this.init = true;
    this.apiService.get(`${this.subject}s`).pipe(tap(res => {
      console.log(res)
      this.$companies.next(res) 
    }),first()).subscribe();      
  }

  edit(hc?: HoldingCompanyModel,options?):Observable<HoldingCompanyModel> {    
    let resultRef = new Subject<HoldingCompanyModel>();
 
    const result = this.editDialog<HoldingCompanyModel>(
      hc,
      AddHoldingCompanyComponent,
      options
    );

    result.pipe(first()).subscribe((res: SubjectAction<HoldingCompanyModel>) => {
      const hc = res.subject;

      (res.action == 'update') && this.add<HoldingCompanyModel>(hc,{ skipLoad: true })
        .pipe(first())
        .subscribe((hc:HoldingCompanyModel) => {
          this.companies(true).pipe(first()).subscribe();
          resultRef.next(hc);
        });

      //(res.action == 'delete') && resultRef.next(hc)
      
    });

    return resultRef;   
  }

}
