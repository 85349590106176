<div>
    <div class="mx-auto" style="max-width: 800px;">
        <div *ngIf="page == '/terms'">
            <div class="WordSection1">
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><b style="mso-bidi-font-weight:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">Last Updated: <span id="static_effective_date" class="saved-var effective_date step-7">04/13/2023</span>.</span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><b style="mso-bidi-font-weight:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><b style="mso-bidi-font-weight:normal"><span style="font-size:14.0pt;font-family:&quot;Times New Roman&quot;,serif">PLEASE READ THE
                FOLLOWING TERMS OF SERVICE AGREEMENT CAREFULLY. BY ACCESSING OR USING OUR WEBSITE
                (THE "SITE") OR OUR SERVICES, YOU HEREBY AGREE TO BE BOUND BY THESE TERMS AND
                CONDITIONS AND ALL TERMS INCORPORATED HEREIN BY REFERENCE. IT IS THE
                RESPONSIBILITY OF YOU, THE USER, CUSTOMER, OR PROSPECTIVE CUSTOMER TO READ THE
                TERMS AND CONDITIONS BEFORE PROCEEDING TO USE THIS SITE. IF YOU DO NOT
                EXPRESSLY AGREE TO ALL OF THE TERMS AND CONDITIONS, THEN PLEASE DO NOT ACCESS
                OR USE OUR SITE OR OUR SERVICES. </span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">The
                present terms and conditions (this "<b style="mso-bidi-font-weight:normal">Agreement</b>"
                or "<b style="mso-bidi-font-weight:normal">Terms</b>") is a legal agreement
                between you and <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span> (hereinafter <b style="mso-bidi-font-weight:
                normal">" <span id="static_company_contact_short_name" class="saved-var company_contact_short_name step-1">Velvetysoft</span></b>"), a company duly organized and
                validly existing, located at <span id="static_company_contact_address" class="saved-var company_contact_address step-1">100 Pheasant Woods Ct</span>,
                <span id="static_company_contact_city" class="saved-var company_contact_city step-1">Loveland</span>, <span id="static_company_contact_state" class="saved-var company_contact_state step-1">Ohio</span> <span id="static_company_contact_zip_code" class="saved-var company_contact_zip_code step-1">45140</span>.<span style="mso-spacerun:yes">&nbsp; </span>This Agreement annuls and voids all previous
                agreements.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><b style="mso-bidi-font-weight:normal"><span style="font-size:16.0pt;font-family:&quot;Times New Roman&quot;,serif">OVERVIEW</span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">The
                Site ( <span id="static_company_contact_website" class="saved-var company_contact_website step-0">Show-pony.us</span>) is operated by <span id="static_company_contact_short_name" class="saved-var company_contact_short_name step-1">Velvetysoft</span>.
                Throughout the Site, the terms "<b style="mso-bidi-font-weight:normal">we</b>",
                "<b style="mso-bidi-font-weight:normal">us</b>" and "<b style="mso-bidi-font-weight:
                normal">our</b>" refer to <span id="static_company_contact_short_name" class="saved-var company_contact_short_name step-1">Velvetysoft</span>. <span id="static_company_contact_short_name" class="saved-var company_contact_short_name step-1">Velvetysoft</span>offers this Site, including all information, tools and services available from
                this Site to you, the user, conditioned upon your acceptance of all terms,
                conditions, policies and notices stated here.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">By
                visiting our Site and/or purchasing something from us, you engage in our
                "Service" and agree to be bound by the following terms and conditions,
                including those additional terms and conditions and policies referenced herein
                and/or available by hyperlink. These Terms apply to all users of the Site,
                including without limitation users who are browsers, vendors, customers,
                merchants, and/or contributors of content. In the event of an inconsistency
                between this Agreement and any additional terms or policies referenced herein,
                the provisions of the additional terms or policies shall control.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">Please
                read these Terms carefully before accessing or using our Site. By accessing or
                using any part of the Site, you agree to be bound by these Terms. If you do not
                agree to all the Terms of this Agreement, then you may not access the Site or
                use any Service. If these Terms are considered an offer, acceptance is
                expressly limited to these Terms.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">Any
                new features or tools which are added to the current store shall also be
                subject to the Terms. You can review the most current version of the Terms at
                any time on this page. We reserve the right to update, change or replace any
                part of these Terms by posting updates and/or changes to our Site. It is your
                responsibility to check this page periodically for changes. Your continued use
                of or access to the Site following the posting of any changes constitutes
                acceptance of those changes.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><b style="mso-bidi-font-weight:normal"><span style="font-size:16.0pt;font-family:&quot;Times New Roman&quot;,serif">SECTION 1 - GENERAL
                TERMS</span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">By
                agreeing to these Terms, you represent that you are at least the age of
                majority in your state or province of residence, or that you are the age of
                majority in your state or province of residence and you have given us your
                consent to allow any of your minor dependents to use this Site.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">You
                may not use our products or Site for any illegal or unauthorized purpose nor
                may you, in the use of our products or Site, violate any laws in your
                jurisdiction (including but not limited to motor vehicle laws).</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">You
                must not transmit any worms or viruses or any code of a destructive nature.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">A
                breach or violation of any of the Terms will result in an immediate termination
                of your account and right to use our Service.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">We
                have the right, but not the obligation, to take any of the following actions in
                our sole discretion at any time and for any reason without giving you any prior
                notice: </span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"><span style="mso-spacerun:yes">&nbsp;&nbsp; </span>1. Restrict, suspend or terminate your
                access to all or any part of our Site; </span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"><span style="mso-spacerun:yes">&nbsp;&nbsp; </span>2. Change, suspend or discontinue all or any
                part of our products or Site; </span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"><span style="mso-spacerun:yes">&nbsp;&nbsp; </span>3. Refuse, move, or remove any content that
                is available on all or any part of our Site; </span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"><span style="mso-spacerun:yes">&nbsp;&nbsp; </span>4. Deactivate or delete your accounts; </span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"><span style="mso-spacerun:yes">&nbsp;&nbsp; </span>5. Establish general practices and limits
                concerning use of our Site. </span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">You
                agree that we will not be liable to you or any third party for taking any of
                these actions.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">You
                understand and agree that our Site may include communications such as service
                announcements and administrative or legal notices from us. Please note that you
                cannot opt out of receiving these notices.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">You
                understand that your content (not including credit card information), may be
                transferred unencrypted and involve (a) transmissions over various networks;
                and (b) changes to conform and adapt to technical requirements of connecting
                networks or devices. Credit card information is always encrypted during
                transfer over networks.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">You
                agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of
                the Site, use of the Site, or access to the Site or any contact on the Site,
                without express written permission by us.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">You
                may not modify, publish, transmit, reverse engineer, participate in the
                transfer or sale, create derivative works, or in any way exploit any of the
                content, in whole or in part, found on the Site. <span id="static_company_contact_short_name" class="saved-var company_contact_short_name step-1">Velvetysoft</span>content is not for resale. Use of the Site does not entitle users to make any
                unauthorized use of any protected content, and in particular you will not
                delete or alter any proprietary rights or attribution notices in any content.
                You will use protected content solely for your personal use, and will make no
                other use of the content without the express written permission of
                <span id="static_company_contact_short_name" class="saved-var company_contact_short_name step-1">Velvetysoft</span> and the copyright owner. You agree that you do
                not acquire any ownership rights in any protected content. We do not grant you
                any licenses, express or implied, to the intellectual property of
                <span id="static_company_contact_short_name" class="saved-var company_contact_short_name step-1">Velvetysoft</span> or our licensors except as expressly authorized
                by these Terms.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><b style="mso-bidi-font-weight:normal"><span style="font-size:16.0pt;font-family:&quot;Times New Roman&quot;,serif">SECTION 2 - CREATING
                AN ACCOUNT</span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">Once
                you create an account with us, you are registered on the
                <span id="static_company_contact_short_name" class="saved-var company_contact_short_name step-1">Velvetysoft</span> Site. The terms "member," "membership," and
                "account" all refer to this registration as a member on
                <span id="static_company_contact_short_name" class="saved-var company_contact_short_name step-1">Velvetysoft</span>'s Site. If you are merely surfing or browsing
                through the Site and have not yet created an account, your use of the Site is
                still subject to this Agreement; if you do not agree to this Agreement, do not
                use the Site.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">When
                you create an account, you will provide a unique username and email.<span style="mso-spacerun:yes">&nbsp; </span>We will also ask you to create a
                password.<span style="mso-spacerun:yes">&nbsp; </span>Because any activities that
                occur under your username or password are your responsibility it is important
                for you to keep your username and/or password secure. You may not assign or
                otherwise transfer your account to any other person or entity. You acknowledge
                that <span id="static_company_contact_short_name" class="saved-var company_contact_short_name step-1">Velvetysoft</span> is not responsible for third party access
                to your account that results from theft or misappropriation of your account.
                Notify us immediately if you believe that someone has used your username,
                email, or password without your authorization.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">Furthermore,
                the registering party hereby acknowledges, understands and agrees to:</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.5in;margin-bottom:.0001pt;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">a) furnish
                factual, correct, current and complete information with regards to yourself as
                may be requested by the data registration process, and</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;line-height:
                normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.5in;margin-bottom:.0001pt;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">b) maintain and
                promptly update your registration and profile information in an effort to
                maintain accuracy and completeness at all times.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">If
                anyone knowingly provides any information of a false, untrue, inaccurate or
                incomplete nature, <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span> <span style="mso-spacerun:yes">&nbsp;</span>will have sufficient grounds and rights to
                suspend or terminate the member in violation of this aspect of the Agreement,
                and as such refuse any and all current or future use of <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span><span style="mso-spacerun:yes">&nbsp;</span>Services, or any portion thereof. </span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><b style="mso-bidi-font-weight:normal"><span style="font-size:16.0pt;font-family:&quot;Times New Roman&quot;,serif">SECTION 3 - CONDUCT</span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">As
                a user or member of the Site, you herein acknowledge, understand and agree that
                all information, text, software, data, photographs, music, video, messages, tags
                or any other content, whether it is publicly or privately posted and/or
                transmitted, is the expressed sole responsibility of the individual from whom
                the content originated. In short, this means that you are solely responsible
                for any and all content posted, uploaded, emailed, transmitted or otherwise
                made available by way of the <span id="static_company_contact_short_name" class="saved-var company_contact_short_name step-1">Velvetysoft</span> Services, and as
                such, we do not guarantee the accuracy, integrity or quality of such content.
                It is expressly understood that by use of our Services, you may be exposed to
                content including, but not limited to, any errors or omissions in any content
                posted, and/or any loss or damage of any kind incurred as a result of the use
                of any content posted, emailed, transmitted or otherwise made available by <span id="static_company_contact_short_name" class="saved-var company_contact_short_name step-1">Velvetysoft</span>.
                </span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">Furthermore,
                you herein agree not to make use of <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span>'s Services for the
                purpose of:</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">a) uploading,
                posting, emailing, transmitting, or otherwise making available any content that
                shall be deemed unlawful, harmful, threatening, abusive, harassing, tortious,
                defamatory, vulgar, obscene, libelous, or invasive of another's privacy or
                which is hateful, and/or racially, ethnically, or otherwise objectionable;</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">b) causing harm to
                minors in any manner whatsoever;</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">c) impersonating
                any individual or entity, including, but not limited to, any <span id="static_company_contact_short_name" class="saved-var company_contact_short_name step-1">Velvetysoft</span><span style="mso-spacerun:yes">&nbsp;</span>officials, forum leaders, guides or hosts
                or falsely stating or otherwise misrepresenting any affiliation with an
                individual or entity;</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">d) forging
                captions, headings or titles or otherwise offering any content that you
                personally have no right to pursuant to any law nor having any contractual or
                fiduciary relationship with;</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">e) uploading, posting,
                emailing, transmitting or otherwise offering any such content that may infringe
                upon any patent, copyright, trademark, or any other proprietary or intellectual
                rights of any other party;</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">f) uploading,
                posting, emailing, transmitting or otherwise offering any content that you do
                not personally have any right to offer pursuant to any law or in accordance
                with any contractual or fiduciary relationship;</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraphCxSpFirst" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">g) uploading,
                posting, emailing, transmitting, or otherwise offering any unsolicited or
                unauthorized advertising, promotional flyers, "junk mail," "spam," or any other
                form of solicitation, except in any such areas that may have been designated
                for such purpose;</span></p>
                <p class="MsoListParagraphCxSpMiddle" style="text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraphCxSpLast" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">h) uploading,
                posting, emailing, transmitting, or otherwise offering any source that may
                contain a software virus or other computer code, any files and/or programs
                which have been designed to interfere, destroy and/or limit the operation of
                any computer software, hardware, or telecommunication equipment;</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">i) disrupting the
                normal flow of communication, or otherwise acting in any manner that would
                negatively affect other users' ability to participate in any real time
                interactions;</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;line-height:
                normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;line-height:normal"><span style="font-size:12.0pt;
                font-family:&quot;Times New Roman&quot;,serif">j) interfering with or disrupting any <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span>Services, servers and/or networks that may be connected or related to our
                website, including, but not limited to, the use of any device software and/or
                routine to bypass the robot exclusion headers;</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;line-height:
                normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;line-height:normal"><span style="font-size:12.0pt;
                font-family:&quot;Times New Roman&quot;,serif">k) intentionally or unintentionally
                violating any local, state, federal, national or international law, including,
                but not limited to, rules, guidelines, and/or regulations decreed by the U.S.
                Securities and Exchange Commission, in addition to any rules of any nation or
                other securities exchange, that would include without limitation, the New York
                Stock Exchange, the American Stock Exchange, or the NASDAQ, and any regulations
                having the force of law;</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;line-height:
                normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;line-height:normal"><span style="font-size:12.0pt;
                font-family:&quot;Times New Roman&quot;,serif">l) providing informational support or
                resources, concealing and/or disguising the character, location, and or source to
                any organization delegated by the United States government as a "foreign
                terrorist organization" in accordance to Section 219 of the Immigration
                Nationality Act;</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;line-height:
                normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;line-height:normal"><span style="font-size:12.0pt;
                font-family:&quot;Times New Roman&quot;,serif">m) "stalking" or with the intent to
                otherwise harass another individual; and/or </span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;line-height:
                normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;line-height:normal"><span style="font-size:12.0pt;
                font-family:&quot;Times New Roman&quot;,serif">n) collecting or storing of any personal
                data relating to any other member or user in connection with the prohibited
                conduct and/or activities which have been set forth in the aforementioned
                paragraphs.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"> <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span><span style="mso-spacerun:yes">&nbsp;</span>herein reserves the right to pre-screen,
                refuse and/or delete any content currently available through our Services. In
                addition, we reserve the right to remove and/or delete any such content that
                would violate the Terms or which would otherwise be considered offensive to
                other visitors, users and/or members. </span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"> <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span><span style="mso-spacerun:yes">&nbsp;</span>herein reserves the right to access,
                preserve and/or disclose member account information and/or content if it is
                requested to do so by law or in good faith belief that any such action is
                deemed reasonably necessary for:</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">a) compliance with
                any legal process;</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">b) enforcement of
                the Terms;</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">c) responding to
                any claim that therein contained content is in violation of the rights of any
                third party;</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">d) responding to
                requests for customer service; or</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;line-height:normal"><span style="font-size:12.0pt;
                font-family:&quot;Times New Roman&quot;,serif">e) protecting the rights, property or the
                personal safety of <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span>, its visitors, users and members,
                including the general public.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"> <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span><span style="mso-spacerun:yes">&nbsp;</span>herein reserves the right to include the
                use of security components that may permit digital information or material to
                be protected, and that such use of information and/or material is subject to
                usage guidelines and regulations established by <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span> or any
                other content providers supplying content services to <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span>.
                You are hereby prohibited from making any attempt to override or circumvent any
                of the embedded usage rules in our Services. Furthermore, unauthorized
                reproduction, publication, distribution, or exhibition of any information or
                materials supplied by our Services, despite whether done so in whole or in
                part, is expressly prohibited.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><b style="mso-bidi-font-weight:normal"><span style="font-size:16.0pt;font-family:&quot;Times New Roman&quot;,serif">SECTION 4 - GLOBAL
                USE; EXPORT/IMPORT COMPLIANCE</span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">Due
                to the global nature of the internet, through the use of our network you hereby
                agree to comply with all local rules relating to online conduct and that which
                is considered acceptable content. Uploading, posting and/or transferring of
                software, technology and other technical data may be subject to the export and
                import laws of the United States and possibly other countries. Through the use
                of our network, you thus agree to comply with all applicable export and import
                laws, statutes and regulations, including, but not limited to, the Export
                Administration Regulations (</span><a href="http://www.access.gpo.gov/bis/ear/ear_data.html"><span style="font-size:
                12.0pt;font-family:&quot;Times New Roman&quot;,serif">http://www.access.gpo.gov/bis/ear/ear_data.html</span></a><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">), as well as the
                sanctions control program of the United States (</span><a href="http://www.treasury.gov/resource-center/sanctions/Programs/Pages/Programs.aspx"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">http://www.treasury.gov/resource-center/sanctions/Programs/Pages/Programs.aspx</span></a><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">). Furthermore, you
                state and pledge that you:</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;line-height:
                normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">a) are not on the
                list of prohibited individuals which may be identified on any government export
                exclusion report (</span><a href="http://www.bis.doc.gov/complianceandenforcement/liststocheck.htm"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">http://www.bis.doc.gov/complianceandenforcement/liststocheck.htm</span></a><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">) nor a member of
                any other government which may be part of an export-prohibited country
                identified in applicable export and import laws and regulations;</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">b) agree not to
                transfer any software, technology or any other technical data through the use
                of our network Services to any export-prohibited country; </span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">c) agree not to
                use our website network Services for any military, nuclear, missile, chemical
                or biological weaponry end uses that would be a violation of the U.S. export
                laws; and</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">d) agree not to
                post, transfer nor upload any software, technology or any other technical data
                which would be in violation of the U.S. or other applicable export and/or
                import laws.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><b style="mso-bidi-font-weight:normal"><span style="font-size:16.0pt;font-family:&quot;Times New Roman&quot;,serif">SECTION 5 - SUBMITTED
                CONTENT</span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"> <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span><span style="mso-spacerun:yes">&nbsp;</span>shall not lay claim to ownership of any
                content submitted by any visitor, member, or user, nor make such content
                available for inclusion on our website Services. Therefore, you hereby grant
                and allow for <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span> the below listed worldwide, royalty-free
                and non-exclusive licenses, as applicable:</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">a) The content
                submitted or made available for inclusion on the publicly accessible areas of <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span>'s
                Sites, the license provided to permit to use, distribute, reproduce, modify,
                adapt, publicly perform and/or publicly display said Content on our network Services
                is for the sole purpose of providing and promoting the specific area to which
                this content was placed and/or made available for viewing. This license shall
                be available so long as you are a member of <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span>'s sites,
                and shall terminate at such time when you elect to discontinue your membership.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;text-indent:.5in;line-height:normal"><span style="font-size:12.0pt;
                font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">b) Photos, audio,
                video and/or graphics submitted or made available for inclusion on the publicly
                accessible areas of <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span>'s sites, the license provided to
                permit to use, distribute, reproduce, modify, adapt, publicly perform and/or
                publicly display said Content on our network Services are for the sole purpose
                of providing and promoting the specific area in which this content was placed
                and/or made available for viewing. This license shall be available so long as
                you are a member of <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span>'s sites and shall terminate at
                such time when you elect to discontinue your membership.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">c) For any other
                content submitted or made available for inclusion on the publicly accessible
                areas of <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span>'s sites, the continuous, binding and
                completely sub-licensable license which is meant to permit to use, distribute,
                reproduce, modify, adapt, publish, translate, publicly perform and/or publicly
                display said content, whether in whole or in part, and the incorporation of any
                such Content into other works in any arrangement or medium current used or
                later developed.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">Those
                areas which may be deemed "publicly accessible" areas of <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span>'s
                Sites are those such areas of our network properties which are meant to be
                available to the general public, and which would include message boards and
                groups that are openly available to both users and members. However, those
                areas which are not open to the public, and thus available to members only,
                would include our mail system and instant messaging.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><b style="mso-bidi-font-weight:normal"><u><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">CONTRIBUTIONS TO COMPANY
                WEBSITE</span></u></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"> <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span><span style="mso-spacerun:yes">&nbsp;</span>may provide an area for our user and
                members to contribute feedback to our website. When you submit ideas,
                documents, suggestions and/or proposals ("<b style="mso-bidi-font-weight:normal">Contributions</b>")
                to our site, you acknowledge and agree that: </span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">a) your contributions
                do not contain any type of confidential or proprietary information;</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">b) <span id="static_company_contact_short_name" class="saved-var company_contact_short_name step-1">Velvetysoft</span><span style="mso-spacerun:yes">&nbsp;</span>shall not be liable or under any
                obligation to ensure or maintain confidentiality, expressed or implied, related
                to any Contributions; </span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">c) <span id="static_company_contact_short_name" class="saved-var company_contact_short_name step-1">Velvetysoft</span><span style="mso-spacerun:yes">&nbsp;</span>shall be entitled to make use of and/or
                disclose any such Contributions in any such manner as they may see fit; </span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">d) the
                contributor's Contributions shall automatically become the sole property of <span id="static_company_contact_short_name" class="saved-var company_contact_short_name step-1">Velvetysoft</span>;
                and</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">e) <span id="static_company_contact_short_name" class="saved-var company_contact_short_name step-1">Velvetysoft</span><span style="mso-spacerun:yes">&nbsp;</span>is under no obligation to either
                compensate or provide any form of reimbursement in any manner or nature.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><b style="mso-bidi-font-weight:normal"><span style="font-size:16.0pt;font-family:&quot;Times New Roman&quot;,serif">SECTION 6 - INDEMNITY</span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">All
                users and/or members agree to insure and hold <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span>, our
                subsidiaries, affiliates, agents, employees, officers, partners and/or
                licensors blameless or not liable for any claim or demand, which may include,
                but is not limited to, reasonable attorney fees made by any third party which
                may arise from any content a <span style="mso-spacerun:yes">&nbsp;</span>member or user
                of our Site may submit, post, modify, transmit or otherwise make available
                through our Services, the use of <span id="static_company_contact_short_name" class="saved-var company_contact_short_name step-1">Velvetysoft</span> Services or
                your connection with these Services, your violations of the Terms of Service
                and/or your violation of any such rights of another person.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><b style="mso-bidi-font-weight:normal"><span style="font-size:16.0pt;font-family:&quot;Times New Roman&quot;,serif">SECTION 7 - COMMERCIAL
                REUSE OF SERVICES</span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">The
                member or user herein agrees not to replicate, duplicate, copy, trade, sell,
                resell nor exploit for any commercial reason any part, use of, or access to <span id="static_company_contact_short_name" class="saved-var company_contact_short_name step-1">Velvetysoft</span>'s
                sites.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><b style="mso-bidi-font-weight:normal"><span style="font-size:16.0pt;font-family:&quot;Times New Roman&quot;,serif">SECTION 8 - MODIFICATIONS</span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"> <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span>reserves the right at any time it may deem fit, to modify, alter and or
                discontinue, whether temporarily or permanently, our service, or any part
                thereof, with or without prior notice. In addition, we shall not be held liable
                to you or to any third party for any such alteration, modification, suspension
                and/or discontinuance of our Services, or any part thereof.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><b style="mso-bidi-font-weight:normal"><span style="font-size:16.0pt;font-family:&quot;Times New Roman&quot;,serif">SECTION 9 - TERMINATION</span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">As
                a member of <span id="static_company_contact_website" class="saved-var company_contact_website step-0">Show-pony.us</span>, you may cancel or terminate your
                account, associated email address and/or access to our Services by submitting a
                cancellation or termination request to <span id="static_termination_email_address_sc" class="empty-var termination_email_address_sc step-"></span>.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">As
                a member, you agree that <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span> may, without any prior
                written notice, immediately suspend, terminate, discontinue and/or limit your
                account, any email associated with your account, and access to any of our Services.
                The cause for such termination, discontinuance, suspension and/or limitation of
                access shall include, but is not limited to:</span><span style="font-family:
                &quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">a) any breach or
                violation of our Terms or any other incorporated agreement, regulation and/or
                guideline;</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">b) by way of
                requests from law enforcement or any other governmental agencies;</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">c) the
                discontinuance, alteration and/or material modification to our Services, or any
                part thereof;</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">d) unexpected
                technical or security issues and/or problems;</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">e) any extended
                periods of inactivity;</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">f) any engagement
                by you in any fraudulent or illegal activities; and/or</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">g) the nonpayment
                of any associated fees that may be owed by you in connection with your <span id="static_company_contact_website" class="saved-var company_contact_website step-0">Show-pony.us</span><span style="mso-spacerun:yes">&nbsp;</span>account Services.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">Furthermore,
                you herein agree that any and all terminations, suspensions, discontinuances,
                and or limitations of access for cause shall be made at our sole discretion and
                that we shall not be liable to you or any other third party with regards to the
                termination of your account, associated email address and/or access to any of
                our Services.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">The
                termination of your account with <span id="static_company_contact_website" class="saved-var company_contact_website step-0">Show-pony.us</span> shall include any
                and/or all of the following:</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;line-height:normal"><span style="font-size:12.0pt;
                font-family:&quot;Times New Roman&quot;,serif">a) the removal of any access to all or
                part of the Services offered within <span id="static_company_contact_website" class="saved-var company_contact_website step-0">Show-pony.us</span>;</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;line-height:
                normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;line-height:normal"><span style="font-size:12.0pt;
                font-family:&quot;Times New Roman&quot;,serif">b) the deletion of your password and any
                and all related information, files, and any such content that may be associated
                with or inside your account, or any part thereof; and</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;line-height:
                normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;line-height:normal"><span style="font-size:12.0pt;
                font-family:&quot;Times New Roman&quot;,serif">c) the barring of any further use of all
                or part of our Services.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><b style="mso-bidi-font-weight:normal"><span style="font-size:16.0pt;font-family:&quot;Times New Roman&quot;,serif">SECTION 10 - LINKS</span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">Either
                <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span> <span style="mso-spacerun:yes">&nbsp;</span>or any third
                parties may provide links to other websites and/or resources. Thus, you
                acknowledge and agree that we are not responsible for the availability of any
                such external sites or resources, and as such, we do not endorse nor are we
                responsible or liable for any content, products, advertising or any other
                materials, on or available from such third-party sites or resources.
                Furthermore, you acknowledge and agree that <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span> <span style="mso-spacerun:yes">&nbsp;</span>shall not be responsible or liable, directly
                or indirectly, for any such damage or loss which may be a result of, caused or
                allegedly to be caused by or in connection with the use of or the reliance on
                any such content, goods or Services made available on or through any such site
                or resource.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><b style="mso-bidi-font-weight:normal"><span style="font-size:16.0pt;font-family:&quot;Times New Roman&quot;,serif">SECTION 11 - PROPRIETARY
                RIGHTS</span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">You
                do hereby acknowledge and agree that <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span>'s Services and
                any essential software that may be used in connection with our Services
                ("Software") shall contain proprietary and confidential material that is
                protected by applicable intellectual property rights and other laws.
                Furthermore, you herein acknowledge and agree that any Content which may be
                contained in any advertisements or information presented by and through our Services
                or by advertisers is protected by copyrights, trademarks, patents or other
                proprietary rights and laws. Therefore, except for that which is expressly permitted
                by applicable law or as authorized by <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span> or such
                applicable licensor, you agree not to alter, modify, lease, rent, loan, sell,
                distribute, transmit, broadcast, publicly perform and/or created any
                plagiaristic works which are based on <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span> <span style="mso-spacerun:yes">&nbsp;</span>Services (e.g. Content or Software), in whole
                or part.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"> <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span><span style="mso-spacerun:yes">&nbsp;</span>hereby grants you a personal,
                non-transferable and non-exclusive right and/or license to make use of the
                object code or our Software on a single computer, as long as you do not, and
                shall not, allow any third party to duplicate, alter, modify, create or
                plagiarize work from, reverse engineer, reverse assemble or otherwise make an
                attempt to locate or discern any source code, sell, assign, sublicense, grant a
                security interest in and/or otherwise transfer any such right in the Software.
                Furthermore, you do herein agree not to alter or change the Software in any
                manner, nature or form, and as such, not to use any modified versions of the
                Software, including and without limitation, for the purpose of obtaining
                unauthorized access to our Services. Lastly, you also agree not to access or
                attempt to access our Services through any means other than through the
                interface which is provided by <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span> for use in accessing
                our Services.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><b style="mso-bidi-font-weight:normal"><span style="font-size:16.0pt;font-family:&quot;Times New Roman&quot;,serif">SECTION 12 - WARRANTY
                DISCLAIMERS</span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">YOU
                HEREIN EXPRESSLY ACKNOWLEDGE AND AGREE THAT:</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">a) THE USE OF <span id="static_company_contact_name_uc" class="saved-var company_contact_name_uc step-1">VELVETYSOFT</span><span style="mso-spacerun:yes">&nbsp;</span>SERVICES AND SOFTWARE ARE AT THE SOLE
                RISK BY YOU. OUR SERVICES AND SOFTWARE SHALL BE PROVIDED ON AN "AS IS" AND/OR
                "AS AVAILABLE" BASIS. <span id="static_company_contact_name_uc" class="saved-var company_contact_name_uc step-1">VELVETYSOFT</span> AND OUR SUBSIDIARIES, AFFILIATES,
                OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS EXPRESSLY DISCLAIM ANY AND
                ALL WARRANTIES OF ANY KIND WHETHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT
                LIMITED TO ANY IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A
                PARTICULAR PURPOSE AND NON-INFRINGEMENT.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">b) <span id="static_company_contact_name_uc" class="saved-var company_contact_name_uc step-1">VELVETYSOFT</span><span style="mso-spacerun:yes">&nbsp;</span>AND OUR SUBSIDIARIES, OFFICERS,
                EMPLOYEES, AGENTS, PARTNERS AND LICENSORS MAKE NO SUCH WARRANTIES THAT (i) <span id="static_company_contact_name_uc" class="saved-var company_contact_name_uc step-1">VELVETYSOFT</span><span style="mso-spacerun:yes">&nbsp;</span>SERVICES OR SOFTWARE WILL MEET YOUR
                REQUIREMENTS; (ii) <span id="static_company_contact_name_uc" class="saved-var company_contact_name_uc step-1">VELVETYSOFT</span> <span style="mso-spacerun:yes">&nbsp;</span>SERVICES OR SOFTWARE SHALL BE UNINTERRUPTED,
                TIMELY, SECURE OR ERROR-FREE; (iii) THAT SUCH RESULTS WHICH MAY BE OBTAINED
                FROM THE USE OF THE <span id="static_company_contact_name_uc" class="saved-var company_contact_name_uc step-1">VELVETYSOFT</span> <span style="mso-spacerun:yes">&nbsp;</span>SERVICES OR SOFTWARE WILL BE ACCURATE OR
                RELIABLE; (iv) QUALITY OF ANY PRODUCTS, SERVICES, ANY INFORMATION OR OTHER
                MATERIAL WHICH MAY BE PURCHASED OR OBTAINED BY YOU THROUGH OUR SERVICES OR
                SOFTWARE WILL MEET YOUR EXPECTATIONS; AND (v) THAT ANY SUCH ERRORS CONTAINED IN
                THE SOFTWARE SHALL BE CORRECTED.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">c) ANY INFORMATION
                OR MATERIAL DOWNLOADED OR OTHERWISE OBTAINED BY WAY OF <span id="static_company_contact_name_uc" class="saved-var company_contact_name_uc step-1">VELVETYSOFT</span><span style="mso-spacerun:yes">&nbsp;</span>SERVICES OR SOFTWARE SHALL BE ACCESSED
                BY YOUR SOLE DISCRETION AND SOLE RISK, AND AS SUCH YOU SHALL BE SOLELY
                RESPONSIBLE FOR AND HEREBY WAIVE ANY AND ALL CLAIMS AND CAUSES OF ACTION WITH
                RESPECT TO ANY DAMAGE TO YOUR COMPUTER AND/OR INTERNET ACCESS, DOWNLOADING AND/OR
                DISPLAYING, OR FOR ANY LOSS OF DATA THAT COULD RESULT FROM THE DOWNLOAD OF ANY
                SUCH INFORMATION OR MATERIAL.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">d) NO ADVICE
                AND/OR INFORMATION, DESPITE WHETHER WRITTEN OR ORAL, THAT MAY BE OBTAINED BY
                YOU FROM <span id="static_company_contact_name_uc" class="saved-var company_contact_name_uc step-1">VELVETYSOFT</span> <span style="mso-spacerun:yes">&nbsp;</span>OR
                BY WAY OF OR FROM OUR SERVICES OR SOFTWARE SHALL CREATE ANY WARRANTY NOT
                EXPRESSLY STATED IN THE TERMS.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;text-align:justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">e) A SMALL
                PERCENTAGE OF SOME USERS MAY EXPERIENCE SOME DEGREE OF EPILEPTIC SEIZURE WHEN
                EXPOSED TO CERTAIN LIGHT PATTERNS OR BACKGROUNDS THAT MAY BE CONTAINED ON A
                COMPUTER SCREEN OR WHILE USING OUR SERVICES. CERTAIN CONDITIONS MAY INDUCE A
                PREVIOUSLY UNKNOWN CONDITION OR UNDETECTED EPILEPTIC SYMPTOM IN USERS WHO HAVE
                SHOWN NO HISTORY OF ANY PRIOR SEIZURE OR EPILEPSY. SHOULD YOU, ANYONE YOU KNOW
                OR ANYONE IN YOUR FAMILY HAVE AN EPILEPTIC CONDITION, PLEASE CONSULT A PHYSICIAN
                IF YOU EXPERIENCE ANY OF THE FOLLOWING SYMPTOMS WHILE USING OUR SERVICES:
                DIZZINESS, ALTERED VISION, EYE OR MUSCLE TWITCHES, LOSS OF AWARENESS,
                DISORIENTATION, ANY INVOLUNTARY MOVEMENT, OR CONVULSIONS.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><b style="mso-bidi-font-weight:normal"><span style="font-size:16.0pt;font-family:&quot;Times New Roman&quot;,serif">SECTION 13 - LIMITATION
                OF LIABILITY</span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">YOU
                EXPLICITLY ACKNOWLEDGE, UNDERSTAND AND AGREE THAT <span id="static_company_contact_name_uc" class="saved-var company_contact_name_uc step-1">VELVETYSOFT</span> AND
                OUR SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND
                LICENSORS SHALL NOT BE LIABLE TO YOU FOR ANY PUNITIVE, INDIRECT, INCIDENTAL,
                SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO,
                DAMAGES WHICH MAY BE RELATED TO THE LOSS OF ANY PROFITS, GOODWILL, USE, DATA
                AND/OR OTHER INTANGIBLE LOSSES, EVEN THOUGH WE MAY HAVE BEEN ADVISED OF SUCH
                POSSIBILITY THAT SAID DAMAGES MAY OCCUR, AND RESULT FROM:</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;line-height:normal"><span style="font-size:12.0pt;
                font-family:&quot;Times New Roman&quot;,serif">a) THE USE OR INABILITY TO USE OUR
                SERVICE; </span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;line-height:
                normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;line-height:normal"><span style="font-size:12.0pt;
                font-family:&quot;Times New Roman&quot;,serif">b) THE COST OF PROCURING SUBSTITUTE GOODS
                AND SERVICES;</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-indent:
                3.0pt;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;line-height:normal"><span style="font-size:12.0pt;
                font-family:&quot;Times New Roman&quot;,serif">c) UNAUTHORIZED ACCESS TO OR THE
                ALTERATION OF YOUR TRANSMISSIONS AND/OR DATA; </span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;line-height:
                normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;line-height:normal"><span style="font-size:12.0pt;
                font-family:&quot;Times New Roman&quot;,serif">d) STATEMENTS OR CONDUCT OF ANY SUCH THIRD
                PARTY ON OUR SERVICE; </span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;line-height:
                normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;line-height:normal"><span style="font-size:12.0pt;
                font-family:&quot;Times New Roman&quot;,serif">e) AND ANY OTHER MATTER WHICH MAY BE
                RELATED TO OUR SERVICE.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><b style="mso-bidi-font-weight:normal"><span style="font-size:16.0pt;font-family:&quot;Times New Roman&quot;,serif">SECTION 14 - RELEASE</span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">In
                the event you have a dispute, you agree to release <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span>(and its officers, directors, employees, agents, parent subsidiaries,
                affiliates, co-branders, partners and any other third parties) from claims,
                demands and damages (actual and consequential) of every kind and nature, known
                and unknown, suspected or unsuspected, disclosed and undisclosed, arising out
                of or in any way connected to such dispute.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><b style="mso-bidi-font-weight:normal"><span style="font-size:16.0pt;font-family:&quot;Times New Roman&quot;,serif">SECTION 15 - NOTICE</span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"> <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span><span style="mso-spacerun:yes">&nbsp;</span>may furnish you with notices, including
                those with regards to any changes to the Terms, including but not limited to
                email, regular mail, MMS or SMS, text messaging, postings on our website Services,
                or other reasonable means currently known or any which may be herein after
                developed. Any such notices may not be received if you violate any aspects of
                the Terms by accessing our Services in an unauthorized manner. Your acceptance
                of this Agreement constitutes your agreement that you are deemed to have
                received any and all notices that would have been delivered had you accessed
                our Services in an authorized manner.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><b style="mso-bidi-font-weight:normal"><span style="font-size:16.0pt;font-family:&quot;Times New Roman&quot;,serif">SECTION 16 - INTELLECTUAL
                PROPERTY RIGHTS</span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">You
                herein acknowledge, understand and agree that all of the <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span>trademarks, copyright, trade name, service marks, and other <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span>logos and any brand features, and/or product and service names are trademarks
                and as such, are and shall remain the property of <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span>. You
                herein agree not to display and/or use in any manner the <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span>logo or marks without obtaining <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span>'s prior written
                consent.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"> <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span><span style="mso-spacerun:yes">&nbsp;</span>will always respect the intellectual
                property of others, and we ask that all of our users do the same. With regards
                to appropriate circumstances and at its sole discretion, <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span><span style="mso-spacerun:yes">&nbsp;</span>may disable and/or terminate the
                accounts of any user who violates our Terms and/or infringes the rights of
                others. If you feel that your work has been duplicated in such a way that would
                constitute copyright infringement, or if you believe your intellectual property
                rights have been otherwise violated, you should provide to us the following
                information:</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;line-height:normal"><span style="font-size:12.0pt;
                font-family:&quot;Times New Roman&quot;,serif">a) The electronic or the physical
                signature of the individual that is authorized on behalf of the owner of the copyright
                or other intellectual property interest;</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;line-height:
                normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;line-height:normal"><span style="font-size:12.0pt;
                font-family:&quot;Times New Roman&quot;,serif">b) A description of the copyrighted work
                or other intellectual property that you believe has been infringed upon;</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-indent:
                2.4pt;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;line-height:normal"><span style="font-size:12.0pt;
                font-family:&quot;Times New Roman&quot;,serif">c) A description of the location of the
                site which you allege has been infringing upon your work;</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;line-height:
                normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;line-height:normal"><span style="font-size:12.0pt;
                font-family:&quot;Times New Roman&quot;,serif">d) Your physical address, telephone
                number, and email address;</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;line-height:
                normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;line-height:normal"><span style="font-size:12.0pt;
                font-family:&quot;Times New Roman&quot;,serif">e) A statement, in which you state that
                the alleged and disputed use of your work is not authorized by the copyright
                owner, its agents or the law;</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;line-height:
                normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoListParagraph" style="margin-bottom:0in;margin-bottom:.0001pt;
                mso-add-space:auto;line-height:normal"><span style="font-size:12.0pt;
                font-family:&quot;Times New Roman&quot;,serif">f) And finally, a statement, made under
                penalty of perjury, that the aforementioned information in your notice is truthful
                and accurate, and that you are the copyright or intellectual property owner,
                representative or agent authorized to act on the copyright or intellectual
                property owner's behalf.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">The
                <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span> <span style="mso-spacerun:yes">&nbsp;</span>agent for notice
                of claims of copyright or other intellectual property infringement can be
                contacted as follows:</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">Mailing
                Address:</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"> <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">Attn:
                Copyright Agent</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"> <span id="static_company_contact_address" class="saved-var company_contact_address step-1">100 Pheasant Woods Ct</span></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"> <span id="static_company_contact_city" class="saved-var company_contact_city step-1">Loveland</span>,
                <span id="static_company_contact_state" class="saved-var company_contact_state step-1">Ohio</span> <span id="static_company_contact_zip_code" class="saved-var company_contact_zip_code step-1">45140</span></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">Telephone:
                <span id="static_company_contact_telephone" class="empty-var company_contact_telephone step-2"></span></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                color:#9BBB59;mso-themecolor:accent3"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">Email:<span style="mso-tab-count:1">&nbsp; </span><span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>msteele@velvethammerbranding.com</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><b style="mso-bidi-font-weight:normal"><span style="font-size:16.0pt;font-family:&quot;Times New Roman&quot;,serif">SECTION 17 - ENTIRE
                AGREEMENT </span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">This
                Agreement constitutes the entire agreement between you and <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span>and shall govern the use of our Services, superseding any prior version of this
                Agreement between you and us with respect to <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span> Services.
                You may also be subject to additional terms and conditions that may apply when
                you use or purchase certain other <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span> Services, affiliate Services,
                third-party content or third-party software.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"><span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><b style="mso-bidi-font-weight:normal"><span style="font-size:16.0pt;font-family:&quot;Times New Roman&quot;,serif">SECTION 18 - CHOICE
                OF LAW AND FORUM</span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">It
                is at the mutual agreement of both you and <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span> with regard
                to the Agreement that the relationship between the parties shall be governed by
                the laws of the state of <span id="static_govern_agreement_state" class="saved-var govern_agreement_state step-4">Ohio</span> without regard to its
                conflict of law provisions and that any and <a name="OLE_LINK1">all claims,
                causes of action and/or disputes, arising out of or relating to the </a>Agreement,
                or the relationship between you and <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span>, shall be filed
                within the courts having jurisdiction within the County of <span id="static_court_county" class="saved-var court_county step-5">Warren</span>, <span id="static_court_state" class="saved-var court_state step-5">Ohio</span><span style="mso-spacerun:yes">&nbsp;</span>or the U.S. District Court located in
                said state. You and <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span> agree to submit to the
                jurisdiction of the courts as previously mentioned, and agree to waive any and
                all objections to the exercise of jurisdiction over the parties by such courts
                and to venue in such courts.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><b style="mso-bidi-font-weight:normal"><span style="font-size:16.0pt;font-family:&quot;Times New Roman&quot;,serif">SECTION 19 - WAIVER
                AND SEVERABILITY OF TERMS</span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">At
                any time, should <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span> <span style="mso-spacerun:yes">&nbsp;</span>fail to exercise or enforce any right or
                provision of the Agreement, such failure shall not constitute a waiver of such
                right or provision. If any provision of this Agreement is found by a court of
                competent jurisdiction to be invalid, the parties nevertheless agree that the
                court should endeavor to give effect to the parties' intentions as reflected in
                the provision, and the other provisions of the Agreement remain in full force
                and effect.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><b style="mso-bidi-font-weight:normal"><span style="font-size:16.0pt;font-family:&quot;Times New Roman&quot;,serif">SECTION 20 - NO RIGHT
                OF SURVIVORSHIP NON-TRANSFERABILITY</span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">You
                acknowledge, understand and agree that your account is non-transferable and any
                rights to your ID and/or contents within your account shall terminate upon your
                death. Upon receipt of a copy of a death certificate, your account may be
                terminated and all contents therein permanently deleted.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><b style="mso-bidi-font-weight:normal"><span style="font-size:16.0pt;font-family:&quot;Times New Roman&quot;,serif">SECTION 21 - STATUTE
                OF LIMITATIONS</span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">You
                acknowledge, understand and agree that regardless of any statute or law to the
                contrary, any claim or action arising out of or related to the use of our Services
                or the Agreement must be filed within <span id="static_statute_of_limitations" class="saved-var statute_of_limitations step-6">1</span> year(s) after
                said claim or cause of action arose or shall be forever barred.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><b style="mso-bidi-font-weight:normal"><span style="font-size:16.0pt;font-family:&quot;Times New Roman&quot;,serif">SECTION 22 - VIOLATIONS</span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">Please
                report any and all violations of this Agreement to <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span> as
                follows:</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">Mailing
                Address:</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"> <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"> <span id="static_company_contact_address" class="saved-var company_contact_address step-1">100 Pheasant Woods Ct</span></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"> <span id="static_company_contact_city" class="saved-var company_contact_city step-1">Loveland</span>,
                <span id="static_company_contact_state" class="saved-var company_contact_state step-1">Ohio</span> <span id="static_company_contact_zip_code" class="saved-var company_contact_zip_code step-1">45140</span></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">Telephone:
                <span id="static_company_contact_telephone" class="empty-var company_contact_telephone step-2"></span></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                color:#9BBB59;mso-themecolor:accent3"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">Email:
                msteele@velvethammerbranding.com</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><b style="mso-bidi-font-weight:normal"><span style="font-size:16.0pt;font-family:&quot;Times New Roman&quot;,serif">SECTION 23 - GOVERNMENT
                REQUESTS</span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">In
                order to cooperate with governmental requests, subpoenas or court orders, to
                protect our systems, or to ensure the integrity and operation of our business
                and systems, we may access and disclose any information we consider necessary
                or appropriate, including and without limitation, your information, IP address,
                and usage history. &nbsp;Our right to disclose any such information is governed
                by the terms of our Privacy Policy.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><b style="mso-bidi-font-weight:normal"><span style="font-size:16.0pt;font-family:&quot;Times New Roman&quot;,serif">SECTION 24 - FOREIGN
                ACCESS OF SITE</span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">The
                Site is controlled, operated and administered by <span id="static_company_contact_short_name" class="saved-var company_contact_short_name step-1">Velvetysoft</span>from our offices within the USA. If you access the Site from a location outside
                the USA, you are responsible for compliance with all local laws. You agree that
                you will not use <span id="static_company_contact_short_name" class="saved-var company_contact_short_name step-1">Velvetysoft</span>'s content accessed through the
                Site in any country or in any manner prohibited by any applicable laws,
                restrictions or regulations.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><b style="mso-bidi-font-weight:normal"><span style="font-size:16.0pt;font-family:&quot;Times New Roman&quot;,serif">SECTION 25 -
                ERRORS, INACCURACIES AND OMISSIONS</span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">Occasionally
                there may be information on our Site that contains typographical errors,
                inaccuracies or omissions that may relate to product descriptions, pricing,
                promotions, offers, product shipping charges, transit times and availability.
                We reserve the right to correct any errors, inaccuracies or omissions, and to
                change or update information or cancel orders if any information on the Site or
                on any related Site is inaccurate at any time without prior notice (including
                after you have submitted your order).</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">We
                undertake no obligation to update, amend or clarify information on the Site or
                on any related Site, including without limitation, pricing information, except
                as required by law. No specified update or refresh date applied on the Site or
                on any related Site, should be taken to indicate that all information on the
                Site or on any related Site has been modified or updated.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                color:#9BBB59;mso-themecolor:accent3"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><b style="mso-bidi-font-weight:normal"><span style="font-size:16.0pt;font-family:&quot;Times New Roman&quot;,serif">SECTION 26 -
                PRIVACY POLICY</span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif"><span style="mso-spacerun:yes">&nbsp;</span></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif">Every
                member's registration data and various other personal information are strictly
                protected by the <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span> Online Privacy Policy (see the full
                Privacy Policy at <span id="static_privacy_policy_url_lc" class="saved-var privacy_policy_url_lc step-3">show-pony.us/privacy</span>). As a member, you herein consent
                to the collection and use of the information provided, including the transfer
                of information within the United States and/or other countries for storage,
                processing or use by <span id="static_company_contact_name" class="saved-var company_contact_name step-1">Velvetysoft</span> and/or our subsidiaries and
                affiliates. </span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                color:#9BBB59;mso-themecolor:accent3"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal"><span style="font-family:&quot;Times New Roman&quot;,serif"></span></p>
                </div>
        </div>
        <div *ngIf="page == '/privacy'">
            <div class="WordSection1">
                <p class="MsoNormal" align="center" style="margin-bottom:0in;margin-bottom:.0001pt;
                text-align:center;line-height:27.0pt;mso-outline-level:1"><b><span style="font-size:16.0pt;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333;mso-font-kerning:18.0pt">ONLINE PRIVACY POLICY
                AGREEMENT</span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:27.0pt;mso-outline-level:1"><b><span style="font-size:22.5pt;
                font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:&quot;Times New Roman&quot;;
                color:#333333;mso-font-kerning:18.0pt"></span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:13.5pt"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333"> <span id="static_company_contact_name" class="saved-var company_contact_name step-0">Velvetysoft</span>,
                ( <span id="static_company_contact_short_name" class="saved-var company_contact_short_name step-0">Velvetysoft</span>), is committed to keeping any and all personal
                information collected of those individuals that visit our website and make use
                of our online facilities and services accurate, confidential, secure and
                private. Our privacy policy has been designed and created to ensure those
                affiliated with <span id="static_company_contact_name" class="saved-var company_contact_name step-0">Velvetysoft</span> of our commitment and realization of
                our obligation not only to meet but to exceed most existing privacy standards. </span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:13.5pt"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333"><span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:13.5pt"><b style="mso-bidi-font-weight:normal"><i style="mso-bidi-font-style:normal"><span style="font-size:12.0pt;font-family:
                &quot;Times New Roman&quot;,serif;mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333">THEREFORE</span></i></b><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333">, this Privacy Policy Agreement shall apply to
                <span id="static_company_contact_name" class="saved-var company_contact_name step-0">Velvetysoft</span></span><b style="mso-bidi-font-weight:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:#E36C0A;
                mso-themecolor:accent6;mso-themeshade:191"></span></b><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333">, and thus it shall govern any and all data
                collection and usage thereof. Through the use of <span id="static_company_contact_website" class="saved-var company_contact_website step-0">Show-pony.us</span></span><b style="mso-bidi-font-weight:normal"><span style="font-size:12.0pt;font-family:
                &quot;Times New Roman&quot;,serif;color:#E36C0A;mso-themecolor:accent6;mso-themeshade:
                191"></span></b><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333"> you are herein
                consenting to the following data procedures expressed within this agreement. </span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:13.5pt"><b style="mso-bidi-font-weight:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:#E36C0A;
                mso-themecolor:accent6;mso-themeshade:191"></span></b></p>
                <p class="MsoNormal" style="line-height:normal"><b style="mso-bidi-font-weight:
                normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                color:#E36C0A;mso-themecolor:accent6;mso-themeshade:191"></span></b></p>
                <p class="MsoNormal" style="line-height:normal"><b style="mso-bidi-font-weight:
                normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                color:#E36C0A;mso-themecolor:accent6;mso-themeshade:191"></span></b></p>
                <p class="MsoNormal" style="line-height:normal"><b style="mso-bidi-font-weight:
                normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                color:#E36C0A;mso-themecolor:accent6;mso-themeshade:191"></span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal;mso-outline-level:2"><b style="mso-bidi-font-weight:
                normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                color:#E36C0A;mso-themecolor:accent6;mso-themeshade:191"></span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify"><span style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333">It is highly
                recommended and suggested that you review the privacy policies and statements
                of any website you choose to use or frequent as a means to better understand
                the way in which other websites garner, make use of and share information
                collected. </span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:13.5pt"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333"></span></p>
                <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.25in;margin-bottom:.0001pt;text-align:justify;line-height:13.5pt"><b><u><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333">Collection of Information</span></u></b></p>
                <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.25in;margin-bottom:.0001pt;text-align:justify;line-height:13.5pt"><b style="mso-bidi-font-weight:normal"><span style="font-size:12.0pt;font-family:
                &quot;Times New Roman&quot;,serif;color:#E36C0A;mso-themecolor:accent6;mso-themeshade:
                191"></span></b><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333">This website collects various types of
                information, such as:</span></p>
                <p class="MsoListParagraphCxSpFirst" style="margin-top:0in;margin-right:0in;
                margin-bottom:0in;margin-left:.25in;margin-bottom:.0001pt;mso-add-space:auto;
                text-align:justify;line-height:normal;mso-outline-level:2"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333"></span></p>
                <p class="MsoListParagraphCxSpMiddle" style="margin-top:0in;margin-right:0in;
                margin-bottom:0in;margin-left:.25in;margin-bottom:.0001pt;mso-add-space:auto;
                text-align:justify;line-height:normal;mso-outline-level:2"><b style="mso-bidi-font-weight:
                normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                color:#E36C0A;mso-themecolor:accent6;mso-themeshade:191"></span></b></p>
                <p class="MsoListParagraphCxSpMiddle" style="margin-top:0in;margin-right:0in;
                margin-bottom:0in;margin-left:.25in;margin-bottom:.0001pt;mso-add-space:auto;
                text-align:justify;line-height:normal;mso-outline-level:2"><b style="mso-bidi-font-weight:
                normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                color:#E36C0A;mso-themecolor:accent6;mso-themeshade:191"></span></b><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333"></span></p>
                <p class="MsoListParagraphCxSpMiddle" style="margin-top:0in;margin-right:0in;
                margin-bottom:0in;margin-left:.75in;margin-bottom:.0001pt;mso-add-space:auto;
                text-indent:-.25in;line-height:13.5pt;mso-list:l4 level1 lfo7"><!--[if !supportLists]--><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333"><span style="mso-list:Ignore">-<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><!--[endif]--><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333">Voluntarily provided
                information which may include your name, address, email </span></p>
                <p class="MsoListParagraphCxSpLast" style="margin-top:0in;margin-right:0in;
                margin-bottom:0in;margin-left:.75in;margin-bottom:.0001pt;mso-add-space:auto;
                line-height:13.5pt"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333">address, billing
                and/or credit card information etc., which may be used when you purchase
                products and/or services and to deliver the services you have requested.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;line-height:
                13.5pt"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-indent:
                .25in;line-height:13.5pt"><b style="mso-bidi-font-weight:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:#E36C0A;
                mso-themecolor:accent6;mso-themeshade:191"></span></b></p>
                <p class="MsoListParagraph" style="margin-top:0in;margin-right:0in;margin-bottom:
                0in;margin-left:.25in;margin-bottom:.0001pt;mso-add-space:auto;text-align:justify;
                line-height:normal;mso-outline-level:2"><b style="mso-bidi-font-weight:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:#E36C0A;
                mso-themecolor:accent6;mso-themeshade:191"></span></b><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-indent:
                .25in;line-height:13.5pt"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333"></span></p>
                <p class="MsoListParagraphCxSpFirst" style="margin-top:0in;margin-right:0in;
                margin-bottom:0in;margin-left:.75in;margin-bottom:.0001pt;mso-add-space:auto;
                text-indent:-.25in;line-height:13.5pt;mso-list:l4 level1 lfo7"><!--[if !supportLists]--><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333"><span style="mso-list:Ignore">-<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><!--[endif]--><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333">Information automatically
                collected when visiting our website, which may include </span></p>
                <p class="MsoListParagraphCxSpMiddle" style="margin-top:0in;margin-right:0in;
                margin-bottom:0in;margin-left:.75in;margin-bottom:.0001pt;mso-add-space:auto;
                line-height:13.5pt"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333">cookies, third party
                tracking technologies and server logs. </span></p>
                <p class="MsoListParagraphCxSpMiddle" style="margin-top:0in;margin-right:0in;
                margin-bottom:0in;margin-left:.25in;margin-bottom:.0001pt;mso-add-space:auto;
                text-align:justify;line-height:normal;mso-outline-level:2"><b style="mso-bidi-font-weight:
                normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                color:#E36C0A;mso-themecolor:accent6;mso-themeshade:191"></span></b></p>
                <p class="MsoListParagraphCxSpLast" style="margin-top:0in;margin-right:0in;
                margin-bottom:0in;margin-left:.25in;margin-bottom:.0001pt;mso-add-space:auto;
                text-align:justify;line-height:normal;mso-outline-level:2"><b style="mso-bidi-font-weight:
                normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                color:#E36C0A;mso-themecolor:accent6;mso-themeshade:191"></span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;line-height:
                13.5pt"><b style="mso-bidi-font-weight:normal"><span style="font-size:12.0pt;
                font-family:&quot;Times New Roman&quot;,serif;color:#E36C0A;mso-themecolor:accent6;
                mso-themeshade:191"></span></b><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333"></span></p>
                <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.25in;margin-bottom:.0001pt;text-align:justify"><span style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333">Please rest assured
                that this site shall only collect personal information that you knowingly and
                willingly provide by way of surveys, completed membership forms, and emails. It
                is the intent of this site to use personal information only for the purpose for
                which it was requested and any additional uses specifically provided on this
                site. </span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify"><span style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333"><span style="mso-tab-count:6">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:normal;mso-outline-level:2"><b style="mso-bidi-font-weight:
                normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                color:#E36C0A;mso-themecolor:accent6;mso-themeshade:191"></span></b></p>
                <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.25in;margin-bottom:.0001pt;text-align:justify"><span style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333"> <span id="static_company_contact_name" class="saved-var company_contact_name step-0">Velvetysoft</span>may have the occasion to collect non-personal anonymous demographic information,
                such as age, gender, household income, political affiliation, race and
                religion, as well as the type of browser you are using, IP address, type of
                operating system, at a later time, that will assist us in providing and
                maintaining superior quality service.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify"><span style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333"><span style="mso-tab-count:6">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></p>
                <p class="MsoListParagraphCxSpFirst" style="margin-top:0in;margin-right:0in;
                margin-bottom:0in;margin-left:.25in;margin-bottom:.0001pt;mso-add-space:auto;
                text-align:justify"><b style="mso-bidi-font-weight:normal"><u><span style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333">Use of Information
                Collected</span></u></b></p>
                <p class="MsoListParagraphCxSpLast" style="margin-top:0in;margin-right:0in;
                margin-bottom:0in;margin-left:.25in;margin-bottom:.0001pt;mso-add-space:auto;
                text-align:justify"><span style="font-size:12.0pt;line-height:115%;font-family:
                &quot;Times New Roman&quot;,serif;mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333"> <span id="static_company_contact_name" class="saved-var company_contact_name step-0">Velvetysoft</span>may collect and may make use of personal information to assist in the operation
                of our website and to ensure delivery of the services you need and request. At
                times, we may find it necessary to use personally identifiable information as a
                means to keep you informed of other possible products and/or services that may
                be available to you from <span id="static_company_contact_website" class="saved-var company_contact_website step-0">Show-pony.us</span></span><b style="mso-bidi-font-weight:
                normal"><span style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
                color:#E36C0A;mso-themecolor:accent6;mso-themeshade:191"></span></b><span style="font-size:12.0pt;
                line-height:115%;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333">. <span id="static_company_contact_name" class="saved-var company_contact_name step-0">Velvetysoft</span></span><b style="mso-bidi-font-weight:normal"><span style="font-size:12.0pt;line-height:
                115%;font-family:&quot;Times New Roman&quot;,serif;color:#E36C0A;mso-themecolor:accent6;
                mso-themeshade:191"></span></b><span style="font-size:12.0pt;
                line-height:115%;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333"> may also be in contact with you with regards
                to completing surveys and/or research questionnaires related to your opinion of
                current or potential future services that may be offered.<b style="mso-bidi-font-weight:
                normal"><u></u></b></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify"><b style="mso-bidi-font-weight:normal"><span style="font-size:12.0pt;
                line-height:115%;font-family:&quot;Times New Roman&quot;,serif;color:#E36C0A;mso-themecolor:
                accent6;mso-themeshade:191"></span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify"><span style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333"></span></p>
                <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.25in;margin-bottom:.0001pt;text-align:justify"><span style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333"> <span id="static_company_contact_name" class="saved-var company_contact_name step-0">Velvetysoft</span>does not now, nor will it in the future, sell, rent or lease any of its
                customer lists and/or names to any third parties.</span></p>
                <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.25in;margin-bottom:.0001pt;text-align:justify"><b style="mso-bidi-font-weight:normal"><span style="font-size:12.0pt;line-height:
                115%;font-family:&quot;Times New Roman&quot;,serif;color:#E36C0A;mso-themecolor:accent6;
                mso-themeshade:191"></span></b><span style="font-size:12.0pt;
                line-height:115%;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify"><b style="mso-bidi-font-weight:normal"><span style="font-size:12.0pt;
                line-height:115%;font-family:&quot;Times New Roman&quot;,serif;color:#E36C0A;mso-themecolor:
                accent6;mso-themeshade:191"></span></b><span style="font-size:12.0pt;
                line-height:115%;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify"><b style="mso-bidi-font-weight:normal"><span style="font-size:12.0pt;
                line-height:115%;font-family:&quot;Times New Roman&quot;,serif;color:#E36C0A;mso-themecolor:
                accent6;mso-themeshade:191"></span></b><span style="font-size:12.0pt;
                line-height:115%;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify"><b style="mso-bidi-font-weight:normal"><span style="font-size:12.0pt;
                line-height:115%;font-family:&quot;Times New Roman&quot;,serif;color:#E36C0A;mso-themecolor:
                accent6;mso-themeshade:191"></span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify"><b style="mso-bidi-font-weight:normal"><span style="font-size:12.0pt;
                line-height:115%;font-family:&quot;Times New Roman&quot;,serif;color:#E36C0A;mso-themecolor:
                accent6;mso-themeshade:191"></span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify"><span style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333"></span></p>
                <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.25in;margin-bottom:.0001pt;text-align:justify"><span style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;"> <span id="static_company_contact_name" class="saved-var company_contact_name step-0">Velvetysoft</span> may deem it
                necessary to follow websites and/or pages that their users may frequent in an
                effort to gleam what types of services and/or products may be the most popular
                to customers or the general public.</span></p>
                <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.25in;margin-bottom:.0001pt;text-align:justify"><b style="mso-bidi-font-weight:normal"><span style="font-size:12.0pt;line-height:
                115%;font-family:&quot;Times New Roman&quot;,serif;color:#E36C0A;mso-themecolor:accent6;
                mso-themeshade:191"></span></b><span style="font-size:12.0pt;
                line-height:115%;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify"><span style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333"></span></p>
                <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.25in;margin-bottom:.0001pt;text-align:justify"><span style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333"> <span id="static_company_contact_name" class="saved-var company_contact_name step-0">Velvetysoft</span>may disclose your personal information, without any prior notice to you, unless
                required to do in accordance to applicable laws and/or in a good faith belief
                that such action is deemed necessary or required in an effort to:</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify"><span style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333"></span></p>
                <p class="MsoListParagraphCxSpFirst" style="margin-top:0in;margin-right:0in;
                margin-bottom:0in;margin-left:.75in;margin-bottom:.0001pt;mso-add-space:auto;
                text-indent:-.25in;mso-list:l4 level1 lfo7"><!--[if !supportLists]--><span style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333"><span style="mso-list:Ignore">-<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><!--[endif]--><span style="font-size:12.0pt;line-height:115%;
                font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:&quot;Times New Roman&quot;;
                color:#333333">Remain in conformance with any decrees, laws and/or statutes or
                in an effort to comply with any process which may be served upon <span id="static_company_contact_name" class="saved-var company_contact_name step-0">Velvetysoft</span>and/or its website;</span></p>
                <p class="MsoListParagraphCxSpMiddle" style="margin-bottom:0in;margin-bottom:
                .0001pt;mso-add-space:auto"><span style="font-size:12.0pt;line-height:115%;
                font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:&quot;Times New Roman&quot;;
                color:#333333"></span></p>
                <p class="MsoListParagraphCxSpMiddle" style="margin-top:0in;margin-right:0in;
                margin-bottom:0in;margin-left:.75in;margin-bottom:.0001pt;mso-add-space:auto;
                text-indent:-.25in;line-height:normal;mso-list:l4 level1 lfo7"><!--[if !supportLists]--><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333"><span style="mso-list:Ignore">-<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><!--[endif]--><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333">Maintain, safeguard
                and/or preserve all the rights and/or property of <span id="static_company_contact_name" class="saved-var company_contact_name step-0">Velvetysoft</span>; and</span></p>
                <p class="MsoListParagraphCxSpMiddle" style="margin-bottom:0in;margin-bottom:
                .0001pt;mso-add-space:auto;line-height:normal"><span style="font-size:12.0pt;
                font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:&quot;Times New Roman&quot;;
                color:#333333"></span></p>
                <p class="MsoListParagraphCxSpMiddle" style="margin-top:0in;margin-right:0in;
                margin-bottom:0in;margin-left:.75in;margin-bottom:.0001pt;mso-add-space:auto;
                text-indent:-.25in;line-height:normal;mso-list:l4 level1 lfo7"><!--[if !supportLists]--><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333"><span style="mso-list:Ignore">-<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span><!--[endif]--><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333">Perform under
                demanding conditions in an effort to safeguard the personal safety of <span style="mso-spacerun:yes">&nbsp;</span></span></p>
                <p class="MsoListParagraphCxSpMiddle" style="margin-top:0in;margin-right:0in;
                margin-bottom:0in;margin-left:.75in;margin-bottom:.0001pt;mso-add-space:auto;
                line-height:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333">users of <span id="static_company_contact_website" class="saved-var company_contact_website step-0">Show-pony.us</span>and/or the general public. </span></p>
                <p class="MsoListParagraphCxSpMiddle" style="margin-bottom:0in;margin-bottom:
                .0001pt;mso-add-space:auto;line-height:normal"><span style="font-size:12.0pt;
                font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:&quot;Times New Roman&quot;;
                color:#333333"></span></p>
                <p class="MsoListParagraphCxSpMiddle" style="margin-top:0in;margin-right:0in;
                margin-bottom:0in;margin-left:.25in;margin-bottom:.0001pt;mso-add-space:auto;
                text-align:justify;line-height:normal;mso-outline-level:2"><b><u><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333">Non-Marketing Purposes</span></u></b></p>
                <p class="MsoListParagraphCxSpMiddle" style="margin-top:0in;margin-right:0in;
                margin-bottom:0in;margin-left:.25in;margin-bottom:.0001pt;mso-add-space:auto;
                text-align:justify;line-height:normal;mso-outline-level:2"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333;mso-bidi-font-weight:bold"> <span id="static_company_contact_name" class="saved-var company_contact_name step-0">Velvetysoft</span>greatly respects your privacy.<span style="mso-spacerun:yes">&nbsp; </span>We do
                maintain and reserve the right to contact you if needed for non-marketing
                purposes (such as bug alerts, security breaches, account issues, and/or changes
                in <span id="static_company_contact_name" class="saved-var company_contact_name step-0">Velvetysoft</span> products and services).<span style="mso-spacerun:yes">&nbsp; </span>In certain circumstances, we may use our
                website, newspapers, or other public means to post a notice.<b><u></u></b></span></p>
                <p class="MsoListParagraphCxSpMiddle" style="margin-top:0in;margin-right:0in;
                margin-bottom:0in;margin-left:.25in;margin-bottom:.0001pt;mso-add-space:auto;
                text-align:justify;line-height:normal;mso-outline-level:2"><b style="mso-bidi-font-weight:
                normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                color:#E36C0A;mso-themecolor:accent6;mso-themeshade:191"></span></b></p>
                <p class="MsoListParagraphCxSpMiddle" style="margin-top:0in;margin-right:0in;
                margin-bottom:0in;margin-left:.25in;margin-bottom:.0001pt;mso-add-space:auto;
                text-align:justify;line-height:normal;mso-outline-level:2"><b style="mso-bidi-font-weight:
                normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                color:#E36C0A;mso-themecolor:accent6;mso-themeshade:191"></span></b><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333;mso-bidi-font-weight:bold"></span></p>
                <p class="MsoListParagraphCxSpMiddle" style="margin-top:0in;margin-right:0in;
                margin-bottom:0in;margin-left:.25in;margin-bottom:.0001pt;mso-add-space:auto;
                text-align:justify;line-height:normal;mso-outline-level:2"><b><u><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333">Children Under Age of 13</span></u></b></p>
                <p class="MsoListParagraphCxSpMiddle" style="margin-top:0in;margin-right:0in;
                margin-bottom:0in;margin-left:.25in;margin-bottom:.0001pt;mso-add-space:auto;
                text-align:justify;line-height:normal;mso-outline-level:2"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333;mso-bidi-font-weight:bold"> <span id="static_company_contact_name" class="saved-var company_contact_name step-0">Velvetysoft</span>does not knowingly collect personal identifiable information from children
                under the age of thirteen (13) without verifiable parental consent. If it is
                determined that such information has been inadvertently collected on anyone
                under the age of thirteen (13), we shall immediately take the necessary steps
                to ensure that such information is deleted from our system's database. Anyone
                under the age of thirteen (13) must seek and obtain parent or guardian
                permission to use this website.</span></p>
                <p class="MsoListParagraphCxSpMiddle" style="margin-top:0in;margin-right:0in;
                margin-bottom:0in;margin-left:.25in;margin-bottom:.0001pt;mso-add-space:auto;
                text-align:justify;line-height:normal;mso-outline-level:2"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333;mso-bidi-font-weight:bold"></span></p>
                <p class="MsoListParagraphCxSpMiddle" style="margin-top:0in;margin-right:0in;
                margin-bottom:0in;margin-left:.25in;margin-bottom:.0001pt;mso-add-space:auto;
                text-align:justify;line-height:normal;mso-outline-level:2"><b style="mso-bidi-font-weight:
                normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                color:#E36C0A;mso-themecolor:accent6;mso-themeshade:191"></span></b><b><u><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333"></span></u></b></p>
                <p class="MsoListParagraphCxSpLast" style="margin-top:0in;margin-right:0in;
                margin-bottom:0in;margin-left:.25in;margin-bottom:.0001pt;mso-add-space:auto;
                text-align:justify;line-height:normal;mso-outline-level:2"><b><u><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333">Unsubscribe or Opt-Out</span></u></b><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333;mso-bidi-font-weight:bold"></span></p>
                <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.25in;margin-bottom:.0001pt;text-align:justify;line-height:13.5pt"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333">All users and/or visitors to our website have
                the option to discontinue receiving communication from us and/or reserve the
                right to discontinue receiving communications by way of email or newsletters.
                To discontinue or unsubscribe to our website please send an email that you wish
                to unsubscribe to</span><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;"> <a name="OLE_LINK1">msteele@velvethammerbranding.com</a>.
                <span style="color:#333333">If you wish to unsubscribe or opt-out from any
                third party websites, you must go to that specific website to unsubscribe
                and/or opt-out. <span style="mso-bidi-font-weight:bold"> <span id="static_company_contact_name" class="saved-var company_contact_name step-0">Velvetysoft</span>will continue to adhere to the privacy policy described herein with respect to
                any personal information previously collected.</span></span></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:13.5pt"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333"></span></p>
                <p class="MsoListParagraph" style="margin-top:0in;margin-right:0in;margin-bottom:
                0in;margin-left:.25in;margin-bottom:.0001pt;mso-add-space:auto;text-align:justify;
                line-height:normal;mso-outline-level:2"><b><u><span style="font-size:12.0pt;
                font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:&quot;Times New Roman&quot;;
                color:#333333">Links to Other Web Sites</span></u></b></p>
                <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.25in;margin-bottom:.0001pt;text-align:justify;line-height:normal;
                mso-outline-level:2"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333;mso-bidi-font-weight:
                bold">Our website does contain links to affiliate and other websites. <span id="static_company_contact_name" class="saved-var company_contact_name step-0">Velvetysoft</span>does not claim nor accept responsibility for any privacy policies, practices
                and/or procedures of other such websites. Therefore, we encourage all users and
                visitors to be aware when they leave our website and to read the privacy
                statements of each and every website that collects personally identifiable
                information. The aforementioned Privacy Policy Agreement applies only and
                solely to the information collected by our website. </span></p>
                <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.25in;margin-bottom:.0001pt;text-align:justify;line-height:normal;
                mso-outline-level:2"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333;mso-bidi-font-weight:
                bold"></span></p>
                <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.25in;margin-bottom:.0001pt;text-align:justify;line-height:normal;
                mso-outline-level:2"><b><u><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333">Security</span></u></b><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333;mso-bidi-font-weight:bold"></span></p>
                <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.25in;margin-bottom:.0001pt;text-align:justify;line-height:13.5pt"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333"> <span id="static_company_contact_name" class="saved-var company_contact_name step-0">Velvetysoft</span> shall endeavor and
                shall take every precaution to maintain adequate physical, procedural and
                technical security with respect to its offices and information storage
                facilities so as to prevent any loss, misuse, unauthorized access, disclosure
                or modification of the user's personal information under our control. </span></p>
                <p class="MsoListParagraph" style="margin-top:0in;margin-right:0in;margin-bottom:
                0in;margin-left:.25in;margin-bottom:.0001pt;mso-add-space:auto;text-align:justify;
                line-height:normal;mso-outline-level:2"><b style="mso-bidi-font-weight:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:#E36C0A;
                mso-themecolor:accent6;mso-themeshade:191"></span></b><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:13.5pt"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333"></span></p>
                <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.25in;margin-bottom:.0001pt;text-align:justify;line-height:13.5pt"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333">The company also uses Secure Socket Layer
                (SSL) for authentication and private communications in an effort to build
                users' trust and confidence in the internet and website use by providing simple
                and secure access and communication of credit card and personal information. </span><b style="mso-bidi-font-weight:normal"><span style="font-size:12.0pt;font-family:
                &quot;Times New Roman&quot;,serif;color:#E36C0A;mso-themecolor:accent6;mso-themeshade:
                191"></span></b><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333"></span></p>
                <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.25in;margin-bottom:.0001pt;text-align:justify;line-height:13.5pt"><b style="mso-bidi-font-weight:normal"><span style="font-size:12.0pt;font-family:
                &quot;Times New Roman&quot;,serif;color:#E36C0A;mso-themecolor:accent6;mso-themeshade:
                191"></span></b></p>
                <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.25in;margin-bottom:.0001pt;text-align:justify;line-height:13.5pt"><b style="mso-bidi-font-weight:normal"><span style="font-size:12.0pt;font-family:
                &quot;Times New Roman&quot;,serif;color:#E36C0A;mso-themecolor:accent6;mso-themeshade:
                191"></span></b></p>
                <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.25in;margin-bottom:.0001pt;text-align:justify;line-height:13.5pt"><b><u><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333">Changes to Privacy Policy Agreement</span></u></b><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333"></span></p>
                <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.25in;margin-bottom:.0001pt;text-align:justify;line-height:13.5pt;
                tab-stops:.25in"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333"> <span id="static_company_contact_name" class="saved-var company_contact_name step-0">Velvetysoft</span>reserves the right to update and/or change the terms of our privacy policy, and
                as such we will post those changes to our website, so that our users and/or
                visitors are always aware of the type of information we collect, how it will be
                used, and under what circumstances, if any, we may disclose such information.
                If at any point in time <span id="static_company_contact_name" class="saved-var company_contact_name step-0">Velvetysoft</span> decides to make use of any
                personally identifiable information on file, in a manner vastly different from
                that which was stated when this information was initially collected, the user
                or users shall be promptly notified by email. Users at that time shall have the
                option as to whether or not to permit the use of their information in this
                separate manner. </span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:13.5pt"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333"></span></p>
                <p class="MsoListParagraph" style="margin-top:0in;margin-right:0in;margin-bottom:
                0in;margin-left:.25in;margin-bottom:.0001pt;mso-add-space:auto;text-align:justify;
                line-height:13.5pt"><b style="mso-bidi-font-weight:normal"><u><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333">Acceptance of Terms</span></u></b></p>
                <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.25in;margin-bottom:.0001pt;text-align:justify;line-height:13.5pt"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333">Through the use of this website, you are
                hereby accepting the terms and conditions stipulated within the aforementioned
                Privacy Policy Agreement. If you are not in agreement with our terms and
                conditions, then you should refrain from further use of our sites. In addition,
                your continued use of our website following the posting of any updates or
                changes to our terms and conditions shall mean that you are in agreement and
                acceptance of such changes. </span></p>
                <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.25in;margin-bottom:.0001pt;text-align:justify;line-height:13.5pt"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333"></span></p>
                <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.25in;margin-bottom:.0001pt;text-align:justify;line-height:13.5pt"><b><u><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333">How to Contact Us</span></u></b><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333"></span></p>
                <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:0in;
                margin-left:.25in;margin-bottom:.0001pt;text-align:justify;line-height:13.5pt"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333">If you have any questions or concerns
                regarding the Privacy Policy Agreement related to our website, please feel free
                to contact us at the following email, telephone number or mailing address.</span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-align:
                justify;line-height:13.5pt"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-indent:
                .25in;line-height:13.5pt"><b style="mso-bidi-font-weight:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333">Email:</span></b><span style="font-size:12.0pt;
                font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:&quot;Times New Roman&quot;;
                color:#333333"> msteele@velvethammerbranding.com</span></p>
                <p class="MsoListParagraph" style="margin-top:0in;margin-right:0in;margin-bottom:
                0in;margin-left:.25in;margin-bottom:.0001pt;mso-add-space:auto;text-align:justify;
                line-height:normal;mso-outline-level:2"><b style="mso-bidi-font-weight:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;color:#E36C0A;
                mso-themecolor:accent6;mso-themeshade:191"></span></b><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-indent:
                .25in;line-height:13.5pt"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333"></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-indent:
                .25in;line-height:13.5pt"><b style="mso-bidi-font-weight:normal"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:
                &quot;Times New Roman&quot;;color:#333333">Mailing Address:</span></b></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-indent:
                .25in;line-height:13.5pt"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333"><span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span> <span id="static_company_contact_name" class="saved-var company_contact_name step-0">Velvetysoft</span></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-indent:
                .25in;line-height:13.5pt"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333"><span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span> <span id="static_company_contact_address" class="saved-var company_contact_address step-0">100 Pheasant Woods Ct</span></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-indent:
                .25in;line-height:13.5pt"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333"><span style="mso-tab-count:1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span> <span id="static_company_contact_city" class="saved-var company_contact_city step-0">Loveland</span>, <span id="static_company_contact_state" class="saved-var company_contact_state step-0">Ohio</span> <span id="static_company_contact_zip_code" class="saved-var company_contact_zip_code step-0">45140</span><br style="mso-special-character:line-break">
                <!--[if !supportLineBreakNewLine]--><br style="mso-special-character:line-break">
                <!--[endif]--></span></p>
                <p class="MsoNormal" style="margin-bottom:0in;margin-bottom:.0001pt;text-indent:
                .25in;line-height:13.5pt"><span style="font-size:12.0pt;font-family:&quot;Times New Roman&quot;,serif;
                mso-fareast-font-family:&quot;Times New Roman&quot;;color:#333333"> <span id="static_effective_date_cfdy" class="saved-var effective_date_cfdy step-">April 13, 2023</span></span></p>
                </div>
        </div>
    </div>
</div>
