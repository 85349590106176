
<div class="row mb-3" >
    <div class="col-sm-2">
        <mat-form-field class="mat-select-wrapper">            
            <mat-select-trigger class="rel">
                <button mat-button><mat-icon>add</mat-icon> Customize Widgets </button>
            </mat-select-trigger>
            <mat-select [(ngModel)]="widgetsSelected" (selectionChange)="updateWdigets()" multiple >                
                <ng-container *ngFor="let group of widgetsAvalible">
                    <!-- <mat-optgroup [label]="group.group"> -->
                        <mat-option *ngFor="let widget of group.widgets" [value]="widget.key">
                            {{widget.title}}
                        </mat-option>
                    <!-- </mat-optgroup> -->
                </ng-container>
            </mat-select>
        </mat-form-field>
    </div>
</div>

<div *ngIf="( $widgets | async ); let widgets; else loading">
    
    <div *ngIf="widgets == 'none'">
        <div class="row">
            <div class="col-sm-8">
                <div class="tile">
                    <div class="d-flex align-items-center">
                    <div class="pr-3">
                        <mat-icon>widgets</mat-icon>
                    </div>
                    <div class="">
                        <div class="label">Customize your wigets</div>
                        <div class="content ">
                            Select the widgets you would like to see on your dashboard
                        </div>
                    </div>
                    </div>            
                </div>        
            </div>
        </div>
    </div>

    <div class="row" >
        <div class="col-sm-4" *ngIf="widgets.total_users">
            <app-widget label="Total users" [value]="widgets.total_users" [trendColors]="colors"></app-widget>
        </div>
        <div class="col-sm-4" *ngIf="widgets.total_assets">
            <app-widget label="Total Assets" [value]="widgets.total_assets" [trendColors]="colors"></app-widget>
        </div>
        <div class="col-sm-4" *ngIf="widgets.total_projects">
            <app-widget label="Total Projects" [value]="widgets.total_projects" [trendColors]="colors"></app-widget>            
        </div>
        <div class="col-sm-6" *ngIf="widgets.revenue">
            <app-widget 
                label="Revenue" 
                [value]="widgets.revenue" 
                [trendColors]="colors" 
                format="currency"
                [chart]="{options: options, type: 'revenue'}"></app-widget>      
                
        </div>

        <div class="col-sm-12" *ngIf="widgets.revenue_brand">
            <app-widget 
                label="Revenue" 
                [value]="widgets.revenue_brand" 
                [trendColors]="colors" 
                format="currency"
                [chart]="{options: options, type: 'revenue_brand'}"></app-widget>      
                
        </div>


        <div class="col-sm-4" *ngIf="widgets.total_proposals">
            <app-widget 
                label="Total Proposals" 
                queryid="total_proposals"
                [value]="widgets.total_proposals"
                [range]="true">
            </app-widget>             
        </div>
    </div>
    <div class="row" >
        <div class="col-sm-4" *ngIf="widgets.total_invoices_paid">
            <div class="block">
                <div class="block-content">
                    <div class="subhead">Invoices Paid | Amount</div>
                    <span class="dot-label active">
                        {{widgets.total_invoices_paid}}
                    </span>
                    <span>
                    </span>
                    <span class="dot-label active">
                        {{widgets.total_invoices_paid_amount | currency:'USD':true}}
                    </span>
                </div>
            </div>
        </div>
        <div class="col-sm-4" *ngIf="widgets.total_UNUSED">
            <div class="block">
                <div class="block-content">
                    <div class="subhead">Total Invoices Unpaid</div>
                <span class="dot-label active">
                    {{widgets.total_users}}
                </span>
                </div>
            </div>
        </div>
        <div class="col-sm-4" *ngIf="widgets.total_UNUSED">
            <div class="block">
                <div class="block-content">
                    <div class="subhead">Total Proposals</div>
                    <span class="dot-label active">
                        {{widgets.total_proposals}}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div> 


<ng-template #loading>
    <mat-spinner>loading</mat-spinner>
</ng-template>