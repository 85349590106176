<div class="d-flex h-100 align-items-center">
    <div class="container">
        <div class="text-center">
            <a routerLink="/"><img src="https://vh-vault.s3.us-east-1.amazonaws.com/static/showpony-logo.png" alt="Show Pony" style="max-width: 280px;" /></a>
        </div>
        <div class="text-center my-5">
            <h2 class="block-title-label mx-auto" style="display: inline-block!important">Check your email for a code</h2>
            <p>We’ve sent a 6-character code to {{email}}. The code expires shortly, so please enter it soon.</p>
        </div>
      <div class="text-center" *ngIf="!loading; else loader">
        <div class="d-flex align_items_center justify-content-center margin_bottom_50" data-qa="confirmation_code_input" data-state="">
            <div class="d-flex align_items_center">
                <div class="split_input_item">                    
                    <input type="text" #num0 [(ngModel)]="code[0]" (paste)="handlePaste($event)" (keyup)="goToNext($event, 0)" maxlength="1" aria-label="digit 1 of 6" aria-disabled="false" aria-invalid="false" >
                </div>
                <div class="split_input_item">
                    <input type="text" #num1 [(ngModel)]="code[1]" (keyup)="goToNext($event, 1)" maxlength="1" aria-label="digit 2 of 6" aria-disabled="false" aria-invalid="false" >
                </div>
                <div class="split_input_item">
                    <input type="text" #num2 [(ngModel)]="code[2]" (keyup)="goToNext($event, 2)" maxlength="1" aria-label="digit 3 of 6" aria-disabled="false" aria-invalid="false" >
                </div>
            </div>

            <div class="d-flex align_items_center">
                <div class="span-cell">—</div>
            </div>

            <div class="d-flex align_items_center">
                <div class="split_input_item">
                    <input type="text" #num3 [(ngModel)]="code[3]" (keyup)="goToNext($event, 3)" maxlength="1" aria-label="digit 4 of 6" aria-disabled="false" aria-invalid="false" >
                </div>
                <div class="split_input_item">
                    <input type="text" #num4 [(ngModel)]="code[4]" (keyup)="goToNext($event, 4)" maxlength="1" aria-label="digit 5 of 6" aria-disabled="false" aria-invalid="false" >
                </div>
                <div class="split_input_item">
                    <input type="text" #num5 [(ngModel)]="code[5]" (keyup)="submit($event,5)" maxlength="1" aria-label="digit 6 of 6" aria-disabled="false" aria-invalid="false" >
                </div>
            </div>
        </div>
      </div>      
  
      

      <ng-template #loader>
        <mat-spinner color="primary" class="mx-auto loader"></mat-spinner>
      </ng-template>
    </div>
  </div>