

  <div *ngIf="!file">
    <span style="color: #2e2b2b;">
      <label mat-flat-button  [classList]="csss">
        <mat-icon *ngIf="icon">upload</mat-icon>
         <span *ngIf="text">{{ text }}</span>
         <input type="file" name="file" hidden multiple (change)="uploadFile($event)">
      </label>
      <div class="label my-2" *ngIf="type == 'company'">Max Width: 2500px, Max Size: 5MB</div>
    </span>
  </div>
  
  <div *ngIf="file">
    <img *ngIf="!hideFile" class="file-uploaded" [src]="file" />
    <ng-container *ngIf="removeBtn">
      <button mat-button>Remove</button>
    </ng-container>
    <div class="label" *ngIf="type == 'company'">Max Width: 2500px, Max Size: 5MB</div>
    <span class="cursor remove" (click)="remove(file)">
      <mat-icon>clear</mat-icon>
    </span>
  </div>


  <!--
    
<ng-container *ngIf="type == 'company'; else normal">

  <div *ngIf="!file">
    <span style="color: #2e2b2b;">      
        <label class="btn btn-link">
        Upload Logo <input type="file" name="file" hidden multiple (change)="uploadFile($event)">
        </label>
    </span>
  </div>

  <div *ngIf="file">    
    <span class="cursor d-inline-flex" (click)="remove(file)"><mat-icon>clear</mat-icon> remove </span>
  </div>

</ng-container>

<ng-template #normal>
  <div *ngIf="!file">
    <span style="color: #2e2b2b;">
      <label class="btn btn-primary">
        {{ text }}<input type="file" name="file" hidden multiple (change)="uploadFile($event)">
      </label>
    </span>
  </div>
  
  <div *ngIf="file">
    <img class="file-uploaded" src="{{ file }}" />
    <span class="d-inline-flex cursor" (click)="remove(file)">
      <mat-icon>clear</mat-icon> remove
    </span>
  </div>
</ng-template>

  -->
