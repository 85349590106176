import { Component, ViewChild,  AfterContentInit } from '@angular/core';
import { ProjectService } from '../../_services/project.service';
import { Observable } from 'rxjs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BrandService } from '../../_services/brand.service';
import { Router } from '@angular/router';
import { VelvetysoftService } from '../../_services/velvetysoft.service';  
import { ProjectModel, SettingsSubject, UserProfileModel } from "../../_models/subjects";
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { UserService } from 'src/app/_services/user.service';



@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements AfterContentInit {
  $projects: Observable<ProjectModel[]>;    
  displayedColumns: string[] = ['select', 'status','name', 'type', 'due', 'user', 'actions'];
  sortedData: ProjectModel[];
  project_type: any;
  //subject: string = 'Project';
  filteredProjects: string[] = ['active'];  
  subject: string = 'project';
  $userProfile: Observable<UserProfileModel>;
  subjectObj: SettingsSubject = {
    subject_type: '',
    label: '',
    id: '',
    icon: '',
    path: '',
    adminPath: '',
  }
  @ViewChild('vaultTable') vaultTable;
  

  constructor(
    private projectService: ProjectService,
    public dialog: MatDialog,
    public brandService: BrandService,
    public vs: VelvetysoftService,
    private snack: MatSnackBar,
    private router: Router,
    private userService: UserService
  ) {
  }

  add(project?:ProjectModel,options?:any) {
    this.projectService.edit(project,options);
  }

  delete(){    
    let ids = this.vaultTable.selection.selected.map(p => p.id);
    this.projectService.deleteDialog(ids);
  }


  navTo(id){
    this.router.navigate([`/dashboard/project/${id}`]);    
  }

  ngAfterContentInit() {
    this.$projects = this.projectService.projects;
    this.project_type = this.vs.settings.project;            
    this.subjectObj = this.vs.findSubject(this.subject);   
    this.$userProfile = this.userService.userProfile();
    this.brandService.activeBrand.subscribe( brand =>{
      this.vs.title([brand.name, 'Projects']);
    })
  }
  

}

