<div class="container" *ngIf="( $timesheets | async ); let timesheetsData; else nousers">

  <app-alert></app-alert>

  <div class="block">
    <div class="block-title">
      <div class="block-title-label" for="">Manage Timesheets</div>
      <div class="block-title-toolbar">
        <!-- <button mat-button (click)="addInvoice()" color="primary">
          <mat-icon>add</mat-icon>New Invoice
        </button>
        &nbsp;
        <button mat-button [matMenuTriggerFor]="menu" color="primary">
          <mat-icon>sort</mat-icon>Actions
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item>Delete</button>
        </mat-menu> -->
      </div>
    </div>
    <div class="block-content">
      <button mat-stroked-button (click)="prevWeek()">
        <mat-icon>chevron_left</mat-icon>
      </button>
      &nbsp;
      <mat-form-field>
        <mat-label>Choose a date</mat-label>
        <input matInput (dateChange)="selectWeek('change', $event)" [value]="currentWeek" [matDatepicker]="picker">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>            
      &nbsp;
      <button mat-stroked-button (click)="nextWeek()"><mat-icon>chevron_right</mat-icon></button>      
    
      <div *ngIf="($brandList | async);let brands">
        
        <div *ngIf="timesheetsData.sheets; let userSheets; else noTimeSheets">
          
          <table mat-table multiTemplateDataRows [dataSource]="userSheets" class="vault-table">

            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>  </th>
              <td mat-cell class="cap" *matCellDef="let element">
                
              </td>
            </ng-container>

            <ng-container matColumnDef="hours">
              <th mat-header-cell *matHeaderCellDef> Hours </th>
              <td mat-cell class="cap" *matCellDef="let element">
                {{ element.hours }} ({{ element.total | currency:'USD':true:'1.2-2' }})
              </td>
            </ng-container>
            
            <ng-container matColumnDef="user">
              <th mat-header-cell *matHeaderCellDef> User </th>
              <td mat-cell class="cap" *matCellDef="let element">
                <user-avatar [user]="findUser(element.user)" showName="true"></user-avatar>
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef> Status </th>
              <td mat-cell class="cap" *matCellDef="let element">
                <span *ngIf="element.approved; else elseBlock" class="dot-label active" >Approved {{ element.approved_at | amFromUtc | date }}</span>                
                <ng-template #elseBlock><span class="dot-label hold">Not Approved</span></ng-template>
                
              </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th class="text-right" mat-header-cell *matHeaderCellDef> Action </th>
              <td mat-cell class="cap text-right" *matCellDef="let element">                
                <button 
                  class="mat-sm" 
                  mat-icon-button 
                  mat-button 
                  matTooltip="View Timesheet"
                  (click)="toggle(element)">
                  <mat-icon>visibility</mat-icon>
                </button>                
                <button 
                  *ngIf="element.approved"
                  class="mat-sm" 
                  mat-icon-button 
                  mat-button 
                  matTooltip="Unlock Timesheet"
                  (click)="unlock(element.data)">
                  <mat-icon>lock_open</mat-icon>
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let element" [attr.colspan]="timesheetHeaders.length" class="details-expandable" [class.expanded]="element == expandedElement" >
                <div class="element-detail py-4 px-5" *ngIf="element.recordStatus === I_INVALID_RECORD_INDICATOR">

                  
                  
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="ts-bars mb-4">
                        <div class="avg" [style.bottom]="getPercent(avg)+'%'"></div>
                        <div class="bar" *ngFor="let day of days">
                          <div 
                              *ngIf="getDay(element.week,day); let percent" 
                              class="fill" 
                              [style.height]="percent.p+'%'"
                              [matTooltip]="percent.hrs+' Hours'"></div>
                          <div class="day-label">{{ day | amDateFormat: 'ddd' }}</div>
                        </div>                    
                      </div>
                    </div>
                    <div class="col-sm-8"></div>
                    <div class="col-sm-12">

                      <div class="row mb-3">
                        <div class="col-sm-6">
                          <div class="row" >
                            <div class="col-sm-5">Type</div>
                            <div class="col-sm-3">Brand</div>
                            <div class="col-sm-4">Project</div>
                          </div>
                        </div>
                        <div class="col-sm-5">
                          <div class="row">
                            <div class="col-sm-2" *ngFor="let day of days">
                              <div >{{ day | amDateFormat: 'ddd' }}</div>
                              <div class="subhead">{{ day | amDateFormat: 'MMM D' }}</div>
                            </div>
                          </div>
                        </div>                    
                      </div>
                      <div class="row" *ngFor="let item of element.data">
                        <div class="col-sm-6 line-sep" *ngIf="!item?.skip">
                          <div class="row">
                            <div class="col-sm-5">
                              <div *ngIf="findType(item.type);let type">
                                {{ type }}
                              </div>
                            </div>
                            <div class="col-sm-3" >
                              <div *ngIf="findBrand(brands,item.brand_id);let brand">
                                {{ brand.name  }}
                              </div>
                            </div>
                            <div class="col-sm-4">
                              <div *ngIf="findBrand(brands,item.brand_id,item.project_id);let project">
                                {{ project.name }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-5 line-sep" *ngIf="!item?.skip">
                          <div class="row">
                            <div class="col-sm-2" *ngFor="let day of days">
                              <div *ngIf="compare(day,item.dates,item); let hours">{{ hours }}</div>
                            </div>
                          </div>
                        </div>                    
                      </div>   

                    </div>
                  </div>
                  

                                 

                </div>
              </td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="timesheetHeaders"></tr>
            <tr mat-row 
              *matRowDef="let row; columns: timesheetHeaders;" 
              class="expandable"
              [class.expanded]="expandedElement === row"
              ></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expandable-row"></tr>
          </table>

        </div>
        <ng-template #noTimeSheets>
          <p>No Timesheets found for this week</p>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<ng-template #nousers>
  <mat-spinner color="primary" class="mx-auto loader"></mat-spinner>
</ng-template>