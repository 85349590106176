<div class="vault-modal">
    
    <button mat-button mat-button-base class="float-right" (click)="close()">
        <mat-icon class="mat-sm">close</mat-icon>   
    </button>

    <h1 mat-dialog-title> <user-avatar showFullName="true" [user]="tsi.user_id" ></user-avatar> {{ data.title }}</h1>

        <div mat-dialog-content *ngIf="( $userProfile | async ); let profile">

            
            <app-alert></app-alert>
            
            <div class="form-group mt-3">
                <div class="mt-4 px-4" *ngIf="tsi">
                    <div class="form-group">
                    <div class="row">
                        <div class="col-sm-12">
                        <div class="form-group">
                            <div class="mb-3"><meta-display subject="expense" [item]="tsi" type="statuses" [id]="tsi.status"></meta-display></div>
                            <div><span class="label mr-2 min-w-100">Title:</span> {{tsi.start | date: 'MMM'}} Invoice</div>
                            <!-- <div><span class="label mr-2 min-w-100">From:</span> {{ expense.from }}</div> -->
                            <div><span class="label mr-2 min-w-100">Amount:</span> {{ tsi.amount | currency }}</div>
                            <div><span class="label mr-2 min-w-100">Submitted:</span> {{ tsi.created_at | date }}</div>
                            <!-- <div><span *ngIf="tsi.user_invoice_ref" class="label mr-2 min-w-100">User Invoice Ref:</span> {{ tsi.user_invoice_ref }}</div> -->
                            <div><span *ngIf="tsi.payment_ref" class="label mr-2 min-w-100">Payment Ref:</span> {{ tsi.payment_ref }}</div>
                        </div>
                        </div>
                        <div class="col-sm-12">                      
                            <button *ngIf="tsi.status == 'submitted'" class="mr-1" (click)="paid(tsi)" mat-raised-button color="accent"><mat-icon>check</mat-icon> Mark Paid</button>
                            <button mat-raised-button  class="mr-1" (click)="view(tsi)" color="primary">View Invoice</button>    
                            <button mat-raised-button  class="mr-1" (click)="download(tsi)" color="primary">Download Invoice</button>     
                            <button mat-button  class="mr-1" (click)="delete(tsi)" color="warn">Delete</button>                            
                        </div>
                    </div>
                </div>

                

                <div class="hr mt-3"></div>

                <div class="block-title-label cap-init">History for this Timesheet Invoice</div>
                <app-activity-log
                    [ids]="[tsi.id]"
                    subject="timesheet-invoice"
                    exclude=""
                    ></app-activity-log>

                
            </div>

            

        </div>
        
       
    
</div>


