import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ApiService } from './api.service';
//private apiService: ApiService
//return this.apiService.post<FileUploaded>(`file`, formData)

export interface FileUploaded {
  status: boolean
  file: string
}

@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor(
    private apiService: ApiService,
    private snack: MatSnackBar,
  ) { }

  sendFile(formData: any): Observable<any> {  
    // MAYBE MOVE THIS TO API RESULTS AND ADD ACTION INTO DATA  
    // this.snack.open(`File Uploading`, "Dismiss", {
    //   duration: 5000,
    // });
    return this.apiService.file<FileUploaded>(`file`, formData)
  }
}
