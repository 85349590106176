<div>    
    <div  [formGroup]="form" class="mt-2">
        <div formArrayName="expenses"  *ngFor="let expense of formExpenses; let i = index">
            <div [class.e-item]="!SAVED" [class.mb-3]="!SAVED" class="row m-0 align-items-center " [formGroupName]="i" >

                <ng-container *ngIf="SAVED; else editing">
                    <div class="col-sm-12 cell">
                        <div class="row m-0 table-text align-items-center">
                            <div class="col-sm-3">
                                Expense #{{i+1}}
                            </div>
                            <div class="col-sm-6">
                                {{ findType(expense.value.type) }}
                            </div>
                            <div class="col-sm-3">
                                {{ expense.value.total | currency:'USD':true:'1.2-2' }}
                            </div>
                        </div>
                    </div>
                    
                </ng-container>

                <ng-template #editing>
                    <div class="col-sm-10 cell">
                        <div class="removeit">
                            <button                             
                            mat-icon-button 
                            color="primary" 
                            matTooltip="Remove"
                            matTooltipPosition="right" 
                            (click)="removeline(i)" 
                            class="quick-remove">
                                <mat-icon>remove</mat-icon> Delete
                        </button>
                        </div>
                        <div class="row m-0">
                            <div class="col-sm-8 p-0">
                                <app-project-selector [form]="expense"></app-project-selector>
                            </div>
                            <div class="col-sm-4">
                                <mat-form-field class="w-100">
                                    <mat-select placeholder="Expense Type" formControlName="type">
                                        <mat-option *ngFor="let type of types" [value]="type.value">
                                            {{type.title}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row m-0">
                            <div class="col-sm-8">
                                <mat-form-field class="w-100">
                                    <textarea matInput [rows]="1" placeholder="Notes" formControlName="notes"></textarea>
                                </mat-form-field>
                            </div>
                            <div class="col-sm-4">
                                <mat-form-field class="w-100">
                                    <span matPrefix>$ &nbsp;</span>
                                    <input matInput placeholder="Amount" required autocomplete="off" mask="separator.2" thousandSeparator="," formControlName="total">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>                        
                    <div class="col-sm-2">
                        <!-- <button mat-icon-button color="primary" (click)="receipt()">
                            <mat-icon>upload</mat-icon>
                        </button> -->
                        <app-file-upload 
                            type="receipt"
                            [icon]="true"
                            class="might-be-trans"
                            text="Receipt"
                            (uploadComplete)="uploadReceipt($event,i)" 
                            [_file]="expense.value.receipt">
                        </app-file-upload>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>    
    <div class="mt-2">
        <button  mat-stroked-button color="primary" (click)="addExpense()">
            <mat-icon>add</mat-icon>Expense
        </button>
        &nbsp;
        <button *ngIf="form.value.expenses.length > 0 && SAVED" mat-button color="primary" (click)="editExpense() ">
            Edit
        </button>
        &nbsp;
        <button color="primary" *ngIf="!SAVED" mat-raised-button (click)="saveExpenses()">Save</button>
        <button color="primary" *ngIf="!SAVED" mat-button (click)="dismiss()">Cancel</button>
    </div>
    
    <ng-template #loading>
        <mat-spinner></mat-spinner>
    </ng-template>
</div>