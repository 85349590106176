import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { UntypedFormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { VelvetysoftService  } from '../../../_services/velvetysoft.service';
import { BrandList, BrandModel, Settings, UserProfileModel } from "../../../_models/subjects";
import { FormErrorsService } from '../../../_services/form-errors.service';
import { AlertService } from '../../../_services/alert.service';
import * as moment from "moment";
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { CompareRounds } from '../../../_validators/validators';
import { BrandService } from 'src/app/_services/brand.service';
import { OpenaiService } from 'src/app/_services/openai.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/_services/user.service';
import { ManageUsersDialogComponent } from 'src/app/_directive/manage-users/manage-users.component';

@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ]
})
export class AddProjectComponent implements OnInit {

  form: UntypedFormGroup;
  error: any = false;
  settings: Settings = null;
  subject: string = 'project';
  protectdates = false;
  $brand: Observable<BrandModel>;
  $userProfile: Observable<UserProfileModel>;
  @ViewChild("picker2") roundEstPicker: MatDatepicker<Date>;

  constructor(
    private dialogRef: MatDialogRef<AddProjectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private velvetysoft: VelvetysoftService,
    private formErrors: FormErrorsService,
    private alertService: AlertService,
    private brandService: BrandService,
    private openaiService: OpenaiService,
    private dialog: MatDialog,
  ) {
    this.form = fb.group({
      id: [null],
      name: ['', Validators.required],
      kickoff: [''],
      ongoing: [''],
      budget: [''],
      due: ['', Validators.required],
      type: ['', Validators.required],
      est_round: ['', [Validators.required, ]],
      est_round_dates: this.fb.array([]),
      brand_id: ['', Validators.required],
      status: ['', Validators.required],
      users: []
    },
    {
      // Used custom form validator name
      validator: CompareRounds("est_round", "est_round_dates")
    });
  }

  ngOnInit() {

    this.$brand = this.brandService.$activeBrand;   
    this.formErrors.errors.subscribe(errors => {
      this.formErrors.display(errors, err => this.alertService.error(err));
    })  
    
    //WEIRD ISSUE NO PROJECT KEEPS LAST PROJECT!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    //RESET MODEL    
    //WEIRD FIX    
    if(!this.data[this.subject].est_round_dates) this.data[this.subject].est_round_dates = [];
    
    this.form.patchValue(this.data[this.subject]);
    if(this.data?.options?.proposal > 0) this.form.controls.budget.setValue(this.data.options.proposal);
    
    this.settings = this.velvetysoft.settings[this.subject]         
    
    if(this.data[this.subject].est_round_dates.length > 0){
       if(this.data[this.subject].id) this.protectdates = true;
       this.createEstimatedDates(this.data[this.subject].est_round_dates);
    }

    // IF NO Estimated Dates, Make them
    this.generateEstimatedDates();    

  }

  // DUPLICATE FUNCTION IN OVERVIEW
  manageUsers(item: BrandModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { 
      context: "brand", 
      item: item };
    const dialogRef = this.dialog.open(
      ManageUsersDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result.status === true) {
        this.brandService.updateUsers(result.item);
        this.form.controls.users.patchValue(result.item.users);
        //console.log(result)
      }
    });
  }


  get estRndDates(): UntypedFormArray{
    return this.form.get("est_round_dates") as UntypedFormArray;
  }

  newEstRndDate(date): UntypedFormGroup{
    return this.fb.group({
      date: date
    })
  }

  modDate(){  
    this.protectdates = true;    
  }

  createEstimatedDates(dates){
    dates.forEach(date => {      
      this.estRndDates.push(this.fb.group({ date: [date] }))
    });
  }

  removeEstRndDates(idx){
    this.estRndDates.removeAt(idx);
    this.form.controls.est_round.setValue(this.estRndDates.length);
  }

  addEstRndDates(){    
    this.estRndDates.push(this.newEstRndDate(''));
    this.form.controls.est_round.setValue(this.estRndDates.length);
  }

  generateEstimatedDates(force=false){
    if(this.protectdates && !force) return;
    let created = this.data[this.subject].created_at;
    this.estRndDates.clear();
    let rounds = this.form.controls.est_round.value;
    let due = this.form.controls.due.value;
    
    let today = (this.form.controls.kickoff.value) 
      ? moment(this.form.controls.kickoff.value)
      : (created)
        ? moment(created) 
        : moment();
    let diff = moment(due).diff(today, 'days', false);
    let distro = diff / (rounds+1);

    Array.from({ length: rounds }, () => {      
      let date = today.add(distro,'days').toDate();
      this.estRndDates.push(this.newEstRndDate(date));
    });
   
  }

  removeExtDate(index){
    //console.log(this.data.projectService.removeEstDate())
    this.data.projectService.removeEstDate(this.data[this.subject].id,index)
       .pipe(first())
       .subscribe( dates => { 
        this.data[this.subject].past_due_dates = dates
        console.log(dates) 
      })
  }

 

  save() {
    //this.alertService.error(errRes.error.error);    
    let data = { status: true }
    let res = this.form.value;
    if(res.ongoing) res.est_round_dates = [];
    data[this.subject] = res;
    this.dialogRef.close(data);
  }

  delete() {
    this.dialogRef.close({ status: 'delete', project: this.form.value });    
  }

  close() {    
    this.dialogRef.close();
  }

  ngDestroy(){
    
    
  }

}
