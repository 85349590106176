import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormErrorsService {

  errors: BehaviorSubject<any> = new BehaviorSubject(null);
  constructor() { }

  add(errs){
    this.errors.next(errs);
  }

  apply(_form, errors){
    for (let err in errors) {
      let _errors = errors[err];
      console.log('errrrrs', _errors, err);
      _form.get(err).setErrors(_errors);
    }
  }

  display(errors, cb:Function){
    if(errors && !errors.length) Object.keys(errors).forEach(key => {
      cb(errors[key].join("<br>"));
    });      
  }

  // attach(form){
  //   let _form = form;
  //   this.errors.subscribe((errors) => {
      
  //     for (let err in errors) {
  //       let _errors = errors[err];
  //       console.log('errrrrs', _errors, err);
  //       _form.get(err).setErrors(_errors);
  //     }
  //   })
  // }
  
}
