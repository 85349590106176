<div class="block">
    <div class="block-content">
        <div class="row justify-content-between">
            <div class="col-auto">
                <div class="subhead uc">{{ label }} </div>
            </div>
            <div *ngIf="range" class="col-auto">
                <mat-select 
                    class="date" 
                    (selectionChange)="rangeChange()"
                    [(ngModel)]="rangeValue"
                    placeholder="Time Range" >
                        <mat-option value="day">Day</mat-option>
                        <mat-option value="week">Week</mat-option>
                        <mat-option value="month">Month</mat-option>
                        <mat-option value="year">Year</mat-option>
                </mat-select>     
            </div>
        </div>
        
        
        <!-- CHART -->        

        <!-- TREND -->
        <div *ngIf="value?.total; else basic">
            <span class="widget-label color-primary">
                <ng-container *ngIf="format == 'currency'">{{ value.total | currency }}</ng-container>
                <ng-container *ngIf="!format">{{ value.total }}</ng-container>
            </span>
            <ngx-trend 
                *ngIf="value.trend" 
                [gradient]="trendColors" 
                [data]="value.trend" 
                [autoDraw]="true"
                [height]="70"
                strokeWidth="3"
                [smooth]="true" 
                [radius]="20"
                ></ngx-trend>
        </div>

        <!-- BAISC -->
        <ng-template #basic>
            <span class="widget-label color-primary" >
               
                <ng-container *ngIf="format == 'currency'">{{ value?.total | currency }}</ng-container>
                <ng-container *ngIf="!format">{{ value }}</ng-container>
            </span>
        </ng-template>      
        
        <div *ngIf="chart && value.graph" class="chart-container mt-3">                         
            <app-charts [data]="value.graph" [options]="chart.options" [type]="chart.type"></app-charts>  
        </div>

        
    </div>
</div>