<ng-container *ngIf="( $brandList | async ); let brands">
  <ng-container *ngIf="( $users | async ); let users">
  <div class="container" >
    <div class="block" *ngIf="($userProfile | async); let profile">
      <div class="block-title" *ngIf="subject">
        <div class="block-title-label"><mat-icon *ngIf="subject.icon" class="mr-2">{{ subject.icon }}</mat-icon>{{ subject.label }}</div>      
        <div class="block-title-toolbar">
          <button mat-button color="primary" ><mat-icon>add</mat-icon>New {{subject.label}}</button>
          &nbsp;
          <button mat-button [matMenuTriggerFor]="menu" color="primary"><mat-icon>sort</mat-icon>Actions</button>
          <mat-menu #menu="matMenu" class="menu-sm">
            
          </mat-menu>
        </div>
      </div>
      <div class="block-content">
        <!--          
                       -->
        <div >
          <app-table
            mat-table
            #vaultTable
            matSort #sort="matSort"  
            [dataSource]="$activity"   
            [pager]="activitypager"
            [asyncFilters]="asyncFilters"
            [columns]="displayedColumns"
            > 

            <ng-container matColumnDef="subject">
              <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
              <td mat-cell *matCellDef="let element">
                <div class="d-flex align-items-center">
                  <mat-icon>{{ element.subject?.icon }}</mat-icon>  {{ element.subject?.label }} 
                </div>
              </td>
            </ng-container>
  
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> User </th>
              <td mat-cell *matCellDef="let element" class="text-capitalize" > 
                <user-avatar [user]="element.causer_id" ></user-avatar>
              </td>
            </ng-container>

            <ng-container matColumnDef="created">
              <th mat-header-cell *matHeaderCellDef> Created </th>
              <td mat-cell *matCellDef="let element" class="text-capitalize" > 
                {{element.created_at | date}} <div *ngIf="element.deleted_at" class="mt-3"> <span class="dot-label hold">Deleted {{ element.deleted_at | date }}</span></div></td>
            </ng-container>
            
            <ng-container matColumnDef="event">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Event </th>
              <td mat-cell *matCellDef="let element"> {{ element.description }} </td>
            </ng-container>

            <ng-container matColumnDef="info">
              <th mat-header-cell class="" *matHeaderCellDef> Description </th>
              <td mat-cell class="" *matCellDef="let element">    
                <div >
                  <div>{{ element.properties?.attributes?.title }}</div>
                  <div>
                      <span class="cap-init" *ngIf="element.properties?.attributes?.email">{{ element.properties?.attributes?.email }}</span>
                      <span class="cap-init" *ngIf="element.properties?.attributes?.payment_ref">Ref: {{ element.properties?.attributes?.payment_ref }}</span>
                      <span class="cap-init" *ngIf="element.properties?.attributes?.name">{{ element.properties?.attributes?.name }}</span>
                      <div class="cap-init" *ngIf="element.properties?.attributes?.time_worked">
                        <div>{{ element.properties?.attributes?.time_worked }} Hr -
                        <span *ngIf="findType(element.properties?.attributes?.work_type);let type">{{ type }}</span></div>
                        <div *ngIf="element.properties?.attributes?.brand_id; let bid">
                          <span *ngIf="findBrandProjects(brands,bid); let brand">{{ brand.name  }}</span>
                          <span *ngIf="findBrandProjects(brands,bid,element.properties?.attributes?.project_id); let projects">: {{ projects.name }}</span>
                        </div>
                      </div>
                  </div>
              </div>
              </td>
            </ng-container>
  
            <tr  mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns; "></tr>
  
          </app-table>
            
          <ng-template #noneYet>
            <mat-spinner color="primary" class="mx-auto loader"></mat-spinner>
            <p>No {{ subject }} found</p>
          </ng-template> 
  
        </div>    
      </div>
    </div>
  </div>
  </ng-container>
</ng-container>
  <ng-template #noneYet>
    <p>No {{ subject }} found</p>
  </ng-template>
  <ng-template #noProjects>
    <mat-spinner color="primary" class="mx-auto loader"></mat-spinner>
  </ng-template>