import { AfterContentInit, Component, OnDestroy, Input, Output, QueryList, EventEmitter, ContentChildren, ViewChild, HostListener, Directive, TemplateRef, ContentChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { SelectionModel, DataSource } from '@angular/cdk/collections';
import { VelvetysoftService, } from '../../_services/velvetysoft.service';  
import { Ng2SearchPipe } from "ng2-search-filter";
import { BrandModel, Pager } from 'src/app/_models/subjects';
import { debounceTime } from "rxjs/operators";
//import * as _ from 'lodash';

import {
  MatLegacyColumnDef as MatColumnDef,
  MatLegacyHeaderRowDef as MatHeaderRowDef,
  MatLegacyRowDef as MatRowDef,
  MatLegacyTable as MatTable,
  MatLegacyTableDataSource as MatTableDataSource
} from '@angular/material/legacy-table';
import { Observable, Subject, Subscription } from 'rxjs';
import { UserService } from 'src/app/_services/user.service';
import { BrandService } from 'src/app/_services/brand.service';
import { ProjectService } from 'src/app/_services/project.service';



@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
})


export class TableComponent<T> implements AfterContentInit, OnDestroy {
  @Input() filtered: string[] | null;
  @Input() search: boolean = true;
  @Input() filters: string[] | null;
  @Input() filterOn: string | null;
  @Input() pager: any | null;
  applySearchFilter = new Subject<any>();
  filterOnSettings = {
    filter: null,
    deep: null,
  };

  @Input() options: object = {
    count: null,
    filter: null,
  };
  @Input() columns: string[];
  // columns: string[] = [
  //   "name",
  //   "Brands",
  //   "Projects",
  //   "Role",
  //   "actions",
  // ];
  @Input() dataSource: Observable<any>;

  searchText: string = "";
  //@Input() dataSource: MatTableDataSource<any>;
  dataSourceHandler: any = new MatTableDataSource();
  dataSourceSub: Subscription;

  @Input() public actionsTemplate: TemplateRef<any>;
  @Input() public alertsTemplate: TemplateRef<any>;
  @ContentChildren(MatHeaderRowDef) headerRowDefs: QueryList<MatHeaderRowDef>;
  @ContentChildren(MatRowDef) rowDefs: QueryList<MatRowDef<T>>;
  @ContentChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;
  @ContentChildren(MatSort) sort: MatSort;
  @ViewChild(MatTable, { static: true }) table: MatTable<T>;

  @Input() inputColumnDefs: QueryList<MatColumnDef>;
  @Input() inputHeaderRowDefssssss: QueryList<MatHeaderRowDef>;
  @Input() inputRowDefs: QueryList<MatRowDef<T>>;

  @Input() asyncFilters: any;
  asyncFilterState: any = {
    search: null
  };

  loading: boolean = false;
  project_type: any;
  sortedData: any;
  resolvedSort: any;
  selection = new SelectionModel(true, []);
  // PAGER
  pagerResults: Pager<any> | null; 
  $brands: Observable<BrandModel[]>;
  constructor(
    public vs:VelvetysoftService,
    public userService: UserService,
    private projectService: ProjectService
  ){

  }

  ngAfterContentInit() {
    this.sortData(new MatSort());

    this.applySearchFilter.pipe(
      debounceTime(400))
      .subscribe((event) => this.doSearch());
    //add the keys from this.asyncFilters to this.asyncFilterState as keys not vales
    this.$brands =this.projectService.projectTree;
    
    if(this.asyncFilters){
      Object.keys(this.asyncFilters).forEach((key) => {
        this.asyncFilterState[key] = null;
      });      
      
      if(this.asyncFilters?.deleted !== undefined) this.asyncFilterState.deleted = this.asyncFilters.deleted;
      
      if(this.asyncFilters.status){
        this.asyncFilterState.status = {};
        this.asyncFilters.status.data.map((status) => {
          Object.hasOwnProperty
          this.asyncFilters.status.selected
          this.asyncFilterState.status[status.value] = this.asyncFilters.status.selected.split(',').includes(status.value);
        });
      }

      if(this.asyncFilters.filters){
        this.asyncFilterState.filters = {};
        this.asyncFilters.filters.map((filter) => {
          this.asyncFilterState.filters[filter.field] = {};
          filter.data.map((item) => {
            if(item.value)
              this.asyncFilterState.filters[filter.field][item.value] = (filter.selected == item.value)
                ? true
                : false;
          });
        });
      }

      if(this.asyncFilters.tiles){
        this.asyncFilterState.tiles = {};
        this.asyncFilters.tiles.map((tile) => {
          this.asyncFilterState.tiles[tile.field] = tile.selected
        });
        //this.asyncFilterState.tiles = this.asyncFilters.tiles;        
      }
      //console.log(this.asyncFilterState)
    }

    //console.log(this.inputHeaderRowDefssssss);
    
    if (this.inputColumnDefs) this.columnDefs = this.inputColumnDefs;
    if (this.inputRowDefs) this.rowDefs = this.inputRowDefs;
    if (this.inputHeaderRowDefssssss) this.headerRowDefs = this.inputHeaderRowDefssssss;
    
    this.columnDefs.forEach((columnDef) => this.table.addColumnDef(columnDef));
    this.rowDefs.forEach((rowDef) => this.table.addRowDef(rowDef));
    this.headerRowDefs.forEach((headerRowDef) =>
      this.table.addHeaderRowDef(headerRowDef)
    );

    this.dataSourceSub = this.dataSource.subscribe((data) => {      
      if(this.pager){        
        //THIS IS UGLY THING OF A FIX TO SEPERAT TEH DATA HERE
        this.dataSourceHandler.data = data.data;
        this.pagerResults = data;               
        this.refresh();
        this.sortedData = this.resolvedSort
        this.loading = false;
      }else{
        this.dataSourceHandler.data = data;
      }
      
      this.refresh();
      if(!this.pager) this.applySearchFilter.next();
    });
    // console.log(
    //   this.inputColumnDefs,
    //   this.columnDefs,
    //   this.rowDefs,
    //   this.headerRowDefs,
    //   this.table
    // );
    //console.log(this.loadingRef,'1');
  }

  damnit(text){
    //damn it
    return  text.replace(/ /g, "<br>");
  }

  next(){
    this.loading = true;
    //this.pager.load(this.pagerResults.next_page_url); 
    this.doSearch(this.pagerResults.next_page_url);   
  }

  prev(){
    this.loading = true;
    //this.pager.load(this.pagerResults.prev_page_url);
    this.doSearch(this.pagerResults.prev_page_url);
  }

  /** Change function when a filter is selected or unselected */
  filterItems(value) {
    this.filtered.includes(value)
      ? (this.filtered = this.filtered.filter((item) => item !== value))
      : this.filtered.push(value);
    this.sortData(new MatSort());
    if(!this.pager) this.applySearchFilter.next();
  }

  refresh() {
    this.sortData(new MatSort());
  }

  /** Check if Filter is checked */
  filterIsChecked(value) {
    return this.filtered.includes(value) ? true : false;
  }

  tileSelect(field,val){
    this.asyncFilterState.tiles[field] = val;
    this.doSearch();
  }
  // ngOnChanges(changes) {
  //   const data = this.dataSourceHandler.data.slice();
  //   if (changes && changes.search && changes.search.currentValue) {
  //     this.sortedData = Ng2SearchPipe.filter(
  //       this.sortedData,
  //       changes.search.currentValue
  //     );
  //   } else {
  //     this.sortedData = data;
  //   }
  // }

  buildFilters() {
    if(!this.filterOn) return;
    let FitlersOnArray = this.filterOn.split(":");
    if (FitlersOnArray.length > 1) {
      this.filterOnSettings.filter = FitlersOnArray[0];
      this.filterOnSettings.deep = FitlersOnArray[1];
    } else {
      this.filterOnSettings.filter = FitlersOnArray[0];
    }
  }

  doSearch(url?){
    //EXCEPTION FOR ASYNC VS INPAGE
    if(this.pager){
      this.loading = true;
      this.asyncFilterState.search = this.searchText;
      this.asyncFilterState.size = this.pagerResults.per_page;
      
      let data = {...this.asyncFilterState};
      //console.log(data)

      data.user = data?.user?.id ?? null;      
      if(this.asyncFilterState.status) data.status = Object.keys(this.asyncFilterState.status)
        .filter((key) => this.asyncFilterState.status[key] == true)
        .join(',');
      //data.asyncFilterState.category = 
      
      if(this.asyncFilterState.filters) Object.keys(this.asyncFilterState.filters).forEach((key) => {
        data[key] = Object.keys(this.asyncFilterState.filters[key])
          .filter((key2) => this.asyncFilterState.filters[key][key2] == true)
          .join(',');
        delete(data['filters']);
      });

      if(this.asyncFilterState.tiles) Object.keys(this.asyncFilterState.tiles).forEach((key) => {
        data[key] = this.asyncFilterState.tiles[key];
        //console.log(data)
        // data[key] = Object.keys(this.asyncFilterState.tiles[key])
        //   .filter((key2) => this.asyncFilterState.tiles[key][key2] == true)
        //   .join(',');
        // delete(data['tiles']);
      });



      //console.log(data)
      this.pager.loadSearch(data,url);               
    }else{
      this.sortedData = Ng2SearchPipe.filter(this.resolvedSort, this.searchText);
    }
  }

  // applySearchFilter = _.debounce(
  //   (event) => this.doSearch(),
  //   400
  // )

  clearSearch() {
    this.searchText = "";
    this.applySearchFilter.next();
  }

  /** Sort the table data */
  @HostListener("matSortChange", ["$event"])
  sortData(sort: MatSort) {
    

    const data = this.dataSourceHandler.data.slice();
    this.resolvedSort = data;

    if (!this.filterOnSettings.filter) this.buildFilters();

    //FILTER
    if (this.filterOn) {
      if (!sort.active || sort.direction === "") {
        this.resolvedSort = data.filter((item) => {
          //CHECK IF ARRAY
          if (this.filterOnSettings.deep) {
            let itemsValue = item[this.filterOnSettings.filter].find(
              (i) => i[this.filterOnSettings.deep]
            );
            return this.filtered.includes(
              itemsValue[this.filterOnSettings.deep]
            );
          } else {
            return this.filtered.includes(item[this.filterOn]);
          }
        });
        return;
      }
    }

    //SORT
    // if (this.filterOn) {
    //   this.sortedData = data
    //     .filter((item) => this.filtered.includes(item[this.filterOn]))
    //     .sort((a, b) => {
    //       console.log(a, b);
    //       const isAsc = sort.direction === "asc";
    //       return sort.active
    //         ? compare(a[sort.active], b[sort.active], isAsc)
    //         : 0;
    //     });
    // }
    if(!this.pager) this.applySearchFilter.next();
  }

  searchData() {
    //console.log("filter");
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceHandler.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.sortedData.forEach((row) => {
          //console.log(row);
          return this.selection.select(row)
      });
  }

  ngOnDestroy() {
    this.dataSourceSub.unsubscribe();
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?) {}
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}