import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { VelvetysoftService } from 'src/app/_services/velvetysoft.service';
import { OpenaiService } from 'src/app/_services/openai.service';
import { Observable } from 'rxjs';
import { BriefModel, UserProfileModel } from 'src/app/_models/subjects';
import { BriefService } from 'src/app/_services/brief.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-add-brief',
  templateUrl: './add-brief.component.html',
  styleUrls: ['./add-brief.component.scss']
})
export class AddBriefComponent implements OnInit {
  public Editor = ClassicEditor;
  form: UntypedFormGroup;
  subject: string = "brief";
  error: any = false;
  $brief: Observable<BriefModel>;
  $userProfile: Observable<UserProfileModel>;

  constructor(
    private dialogRef: MatDialogRef<AddBriefComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: UntypedFormBuilder,
    public vs: VelvetysoftService,
    private openaiService: OpenaiService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.$userProfile = this.userService.userProfile();
    //this.$brief = this.data[`${this.subject}Service`].brief(this.data[this.subject]?.id);
    console.log(this.data)
    this.form = this.fb.group({
      id: [""],
      content: ["", Validators.required],
      features: [""],
      fid: [""],
      audience: [""],
      deliverables: [""],
      sent_at: [""],      
      project_id: [""],
    });
    
    if(this.data[this.subject]?.id){      
      this.form.patchValue(this.data[this.subject]);    
    }else{
      this.form.patchValue({project_id: this.data?.options?.project_id});
    }
    
  }

  save() {
    this.dialogRef.close({ status: true, brief: this.form.value });
  }

  delete() {
    this.dialogRef.close({ status: "delete", brief: this.form.value });
  }

  close() {
    this.dialogRef.close();
  }

  prompt(form,field){    
    let prompt = form.value[field];
    this.openaiService.openPrompt(prompt)
    .subscribe(result => {
        let data = [];
        data[field] = result;
        form.patchValue(data);
    });
  }

  view(brief){
    this.data[`${this.subject}Service`].download(brief,true) 
  }

  download(brief: BriefModel) {
    this.data[`${this.subject}Service`].download(brief)    
  }

  send(brief: BriefModel){
    this.data[`${this.subject}Service`].sendEmail(brief);
  }

}
