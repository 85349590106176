import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../_services/auth.service';
import { UserService } from '../../_services/user.service';
import { Observable } from 'rxjs';
import { VelvetysoftService } from '../../_services/velvetysoft.service';


@Component({
  selector: 'app-usermenu',
  templateUrl: './usermenu.component.html',
  styleUrls: ['./usermenu.component.scss']
})
export class UsermenuComponent implements OnInit {
  $userProfile: Observable<any>;  
  managerNav: Array<any> = [];
  activityOpen: boolean = false;
  constructor(
    private authService: AuthService,
    public userService: UserService,    
    private vs: VelvetysoftService
  ) {}
  
  //MOVE THIS STUFF TO NEW COMPONENT
  displayType(subject_typle){
    return subject_typle.replace('App\\','');
  }

  ngOnInit() {
    //this._userProfile = this.userService.userProfile();
    //this.userService.loadUserProfile();    
    this.$userProfile = this.userService.userProfile();    
    this.managerNav =  this.vs.managerNav;
  }

  logout() {
    this.authService.logout();
  }

  toggleDashSnap(){
    this.vs.dashboardSnapshot = !this.vs.dashboardSnapshot;
  }

}
