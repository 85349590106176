<ng-container *ngIf="($userProfile | async); let profile; else loading">
    <ng-container *ngIf="($brandList | async); let brands">
        <ng-container [formGroup]="form">
            <div class="row m-0">
                <div class="col-sm-4">
                    <app-brand-selector [form]="form" [brands]="brands" [profile]="profile"></app-brand-selector>
                </div>
                <div class="col-sm-8">
                    <div *ngIf="form.value?.brand_id; let bid">
                        <div *ngIf="bid != -1">
                            <mat-form-field class="w-100" *ngIf="findBrandProjects(brands,bid); let projects">
                                <mat-select placeholder="Project" formControlName="project_id">
                                    <mat-option value="">- None -</mat-option>
                                    <mat-option *ngFor="let project of projects | keyvalue" [value]="project.value.id">
                                        {{project.value.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>                                                            
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #loading>
    <mat-spinner></mat-spinner>
</ng-template>