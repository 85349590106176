import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BrandModel, ExpenseModel, SettingTypes, Setting, UserProfileModel, InvoiceModel } from 'src/app/_models/subjects';
import { ExpenseService } from 'src/app/_services/expense.service';
import { ProjectService } from 'src/app/_services/project.service';
import { UserService } from 'src/app/_services/user.service';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { UntypedFormBuilder, UntypedFormGroup, FormControl, Validators, UntypedFormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import moment from 'moment';
import { VelvetysoftService } from 'src/app/_services/velvetysoft.service';
import { HoldingCompanyList, InvoiceService } from 'src/app/_services/invoice.service';
import lang from '../../../assets/copy.json';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-new-expense',
  templateUrl: './new-expense.component.html',
  styleUrls: ['./new-expense.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ]
})
export class NewExpenseComponent implements OnInit {
  $userProfile: Observable<UserProfileModel>;
  $expenses: Observable<ExpenseModel[]>;
  $brandList: Observable<BrandModel[]>;
  invoiceList = [];
  form: UntypedFormGroup;
  expenses: UntypedFormArray;
  expense: ExpenseModel;
  types: Setting[];
  deleteExpenses: number[] = [];
  lang = lang.lang;
  eid: number;
  subject: any;
  categoryTypes: Setting[];
  state: any;
  hcList: HoldingCompanyList[] = [];
  includeHC = false;

  constructor(
    private expenseService: ExpenseService,
    private userService: UserService,
    private projectService: ProjectService,
    private fb: UntypedFormBuilder,
    private vs: VelvetysoftService,
    private invoiceService: InvoiceService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) {
    
  }

  ngOnInit(): void {
    this.$userProfile = this.userService.userProfile();
    this.$brandList = this.projectService.projectTree;
    //this.expense = this.expenseService. (eid);
    this.$expenses = this.expenseService.myExpenses();

    this.state = this.location.getState();

    

    // this.route.paramMap.pipe(first()).subscribe((queryParams) => {
    //   console.log(queryParams);
    //   this.eid = queryParams["params"]["eid"];  
      
    // });
    this.invoiceService.invoiceList().pipe(first()).subscribe(data =>{
       this.invoiceList = data.invoices ;
       this.hcList = data.holding_company;
    });
    // this.form = this.fb.group({
    //   expenses: this.fb.array([this.createExpense()])
    // });   
    this.form = this.fb.group({
      id: [""],
      sub_category: [""],
      expensed_seperatly: [""],
      title: ["", Validators.required],
      project_id: [""],
      brand_id: [""],
      category: [""], //switch
      invoice_id: [""],
      date: [moment(), Validators.required],
      expense_group: this.fb.array([this.makeExpenseForm()]),
    });

    // this.form = this.fb.group({
    //   //date: [moment().format("Y-M-D")],
    //   id: [""],
    //   project_id: [""],
    //   brand_id: ["", Validators.required],
    //   title: ["", Validators.required],
    //   date: [moment(), Validators.required],
    //   paid_at: [""],
    //   payment_ref: [""],
    //   //reimbursement: [""],
    //   total: ["", Validators.required],
    //   receipt: [""],
    //   type: ["", Validators.required],
    //   category: ["", Validators.required], //switch
    //   invoice_id: [""],
    //   status: [""],
    //   _open: [""],
    //   from: [""],
    //   notes: [""],
    // });

    this.types = this.vs.getSettings('expense')?.types;
    this.categoryTypes = this.vs.getSettings('expense')?.categories;
    this.expenses = this.form.get('expense_group') as UntypedFormArray;

    if(this.state.type == 'payment'){
      this.form.patchValue({category: 'payment'});
      this.form.patchValue({sub_category: 'expense reimbursement'});
    }else if(this.state.type == 'purchase') {
      this.form.patchValue({category: 'purchase'});           
      this.form.patchValue({sub_category: 'purchase'}); 
    }    
    
    

    if(this.state.expense){
      this.form.patchValue(this.state['expense']);  
      let create = this.state['expense'].expenses.length - 1;
      for(let i = 0; i < create; i++){
        this.add();
      }
      
      this.form.patchValue({ 'brand_id': this.state['expense'].expenses[0].brand_id });
      this.form.patchValue({ 'project_id': this.state['expense'].expenses[0].project_id });
      this.form.patchValue({ 'invoice_id': this.state['expense'].expenses[0].invoice_id });
      this.expenses.patchValue(this.state['expense'].expenses);
      
    }else{
      if(this.state.type == 'payment') this.form.patchValue({ expensed_seperatly: true });
    }

    // this.form.get('category').valueChanges.subscribe(val => {
    //   // if(val == 'payment'){
    //   //   this.form.get('reimbursement').setValue(1);
    //   // }else{
    //   //   this.form.get('reimbursement').setValue(0);
    //   // }
    //   // console.log(this.form.value)
    // });
    
    //this.loadMyExpenses();
  }

  add(){
    this.expenses = this.form.get('expense_group') as UntypedFormArray;
    this.expenses.push(this.makeExpenseForm());
  }

  modifyExpensedSeperate(event){
    if(event.value == -1){
      this.form.patchValue({ expensed_seperatly: false });
    }
  }

  makeExpenseForm(){
    return this.fb.group({
      //date: [moment().format("Y-M-D")],
      id: [""],      
      project_id: [""],
      brand_id: [""],
      title: [""],
      date: [moment(), Validators.required],
      paid_at: [""],
      payment_ref: [""],
      //reimbursement: [""],
      total: ["", Validators.required],
      receipt: [""],
      type: ["", Validators.required],
      category: [""], //switch
      invoice_id: [""],
      status: [""],
      _open: [""],
      from: [""],
      notes: [""],
    });
  }

  avalibleInvoices(bid){
    return this.invoiceService.avalibleInvoices(this.includeHC, this.hcList, this.invoiceList, bid);    
  }

  invoiceName(iid){
    return this.invoiceList.find(i => {
      if(Array.isArray(i) ){
        return i.find( inv => inv.brand_id == iid);
      }else{
        return i.id == iid;
      }      
    });
  }
  saveExpense(){
  }

  // loadMyExpenses(){
  //   this.expenseService.myExpenses()
  //     .pipe(first())
  //     .subscribe(expenses =>{
  //       this.form = this.fb.group({
  //         expenses: this.fb.array([this.createExpense()])
  //       }); 
  //       expenses.forEach(expense => {
  //         this.addExpense(expense)
  //       });      
  //   });
  // }


  //FORMS
  get formExpenses(){
    return (this.form.get("expense_group") as UntypedFormArray).controls;    
  }

  // createExpense() {
  //   // this.days = this.form.get('timesheets').get('days') as FormArray;
  //   return this.fb.group({
  //     //date: [moment().format("Y-M-D")],
  //     id: [""],
  //     project_id: [""],
  //     brand_id: ["", Validators.required],
  //     title: ["", Validators.required],
  //     date: [moment(), Validators.required],
  //     paid_at: [""],
  //     payment_ref: [""],
  //     reimbursement: [""],
  //     total: ["", Validators.required],
  //     receipt: [""],
  //     type: ["", Validators.required],
  //     category: [""], //switch
  //     invoice_id: [""],
  //     status: [""],
  //     _open: [""],
  //     from: [""],
  //     notes: [""],
  //   });
  // }

  toggle(i){
    this.expenses = this.form.get("expenses") as UntypedFormArray;
    //this.expenses.controls[i].patchValue({_open: !this.expenses.controls[i].value._open})
    //this.expenses = this.form.get("expenses") as FormArray;
    //this.expenses.controls[i].patchValue({_open: true})
    //!this.expenses.controls[i].value._open
  }

  // addExpense(expense?: ExpenseModel, action?: string) {
  //   this.expenses = this.form.get("expenses") as UntypedFormArray;

  //   (action)
  //     ? this.expenses.insert(0, this.createExpense())
  //     : this.expenses.push(this.createExpense());
  //   let idx = this.expenses.length - 1;
  //   this.expenses.controls[idx].patchValue(expense);
  //   //expense ?? this.expenses.controls[1].patchValue(expense);
  // }

  removeline(idx){
    this.expenses = this.form.get("expenses") as UntypedFormArray;
    //this.deleteExpenses = [...this.deleteExpenses, ...this.getCtxFormVal(idx,'id')];
    this.expenses.removeAt(idx);
  }

  // uploadReceipt(event,idx){
  //   this.form.get("expenses").get(`${idx}`).get('receipt').patchValue(event)
  //   //this.data.expense.receipt = event;
  // }
  
  uploadReceipt(event, idx){
      this.formExpenses[idx].patchValue({receipt: event});
      //this.data.expense[idx].receipt = event;
    //this.data.expense.receipt = event;
  }

  getCtxFormVal(index:number, field?:string){
    if(!field) return this.form.get('expenses').value[index]
    return this.form.get('expenses').value[index][field];
  }
  
  collapse(event,i){
    event.stopPropagation();    
    this.expenses = this.form.get("expenses") as UntypedFormArray;
    this.form.get("expenses").get(`${i}`).get('_open').patchValue(false)
    //form.get('expenses').value[i]._open = true
    //setTimeout(()=>this.expenses.controls[i].patchValue({_open: !this.expenses.controls[i].value._open}),100);
    //this.form.get("expenses").get(`${i}`).get('_open').setValue('rarr')
    //console.log(i,this.form.get("expenses").get(`${i}`).get('_open'))
    //console.log(this.expenses.controls[i)
  }

  save(){
    
    let data = this.form.value;
    if(!data.expensed_seperatly) data.expensed_seperatly = false;
    this.expenseService.add(data,{skipLoad: true})
      .pipe(first())
      .subscribe(res => {
        //this.router.navigate(['/dashboard/expenses']);
        let url = `/dashboard/${this.state.label}s`;
        this.router.navigate([url.toLocaleLowerCase()],{ state: this.state  });
        //this.loadMyExpenses();/dashboard/Expenses
    });
    //this.form.get("expenses").get(`${i}`).get('_open').patchValue(false)
  }

  delete(event,expense){
    event.stopPropagation();
    this.expenseService.deleteDialog([expense.id],{skipLoad:true})
    .pipe(first())
    .subscribe( expense =>{
      //this.loadMyExpenses();
    })
  }

  // onSubmit() {
    
  //   const data = {...this.form.value,deleteExpenses: this.deleteExpenses}
  //   console.log(data)
  //   // this.timesheetService.addTimesheet(data).subscribe(res => {
  //   //   this.deleteSheets = [];
  //   //   this.timesheetService.loadTimesheets(this.genStartOfWeek, this.overview, this.impersonating?.id);
  //   // });
  // }


  findBrandProjects(brands,id){
    let brand =  brands.find( brand => brand.id == id)
    if(!brand) return ;
    return brand.projects;
  }

  ngOnDestroy() {
    //this.$expenses.unsubscribe();
  }

}
