
<div class="vault-modal">
  <form [formGroup]="form">
    <h1 mat-dialog-title>
      <ng-container *ngIf="data?.options?.title; else normalTitle">
        {{ data.options.title }}
      </ng-container>

      <ng-template #normalTitle>
        <span *ngIf="data.title; else addTitle">Edit</span>
        <ng-template #addTitle>
          Add
        </ng-template>
        Round {{ data.title }}
      </ng-template>
    </h1>
    <div mat-dialog-content>

      <div *ngIf="data.round.id && data?.options?.view == 'nextsteps'" class="form-group">
        <div class="d-flex justify-content-between align-items-bottom">
          <label class="label mb-0">Next Steps</label>
          <button  mat-button mat-icon-button matTooltip="Robot Writter"  (click)="prompt(form,'next_steps')"><mat-icon color="primary">smart_toy</mat-icon></button>
        </div>
        <ckeditor [editor]="Editor" [config]="vs.editor.config" formControlName="next_steps"></ckeditor>
      </div>

      <div *ngIf="data?.options?.view != 'nextsteps'" class="row">
        <div class="col-sm-6">
          <mat-form-field class="w-100">
            <input matInput name="title" name="name" placeholder="Round Title" formControlName="name" required required>
          </mat-form-field>
        </div>
        <!-- <div class="col-sm-6">
          <mat-form-field class="w-100">
            <input matInput name="date" [(ngModel)]="data.date" [matDatepicker]="picker" placeholder="Choose a date"
              required>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div> -->
        <div class="col-sm-3">
          <mat-form-field class="w-100">
            <input matInput [matDatepicker]="picker" placeholder="Date" formControlName="due_date">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="form.controls.due_date.invalid">Enter a valid date</mat-error>
            <!-- <mat-error *ngIf="form.controls.due_date.invalid">Enter a valid date</mat-error> -->
          </mat-form-field>
        </div>
        <div class="col-sm-11">
          <div>
            <div class="form-group">
              <div class="d-flex justify-content-between align-items-bottom">
                <label class="label mb-0">Description</label> 
                <button  mat-button mat-icon-button matTooltip="Robot Writter"  (click)="prompt(form,'description')"><mat-icon color="primary">smart_toy</mat-icon></button>
              </div>
              <ckeditor [editor]="Editor" [config]="vs.editor.config" formControlName="description"></ckeditor>
            </div>
            

            <!-- <div *ngIf="data.round.id" class="form-group">
              <label class="label">Meeting Notes</label> <button  mat-button matTooltip="Robot Writter"  (click)="prompt(form,'meeting_notes')">AI BOT</button>
              <ckeditor [editor]="Editor" [config]="vs.editor.config" formControlName="meeting_notes"></ckeditor>
            </div> -->
          </div>
        </div>
      </div>

      <!-- <div> -->
      <!-- <mat-form-field class="w-100"> -->        
        <!-- <textarea matInput name="description" formControlName="description"  placeholder="Description" ></textarea> -->
      <!-- </mat-form-field> -->
      <!-- </div> -->
      
    </div>

    <mat-dialog-actions>
      <button mat-button (click)="close()">Cancel</button>
      <!-- Can optionally provide a result for the closing dialog. -->
      <button color="primary" [disabled]="form.invalid" mat-raised-button (click)="save()">Save</button>
      <button *ngIf="data?.options?.view != 'nextsteps'" mat-button mat color="warn" (click)="delete()">Delete</button>
    </mat-dialog-actions>
  </form>
</div>
