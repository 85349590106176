import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';
import { DashboardComponent } from './_component/dashboard/dashboard.component';
import { OverviewComponent } from './_component/overview/overview.component';
import { ProfileComponent } from './_component/profile/profile.component';
import { ProjectComponent } from './_component/project/project.component';
import { ProjectsComponent } from './_component/projects/projects.component';
import { InvoicesComponent } from './_component/invoices/invoices.component';
import { ProposalsComponent } from './_component/proposals/proposals.component';
import { CompanyComponent } from './_component/company/company.component';
import { LoginComponent } from './_component/login/login.component';
import { BrandsComponent } from './_component/brands/brands.component'
import { VelvetysoftService } from './_services/velvetysoft.service';
import { ManageTimesheetsComponent } from './_component/manage-timesheets/manage-timesheets.component';
// import { TimesheetComponent } from './_component/timesheet/timesheet.component';
import { TimesheetComponent } from './_component/timesheet/timesheet.component';
import { ManageUsersComponent } from './_component/manage-users/manage-users.component';
import { ResetPassComponent } from './_component/reset-pass/reset-pass.component';
import { BrandService } from './_services/brand.service';
import { ManageComponent } from './_component/manage/manage.component';
import { ManageAssetsComponent } from './_component/manage-assets/manage-assets.component';
import { ManagerPermissonsComponent } from './_component/manager-permissons/manager-permissons.component';
import { ManagerActivityComponent } from './_component/manager-activity/manager-activity.component';
import { UserDashComponent } from './_component/user-dash/user-dash.component';
import { ManageInvoicesComponent } from './_component/manage-invoices/manage-invoices.component';
import { NoconnectionComponent } from './_component/noconnection/noconnection.component';
import { FeComponent } from './_component-fe/fe/fe.component';
import { ManageExpensesComponent } from './_component/manage-expenses/manage-expenses.component';
import { NewExpenseComponent } from './_component/expenses/new-expense.component';
import { ExpensesComponent } from './_component/expenses/expenses.component';
import { TeamCalComponent } from './_component/team-cal/team-cal.component';
import { ClientApprovalComponent } from './_component/proposals/client-approval/client-approval.component';
import { UnsavedChangesGuardGuard } from './_guards/unsaved-changes-guard.guard';
import { ManageVendorsComponent } from './_component/manage-vendors/manage-vendors.component';
import { ManagePurchasesComponent } from './_component/manage-purchases/manage-purchases.component';
import { ManageProposalsComponent } from './_component/manage-proposals/manage-proposals.component';
import { RegisterComponent } from './_component/register/register.component';
import { S } from '@angular/cdk/keycodes';
import { SetupRegistrationComponent } from './_component/setup/setup-registration/setup-registration.component';
import { QuickSigninComponent } from './_component/quick-signin/quick-signin.component';


const routes: Routes = [
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    component: DashboardComponent,
    resolve: { settings: VelvetysoftService, brand: BrandService },
    children: [
      { path: '', redirectTo: 'welcome', pathMatch: 'full', data: { animation: 'dash-redirect' } },
      { path: 'overview', component: OverviewComponent, canActivate: [AuthGuard], data: { animation: 'overview' } },
      { path: 'welcome', component: UserDashComponent, canActivate: [AuthGuard], data: { animation: 'overview' } },
      { path: 'edit/profile', canActivate: [AuthGuard], component: ProfileComponent },
      { path: 'timesheets', canActivate: [AuthGuard], component: TimesheetComponent}, //canDeactivate: [UnsavedChangesGuardGuard]
      { path: 'new-expenses', canActivate: [AuthGuard], component: NewExpenseComponent, data: {hideRoster: true} },
      { path: 'expense/:eid', canActivate: [AuthGuard], component: NewExpenseComponent, data: {hideRoster: true} },
      { path: 'expenses', canActivate: [AuthGuard], component: ExpensesComponent, data: {hideRoster: true} },
      { path: 'purchases', canActivate: [AuthGuard], component: ExpensesComponent, data: {hideRoster: true} },
      { path: 'projects', canActivate: [AuthGuard], component: ProjectsComponent },
      { path: 'invoices', canActivate: [AuthGuard], component: InvoicesComponent },
      { path: 'proposals', canActivate: [AuthGuard], component: ProposalsComponent },
      { path: 'project/:pid', canActivate: [AuthGuard], component: ProjectComponent },
      { path: 'edit/company', canActivate: [AuthGuard], component: CompanyComponent,  data: {hideRoster: true} },
      { path: 'manage/brands', canActivate: [AuthGuard], component: BrandsComponent,  data: {hideRoster: true} },
      { path: 'manage/users', canActivate: [AuthGuard], component: ManageUsersComponent,  data: {hideRoster: true} },
      { path: 'manage/timesheets', canActivate: [AuthGuard], component: ManageTimesheetsComponent,  data: {hideRoster: true} },
      { path: 'manage/assets', canActivate: [AuthGuard], component: ManageAssetsComponent,  data: {hideRoster: true} },
      { path: 'manage/invoices', canActivate: [AuthGuard], component: ManageInvoicesComponent,  data: {hideRoster: true} },    
      { path: 'manage/vendors', canActivate: [AuthGuard], component: ManageVendorsComponent,  data: {hideRoster: true} },      
      { path: 'manage/permissons', canActivate: [AuthGuard], component: ManagerPermissonsComponent,  data: {hideRoster: true} },
      { path: 'manage/activity', canActivate: [AuthGuard], component: ManagerActivityComponent,  data: {hideRoster: true} },      
      { path: 'manage/payments', canActivate: [AuthGuard], component: ManageExpensesComponent,  data: {hideRoster: true} },      
      { path: 'manage/purchases', canActivate: [AuthGuard], component: ManagePurchasesComponent,  data: {hideRoster: true} },      
      { path: 'manage/proposals', canActivate: [AuthGuard], component: ManageProposalsComponent,  data: {hideRoster: true} },      
      { path: 'manage', canActivate: [AuthGuard], component: ManageComponent,  data: {hideRoster: true} },
      { path: 'team-calendar', canActivate: [AuthGuard], component: TeamCalComponent,  data: {hideRoster: true} },
      
    ]
  },{
  //   path: 'setup',
  //   canActivate: [AuthGuard],
  //   component: SetupRegistrationComponent,
  // },{
    path: 'login',
    component: LoginComponent,
  },{
  //   path: 'register-beta',
  //   component: RegisterComponent,
  // },{
    path: 'noconnection',
    component: NoconnectionComponent
  },{
    path: 'terms',
    component: FeComponent
  },{
      path: 'privacy',
      component: FeComponent
  },{
    path: 'reset-pass',
    component: ResetPassComponent,    
  },{
    path: 'quick-signin',
    component: QuickSigninComponent,
  },{
    path: 'url/:type/:id',
    component: ClientApprovalComponent
  },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', redirectTo: '/login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
