import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { ProposalModel } from 'src/app/_models/subjects';
import { ProposalService } from 'src/app/_services/proposal.service';
import { environment } from 'src/environments/environment';
import moment from 'moment';

@Component({
  selector: 'app-client-approval',
  templateUrl: './client-approval.component.html',
  styleUrls: ['./client-approval.component.scss']
})
export class ClientApprovalComponent implements AfterViewInit{
  $proposalData: any = null;
  type: string;
  id: string;
  pdf: any = null;
  approvedImg: string = null;
  name: string = null;
  date: string = null;  
  cdn: string = environment.cdn;
  @ViewChild('workauth', {static: false}) workauth: ElementRef;
  context: CanvasRenderingContext2D;
  canvas: HTMLCanvasElement;
  loading: boolean = false;
  pdfLink: any = null;
  approved: string = null;
  makingPNG: boolean = false;

  constructor(
    private proposalService: ProposalService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.date = moment().format('L');
    this.route.paramMap.subscribe((queryParams) => {
      //Project ID
      this.type = queryParams["params"]["type"];      
      this.id = queryParams["params"]["id"];
      this.$proposalData = this.proposalService.approvalLink(this.id).pipe(tap((data) => {
        if(data.found){
          //@ts-ignore
          this.pdf = this.sanitizer.bypassSecurityTrustResourceUrl(this.decodeHtml(data.pdf));
          setTimeout(() => { 
            this.context = this.workauth.nativeElement.getContext('2d'); 
            this.canvas = this.workauth.nativeElement;
          }, 1000);
        }
      }));
    });    
  }

  downloadAfter(proposal: ProposalModel){
    let pdfAfter = this.decodeHtml(this.approved);
    console.log(pdfAfter, this.approved);
    const link = document.createElement("a");
    link.download = proposal.title;
    //@ts-ignore
    link.href = pdfAfter;    
    link.click();
    
  }

  ngAfterViewInit(): void {   
    
  }

  makepng($e){
    this.makingPNG = true;
    this.canvas.width = 600;
    this.canvas.height = 210;
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

    let fsize = 24;
    let fsizesig = 32;
    let foffset = 10;
    let padding = 40
    let fsizelabel = 14;


    //LINES
    let l1_length = 350;
    let l2_length = 150;

    //NAME
    this.context.beginPath(); // Start a new path
    this.context.moveTo(padding, padding+fsize+foffset); 
    this.context.lineTo(l1_length, padding+fsize+foffset); 
    this.context.stroke(); 

    //DATE
    this.context.beginPath(); // Start a new path
    this.context.moveTo(l1_length+padding, padding+fsize+foffset); 
    this.context.lineTo(l1_length+padding+l2_length, padding+fsize+foffset); 
    this.context.stroke(); 

    //SIGNATURE
    this.context.beginPath(); // Start a new path
    this.context.moveTo(padding, padding*2+fsize*3+foffset*2); 
    this.context.lineTo(l1_length+padding+l2_length, padding*2+fsize*3+foffset*2); 
    this.context.stroke(); 
    

    this.context.font = `${fsize}px 'Poppins', sans-serif`;    
    this.context.fillText(this.name, padding, (padding+fsize));
    this.context.fillText(this.date, l1_length+padding, (padding+fsize));

    this.context.font = `${fsizesig}px 'Alex Brush', cursive`;
    this.context.fillText(this.name, padding, padding*2+fsize*3+foffset);

    this.context.font = `${fsizelabel}px Roboto, "Helvetica Neue", sans-serif`;    
    this.context.fillText("Name", padding, 25);
    this.context.fillText("Date", l1_length+padding, 25);
    this.context.fillText("Signature", padding, 120);
  }

  decodeHtml(html) {    
    var binary = atob(html.replace(/\s/g, ''));
    var len = binary.length;
    var buffer = new ArrayBuffer(len);
    var view = new Uint8Array(buffer);
    for (var i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
    }
    const blob = new Blob([view], { type: "application/pdf" });
    let newurl = window.URL.createObjectURL(blob);
    this.pdfLink = newurl;
    return newurl;
    return  this.sanitizer.bypassSecurityTrustResourceUrl(newurl);
    // const link = document.createElement("a");

        
    //       link.target = "_blank";
        
    //     link.href = newurl;
        
    //     link.click();
    // console.log(blob);
    // return newurl;
  }

  approveProposal(proposal: ProposalModel){
    this.loading = true;
    this.approvedImg = this.canvas.toDataURL('image/png');
    this.proposalService.approvedByLink(proposal, this.id, this.approvedImg, this.name)
      .subscribe((res) => {
        this.loading = false;
        console.log(res);
        if (res && res.approvedImgUrl?.response && res.approvedPDF) {          
          this.approved = res.approvedPDF;          
        }
      });

  }
  rejectProposal(proposal: ProposalModel){

  }

  download(proposal: ProposalModel){
    const link = document.createElement("a");


    link.download = proposal.title;
    
    link.href = this.pdfLink;
    
    link.click();
    //window.open(this.pdfLink, '_blank');
  }
}
