import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { Settings, UserRateModel } from 'src/app/_models/subjects';
import { UserService } from 'src/app/_services/user.service';
import { VelvetysoftService } from 'src/app/_services/velvetysoft.service';
import { ConfirmComponent } from '../dialogs/confirm/confirm.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { first } from 'rxjs/operators';


@Component({
  selector: 'app-user-rates',
  templateUrl: './user-rates.component.html',
  styleUrls: ['./user-rates.component.scss']
})
export class UserRatesComponent implements OnInit {
    @Input() form: UntypedFormGroup;
    @Input() data: UserRateModel[];
    workTypes: any;
    rates: UntypedFormArray;
    types: any;
    constructor(
      private fb: UntypedFormBuilder,
      private vs: VelvetysoftService,
      private userService: UserService,
      public dialog: MatDialog,
    ) {
      
    }

    ngOnInit(): void {
      this.workTypes = this.vs.getSettings('jobs');    
      this.types =this.vs.getSettings('timesheet-invoices');

      

      if(this.form){
        this.form.addControl("rates", this.fb.array([]));    
        this.rates = this.form.get("rates") as UntypedFormArray;        
        if(this.data.length > 0){
          console.log(this.data);
          this.data && this.data.forEach((UserRate, i) => {      
            this.rates.push(this.createRate());
          });
          this.rates.patchValue(this.data);
        }else{
          this.addRate('base');
          //this.addRate('contract');
        }
      }
     
    }

    delete(e, i, rate) {
      e.preventDefault();
      
      let _self = this;

      const dialogRef = this.dialog.open(ConfirmComponent, {
        data: {        
          type: "delete",
          title: `Delete : User Rate`,
          content: `Are you sure you want to delete this user rate?`,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if(result && result.status == 'delete'){
            if(rate.value.id){        
              _self.userService.removeRate(rate.value.id).pipe(first()).subscribe(res => {
                _self.rates.removeAt(i);
              });
            }else{
              _self.rates.removeAt(i);
            }
        }
      });

    }

    // this.addresses = this.form.get("addresses") as UntypedFormArray;
    // this.currentAddresses && this.currentAddresses.forEach((address, i) => {      
    //   if(this.currentAddresses.length > this.addresses.length ) this.addresses.push(this.createAddress());
    // });
    // this.addresses.patchValue(this.currentAddresses);

    

    createRate(){
      return this.fb.group({
        id: [""],
        condition: [""],
        rate: [""],
        value: [""],
        type: [""],        
      });
    }

    addRate(type){
      //console.log(this.rates);
      try{this.rates.push(this.createRate());}catch(e){console.log(e)}

      if(type == 'base'){
        this.rates.controls[this.rates.length - 1].patchValue({
          type: "base",          
        });
      }else if(type == 'conditional'){
        this.rates.controls[this.rates.length - 1].patchValue({
          type: "conditional",
        });
      }else if(type == 'contract'){
        this.rates.controls[this.rates.length - 1].patchValue({
          type: "contract",
          condition: "min",
        });
      }
    }

    get getformRate() {      
      return (this.form.get("rates") as UntypedFormArray).controls;
    }
}
