<div class="row m-0 justify-space-between" *ngIf="(userService.userProfile() | async);let profile"> 

  <div class="w-100" *ngIf="asyncFilters?.tiles">        
    <div class="row mb-4" *ngFor="let tile of asyncFilters.tiles" >
      <div class="col-sm-3 block" (click)="tileSelect(tile.field, option.value)" *ngFor="let option of tile.data" >
        <div 
          class="block-content cursor text-center selectable"
          [ngClass]="{'selected': option.value == asyncFilterState.tiles[tile.field]}">
          <span class="block-title-label uci justify-content-center" [innerHTML]="damnit(option.title)" ></span>
        </div>
        <div class="subhead p-2 " *ngIf="option?.description">{{ option.description }}</div>
      </div>      
    </div>
    <!-- <div class="col-sm-4 block" (click)="category('payment')">
      <div 
        class="block-content cursor text-center selectable" 
        [ngClass]="{'selected': 'payment' == selected}">
        <span class="block-title-label">Payments</span>
      </div>
    </div> -->    
  </div>
  

  <div class="col m-0">
    <div class="row">
      <div class="col-sm-3" *ngIf="search">
        <mat-form-field class="example-form-field">
          <mat-label>Search</mat-label>
          <input matInput type="text" [(ngModel)]="searchText" (ngModelChange)="applySearchFilter.next()">
          <button mat-button *ngIf="searchText" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="col-sm-2" *ngIf="asyncFilters?.user">
        <div class="label">Filter by User</div>
        <div class="" *ngIf="(userService.$activeUsers | async)let users">
          <div class="d-flex align-items-center">
              <span *ngIf="asyncFilterState.user" prefix class="mr-1"><user-avatar  [user]="asyncFilterState.user"></user-avatar></span>
              <div class="w-100"  >                
                  <mat-select class="" (selectionChange)="doSearch()" placeholder="User"  [(ngModel)]="asyncFilterState.user">
                    <mat-option value="">- Any -</mat-option>                                
                      <mat-option                     
                      *ngFor="let user of users | userIs: asyncFilters.user.role"
                      [value]="user">
                          <div class="my-1 d-flex align-items-center">
                              <user-avatar [user]="user"></user-avatar> &nbsp; {{user.name}} {{user.last_name}}
                          </div>
                      </mat-option>
                  </mat-select>                                
              </div>
          </div>
        </div>
      </div>
      <div class="col-sm-2" *ngIf="asyncFilters?.brand">
        <div class="label">Filter by Brand</div>
        <div class="" *ngIf="($brands | async);let brands">
          <div class="d-flex align-items-center">
              <!-- <span *ngIf="asyncFilterState.user" prefix class="mr-1"><user-avatar  [user]="asyncFilterState.brand"></user-avatar></span> -->
              <div class="w-100" >                
                  <mat-select class="" (selectionChange)="doSearch()" placeholder="Brand"  [(ngModel)]="asyncFilterState.brand">
                    <mat-option value="">- Any -</mat-option>                                                     
                    <mat-option [value]="-1" >
                        <app-brand-logo [brand]="profile.company"></app-brand-logo> &nbsp; {{profile.company.name}}
                    </mat-option>
                    <mat-option *ngFor="let brand of brands | keyvalue" [value]="brand.value.id">
                        <app-brand-logo [brand]="brand.value"></app-brand-logo> &nbsp; {{brand.value.name}}
                    </mat-option>                      
                  </mat-select>                                
              </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="asyncFilters?.filters && asyncFilters?.filters.length > 0">     
        <div *ngFor="let filter of asyncFilters.filters" class="col d-flex align-items-start" >          
            <button *ngIf="!filter.hidden" mat-button [matMenuTriggerFor]="filterMenu" color="primary">
              <mat-icon>filter</mat-icon>  {{ filter.label }} 
            </button>
            <mat-menu #filterMenu="matMenu">
              <ng-container *ngFor="let item of filter.data">
                <div mat-menu-item *ngIf="item.label">
                  <!-- (change)="filterItems(status.value)"  [checked]="filterIsChecked(status.value)" -->
                  <mat-checkbox 
                    color="primary"                                       
                    (change)="doSearch()"
                    [(ngModel)]="asyncFilterState.filters[filter.field][item.value]">
                    <span >{{ item.label }}</span> &nbsp;
                  </mat-checkbox>
                </div>  
              </ng-container>        
            </mat-menu> 
        </div> 
      </ng-container>

      <div class="col-auto d-flex align-items-center" *ngIf="asyncFilters?.status">
        <mat-checkbox 
          color="primary" 
          *ngFor="let status of asyncFilters.status.data" 
          (change)="doSearch()"
          [(ngModel)]="asyncFilterState.status[status.value]"
          class="mr-1"
          [ngClass]="status.value">
          <span class="subhead">{{ status.title }}</span> &nbsp;
        </mat-checkbox>
      </div>
      <div class="col-auto d-flex align-items-center" *ngIf="asyncFilters && asyncFilters.deleted !== undefined">
        <mat-checkbox 
          color="primary"       
          (change)="doSearch()"
          [(ngModel)]="asyncFilterState.deleted"
          class="mr-1">
          <span class="subhead">Show Deleted</span> &nbsp;
        </mat-checkbox>
      </div>
    </div>
  </div>

  <div class="col" *ngIf="filters">    
    <div class="float-right">
      <ng-container *ngIf="filters.length > 3;else inlineFilters">
        <button mat-button [matMenuTriggerFor]="filterMenu" color="primary">
          <mat-icon>filter</mat-icon> Filters
        </button>
        <mat-menu #filterMenu="matMenu">
          <div mat-menu-item *ngFor="let status of filters">
            <mat-checkbox 
              color="primary" 
              (change)="filterItems(status.value)" 
              [ngClass]="status.value"
              [checked]="filterIsChecked(status.value)" 
              [value]="status.value">
              <span >{{ status.title }}</span> &nbsp;
            </mat-checkbox>
          </div>          
        </mat-menu>
      </ng-container>

      <ng-template #inlineFilters>
        <mat-checkbox color="primary" *ngFor="let status of filters" (change)="filterItems(status.value)"
          [ngClass]="status.value" [checked]="filterIsChecked(status.value)" [value]="status.value">
          <span class="subhead">{{ status.title }}</span> &nbsp;
        </mat-checkbox>
      </ng-template>
        
        <!-- NEED A CONTAINER HERER -->
        <span *ngIf="actionsTemplate">
            <button mat-button [matMenuTriggerFor]="menu" color="primary">
              <mat-icon>sort</mat-icon>Actions
            </button>
            <mat-menu #menu="matMenu">
              <ng-container *ngTemplateOutlet="actionsTemplate"></ng-container>
            </mat-menu>
        </span>
    </div>
  </div>
  
  <div class="mat-paginator" *ngIf="pagerResults">
    <mat-form-field style="width: 56px;" class="mat-paginator-page-size-select mr-5">      
      <mat-select (selectionChange)="applySearchFilter.next()" [(value)]="pagerResults.per_page">
        <mat-option *ngFor="let option of vs.pagerOptions" [value]="option">
          {{option}}
        </mat-option>
      </mat-select>
    </mat-form-field>     

    <span class="mr-5" *ngIf="pagerResults.total">
      <span class="label">{{ pagerResults.from }} - {{ pagerResults.to }} of {{ pagerResults.total }}</span>
    </span>

    <span class="">
      <button [disabled]="!pagerResults.prev_page_url" class="mat-icon-button" mat-button (click)="prev()"><mat-icon>chevron_left</mat-icon></button>
      <button [disabled]="!pagerResults.next_page_url" class="mat-icon-button" mat-button (click)="next()"><mat-icon>chevron_right</mat-icon></button>
    </span>
  </div>

  
  <!-- <div *ngIf="( dataSource | async ); let data">
    {{ data.total }}
  </div> -->

  <div class="col-sm-12">
    <ng-container *ngTemplateOutlet="alertsTemplate"></ng-container> 
  </div>
</div>


<div class="table-wrap" [ngClass]="{ 'parent-loading': loading}">
  <table 
    mat-table 
    matSort  
    [dataSource]="sortedData"
    class="vault-table">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-content></ng-content>  
  </table>
  <mat-spinner *ngIf="loading" style="z-index:1;" color="primary" class="mx-auto loader ontop"></mat-spinner>
</div>

<div class="p-3 label pt-4 text-center" *ngIf="!dataSourceHandler.data || dataSourceHandler.data.length < 1">No Items Found</div>
