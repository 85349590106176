import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../_services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../../_services/alert.service';

//import { FormErrorsService } from '../../_services/form-errors.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  model: any = {};
  error: any = false;
  submitted = false;
  loading = true;
  forceReset = false;
  quickemail = '';

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private alertService: AlertService,
    //private formErrors: FormErrorsService
  ) { }

  ngOnInit() {
    console.log(this.authService.isLoggedIn(),localStorage.getItem('expiration'));
    if( this.authService.isLoggedIn()){
      this.router.navigate(['/dashboard']);
    }else{
      this.loading = false;
    }

    this.route.queryParamMap.subscribe(params => {
      //console.log(params,params.get("email"));
      this.model.email = params.get("email");
      this.forceReset = (params.get("action") == 'reset') ? true : false;
    });
    // this.formErrors.errors.subscribe(errors => {  
    //   this.formErrors.display(errors, err => this.alertService.error(err) );      
    // })    
  }

  login() {
    this.alertService.clear();
    this.submitted = true;
    this.model.action = 'login';
    this.authService.loginForm(this.model).subscribe(response => {      
      this.loading = false;
      this.submitted = false;
      if (response.token) {
        this.authService.setUser(response);
      }else{
        this.alertService.error(response.message);
      }
    }, errRes => {
      this.submitted = false;
      console.log(errRes)
      this.alertService.error(errRes.error.message);
    });
  }

  forgot(){
    this.alertService.clear();
    this.authService.forgotPassword(this.model).subscribe(res => {
      if (res.status) this.alertService.success(res.message);
    });
  }

  quickSignin(){
    this.alertService.clear();
    this.authService.quickSignin({email: this.quickemail}).subscribe(res => {
      console.log(res);
      //pass the email to the next page      
      if (res == true) this.router.navigate(['/quick-signin'], { queryParams: { email: this.quickemail } });
    });
  }
  

}
