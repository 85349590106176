import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { SubjectBaseService } from './subject-base.service';
import { AssetModel, GoogleAsset, Pager, SubjectAction } from '../_models/subjects';
import { BrandService } from './brand.service';
import { UserService } from './user.service';
import { FileService } from './file.service';
import { AlertService } from './alert.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { EditAssetComponent } from '../_component/dialogs/edit-asset/edit-asset.component';
import { first } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ConfirmComponent } from '../_component/dialogs/confirm/confirm.component';
import { VelvetysoftService } from './velvetysoft.service';
import { AssetViewComponent } from '../_component/asset-view/asset-view.component';
import { GoogleApiService } from './google-api.service';
import { IoService } from './io.service';

@Injectable({
  providedIn: 'root'
})
export class AssetService extends SubjectBaseService {
  _asset: AssetModel = {
    id: null,
    title: null,
    ext: null,
    size: null,
    path: null,
    hasThumb: false,
    project_id: null,
    round_id: null,
  };
  cdn = environment.cdn;
  $ctx_cabinet_data: ReplaySubject<any> = new ReplaySubject(1);
  pid_cache = null;

  //THIS WILL MOVE TO SUBJECT BASE
  public $assetPager: ReplaySubject<Pager<AssetModel>> = new ReplaySubject(1);

  constructor(
    private apiService: ApiService,
    public brandService: BrandService,
    private alertService: AlertService,
    private userService: UserService,
    public dialog: MatDialog,
    private snack: MatSnackBar,
    private fileService: FileService,
    private vs: VelvetysoftService,
    private toast: MatSnackBar,
    private gapiService: GoogleApiService,
    private io: IoService
  ) {
    super("asset", apiService, brandService, dialog);
    this.setModel<AssetModel>(this._asset);
  }

  upload(event,subject:string,model,gAssets?:GoogleAsset[], type="asset"): Observable<AssetModel[]>{
    //console.log(event,subject,model);
    let formData = new FormData();

    //NORMAL UPLOAD
    if (event) {

      var el;
      //INPUT EVENT should change this to take files only
      // console.log(event.target.files && event.target.files.length <= 0);
      // console.log(event.target.files);
      if (event.target.files && event.target.files.length > 0 ){
        el = event.target;
      }else if (
        event.dataTransfer &&
        event.dataTransfer.files &&
        event.dataTransfer.files.length > 0
      ) {
        el = event.dataTransfer;
      } else {
        throw new Error("Error No Files or File References");
      }
      
      for (let index = 0; index < el.files.length; index++) {        
        const file = el.files[index];
        const errors = this.validate(file);
        if( errors){
          this.snack.open(`Error Uploading: ${errors}`, "Dismiss", {
            duration: 2000,
          });
          throw new Error(errors);
        }
        formData.append("files[]", file);

      }      
      formData.append("type", type);
      formData.append("subject", subject);
      formData.append("model", JSON.stringify(model));
    }else if (gAssets) {
      formData.append("files[]", null);
      formData.append("type", gAssets[0].type);
      formData.append("subject", subject);
      formData.append("model", JSON.stringify(model));
      formData.append("gfiles", JSON.stringify(gAssets));
    } else {
      throw new Error("Error No Files or File References");
    }    
    //console.log(model)

  
    return this.fileService.sendFile(formData);   
  }

  validate(file: File) {
    //SIZE CHECK
    if(file.size > +this.vs.maxFileSize){
      return `File is to large ${this.vs.size(file.size)}, max size is ${this.vs.size(this.vs.maxFileSize)}`;
    }
  }

  //CUSTOM VIEW FOR PROJECT LEVEL -> LOAD
  activeAssetsByProject(): Observable<AssetModel[]> {    
    return this.$ctx_cabinet_data.asObservable();
  }


  // assets(){
  //   return this.apiService.pager<AssetModel>(`assets-pager`,{});
  // }
  
  assetsByProject(pid?):Observable<AssetModel[]>{    
    
    let _pid = null;
    if (!pid && !this.pid_cache){
      throw new Error("NO PID");      
    } else if (!pid){
      _pid = this.pid_cache;
    }else{
      _pid = this.pid_cache = pid;
    }    

    this.apiService
      .get(`assets-project/${_pid}`)
      .pipe(first())
      .subscribe((res) => {
        this.$ctx_cabinet_data.next(res);
      });
    return this.activeAssetsByProject();
  }

  edit(asset: AssetModel): Observable<AssetModel> {
    let resultRef = new Subject<AssetModel>();    

    const result = this.editDialog<AssetModel>(
      asset,
      EditAssetComponent
    );

    result.pipe(first()).subscribe((res: SubjectAction<AssetModel>) => {      
      const asset = res.subject;
      (res.action == 'update') && this.add<AssetModel>(asset, { skipLoad: true })
        .pipe(first())
        .subscribe((res) => resultRef.next(asset) );      
       
    });

    return resultRef;
  }

  gallery<T>(subject, options?: any) {    
    let resultRef = new Subject();

    //DIALOG CONFIG
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;        
    dialogConfig.data = {};
   
    dialogConfig.data[this.subject] = subject;
    dialogConfig.data[`${this.subject}Service`] = this;   
    dialogConfig.data['options'] = options;
    dialogConfig.id = 'sp-gallery';
    const dialogRef = this.dialog.open(AssetViewComponent, dialogConfig);
    dialogRef.componentInstance['config'] = dialogConfig;
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe((result) => {
      });
    return resultRef;
  }

  /* DELETE DIALOG */
  deleteDialog(ids: Array<Number>, options = { skipLoad: false }) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: "",
      type: "delete",
      title: `Delete ${ids.length} ${this.subject} `,
      content: "This can not be undone.",
    };

    let resultRef = new Subject();
    const dialogRef = this.dialog.open(ConfirmComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.status === "delete") {
        this.delete(ids, options).pipe(first()).subscribe( res => {
          this.assetsByProject();
        });        
      }
      resultRef.next(result);
    });
    return resultRef;
  }

  thumb(asset){
    if(asset.g_icon){
      let thumb = this.gapiService.thumb(asset);  
      //console.log(thumb)    
      return asset.g_icon; 
        // ? thumb
        // : asset.g_icon;
    }

    //IF PDF CHANGE FILE EXTENSION FOR THUMB
    let path = ( asset.path && asset.path.includes(".pdf") )
      ? asset.path.replace("\.pdf", ".jpg")
      : asset.path;
    
    return (asset.hasThumb)
      ? this.cdn + path.replace("original", "thumb")
      : '';
  }

  viewVideo(asset){
    return this.apiService.get(`asset-preview-video/${asset.id}`);
  }


  webshots(params: {model: any, urls: Array<string>, type: string, optoins?: any}){
    return this.io.ping('webshots',params);
  }

  // nervThumb(asset: AssetModel){
  //   //https://nerv.velvetysoft.com/image/assets/original/v-1-1587940439.jpeg?w=800
  //   //return `${this.vs.nerv}image/${asset.path}?max=1920`
  // }

  findProjectID(asset:AssetModel):Observable<Number>{
    return this.apiService.post(`asset-findProject/${asset.id}`,{});
  }

  downloadAsset(asset){

    //GASSET EXCEPTION
    if(asset.g_id){
      return window.open(asset.path);
    } 
    
    this.apiService.get(`download/${asset.id}`)
      .pipe(first())
      .subscribe(
        (response) => {
          window.open(response);
        });
  }


  size = this.vs.size;
  
}
