<div class="container" *ngIf="( $vendors | async ); let vendors">
  
    <div class="block">
      <div class="block-title">
        <div class="block-title-label">Manage Vendors</div>
          <div class="block-title-toolbar view-tools">
          <button mat-button (click)="add()" color="primary">
            <mat-icon>add</mat-icon>New Vendor
          </button>
          <!-- <button [ngClass]="{ 'active': view == 'table'}" mat-icon-button (click)="changeView('table')">
            <mat-icon class="">view_list</mat-icon>
          </button>
          <button [ngClass]="{ 'active': view == 'tile'}" mat-icon-button (click)="changeView('tile')">
            <mat-icon class="">view_module</mat-icon>
          </button>         -->
        </div> 
      </div>  
    </div>
  
    <div class="block" >
     
      <div class="block-content">
        <!--          
                       -->
        <div *ngIf="vendors && vendors.data">
  
  
        <app-table
          mat-table 
          #vaultTable
          matSort #sort="matSort" 
          [asyncFilters]="asyncFilters"
          [dataSource]="$vendors"   
          [pager]="vendorPager"        
          [columns]="displayedColumns"
          >
   
        
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell class="cap cursor" *matCellDef="let element" (click)="add(element)">
              <div class="row  m-0 align-items-center">
                <div class="mr-3">
                  <user-avatar [class]="'mdm'" [user]="element"></user-avatar>
                </div>
                <div class="flex-fill">
                  <div>{{element.name}}</div>
                  <div class="subhead">{{element.email}}</div>
                </div>
              </div>
            </td>
          </ng-container>

          
          <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone </th>
            <td mat-cell class="cap" *matCellDef="let element">
              <span mask='(000) 000-0000'>{{ element.phone }}</span>
            </td>
          </ng-container>
          
          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Address </th>
            <td mat-cell class="cap" *matCellDef="let element">
              <div *ngIf="element.addresses[0]">
                {{ element.addresses[0].street }}<br>
                {{ element.addresses[0]?.city }}, {{ element.addresses[0]?.state }} {{ element.addresses[0]?.post_code }}
              </div>
            </td>
          </ng-container>
          
          <ng-container matColumnDef="actions">
            <th class="text-right" mat-header-cell *matHeaderCellDef> Actions </th>
            <td class="text-right" mat-cell *matCellDef="let element">
              <button (click)="add(element)" class="mat-sm" mat-icon-button mat-button>
                <mat-icon>edit</mat-icon>
              </button>
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></tr>
        
      </app-table>
      
    </div>
  </div>
  
  <ng-template #nousers>
    <mat-spinner color="primary" class="mx-auto loader"></mat-spinner> No Users
  </ng-template>
  
  <ng-template #noUsers>
    <mat-spinner color="primary" class="mx-auto loader"></mat-spinner>
  </ng-template>