
<div class="container">
<div class="block">
  <div class="block-title">
    <div class="block-title-label">Manage Brands</div>
    <div class="block-title-toolbar view-tools">
      <button mat-button (click)="add()" color="primary">
        <mat-icon>add</mat-icon>New Brand
      </button>      
      <button [class.active]="(view == 'table')" mat-icon-button (click)="changeView('table')">
        <mat-icon class="">view_list</mat-icon>
      </button>
      <button [class.active]="(view == 'tile')" mat-icon-button (click)="changeView('tile')">
        <mat-icon class="">view_module</mat-icon>
      </button>
    </div>
  </div>
  <!-- <div class="block-content">    
  </div> -->
</div>

<!-- ////// -->

  <div class="" *ngIf="( $brands | async ); let brands; else noBrands">  
    <div class="block">

      <div  [class.block-content]="(view == 'table')">
           
          <div *ngIf="brands && brands.data">
            <div class="row ">       

            <div class="col-sm-12" *ngIf="view == 'table'">

              <app-table
                mat-table
                #vaultTable
                matSort #sort="matSort"  
                [asyncFilters]="asyncFilters"
                [dataSource]="$brands"   
                [pager]="brandspager"
                [columns]="displayedColumns">          


                <ng-container matColumnDef="logo" >
                  <th mat-header-cell *matHeaderCellDef> </th>
                  <td mat-cell *matCellDef="let element"  class="cursor" > 
                    <app-brand-logo  format="small" [brand]="element"></app-brand-logo>
                  </td>
                </ng-container>

                <ng-container matColumnDef="title" >
                  <th mat-header-cell *matHeaderCellDef> Name</th>
                  <td mat-cell *matCellDef="let element"  class="title text-capitalize cursor" > 
                    {{ element.name }}
                  </td>
                </ng-container>

                

                <ng-container matColumnDef="users" >
                  <th mat-header-cell *matHeaderCellDef> Users</th>
                  <td mat-cell *matCellDef="let element"  class="text-capitalize cursor" > 
                    <div class="row">
                      <div class="col-6">
                        <span class="subhead">Clients</span> &nbsp;<div style="display:inline-block;"><app-users [users]="element.users" role="client"></app-users></div>
                      </div>
                      <div class="col-6">
                        <span class="subhead">Employees</span> &nbsp;<div style="display:inline-block;"><app-users [users]="element.users" role="employee"></app-users> </div>
                      </div>
                    </div>
                  </td>
                </ng-container>

              

                <ng-container matColumnDef="action" >
                  <th mat-header-cell *matHeaderCellDef> Actions</th>
                  <td mat-cell *matCellDef="let element"  class="text-capitalize cursor" > 
                    <button mat-button (click)="add(element)" color="primary"><mat-icon>edit</mat-icon></button>
                  </td>
                </ng-container>
              

                <tr  mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns; "></tr>

              </app-table>
                
              <ng-template #noneYet>
                <mat-spinner color="primary" class="mx-auto loader"></mat-spinner>
                <p>No {{ subject }} found</p>
              </ng-template> 

            </div> 
            
            <div class="col-sm-12 tiles" *ngIf="view == 'tile'">
              <div class="row">
                <div class="col-sm-12">
                  <mat-form-field class="example-form-field">
                    <mat-label>Search</mat-label>
                    <input matInput type="text" [(ngModel)]="searchtiles" (ngModelChange)="updateTiles()">
                  </mat-form-field>
                </div>
               
                <div class="col-sm-6 col-lg-4 p-4 text-center"  *ngFor="let brand of brands.data">

                  <div class="tile-wrap cursor" (click)="add(brand)">
                    <div class="brand-tile" [style.background-color]="brand.color">
                      <div *ngIf="brand.logo" [style.background-color]="brand.color" class="brand-tile-bg" [style.background-image]="'url(' + (cdn+brand.logo ) + ')'"></div>
                      <div class="rel p-2" style="z-index: 1;">
                        <div *ngIf="!brand.logo" class="no-brand-logo brand-logo brand-logo-primary-hero">{{ brand.name }}</div>
                        <img *ngIf="brand.logo" class="brand-logo py-4 brand-logo-primary-hero" [src]="cdn+brand.logo">
                        <!-- <app-brand-logo format="large" [brand]="brand"></app-brand-logo> -->
                        <div class="hr my-1"></div>
                        <div class="row min-h-users mx-auto my-1">
                            <div class="col-auto d-flex align-items-center">
                              <span class="subhead">Clients</span>&nbsp;
                              <app-users [users]="brand.users" role="client"></app-users>
                            </div>
                            <div class="col-auto d-flex align-items-center">
                              <span class="subhead">Employees</span>&nbsp;
                              <app-users [users]="brand.users" role="employee"></app-users>
                            </div>
                          </div>
                      </div>                        
                    </div>
                    <div class="brand-title">
                      <div class="row">                        
                        <div class="col-sm-12 mt-2">
                          {{ brand.name }}
                        </div>
                      </div>
                      
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>

    </div>
  </div>   

<!-- //////// -->

<!-- <div class="" *ngIf="( $brands | async ); let brands; else noBrands">  
  <div class="row">
    <div class="col-sm-6 col-lg-4" *ngFor="let brand of brands">
      <div class="block">
        <div class="block-content">
          <div class="d-flex align-items-center form-group">
            <div class="mr-3">
              <app-brand-logo format="large" [brand]="brand"></app-brand-logo>
            </div>            
            <div class="flex-shrink ">
              <button mat-button (click)="add(brand)" color="primary">Edit</button>
            </div>
          </div>
        </div>
        <div class="block-hr"></div>
        <div class="block-content">        
          <div class="">
            <div class="row min-h-users">
              <div class="col-auto d-flex align-items-center">
                <span class="subhead">Clients</span>
                <app-users [users]="brand.users" role="client"></app-users>
              </div>
              <div class="col-auto d-flex align-items-center">
                <span class="subhead">Employees</span>
                <app-users [users]="brand.users" role="employee"></app-users>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>  
</div> -->

<ng-template #noBrands>
  <mat-spinner color="primary" class="mx-auto loader"></mat-spinner>
</ng-template>
</div>