import { Component, OnInit, Input } from '@angular/core';
import { InvoiceModel } from "../../_models/subjects";
import { ApiService } from '../../_services/api.service'
import { Observable } from 'rxjs';
import { ActivityModel } from '../../_models/activity';

@Component({
  selector: 'app-invoice-log',
  templateUrl: './invoice-log.component.html',
  styleUrls: ['./invoice-log.component.scss']
})
export class InvoiceLogComponent implements OnInit {

  @Input() invoice: InvoiceModel;
  $activity: Observable<ActivityModel[]>;
  constructor(
    private apiService: ApiService
  ) { }

  ngOnInit() {
    let data = { 'type': 'Invoice', 'id': this.invoice.id }
    this.$activity = this.apiService.post('activity',data);
  }

}
