import { Injectable } from '@angular/core';
import { BriefModel, DialogOptions, SubjectAction, SubjectState } from '../_models/subjects';
import { Subject, Observable, ReplaySubject } from 'rxjs';
import { first, tap, switchMap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { AddBriefComponent } from '../_component/add-brief/add-brief.component';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ConfirmComponent } from '../_component/dialogs/confirm/confirm.component';
import { SubjectBaseService } from './subject-base.service';
import { BrandService } from './brand.service';
import { SendEmailComponent } from '../_component/dialogs/send-email/send-email.component';


@Injectable({
  providedIn: 'root'
})
export class BriefService extends SubjectBaseService{

  _brief: BriefModel = {
    id: null,
    content: "",
    features: "",
    fid: null,
    audience: "",
    deliverables: "",
    sent_at: null
  }
  subject = 'brief';

  constructor(
    private apiService: ApiService,
    public dialog: MatDialog,
    public brandService: BrandService,
  ) {
    super("brief", apiService, dialog, brandService);
    this.setModel<BriefModel>(this._brief);
    //this.setBroadcaster();
    this.setDependecy('project');
  }

  sendEmail(brief:BriefModel){
    const result = this.sendDialog(brief, SendEmailComponent);
    return result.pipe(first()).subscribe();
  }

  edit(pid, brief: BriefModel, options?:DialogOptions):Observable<BriefModel>{
    let resultRef = new Subject<BriefModel>();
    
    const result = this.editDialog<BriefModel>(
      brief,
      AddBriefComponent,
      options
    );

    result.pipe(first()).subscribe((res: SubjectAction<BriefModel>) => {
      res.subject.project_id = pid;
      const brief = res.subject;

      (res.action == 'update') && this.add<BriefModel>(brief,{skipLoad:true})
        .pipe(first())
        .subscribe((newBrief:BriefModel) => resultRef.next(newBrief) );

      (res.action == 'delete') && resultRef.next(brief)
    });

    return resultRef;
  }


}



// import { Injectable } from '@angular/core';
// import { BriefModel, SubjectAction, SubjectState } from '../_models/subjects';
// import { Subject, Observable, ReplaySubject } from 'rxjs';
// import { first, tap, switchMap } from 'rxjs/operators';
// import { ApiService } from './api.service';
// import { AddBriefComponent } from '../_component/add-brief/add-brief.component';
// import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
// import { ConfirmComponent } from '../_component/dialogs/confirm/confirm.component';

// @Injectable({
//   providedIn: 'root'
// })
// export class BriefService {
//   source:ReplaySubject<BriefModel> = new ReplaySubject(1);
//   current: null;
//   subject = 'brief';

//   constructor(
//     private apiService: ApiService,
//     public dialog: MatDialog,
//   ) { }

//   // MOVE ALL OVER THIS FUCKING CODE TO A DEPENDENCY ON SUBJECT_BASE(PROJECT)

//   brief(pid?): Observable<BriefModel> {
//      if(pid && pid !== this.current)
//       this.apiService.post(`${this.subject}s`,{id: pid})
//         .pipe(first())
//         .subscribe((brief: BriefModel) => this.source.next(brief));

//     return this.source;
//   }

//   addUpdate<T>(brief: BriefModel): Observable<BriefModel> {
//     return this.apiService.post(`${this.subject}`, brief)
//       .pipe(tap((brief: BriefModel) => this.source.next(brief)));
//   }

//   delete(id: Number) {
//     console.log(id)
//     return this.apiService.post<number>(`${this.subject}-delete/${id}`,null);
//   }
  
  

//   edit<T>(brief?: BriefModel, options?):Observable<any> {

//     let resultRef = new Subject<SubjectAction<BriefModel>>();        
//     const result = this.editDialog<BriefModel>(brief,AddBriefComponent,options);

//     result.pipe(
//       first(),
//       switchMap((brief: BriefModel) => this.addUpdate<BriefModel>(brief)))
//       .pipe(first())
//       .subscribe( res => resultRef.next({action: 'edit/add', subject: brief}));

//     return resultRef;
//   }

//   editDialog<T>(subject, AddComponent: any, options?: any) {

//     let resultRef = new Subject();
//     //DIALOG CONFIG
//     const dialogConfig = new MatDialogConfig();
//     dialogConfig.disableClose = true;
//     dialogConfig.autoFocus = true;        
//     dialogConfig.data = {};
//     dialogConfig.data[this.subject] = subject;
//     dialogConfig.data['options'] = options;
//     dialogConfig.data[`${this.subject}Service`] = this;
    
//     const dialogRef = this.dialog.open(AddComponent, dialogConfig);
//     dialogRef.componentInstance['config'] = dialogConfig;
//     dialogRef
//       .afterClosed()
//       .pipe(first())
//       .subscribe((result) => {
//         if (result && result.status === true) {
        
//           resultRef.next(<T>result[this.subject]);
//         } else if (result && result.status === "delete") {
//           this.deleteDialog(result[this.subject])
//             .pipe(first())
//             .subscribe((result) => resultRef.next(result));
//           //resultRef.next();
//           //this.source.next(null);
//         } else {
//           //resultRef.next(false);
//         }
//       });
//     return resultRef;
//   }

//   deleteDialog(subject, options = { skipLoad: false }) {
//     const dialogConfig = new MatDialogConfig();
//     dialogConfig.disableClose = true;
//     dialogConfig.autoFocus = true;
//     dialogConfig.data = {
//       id: "",
//       type: "delete",
//       title: `Delete ${this.subject} `,
//       content: "This can not be undone.",
//     };

//     let resultRef = new Subject<SubjectAction<BriefModel>>();
//     const dialogRef = this.dialog.open(ConfirmComponent, dialogConfig);
//     dialogRef.afterClosed().subscribe((result:SubjectState<BriefModel>) => {
//       console.log(result)
//       if (result && result.status === "delete") {
//         this.delete(subject.id).pipe(first()).subscribe();
//         resultRef.next({action: 'delete', subject: result[this.subject]});
//       }
      
//     });
//     return resultRef;
//   }
// }
