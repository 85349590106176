
<ng-container *ngIf="($userProfile | async);let profile">
<div class="vault-modal">
    <form [formGroup]="form">
      <h1 mat-dialog-title>
        <ng-container *ngIf="data?.options?.title; else normalTitle">
          {{ data.options.title }}
        </ng-container>
  
        <ng-template #normalTitle>
          <!-- <span *ngIf="data.title; else addTitle">Edit</span>
          <ng-template #addTitle>
            Add
          </ng-template> -->
          <span class="cap">{{ subject }}</span>
        </ng-template>
      </h1>

      <div mat-dialog-content >

        <div>
          <mat-tab-group>
            <mat-tab *ngIf="data?.brief?.id" label="Brief">
              <div class="mt-3" *ngIf="data[subject]; let brief">
                <div class="form-group">
                  <div class="my-4">
                    <span *ngIf="profile | userCan: 'view invoice & proposal'">
                      <button class="my-2" mat-raised-button class="mr-2" (click)="send(brief)" color="primary"><mat-icon>email</mat-icon> &nbsp;<ng-container *ngIf="brief.sent_at"> Re-</ng-container>Send</button>                      
                    </span>
                    <button class="my-2" mat-button class="mr-2" (click)="view(brief)" color="primary">View PDF</button>
                    <button class="my-2" mat-button class="mr-2" (click)="download(brief)" color="primary">Download PDF</button>
                  </div>

                  <div *ngIf="brief">

                    <div class="hr my-3"></div>

                    <div class="py-3" *ngIf="brief.fid">
                      <div class="block-title-label">
                        <mat-icon color="primary" class="mr-2">done</mat-icon> &nbsp; See client brief in file cabinet</div>
                    </div>

                    

                    <div class="py-3" *ngIf="brief.content">
                      <div class="block-title-label">Project Objective</div>
                      <div [innerHtml]="brief.content"></div>
                    </div>

                    <div class="py-3" *ngIf="brief.audience">
                      <div class="block-title-label">Target audience</div>
                      <div [innerHtml]="brief.audience"></div>
                    </div>

                    <div class="py-3" *ngIf="brief.features">
                      <div class="block-title-label">Key Features & Benefits</div>
                      <div [innerHtml]="brief.features"></div>
                    </div>

                    <div class="py-3" *ngIf="brief.deliverables">
                      <div class="block-title-label">Deliverables Specifications</div>
                      <div [innerHtml]="brief.deliverables"></div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="{{ (data?.brief?.id ) ? 'Edit' : 'Add New Brief' }}" *ngIf="profile | userCan: 'view invoice & proposal'">
              <div class="row mt-4">
                <div class="col-sm-10">
                    <div class="form-group">
                      <mat-checkbox value="create" color="primary" formControlName="fid">
                        <span >See client brief in file cabinet</span>
                      </mat-checkbox>
                    </div>
                  </div>
                  <div class="col-sm-10">            
                      <div class="form-group">
                          <div><label class="label mb-0">Project Objective</label> </div>                        
                          <div class="">What are we trying to accomplish?  Why are we doing this?  What does success look like?</div>
                          <div class="d-flex justify-content-between align-items-bottom">                        
                              <span></span><button matTooltip="Robot Writter" mat-button mat-icon-button (click)="prompt(form,'description')"><mat-icon color="primary">smart_toy</mat-icon></button>
                          </div>
                          <ckeditor [editor]="Editor" [config]="vs.editor.config" formControlName="content"></ckeditor>
                      </div>
                  </div>
                  <div class="col-sm-10">            
                    <div class="form-group">
                        <div><label class="label mb-0">Target audience</label> </div>                      
                        <div class="">Who are we trying to reach?  What needs or opportunities do they have?</div>
                        <div class="d-flex justify-content-between align-items-bottom">                      
                            <span></span><button matTooltip="Robot Writter" mat-button mat-icon-button (click)="prompt(form,'audience')"><mat-icon color="primary">smart_toy</mat-icon></button>
                        </div>
                        <ckeditor [editor]="Editor" [config]="vs.editor.config" formControlName="audience"></ckeditor>
                    </div>
                </div>
                <div class="col-sm-10">            
                    <div class="form-group">
                        <div><label class="label mb-0">Key Features & Benefits</label> </div>                      
                        <div class="">What main elements should we highlight?  Why are they important to our audience?</div>
                        <div class="d-flex justify-content-between align-items-bottom">                      
                            <span></span><button matTooltip="Robot Writter" mat-button mat-icon-button (click)="prompt(form,'features')"><mat-icon color="primary">smart_toy</mat-icon></button>
                        </div>
                        <ckeditor [editor]="Editor" [config]="vs.editor.config" formControlName="features"></ckeditor>
                    </div>
                </div>
                  <div class="col-sm-10">            
                      <div class="form-group">
                          <div><label class="label mb-0">Deliverables Specifications</label></div>                        
                          <div class="">What details do we need to know to complete this?  Sizes, formats, mandatory elements, etc.?</div> 
                          <div class="d-flex justify-content-between align-items-bottom">                        
                              <span></span><button matTooltip="Robot Writter" mat-button mat-icon-button (click)="prompt(form,'deliverables')"><mat-icon color="primary">smart_toy</mat-icon></button>
                          </div>
                          <ckeditor [editor]="Editor" [config]="vs.editor.config" formControlName="deliverables"></ckeditor>
                      </div>
                  </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>

        <div *ngIf="!(profile | userCan: 'view invoice & proposal') && !data?.brief?.id">
          <p class="py-3">No brief yet.</p>
        </div>


      </div>
  
      <mat-dialog-actions>
        <button mat-button (click)="close()">Cancel</button>
        <button *ngIf="profile | userCan: 'view invoice & proposal'" color="primary" [disabled]="form.invalid" mat-raised-button (click)="save()">Save</button>
        <ng-container *ngIf="profile | userCan: 'view invoice & proposal'">
          <button *ngIf="data?.brief?.id" mat-button mat color="warn" (click)="delete()">Delete</button>
        </ng-container>
      </mat-dialog-actions>
    </form>
  </div>
</ng-container>