import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { ExpenseModel, Pager, PagerObject, Setting, UserProfileModel } from 'src/app/_models/subjects';
import { ExpenseService } from 'src/app/_services/expense.service';
import { UserService } from 'src/app/_services/user.service';
import { VelvetysoftService } from 'src/app/_services/velvetysoft.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-manage-purchases',
  templateUrl: './manage-purchases.component.html',
  styleUrls: ['./manage-purchases.component.scss']
})
export class ManagePurchasesComponent {
  viewOptions = {};
  purchasesPager: PagerObject;
  $purchases: Observable<Pager<ExpenseModel>>;
  $userProfile: Observable<UserProfileModel>;
  cdn = environment.cdn;
  types: Setting[];
  search: string = "";  
  subject: string = "purchases";
  settings: any;
  @Input() public tableRef: TemplateRef<any>;
  sortedData: ExpenseModel[];
  //filteredSubjects: string[];
  @ViewChild("vaultTable") vaultTable;
  //TABLE VIEW
  displayedColumns: string[] = [
    "select",
    "author",
    "brand",
    "name",
    "created",
    "total",
    "type",
    "receipt",
    "download"

  ];
  asyncFilters = {   
    filters:[
      {
        field: 'category',
        selected: 'purchase',
        hidden: true,
        data: [
          {label: 'Purchase', value: 'purchase'},
        ]
      }
    ]    
    
  };

  constructor(
    private userService: UserService,
    public dialog: MatDialog, //private snack: MatSnackBar,
    public vs: VelvetysoftService,
    private expenseService: ExpenseService
  ) {
    this.types = this.vs.getSettings('expense')?.types;
  }

  ngOnInit(): void {
    this.purchasesPager = this.expenseService.pager<ExpenseModel>({
      category: 'purchase',
      
    });
    //console.log(this.purchasesPager);
    this.$purchases = this.purchasesPager.$source;
  }

  add(purchase?: ExpenseModel) {
    let options = {pager: this.purchasesPager };
    this.expenseService.edit(purchase,options)
        .pipe(first())
        .subscribe( expense =>{
          this.purchasesPager.refresh();
          this.vaultTable.selection.clear();
        });
  }

  view(expense: ExpenseModel){
    this.expenseService.view(expense);
  }

  download(expense: ExpenseModel){
    this.expenseService.print([expense.id], false);
  }

  selection = new SelectionModel<ExpenseModel>(true, []);
  ngAfterContentInit() {
    
  }
}
