import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BrandService} from '../../../_services/brand.service';
import { BrandModel, CompanySetting, UserRateModel  } from "../../../_models/subjects";
import { VelvetysoftService } from '../../../_services/velvetysoft.service';
import { environment } from '../../../../environments/environment';
import { Observable, ReplaySubject } from 'rxjs';
import { ProjectService } from 'src/app/_services/project.service';
import { FormErrorsService } from '../../../_services/form-errors.service';
import { first } from 'rxjs/operators';


@Component({
  selector: "app-add-user",
  templateUrl: "./add-user.component.html",
  styleUrls: ["./add-user.component.scss"],
})
export class AddUserComponent implements OnInit {
  formUser: UntypedFormGroup;
  formProjects: UntypedFormGroup;
  formRates: UntypedFormGroup;
  formUserRates: UntypedFormGroup;
  userRates: Observable<UserRateModel[]>;
  error: any = false;
  cdn = environment.cdn;
  settings: any;
  $companySettings: Observable<CompanySetting[]>
  rates: UntypedFormArray;
  workTypes: Array<CompanySetting>;
  //projectList = [];
  $brandList: Observable<BrandModel[]>;
  projects = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddUserComponent>,
    private brandService: BrandService,
    private projectService: ProjectService,
    private vs: VelvetysoftService,
    private fb: UntypedFormBuilder,
    private formErrors: FormErrorsService
  ) {
    this.formUser = fb.group({
      id: [null],
      name: ["", Validators.required],
      last_name: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      roles: ["", Validators.required],
      brands: [[]],
      send_notification: ["true"],
      photo: [""],
      phone: [""],
      dob: [""],
      position: [""],
      addresses: fb.array([]),
    });
    //CHANGE THIS TO ARRAY ARRAY
    //bid -> pids
    this.formProjects = fb.group({
      projects: [],
    });

    this.formRates = fb.group({
      rates: this.fb.array([])
    })
    this.formUserRates = fb.group({});
    this.$companySettings = this.data.userService.companySettings();
  }

  userIs(user,role){
    console.log(user,role)
    return user.roles.find( r => r.name == role);
  }

  ngOnInit() {
    //console.log(this.settings);
    this.$brandList = this.projectService.projectTree;
    this.settings = this.vs.getSettings("user");
    this.workTypes = this.vs.mutateJobs(this.vs.getSettings('jobs'));

    this.formUser.patchValue(this.data.user);

    console.log(this.data.user)

    //UserRates
    if(this.data.user.timesheet_invoice_rates){
      this.userRates = this.data.user.timesheet_invoice_rates;
    }

    if(this.data.presets){            
      if(this.data.presets.roles)
        this.data.presets.roles = this.settings.roles.find(role => role.name == this.data.presets.roles).id;

      if(this.data.presets.brands)
        this.data.user.brands = [{id: this.data.presets.brands}];

      if(this.data.presets.projects)
        //this.data.user.projects = [{id: this.data.presets.projects}];
        this.projects[this.data.presets.brands] = [this.data.presets.projects];

      this.formUser.patchValue(this.data.presets);
    }

    
    this.formUser.controls.brands.patchValue(
      this.data.user.brands.map((brand) => brand.id)
    );

    if (this.data.user.roles)
      this.formUser.controls.roles.patchValue(
        this.data.user.roles.map((role) => role.id)[0]
      );

    if (this.data.user.projects){
    //   this.formProjects.controls.projects.patchValue(
    //     this.data.user.projects.map((project) => project.id)
    //   );

      // this.data.user.projects.map((project) => project.id)


      // group projects in this.data.user.projects by brand id and then map to array
      this.projects = this.data.user.projects.reduce((r, a) => {
        r[a.brand_id] = [...r[a.brand_id] || [], a.id];
        return r;
      }, {}); 
    }

    //remove ids from this.projects that are not in brands on change
    this.formUser.get('brands').valueChanges.subscribe( data => {      
      Object.keys(this.projects).forEach( key => {
        if(!data.includes(parseInt(key))){
          delete this.projects[key];
        }
      });
    });

    // this.formProjects.get('projects').valueChanges.subscribe(projects => {
    //   console.log(this.formProjects.value, this.projects)
    //   this.projects = this.formProjects.value;
    // });
    // console.log(this.data.user.projects,this.formProjects.value)

    this.rates = this.formRates.get("rates") as UntypedFormArray;
    
    Array.from({ length: this.workTypes.length }, () => {    
      this.rates.push(this.createRate())  
    }); 
    
    this.$companySettings.subscribe(_settings => {
      //COMPANY COLLECTION AND FILL MODEL BY NAME MATCH
      const settings = this.workTypes.map( setting =>({        
        ...setting,
        ..._settings.find( type => type.name == setting.name),
      })) ;      
      //FLIP AND FLOP
      settings.map( setting => {
        setting.overide = setting.value;
        setting.value = null;
        return setting;
      })
      this.rates.patchValue(settings) 
    });
  }

  test(){
    console.log(this.formProjects.value);
  }
  save() {
    let projects = {projects: [ ...Object.values(this.projects).flat()]};
    this.formUser.value.addresses.forEach((address) => {        
      address.is_primary = true;
      address.is_billing = true;
    });

    let user = Object.assign(this.formUser.value, projects);
    if(this.formUserRates.value?.rates?.length > 0 ) user.userRates = this.formUserRates.value;

    this.data.userService
      .addUser(user)
      .pipe(first())
      .subscribe((res) => {
        if (res) {
          console.log(res);
          this.dialogRef.close({
            status: true,
            user: user,
            skipAdd: true, //NORMALL USER IS ADDED AFTER THIS STEP
          });
        }
      });
    // this.dialogRef.close({
    //   status: true,
    //   user: Object.assign(this.formUser.value, this.formProjects.value),
    // });
  }

  createRate(){
    return this.fb.group({
      id: [""],
      name: [""],
      label: [""],
      value: [""],
      type: [""],
      overide: [""]
    });
  }

  get getformRate() {
    return (this.formRates.get("rates") as UntypedFormArray).controls;
  }

  // this.userService
  //   .update<CompanySetting>(this.formRates.value, "company-settings")
  //   .pipe(first())
  //   .subscribe();

  resetPassEmail() {
    this.data.userService
      .resetPassEmail(this.data.user)
      .pipe(first())
      .subscribe((x) => console.log(''));
  }
  resetPass(){
    this.data.userService
      .resetPassDialog(this.data.user)      
  }

  close() {
    this.dialogRef.close();
  }

  delete() {
    this.dialogRef.close({ status: "delete", user: this.formUser.value });
  }

  findBrand(brands, id, pid = null) {
    return this.projectService.selectTree(brands, id, pid);
  }

  userPhoto(imagePath) {
    this.formUser.patchValue({ photo: imagePath });
  }
}
