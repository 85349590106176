import { I } from '@angular/cdk/keycodes';
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { BrandModel, UserProfileModel } from 'src/app/_models/subjects';
import { ProjectService } from 'src/app/_services/project.service';
import { UserService } from 'src/app/_services/user.service';


@Component({
  selector: 'app-project-selector',
  templateUrl: './project-selector.component.html',
  styleUrls: ['./project-selector.component.scss']
})
export class ProjectSelectorComponent {
  @Input() form: FormGroup;
  $brandList: Observable<BrandModel[]>;
  $userProfile: Observable<UserProfileModel>;

  constructor(
    private projectService: ProjectService,
    private userService: UserService,
  ) {
    this.$brandList = this.projectService.projectTreeCache;
    this.$userProfile = this.userService.userProfile();
  }

  ngOnInit(): void {
  }

  

  findBrandProjects(brands,id){
    let brand =  brands.find( brand => brand.id == id)
    if(!brand) return ;
    
    return brand.projects.filter( project => project.status == "active");
  }
}
