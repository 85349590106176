import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { first } from 'rxjs/operators';
import { UploadResults } from 'src/app/_models/subjects';
import { AssetService } from 'src/app/_services/asset.service';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { VelvetysoftService } from 'src/app/_services/velvetysoft.service';

@Component({
  selector: "app-add-asset",
  templateUrl: "./add-asset.component.html",
  styleUrls: ["./add-asset.component.scss"],
})
export class AddAssetComponent implements OnInit {
  @Input() callback: EventEmitter<UploadResults>;
  @Input() plural: boolean = true; //@TODO MAKE A MODEL FOR FILE
  @Input() subject: string;
  @Input() model: object;
  @Input() dialogref: MatDialogRef<any>;
  maxFileSize: string;

  isOver = false;
  constructor(
    private assetService: AssetService,
    private vs: VelvetysoftService
  ) {}

  ngOnInit() {
    this.maxFileSize = this.vs.size(this.vs.maxFileSize);
  }

  @HostListener("dragover", ["$event"]) onDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
    this.isOver = true;
  }

  @HostListener("dragleave", ["$event"]) onDragLeave(e) {
    e.preventDefault();
    e.stopPropagation();
    this.isOver = false;
  }

  @HostListener("drop", ["$event"]) onDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    this.isOver = false;
    console.log(e);
    this.upload(e);    
  }

  upload(_event) {
    const ref = this.assetService.upload(_event, this.subject, this.model);
    if (this.dialogref) this.dialogref.close();
    return ref.pipe(first()).subscribe((assets) => {
      let results: UploadResults = {
        assets: assets,
        model: this.model,
      };
      return this.callback.emit(results);
    });
  }
}
