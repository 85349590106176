import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { VelvetysoftService } from 'src/app/_services/velvetysoft.service';
import { Observable } from 'rxjs';
import { Pager, PagerObject, UserProfileModel, VendorModel } from 'src/app/_models/subjects';

@Component({
  selector: 'app-add-vendor',
  templateUrl: './add-vendor.component.html',
  styleUrls: ['./add-vendor.component.scss']
})
export class AddVendorComponent {
  form: UntypedFormGroup;
  subject: string = "vendor";
  error: any = false;
  $brief: Observable<VendorModel>;
  $userProfile: Observable<UserProfileModel>;
  pager: PagerObject;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddVendorComponent>,
    public fb: UntypedFormBuilder,
    public vs: VelvetysoftService,
  ) {
    this.form = this.fb.group({
      id: [null],
      name: [""],
      phone: [""],
      email: [""],
      addresses: fb.array([]),
    });
  }

  ngOnInit(): void {        
    if(this.data.options.pager) this.pager = this.data.options.pager;
    if (this.data.vendor) {
      this.form.patchValue(this.data.vendor);
    }
  }

  close() {
    this.dialogRef.close();
  }

  makeAddressPrimary(vendor){
    //IF ADDRESSES HAS A ARRAY ITEM
    if(vendor.addresses.length > 0){
      vendor.addresses.forEach((address) => {
        address.is_primary = true;
        address.is_billing = true;
      });
    }
    return vendor
  }

  save(){
    let vendor = this.form.value;
    vendor = (vendor.addresses)
      ? this.makeAddressPrimary(vendor)
      : vendor;

    const options = {skipLoad: true};
    this.data.vendorService
      .add(vendor,options)
      .subscribe( res =>{
        
        if(res.id){          
          if(this.pager){ 
            this.pager.refresh(); 
            this.dialogRef.close({status: 'load', vendor: res});
          }else{
            this.dialogRef.close({status: true, vendor: res});
          }

          
        }
    });
  }

  delete(){
    const options = {skipLoad: true};
    this.data.vendorService
      .deleteDialog([this.form.value.id],options)
      .subscribe( res =>{
        console.log(res);
        if(res.status == 'delete'){
          if(this.pager) this.pager.refresh();
          this.dialogRef.close();
        }
    });
  }

}
