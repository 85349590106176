<div class="container">
  
  <div class="block">
    <div class="block-title">
      <div class="block-title-label">Profile</div>
      
    </div>
    <div class="block-content ng-star-inserted">
      <app-alert></app-alert>
      <form *ngIf="$userProfile | async; let profile" (ngSubmit)="submit()" [formGroup]="form">
        <div *ngIf="profile.company.user_id == profile.user.id" class="form-group">
          You are the admin of <a [routerLink]="['/dashboard/edit/company']">{{ profile.company.name }}</a>
        </div>

        <div class="row">
          <div class="col-sm-3">
            <div class="form-group">
              <div><label class="label">Profile Image</label></div>
              <app-file-upload (uploadComplete)="profileImage($event,'photo')" [_file]="profile.user.photo"></app-file-upload>
            </div>
            <div class="form-group" *ngIf="profile | theUserIs: 'client,contractor,consultant' : true">
              <div><label class="label">Headshot</label></div>
              <app-file-upload (uploadComplete)="profileImage($event,'headshot')" [_file]="profile.user.headshot"></app-file-upload>
            </div>
            <div class="form-group" *ngIf="profile | theUserIs: 'contractor'">  
              <hr>          
              <div class="mat-dialog-title mr-3 pl-0 mb-1 mt-3">Contractor Information</div>
              <div class="form-group">
                <mat-form-field class="w-100">
                  <input matInput placeholder="Company Name" type="text" name="contractor_business_name" formControlName="contractor_business_name"  />
                </mat-form-field>
              </div>
              <div><label class="label">Your Logo</label></div>              
              <app-file-upload (uploadComplete)="profileImage($event,'contractor_logo')" [_file]="profile.user.contractor_logo"></app-file-upload>
            </div>
          </div>
          <div class="col-sm-9">
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">
                  <mat-form-field class="w-100">
                    <input matInput placeholder="First Name" type="text" name="name" formControlName="name" required />
                  </mat-form-field>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <mat-form-field class="w-100">
                    <input matInput placeholder="Last Name" type="text" name="last_name" formControlName="last_name" required />
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <mat-form-field class="w-100">
                    <input matInput [readonly]="true" placeholder="Email" type="email" name="email" formControlName="email"  />
                  </mat-form-field>
                </div>
              </div>
            </div>
             
            <div class="row">
              <div class="col-sm-3">
                <mat-form-field class="w-100">            
                  <input matInput name="dob" formControlName="dob" [matDatepicker]="picker" placeholder="DOB">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>            
                </mat-form-field>      
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <mat-form-field class="w-100">
                    <input matInput placeholder="Phone" type="text" name="phone" formControlName="phone" mask='(000) 000-0000' required />
                  </mat-form-field>
                </div>
              </div>
              <div class="col-sm-3" *ngIf="profile | theUserIs: 'contractor,client': true">
                <div class="form-group">
                  <mat-form-field class="w-100">
                    <input matInput placeholder="Position" type="text" name="position" formControlName="position" />
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="profile | theUserIs: 'client' : true">
              <div class="col-sm-6">
                <label class="mat-dialog-title pl-0" for="">Google Drive</label>
                  <div class="fmb-4">
                    <div class="row ">                
                      <div class="col-sm-12">                  
                        <div class="form-group">                                         
      
                          <div *ngIf="(gapi.$activeted | async); else connected">
                            <div>Signed in as: {{ gapi.guser.email  }}</div>
                            <button mat-button color="primary" (click)="gapi.signOut($event)">Disconnect</button>
                          </div>
      
                          <ng-template #connected>
                            <button mat-raised-button color="primary" (click)="gapi.signIn($event)">Connect</button>
                          </ng-template>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <label class="mat-dialog-title mr-3 pl-0 mb-1 mt-3" for="">Address</label>
                <app-addresses 
                      [currentAddresses]="profile?.user?.addresses" 
                      [form]="form"
                      [hideOptions]="true"></app-addresses> 
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-3">
            
          </div>
        </div>
        <!-- <div>
          <h5>Notifications</h5>
          ....
        </div> -->
        <!-- <button mat-flat-button color="primary" type="submit" [disabled]="submitted || !form.valid">Submit</button> -->
        <button mat-raised-button color="primary" type="submit">Save</button>
      </form>
    </div>
  </div>
 
</div>