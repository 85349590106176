//BEFORE CHANGES

import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { throwError, ReplaySubject, Observable, Subject } from 'rxjs';
import { ApiService } from './api.service';
import { CompanySetting, UserModel, UserProfileModel, apiResponse} from '../_models/subjects';
import { AddUserComponent } from "../_component/dialogs/add-user/add-user.component";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { first, map, tap } from 'rxjs/operators';
import { ConfirmComponent } from '../_component/dialogs/confirm/confirm.component';



@Injectable({
  providedIn: "root",
})
export class UserService {
  private _userProfileData: UserProfileModel;
  private $userProfile: ReplaySubject<UserProfileModel> = new ReplaySubject();
  public userProfileBuster = true;
  subject = "users";
  $ctx_user_data: ReplaySubject<any> = new ReplaySubject(1);
  initUserList = false;

  constructor(
    private router: Router,
    private apiService: ApiService,
    public dialog: MatDialog
  ) {}

  userProfile(skipToast?, SkipErrors?) {
    if (this.userProfileBuster) {
      this.loadUserProfile(skipToast, SkipErrors);
      this.userProfileBuster = false;
    }
    return this.$userProfile.asObservable();
  }

  loadUserProfile(skipToast?, SkipErrors?) {
    this.apiService.get<UserProfileModel>("user",skipToast, SkipErrors).subscribe(
      (res) => this.formatUserProfile(res)
      //,(error) => this.handleError(error)
    );
  }

  /**
   * Iterate over a set of operations which when applied to the original `Iterable` will produce the
   * new `Iterable`.
   *
   * NOTE: These are not necessarily the actual operations which were applied to the original
   * `Iterable`, rather these are a set of computed operations which may not be the same as the
   * ones applied.
   *
   * @param context The relation to users needed ex: brand, project. ( NOT USED YET )
   * @param id The ID of the context to get user based on it
   *
   */
  loadUsersAssignedTo(context = null, id = null) {
     let data = (context) ? { ctx: context} : null;
     this.initUserList = true;
    return this.apiService
      .post<UserModel[]>("users", data)
      .pipe(tap(res => {
        this.$ctx_user_data.next(res) 
      }));      
  }

  get $activeUsers(){
    if(!this.initUserList) this.loadUsersAssignedTo('all','all').pipe(first()).subscribe();
    return this.$ctx_user_data.asObservable();
  }

  pager<T>(data?){
    return this.apiService.pager<T>(`${this.subject}-pager`,data);
  }

  addUser(userData) {
    return this.apiService.post<any>("user-create", userData);
  }

  
  
  deleteUser(uid, permanentDelete) {
    return this.apiService.post<any>("user-delete", {
      uid: uid,
      permanentlyDelete: permanentDelete,
    });
  }

  deleteUsers(ids: Array<Number>, permanentDelete: boolean) {
    return this.apiService.post<any>("users-delete", {
      ids: ids,
      permanentlyDelete: permanentDelete,
    });
  }

  //const REMOVING_USER = result.user;
  // const dialogConfig = new MatDialogConfig();
  // dialogConfig.disableClose = true;
  // dialogConfig.autoFocus = true;
  // dialogConfig.data = {
  //   id: "",
  //   type: "delete",
  //   subType: "user",
  //   permDelete: false,
  //   title: `Delete ${result.user.name} ${result.user.last_name} `,
  //   content:
  //     "Are you sure? <br><br> If you choose to Permanently Delete the user this can not be undone and is permanent",
  // };
  // const dialogRef = this.dialog.open(ConfirmComponent, dialogConfig);

  // dialogRef.afterClosed().subscribe((result) => {
  //   if (result && result.status === "delete") {
  //     this.deleteUser(REMOVING_USER.id, result.data.permDelete).subscribe(
  //       (res) => {
  //         resultRef.next(result.user);
  //       }
  //     );
  //   }
  //   //resultRef.next(result);
  // });

  deleteDialog(ids: Array<Number>, options = { skipLoad: false }) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      type: "delete",
      subType: "user",
      permDelete: false,
      title: `Delete ${ids.length} ${this.subject} `,
      content: "This can not be undone.",
    };

    let resultRef = new Subject();
    const dialogRef = this.dialog.open(ConfirmComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.status === "delete") {
        this.deleteUsers(ids, result.data.permDelete)
          .pipe(first())
          .subscribe((res) => {
            this.loadUsersAssignedTo("all", "all");
          });
        //.subscribe((res) => {
        //this.loadSubject();
        //this.loadProposalBrand(this.brand, true);
        //});
      }
      resultRef.next(result);
    });
    return resultRef;
  }

  resetPassEmail(user: UserModel) {
    return this.apiService.post<any>("forgot", { email: user.email });
  }

  resetPassManually(values) {
    return this.apiService.post<any>("password-reset", values);
  }

  resetPassDialog(user) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: "",
      type: "password-reset",
      subType: "user",
      user: user,
      title: `Manually Reset ${user.name} Password `,
      content: "Update Password",
      userService: this,
    };
    const dialogRef = this.dialog.open(ConfirmComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  addDialog(user?: UserModel,presets?:Object,options?) {
    let _presets = presets;
    let resultRef = new Subject<UserModel>();
    let _user: UserModel = user
      ? user
      : {
          id: null,
          name: null,
          last_name: null,
          email: null,
          roles: null,
          brands: [],
        };

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      userService: this,
      user: _user,
      presets: _presets,
      options: { $profile: this.$userProfile }
    };

    const dialogRef = this.dialog.open(AddUserComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.status === true && result.skipAdd) {
        //WHAT IS SKIP ADD
        resultRef.next(result.user);
      } else if (result && result.status === true) {
        this.addUser(result.user).subscribe((res) => {
          resultRef.next(result.user);
        });
      } else if (result && result.status === "delete" && result.user) {
        //@TODO might want to seperate this
        const REMOVING_USER = result.user;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
          id: "",
          type: "delete",
          subType: "user",
          permDelete: false,
          title: `Delete ${result.user.name} ${result.user.last_name} `,
          content:
            "Are you sure? <br><br> If you choose to Permanently Delete the user this can not be undone and is permanent",
        };
        const dialogRef = this.dialog.open(ConfirmComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((result) => {
          if (result && result.status === "delete") {
            this.deleteUser(REMOVING_USER.id, result.data.permDelete).subscribe(
              (res) => {
                resultRef.next(result.user);
              }
            );
          }
          //resultRef.next(result);
        });
      }
    });
    return resultRef;
  }


  removeRate(id){
    return this.apiService.post<any>("user-rate-remove", {id: id});
  }

  //CHECK ROLES this is a PIPE
  // public isRole(RoleSlug){
  //   return this._userProfileData.user.roles;

  //   // profile.user.roles
  // }

  //FIX THIS TYPECASTING
  public update<T>(user, action): any {
    console.log(user, action);
    return this.apiService.post<T>(action, user).pipe(
      map((data) => {
        this._userProfileData[action] = data;
        this.$userProfile.next(this._userProfileData);
        return data;
      })
    );
  }

  public createCompany(data):Observable<any>{
    return this.apiService.post("company-create",data);
  }

  public companySettings():Observable<CompanySetting[]>{
    return this.apiService.get("company-settings");
  }

  public removeCompanySetting(id:number|string):Observable<apiResponse>{
    return this.apiService.post("company-setting-delete",{id:id});
  }

  public staff(date?):Observable<any>{
    let datea = (date) ? `?date=${date}` : '';
    return this.apiService.get(`company-staff${datea}`);
  }

  // COMBINE COMPANY AND USER INTO USERPROFILE
  private formatUserProfile(res) {
    //@TODO CHECK IF NO COMPANY IS ADDED and make errors
    if (res.companies) {
      let cid = res.user.active_company;
      res.company = res.companies.find((c) => c.id === cid);
      //exception if not set
      if (!res.company) res.company = res.companies[0];
    }
    this._userProfileData = res;
    this.$userProfile.next(res);
  }

  
}
