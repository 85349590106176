import { Component, OnInit, Output, Input, SimpleChanges } from '@angular/core';
import { FileService } from '../../_services/file.service';
import { environment } from '../../../environments/environment';
import { EventEmitter } from '@angular/core';



@Component({
  selector: "app-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.scss"],
})
export class FileUploadComponent implements OnInit {
  cdn = environment.cdn;
  file: string = null;
  isPDF = null;
  
  @Output() uploadComplete: EventEmitter<any>;
  @Input() _file: string; //@TODO MAKE A MODEL FOR FILE
  @Input() type: string = "profile";
  @Input() text: string = "Upload file";
  @Input() hideFile: boolean = false;  
  @Input() removeBtn = null;
  @Input() icon = false;
  @Input() csss: string = "btn btn-primary";

  constructor(private fileService: FileService) {
    this.uploadComplete = new EventEmitter();
  }
  

  ngOnInit() {
    this.managePreview(this._file);
  }

  managePreview(file){
    if (file) {
      let fileExt = file.split('.').pop();
      if(fileExt == 'pdf'){    
        this.isPDF = `${this.cdn}${file}`;
        //IF PDF CHANGE FILE EXTENSION FOR THUMB
        let path = ( file && file.includes(".pdf") )
          ? file.replace("\.pdf", ".jpg")
          : file;
        
          this.file = this.cdn + path.replace("original", "thumb")          
      }else{
        this.file = `${this.cdn}${file.replace("original", "thumb") }`;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes._file && changes._file.currentValue){
      //console.log('a',this._file,changes)
      this.managePreview(changes._file.currentValue);
      //console.log('c',this._file,changes)
    }
  }

  remove(file) {
    this.file = "";
    this.uploadComplete.emit("");
  }

  uploadFile(event) {
    let elem = event.target;
    if (elem.files.length > 0) {
      let formData = new FormData();
      formData.append("file", elem.files[0]);
      formData.append("type", this.type);

      this.fileService.sendFile(formData).subscribe((path) => {
        if (path) {
          this.file = `${environment.cdn}${path}`;
          this.uploadComplete.emit(path);
        }
      });
    }
  }
}
