import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';
import { VelvetysoftService } from '../../../_services/velvetysoft.service';
import { first } from 'rxjs/operators';
import { HoldingCompanyModel, Settings, UserProfileModel } from "../../../_models/subjects";
import { AlertService } from '../../../_services/alert.service';
import { ConfirmComponent } from '../../dialogs/confirm/confirm.component';
import { Observable } from 'rxjs';
import { HoldingCompanyService } from 'src/app/_services/holding-company.service';
import { AddHoldingCompanyComponent } from '../../add-holding-company/add-holding-company.component';
import { UserService } from 'src/app/_services/user.service';


@Component({
  selector: 'app-add-brand',
  templateUrl: './add-brand.component.html',
  styleUrls: ['./add-brand.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ]
})
export class AddBrandComponent implements OnInit {

  form: UntypedFormGroup;
  error: any = false;
  settings: Settings = null;
  swatchOpen = false;
  //states: object[];
  subject: string = 'Brand';
  //addresses: UntypedFormArray;
  preview: any;
  $holdingCompanies: Observable<HoldingCompanyModel[]>
  useHoldingCompany: boolean = false;
  $userProfile: Observable<UserProfileModel>;

  constructor(
    private dialogRef: MatDialogRef<AddBrandComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private vs: VelvetysoftService,
    private holdingCompanyService: HoldingCompanyService,
    private UserService: UserService,
  ) {
    
  }
  

  ngOnInit() {    
    this.$holdingCompanies = this.holdingCompanyService.companies();
    this.$userProfile = this.UserService.userProfile();
    this.form = this.fb.group({
      id: [null],
      name: ['', Validators.required],
      color: ['', Validators.required],
      active: ['', Validators.required],
      logo: [''],
      gfolder: [''],
      users: [''],
      prefix: ['', Validators.required],
      client: [''],
      yearly_forcast: [''],
      invoice_overdue_day: [''],
      holding_company_id: [''],
      addresses: this.fb.array([]),
    });
    this.data.brand.yearly_forcast = this.vs.getYearProjection(this.data.brand);
 
    this.form.patchValue(this.data.brand);
    if(this.data.brand.holding_company_id) this.useHoldingCompany = true;
    this.preview = this.data.brand;
    this.form.valueChanges.subscribe(frm => {      
      if(this.preview?.name) this.preview.name = frm.name;
    })
    
  }

  

 

  removeHC(){
    if(this.useHoldingCompany == false){
      this.form.controls.holding_company_id.patchValue(null);
    }
  }

  statusChange(ob: MatSlideToggleChange) {
    console.log(ob.checked);
  }

  

  colorChange(e) {    
    let color = (e && e.color && e.color.hex) ? e.color.hex : null;
    this.form.patchValue({ color: color });
    this.preview.color = color;
    console.log(this.preview.color);
  }

  brandLogo(imagePath) {    
    console.log(imagePath);
    this.form.patchValue({ logo: imagePath });
    this.preview.logo = imagePath;
  }
  

  save() {
    if (this.form.valid) {
      this.form.value.addresses.forEach((address) => {        
        address.is_primary = address.is_primary ? true : false;
        address.is_billing = address.is_billing ? true : false;
        console.log(address);
      });
      console.log(this.form.value)
      this.dialogRef.close({ status: true, brand: this.form.value });
      this.form.reset();
      this.form.controls.users.patchValue(null)
      this.preview = null;
      this.form.controls.color.patchValue('');
    }    
  }

  resetForm(){
    
  }

  delete() {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      data: {        
        type: "delete",
        title: `Delete : ${this.data.brand.name}`,
        content: `Are you sure you want to permanently delete ${this.data.brand.name}`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if(result && result.status == 'delete') this.dialogRef.close({ status: 'delete' });
    });
  }



  close() {
    this.dialogRef.close();
  }

  addHcs(hcs?: HoldingCompanyModel){
    this.holdingCompanyService.edit(hcs, {brand_id: this.data.brand.id, skipLoad: true})
      .pipe(first())
      .subscribe( (result) => {
        if(result.id) this.form.controls.holding_company_id.patchValue(result.id);        
      });
  }


}
