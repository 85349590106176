import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';  

@Component({
  selector: 'app-fe',
  templateUrl: './fe.component.html',
  styleUrls: ['./fe.component.scss']
})
export class FeComponent implements OnInit {
  page = '';
  constructor(
    private readonly location: Location
  ) { }

  ngOnInit(): void {
    this.page =this.location.path();
  }

}
