<div class="container-fluid" *ngIf="($userProfile | async); let profile">    
    <div>
        <div class="col-sm-12" *ngIf="($brandList | async); let brands">
            <div class="block" *ngIf="lang">
                
                <div class="container">
                    <div class="block-title">     
                        <div class="block-title-label" for="">
                            <div class="subhead-large" for="">My {{subject}} Reports</div>                     
                        </div>                   
                        <div class="block-title-toolbar">
                            <button *ngIf="profile | userCan: 'create timesheets'" mat-button color="primary" (click)="add(profile)"><mat-icon>add</mat-icon>New {{ subject }}</button>
                            &nbsp;
                        </div>
                    </div>

                    <app-alert></app-alert>


                    <app-table
                        mat-table 
                        #vaultTable                              
                        [dataSource]="$expenses"          
                        [columns]="displayedColumns"> 

                        <ng-container matColumnDef="title">
                            <th mat-header-cell *matHeaderCellDef> Title </th>
                            <td mat-cell *matCellDef="let element" class="text-capitalize click" (click)="edit(element)">{{element.title}} </td>
                        </ng-container>

                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef> Date </th>
                            <td mat-cell *matCellDef="let element" class="text-capitalize click" (click)="edit(element)"> 
                                <ng-container *ngIf="element.expenses; else single">{{element.created_at | date}} </ng-container>
                                <ng-template #single>{{element.date | date}}</ng-template>                                
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="category">
                            <th mat-header-cell *matHeaderCellDef> Type </th>
                            <td mat-cell *matCellDef="let element" class="text-capitalize click" (click)="edit(element)">
                                <ng-container *ngIf="element.expenses; else single">{{element?.expenses[0]?.category }} </ng-container>
                                <ng-template #single>{{element.category}}</ng-template>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="total">
                            <th mat-header-cell *matHeaderCellDef > Total </th>
                            <td mat-cell *matCellDef="let element">{{ element.total | currency }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef> Status </th>
                            <td mat-cell *matCellDef="let element"> 

                              <ng-container *ngIf="element.expenses; else single">
                                <meta-display subject="expense" [item]="element" type="statuses" [id]="element?.expenses[0]?.status"></meta-display>   
                                <span *ngIf="element.deleted_at" class="dot-label sent mx-1">Deleted {{ element.deleted_at | date }}</span>
                              </ng-container>
                              <ng-template #single><meta-display subject="expense" [item]="element" type="statuses" [id]="element?.status"></meta-display></ng-template>
                              
                            </td>
                          </ng-container>

                        <ng-container matColumnDef="type">
                            <th mat-header-cell *matHeaderCellDef> Category </th>
                            <td mat-cell *matCellDef="let element">
                                <ng-container *ngIf="element.expenses; else single">
                                    <meta-display subject="expense" [item]="element" type="types" [id]="element?.expenses[0]?.type"></meta-display>
                                </ng-container>
                                <ng-template #single>
                                    <meta-display subject="expense" [item]="element" type="types" [id]="element?.type"></meta-display>
                                </ng-template>
                            </td>
                        </ng-container>       

                        <ng-container matColumnDef="actions">
                        <th mat-header-cell class="text-right" *matHeaderCellDef> Actions </th>
                        <td mat-cell class="text-right" *matCellDef="let element">    
                            <button (click)="edit(element)" class="mat-sm" mat-icon-button mat-button>
                            <mat-icon>edit</mat-icon>
                            </button>
                        </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns; "></tr>
                    </app-table>

                    <!-- <div *ngIf="($expenses | async); let expenses">
                        <div *ngFor="let expense of expenses">
                            <div>{{ expense.title }}</div>
                        </div>
                    </div> -->

                    <!-- <div class="form-group" [formGroup]="form">
                        <div formArrayName="expenses" 
                            class="form-row expense" 
                            *ngFor="let expense of formExpenses; let i = index"
                            (click)="toggle(i)"
                            [ngClass]="{'closed': form.get('expenses').value[i].id !== '' && getCtxFormVal(i,'_open') == false }">
                            <div class="preview row m-0 vault-table">
                                <div class="col-sm-3 td">
                                    {{ getCtxFormVal(i,'title')  }}
                                </div>
                                <div class="col-sm-1 td" >
                                    {{ getCtxFormVal(i,'date') | date }}
                                </div>
                                <div class="col-sm-2 td" >
                                    <span class="label mr-2"></span> <meta-display subject="expense" [item]="getCtxFormVal(i)" type="statuses" [id]="getCtxFormVal(i,'status')"></meta-display>
                                </div>    
                                <div class="col-sm-1 td">
                                    <span *ngIf="invoiceName(getCtxFormVal(i,'invoice_id')); let invoice; else noinvoice">
                                        #{{ invoice.invoice_number }}
                                    </span>
                                    <ng-template #noinvoice>
                                        -
                                    </ng-template>
                                </div>          
                                <div class="col-sm-1 td">
                                    {{ getCtxFormVal(i,'total') | currency }}
                                </div>         
                                <div class="col-sm-2 td" *ngIf="getCtxFormVal(i,'payment_ref');let pref">
                                    Payment Ref: {{ pref  }}
                                </div>         
                            </div>
                            <div  class="exist-hide row pl-3 m-0 w-100 rel" [formGroupName]="i">                               
                                <div class="remove-ico " *ngIf="!form.get('expenses').value[i].id">
                                    <button                                                         
                                        mat-icon-button 
                                        color="primary" 
                                        matTooltip="Remove"
                                        matTooltipPosition="right" 
                                        (click)="removeline(i)" 
                                        class="quick-remove">
                                            <mat-icon>remove</mat-icon>
                                    </button>
                                </div>
                                <div class="col-sm-9">
                                    <div class="row">
                                        <div class="col-sm-5">              
                                            <mat-form-field class="w-100">
                                                <input matInput name="title" placeholder="Title" formControlName="title" required>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-sm-3  d-flex align-items-center">
                                            <mat-checkbox formControlName="reimbursement">Reimbursement Needed</mat-checkbox>
                                        </div>
                                        <div class="col-sm-2">
                                            <mat-form-field class="w-100">
                                                <mat-select  placeholder="Type" formControlName="type">
                                                    <mat-option *ngFor="let type of types" [value]="type.value">
                                                        {{type.title}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-sm-2 ">
                                            <mat-form-field class="w-100">
                                                <input matInput [matDatepicker]="picker" placeholder="Date of Expense" formControlName="date">
                                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                                <mat-error *ngIf="form.get('expenses').value[i].date.invalid">Enter a valid date</mat-error>
                                                
                                                </mat-form-field>
                                        </div>
                                        <div class="col-sm-6">
                                            <mat-form-field class="w-100">
                                                <input matInput name="from" placeholder="Purchase From" formControlName="from">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-sm-4 ">
                                            <mat-form-field class="w-100">
                                                <span matPrefix>$ &nbsp;</span>
                                                <input matInput placeholder="Total" autocomplete="off" formControlName="total" mask="separator.2"
                                                thousandSeparator="," required>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-sm-3" style="overflow: hidden;">
                                            <div class="d-flex">
                                                
                                                <mat-form-field class="">
                                                    <mat-select  placeholder="Brand" formControlName="brand_id">
                                                        <mat-option [value]="-1" >
                                                            <app-brand-logo [brand]="profile.company"></app-brand-logo> &nbsp; {{profile.company.name}}
                                                        </mat-option>
                                                        <mat-option *ngFor="let brand of brands | keyvalue" [value]="brand.value.id">
                                                            <app-brand-logo [brand]="brand.value"></app-brand-logo> &nbsp; {{brand.value.name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div *ngIf="form.get('expenses').value[i].brand_id; let bid">
                                                <div *ngIf="bid != -1">
                                                    <mat-form-field class="w-100" *ngIf="findBrandProjects(brands,bid); let projects">
                                                        <mat-select  placeholder="Project" formControlName="project_id">
                                                            <mat-option value="">- None -</mat-option>
                                                            <mat-option *ngFor="let project of projects | keyvalue" [value]="project.value.id">
                                                                {{project.value.name}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>                                      
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-2" >
                                            <div *ngIf="form.get('expenses').value[i].brand_id; let bid">
                                                <ng-container *ngIf="avalibleInvoices(bid); let invoices">
                                                    <mat-form-field class="w-100" *ngIf="bid !== -1">
                                                        
                                                        <mat-select  placeholder="Invoice#" formControlName="invoice_id">
                                                            <mat-option>- None Available -</mat-option>
                                                            <mat-option *ngFor="let invoice of invoices" [value]="invoice.id">
                                                                {{invoice.title}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </ng-container>
                                            </div>
                                        </div>                                        
                                        
                                        <div class="col-sm-8">
                                            <mat-form-field class="w-100">
                                                <textarea matInput placeholder="Notes" formControlName="notes" required></textarea>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-3 ">
                                    <div class="label">Receipt</div>
                                    <app-file-upload 
                                        type="receipt"
                                        class="might-be-trans"
                                        text="Upload Receipt"
                                        (uploadComplete)="uploadReceipt($event,i)" 
                                        [_file]="form.get('expenses').value[i].receipt">
                                    </app-file-upload>
                                    <div class="py-3">
                                        <button class="mr-1" (click)="save($event,expense,i)" color="accent" mat-raised-button>
                                            <span *ngIf="form.get('expenses').value[i].id">Save</span>
                                            <span *ngIf="!form.get('expenses').value[i].id">Create</span>
                                        </button>
                                        <button class="mr-1" *ngIf="form.get('expenses').value[i].id" (click)="collapse($event,i)" mat-button>Cancel</button>                                        
                                        <button *ngIf="form.get('expenses').value[i].id" class="mr-1" (click)="delete($event,form.get('expenses').value[i])" color="warn" mat-button>Delete</button>
                                    </div>
                                </div>
                                
                                
                                
                                
                                                                                                                                                                                         
                            </div>
                        </div>
                        
                
                    </div> -->
                </div>
            </div>
        </div>
    </div>    
</div>