import { Component, OnInit, Input } from '@angular/core';
import { VelvetysoftService } from '../../_services/velvetysoft.service';
import * as moment from "moment";

@Component({
  selector: 'meta-display',
  templateUrl: './meta-display.component.html',
  styleUrls: ['./meta-display.component.scss']
})
export class MetaDisplayComponent implements OnInit {
  @Input() subject;
  @Input() type:'statuses' | 'types';
  @Input() id;
  @Input() format;
  @Input() item: any = null;
  display: string = '--';
  constructor(
    private vs: VelvetysoftService
  ) { }

  ngDoCheck(){
    this.display = this.vs.settings[this.subject][this.type]
      .filter(item => item.value == this.id)
      .map(item => item.title)
  }

  ngOnInit() {        
    if (!this.subject || !this.type || !this.id) throw "Incorect paramaters";
    
  }

  isOverDue(item){
   return ( moment(item.due_date) > moment().startOf("day") )
    ? true
    : false    
  }

}
