import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormBuilder, FormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { UserService} from '../../_services/user.service';
import { UserModel, UserProfileModel } from "../../_models/subjects";
import { Observable, Subject} from 'rxjs';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent, MatLegacyAutocomplete as MatAutocomplete } from '@angular/material/legacy-autocomplete';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { first, map, startWith } from 'rxjs/operators';

@Component({
  selector: "users-select",
  templateUrl: "./users-select.component.html",
  styleUrls: ["./users-select.component.scss"],
})
export class UsersSelectComponent implements OnInit {
  @ViewChild("userInput") userInput: ElementRef<
    HTMLInputElement
  >;
  @ViewChild("auto") matAutocomplete: MatAutocomplete;

  // SELECTED USERS THAT ALREADY EXIST
  @Input() users: Array<UserModel> | any;

  //FILTER USERS BY ROLE
  @Input() role: string; 

  //CONTEXT OF THE USER EX: BRAND, Project, ACCOUNT
  @Input() context: string;

  //TARGET LIST TO BE UPDATED 
  @Input() userList: any; 

  //ADDITION SCREEN
  @Input() contextid: number;
  
  @Input() title: any; //TARGET MODEL
  @Input() allowAnnonymous: boolean = false;
  //AUTO FILL WILL FILL IN A NEW USER AFTER ADEDE
  @Input() autofill: boolean = true;
  //PREPOPOLATE WILL FILL IN THE USERS THAT ARE ALREADY IN THE LISt context
  @Input() prepopulate: boolean = true;
  @Input() toolTip: boolean = false;
  //FILL WHEN CREATING
  @Input() fillproject: string|null = null; 
  @Input() fillrole: string|null = null; 
  @Input() fillbrand: string|null = null; 
  _title: string;
  listID = null;

  //AutoComplete
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  //$$avalibleUsers = new Subject();
  $avalibleUsers: UserModel[]; //ALL AVALIBEL USERS ON THE ACCOUNT
  userCtrl = new UntypedFormControl(); //CONTROL FOR AUTOCOMPLETE
  //_users: UserModel[] = []; //UPDATED USER MODEL
  filteredUsers: Observable<UserModel[]>;
  userProfile: UserProfileModel;
  EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  constructor(
    private userService: UserService
    ) {
    this.filteredUsers = this.userCtrl.valueChanges.pipe(
      startWith(null),
      map((user: UserModel | null) =>{       
        return user ? this._filter(user) : this.$avalibleUsers.slice()
        }
      )
    );
  }

  ngOnInit() {  
    this.userList = [];        
    this.listID = null;//this.userList && this.userList.id ? this.userList.id : null;    
    this._title = this.title ? this.title : this.role + "s";
    this.userService.userProfile().pipe(first()).subscribe((profile) => {
      this.userProfile = profile;
      this.reloadUsers();
    });
    
  }

  //@TODO: look at this a subscribe shouldn't be in here
  reloadUsers(emailToAdd?){            
    this.userService
      //.loadUsersAssignedTo()
      .$activeUsers
      .pipe(first())
      .subscribe((users) => {
        this.$avalibleUsers = [];

        //LIST TO SELECT FROM
        console.log('users',this.contextid, this.context, users)
        this.$avalibleUsers = ( !(this.contextid && this.context) ) 
          ? users
          : users.filter( user => {
              //console.log('adding users',user, this.context, this.contextid)
              return user[this.context]
                .find( u => u.id == this.contextid)
        });
        

        //IF USERS ARE ALREADY ADDED SKIP THIS, IF NO USER prefill with brand,
         if(this.users.length == 0 && this.contextid && this.context && this.prepopulate){
          this.$avalibleUsers.forEach(user => {
            this.users.push(user);
          });
           //this.users = this.$avalibleUsers        
         }

        //if this.user
        if(this.users.length == 0 && !this.contextid){
          this.users.push(this.userProfile.user);
        }


        //FILL IT ?
        if(this.autofill && emailToAdd){
          let attached = this.$avalibleUsers.find(user => emailToAdd == user.email);
          this.users.push(attached);
          //reload brand
          //if(this.fillbrand)
          //reload project
          //if(this.fillproject)
          
        }
      })
  }



  add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      if (!this.EMAIL_REGEXP.test(value)) return;
      //console.log('inside', event)

      // Add our fruit
      if ((value || "").trim()) {
        if (this.allowAnnonymous)
          this.users.push({ email: value.trim(), name: value.trim() });
      }

      // Reset the input value
      if (input) {
        input.value = "";
      }

      this.userCtrl.setValue(null);
    }
  }

  remove(user: UserModel): void {
    //if (user.id) _.remove(this.users, { id: user.id });
    
    //if(user.id) this.users = [...this.users.filter(u => u.id !== user.id)];
    //if (user.id) _.remove(this.users, { id: user.id });
    for (var i = 0; i < this.users.length; i++) {
        if (this.users[i].id === user.id) {
            this.users.splice(i, 1);
        }
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    //console.log(event.option.value);
    this.users.push(event.option.value);
    this.userInput.nativeElement.value = "";
    this.userCtrl.setValue(null);
  }

  createUser() {
    const fill = { 
      brands: this.fillbrand,
      projects: this.fillproject,
      roles: this.fillrole };
    this.userService
      .addDialog(null,fill)
      .pipe(first())
      .subscribe((user: UserModel) => {

        let res = (user?.email)
          ? user?.email
          : null;        
        this.userService.loadUsersAssignedTo().pipe(first()).subscribe(
          (users) => {
            //console.log(res, users)
            this.reloadUsers(res);
          });              
      });
  }

  ngOnDestroy() {

  }

  public _trackItem(index: number, user: any) {
    return index;
  }

  private _filter(user: any): UserModel[] {
    //if (_.isString(user)) {
    if (  typeof user === 'string' ){
      const filterValue = user.toLowerCase();
      return this.$avalibleUsers.filter(
        (user) => user.name.toLowerCase().indexOf(filterValue) === 0
      );
    }
  }
}
