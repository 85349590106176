

<div 
  *ngIf="(url | secure | async); let nerv" 
  class="asset-preview-modal">          
        <!-- <img sdfsdf
            class="asset-preview" 
            [ngClass]="{noThumb: !asset.hasThumb, 'gthumb': asset.g_id}"             
            [src]="assetThumb(asset)"> -->
        <div class="color-white" *ngIf="nerv?.gallery">{{nerv.gallery.current}} of {{nerv.gallery.pages}} </div>
        <button (click)="gallery(nerv.gallery.prev)" *ngIf="nerv?.gallery?.prev" color="white" mat-raised-button class="asset-btn asset-prev"><mat-icon class="" >chevron_left</mat-icon></button>

        <ng-container *ngIf="isVideo">
          <video 
              *ngIf="($videoPreview | async); let video;"
              style="max-width: 100%;"
              class="asset-preview"               
              [attr.src]="video"
              controls>
          </video>
        </ng-container>

        <ng-container *ngIf="!isVideo">
          <img             
              style="max-width: 100%;"
              class="asset-preview" 
              [ngClass]="{noThumb: !asset.hasThumb, 'gthumb': asset.g_id}"             
              [attr.src]="nerv.image">
        </ng-container>
        <button (click)="gallery(nerv.gallery.next)" *ngIf="nerv?.gallery?.next" color="white" mat-raised-button class="asset-btn asset-next"><mat-icon class="" >chevron_right</mat-icon></button>

</div>

<div class="py-3 d-flex align-items-center justify-content-between">
  <div class="title-shadow">
    <span class="desc mr-1">{{ asset.title }}</span>
    <span class="size">{{ size(asset.size) }}</span>
  </div>
  <div>
    <button class="mx-1" color="white" (click)="download(asset)" mat-button><mat-icon>cloud_download</mat-icon> &nbsp; Download</button>
    <button class="mx-1" color="white" (click)="edit(asset)" mat-button><mat-icon>edit</mat-icon> &nbsp; Edit</button>
  </div>
</div>


