import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { PermissionModel } from '../_models/subjects';


@Injectable({
  providedIn: 'root'
})
export class PermsService {

  constructor(
    private apiService: ApiService,
    public dialog: MatDialog,
  ) {

  }

  createRole(name):Observable<any>{
    return this.apiService.post('role',{name: name});
  }

  createPerm(name):Observable<any>{
    return this.apiService.post('perm',{name: name});
  }

  assignPerm(id,role, status){
    return this.apiService.post('assign-perm',{perm: id, role: role, status: status});
  }

  perms(){
    return this.apiService.get('perms');
  }
}
