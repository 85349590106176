
<div class="container" *ngIf="roles else noProjects">
    <div class="block">
      <div class="block-title" *ngIf="vs.subject('permissons'); let subject">
        <div class="block-title-label"><mat-icon *ngIf="subject.icon" class="mr-2">{{ subject.icon }}</mat-icon>{{ subject.label }}</div>      
        <div class="block-title-toolbar">
          <button mat-button color="primary" ><mat-icon>add</mat-icon>New {{subject.label}}</button>
          &nbsp;
          <button mat-button [matMenuTriggerFor]="menu" color="primary"><mat-icon>sort</mat-icon>Actions</button>
          <mat-menu #menu="matMenu" class="menu-sm">
            <button mat-menu-item  >Delete</button>
          </mat-menu>
        </div>
      </div>
      <div class="block-content" *ngIf="displayedColumns.length > 0">

        <div *ngIf="(socketMsg | async)let msg">... {{ msg }}</div>
        <button mat-button (click)="test()">Test</button>

        <!-- <div  >{{ i }}</div> -->
        <div >
          <app-table
            mat-table 
            #vaultTable
            [dataSource]="$permissions"
            [columns]="displayedColumns"> 

            <ng-container matColumnDef="label">
              <th mat-header-cell *matHeaderCellDef > Permission </th>
              <td mat-cell *matCellDef="let element" class="strong"> {{ element.name }} </td>
            </ng-container>

            <ng-container *ngFor="let col of displayedColumns | slice:1" [matColumnDef]="col">
              <th mat-header-cell *matHeaderCellDef> {{col}} </th>
              <td mat-cell *matCellDef="let element" >
                <mat-checkbox color="primary"
                  class="" (change)="assignPerm($event,element,col)" [checked]="isActive(element,col)" [disabled]="isNotCustom(element,col)" [value]="isActive(element,col)">
                </mat-checkbox>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns; "></tr>

          </app-table>
          <mat-form-field >
            <input matInput placeholder="Permission" type="text" [(ngModel)]="nameP" >           
          </mat-form-field>
          <button mat-button color="primary" (click)="createPerm(nameP)">Add</button>
        </div>
      </div>

      <div class="block-title mt-4" *ngIf="vs.subject('permissons'); let subject">
        <div class="block-title-label"><mat-icon *ngIf="subject.icon" class="mr-2">{{ subject.icon }}</mat-icon> Roles</div>      
        <div class="block-title-toolbar">          
          
        </div>
      </div>
      <div class="block-content">

        <div class="" *ngFor="let role of roles">
            <strong >{{ role.name }}</strong> {{ role.permissons }} <span *ngIf="role?.company_id" class="label">Custom</span>
            <hr>
        </div>
        <mat-form-field >
          <input matInput placeholder="Role" type="text" [(ngModel)]="name" >           
        </mat-form-field>
        <button mat-button color="primary" (click)="create(name)">Add</button>
      </div>


      <div class="mt-4">
        <h3 class="block-title-label">Testing</h3>
        <input type="text" [(ngModel)]="testInput">
        <button mat-button (click)="testuser()">User Test</button>
        <button mat-button (click)="testCompany()">company Test</button>
      </div>

    </div>
  </div>
  <ng-template #noneYet>
    <p>No  found</p>
  </ng-template>
  <ng-template #noProjects>
    <mat-spinner color="primary" class="mx-auto loader"></mat-spinner>
  </ng-template>