import {Component, OnInit, OnDestroy } from '@angular/core';
import { BrandService } from '../../../_services/brand.service';
import { Observable, Subscription } from 'rxjs';
import { BrandModel, ProjectModel } from 'src/app/_models/subjects';
import { ProjectService } from 'src/app/_services/project.service';
import { ActivatedRoute, Router, NavigationEnd, ActivationEnd } from '@angular/router';
import { filter, map } from "rxjs/operators";
import { VelvetysoftService } from 'src/app/_services/velvetysoft.service';

  
@Component({
  selector: 'app-team-bar',
  templateUrl: './team-bar.component.html',
  styleUrls: ['./team-bar.component.scss']
})
export class TeamBarComponent implements OnInit, OnDestroy {
  $activeBrand: Observable<BrandModel>;
  $activeProject: Observable<ProjectModel>;
  isProject: boolean = false;
  isVisible: boolean = true;
  routerSub: Subscription;

  constructor(
    private brandService: BrandService,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private router: Router,
    private vs: VelvetysoftService
  ) {
    this.routerSub = router.events.pipe(
       filter((val) => val instanceof NavigationEnd))
       .subscribe((val: NavigationEnd) => {        
        this.screen(val);        
       }
    );    
  }

  ngOnInit() {
    this.$activeBrand = this.brandService.activeBrand;
    this.$activeProject = this.projectService['$activeProject'];  
    this.screen({url: window.location.pathname});  
  }

  screen(currentPath){
    let item = this.vs.noTeamBar.find((item) => item.path == currentPath.url);
    let HIDE = (item && Object.keys(item).length > 0);    
    this.isVisible = (HIDE) ? false : true ;
    //console.log(item,HIDE, `this path should ${(HIDE)?'not':''} have a team bar`,`VISIBILITY set TO ${this.isVisible}`);
    this.isProject = currentPath?.url.includes("/dashboard/project/") ? true : false;    
  }

  ngOnDestroy(){
    this.routerSub.unsubscribe();
  }

}
