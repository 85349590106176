import { UntypedFormGroup, AbstractControl, UntypedFormArray, Validators } from "@angular/forms";


// @TODO: this only works on one of many
export function MultiPhaseTypeRequired(field:string, isEnabled:boolean){
    return (formGroup: UntypedFormGroup) => {
        const control = formGroup.controls[field];     
        if(isEnabled){
            console.log(control);
            return Validators.required(control);            
        }else{
            control.setErrors(null);
        }
    }
}

// Validator for Project
export function CompareRounds(erounds: string, edateround: string){
    console.log(erounds,edateround)
    
    return (formGroup: UntypedFormGroup) => {
        
        const control = formGroup.controls[erounds];        
        // @ts-ignore
        const matchingControl:UntypedFormArray = formGroup.controls[edateround].length;
        //return;
        if(matchingControl !=  control.value){
            control.setErrors({'compareRounds': 'Check the estimated rounds and estimated round dates'});
        } else {
            control.setErrors(null);
          }
        // if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        //   return;
        // }
    
        // if (control.value !== matchingControl.value) {
        //   matchingControl.setErrors({ mustMatch: true });
        // } else {
        //   matchingControl.setErrors(null);
        // }
      };
}

//PROPOSAL
export function requiredApproval(erounds: string, edateround: string){
    console.log(erounds,edateround)
    
    return (formGroup: UntypedFormGroup) => {
        
        const control = formGroup.controls[erounds];        
        // @ts-ignore
        const matchingControl:UntypedFormArray = formGroup.controls[edateround].length;
        //return;
        if(matchingControl !=  control.value){
            control.setErrors({'compareRounds': 'Check the estimated rounds and estimated round dates'});
        } else {
            control.setErrors(null);
          }
        // if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        //   return;
        // }
    
        // if (control.value !== matchingControl.value) {
        //   matchingControl.setErrors({ mustMatch: true });
        // } else {
        //   matchingControl.setErrors(null);
        // }
      };
}