<div class="vault-modal">
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <app-alert></app-alert>
    <div mat-dialog-content class="form-group">
      <p  [innerHtml]="data.content"></p>
      
      <!-- INVOICE SPECIFIV -->
      <div *ngIf="data.type === 'view-invoice-log'">        
        <app-invoice-log [invoice]="data.invoice"></app-invoice-log>
      </div>

      <!-- DELETE USER -->
      <div *ngIf="(data.type === 'delete' && data.subType === 'user')">        
        <mat-checkbox class="w-100" color="primary" [(ngModel)]="data.permDelete" 
           >
          Permanently Delete User
        </mat-checkbox>
      </div>

      <!-- DELETE USER -->
      <div *ngIf="(data.type === 'deletepermanently')">   
        <mat-checkbox class="w-100" color="primary" [(ngModel)]="data.permDelete" >
          Permanently Expense
        </mat-checkbox>
      </div>

      <div *ngIf="(data.type === 'add-tag')">
        <form  [formGroup]="form">
          <div class="form-group">
            <mat-form-field class="w-100">
              <input matInput type="text" name="tag" formControlName="tag"
                required />
            </mat-form-field>
          </div>
        </form>
      </div>

      <div *ngIf="(data.type === 'password-reset')">
        <form  [formGroup]="form">
          <div class="form-group">
            <mat-form-field class="w-100">
              <input matInput placeholder="New password" type="password" name="password" formControlName="password"
                required />
                <mat-error *ngIf="f.password.invalid">
                  Password must be at least 8 characters long
                </mat-error>
            </mat-form-field>
          </div>
          <div class="form-group">
            <mat-form-field class="w-100">
              <input matInput placeholder="Confirm password" type="password" name="password_confirm"
                formControlName="password_confirm" required />                
                <mat-error *ngIf="f.password_confirm.invalid">
                  Passwords do not match
                </mat-error>
            </mat-form-field>
          </div>
        </form>
      </div>

      <div *ngIf="data.type == 'expense-paid'">
        <mat-form-field class="w-100">
          <input matInput [placeholder]="data.label" 
            type="text" 
            [(ngModel)]="data.payment_ref" 
            [ngModelOptions]="{standalone: true}" />
        </mat-form-field>                
      </div>

      <div *ngIf="data.type === 'proposalapproval'">
        <label for="" class="label mr-3">Approved Document</label>
        <app-file-upload 
          type="proposal_approval"
          class="might-be-trans inline"
          (uploadComplete)="attachFile($event,data.approve_doc)" 
          [_file]="data.approve_doc">
        </app-file-upload>
      </div>

      <div class="mb-5" *ngIf="(data.type === 'view-users')">
        
        <div class="row">
          <div class="col-sm-6 mb-2" *ngFor="let user of data.users; let i = index">
            <user-avatar                   
              [class]="'div lrg'"
              [showFullName]="true"    
              [user]="user" 
              [position]="true"
              [phone]="true"></user-avatar>
          </div>
        </div>      

      </div>


      <ng-container *ngIf="(data.type === 'invoice-overdue-notifications')">
        <div class="mb-3">
          <!-- <div [innerHTML]="data.notifications[0].preview"></div> -->
          <div class="row mb-3">
            <div class="col-sm-3 label">Due Date</div>
            <div class="col-sm-3 label">Days Past Due</div>
            <div class="col-sm-3 label">Last Overdue Notification</div>
            <div class="col-sm-3 label"></div>
          </div>
          <div class="row table-text" *ngFor="let notification of data.notifications">
            <div class="col-sm-3">{{ notification.due_date | date }}</div>
            <div class="col-sm-3">{{ notification.past_due }}</div>
            <div class="col-sm-3">{{ notification.overdue_notice_sent_at | date }}</div>
            <div class="col-sm-3"><button mat-button (click)="preview(notification)">Preview</button></div>
          </div>
        </div>
      </ng-container>

      <div class="mb-5" *ngIf="(data.type === 'webshots')">
        
        <mat-checkbox class="w-100" color="primary" [(ngModel)]="data.options.fullscreen" >
          Fullscreen
        </mat-checkbox>
        <mat-checkbox class="w-100" color="primary" [(ngModel)]="data.options.mobile" >
          Mobile
        </mat-checkbox>
        <mat-form-field class="w-100">
          <label class="label" for="">https://google.com</label>
          <textarea matInput placeholder="https://google.com" [(ngModel)]="data.pages" placeholder="Urls"></textarea>
        </mat-form-field>

      </div>


      <div class="mb-5" *ngIf="(data.type === 'active-users')">
        <div clss="label">{{ data.summary.connections }} Concurrent Connections.</div>
        <div class="mt-3">
          <div class="" *ngFor="let user of data.summary.users">
            <div class="d-flex align-items-center">
              <user-avatar [user]="user.id"></user-avatar> <span class="ml-3 subhead">{{ user.start | amTimeAgo}} <span>({{user.connections}})</span></span>
            </div>
          </div>
        </div>
      </div>

    <div *ngIf="(data.type === 'delete' && data.subject == 'project')">        
        <div *ngIf="($hours | async); let hours">
          <div *ngIf="hours?.hours?.length > 0">
            <p>This project has Timesheet hours added to it. </p>
            <div class="mb-3">
              <div *ngFor="let _person of hours.userMap | keyvalue" >
                <div class="ml-2 d-flex align-items-center">
                  <span class="label"><span class="mr-2">{{ _person.value }}</span> {{ getHours(_person.key, hours.hours) }} Hours</span>  
                </div>          
              </div>  
            </div>  
            <div>Select a project to assign these hours to</div>
            <div class="my-3">
              <button mat-raised-button color="primary" (click)="updateTimsheets = !updateTimsheets" class="mt-3"><span *ngIf="!updateTimsheets">Enable</span><span *ngIf="updateTimsheets">Disable</span> timesheet adjustment</button>
            </div>
            <ng-container *ngIf="updateTimsheets">
            <div class="row" *ngIf="($brandList | async); let brands">
              <div class="col-sm-3">
                  <mat-form-field class="w-100">
                      <mat-select  required placeholder="Brand" [(ngModel)]="tsbrandid">                      
                          <mat-option  *ngFor="let brand of brands | keyvalue" [value]="brand.value.id">
                              <app-brand-logo [brand]="brand.value"></app-brand-logo> &nbsp; {{brand.value.name}}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>          
              <div class="col-sm-5">
                  <div *ngIf="tsbrandid; let bid">
                      <div *ngIf="bid != -1">
                          <mat-form-field class="w-100" *ngIf="findBrandProjects(brands,bid); let projects">
                              <mat-select required placeholder="Project" [(ngModel)]="tsprojectid">
                                  <mat-option value="">- None -</mat-option>
                                  <mat-option  *ngFor="let project of projects | keyvalue" [value]="project.value.id">
                                      {{project.value.name}}
                                  </mat-option>
                              </mat-select>
                          </mat-form-field>                                                            
                      </div>
                  </div>
              </div>            
            </div>
            </ng-container>
          </div>

        </div>
      </div>

  </div>

    <mat-dialog-actions>
      <button mat-button (click)="close()">Close</button>
      <button *ngIf="data.type == 'save'" color="primary" mat-raised-button (click)="save()">Save</button>
      <button *ngIf="data.type == 'proposalapproval'" [disabled]="!canApprove" color="primary" mat-raised-button (click)="approvedDoc()">Approve</button>      
      <button *ngIf="data.type == 'add-tag'" color="primary" mat-raised-button (click)="add()">Add</button>
      <button *ngIf="data.type == 'approve'" color="accent" mat-raised-button (click)="approve()">Approve & Save</button>
      <button *ngIf="data.type == 'expense-paid'" color="accent" mat-raised-button (click)="paid()">
        <span *ngIf="!data?.button">Paid</span>
        <span *ngIf="data?.button">{{ data?.button }}</span>
      </button>
      <button *ngIf="data.type == 'delete'" color="danger" mat-raised-button (click)="delete()">Delete</button>
      <button *ngIf="data.type == 'deletepermanently' && data.permDelete == true" color="danger" mat-raised-button (click)="pdelete()">Delete</button>
      <button *ngIf="data.action == 'save'" color="primary" mat-raised-button (click)="saveWithData()">Save</button>
      <button *ngIf="data.type == 'password-reset'" color="primary" [disabled]="form.invalid || form.invalid" mat-raised-button (click)="change()">Change Password</button>
    </mat-dialog-actions>
