<ng-container *ngIf="!mediaLibrary; else mediaPrompt" >
  <label class="mat-button mat-button-base mat-primary">
    <span class="mat-button-wrapper">
      <mat-icon>note_add</mat-icon> Add Files
    </span>
    <div class="mat-button-focus-overlay"></div>
    <input type="file" name="file" hidden multiple (change)="upload($event)">
  </label>
  
</ng-container>

<ng-template #mediaPrompt>
  <button mat-button [color]="color" (click)="openLibrary()"><mat-icon>note_add</mat-icon> Add File</button>
</ng-template> 