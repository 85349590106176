import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, pipe } from 'rxjs';
import { first, tap,  } from 'rxjs/operators';
import { BrandModel, InvoiceModel, Pager, UserProfileModel } from 'src/app/_models/subjects';
import { BrandService } from 'src/app/_services/brand.service';
import { InvoiceService } from 'src/app/_services/invoice.service';
import { UserService } from 'src/app/_services/user.service';
import { VelvetysoftService } from 'src/app/_services/velvetysoft.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmComponent } from '../dialogs/confirm/confirm.component';
import moment from 'moment';



@Component({
  selector: 'app-manage-invoices',
  templateUrl: './manage-invoices.component.html',
  styleUrls: ['./manage-invoices.component.scss']
})
export class ManageInvoicesComponent implements OnInit {

  subject:any;
  $userProfile: Observable<UserProfileModel>;
  $invoices: Observable<Pager<InvoiceModel>>;
  displayedColumns: string[] = ['select', 'brand', 'author', 'invoicenum','printed', 'name','created','due','total','status', 'actions',];
  sortedData: InvoiceModel[];
  $summary: Observable<any>;
  invoiceSummaryDetails: Boolean = false;
  project_type: any;
  asyncFilters = {
    user: {role: 'employee'},
    brand: 'all',    
    status: {
      selected: 'sent,draft,paid',//complete
      data: null
    },
    filters: [],
    deleted: false,    
  };
  //subject: string = 'Project';
  filteredInvoices: string[] = [];  
  invoicespager;
  brands: BrandModel[];
  @ViewChild('vaultTable') vaultTable;

  constructor(
    public vs: VelvetysoftService,
    private invoiceService: InvoiceService,
    private userService: UserService,
    private brandService: BrandService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.subject = this.vs.managerNav.find( item => item.label == "Invoice" );
    this.asyncFilters.status.data = this.vs.getSettings('invoice').statuses; 
    this.asyncFilters.filters.push({
      label: 'Filter Printed',
      field: 'printed',
      data: [
        {label: 'Printed', value: 1},
        {label: 'Not Printed', value: 0}
      ]
    });
    //this.asyncFilters.status.data.push({value: 'printed', label: 'Printed'});
    this.invoicespager = this.invoiceService.pager();
    this.$invoices = this.invoicespager.$source;
    this.$userProfile = this.userService.userProfile();
    this.brandService.brands
      .pipe(first())
      .subscribe(brands => this.brands = brands)
    
  }

  delete(){

  }

 
 

  // previewNotifications(){

    
  //   this.invoiceService.previewNotifications().pipe(first()).subscribe(notifications => {

  //     const dialogRef = this.dialog.open(ConfirmComponent, {
  //       data: {        
  //         type: "invoice-overdue-notifications",
  //         title: `Preview Invoice notifications in the next 30 days`,
  //         notifications: notifications,
  //       },
  //     });

  //     // dialogRef.afterClosed().subscribe((result) => {
  //     //   if(result && result.status == 'delete') this.dialogRef.close({ status: 'delete' });
  //     // });

  //   });
  // }

  edit(invoice: InvoiceModel){
    this.invoiceService.edit(invoice,{view:'invoice',title: invoice.title,skipLoad: true})
    .pipe(first())
    .subscribe(() => {
      this.invoicespager.refresh();
    });
      
  }

  markPrinted(_invoice: InvoiceModel){
    console.log(_invoice);
    this.invoiceService.markPrinted(_invoice).pipe(first()).subscribe(() => {
      //this.invoicespager.refresh();
    });
  }

  findBrand(bid){
    return this.brands.find(brand => brand.id == bid);
  }

  add(){
    this.invoiceService.edit();
  }

}
