import { Pipe, PipeTransform } from '@angular/core';
import { UserModel, UserProfileModel, RoleModel, ProjectModel } from "./_models/subjects";
import moment from 'moment';
import { VelvetysoftService as vs } from './_services/velvetysoft.service';


@Pipe({
  name: "filterSingle",
  pure: false,
})
export class VhFilterByPipe implements PipeTransform {
  transform(items: any[], filterKey: string, filterVal: any): any {
    if (!items || !filterKey || !filterVal) {
      return items;
    }
    //console.log(items);
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return items.filter((item) => item[filterKey] == filterVal);
  }
}


// ITERATE on ES6 new Map() element
const cache = new WeakMap<ReadonlyMap<any, any>, Array<{ key: any; value: any }>>();
@Pipe({ name: 'mapkeyvalue', pure: true })
export class MapKeyValuePipe implements PipeTransform {
  transform<K, V>(input: ReadonlyMap<K, V>): Iterable<{ key: K; value: V }> {
    const existing = cache.get(input);
    if (existing !== undefined) {
      return existing;
    }

    const iterable = Array.from(input, ([key, value]) => ({ key, value }));
    cache.set(input, iterable);
    return iterable;
  }
}


/*USED TO FILTER OUR COLLECTIONS BY ONE VALUE*/
@Pipe({
    name: 'vhFilter',
    pure: false
})
export class VhFilterPipe implements PipeTransform {
    transform(items: any[], filter: Object): any {
      
        //return items;
        if (!items || !filter) {
            return items;
        }

        let key = Object.keys(filter)[0];
        return items.filter(item => item[key] == filter[key] );
    }
}

@Pipe({ name: 'capitalize' })
export class CapitalizePipe implements PipeTransform {

    transform(value: any) {
        if (value) {
            return value.charAt(0).toUpperCase() + value.slice(1);
        }
        return value;
    }

}

@Pipe({
    name: 'reverse',
    pure: false
})
export class ReversePipe implements PipeTransform {

    transform(value) {
        if (!value) return;

        return value.reverse();
    }
}

@Pipe({
    name: 'userCan',
    pure: true //ONLY ON CHANGES
})
export class filterUserCanByPipe implements PipeTransform {

    transform(userProfile: UserProfileModel, perms: String) {        
        if(!userProfile) return userProfile;
        if(!perms) return userProfile;
        //let perm = perms.split(",");
        //return userProfile.user.roles.find( r => perms.includes(r.name) );
        return userProfile.user.roles.find( role => 
            role.permissions.find( permissons => 
              perms == permissons.name 
        ));
       
        // return user.filter(user => {
        //     if (role == 'employee'){
        //         return _.find(user.roles, role => {
        //             return role.slug !== 'client'
        //         });
        //     }else{
        //         return _.find(user.roles, { slug: role });
        //     }            
        // });
    }
}


//FILTER LIST BY LIST REMOVE DUPS
@Pipe({
  name: 'labelsWork',  
})
export class labelsWork implements PipeTransform {
    workTypes: any;
    constructor(private vs: vs) {
      this.workTypes = this.vs.getSettings('jobs');
      let internalTypes = this.vs.getSettings('internaljobs');
      this.workTypes = {
        types: [...this.workTypes.types, ...internalTypes.types]
      }
    }
    
  transform(key: string) {  
    let res = '';
    res = this.workTypes.types.find( type => type.value == key)?.title 
    return res;
  }
}


//FILTER LIST BY LIST REMOVE DUPS
@Pipe({
  name: 'isNot',
  pure: true
})
export class isNOtPipe implements PipeTransform {
  transform(masterList: UserModel[], users: UserModel[]) {  
    if(!users) return masterList;
    return masterList.filter( user => !(users.some( u => u.id == user.id )) );
  }
}

// FILTER USER IN A SELECT BY THEIR ROLE
@Pipe({
    name: 'userIs',
    pure: false
})
export class filterUsersByPipe implements PipeTransform {

    transform(users: UserModel[], role: String) {  
        if(!users) return users;
        if(!role) return users;
        return users.filter(user => {
            if (role == 'employee'){
                return user.roles.find( r => r.name !== 'client');
            }else{
                return user.roles.find( r => r.name == role);
            }            
        });
    }
}

@Pipe({
    name: 'theUserIs',
    pure: false
})
export class filterTheUsersByPipe implements PipeTransform {

    transform(userProfile: UserProfileModel, role: String, not: boolean = false) {  
        if(!userProfile) return userProfile;
        if(!role) return userProfile;

        let roles = role.split(",");

        return (not)
          ? userProfile.user.roles.find( r => !roles.includes(r.name) )
          : userProfile.user.roles.find( r => roles.includes(r.name) );
    }
}


@Pipe({
  name: "active",
  pure: false,
})
export class filterActivePipe implements PipeTransform {
   transform(values: any) {
       return values.filter( i => i.active);
   }
}


// CHECK IS A EST DUE DATE IS THIS WEEK
@Pipe({
    name: "projectsThisWeek",
    pure: true,
  })
  export class filterProjectThisWeek implements PipeTransform {
     transform(projects: ProjectModel[]) {
        let ps = [];
        ps = [
            ...projects.filter(project => 
              project.est_round_dates.filter( date =>  moment(date).isSame(new Date(), 'week') ).length 
              || moment(project.due).isSame(new Date(), 'week')
            ),
            //...projects.filter(project => moment(project.due).isSame(new Date(), 'week'))
        ];
        return ps
     }
  }

  
  //GET PROJECT ESTIMATED CHECKING THIS WEEK
  @Pipe({
    name: "getProjectEst",
    pure: true,
  })
  export class filterProjectEstThisWeek implements PipeTransform {
     transform(project: ProjectModel) {
         return project.est_round_dates.find( date =>  moment(date).isSame(new Date(), 'week') );
     }
  }

  //GET PROJECT DUE DATE THIS WEEK
  @Pipe({
    name: "getProjectDue",
    pure: true,
  })
  export class filterProjectDueThisWeek implements PipeTransform {
     transform(project: ProjectModel) {
         return  moment(project.due).isSame(new Date(), 'week')
            ? project.due
            : null
     }
  }


@Pipe({
  name: 'Fn',
  //standalone: true,
  pure: true,
})
export class WrapFnPipe implements PipeTransform {
  transform<ARG, R>(func: (arg?: ARG) => R, args?: ARG): R;
  transform<ARG1, ARG2, R>(
    func: (arg1: ARG1, arg2: ARG2) => R,
    arg1: ARG1,
    arg2: ARG2
  ): R;
  transform<ARG1, ARG2, ARG3, R>(
    func: (arg1: ARG1, arg2: ARG2, arg3: ARG3) => R,
    arg1: ARG1,
    arg2: ARG2,
    arg3: ARG3
  ): R;
  transform<ARG1, ARG2, ARG3, R>(
    func: (arg1: ARG1, arg2: ARG2, arg3: ARG3, ...arg: any[]) => R,
    arg1: ARG1,
    arg2: ARG2,
    arg3: ARG3,
    ...arg: any[]
  ): R;

  transform<R>(func: (...arg: unknown[]) => R, ...args: unknown[]): R {
    return func(...args);
  }
}
  


//SECURE IMAGES

import { ApiService } from './_services/api.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Pipe({
    name: 'secure'
})
export class SecurePipe implements PipeTransform {

    constructor(private apiService: ApiService) { }

    transform(url): Observable<SafeUrl> {
        return this.apiService.secureImg(url);
    }

}


//URLS
@Pipe({
  name: 'urlify'
})
export class UrlifyPipe implements PipeTransform {

  transform(text: any): any {
    var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    return text && text.replace(urlRegex, function(url) {
      return '<a href="' + url + '" target="_blank">' + url + '</a>';
    });
  }
}