import { Component, OnInit, ViewChild } from '@angular/core';
import { TimesheetService,  } from '../../_services/timesheet.service';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { BrandModel, CompanySetting, Settings, TimesheetModel, UserModel } from "../../_models/subjects";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerInputEvent, MatCalendar } from '@angular/material/datepicker';
import { ProjectService } from '../../_services/project.service';

import * as moment from "moment";
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { first, flatMap, map, tap } from 'rxjs/operators';
import { VelvetysoftService } from 'src/app/_services/velvetysoft.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: "app-manage-timesheets",
  templateUrl: "./manage-timesheets.component.html",
  styleUrls: ["./manage-timesheets.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ]
})
export class ManageTimesheetsComponent implements OnInit {
  $brandList: Observable<BrandModel[]>;  
  timesheetHeaders: string[] = ["select", "user", "hours", "status", "action"];
  rates: Array<CompanySetting>;
  max: number = 14;
  avg: number = 8;
  days = [];
  currentWeek = null;
  workTypes: Settings;
  expandedElement: any;
  I_INVALID_RECORD_INDICATOR: any;
  $companySettings: Observable<CompanySetting[]>
  users: UserModel[];
  @ViewChild(MatCalendar) _cal: MatCalendar<any>;
  $timesheets: ReplaySubject<TimesheetModel[]> = new ReplaySubject(1);
  $tsSub: Subscription;//Observable<any>;
  //private $brandList: ReplaySubject<BrandModel[]> = new ReplaySubject(1);

  constructor(
    private timesheetService: TimesheetService,
    private projectService: ProjectService,
    private vs: VelvetysoftService,
    private userService: UserService
  ) {}

  ngOnInit() {
    //GET ALL THE DATA--move to project/brand service
    this.workTypes = this.vs.getSettings('jobs');
    let internalTypes = this.vs.getSettings('internaljobs');
    this.workTypes.types = [...this.workTypes.types, ...internalTypes.types];
    console.log(this.workTypes)
    //console.log(this.workTypes,this.vs.getSettings('internaljobs'));
    this.userService.loadUsersAssignedTo("all", "all").pipe(
      tap( users => { this.users = users }))
      .subscribe();
    this.$brandList = this.projectService.projectTree;
    this.currentWeek = moment();        
    this.updateTimesheets();
    this.$companySettings = this.userService.companySettings();
    this.$companySettings
      .pipe(first())
      .subscribe(_settings => {
        this.rates = _settings;
      });
  }

  findType(type){
    return this.workTypes.types.find( _type => _type.value == type ).title;
  }


  findUser(uid){
    return this.users.find(users => users.id == uid);
  }
  findBrand(brands,id,pid=null){
    if(id == -1) return null;//{name: "Internal"};
    return this.projectService.selectTree(brands, id, pid);    
  }

  findRate(name,hours){
    let price =  this.rates.find( rate => rate.name == name);
    return (!price) ? 0 : parseInt(price.value) * parseInt(hours);
  }

  getDay(week,day){
    const hrs = week[day.format("YYYY-MM-DD HH:mm:ss")];
    return {
      hrs: hrs,
      p: this.getPercent(hrs)
    };
  }

  getPercent(a){
    return (a/this.max)*100;
  }

  updateTimesheets() {    
    this.genWeek();
    var lastLine = '';
    this.$tsSub = this.timesheetService
      .loadAllTimesheets(this.genStartOfWeek)
      .subscribe(timesheets => {
        timesheets.sheets.map(usr => 
          usr.data.map((ts,idx) => {
            let thisLine = JSON.stringify(`${ts.type}-${ts.brand_id}-${ts.project_id}`); 
            ts.dates = ts.dates || [];
            ts.dates.push(ts.date);

            ts.hoursArr = ts.hoursArr || [];
            ts.hoursArr.push(ts.hours);

            if(thisLine == lastLine){
              ts.multi = true;
              if(usr.data[idx-1]){
                 usr.data[idx-1].skip = true;
                 ts.dates = [...ts.dates, ...usr.data[idx-1].dates];
                 ts.hoursArr = [...ts.hoursArr, ...usr.data[idx-1].hoursArr];
              }else{
                console.log('error',usr.data)
              }
            }
            //console.log(ts,idx) 
            lastLine = thisLine;
          })          
        );
        //console.log(timesheets); 
        this.$timesheets.next(timesheets);
      });
  }

  genWeek(){
    let startOfWeek = this.genStartOfWeek.clone();
    let endOfWeek = startOfWeek.clone().add(4, "d");
    this.days = [startOfWeek];
    let day = startOfWeek;
    do {      
      day = day.clone().add(1, "d");
      this.days.push(day);
    } while (day <= endOfWeek);    
  }

  compare(a,bs,line){
    
    let match = bs.filter(b => moment(a).diff(b, 'days') == 0 );
    return ((match.length > 0))
      ? line.hoursArr[bs.indexOf(match[0])]
      : false
    //return (moment(a).diff(b, 'days') == 0);
  }

  unlock(element) {
    let tids = element.map(ts => ts.id);
    this.timesheetService.unlock(tids).subscribe(res => {
      this.updateTimesheets();
    });
  }

  toggle(element){
    this.expandedElement = (this.expandedElement == element)
      ? null
      : element;
  }

  get genStartOfWeek() {
    return (this.currentWeek = this.currentWeek.clone().startOf("isoWeek"));
  }

  prevWeek() {
    this.currentWeek = this.currentWeek.clone().add(-1, "weeks");
    this.updateTimesheets();
  }

  nextWeek() {
    this.currentWeek = this.currentWeek.clone().add(1, "weeks");
    this.updateTimesheets();
  }

  selectWeek(type: string, event: MatDatepickerInputEvent<Date>) {
    this.currentWeek = event.value;
    this.updateTimesheets();
  }

  ngOnDestroy(){
    this.$tsSub.unsubscribe();
    this.$timesheets.unsubscribe();
  }

  
}
