<div *ngIf="($activity | async); let activity; else loading">
  <div *ngIf="users; else loading">
    <div class="col-sm-12 mb-3 subhead-bold">
      Recent Activiy
    </div>
    <div class="col-sm-12">
      <div>
        <div *ngFor="let action of activity" class="activity-item">
          <div class="fieldset activity">
            <div class="d-flex align-items-center title-bar">
              <mat-icon class="subject-icon">{{ action.icon }}</mat-icon>
              <app-date [date]="action.created_at"></app-date>            
            </div>
            <div class="cap small content-bar">
              <div><user-avatar class="shrink" showName="true" [user]="userById(action.causer_id)"></user-avatar></div>

              <div [ngSwitch]="action.label">

                <div *ngSwitchCase="'Projects'">
                  <span>{{ action.label }}</span> &nbsp;
                  <span *ngIf="action.properties.attributes">
                    <a routerLink="./project/{{ action.properties.attributes.id }}">{{ action.properties.attributes.name }}</a> &nbsp;
                  </span>
                  <span>{{ action.description }}</span>
                </div>
                
                <div *ngSwitchCase="'Rounds'">
                  <span>{{ action.label }}</span> &nbsp;
                  <span *ngIf="action.properties.attributes">
                    <a routerLink="./project/{{ action.properties.attributes.project_id }}">{{ action.properties.attributes.name }}</a>
                    &nbsp;
                  </span>
                  <span>{{ action.description }}</span>
                </div>
                
                <ng-template *ngSwitchDefault>
                  <div><span>{{ action.label }}</span> &nbsp; <span>{{ action.description }}</span></div>
                </ng-template>

              </div>              
            </div>
          </div>
          <!-- <user-avatar [user]="action.causer_id"></user-avatar> -->
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #loading>
  <app-loader></app-loader>
</ng-template>