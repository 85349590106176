import { Component, Input, KeyValueDiffers, OnInit } from '@angular/core';
import { ProjectModel, RoundModel } from 'src/app/_models/subjects';
import { DatePipe } from '@angular/common';
import moment, { Moment } from 'moment';

export interface ProjectProgressModel {
  project: ProjectModel;
  rounds: [RoundModel];
}

export interface Milestone{
  left:number;
  date:Date|Moment;
  css:string;
  label:string;
}




@Component({
  selector: "app-project-progress",
  templateUrl: "./project-progress.component.html",
  styleUrls: ["./project-progress.component.scss"],
  providers: [DatePipe]
})



export class ProjectProgressComponent implements OnInit {
  @Input() data: ProjectProgressModel;
  error:string = null;
  milestones:Array<Milestone> = [];
  progress: number = 0;
  currentRound: number = 0;
  estRoundDates:Array<Date> = [];
  estMarkers:Array<any> = [];
  active: boolean;
  pastDue: boolean = false;
  totalMarkers: number = 0;
  rangeEndDate:Date|Moment = null;
  differ: any;
  cssStatus:string = '';
  //currentLength = 0;
  constructor(private datePipe: DatePipe,private differs: KeyValueDiffers) {
    this.differ = differs.find({}).create();
  }

  ngDoCheck(){
    var changes = this.differ.diff(this.data);
    if(changes) {
      this.run();
    }    
  }
  // ngOnChanges(changes) {
  //   for (let propName in changes) {
  //     let chng = changes[propName];
  //     if (
  //       chng &&
  //       chng.currentValue &&
  //       chng.currentValue.rounds &&
  //       chng &&
  //       chng.previousValue &&
  //       chng.previousValue.rounds
  //     ){
  //       let cur = JSON.stringify(chng.currentValue.rounds.map((r) => r.due_date));
  //       let prev = JSON.stringify(chng.previousValue.rounds.map((r) => r.due_date));
        
  //       if(cur == prev) return;
  //     }
      
  //   }
  //   this.run();
  // }

  ngOnInit() {    
    this.run();
  }

  trackOn(index: number, item){
    return item
  }

  run() {
    this.milestones = [];
    this.progress = 0;
    this.active = true;
    this.cssStatus = this.data.project.status;

    if(!(this.data 
      && this.data.project 
      && this.data.project.est_round )){
      this.error = null;
      return this.error = "Unable to generate graph";
    }

    //RESTS
    this.estMarkers = [];
    this.error = null;

    //SETUP CURRENT ROUND
    this.currentRound = this.data.rounds.length;
    this.estRoundDates = this.data.project.est_round_dates;
    this.rangeEndDate = this.data.project.due;

    //EXCEPTION - CHECK IF A ROUND IS PAST DUE RESET RANGE!!!!!!!!!!!!!!!
    this.data.rounds.map( r => {
      if(moment(r.due_date) >  moment(this.data.project.due)){
        this.rangeEndDate = r.due_date; 
      }
    });

    //SETUP LINEAR SCALE
    let kicoff = (this.data.project.kickoff)
      ? this.data.project.kickoff
      : this.data.project.created_at;

    let start = moment(kicoff);
    let range = moment(this.rangeEndDate).diff(start, 'days', false); 
    let today = moment().diff(start, 'days', false); 
    
    this.milestones.push(this.milesStoneObj(
      this.getPercent(range,today)
      ,moment(),'today','')
    );

    this.milestones.push(this.milesStoneObj(
      0,start,'kickoff','')
    );

    //DUE DATE
    let text = (this.data.project.ongoing)
      ? ""
      : `Due ${this.datePipe.transform(this.data.project.due, 'shortDate')}`
    this.milestones.push(this.milesStoneObj(
      this.getPercent(range,moment(this.data.project.due).diff(start, 'days', false))
      ,this.data.project.due,'due',text)
    );

    //ESTIMATED ROUNDS
    let textEst = (this.data.project.ongoing) ? "" : 'R';
    this.generateMarkers(this.estRoundDates,start,range,'est', textEst)
      .map( marker => this.milestones.push(marker) );
    
    //ACTIVE ROUNDS
    this.generateMarkers(
      this.data.rounds.map( round => round.due_date ),
      start,range,'active',textEst)
      .map( (marker,idx) => {
        //REMOVE EQUAL EST ROUND
        let existsIdx = this.milestones.findIndex( m => m.label == marker.label );
        //ADD ROUND        
        (existsIdx > -1 ) 
        ? this.milestones.splice(existsIdx, 1, marker)
        : this.milestones.push(marker) ;        
        //Do Progress
        //console.log(this.data.rounds.length-1 == idx)
        if(this.data.rounds.length-1 == idx) this.progress = marker.left;
      });

      let last = this.milestones[this.milestones.length-1];
      last.css += " last";

      //COMPLETION EXCEPTIONS
      if(this.data.project.status == 'complete') last.label += ": Complete";
      //OVERDUE EXCEPTIONS
      if(this.data.project.due != this.rangeEndDate) this.cssStatus += ' overdue';
  }

  generateMarkers(dates:Array<Date>,start:Date|Moment,total:number,css:string,label:string):Array<Milestone>{
    return dates.map( (p,idx) => {
      let days = moment(p).diff(start, 'days', false);
      let left = this.getPercent(total, days)
      let _label = `${label}${idx+1}`;
      return this.milesStoneObj(left,p,css,_label);  
    })
  }

  milesStoneObj(left:number,date:Date|Moment,css:string,label:string):Milestone{
    return {
      left: left,
      date: date,
      css: css,
      label: label
    }
  }

  getPercent(total:number,marker:number):number{
    let p = Math.floor(marker/total*100);
    return (p>99) ? 99 : ( p<1) ? 1 : p ;
  }
}



