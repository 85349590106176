import { Injectable } from '@angular/core';
import { SubjectBaseService } from './subject-base.service';
import { DialogOptions, RoundModel, SubjectAction } from '../_models/subjects';
import { BrandService } from './brand.service';
import { ApiService } from './api.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AddRoundComponent } from '../_component/project/add-round/add-round.component';
import { first, tap, flatMap } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { ProjectService } from './project.service';
import moment from 'moment';
import { SendEmailComponent } from '../_component/dialogs/send-email/send-email.component';

@Injectable({
  providedIn: 'root'
})
export class RoundService extends SubjectBaseService{
  round: RoundModel = {
    id: null,
    name: "",
    project_id: null,
    description: "",
    status: "active",
    due_date: moment().toDate(),
    assets:[],
    next_steps: null,
    meeting_notes: null
  };
  constructor(
    public brandService: BrandService,
    public apiService: ApiService,
    public dialog: MatDialog,   
    public projectService: ProjectService,    
  ) { 
    super("round", apiService, dialog, brandService);
    this.setModel<RoundModel>(this.round);
    this.setDependecy('project');
  }

  //ROUNDS DEPEND ON PROJECT
  // resolveDependency<T>(cb): Observable<any> {
  //   //IF BRAND ISN"T RESOLVE, RESOLVE IT
  //   return this.projectService.$activeProject.pipe(
  //     flatMap((project) => {
  //       this.cacheCheck(project);
  //       return cb;
  //     })
  //   );
  // }
  
  get rounds(): Observable<RoundModel[]> {
    return this._actives;
  }

  edit(pid, round: RoundModel, options?:DialogOptions):Observable<RoundModel>{
    let resultRef = new Subject<RoundModel>();
    const result = this.editDialog<RoundModel>(
      round,
      AddRoundComponent,
      options
    );

    result.pipe(first()).subscribe((res: SubjectAction<RoundModel>) => {
      res.subject.project_id = pid;
      const round = res.subject;      
      this.prepDate(round);
      (res.action == 'update') && this.add<RoundModel>(round)
        .pipe(first())
        .subscribe((newRound:RoundModel) => resultRef.next(newRound) )
      //(res.action == 'delete') 
    });

    return resultRef;
  }

  addNotes<RoundModel>(round){
    return this.api
        .put<RoundModel>(`${this.subject}-notes`, round.id, round, true)
        .pipe(tap(() => this.loadSubject()))
  }
  

  sendEmail(round: RoundModel) {
    const result = this.sendDialog(round, SendEmailComponent);
    result
      .pipe(first())
      .subscribe();
  }
}
