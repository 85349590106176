<div class="container" *ngIf="( $purchases | async ); let purchases">
  
    <div class="block">
      <div class="block-title">
        <div class="block-title-label">Manage purchases</div>
          <div class="block-title-toolbar view-tools">
          <!-- <button mat-button (click)="add()" color="primary">
            <mat-icon>add</mat-icon>New Vendor
          </button> -->
          <!-- <button [ngClass]="{ 'active': view == 'table'}" mat-icon-button (click)="changeView('table')">
            <mat-icon class="">view_list</mat-icon>
          </button>
          <button [ngClass]="{ 'active': view == 'tile'}" mat-icon-button (click)="changeView('tile')">
            <mat-icon class="">view_module</mat-icon>
          </button>         -->
        </div> 
      </div>  
    </div>
  
    <div class="block" >
     
      <div class="block-content">
        <!--          
                       -->
        <div *ngIf="purchases && purchases.data">
  
  
        <app-table
          mat-table 
          #vaultTable
          matSort #sort="matSort" 
          [asyncFilters]="asyncFilters"
          [dataSource]="$purchases"   
          [pager]="purchasesPager"        
          [columns]="displayedColumns"
          >
   
          <ng-container matColumnDef="brand">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Brand </th>
            <td mat-cell class="cap cursor" *matCellDef="let element" >
                <app-brand-logo *ngIf="element?.expenses[0]?.brand_id" [tooltip]="true" [bid]="element.expenses[0].brand_id"></app-brand-logo>
            </td>
          </ng-container>
        
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
            <td mat-cell class="cap cursor" *matCellDef="let element" (click)="add(element)">
              {{ element.title }}
            </td>
          </ng-container>

          <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef> Date </th>
            <td mat-cell *matCellDef="let element" (click)="add(element)" class="text-capitalize" > 

              <ng-container *ngIf="element?.total; else createdAt">
                {{element.expenses[0].date | date}}
              </ng-container>

              <ng-template #createdAt>
                {{element.created_at | date}}
              </ng-template>

            </td>
          </ng-container>

          <ng-container matColumnDef="author">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Author </th>
            <td mat-cell class="cursor" *matCellDef="let element"> <user-avatar showFullName="true" [user]="element.user_id" ></user-avatar> </td>
          </ng-container>

          <ng-container matColumnDef="total">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Total </th>
            <td mat-cell *matCellDef="let element"> 
                {{ element.total | currency }}
              
            </td>
          </ng-container>


          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
            <td mat-cell *matCellDef="let element">              
              <meta-display *ngIf="element.expenses[0].type" subject="expense" [item]="element" type="types" [id]="element.expenses[0].type"></meta-display>
            </td>
          </ng-container>       

          <ng-container matColumnDef="receipt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Receipt </th>
            <td mat-cell *matCellDef="let element">
              <button mat-button (click)="view(element)" *ngIf="element.expenses[0].receipt; else noRe">Download</button>
              <ng-template #noRe>( No Receipt )</ng-template>
            </td>
          </ng-container>       

          <ng-container matColumnDef="download">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Actions </th>
            <td mat-cell *matCellDef="let element">
              <button mat-button (click)="download(element)">Download</button>              
            </td>
          </ng-container>       

                    
          
 
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></tr>
        
      </app-table>
      
    </div>
  </div>
  

  
  <ng-template #noUsers>
    <mat-spinner color="primary" class="mx-auto loader"></mat-spinner>
  </ng-template>