import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService} from '../../_services/user.service';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { BrandService } from '../../_services/brand.service';
import { ProjectService } from '../../_services/project.service';
import { InvoiceService } from '../../_services/invoice.service';
import {
  InvoiceModel,
  ProjectModel,
  BrandModel,
  UserProfileModel,
  ProposalModel,
  ProjectionModel,
  UserModel,
} from "../../_models/subjects";
import { environment } from '../../../environments/environment';
import { VelvetysoftService } from '../../_services/velvetysoft.service';
import { ManageUsersDialogComponent } from '../../_directive/manage-users/manage-users.component';
import { Router } from '@angular/router';
import { map, take, mergeMap, filter, tap, flatMap, first } from 'rxjs/operators';
import * as moment from "moment";
import { ProposalService } from 'src/app/_services/proposal.service';
import { GoogleApiService } from 'src/app/_services/google-api.service';


@Component({
  selector: "app-overview",
  templateUrl: "./overview.component.html",
  styleUrls: ["./overview.component.scss"],
})
export class OverviewComponent implements OnInit, OnDestroy {
  $userProfile: Observable<UserProfileModel>;
  $activeBrand: Observable<BrandModel>;
  $projects: Observable<ProjectModel[]>;
  $proposals: Observable<ProposalModel[]>;
  $invoicesSent: Observable<InvoiceModel[]>;
  $proposalsSent: Observable<ProposalModel[]>;
  //$projections: Observable<ProjectionModel>;
  $staff: Observable<any>;
  projectStatuses: any;
  $brand: BrandModel;
  cdn = environment.cdn;
  projectStats: any;
  invoiceStats: any;
  projectDue: any;
  paddingBetween = 5;
  view: any[] = [500, 400];
  showXAxis: boolean = true;
  drives;
  showYAxis: boolean = true;
  month = null;
  colorScheme = {
    domain: ["#fd397a", "#ffb822", "#1dc9b7", "#673ab7", "#6610f2", "#fd397a"],
  };
  $openProposals: Observable<any>;
  projectsSub: Subscription;
  $projections = new ReplaySubject(1);

  // TEST

  // TEST

  constructor(
    private userService: UserService,
    private brandService: BrandService,
    private projectService: ProjectService,
    private proposalService: ProposalService,
    private invoiceService: InvoiceService,
    public dialog: MatDialog,
    private router: Router,
    public vs: VelvetysoftService,
    private gapi: GoogleApiService
  ) {}

  count(collection, status) {
    //convert the line below to es6
    var _projects = collection.filter((p) => p.status == status.value);
    return _projects ? _projects.length : 0;
  }

  ngOnInit() {
    this.month = moment();//.format("MMMM");
    const weekStart = moment().startOf("week");
    this.gapi.listDrives().subscribe( (drives) =>{
      this.drives = drives;
    });
    this.$invoicesSent = this.invoiceService.invoices.pipe(
      map((invoice) =>
        invoice.filter(
          (invoice) =>
            invoice.status == "sent" 
            //&& !this.invoiceService.isOverDue(invoice.due_date, "sent")
        )
      )
    );
    this.$proposalsSent = this.proposalService.proposals
      .pipe(
        map((proposal) => proposal.filter( proposal => proposal.status == "sent" )
    ));
    

    //});

    this.$userProfile = this.userService.userProfile();
    this.$staff = this.userService.staff();
    this.$activeBrand = this.brandService.activeBrand;
    this.$activeBrand.subscribe( brand =>{
      this.$brand = brand;
      this.vs.title([brand.name, 'Overiew']);
      this.$openProposals = this.brandService.openProposals();
    });    
    //this.$invoicesDue = this.invoiceService.invoices();
    // this.$invoicesDue = this.$activeBrand.pipe(
    //   mergeMap(brand => this.invoiceService.invoices(brand.id) ),
    //   map(invoice => invoice.filter(invoice => invoice.status == 'sent' && this.invoiceService.isOverDue(invoice.due_date, 'sent')) )
    // );
    this.$projects = this.projectService.projects;
    //this.$proposals = this.proposalService.proposals;
    this.projectStatuses = this.vs.settings.project.statuses;
    this.projectsSub = this.$projects.subscribe((projects) => {
      //this.projectStats = this.vs.graphData(projects);
      //console.log(projects);
      let types = this.vs.settings.project.types.map((i) => {
        return { name: i.value, value: 0 };
      });
      //this.projectStats = [...this.projectStats, ...types]
      //console.log(this.projectStats);
      this.projectDue = projects
        .sort((a, b) => +b.due - +a.due)
        .filter(
          (p) => moment().diff(p.due, "days") > -7 && p.status == "active"
        );
    });
  }

  projections(bid,self){
    let _self = self || this; 
    _self.brandService.projections(bid)
        .pipe(first())
        .subscribe( projections => _self.$projections.next(projections) );
    return true;
  }

  isPastDue(due) {
    return moment().diff(due, "days") >= 0 ? true : false;
  }

  edit(brand){    
    this.brandService.editDialog(brand,this.drives);    
  }

  navTo(type, id) {
    this.router.navigate([`/dashboard/${type}/${id}`]);
  }

  openInvoice(item: InvoiceModel) {
    const res = this.invoiceService.edit(item,{view: 'invoice', title: item.title});
  }
  openProposal(item: ProposalModel) {
    const res = this.proposalService.edit(item,{view: 'proposal', title: item.title});
  }

  

  manageUsers(item: BrandModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { 
      context: "brand", 
      item: item };
    const dialogRef = this.dialog.open(
      ManageUsersDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result.status === true) {
        this.brandService.updateUsers(result.item);
      }
    });
  }

  year(){
    return new Date().getFullYear();
  }

  nextMonth(){
    this.month = moment(this.month).add(1,'month').toDate();
    this.brandService.projections(this.brandService.activeBrandID,this.month)
      .pipe(first())
      .subscribe( projections => this.$projections.next(projections) );
  }

  prevMonth(){
    this.month = moment(this.month).subtract(1,'month').toDate();
    this.brandService.projections(this.brandService.activeBrandID,this.month)
      .pipe(first())
      .subscribe( projections => this.$projections.next(projections) );
      
  }

  progress(paid,owe){
      let percent = Math.floor(paid/owe*100);
      return (percent > 100)
        ? 100 
        : ( percent < 0 ) 
          ? 0
          : percent ;
  }



  forcast(brand?:BrandModel){
    if(!brand) return false;
    return this.vs.getYearProjection(brand);
  }

  hasEmployee(brand:BrandModel,employee){
    return brand.users.find( user => user.id == employee.user.id)
  }

  //THIS WILL CHANGE


  ngOnDestroy(){
    this.projectsSub.unsubscribe();
  }

 
}
