
<ng-container *ngIf="($userProfile | async); let profile">
  <ng-container *ngIf="$brand; let brand">
    <div class="container" >
      <div class="block">
        <div class="block-content" >          
          <div class="row align-items-center" >
            <div class="col-md-12 col-lg-5 col-xl-4">
              <div class="d-flex align-items-center">
                <app-brand-logo class="pr-3" [brand]="brand" format="small"></app-brand-logo> 
                <span><h3 class="subhead-large mb-0">{{ brand.name}}</h3></span>
              </div>
            </div>
            <div class="col-md-12 col-lg-5 col-xl-4" *ngIf="profile | theUserIs: 'client' : true">
              <div class="d-flex align-items-center ">
                <div class="w-100">
                  <div class="d-flex align-items-center ">
                    <h3 class="subhead-bold">Brand Team</h3>
                    <button 
                      *ngIf="profile | userCan: 'manage brand'"
                      class="mat-sm" 
                      mat-icon-button 
                      color="primary"
                      matTooltip="Manage Team"
                      matTooltipPosition="right"
                      (click)="manageUsers(brand)">
                        <mat-icon>group</mat-icon>
                    </button>
                  </div>              
                  <div class="m-0 mb-3 row align-items-center">
                    <div class="pr-3 d-flex align-items-center">
                      <span class="subhead">Clients</span> &nbsp;
                      <app-users class="inline" [users]="brand.users" role="client"></app-users>
                    </div>
                    <div class="d-flex align-items-center">
                      <span class="subhead">Employees</span> &nbsp;
                      <app-users class="inline" [users]="brand.users" role="employee"></app-users>
                    </div>
                  </div>              
                </div>
              </div>
            </div>                
          </div>           
        </div>
      </div>


      <div class="row" *ngIf="profile | userCan: 'view brand projections'">
        <ng-container *ngIf="projections | Fn : brand.id : this">
        <div class="col-sm-12" *ngIf="($projections | async) let projections">
          <div class="block block-cool">
            <div class="block-title">
              <div class="block-title-label">
                <mat-icon class="mr-1">account_balance</mat-icon> Projections
              </div>
            </div>
            <div class="block-content" >
              <div class="row">
                <div class="col-md-12 col-lg-5 col-xl-4">
                  <div class="d-flex align-items-center mb-3">
                    <button mat-stroked-button (click)="prevMonth()">
                      <mat-icon>chevron_left</mat-icon>
                    </button>
                    <h3 class="subhead-bold px-3">{{ month | date: "MMMM" }}</h3>  
                    <button mat-stroked-button (click)="nextMonth()">
                      <mat-icon>chevron_right</mat-icon>
                    </button>
                  </div>
                  <div class="row" >                    
                    <div class="col-sm-5" *ngIf="projections.totalcost">
                      <span class="subhead">Fees: </span> <span class="table-text">{{ projections.totalcost | currency }}</span>    
                    </div>
                    <div class="col-sm-5" *ngIf="projections.totalhours">
                      <span class="subhead">Hours: </span> <span class="table-text">{{ projections.totalhours }}</span>    
                    </div>
                  </div>
                                
                </div>
                <div  class="col-md-12 col-lg-5 col-xl-4">
                  <h3 class="subhead-bold">{{ year | Fn }} Status</h3>  
                  <div class="row" >                    
                    <div class="col-sm-4" *ngIf="forcast(brand);let yrlForcast">
                      <span class="subhead">Forecast:</span> <div class="table-text" >{{ yrlForcast | currency }}</div>
                    </div>
                    <div class="col-sm-4" *ngIf="forcast(brand);let yrlForcast">
                      <span class="subhead">Billed:</span> <div class="table-text" *ngIf="projections.billed">{{ projections.billed | currency }}</div>
                    </div>
                    <div class="col-sm-4" *ngIf="forcast(brand);let yrlForcast">
                      <span class="subhead">Revenue:</span> <div class="table-text" *ngIf="projections.revenue">{{ projections.revenue | currency }}</div>
                    </div>   
                    <div class="col-sm-4">
                      <div *ngIf="!forcast(brand)">
                        <button mat-button color="primary" (click)="edit(brand)">Set {{ brand.title}} forecast</button>
                      </div>    
                    </div>         
                  </div>                      
                </div>
              </div>
            </div>
          </div>
        </div>
        </ng-container>
      </div>

      <!-- Client only -->
      <div class="row" *ngIf="profile | theUserIs: 'client'">
        <div class="col-sm-12" >
          <div class="block block-cool">
            <div class="block-title">
              <div class="block-title-label">
                <mat-icon class="mr-2">group</mat-icon>Brand Team
              </div>
            </div>
            <div class="block-content" >
              <div class="row">
                  <div class="col-sm-6">
                    <!-- <span class="subhead">Clients</span> &nbsp; -->
                    <div class="row">
                      <div class="col-sm-6 mb-2" *ngFor="let user of brand.users | userIs: 'client'; let i = index">
                        <user-avatar                   
                          [class]="'div lrg'"
                          [showFullName]="true"    
                          [user]="user" 
                          [position]="true"
                          [phone]="true"></user-avatar>
                      </div>
                    </div>      
                  </div>
                  <div class="col-sm-6">
                    <!-- <span class="subhead">Employees</span> &nbsp; -->
                    <div class="row">
                      <div class="col-sm-6 mb-2" *ngFor="let user of brand.users | userIs: 'employee'; let i = index">
                        <user-avatar                   
                          [class]="'div lrg'"
                          [showFullName]="true"    
                          [user]="user" 
                          [position]="true"
                          [phone]="true"></user-avatar>
                      </div>
                    </div>               
                  </div>      
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">

        <div class="col-sm-12" *ngIf="($projects | async); let projects">
          <div class="block block-cool">
            <div class="block-title" *ngIf="vs.subject('project'); let subject">
              <div class="block-title-label"><mat-icon *ngIf="subject.icon" class="mr-2">{{ subject.icon }}</mat-icon>{{ subject.label }}</div>
            </div>
            <div class="block-content" >
              <div class="row">
                <div class="col-sm-12">
                  <div class="mb-3">
                    <ul class="list-group list-group-horizontal list-group-reset mx-auto d-inline-flex">
                      <li class="list-group-item" *ngFor="let status of projectStatuses">
                        <span class="dot-label" [ngClass]="status.value">{{ count | Fn: projects : status  }}</span>&nbsp;<span
                          class="subhead">{{ status.title }}</span>
                      </li>
                    </ul>
                  </div>  
                </div>
                <div class="col-sm-5">                                    
                  <div *ngIf="projects && projects.length; else noneProjects">
                  <!--<div *ngIf="projectStats?.length; else noneProjects">-->                
                    <div class="pb-3">
                      <div class="chart-wrapper p-3">
                        <app-charts [data]="projects" ></app-charts>
                      </div>                 
                    </div>
                    
                  </div>
                </div>
                <div class="col-sm-7">              
                  <div class="subhead-bold">This Week</div>
                  <!-- STAFF -->              
                  
                  <div *ngIf="($staff | async)?.ts let staff">
                    <div *ngFor="let employee of staff" class="mb-1">
                      <div class="row table-text cell cursor" *ngIf="hasEmployee(brand,employee)">
                        <div class="col-sm-12 d-flex align-items-center" >
                          <user-avatar [user]="employee.user" class="mr-1" [showName]="true"></user-avatar>
                          OOO : <span class="dot-label hold mx-1" *ngFor="let date of employee.dates">{{ date | amDateFormat: 'ddd' }}</span>
                        </div>
                      </div>
                    </div>              
                  </div>
                  <div 
                    class="row table-text cell cursor" 
                    *ngFor="let project of projects | projectsThisWeek"
                    [routerLink]="['/dashboard/project/'+project.id]">
                    <div  class="col-sm-4 " >
                        {{ project.name }} 
                    </div>
                    <div class="col-sm-7">
                      <div *ngIf="(project | getProjectEst); let checkin">
                        <span class="subhead pr-2">Checkin</span> 
                        <span >{{ checkin | amTimeAgo }} ({{ checkin | date: 'MMM d' }})</span>
                      </div>
                      <div *ngIf="(project | getProjectDue); let due">
                        <span class="subhead pr-2">Due</span> 
                        <span >{{ due | amTimeAgo }} ({{ due | date: 'MMM d' }})</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <ng-template #noneProjects>
                No Projects created yet
              </ng-template>

            </div>
          </div>
        </div>

      </div>

      <div>
        <div class="row" *ngIf="profile | userCan: 'view invoice & proposal'">

          <ng-container *ngIf="($invoicesSent | async) let invoices">
            <div class="col-sm-6" *ngIf="invoices.length;">
              <div >
                <div class="block block-cool" >
                  <div class="block-title" *ngIf="vs.subject('invoice'); let subject">
                    <div class="block-title-label"><mat-icon *ngIf="subject.icon" class="mr-2">{{ subject.icon }}</mat-icon> {{ subject.label }} Pending</div>
                  </div>
                  <div class="block-content" >
                    <div >            
                      <div class="" >
                        <div class="">
                          <div *ngFor="let invoice of invoices"
                            class="row pb-2 justify-content-between line align-items-start align-items-center">
                            <div class="col-sm-4">
                              <span (click)="openInvoice(invoice)" class="click label-sm">{{ invoice.title }}</span>
                            </div>
                            <div class="col-sm-4">
                              <meta-display subject="invoice" [item]="invoice" type="statuses" [id]="invoice.status"></meta-display>
                            </div>
                            <div class="col-sm-4">
                              <span class="label-sm">Due: {{ invoice.due_date | date }} </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ng-template #noneInvoices>
                      
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="($proposalsSent | async) let proposals">
            <div class="col-sm-6" *ngIf="proposals.length;">
              <div >
                <div class="block block-cool" >
                  <div class="block-title" *ngIf="vs.subject('proposal'); let subject">
                    <div class="block-title-label">
                      <mat-icon *ngIf="subject.icon" class="mr-2">{{ subject.icon }}</mat-icon> {{ subject.label }} Pending
                    </div>
                  </div>
                  <div class="block-content" >
                    <div >            
                      <div class="" >
                        <div class="">
                          <div *ngFor="let proposal of proposals"
                            class="row pb-2 justify-content-between line align-items-start align-items-center">
                            <div class="col-sm-8">
                              <span (click)="openProposal(proposal)" class="click label-sm">{{ proposal.title }}</span>
                            </div>
                            <div class="col-sm-4">
                              <meta-display subject="proposal" [item]="proposal" type="statuses" [id]="proposal.status"></meta-display>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    <ng-template #noneInvoices>
                      No Invoices created yet
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="profile | userCan: 'manage invoice'">          
            <ng-container *ngIf="($openProposals | async) let openproposals">
              <div class="col-sm-6" *ngIf="openproposals.length;">
                <div >
                  <div class="block block-cool" >
                    <div class="block-title" *ngIf="vs.subject('proposal'); let subject">
                      <div class="block-title-label">
                        <mat-icon *ngIf="subject.icon" class="mr-2">{{ subject.icon }}</mat-icon> Proposals Uninvoiced
                      </div>
                    </div>
                    <div class="block-content" >
                      <div >            
                        <div class="" >
                          <div class="">
                            <div *ngFor="let proposal of openproposals"
                              class="row pb-2 justify-content-between line align-items-start align-items-center">
                              <div class="col-sm-6">
                                <span (click)="openProposal(proposal)" class="click label-sm">{{ proposal.title }}</span>
                              </div>
                              <div class="col-sm-6">        
                                <div class="label">{{ (proposal.invoiced || 0) | currency }} of {{ proposal.total | currency }} </div>
                                <mat-progress-bar 
                                    mode="determinate" 
                                    class="vh-progress"
                                    value="{{ progress | Fn: proposal.invoiced : proposal.total }}"></mat-progress-bar>
                              </div>
                              
                            </div>
                          </div>
                        </div>
                      </div>
                      <ng-template #noneInvoices>
                        No Invoices created yet
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>

        </div>  
      </div>
    </div>
  </ng-container>
</ng-container>