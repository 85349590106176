import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ApiOptions, InvoiceModel, ProposalModel, SubjectAction } from '../_models/subjects';
import { BrandService } from './brand.service';
import * as moment from "moment";
import { AddProposalComponent } from '../_component/proposals/add-proposal/add-proposal.component';
import { ProjectService } from './project.service';
import { ProjectModel } from "../_models/subjects";
import { first, map, mergeAll, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { SubjectBaseService } from './subject-base.service'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { forkJoin, from, merge, Observable, Subject } from 'rxjs';
import { SendEmailComponent } from '../_component/dialogs/send-email/send-email.component';

@Injectable({
  providedIn: "root",
})
export class ProposalService extends SubjectBaseService {
  _proposal: ProposalModel = {
    id: null,
    title: null,
    proposal_number: null,
    type: null,
    due_date: null,
    total: null,
    deliverables: null,
    content: null,
    project_id: null,
    description: null,
    penalty: false,
    brand_id: null,
    user_id: null,
    approved_doc: null,
    status: "active",   
    sent_at: null, 
    approved_at: null
  };
  constructor(
    private apiService: ApiService,
    public dialog: MatDialog,
    private projectService: ProjectService,
    public brandService: BrandService,
    private snack: MatSnackBar
  ) {
    super("proposal", apiService, dialog, brandService);
    this.setModel<ProposalModel>(this._proposal);
  }

  /* ALL PROPOSALS */
  get all(): Observable<ProposalModel[]> {
    return this.subjectsAll;
  }

  get proposals(): Observable<ProposalModel[]> {
    return this._actives;
  }

  sendEmail(proposal:ProposalModel){
    const result = this.sendDialog(proposal, SendEmailComponent);
    result
      .pipe(
        first(),
        tap(()=> this.loadSubject())
      ).subscribe();
  }

  approved(proposal: ProposalModel){
    const data = {
      approved_doc: proposal.approved_doc,
      id: proposal.id
    };
    return this.api
      .post(`${this.subject}-approved`,data)
      .pipe(tap(() => this.loadSubject()));
  }

  downloadApproved(name: string){
    name = name.replace("proposal-approvals/original/","");
    this.apiService.post(`${this.subject}-approval-download?name=${name}`,{name: name})
      .pipe(first())
      .subscribe( url => window.open(url,'_blank'))
  }

  convertToInvoice(proposal: ProposalModel){
    let invoice = {  ...proposal};
    delete invoice.id;
    delete invoice.user;
    delete invoice.sent_at;
    delete invoice.status;    
    delete invoice.proposal_number;
    invoice.penalty = false;
    invoice.due_date = moment().add(30,'days').toDate();
    return invoice;
  }
  
  deletePhase(id: Number) {
    return this.api.put<number>(`proposalphase-delete`,id,{})      
  }

  //APPROVAL BY NON AUTHENTICATED USER
  approvedByLink(proposal: ProposalModel, key: string, approved_doc: string, name: string){
    const data = {
      approved_doc: approved_doc,
      key: key,
      id: proposal.id,
      name: name
    };
    return this.api
      .post(`${this.subject}-approved-link`,data)          
  }

  edit(proposal?: ProposalModel,options?, pid=null) {
    let resultRef = new Subject<ProposalModel>();
    //FIX DATE
    //if (proposal) this.fixDate(proposal);      

    if(pid) options = {...options, pid: pid}

    const result = this.editDialog<ProposalModel>(
      proposal,
      AddProposalComponent,
      options
    );

    result.pipe(first()).subscribe((res: SubjectAction<ProposalModel>) => {
      //CREATE PROJECT
      const proposal = res.subject;
      console.log(res)
      if(res.action == 'update'){
        if (proposal._createProject === true) {
          this.createProjects(proposal);       
          //resultRef.next(newBrief) ); 
        }else{
          //CREATE PROPOSAL - RENAME THIS
          this.createProposalTransition(proposal)
            .pipe(first())
            .subscribe(res => {
              console.log(res)
              resultRef.next(proposal);
            });
          //resultRef.next(newBrief) );
        }
      }else{
        resultRef.next(proposal);
      }

      
    });

    return resultRef;
  }

  createProjects(proposal: ProposalModel){

    //const createResults = this.proposalToProject(proposal).map(project => this.projectService.add(project,{skipToast: true}))
    
    forkJoin(
      this.proposalToProject(proposal)
      .map(project => this.projectService.add(project,{skipToast: true})))
      .pipe(switchMap((items:any) => {
        console.log(items)
        const pIDs = items.map(i => i.project.id);
        //proposal.project_id = pIDs; 
        console.log(pIDs)
        if(pIDs.length < 2) proposal.project_id = pIDs; 
        proposal.phases.map((phase,idx) =>{
          phase.brand_id = items[0].project.brand_id;
          phase.project_id = pIDs[idx];
        });
        return this.createProposalTransition(proposal,{skipToast:true})
          .pipe(map(proposalRes => {
            return {projects: pIDs.length, proposalRes: proposalRes};
          }));
      }))
      .pipe(first())
      .subscribe(({projects, proposalRes}) => {
        this.snack.open(`Created ${projects} Projects and added to Proposal`, "Dismiss", {
          duration: 2000,
        });      
      })
  }

  //HELPERS
  proposalToProject(proposal):ProjectModel[] {
    var proposalShell: any = JSON.parse(JSON.stringify(proposal));
    var projectsShell = [];
    
    let _brandUsers;
    this.brandService.activeBrand.pipe(first()).subscribe( b => _brandUsers = b.users );

    proposalShell.phases.forEach( phase =>{      
      let _project:any = {};
      _project.due = proposal.due_date,
      _project.status = 'active';//(proposal.status == "draft") ? "hold" : "active";
      _project.name = (phase.title) ? phase.title : proposal.title;
      _project.type = (phase.type) ? phase.type : proposal.type;
      _project.brand_id = this.brandService.activeBrandID;
      _project.users = _brandUsers;
      let project = this.projectService.getemptyProject(_project);
      projectsShell.push(project);
    }); 

    return projectsShell;
  }

  createProposalTransition(proposal:ProposalModel,options?:ApiOptions){
    this.prepDate(proposal);
    return this.add<ProposalModel>(proposal,options);
  }

  approvalLink(key){
    return this.apiService.get(`proposal-approval-link/${key}`);
  }
}
