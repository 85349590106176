import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { trigger, transition, style, animate } from '@angular/animations';
import { ProjectModel, AssetModel, UploadResults, UserProfileModel } from 'src/app/_models/subjects';
import { AssetService } from 'src/app/_services/asset.service';
import { Observable } from 'rxjs';
import { GoogleApiService } from 'src/app/_services/google-api.service';
import { first } from 'rxjs/operators';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: "app-asset-cabinet",
  templateUrl: "./asset-cabinet.component.html",
  styleUrls: ["./asset-cabinet.component.scss"],
})
export class AssetCabinetComponent implements OnInit {
  @Input() project: ProjectModel;
  @Output() toggleDisplay = new EventEmitter();

  $assets: Observable<AssetModel[]>;
  $userProfile: Observable<UserProfileModel>;
  constructor(
    private assetService: AssetService,
    public gapi: GoogleApiService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.$assets = this.assetService.activeAssetsByProject();
    this.$userProfile = this.userService.userProfile();
  }

  close() {
    console.log("click");
    this.toggleDisplay.emit(false);
  }

  // uploadProjectGAsset($event, project: ProjectModel) {
  //   const googleRef = this.gapi.openPicker($event, {
  //     subject: "project",
  //     model: project,
  //   });

  //   googleRef.pipe(first()).subscribe((result) => {
  //     console.log(result);
  //     this.$assets = this.assetService.assetsByProject(project.id);
  //   });
  // }

  uploadProjectAsset(result: UploadResults) {
    //console.log('added',event, project);
    this.assetService.assetsByProject();
    //this.$assets = this.assetService.activeAssetsByProject();
    // this.assetService.upload(event, "project", project).subscribe((assets) => {
    //   this.$assets = this.assetService.assetsByProject(project.id);
    // });
  }
}
