import { Injectable, Input, Directive } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray } from '@angular/forms';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class ModalLibraryService {
  @Input() form: UntypedFormGroup;
  //@Input() data: any;
  items: UntypedFormArray;
  subject: string = null;
  panels: Array<string>;
  panelSelected = new UntypedFormControl(0);
  isLibraryOpen: boolean = false;
  editMultiple: boolean = false;
  hasNew: boolean = false;
  constructor() {    
  }


  init(){

  }

  cloneframe(index){
    // let copyForm = this.thisform(index).value;
    // delete (copyForm[`${this.subject}_number`]);
    // delete (copyForm.id);
    // this.thisform(0).patchValue(copyForm);
    // this.panelSelected.setValue(0);
    // this.closeFrame(index);
  }

  closeFrame(index){
    this.items = this.form.get("items") as UntypedFormArray;
    this.items.removeAt(index);
    this.panels = this.panels.filter((item, i) => i !== index);
  }

 
}
