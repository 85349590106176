import { Component,HostListener, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject, Subscription} from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators, UntypedFormArray, AbstractControl, ValidationErrors, FormArray, FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import { TimesheetService } from '../../_services/timesheet.service';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { MatCalendarCellCssClasses, MatCalendar } from '@angular/material/datepicker';
import { BrandService } from '../../_services/brand.service';
import { VelvetysoftService } from '../../_services/velvetysoft.service';
import { ProjectService } from '../../_services/project.service';
import { BrandModel, CompanySetting, ProjectModel, Settings, TimesheetModel, UserModel, UserProfileModel } from "../../_models/subjects";
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmComponent } from '../dialogs/confirm/confirm.component';
import * as moment from "moment";
import { map, tap, debounceTime } from 'rxjs/operators';
import { UserService } from 'src/app/_services/user.service';
import { filterUserCanByPipe } from 'src/app/pipes.pipes';
import { AddTimsheetInvoiceComponent } from '../timesheet-invoice/add-timesheet-invoice/add-timesheet-invoice.component';
import { TimesheetInvoiceService } from 'src/app/_services/timesheet-invoice.service';
import { BlockNavigationIfChange } from 'src/app/_guards/unsaved-changes-guard.guard';




// import { default as _rollupMoment } from "moment";
// const moment = _rollupMoment || _moment;

@Component({
  selector: "app-timesheet",
  templateUrl: "./timesheet.component.html",
  styleUrls: ["./timesheet.component.scss"],
  providers: [
    filterUserCanByPipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ]
  //encapsulation: ViewEncapsulation.None
})
export class TimesheetComponent implements OnInit {

  hasChanges$ = new Subject<boolean>();

  currentWeek: UntypedFormControl;
  form: UntypedFormGroup;
  timesheets: UntypedFormArray;
  days: UntypedFormArray;
  cdn = environment.cdn;
  deepErrors: any;
  weekRange: any = { start: null, end: null };
  //$brands: Observable<BrandModel[]>;
  $projects: Observable<ProjectModel[]>;  
  $projectList = [];
  $userProfile: Observable<UserProfileModel>;
  $brandList: Observable<BrandModel[]>;
  $myProjects: Observable<ProjectModel[]>
  loadingTS: boolean = false;
  $timesheets: Subscription;
  totalhours: number = 0;
  approved = false;
  deleteSheets: number[] = [];
  approvedDate: moment.Moment = null;
  @ViewChild(MatCalendar) _cal: MatCalendar<any>;
  workTypes: Settings;
  workTypesInternal: Settings;
  hourstype: any = null;
  brandType: any = null;
  projectType: any = null;
  cachedBrandList: BrandModel[] = [];
  mycompany: string = null;
  projectTotal: number|null = null;
  pastFormState: any = null;
  //$overview: Observable<any>;
  approveds = [];
  autosave: boolean = false;
  autosavestatus: string = null;
  users:UserModel[] = [];  

  //ADMIN impersonating
  impersonating: UserModel = null;
  overview: string = 'week';
  overviewtype: string = 'type';

  optionsBrand = {
    responsive: true,
    lineTension: 0,
    legend: {
      position: "right",
    },    
  };

  // for existing timesheets.
  // observable is asynchronous
  // $timesheets: Observable<TimesheetModel[]>

  constructor(
    private fb: UntypedFormBuilder,
    private timesheetService: TimesheetService,
    public brandService: BrandService,
    private projectService: ProjectService,
    private timesheetInvoiceService: TimesheetInvoiceService,
    public vs: VelvetysoftService,
    private snack: MatSnackBar,
    public dialog: MatDialog,
    private router: Router,
    private userService: UserService,
    private userCan: filterUserCanByPipe
  ) {
    this.impersonating = null;
    this.currentWeek = new UntypedFormControl(moment());
  }

  
  
  public onValChange(val: string, type: string) {
    if(type == 'range') this.overview = val;
    if(type == 'type') this.overviewtype = val
    this.generateTimesheets();
    
  }

  ngOnDestroy(){
    this.$timesheets.unsubscribe();
    this.impersonating = null;
    this.timesheetService.cached = null;
    this.form = null;
    //this.hasChanges$.next(true);
    //console.log('destroy')
  }

  trigger(){
    this.autosave = false;
    this.refreshApprovedOverview();
    this.generateTimesheets();
  }

  ngOnInit() {
    //console.log('reinit')
    this.timesheetService.cached = null;
    this.impersonating = null;
    this.refreshApprovedOverview();
    this.generateTimesheets();    
    this.workTypes = this.vs.getSettings('jobs');
    //console.log(this.workTypes)
    this.workTypesInternal = this.vs.getSettings('internaljobs');
    this.$brandList = this.projectService.projectTree.pipe(tap( blist => this.cachedBrandList = blist));
    this.$userProfile = this.userService.userProfile().pipe(tap( profile => {
       this.mycompany = profile.company.name;
       if(this.userCan.transform(profile, 'manage timesheets')){        
        this.userService.$activeUsers.pipe(first())
        .subscribe( users => this.users = users);
       }
    }));
   
    this.$timesheets = this.timesheetService
      .timesheets(this.genStartOfWeek)
      .subscribe(data => {
        this.injectSheets(data.sheets)
        this.hourstype = (Object.keys(data.type).length > 0) ? data.type : null ;
        this.brandType = (Object.keys(data.brand).length > 0) ? data.brand : null; 
        this.projectType = (Object.keys(data.projects).length > 0) ? data.projects : null; 
        this.projectTotal = data.projectTotal || null; 
        //console.log(this.brandType)
      });

    
  }

  refreshApprovedOverview(){
    this.timesheetService.overview(this.impersonating?.id)
      .pipe(first())
      .subscribe(app => {        
        this.approveds =  app
        this._cal?.updateTodaysDate();
        //this.selectWeek('change', null)
      });
  }

  findBrandProjects(brands,id){
    let brand =  brands.find( brand => brand.id == id)
    if(!brand) return ;
    
    return brand.projects.filter( project => project.status == "active");
  }

  

  get formTimesheets(){
    return (this.form.get("timesheets") as UntypedFormArray).controls;    
  }

  onSubmit(skipToast?:boolean) {  
    //console.log(this.form.value);
    /// THE SERVER CALL IS GOING TO RETURN AN ERROR, IT"S EXPECTING A SINGLE ROW AND WE ARE GIVING IT 5 IN AN OBJECT - GOING TO NEED TO REFORAMT DATA FOR REQUEST
    this.pastFormState = 'SKIP';
    this.loadingTS = true;
    const data = {...this.form.value,deleteSheets: this.deleteSheets,imp: this.impersonating?.id}
    this.timesheetService.addTimesheet(data, skipToast).subscribe(res => {      
      this.pastFormState = 'SKIP';
      this.deleteSheets = [];
      this.timesheetService.loadTimesheets(this.genStartOfWeek, this.overview, this.impersonating?.id, skipToast).subscribe( data => {
        this.loadingTS = false;
      });
    });
  }

  navTo(id){
    this.router.navigate([`/dashboard/project/${id}`]);    
  }

  approve(){
    let vm = this;
    const dialogRef = this.dialog.open(ConfirmComponent, {
      data: {
        id: '',
        type: 'approve',
        title: `Approve Timesheet for week: ${moment(this.currentWeek.value).format('MMM Do YYYY')}`,
        content: 'This can not be undone. Once approved your timesheet can not be edited.'
      }
    });
    dialogRef
      .afterClosed()
      .subscribe((result) => {
        if (result && result.status === 'approve') {          
          vm.form.value.timesheets
          .map( ts => ts.days.map( day => {
            day.approved = true
          }));
          this.approved = true;
          this.approvedDate = moment();
          this.onSubmit();
          this.refreshApprovedOverview();
        }
      })
  }

  generateTimesheets() {
    //FORCE CHANGE DETECTION
    //CREATE NEW FORM ENTIRELY
    this.hourstype = [];
    this.brandType = [];
    this.form = this.fb.group({
      timesheets: this.fb.array([this.createTimesheet()])
    });    
    this.timesheetService.setRange(this.genStartOfWeek,this.impersonating?.id,this.overview,true);
  }

  triggerAutoSave(_self){
        let _formState = JSON.stringify(_self.form.value);                
        //console.log('trigger save', _self.pastFormState)

        if(_self.form.status == 'VALID' && _self.pastFormState !== 'SKIP' && _formState !== _self.pastFormState){
          _self.autosavestatus = 'Saving...';
          _self.onSubmit(true);
          setTimeout(()=> _self.autosavestatus = null, 1000);
        }

        this.pastFormState = _formState;
  }

  canInvoice(){
    //console.log(this.approveds, this.currentWeek.value)
    let prev = this.currentWeek.value.clone().add(-1, "months");
    return {
      prev: prev
    };
  }

  injectSheets(sheets, aClone:boolean = false){
    this.approved = false;
    this.approvedDate = null;
    this.totalhours = 0;

    //INCOMING TIMESHEETS DATA     
     
    
    let lines = this.createLineItems(sheets);    
    let _timesheetsForm = this.form.get('timesheets') as UntypedFormArray;
    _timesheetsForm.clear();

    //CLONE EXCEPTION
    /*
    * See if any timeseehts exist. Check if their is a brand then it was started and skip, if not overide it
    * This exception will allow for appending new data keeping old, or preventing a blank line
    */
    let cloneStartLine = 0;
    if(aClone && this.form.value && this.form.value.timesheets[_timesheetsForm.controls.length-1]?.brand_id !==  ""){
      cloneStartLine = _timesheetsForm.controls.length;
    }

    lines.forEach( (lineData, i) =>{     
      //console.log(lineData,'line')
      //CLONE SPECIFIC
      i = cloneStartLine+i;

      if (!_timesheetsForm.controls[i]) this.addTimesheet();      
      
      let lineForm = _timesheetsForm.controls[i];
      let daysForm = lineForm.get('days') as UntypedFormArray;

      //SETUP GLOBALS LINEDATA
      lineForm.patchValue(lineData[0]);

      //ADD DAYS
      lineData.forEach(dayData =>{      
        //console.log(dayData)
        daysForm.controls.forEach((dayForm: UntypedFormGroup, j) => {   
          dayData.exists = true;

          //CLONE EXCEPTION
          //console.log(dayData,this.approved,aClone)
          if(aClone){
            dayData.id = null;            
            if (dayForm.controls.day.value.isSame(moment(dayData.date).add(1, "weeks"))){
              dayData.approved = false;
              dayForm.patchValue(dayData);
              
              this.approved = false;
              this.approvedDate = null;
              //console.log(this.approved)
              this.totalhours += dayData.time_worked;
            }
          }else{
            //console.log(dayData.time_worked, dayData.date)
            //IF APPROVED SET GLOBALLY
            if(dayData.approved){
              this.approved = true;
              this.approvedDate = dayData.updated_at;              
            }
            if (dayForm.controls.day.value.isSame(dayData.date)){
              
              //CHECK FOR DUPLICATE ERROR
              if(dayForm.value.time_worked){
                this.duplicateError(dayForm,dayData);                 
              }else{
                dayForm.patchValue(dayData);
              }                                    

              this.totalhours += dayData.time_worked;
            }

          }
        });
      })
    })

    this.form.valueChanges.pipe(debounceTime(300))
    .subscribe((_form) => {
    // this.createGroupForm.valueChanges.subscribe(value => {
    //   this.hasChange = Object.keys(initialValue).some(key => this.form.value[key] != 
    //                     initialValue[key])
      this.totalhours = 0;
        _form['timesheets'].map(ts => ts.days.map(d => {
          //console.log(d.time_worked)
          let hours = +d.time_worked;
          if (!isNaN(hours)) this.totalhours += hours;
        }))

        if(this.autosave){          
          this.triggerAutoSave(this)
          //this.debounceNormal(, 10000)(this);
        }

        this.duplicateLine();

        this.deepErrors = this.findInvalidControls();  
        //console.log(this.deepErrors)            
    });
  }

  duplicateError(dayForm, dayData){
    let _time = +dayForm.value.time_worked + +dayData.time_worked;         
    console.log(`ERROR, DUPLICATE - adding ${dayData.time_worked} for ${dayData.brand_id} ${dayData.work_type} to ${dayForm.value.time_worked} = ${_time}`)       
    this.deleteSheets = [...this.deleteSheets, dayData.id];
    dayForm.patchValue({time_worked: _time});
    let saveNow = this.snack.open(`Duplicate timesheet found. Hours have been added to existing timesheet. Save Now to Fix`, 'Save', {duration: 15000, });
    saveNow.onAction().subscribe(()=> this.onSubmit());
  }

  addError(field, parent, invalidControls){
    
    (invalidControls[parent]?.length)
      ? invalidControls[parent].push(field)
      : invalidControls[parent] = [field];         
    //console.log(field, parent)
  }

  findInvalidControls() {
    //console.log('findInvalidControls')
    //LOOK FOR DUPLIACAETES


    var invalidControls = {};
    let recursiveFunc = (form:FormGroup|FormArray, parent) => {
      Object.keys(form.controls).forEach((field,idx) => { 
        const control = form.get(field);
        if (control.invalid && field !== 'timesheets' && control.errors?.duplicateError) this.addError('Duplicate found', idx, invalidControls);

        if (control.invalid && field !== 'timesheets'){
          //add to array or create new
          this.addError(field, parent, invalidControls);
                     
        }
        if (control instanceof FormGroup) {
          recursiveFunc(control, field);
        } else if (control instanceof FormArray) {
          recursiveFunc(control, field);
        }        
      });
    }
    recursiveFunc(this.form, null);
    delete invalidControls['timesheets'];
    //map each itme in array to a string of the array items in it
    
    Object.keys(invalidControls)
      .map( key => invalidControls[key] = `Line ${+key+1} requires ` + invalidControls[key].join(', ')
        .replace("brand_id","Brand")
        .replace("work_type","Work Type")
        .replace("days","Hours") 
        .replace("requires Duplicate found","Duplicate entry found")
      );
    console.log(this.form)
    //do a join on this object
    return Object.values(invalidControls).join('; ');
}

  debounceNormal(fn: Function, wait: number) {
    let timer: any;
    return (...args: any[]) => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        fn(...args);
      }, wait);
    };
  }

  markCalendarDates() {
    return (date: moment.Moment): MatCalendarCellCssClasses => {
      let _date = date.format("Y-M-D");
      let output = "";

      this.approveds.map((appDate)=>{
        if( moment(appDate).isSame(date,'week') ){
          output += "approved ";
        }
      })

      if (date.isBetween(this.weekRange.start, this.weekRange.end)) {
        output += "selected-week ";
      }
      if (
        date.format("Y-M-D") == "2019-11-27" ||
        date.format("Y-M-D") == "2019-11-19"
      ) {
        output += "has-timesheet";
      }

      return output;
      
    };
  }

  createTimesheet() {
    // this.days = this.form.get('timesheets').get('days') as FormArray;
    return this.fb.group({
      //date: [moment().format("Y-M-D")],
      project_id: [""],
      brand_id: ["", Validators.required],
      description: [""],
      hasDescription: [false],
      work_type: ["", Validators.required],
      days: this.fb.array(this.generateWeek(),{ validators: [this.atLeastOne] }) //this.days
    })
    //, { validators: [this.duplicateLine.bind(this)] });
  }

  sortBy(prop: string,arr: any[]) {
    return arr.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
  }

  duplicateLine(){
    if(!this.timesheets) return null;
    
    let _frm = this.form.get("timesheets") as UntypedFormArray;
    let _sheets = this.form.get("timesheets").value;
    let dups = false;
    let seen = [];

    
    _sheets.forEach(function (sheet, idx) {
      let bid = (sheet?.brand_id) ? sheet?.brand_id : '';
      let pid = (sheet?.project_id) ? sheet?.project_id : '';
      let wt = (sheet?.work_type) ? sheet?.work_type : '';
      let _key = `${bid}-${pid}-${wt}`;            
      if(seen.includes(_key)) dups = idx;        
      seen.push(_key);
    });    

    if(dups){
      _frm.setErrors({duplicateError: `Duplicate found`});
      console.log('DUPS',seen)
      this.snack.open(`Duplicate timesheet found. Please remove duplicate lines ${dups+1}`, 'Close', {duration: 2000, });
    }
  }

  atLeastOne(control: AbstractControl): ValidationErrors {
    const valid = (control as FormArray).controls.some(c =>  c.value.time_worked > 0 );

    return valid ? 
      null : 
      {'atLeastOneError': true};
}

  addTimesheet() {
    this.timesheets = this.form.get("timesheets") as UntypedFormArray;
    this.timesheets.push(this.createTimesheet());
  }

  removeline(idx){

    this.timesheets = this.form.get("timesheets") as UntypedFormArray;
    this.deleteSheets = [...this.deleteSheets, ...this.getCtxFormVal(idx,'days')
      .filter(day => day.id)
      .map(day => day.id)
    ];
    //console.log(this.deleteSheets)
    // console.log(this.getCtxFormVal(idx,'days'))
    //
    this.timesheets.removeAt(idx);
  }

  selectWeek(type: string, event: moment.Moment) {        
    this.deleteSheets = [];
    //console.log(event, this.currentWeek.value)
    //if (event.diff(this.currentWeek.value)){
    if (event.diff(this.currentWeek.value, 'days') < 7 && event.diff(this.currentWeek.value, 'days') > 0){
      return console.log('skip');
    }
    //}
    this.currentWeek.patchValue(event);
    this.generateTimesheets();
    //FORCE UPDATE
    this._cal.updateTodaysDate();
  }

  clonePrevWeek(){
    this.autosave = false;
    let lastWeek = this.currentWeek.value.clone().add(-1, "weeks");
    this.timesheetService
      .returnTimesheets(lastWeek.toDate())
      .subscribe( sheets => this.injectSheets(sheets, true));
    //this.currentWeek.value.clone().startOf("isoWeek");
    //console.log(lastWeek)
    //this.timesheetService.setRange(this.genStartOfWeek);    
  }

  prevWeek() {
    this.currentWeek.patchValue(this.currentWeek.value.clone().add(-1, "weeks"));
    
    this.generateTimesheets();
  }

  nextWeek() {
    this.currentWeek.patchValue(this.currentWeek.value.clone().add(1, "weeks"));
    
    this.generateTimesheets();
  }

 invoice(profile:UserProfileModel){
  
  //console.log(profile)
  //check if user has a address in profile.user.addresses
  if(profile.user?.addresses.length){
    this.timesheetService
      .timesheets(this.genStartOfWeek)
      .pipe(first())
      .subscribe(data => {
        this.timesheetInvoiceService.edit(null, {
          week: this.genStartOfWeek, 
          sheets: data.sheets,
          preview: { range: this.genStartOfWeek, imp: this.impersonating?.id, overview: this.overview },
        });
      });
  }else{
    let sref =this.snack.open('Please add your address before creating an invoice', 'Add Address', {duration: 3000, });
    sref.onAction().subscribe(()=>{
      this.router.navigate(['/dashboard/edit/profile']);
    });
  }

  
 }

  getCtxFormVal(index:number, field:string){
    return this.form.get('timesheets').value[index][field];
  }
  toggleCtxFormVal(index: number, field: string) {
    this.form.get('timesheets').value[index][field] = !this.form.get('timesheets').value[index][field];
  }

  get genStartOfWeek(){
    return this.currentWeek.value.clone().startOf("isoWeek");
  }

  private generateWeek() {
    //FIND MONDAY OF CURRENT WEEK
    var dayForm = [];
    //var week = [];
    let startOfWeek = this.genStartOfWeek;
    let endOfWeek = startOfWeek.clone().add(5, "d");

    this.currentWeek.patchValue(startOfWeek);
    this.weekRange.start = startOfWeek;
    this.weekRange.end = endOfWeek.clone().add(1, "d");

    var day = startOfWeek;

    do {
      //week.push(day.toDate());
      dayForm.push(
        this.fb.group({
          time_worked: ["", [Validators.pattern("^[0-9]{1,3}(?:\.[0-9]{1,3})?$")]],
          approved: false,
          day: [day],
          exists: [false],
          id: null
        }) //this.days
      );
      day = day.clone().add(1, "d");
    } while (day <= endOfWeek);
    
    return dayForm;
  }

  /////////
  // THIS IS FOR DISPLAYING EXISTING TIMESHEETS - MIGHT NEED TO MOVE TO SERVICE IF USED AGAIN
  /////////
  createLineItems(sheets:{types:any[], sheets:any[]}){    
    let lines = this.groupBy(sheets, (line)=>{
      return [line.brand_id, line.project_id, line.work_type];
    })    
    return  lines;
  }

  
  groupBy(array, f) {
    var groups = {};
    let _array = array;
    //console.log(array instanceof Object, array)
    if(array instanceof Object && array.sheets) _array =  _array.sheets;
    _array.forEach(function (o) {
      var group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    return Object.keys(groups).map(function (group) {
      return groups[group];
    })
  }  
  

}
