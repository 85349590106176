<div class="container" *ngIf="( $proposals | async ); let proposals; else noProposals">

  <ng-container *ngIf="($userProfile | async);let profile">
    <app-alert></app-alert>

    <div class="block">
      <div class="block-title">
        <div class="block-title-label" for="">
          <mat-icon class="mr-1" *ngIf="subjectObj.icon">{{ subjectObj.icon }}</mat-icon>
          <span *ngIf="subjectObj.icon">{{ subjectObj.label }}</span>
        </div>
        <div class="block-title-toolbar view-tools">
          <button *ngIf="profile | userCan: 'view invoice & proposal'" mat-button (click)="edit()" color="primary">
            <mat-icon>add</mat-icon>New Proposal
          </button>
          <button [ngClass]="{ 'active': view == 'table'}" mat-icon-button (click)="changeView('table')">
            <mat-icon class="">view_list</mat-icon>
          </button>
          <button [ngClass]="{ 'active': view == 'tile'}" mat-icon-button (click)="changeView('tile')">
            <mat-icon class="">view_module</mat-icon>
          </button>
        </div>
      </div>      

      <div class="row ">
        <div class="col-sm-12" *ngIf="view == 'table'">
          <div class="table-wrap-mobile" *ngIf="proposals.length; else noneYet">
            <div class="block">
              <div class="block-content">

                <ng-template #customActionsTemplate>
                  <button *ngIf="profile | userCan: 'view invoice & proposal'" mat-menu-item (click)="trash()">Delete</button>
                </ng-template>        

                <app-table
                  mat-table
                  #vaultTable
                  matSort #sort="matSort"
                  filterOn="status"
                  [filters]="types.statuses"
                  [filtered]="filteredSubjects" 
                  [dataSource]="$proposals" 
                  [columns]="displayedColumns"
                  [actionsTemplate]="customActionsTemplate">

                    <ng-container matColumnDef="user">
                      <th mat-header-cell *matHeaderCellDef> </th>
                      <td mat-cell class="cap" *matCellDef="let element">
                        <user-avatar [user]="element.user"></user-avatar>
                      </td>
                    </ng-container>
                    
                    <!-- <ng-container matColumnDef="proposal_number" >
                      <th mat-header-cell *matHeaderCellDef mat-sort-header><span class="cap">{{ subject }}</span> #</th>
                      <td mat-cell class="cap" *matCellDef="let element"> 
                        #<span *ngIf="!multiBrand(element)">{{prefix}}</span>
                        {{element.proposal_number}}
                        <span class="label" *ngIf="multiBrand(element)"> (Multi-brand)</span>
                      </td>
                    </ng-container> -->
                    
                    <ng-container matColumnDef="title">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
                      <td mat-cell class="cap" *matCellDef="let element">
                        <div class="title cursor" (click)="edit(element,{view:'proposal',title: element.title})">{{element.title}} <span class="label" *ngIf="multiBrand(element)"> (Multi-brand)</span> </div>
                      </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="total" >
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Total </th>
                      <td mat-cell class="cap" *matCellDef="let element"> {{element.total | currency }} </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="type">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
                      <td mat-cell class="cap" *matCellDef="let element">
                        <meta-display subject="proposal" type="types" [id]="element.type"></meta-display>
                      </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="status">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                      <td mat-cell class="" *matCellDef="let element">
                        <meta-display subject="proposal" [item]="element" type="statuses" [id]="element.status"></meta-display>                     
                        <span class="mat-sm" *ngIf="element.analytics.length > 0;">
                          &nbsp; <mat-icon matTooltipPosition="right" matTooltip="Recipient Viewed Invoice" (click)="log(element)">
                            how_to_reg</mat-icon>                  
                        </span>
                        <span class="mat-sm" *ngIf="element.penalty"> &nbsp; <mat-icon matTooltipPosition="right"
                            matTooltip="Penalty Added">notification_important</mat-icon></span>
                      </td>
                    </ng-container>
                    
                    <!-- <ng-container matColumnDef="due">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Due Date </th>
                      <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.due_date; else nodate" [matTooltip]="element.due_date | date"
                          matTooltipPosition="above">{{element.due_date | amFromUtc | amTimeAgo}}</span>
                        <ng-template #nodate> -- </ng-template>
                      </td>
                    </ng-container> -->
                    
                    <ng-container matColumnDef="actions">
                      <th class="text-right" mat-header-cell *matHeaderCellDef> Actions </th>
                      <td class="text-right" mat-cell *matCellDef="let element">                      
                        <button *ngIf="profile | userCan: 'view invoice & proposal'" (click)="edit(element,{view:'proposal',title: element.title})" class="mat-sm cursor" mat-icon-button mat-button>
                          <mat-icon>edit</mat-icon>
                        </button>
                        <button *ngIf="profile | userCan: 'view invoice & proposal'" (click)="send(element)" class="mat-sm" mat-icon-button mat-button>
                          <mat-icon>email</mat-icon>
                        </button>
                        <button *ngIf="profile | userCan: 'view invoice & proposal'" (click)="trash(element.id)" class="mat-sm" mat-icon-button mat-button>
                          <mat-icon>delete</mat-icon>
                        </button>
                        <button [matMenuTriggerFor]="moreMenu" class="mat-sm" mat-icon-button mat-button>
                          <mat-icon>more_vert</mat-icon>
                          <mat-menu #moreMenu="matMenu">
                            <!-- <button mat-menu-item *ngIf="element.project_id">View Project</button> -->
                            <button mat-menu-item (click)="download(element)">Download Proposal</button>
                            <button *ngIf="profile | userCan: 'create project'" mat-menu-item (click)="createProjects(element)">Create Project(s) from Proposal</button>
                            <button *ngIf="profile | userCan: 'manage invoice'" mat-menu-item (click)="convetToInvoice(element)">Convert to Invoice</button>            
                            <button *ngIf="profile | userCan: 'manage invoice'" mat-menu-item (click)="wizard(element)">Invoice Wizard Creator</button>                            
                            <button *ngIf="profile | userCan: 'manage invoice'" mat-menu-item (click)="approvalLink(element)">Show Approval Link</button>                            
                            <!-- <button mat-menu-item (click)="log(element)">Proposal Log</button> -->
                          </mat-menu>
                        </button>
                      </td>
                    </ng-container>
                    
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></tr>
                </app-table>

              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-12" *ngIf="view == 'tile'">
          <div class="row">
            <div class="col-sm-12">
              <div class="block block-mini">
                <div class="block-content">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="d-flex justify-content-between align-items-center">
                        <div>
                          <mat-form-field class="example-form-field">
                            <mat-label>Search</mat-label>
                            <input matInput type="text" [(ngModel)]="search">
                            <button mat-button *ngIf="search" matSuffix mat-icon-button aria-label="Clear" (click)="search=''">
                              <mat-icon>close</mat-icon>
                            </button>
                          </mat-form-field>
                        </div>
          
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="row ">
            <div class="col-lg-6 col-xl-4" *ngFor="let proposal of proposals | filter:search">
              
              <div class="block">
                <div class="block-content">
                  <div class="row m-0 align-items-center form-group">
                    <div class="mr-3">
                      <user-avatar [class]="'mdm'" [user]="proposal.user"></user-avatar>
                    </div>
                    <div class="flex-fill">
                      <h3 class="card-title">{{ proposal.title }}</h3>
                      <div class="subhead">{{ proposal.proposal_number }}</div>
                    </div>
                    <div class="flex-shrink ">
                      <button mat-button (click)="edit(proposal,{view:'proposal',title: proposal.title})" color="primary">Edit</button>
                    </div>
                  </div>
                </div>
                <div class="block-hr"></div>
                <div class="block-content">
                  <div class="">
                    <!-- <div class="row">
                      <div class="col-auto d-flex align-items-center">
                        <span class="subhead">Brands: &nbsp;</span>
                        <app-brand-logos [brands]="user.brands"></app-brand-logos>
                      </div>
                      <div class="col-auto d-flex align-items-center">
                        <span class="subhead">Projects: &nbsp;</span>
                        <span class="dot-label active">{{ user.projects.length }}</span>
                      </div>
                      <div class="col-auto d-flex align-items-center">
                        <span class="subhead">Role: &nbsp;</span>
                        <span class="label-sm" *ngFor="let role of user.roles">
                          {{ role.name }}
                        </span>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>


    </div>
  </ng-container>
</div>

<ng-template #noneYet>
  <p>No Proposals found</p>
</ng-template>

<ng-template #noProposals>
  <mat-spinner color="primary" class="mx-auto loader"></mat-spinner>
</ng-template>