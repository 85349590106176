import { Component, OnInit, ViewChild } from '@angular/core';
import { VelvetysoftService} from '../../_services/velvetysoft.service'
import { AssetService } from '../../_services/asset.service';
import { Observable } from 'rxjs';
import { AssetModel, Pager, UserProfileModel } from 'src/app/_models/subjects';
import { first } from 'rxjs/operators';
import { UserService } from 'src/app/_services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-manage-assets',
  templateUrl: './manage-assets.component.html',
  styleUrls: ['./manage-assets.component.scss']
})
export class ManageAssetsComponent implements OnInit {

  subject:any;
  $userProfile: Observable<UserProfileModel>;
  $assets:Observable<Pager<AssetModel>>;
  displayedColumns: string[] = ['select', 'preview', 'name','created', 'size','actions',];
  sortedData: AssetModel[];
  project_type: any;
  assetspager;
  
  @ViewChild('vaultTable') vaultTable;

  constructor(
    public vs: VelvetysoftService,
    private assetService: AssetService,
    private userService: UserService,
    private router: Router,
  ) { }
    //this.subject = vs.

  ngOnInit() {
    this.subject = this.vs.managerNav.find( item => item.label == "Assets" );
    this.assetspager = this.assetService.pager<AssetModel>();
    this.$assets = this.assetspager.$source;
    this.$userProfile = this.userService.userProfile();
  }

  navTo(asset:AssetModel,subject){

    if(subject == 'project') this.router.navigate([`/dashboard/project/${asset.project_id}`])
    if(subject == 'round'){
      this.assetService.findProjectID(asset).pipe(first()).subscribe( pid => {
        this.router.navigate([`/dashboard/project/${pid}`], {
          queryParams: { round: asset.round_id } 
        });
      });
    }
  }

  size(bytes){    
    return this.assetService.size(bytes);
  }

  add(){

  }

  trash(id?){
    // let ids = id ? [id] : this.vaultTable.selection.selected.map((p) => p.id);
    // this.assetService.deleteDialog(ids,{skipLoad:true});
  }

  edit(asset){
    this.assetService.edit(asset)
      .pipe(first())
      .subscribe( asset =>{
        console.log('finished');        
      })
  }

  

}
