<div class="mt-2">
    <span *ngIf="showlabel" class="label">Tags:</span>
    <mat-chip-list #tagList aria-label="tags" >
        <mat-chip class="chip-sm chip-subtle" *ngFor="let tag of tags">{{ tag.vhtag }} </mat-chip>
        <button 
            mat-icon-button 
            color="primary" 
            matTooltip="Add Tag"
            matTooltipPosition="right" 
            (click)="createTag(subject)" 
            class="quick-add inline-icon">
        <mat-icon class="">add_box</mat-icon>
        </button>
    </mat-chip-list>    
</div>
