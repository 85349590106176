<div class="container" *ngIf="$userProfile | async; let profile; else loader">
  <div class="block">
    <div class="block-title">
      <div class="block-title-label" for="">Company</div>      
    </div>
    <div class="block-content">
      <app-alert></app-alert>
      <mat-tab-group>
        <mat-tab label="Company">                
          <div class="py-5">
            <div class="row m-0">
              <div class="col-sm-8">
                <form *ngIf="$userProfile | async; let profile" (ngSubmit)="submit()" [formGroup]="form">  
      
                  <div class="fieldset mb-4">
                    <div class="row ml-2">
                      <div class="col-sm-6 ">
                        <div class="form-group">
                          <mat-form-field class="w-100">
                            <input matInput placeholder="Name" type="text" name="name" formControlName="name" required />
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="form-group">
                          <mat-form-field class="w-100">
                            <input matInput name="prefix" [maxLength]="3"  placeholder="Company Prefix (IBM)"  formControlName="prefix" required>                                                        
                          </mat-form-field>
                          <label for="" class="label" >Brand Initials up to 3 characters</label>
                        </div>
                      </div>
                      <div class="col-sm-8 ">
                        <div class="form-group">
                          <mat-form-field class="w-100">
                            <input matInput placeholder="Website" type="text" name="website" formControlName="website" required />
                          </mat-form-field>
                        </div>
                      </div>
                      
                      <div class="col-sm-12">
                        <div class="form-group">
                            <div><label class="label">Brand Color</label></div>
                            <span (click)="swatchOpen = !swatchOpen" class="color-block mr-1"
                              [style.background-color]="form.controls.color.value"></span> 
                            <div *ngIf="swatchOpen" class="swatch-wrap">
                              <color-chrome
                                [color]="(form.controls.color.value||'#333333')"
                                (onChangeComplete)="colorChange($event)"></color-chrome>
                                <div class="p-1 text-center">
                                  <button (click)="swatchOpen = false" mat-button>Close</button>
                                </div>                              
                            </div>                          
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="form-group">
                          <div class="label">Logo:</div>
                          <app-file-upload 
                            type="company"
                            class="might-be-trans"
                            (uploadComplete)="companyLogo($event)" 
                            [_file]="profile.company.logo">
                          </app-file-upload>
                          <br>
                          <div class="label">Icon:</div>
                          <app-file-upload 
                            class="might-be-trans"
                            (uploadComplete)="companyIcon($event)" 
                            [_file]="profile.company.icon" 
                            type="company">
                          </app-file-upload>
                        </div>
                      </div>                      
                    </div>
                  </div>

                  <label class="mat-dialog-title" for="">Company Brand</label>
                  <div class="fieldset mb-4">
                    <div class="row ml-2">
                        <div class="col-sm-7">                  
                          <div class="form-group">
                            <div class="label">Attach a Brand as your compan y to track internal projects</div>
                            <app-brand-selector [excludeCompany]="true" [form]="form" formkey="mybrand" [profile]="profile"></app-brand-selector>                             
                          </div>
                        </div>
                    </div>
                  </div>
      
                  
      
                  <label class="mat-dialog-title" for="">Space Used</label>
                  <div class="fieldset mb-4">
                    <div class="row ml-2">
                      <div class="col-sm-4">
                        <div 
                          class="form-group"
                          matTooltip="{{ spaceUsed.percent }}% Used"
                          matTooltipPosition="right">
                          {{ spaceUsed.value }} of {{ spaceUsed.allowed }}
                          <mat-progress-bar 
                            mode="determinate" 
                            value="{{ spaceUsed.percent }}"></mat-progress-bar>
                        </div>
                      </div>
                    </div>
                  </div>

                  <label class="mat-dialog-title" for="">OpenAI Used</label>
                  <div class="fieldset mb-4">
                    <div class="row ml-2">
                      <div class="col-sm-4">
                        <div 
                          class="form-group"
                          matTooltip="{{ openAiUsed.percent }}% Used"
                          matTooltipPosition="right">
                          {{ openAiUsed.value }} of {{ openAiUsed.allowed }}
                          <mat-progress-bar 
                            mode="determinate" 
                            value="{{ openAiUsed.percent }}"></mat-progress-bar>
                        </div>
                      </div>
                    </div>
                  </div>

                  <label class="mat-dialog-title" for="">Emails Sent</label>
                  <div class="fieldset mb-4">
                    <div class="row ml-2">
                      <div class="col-sm-4">
                        <div 
                          class="form-group">
                          {{ profile.company.emailcount }} of 1000
                          <!-- <mat-progress-bar 
                            mode="determinate" 
                            value="{{ spaceUsed.percent }}"></mat-progress-bar> -->
                        </div>
                      </div>
                    </div>
                  </div>
                              
                  <label class="mat-dialog-title" for="">Invoices</label>
                  <div class="fieldset mb-4">
                    <div class="row ml-2">            
                      <div class="col-sm-4">
                        <div class="form-group">
                          <mat-form-field class="w-100">
                            <input matInput placeholder="Invoice Penalty Percent" type="text" formControlName="invoice_penalty_percent" />
                            <label for="" class="label" matPrefix>% &nbsp;</label>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="form-group">
                          <mat-form-field class="w-100">
                            <input matInput placeholder="Invoice Overdue Range" type="text" formControlName="invoice_overdue_day" />
                            <label for="" class="label" matSuffix>Days</label>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>
      
                  <div class="mb-4">
                    <label class="mat-dialog-title mr-3" for="">Addresses</label>
                    <app-addresses *ngIf="($userProfile | async); let profile" [currentAddresses]="profile?.company?.addresses" [form]="form" ></app-addresses>                    
                  </div>  
                    
                    
                  <!-- <div class="form-group">
                    <mat-form-field class="w-100">
                      <input matInput placeholder="Email" type="email" name="email" formControlName="email" required />
                    </mat-form-field>
                  </div> -->
                  <!-- <button mat-flat-button color="primary" type="submit" [disabled]="submitted || !form.valid">Submit</button> -->
                  <div class="actions mt-2">
                    <button mat-raised-button class="mr-2" color="primary" [disabled]="form.invalid" type="submit">Save Company</button>
                    <button mat-button (click)="back($event)">Back</button>                    
                  </div>
                </form>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Rates">                
          <div class="py-5">
            <div class="row m-0">
              <div class="col-sm-6">
                <label class="mat-dialog-title" for="">Rates</label>
                  <form [formGroup]="formRates" >
                      <div  formArrayName="rates" *ngFor="let rate of getformRate; let i = index">
                        <div class="row ml-2" [formGroupName]="i">     
                            <div class="row ml-2 w-100">
                              
                                <div class="col-sm-4 d-flex align-items-center">
                                  <div >
                                    {{ rate.controls.label.value }}
                                  </div>
                                </div>
                                <div class="col-sm-6">
                                  <mat-form-field class="w-100 type-field">
                                    <span matPrefix>$ &nbsp;</span>
                                    <input matInput placeholder="Rate" autocomplete="off" formControlName="value" mask="separator.2" thousandSeparator="," >
                                    <span matSuffix>per Hour</span>
                                  </mat-form-field>
                                </div>         

                            </div>
                        </div>
                      </div>
                  </form>
              </div>
              <div class="col-sm-6">
                <label class="mat-dialog-title" for="">Internal Rates</label>
                  <form [formGroup]="formRates" >
                      <div  formArrayName="ratesInternal" *ngFor="let rate of getformRateInternal; let i = index">
                        <div class="row ml-2" [formGroupName]="i">     
                            <div class="row ml-2 w-100">
                              
                                <div class="col-sm-4 d-flex align-items-center">
                                  <div >
                                    {{ rate.controls.label.value }}
                                  </div>
                                </div>
                                <div class="col-sm-6">
                                  <mat-form-field class="w-100 type-field">
                                    <span matPrefix>$ &nbsp;</span>
                                    <input matInput placeholder="Rate" autocomplete="off" formControlName="value" mask="separator.2" thousandSeparator="," >
                                    <span matSuffix>per Hour</span>
                                  </mat-form-field>
                                </div>         

                            </div>
                        </div>
                      </div>                                        
                  </form>

                  <label class="mat-dialog-title" for="">Contractor Rates</label>
                  <form [formGroup]="formRates" >               
                      <div  formArrayName="ratesContractor" *ngFor="let rate of getformRateContractor; let i = index">
                        <div class="row ml-2" [formGroupName]="i">
                            <div class="row ml-2 w-100">
                                <div class="col-sm-4 d-flex align-items-center">
                                  <div >
                                    {{ rate.controls.label.value }}
                                  </div>
                                </div>
                                <div class="col-sm-6">
                                  <mat-form-field class="w-100 type-field">
                                    <span matPrefix>$ &nbsp;</span>
                                    <input matInput placeholder="Rate" autocomplete="off" formControlName="value" mask="separator.2" thousandSeparator="," >
                                    <span matSuffix>per Hour</span>
                                  </mat-form-field>
                                </div>         

                            </div>
                        </div>
                      </div>                                        
                  </form>
              </div>
              <div class="col-sm-12 mt-3">
                <div class="actions">                  
                  <button mat-raised-button class="mr-2" color="primary" (click)="submitRates()" [disabled]="formRates.invalid" type="submit">Save Rates</button>
                  <button mat-button (click)="back($event)">Back</button>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Expenses">
          <div class="py-5">
            <div class="row m-0">
              <div class="col-sm-6">
                <label class="mat-dialog-title" for="">Expense Contacts</label>
                <form [formGroup]="formExpenses" >
                    <div formArrayName="expenseContacts" *ngFor="let contact of getformExpense; let i = index">
                      <div class="row ml-2" [formGroupName]="i">
                        <div class="col-sm-1">
                          <div class="removeit">
                            <button                             
                              mat-icon-button 
                              color="primary" 
                              matTooltip="Remove"
                              matTooltipPosition="left" 
                              (click)="removeline(i,'expenseContacts')" 
                              class="quick-remove">
                                  <mat-icon>remove</mat-icon>
                            </button>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <mat-form-field class="w-100 type-field">
                            <input matInput [placeholder]="contact.value.label" type="email" autocomplete="off" formControlName="value" >
                          </mat-form-field>
                        </div>       
                      </div>
                    </div>
                    <div class="form-group">
                      <button class="mr-3" mat-stroked-button (click)="addExpenseAddress($event)"><mat-icon>add</mat-icon> Add To Address</button>
                      <button mat-stroked-button (click)="addExpenseAddress($event,'bcc')"><mat-icon>add</mat-icon> Add Bcc Address</button>
                    </div>
                    <div class="form-group">
                      <button mat-raised-button class="mr-2" color="primary" (click)="submitExpenses()" [disabled]="formRates.invalid" type="submit">Save Expenses</button>
                    </div>
                </form>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Invoices">
          <div class="py-5">
            <div class="row m-0">
              <div class="col-sm-6">
                <label class="mat-dialog-title" for="">Invoice Settings</label>
                <form [formGroup]="formInvoices" *ngIf="formInvoices" >
                    <div formArrayName="invoiceSettings" *ngFor="let setting of getformInovice; let i = index">
                      <div class="row ml-2" [formGroupName]="i">
                        <div class="col-sm-6" *ngIf="setting.value.name == 'logo'">
                          <div class="label">Invoice Logo</div>
                          <app-file-upload 
                            type="company"
                            class="might-be-trans"
                            (uploadComplete)="invoiceLogo($event,i)" 
                            [_file]="setting.value.value">
                          </app-file-upload>
                        </div>       
                      </div>
                    </div>                    
                    <div class="form-group mt-4">
                      <button mat-raised-button class="mr-2" color="primary" (click)="submitInvoices()" [disabled]="formRates.invalid" type="submit">Save Invoices</button>
                    </div>
                </form>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>

    </div>
  </div>
</div>

<ng-template #loader>
  <mat-spinner color="primary" class="mx-auto loader"></mat-spinner>
</ng-template>