import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../_services/auth.service';
import { FormErrorsService } from '../../_services/form-errors.service';
import { AlertService } from '../../_services/alert.service';

  

@Component({
  selector: 'app-reset-pass',
  templateUrl: './reset-pass.component.html',
  styleUrls: ['./reset-pass.component.scss']
})
export class ResetPassComponent implements OnInit {
  token:string = null;
  model: any = {};
  submitted: boolean = false;
  loading: boolean = false;
  email: string = null;
  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private formErrors: FormErrorsService,
    private alertService:AlertService
  ) { }

  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      this.model.token = params.get("token");
      // this.authService.confirmToken(this.model.token).subscribe( 
      //   (res) =>{          
      //     if (res && res.email) {
      //       this.email = res.email;
      //     } else {
      //       //@TODO ADD ERROR THROUGH ROUTE
      //       this.router.navigate(["/login"]);
      //     }
      //   },
      //   error =>{
      //     if (error.status == 429){
      //       //this.router.navigate(["/login"]);            
      //       //@TODO FIX THIS ERROR AND FIX EROR DISPLAY
      //       //this.formErrors.display("to many attempts")
      //     }
      //   }
      // );
      //console.log(params.get("token"));
    });

    this.formErrors.errors.subscribe(errors => {
      this.formErrors.display(errors, err => this.alertService.error(err));
    })  
  }

  login(){
    this.router.navigate(['/login']);
  }


  reset(){    
    this.submitted = true;
    this.alertService.clear();
    this.authService.resetPassword(this.model).subscribe({
      next: (res) => {      
        this.submitted = false;
        if (res == 'confirmed') {
          this.alertService.success("Password Updated");
          setTimeout(() => {
            this.router.navigate([`/login?email=${this.email}`], {
              queryParams: { email: this.email, token: null },
              queryParamsHandling: "merge",
            });
          }, 1000);
        }else{
          this.submitted = false;
        }
      },
      error: (err)=>this.submitted = false,
      complete:()=>console.log('complete')
    });
  }

}
